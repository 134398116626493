import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router';
import { Translation } from 'react-i18next';
import { Button, Input, Col, Row, Typography, Space } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { faFilter, faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useWindowDimensions from '../../../../../lib/WindowDimensions';
import hasPermission, {
  CREATE_RESOURCE,
} from '../../../elements/lib/hasPermission';
import useDebounce from '../../../elements/components/useDebounce';
import ResourceModalFilters from './ResourceModalFilters';
const { Title } = Typography;

const ResourcesScreenHeader = ({
  showForm,
  search,
  onChangeSearch,
  onChangeServiceTagsHandler,
  ...props
}) => {
  const { width } = useWindowDimensions();
  const isSmall = width <= 576;
  const [query, setQuery] = useState('');
  const debouncedQuery = useDebounce(query, 1000);
  const [activeSearch, setActiveSearch] = useState(false);
  const [visible, setVisible] = useState(false);

  const onSearch = (e) => {
    setQuery(e.target.value);
  };

  useEffect(() => {
    onChangeSearch(debouncedQuery);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedQuery]);

  return (
    <Translation>
      {(t) => (
        <>
          <div className="resource-list-header">
            <Row
              justify="space-between"
              align="top"
              className="resource-list-header-row"
            >
              <Col>
                <Title
                  level={2}
                  className="resource-title"
                  style={{ marginBottom: 0 }}
                >
                  {t('resources_screen_title')}
                </Title>
              </Col>
              <Col>
                {isSmall && (
                  <Space>
                    <Button className="icon-btn" size="large">
                      <FontAwesomeIcon
                        icon={faFilter}
                        className="icon-active"
                      />
                    </Button>
                    <Button
                      className="icon-btn"
                      size="large"
                      type={activeSearch ? 'primary' : 'default'}
                      onClick={() => {
                        setActiveSearch(!activeSearch);
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faMagnifyingGlass}
                        className={`${!activeSearch && 'icon-active'}`}
                      />
                    </Button>
                  </Space>
                )}
              </Col>
            </Row>

            <Row
              justify={isSmall ? 'start' : 'space-between'}
              align="top"
              className="resource-list-header-row"
            >
              <Col
                style={{ display: 'flex', alignItems: 'center' }}
                flex={isSmall ? 1 : 0}
              >
                {((activeSearch && isSmall) || !isSmall) && (
                  <Input
                    size="large"
                    placeholder={t('resources_screen_header_search')}
                    allowClear
                    onChange={(e) => onSearch(e)}
                    style={{
                      flex: 1,
                      marginRight: !isSmall ? '6px' : '0',
                      marginBottom: !isSmall ? '0' : '6px',
                    }}
                  />
                )}
                {!isSmall && (
                  <Button
                    className="icon-btn"
                    size="large"
                    onClick={() => setVisible(true)}
                  >
                    <FontAwesomeIcon icon={faFilter} className="icon-active" />
                    {t('resources_screen_header_add_filters')}
                  </Button>
                )}
              </Col>
              <Col
                style={
                  isSmall && {
                    display: 'flex',
                    width: '100%',
                    justifyContent: 'flex-start',
                  }
                }
              >
                {hasPermission(CREATE_RESOURCE) ? (
                  <Button
                    type="primary"
                    size="large"
                    icon={<PlusOutlined />}
                    onClick={() => showForm()}
                    style={{ flex: 1 }}
                  >
                    {t('resource_screen_header_add_resouce')}
                  </Button>
                ) : null}
              </Col>
            </Row>
          </div>

          <ResourceModalFilters
            visible={visible}
            setVisible={setVisible}
            onChangeServiceTagsHandler={onChangeServiceTagsHandler}
          />
        </>
      )}
    </Translation>
  );
};

export default withRouter(ResourcesScreenHeader);
