import {connect} from 'react-redux';
import {denormalize} from 'normalizr';
import {schema} from '../../../../../state/schema';

import {loadJournalTags} from '../../../../../state/modules/journalTags/actions';
import TagInput from '../components/TagInput';
import {loadJournalPartnerTags} from '../../../../../state/modules/journalPartnerTags/actions';

const mapStateToProps = (state, ownProps) => {

  const page = ownProps.page ? ownProps.page : 1;
  const limit = 500;
  const order = null;
  const result = state.journalTags.getIn(["pages", order, limit, page]);
  const result_partner = state.journalPartnerTags.getIn(["pages", order, limit, page]);

  const tags = state.entities.get('journalTags', {});
  const partner_tags = state.entities.get('journalPartnerTags', {});

  return {

    list: result 
      ? result.map(x => {
          return {
            key: x,
            ...denormalize(
              state.entities.getIn(['journalTags', x]),
              schema.journalTag,
              state.entities.toJS()
            )
          };
        }).toArray()
      : [],
    list_partner: result_partner 
      ? result_partner.map(x => {
          return {
            key: x,
            ...denormalize(
              state.entities.getIn(['journalPartnerTags', x]),
              schema.journalPartnerTag,
              state.entities.toJS()
            )
          };
        }).toArray()
      : [],
    options: Object.keys(tags)
      .map(id => {return {label: tags[id].label, value: id,}}),
    options_partner: Object.keys(partner_tags)
      .map(id => {return {label: partner_tags[id].label, value: id}}),
    isLoading: state.journalTags.get('isLoading'),
    isLoadingPartnerTags: state.journalPartnerTags.get('isLoading'),
    activeId: state.careGuides.get('activeId'),
  }
}

const mapDispatchToProps = dispatch => {
  return {
    load: (page, limit, order, filter, cb) => {
      dispatch(loadJournalTags(page, limit, order, filter, cb));
    },
    loadPartnerTags: (care_guide_id, page, limit, order, filter, cb) => {
      dispatch(loadJournalPartnerTags(care_guide_id, page, limit, order, filter, cb));
    },
  }
}

const TagInputContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(TagInput);

export default TagInputContainer;
