import { connect } from 'react-redux';
import { denormalize } from 'normalizr';
import { schema } from '../../../../../state/schema';

import {
  careGuideSetActive,
  careGuideSetAccess,
} from '../../../../../state/modules/careGuides/actions';

import HomeScreen from '../components/HomeScreen';
import storage from '../../../../../lib/Storage';

const mapStateToProps = (state, ownProps) => {
  const page = 1;
  const limit = 10;
  const order = 'started_at.asc';

  const pageUserProfilePartnership = parseInt(
    ownProps.match.params.page_partnership
      ? ownProps.match.params.page_partnership
      : storage.get('local', 'userProfilePartnershipPage')
      ? storage.get('local', 'userProfilePartnershipPage')
      : 1
  );

  const result = state.careGuides.getIn(['pages', order, limit, page]);

  const list = result
    ? result
        .map((x) => {
          return {
            key: x,
            ...denormalize(
              state.entities.getIn(['careGuides', x]),
              schema.careGuide,
              state.entities.toJS()
            ),
          };
        })
        .toArray()
    : [];

  return {
    isLoading: state.careGuides.get('isLoading'),
    isLoadingPartnership: state.userProfilesPartnerships.get('isLoading'),
    activeId: state.careGuides.get('activeId'),
    careGuideCount: state.careGuides.get('total'),
    userProfilesPartneshipsCount: state.userProfilesPartnerships.get('total'),
    invitationListCount: state.careGuideInvitations.get('total', 0),
    partnershipActiveId: storage.get('local', 'partnershipId'),
    partnershipName: storage.get('local', 'partnershipData')?.name,
    list: list,
    pageUserProfilePartnership: pageUserProfilePartnership,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setActive: (id) => {
      dispatch(careGuideSetActive(id));
    },
    setAccess: (data) => {
      dispatch(careGuideSetAccess(data));
    },
  };
};

const HomeScreenContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(HomeScreen);

export default HomeScreenContainer;
