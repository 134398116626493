import React, { useEffect } from 'react';
import { withRouter } from 'react-router';
import { Translation } from 'react-i18next';
import { Form, Select, Tag } from 'antd';

const MemberProfileTags = ({
  partnershipId,
  options,
  disabled,
  load,
  nameClasses,
  updateTagsNames,
  isEditionMode,
  ...props
}) => {
  useEffect(() => {
    if (partnershipId) {
      load(partnershipId);
    }
  }, [load, partnershipId]);

  useEffect(() => {
    updateTagsNames(nameClasses);
  }, [nameClasses, updateTagsNames]);

  return (
    <Translation>
      {(t) =>
        options.map((x) => (
          <div key={x?.id}>
            <Form.Item
              style={{ marginTop: '20px' }}
              name={x?.name}
              initialValue={''}
              label={
                <span className="info-subititle" style={{ width: '100%' }}>
                  {x?.name}
                </span>
              }
            >
              <Select
                className="tag-input"
                disabled={!isEditionMode}
                bordered={isEditionMode}
                showArrow={isEditionMode}
                size="large"
                placement="bottomRight"
                style={{
                  width: 'auto',
                  minWidth: !isEditionMode && '150px',
                  margin: 'auto 0',
                }}
                dropdownStyle={{ minWidth: '150px' }}
              >
                {x.care_guide_partner_tag?.map((y) => (
                  <Select.Option key={y.id} value={y.id}>
                    <Tag color={y.color_hex}>{y.label}</Tag>
                  </Select.Option>
                ))}
                <Select.Option key={''} value={''}>
                  <Tag>{t('member_profile_tag_unassigned')}</Tag>
                </Select.Option>
              </Select>
            </Form.Item>
          </div>
        ))
      }
    </Translation>
  );
};

export default withRouter(MemberProfileTags);
