import {Map} from 'immutable';

import {
  USER_ACCOUNT_READ_REQUEST,
  USER_ACCOUNT_READ_SUCCESS,
  USER_ACCOUNT_READ_FAILURE,
  USER_ACCOUNT_UPDATE_REQUEST,
  USER_ACCOUNT_UPDATE_SUCCESS,
  USER_ACCOUNT_UPDATE_FAILURE,
  USER_ACCOUNT_UPDATE_AVATAR_REQUEST,
  USER_ACCOUNT_UPDATE_AVATAR_SUCCESS,
  USER_ACCOUNT_UPDATE_AVATAR_FAILURE,
  USER_ACCOUNT_DELETE_AVATAR_REQUEST,
  USER_ACCOUNT_DELETE_AVATAR_SUCCESS,
  USER_ACCOUNT_DELETE_AVATAR_FAILURE,
  USER_ACCOUNT_FORM_DESTROY,
  PASSWORD_UPDATE_REQUEST,
  PASSWORD_UPDATE_SUCCESS,
  PASSWORD_UPDATE_FAILURE,
  PASSWORD_FORM_DESTROY,
  USER_ACCOUNT_ALERTS_UPDATE_REQUEST,
  USER_ACCOUNT_ALERTS_UPDATE_SUCCESS,
  USER_ACCOUNT_ALERTS_UPDATE_FAILURE,
  USER_ACCOUNT_ALERTS_FORM_DESTROY,
  USER_ACCOUNT_2FA_UPDATE_REQUEST,
  USER_ACCOUNT_2FA_UPDATE_SUCCESS,
  USER_ACCOUNT_2FA_UPDATE_FAILURE,
  USER_ACCOUNT_2FA_FORM_DESTROY,
  EMAIL_CONFIRMATION_REQUEST,
  EMAIL_CONFIRMATION_SUCCESS,
  EMAIL_CONFIRMATION_FAILURE
} from './actions'
import {SESSION_DESTROY} from '../../actions';
import Logger from '../../../lib/Logger';

const initialState = Map({
  isLoading: false,
  isSubmitting: false,
  isSubmittingAvatar: false,
  isSubmittingAlerts: false,
  isSubmitting2FA: false,
  isDeletingAvatar: false,
  isPasswordSubmitting: false,
  isEmailConfirmationSubmitting: false,
  data: {},
  form: {},
  passwordForm: {},
  alertsForm: {},
  form2FA: null,
});

export default function userAccount(
  state=initialState,
  action
) {
  Logger.log('debug', `[state.userAccount.reducers] userAccount(%j, %j)`, state, action);

  switch(action.type) {

    case USER_ACCOUNT_READ_REQUEST:
      return state.mergeDeep({
        isLoading: true
      });

    case USER_ACCOUNT_READ_SUCCESS:
      return state.mergeDeep({
        isLoading: false,
        data: {
          username: action.username,
          email: action.email,
          profile_id: action.profile_id,
          first_name: action.first_name,
          last_name: action.last_name,
          telephone: action.telephone,
          timezone: action.timezone,
          avatar: action.avatar,
          alert_summary: action.alert_summary,
          alert_summary_at: action.alert_summary_at,
          alerts_in_app: action.alerts_in_app,
          alerts_email: action.alerts_email,
          alerts_sms: action.alerts_sms,
          reminders_email: action.reminders_email,
          reminders_sms: action.reminders_sms,
          is_enrolled_otp: action.is_enrolled_otp,
          one_time_password: action.one_time_password,
          is_verified: action.is_verified,
        }
      });

    case USER_ACCOUNT_READ_FAILURE:
      return state.mergeDeep({
        isLoading: false
      });

    case USER_ACCOUNT_UPDATE_REQUEST:
      return state.mergeDeep({
        isSubmitting: true,
        form: null
      });

    case USER_ACCOUNT_UPDATE_SUCCESS:
      return state.mergeDeep({
        isSubmitting: false,
        data: {
          username: action.username,
          email: action.email,
          profile_id: action.profile_id,
          first_name: action.first_name,
          last_name: action.last_name,
          telephone: action.telephone,
          timezone: action.timezone,
          avatar: action.avatar,
          alert_summary: action.alert_summary,
          alert_summary_at: action.alert_summary_at,
          alerts_in_app: action.alerts_in_app,
          alerts_email: action.alerts_email,
          alerts_sms: action.alerts_sms,
          reminders_email: action.reminders_email,
          reminders_sms: action.reminders_sms,
          is_enrolled_otp: action.is_enrolled_otp,
          one_time_password: action.one_time_password,
          is_verified: action.is_verified,
        },
        form: {
          success: true,
          errors: null
        },
        lastUpdated: action.receivedAt
      });

    case USER_ACCOUNT_UPDATE_FAILURE:
      return state.mergeDeep({
        isSubmitting: false,
        form: {
          success: false,
          errors: null
        }
      }).setIn(['form', 'errors'], action.error);

    case USER_ACCOUNT_UPDATE_AVATAR_REQUEST:
      return state.mergeDeep({
        isSubmittingAvatar: true,
      });

    case USER_ACCOUNT_UPDATE_AVATAR_SUCCESS:
      return state.mergeDeep({
        isSubmittingAvatar: false,
        data: {
          username: action.username,
          email: action.email,
          profile_id: action.profile_id,
          first_name: action.first_name,
          last_name: action.last_name,
          telephone: action.telephone,
          timezone: action.timezone,
          avatar: action.avatar,
          alert_summary: action.alert_summary,
          alert_summary_at: action.alert_summary_at,
          alerts_in_app: action.alerts_in_app,
          alerts_email: action.alerts_email,
          alerts_sms: action.alerts_sms,
          reminders_email: action.reminders_email,
          reminders_sms: action.reminders_sms,
          is_enrolled_otp: action.is_enrolled_otp,
          one_time_password: action.one_time_password,
          is_verified: action.is_verified,
        },
        lastUpdated: action.receivedAt
      });

    case USER_ACCOUNT_UPDATE_AVATAR_FAILURE:
      return state.mergeDeep({
        isSubmittingAvatar: false,
      });

    case USER_ACCOUNT_DELETE_AVATAR_REQUEST:
      return state.mergeDeep({
        isDeletingAvatar: true,
      });

    case USER_ACCOUNT_DELETE_AVATAR_SUCCESS:
      return state.mergeDeep({
        isDeletingAvatar: false,
        data: {
          username: action.username,
          email: action.email,
          profile_id: action.profile_id,
          first_name: action.first_name,
          last_name: action.last_name,
          telephone: action.telephone,
          timezone: action.timezone,
          avatar: action.avatar,
          alert_summary: action.alert_summary,
          alert_summary_at: action.alert_summary_at,
          alerts_in_app: action.alerts_in_app,
          alerts_email: action.alerts_email,
          alerts_sms: action.alerts_sms,
          reminders_email: action.reminders_email,
          reminders_sms: action.reminders_sms,
          is_enrolled_otp: action.is_enrolled_otp,
          one_time_password: action.one_time_password,
          is_verified: action.is_verified,
        },
        lastUpdated: action.receivedAt
      });

    case USER_ACCOUNT_DELETE_AVATAR_FAILURE:
      return state.mergeDeep({
        isDeletingAvatar: false,
      });

    case USER_ACCOUNT_FORM_DESTROY:
      return state.mergeDeep({
        form: null
      }).set('form', action.form);

    case PASSWORD_UPDATE_REQUEST:
      return state.mergeDeep({
        isPasswordSubmitting: true,
        passwordForm: null
      });

    case PASSWORD_UPDATE_SUCCESS:
      return state.mergeDeep({
        isPasswordSubmitting: false,
        passwordForm: {
          success: true,
          errors: null
        }
      });

    case PASSWORD_UPDATE_FAILURE:
      return state.mergeDeep({
        isPasswordSubmitting: false,
        passwordForm: {
          success: false,
          errors: null
        }
      }).setIn(['passwordForm', 'errors'], action.error);

    case PASSWORD_FORM_DESTROY:
      return state.mergeDeep({
        form: null
      }).set('passwordForm', action.form);

    case USER_ACCOUNT_ALERTS_UPDATE_REQUEST:
      return state.mergeDeep({
        data: {
          alert_summary: action.alert_summary,
          alert_summary_at: action.alert_summary_at,
          alerts_in_app: action.alerts_in_app,
          alerts_email: action.alerts_email,
          alerts_sms: action.alerts_sms,
          reminders_email: action.reminders_email,
          reminders_sms: action.reminders_sms,
        },
        isSubmittingAlerts: true,
        alertsForm: null
      });

    case USER_ACCOUNT_ALERTS_UPDATE_SUCCESS:
      return state.mergeDeep({
        isSubmittingAlerts: false,
        data: {
          alert_summary: action.alert_summary,
          alert_summary_at: action.alert_summary_at,
          alerts_in_app: action.alerts_in_app,
          alerts_email: action.alerts_email,
          alerts_sms: action.alerts_sms,
          reminders_email: action.reminders_email,
          reminders_sms: action.reminders_sms,
        },
        alertsForm: {
          success: true,
          errors: null
        },
        lastUpdated: action.receivedAt
      });

    case USER_ACCOUNT_ALERTS_UPDATE_FAILURE:
      return state.mergeDeep({
        isSubmittingAlerts: false,
        alertsForm: {
          success: false,
          errors: null
        }
      }).setIn(['alertsForm', 'errors'], action.error);

    case USER_ACCOUNT_ALERTS_FORM_DESTROY:
      return state.mergeDeep({
        alertsForm: null
      }).set('alertsForm', action.form);

    case USER_ACCOUNT_2FA_UPDATE_REQUEST:
      return state.mergeDeep({
        isSubmitting2FA: true,
        form2FA: null
      });

    case USER_ACCOUNT_2FA_UPDATE_SUCCESS:
      return state.mergeDeep({
        isSubmitting2FA: false,
        data: {
          is_enrolled_otp: action.is_enrolled_otp,
          otp_secret: action.otp_secret,
          provisioning_uri: action.provisioning_uri,
          lastUpdated: action.receivedAt
        },
        form2FA: {
          success: true,
          errors: null
        },
        lastUpdated: action.receivedAt
      });

    case USER_ACCOUNT_2FA_UPDATE_FAILURE:
      return state.mergeDeep({
        isSubmitting2FA: false,
        form2FA: {
          success: false,
          errors: null
        }
      }).setIn(['form', 'errors'], action.error);

    case USER_ACCOUNT_2FA_FORM_DESTROY:
      return state.mergeDeep({
        form2FA: null
      }).set('form2FA', action.form2FA);
    
    case EMAIL_CONFIRMATION_REQUEST:
      return state.mergeDeep({
        isEmailConfirmationSubmitting: true,
      });

    case EMAIL_CONFIRMATION_SUCCESS:
      return state.mergeDeep({
        isEmailConfirmationSubmitting: false,
        data: {
          username: action.username,
          email: action.email,
          profile_id: action.profile_id,
          first_name: action.first_name,
          last_name: action.last_name,
          telephone: action.telephone,
          timezone: action.timezone,
          avatar: action.avatar,
          alert_summary: action.alert_summary,
          alert_summary_at: action.alert_summary_at,
          alerts_in_app: action.alerts_in_app,
          alerts_email: action.alerts_email,
          alerts_sms: action.alerts_sms,
          reminders_email: action.reminders_email,
          reminders_sms: action.reminders_sms,
          is_enrolled_otp: action.is_enrolled_otp,
          one_time_password: action.one_time_password,
          is_verified: action.is_verified,
        },
      });

    case EMAIL_CONFIRMATION_FAILURE:
      return state.mergeDeep({
        isEmailConfirmationSubmitting: false,
      });

    case SESSION_DESTROY:
      return initialState;

    default:
      return state;
  }
}

Logger.log('silly', `state.userAccount.reducers loaded.`);
