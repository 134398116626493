import React from 'react';
import {Translation} from 'react-i18next';
import {Card} from 'antd';
import {FcApproval} from "react-icons/fc";

import Logger from '../../../../../lib/Logger';


const PlanCard = ({plan, isSelected=false, canChange=true, onClick=null, ...props}) => {
  return (
    <Translation>{(t) => 
      <Card 
        hoverable={canChange}
        className={isSelected ? 'plan-details selected' : 'plan-details'}
        onClick={onClick}
      >
        <Card.Meta
          title={plan.name}
        />

          {isSelected ? <div className='plan-selected-icon'><FcApproval /></div> : null}

          {plan.description
            ? <div className='plan-description' dangerouslySetInnerHTML={{__html: plan.description}}></div>
            : null}

          {plan.trialPeriod
            ? <div className='plan-trial-period'>{plan.trialPeriod}</div>
            : null}

          <div className='plan-price-label'>{plan.priceLabel}</div>

      </Card>
    }</Translation>
  )  
}

export default PlanCard;

Logger.log('silly', `PlanCard loaded.`);
