import React, { Component } from 'react';
import { Translation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Button, Col, Dropdown, Menu, Row } from 'antd';
import {
  ContainerOutlined,
  // DeleteOutlined,
  DownOutlined,
  FileTextOutlined,
  InboxOutlined,
  PlusOutlined,
  // SendOutlined,
} from '@ant-design/icons';

import DocumentHead from '../../../elements/components/DocumentHead';
import MessageComposeForm from '../containers/MessageComposeFormContainer';
import {
  MessageRoutes,
  MessageThreadRoutes,
  getRouteFromPath,
  hasRoute,
  pathTo,
} from '../../../Routes';
import QueryString from '../../../../../lib/QueryString';
import Logger from '../../../../../lib/Logger';

class MessagesLayout extends Component {
  constructor(props) {
    super(props);
    this.handleResize = this.handleResize.bind(this);
  }

  static defaultProps = {
    limit: 25,
    isLoading: true,
  };

  state = {
    menuVisible: false,
    windowWidth: 0,
  };

  toggleMenu = (val) => {
    this.setState({ menuVisible: val });
  };

  render() {
    const routes = [
      {
        key: "inbox-screen",
        screen: ["MessagesInboxScreen", "MessagesInboxThreadScreen"],
        // pathParams: {id: this.props.careGuideId},
        title: "messages_menu_item_inbox",
        icon: <InboxOutlined />,
      },
      {
        key: "drafts-screen",
        screen: ["MessagesDraftsScreen", "MessagesDraftsThreadScreen"],
        // pathParams: {id: this.props.careGuideId},
        title: "messages_menu_item_drafts",
        icon: <FileTextOutlined />,
      },
      // {
      //   key: 'sent-screen',
      //   screen: ['MessagesSentScreen', 'MessagesSentThreadScreen'],
      //   pathParams: {id: this.props.careGuideId},
      //   title: 'messages_menu_item_sent',
      //   icon: <SendOutlined />,
      // },
      {
        key: "archived-screen",
        screen: ["MessagesArchivedScreen", "MessagesArchivedThreadScreen"],
        // pathParams: {id: this.props.careGuideId},
        title: "messages_menu_item_archived",
        icon: <ContainerOutlined />,
      },
      // {
      //   key: 'trash-screen',
      //   screen: ['MessagesTrashScreen', 'MessagesTrashThreadScreen'],
      //   pathParams: {id: this.props.careGuideId},
      //   title: 'messages_menu_item_trash',
      //   icon: <DeleteOutlined />,
      // },
    ];

    const currentRoute = getRouteFromPath(this.props.location.pathname);
    const currentMenuItem = currentRoute
      ? routes.filter((x) => x.screen.includes(currentRoute.screen))
      : null;
    const defaultSelectedKey =
      currentMenuItem && currentMenuItem.length ? currentMenuItem[0].key : null;
    const currentLabel =
      currentMenuItem && currentMenuItem.length
        ? currentMenuItem[0].title
        : null;

    const menu = (
      <Translation>
        {(t) => (
          <Menu
            defaultSelectedKeys={[defaultSelectedKey]}
            onClick={() => this.toggleMenu(false)}
          >
            {routes.map((x) =>
              hasRoute(x.screen[0]) ? (
                <Menu.Item key={x.key}>
                  <Link to={pathTo(x.screen[0], x.pathParams)}>
                    {x.icon}
                    <span>{t(x.title)}</span>
                  </Link>
                </Menu.Item>
              ) : null
            )}
          </Menu>
        )}
      </Translation>
    );

    return (
      <Translation>
        {(t) => (
          <>
            <DocumentHead title={t("messages_route_list")} />

            <Row className="messages-panel">
              {this.state.windowWidth > 768 ? (
                <>
                  <Col lg={8} md={24} sm={24} xs={24}>
                    <Row gutter={8} className="messages-toolbar">
                      <Col style={{ flex: 1 }}>
                        <Dropdown
                          overlay={menu}
                          overlayClassName="messages-menu-dropdown"
                          trigger={"click"}
                          visible={this.state.menuVisible}
                          onVisibleChange={this.toggleMenu}
                          arrow={true}
                        >
                          <Button shape="round" block>
                            {t(currentLabel)} <DownOutlined />
                          </Button>
                        </Dropdown>
                      </Col>

                      <Col style={{ marginRight: 8 }}>
                        <Button
                          shape="round"
                          type="primary"
                          className="message-compose"
                          icon={<PlusOutlined />}
                          onClick={() => this.props.showForm()}
                        >
                          {t("action_compose")}
                        </Button>
                      </Col>
                    </Row>

                    <MessageRoutes />
                  </Col>
                  <Col lg={16} md={24} sm={24} xs={24}>
                    <MessageThreadRoutes />
                  </Col>
                </>
              ) : currentRoute.params.page === undefined ? (
                <Col lg={8} md={24} sm={24} xs={24}>
                  <Row gutter={8} className="messages-toolbar">
                    <Col style={{ flex: 1 }}>
                      <Dropdown
                        overlay={menu}
                        overlayClassName="messages-menu-dropdown"
                        trigger={"click"}
                        visible={this.state.menuVisible}
                        onVisibleChange={this.toggleMenu}
                        arrow={true}
                      >
                        <Button shape="round" block>
                          {t(currentLabel)} <DownOutlined />
                        </Button>
                      </Dropdown>
                    </Col>

                    <Col style={{ marginRight: 8 }}>
                      <Button
                        shape="round"
                        type="primary"
                        className="message-compose"
                        icon={<PlusOutlined />}
                        onClick={() => this.props.showForm()}
                      >
                        {t("action_compose")}
                      </Button>
                    </Col>
                  </Row>

                  <MessageRoutes />
                </Col>
              ) : (
                <Col lg={16} md={24} sm={24} xs={24}>
                  <MessageThreadRoutes />
                </Col>
              )}
            </Row>

            <MessageComposeForm
              page={this.props.page}
              limit={this.props.limit}
              order={"sent_at.desc"}
            />
          </>
        )}
      </Translation>
    );
  }

  /* LIFECYCLE EVENTS */

  componentDidMount() {
    this.handleResize();
    window.addEventListener("resize", this.handleResize);
    // show compose form if requested via URL params
    const params = QueryString.parse(this.props.location.search);
    if ("composeThreadId" in params) {
      this.props.showForm();
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  handleResize() {
    this.setState({ windowWidth: window.innerWidth });
  }
}

export default MessagesLayout;

Logger.log("silly", `MessagesLayout loaded.`);
