import React, { useState, useEffect } from 'react';
import { Translation, getI18n } from 'react-i18next';
import { Button, Form, Typography } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import BodyInput from './BodyInput';
import notification from '../../../elements/lib/NotificationWrapper';
import Logger from '../../../../../lib/Logger';
import {
  MixpanelTracker,
  mixpanelEventsEnum,
} from '../../../../../lib/Mixpanel';

const { Title, Text } = Typography;

const ResourceNoteForm = ({
  careGuideId,
  resourceId,
  resource,
  careGuide,
  partnershipId,
  partnershipName,
  page,
  limit,
  order,
  errors,
  load,
  destroyForm,
  isLoading,
  isSubmitting,
  ...props
}) => {
  const [resetBody, setResetBody] = useState(null);
  const [form] = Form.useForm();

  // handle errors reported by API
  useEffect(() => {
    let firstFieldName = '';
    for (const field in errors) {
      form.setFields([{ name: field, errors: errors[field] }]);
      if (firstFieldName === '') {
        firstFieldName = field;
      }
    }
    form.scrollToField(firstFieldName);
  }, [form, errors]);

  // submit data handler
  const submitData = async (values) => {
    Logger.log('debug', `ResourceNoteForm.submitData(###)`);

    const payload = {};

    // transform values
    for (var key in values) {
      payload[key] = values[key];
    }

    props.create(careGuideId, resourceId, payload, (success) => {
      if (success) {
        notification.success(getI18n().t('resource_notes_created'));
        for (let i = 1; i <= page; i++) {
          load(careGuideId, resourceId, i, limit, order);
        }
        form.setFieldsValue({ body: '', rating: '' });
        setResetBody(Math.random());

        if (careGuide?.partnership) {
          MixpanelTracker.mixpanelTrack(
            mixpanelEventsEnum.RESOURCE.CREATE_NOTE,
            {
              careGuideName: `${careGuide.first_name} ${careGuide.last_name}`,
              partnershipName: careGuide.partnership.name,
              careGuideId: careGuide.id,
              partnershipId: careGuide.partnership.id,
              resourceId: resource.id,
              resourceName: resource.name,
            }
          );
        } else {
          MixpanelTracker.mixpanelTrack(
            mixpanelEventsEnum.RESOURCE.CREATE_NOTE,
            {
              careGuideName: `${careGuide.first_name} ${careGuide.last_name}`,
              careGuideId: careGuide.id,
              resourceId: resource.id,
              resourceName: resource.name,
            }
          );
        }
      } else {
        notification.error(getI18n().t('resource_notes_failure_created'));
      }
    });
  };

  // form submit handler
  const handleFinish = async (values) => {
    Logger.log('debug', `ResourceNoteForm.handleFinish(###)`);
    if (!props.isSubmitting) {
      await submitData(values);
    }
  };

  // form error handler
  const handleFinishFailed = ({ values, errorFields, outOfDate }) => {
    Logger.log('debug', `ResourceNoteForm.handleFinishFailed(###)`);
    notification.error(getI18n().t('resource_notes_failure_created'));
    if (errorFields && errorFields.length > 0) {
      form.scrollToField(errorFields[0].name);
    }
  };

  // remove error message when input value changes
  const handleValuesChange = (changedValues, allValues) => {
    for (const key of Object.keys(changedValues)) {
      form.setFields([{ name: key, errors: [] }]);
    }
  };

  return (
    <Translation>
      {(t) => (
        <>
          <div className="resource-note-form">
            <Form
              name="resource_note_form"
              form={form}
              onFinish={handleFinish}
              onFinishFailed={handleFinishFailed}
              onValuesChange={handleValuesChange}
              validateTrigger="onSubmit"
              requiredMark={false}
              layout="vertical"
            >
              <Title level={5}>{t('resource_notes_form_title')}</Title>
              <BodyInput
                name="body"
                form={form}
                placeholder={t('resource_notes_form_body_placeholder')}
                rules={[
                  {
                    required: true,
                    message: t('feedback_validation_required'),
                  },
                  {
                    type: 'string',
                    min: 1,
                    max: 4000,
                    message: t('feedback_validation_char_range', {
                      min: 1,
                      max: 4000,
                    }),
                  },
                ]}
                disabled={isLoading || isSubmitting}
                reset={resetBody}
              />
              <Text type="secondary" italic>
                {t('resource_notes_form_message')}
              </Text>
              <div className="button-container">
                <Button
                  type="primary"
                  icon={<PlusOutlined />}
                  htmlType="submit"
                  loading={isSubmitting}
                >
                  {t('resource_notes_form_button_submit')}
                </Button>
              </div>
            </Form>
          </div>
        </>
      )}
    </Translation>
  );
};

export default ResourceNoteForm;

Logger.log('silly', `ResourceNoteForm loaded.`);
