import React, {Component} from 'react';
import {Translation} from 'react-i18next';
import {Card} from 'antd';

import DocumentHead from '../../../elements/components/DocumentHead';
import DraftsList from '../containers/DraftsListContainer';
import Logger from '../../../../../lib/Logger';

class MessagesDraftsScreen extends Component {

  static defaultProps = {
    limit: 25,
    isLoading: true,
  }

  render() {
    const {page, limit} = this.props;
    return (
      <Translation>{(t) => 
        <>

          <DocumentHead title={t('messages_route_list_drafts')} />

          <Card size="small" bordered={false} className="message-list-card" bodyStyle={{padding: 0}}>
            <DraftsList
              component="MessagesDraftsThreadScreen"
              page={page}
              limit={limit}
              order={'created_at.desc'}
            />
          </Card>

        </>
      }</Translation>
    )
  }
}

export default MessagesDraftsScreen;

Logger.log('silly', `MessagesDraftsScreen loaded.`);
