import {connect} from 'react-redux';

import CalendarEventAdminEditScreen from '../components/CalendarEventAdminEditScreen';
import {loadCareGuidesMembersPartnership} from '../../../../../state/modules/careGuidesMembersPartnership/actions';
import storage from "../../../../../lib/Storage";

const mapStateToProps = state => {
  return {
    partnershipActiveId: storage.get('local', 'partnershipId'),
    isLoading: state.calendarEventsAdmin.get('isLoading'),
    success: state.calendarEventsAdmin.getIn(['form', 'success']),
  }
}

const mapDispatchToProps = dispatch => {
  return {
    loadMembersPartnership: (partnershipId, cb) => {
      dispatch(loadCareGuidesMembersPartnership(partnershipId, cb));
    },
  }
}

const CalendarEventAdminEditScreenContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(CalendarEventAdminEditScreen);

export default CalendarEventAdminEditScreenContainer;
