import React from "react";
import { withRouter } from "react-router";
import { Translation } from "react-i18next";
import { Tag } from "antd";

const MembersListTagColumn = ({ item }) => {
  return (
    <Translation>
      {(t) => (
        <>
          {item &&
            item.map((tag, index) => (
              <Tag color={tag.color_hex} key={index} style={{ margin: "6px" }}>
                {tag.label}
              </Tag>
            ))}
        </>
      )}
    </Translation>
  );
};

export default withRouter(MembersListTagColumn);
