import { List, Map } from "immutable";

import { TYPES } from "./actions";
import { SESSION_DESTROY } from "../../actions";
import Logger from "../../../lib/Logger";

const initialState = Map({
  pages: {},
  total: null,
  form: {},
  activeId: null,
  isLoading: false,
});

export default function userProfilesPartnerships(state = initialState, action) {
  Logger.log(
    "debug",
    `[state.userProfilesPartnerships.reducers] userProfilesPartnerships(%j, %j)`,
    state,
    action
  );

  switch (action.type) {
    case TYPES.LIST_REQUEST:
      return state.mergeDeep({
        isLoading: true,
      });

    case TYPES.LIST_SUCCESS:
      return state
        .mergeDeep({
          isLoading: false,
          pages: {
            [action.order]: {
              [action.limit]: {
                [action.page]: null,
              },
            },
          },
          total: action.total,
          lastUpdated: action.receivedAt,
        })
        .setIn(
          ["pages", action.order, action.limit, action.page],
          List(action.result)
        );

    case TYPES.LIST_FAILURE:
      return state.mergeDeep({
        isLoading: false,
      });

    case TYPES.SET_ACTIVE:
      return state.mergeDeep({
        activeId: action.id,
      });

    case SESSION_DESTROY:
      return initialState;

    default:
      return state;
  }
}

Logger.log("silly", `state.userProfilesPartnerships.reducers loaded.`);
