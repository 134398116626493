import {connect} from 'react-redux';

import PageHeaderPartnership from '../components/PageHeaderPartnership';

const mapStateToProps = (state) => {
  return {
    partnershipsCount: state.userProfilesPartnerships.get('total'),
  }
}

const PageHeaderPartnershipContainer = connect(
  mapStateToProps
)(PageHeaderPartnership);

export default PageHeaderPartnershipContainer;
