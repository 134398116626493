import { Map, List } from "immutable";
import { TYPES } from "./actions";
import Logger from "../../../lib/Logger";

export default function userProfilesPartnershipsAdmin(
  state = Map({
    isLoading: false,
    pages: {},
  }),
  action
) {
  Logger.log(
    "debug",
    `[userProfilesPartnershipsAdmin.reducers] userProfilesPartnershipsAdmin(%j, %j)`,
    state,
    action
  );

  switch (action.type) {
    case TYPES.USER_PROFILES_PARTNERSHIP_ADMIN_LIST_REQUEST:
      return state.mergeDeep({
        isLoading: true,
      });

    case TYPES.USER_PROFILES_PARTNERSHIP_ADMIN_LIST_SUCCESS:
      return state
        .mergeDeep({
          isLoading: false,
          pages: {
            [action.order]: {
              [action.limit]: {
                [action.page]: null,
              },
            },
          },
          total: action.total,
          lastUpdated: action.receivedAt,
        })
        .setIn(
          ["pages", action.order, action.limit, action.page],
          List(action.result)
        );

    case TYPES.USER_PROFILES_PARTNERSHIP_ADMIN_LIST_FAILURE:
      return state.mergeDeep({
        isLoading: false,
      });

    default:
      return state;
  }
}

Logger.log("silly", `userProfilesPartnershipsAdmin.reducers loaded.`);
