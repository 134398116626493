import { List, Map } from 'immutable';

import { TYPES } from './actions';
import { SESSION_DESTROY } from '../../actions';
import Logger from '../../../lib/Logger';

const initialState = Map({
  isLoading: false,
  isLoadingIds: false,
  isDeleting: false,
  isSubmittingImage: false,
  isDeletingImage: false,
  isGeneratingPDF: false,
  isGeneratingSegmentPDF: false,
  accessCareGuide: null,
  pages: {},
  total: null,
  totalMembers: 0,
  totalMembersFiltered: 0,
  idsFiltered: List([]),
  form: {},
  segmentForm: {},
  activeId: null,
  activePage: null,
  firstLoad: true,
  isCSVDownloading: false,
  allIds: List([]),
  areCareGuidesLoading: false,
});

export default function careGuides(state = initialState, action) {
  Logger.log(
    'debug',
    `[state.careGuides.reducers] careGuides(%j, %j)`,
    state,
    action
  );

  switch (action.type) {
    case TYPES.LIST_REQUEST:
      return state.mergeDeep({
        isLoading: true,
      });

    case TYPES.LIST_SUCCESS:
      return state
        .mergeDeep({
          isLoading: false,
          pages: {
            [action.order]: {
              [action.limit]: {
                [action.page]: null,
              },
            },
          },
          total: action.total,
          lastUpdated: action.receivedAt,
        })
        .setIn(
          ['pages', action.order, action.limit, action.page],
          List(action.result)
        );

    case TYPES.LIST_FAILURE:
      return state.mergeDeep({
        isLoading: false,
      });

    case TYPES.TOTAL_MEMBERS_REQUEST:
      return state.mergeDeep({
        isLoading: true,
      });

    case TYPES.TOTAL_MEMBERS_SUCCESS:
      return state.mergeDeep({
        isLoading: false,
        totalMembers: action.total,
        totalMembersFiltered: action.total_filtered,
        lastUpdated: action.receivedAt,
      }).setIn(['idsFiltered'], List(action.ids_filtered));

    case TYPES.TOTAL_MEMBERS_FAILURE:
      return state.mergeDeep({
        isLoading: false,
      });

    case TYPES.READ_REQUEST:
      return state.mergeDeep({
        isLoading: true,
      });

    case TYPES.READ_SUCCESS:
      return state.mergeDeep({
        isLoading: false,
        lastUpdated: action.receivedAt,
      });

    case TYPES.READ_FAILURE:
      return state.mergeDeep({
        isLoading: false,
      });

    case TYPES.UPDATE_REQUEST:
      return state.mergeDeep({
        isLoading: true,
        form: {
          isSubmitting: true,
          success: null,
          errors: null,
        },
      });

    case TYPES.UPDATE_SUCCESS:
      return state.mergeDeep({
        isLoading: false,
        form: {
          isSubmitting: false,
          success: true,
          errors: null,
        },
        lastUpdated: action.receivedAt,
      });

    case TYPES.UPDATE_FAILURE:
      return state
        .mergeDeep({
          isLoading: false,
          form: {
            isSubmitting: false,
            success: false,
            errors: null,
          },
        })
        .setIn(['form', 'errors'], action.error);

    case TYPES.PATCH_REQUEST:
      return state.mergeDeep({
        form: {
          isSubmitting: true,
          success: null,
          errors: null,
        },
      });

    case TYPES.PATCH_SUCCESS:
      return state.mergeDeep({
        form: {
          isSubmitting: false,
          success: true,
          errors: null,
        },
        lastUpdated: action.receivedAt,
      });

    case TYPES.PATCH_FAILURE:
      return state
        .mergeDeep({
          form: {
            isSubmitting: false,
            success: false,
            errors: null,
          },
        })
        .setIn(['form', 'errors'], action.error);

    case TYPES.PDF_REQUEST:
      return state.mergeDeep({
        isGeneratingPDF: true,
      });

    case TYPES.PDF_SUCCESS:
      return state.mergeDeep({
        isGeneratingPDF: false,
        lastUpdated: action.receivedAt,
      });

    case TYPES.PDF_FAILURE:
      return state.mergeDeep({
        isGeneratingPDF: false,
      });

    case TYPES.SET_ACTIVE:
      return state.mergeDeep({
        activeId: action.id,
      });

    case TYPES.SET_FIRST_LOAD:
      return state.mergeDeep({
        firstLoad: action.firstLoad,
      });

    case TYPES.SET_ACTIVE_PAGE:
      return state.mergeDeep({
        activePage: action.page,
      });

    case TYPES.CREATE_SEGMENT_REQUEST:
      return state.mergeDeep({
        isLoading: false,
        segmentForm: {
          isSubmitting: true,
          success: null,
          errors: null,
        },
        lastUpdated: action.receivedAt,
      });

    case TYPES.CREATE_SEGMENT_SUCCESS:
      return state.mergeDeep({
        isLoading: false,
        segmentForm: {
          isSubmitting: false,
          success: true,
          errors: null,
        },
      });

    case TYPES.CREATE_SEGMENT_FAILURE:
      return state
        .mergeDeep({
          isLoading: false,
          segmentForm: {
            isSubmitting: false,
            success: false,
            errors: null,
          },
        })
        .setIn(['segmentForm', 'errors'], action.error);

    case TYPES.UPDATE_SEGMENT_REQUEST:
      return state.mergeDeep({
        isLoading: true,
        segmentForm: {
          isSubmitting: true,
          success: null,
          errors: null,
        },
      });

    case TYPES.UPDATE_SEGMENT_SUCCESS:
      return state.mergeDeep({
        isLoading: false,
        segmentForm: {
          isSubmitting: false,
          success: true,
          errors: null,
        },
        lastUpdated: action.receivedAt,
      });

    case TYPES.UPDATE_SEGMENT_FAILURE:
      return state
        .mergeDeep({
          isLoading: false,
          segmentForm: {
            isSubmitting: false,
            success: false,
            errors: null,
          },
        })
        .setIn(['segmentForm', 'errors'], action.error);

    case TYPES.DELETE_SEGMENT_REQUEST:
      return state.mergeDeep({
        isDeleting: false,
        lastUpdated: action.receivedAt,
      });

    case TYPES.DELETE_SEGMENT_SUCCESS:
      return state.mergeDeep({
        isDeleting: false,
      });

    case TYPES.DELETE_SEGMENT_FAILURE:
      return state
        .mergeDeep({
          isDeleting: false,
          segmentForm: null,
        })
        .setIn(['segmentForm', 'errors'], action.error);

    case TYPES.PDF_SEGMENT_REQUEST:
      return state.mergeDeep({
        isGeneratingSegmentPDF: true,
      });

    case TYPES.PDF_SEGMENT_SUCCESS:
      return state.mergeDeep({
        isGeneratingSegmentPDF: false,
        lastUpdated: action.receivedAt,
      });

    case TYPES.PDF_SEGMENT_FAILURE:
      return state.mergeDeep({
        isGeneratingSegmentPDF: false,
      });

    case TYPES.UPDATE_IMAGE_REQUEST:
      return state.mergeDeep({
        isSubmittingImage: true,
      });

    case TYPES.UPDATE_IMAGE_SUCCESS:
      return state.mergeDeep({
        isSubmittingImage: false,
        lastUpdated: action.receivedAt,
      });

    case TYPES.UPDATE_IMAGE_FAILURE:
      return state.mergeDeep({
        isSubmittingImage: false,
      });

    case TYPES.DELETE_IMAGE_REQUEST:
      return state.mergeDeep({
        isDeletingImage: true,
      });

    case TYPES.DELETE_IMAGE_SUCCESS:
      return state.mergeDeep({
        isDeletingImage: false,
        lastUpdated: action.receivedAt,
      });

    case TYPES.DELETE_IMAGE_FAILURE:
      return state.mergeDeep({
        isDeletingImage: false,
      });

    case TYPES.SET_CARE_GUIDE_ACCESS:
      return state.mergeDeep({
        accessCareGuide: action.access_role_admin_in_care_guide,
      });

    case TYPES.CARE_GUIDES_ALL_IDS_REQUEST:
      return state.mergeDeep({
        isLoadingIds: true,
      });

    case TYPES.CARE_GUIDES_ALL_IDS_SUCCESS:
      return state
        .mergeDeep({
          isLoadingIds: false,
        })
        .setIn(['allIds'], List(action.data));

    case TYPES.CARE_GUIDES_ALL_IDS_FAILURE:
      return state.mergeDeep({
        isLoadingIds: false,
      });

    case TYPES.CARE_GUIDES_CSV_DOWNLOAD_REQUEST:
      return state.mergeDeep({
        isCSVDownloading: true,
      });

    case TYPES.CARE_GUIDES_CSV_DOWNLOAD_SUCCESS:
      return state.mergeDeep({
        isCSVDownloading: false,
      });

    case TYPES.CARE_GUIDES_CSV_DOWNLOAD_FAILURE:
      return state.mergeDeep({
        isCSVDownloading: false,
      });

    case TYPES.CARE_GUIDES_PARTNERSHIP_LIST_REQUEST:
      return state.mergeDeep({
        areCareGuidesLoading: true,
      });

    case TYPES.CARE_GUIDES_PARTNERSHIP_LIST_SUCCESS:
      return state
        .mergeDeep({
          areCareGuidesLoading: false,
          pages: {
            [action.partnershipId]: {
              [action.order]: {
                [action.limit]: {
                  [action.page]: null,
                },
              },
            },
          },
          total: action.total,
          lastUpdated: action.receivedAt,
        })
        .setIn(
          [
            'pages',
            action.partnershipId,
            action.order,
            action.limit,
            action.page,
          ],
          List(action.result)
        );

    case TYPES.CARE_GUIDES_PARTNERSHIP_LIST_FAILURE:
      return state.mergeDeep({
        areCareGuidesLoading: false,
      });

    case SESSION_DESTROY:
      return initialState;

    default:
      return state;
  }
}

Logger.log('silly', `state.careGuides.reducers loaded.`);
