import React, {useEffect} from 'react';
import {withRouter} from "react-router";
import {Link} from 'react-router-dom';
import {Translation} from 'react-i18next';
import {Avatar, Badge, List} from 'antd';

import QueryString from '../../../../../lib/QueryString';
import UserAvatar from '../../../elements/components/UserAvatar';
import {pathTo} from '../../../Routes';
import Format from '../../../../../lib/Format';
import Logger from '../../../../../lib/Logger';


function MessagesList({type, component, page, threadId, limit, order, total=0, load, history, ...props}) {

  useEffect(() => {
    load(type, page, limit, order);
  }, [type, page, limit, order, load]);

  const pagination = {
    onChange: newPage => {
      const path = pathTo(component, {page: newPage, threadId: threadId});
      history.push(QueryString.append(path));
    },
    current: page,
    pageSize: limit,
    total: total,
  };

  const screens = {
    'inbox': 'MessagesInboxThreadScreen',
    'drafts': 'MessagesDraftsThreadScreen',
    'sent': 'MessagesSentThreadScreen',
    'archived': 'MessagesArchivedThreadScreen',
    'deleted': 'MessagesTrashThreadScreen',
  };

  // get all thread participants, ordered by most recent activity
  const getThreadParticipants = (thread) => {
    return [
      ...new Set([
        ...thread.messages.map(x => x.profile).reverse(),     // get ordered list of message authros
        ...thread.messages[0].recipients.map(x => x.profile)  // get all recipients with no particular order
      ])
    ];
  }

  return (
    <Translation>{(t) => 
      <List
        className="messages-list"
        itemLayout="horizontal"
        dataSource={props.list}
        pagination={page === 1 && total <= limit ? false : pagination}
        renderItem={item => (
          <Link
            className={threadId === item.id ? 'active' : null}
            to={pathTo(screens[type], {page: page, threadId: item.id})}
            onClick={type === 'drafts' ? () => props.showForm(null, item.id) : null}
          >
            <List.Item
              extra={
                <div>
                  <div className="message-date">{Format.date(item.messages[item.messages.length - 1].sent_at)}</div>
                  <div className="message-badge"><Badge count={item.messages.filter(x => x.recipients.filter(y => y.profile.id === props.profileId && y.is_read === false).length > 0).length} /></div>
                </div>
              }
            >

              <List.Item.Meta
                avatar={
                  <Avatar.Group maxCount={2} maxStyle={{ color: '#f56a00', backgroundColor: '#fde3cf' }}>
                    {getThreadParticipants(item).map((x, i) => <UserAvatar key={i} profile={x} showTooltp={true} />)}
                  </Avatar.Group>
                }
                title={item.messages[0].subject || t('messages_no_subject')}
                description={['drafts'].includes(type)
                  ? item.messages[0].recipients.map(x => x.profile.first_name + ' ' + x.profile.last_name).join(', ')
                  : getThreadParticipants(item).map(x => x.first_name + ' ' + x.last_name).join(', ')
                }
              />

            </List.Item>
          </Link>
        )}
      />
    }</Translation>
  )  
}

export default withRouter(MessagesList);

Logger.log('silly', `MessagesList loaded.`);
