import {connect} from 'react-redux';

import UserAccountScreen from '../components/UserAccountScreen'

const mapStateToProps = state => {
  return {
    isLoading: state.userAccount.get('isLoading'),
    isSubmitting2FA: state.userAccount.get('isSubmitting2FA'),
    oneTimePassword: state.userAccount.getIn(['data', 'one_time_password']),
  }
}

const UserAccountScreenContainer = connect(
  mapStateToProps
)(UserAccountScreen)

export default UserAccountScreenContainer
