import { connect } from 'react-redux';
import { List } from 'immutable';
import { denormalize } from 'normalizr';

import { schema } from '../../../../../state/schema';
import {
  loadCareGuide,
  patchCareGuide,
  generateCareGuidePDF,
} from '../../../../../state/modules/careGuides/actions';
import { uiSetRoutingPreventTransition } from '../../../../../state/ui/actions';
import CareGuideSummary from '../components/CareGuideSummary';

const inputs = List(['summary']);

const mapStateToProps = (state, ownProps) => {
  const careGuideId = ownProps.careGuideId;

  const data = {},
    errors = {};
  for (const val of inputs.values()) {
    data[val] = state.entities.getIn(['careGuides', careGuideId, val], '');
    errors[val] = state.careGuides.getIn(['form', 'errors', val]);
  }

  const careGuide = careGuideId
    ? denormalize(
        state.entities.getIn(['careGuides', careGuideId]),
        schema.careGuide,
        state.entities.toJS()
      )
    : null;

  return {
    isLoading: state.careGuides.get('isLoading'),
    isSubmitting: state.careGuides.getIn(['form', 'isSubmitting']),
    success: state.careGuides.getIn(['form', 'success']),
    isGeneratingPDF: state.careGuides.get('isGeneratingPDF'),
    careGuide: careGuide,
    data: data,
    errors: errors,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    load: (id, cb) => {
      dispatch(loadCareGuide(id, cb));
    },
    patch: (careGuideId, data, cb) => {
      dispatch(patchCareGuide(careGuideId, data, cb));
    },
    preventRoutingTransition: (prevent) => {
      dispatch(uiSetRoutingPreventTransition(prevent));
    },
    generatePDF: (careGuideId, cb) => {
      dispatch(generateCareGuidePDF(careGuideId, cb));
    },
  };
};

const CareGuideSummaryContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(CareGuideSummary);

export default CareGuideSummaryContainer;
