import {connect} from 'react-redux';

import MessagesInboxScreen from '../components/MessagesInboxScreen';

const mapStateToProps = (state, ownProps) => {
  const page = parseInt(ownProps.match.params.page ? ownProps.match.params.page : 1);
  const threadId = ownProps.match.params.threadId ? ownProps.match.params.threadId : null;
  return {
    isLoading: state.journalPosts.get('isLoading'),
    page: page,
    threadId: threadId,
  }
}

const MessagesInboxScreenContainer = connect(
  mapStateToProps
)(MessagesInboxScreen);

export default MessagesInboxScreenContainer;
