import React, {Component} from 'react';
import {Translation} from 'react-i18next';
import {Card} from 'antd';

import DocumentHead from '../../../elements/components/DocumentHead';
import MessagesList from '../containers/MessagesListContainer';
import Logger from '../../../../../lib/Logger';

class MessagesInboxScreen extends Component {

  static defaultProps = {
    limit: 25,
    isLoading: true,
  }

  render() {
    const {page, limit} = this.props;
    return (
      <Translation>{(t) => 
        <>

          <DocumentHead title={t('messages_route_list_inbox')} />

          <Card size="small" bordered={false} className="message-list-card" bodyStyle={{padding: 0}}>
            <MessagesList
              type={'inbox'}
              component="MessagesInboxThreadScreen"
              page={page}
              limit={limit}
              order={'sent_at.desc'}
            />
          </Card>

        </>
      }</Translation>
    )
  }
}

export default MessagesInboxScreen;

Logger.log('silly', `MessagesInboxScreen loaded.`);
