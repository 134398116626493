import React from "react";
import { withRouter } from "react-router";
import { Translation } from "react-i18next";
import { Row, Space, Tag, Button } from "antd";
import { IoClose } from "react-icons/io5";

const CareGuideNoteListTags = ({ dateStart, dateEnd, handleResetDate}) => {

    return (
        <Translation>{(t) =>
            <Row>
                <Space size={12}>
                    <Tag
                        className="action-filter-tag"
                        style={{ backgroundColor: "#E5E5E5", color: "#262626", }}
                    >
                        {t("care_guide_note_date_tag", { dateStart: dateStart, dateEnd: dateEnd})}
                        <Button type="link" onClick={handleResetDate}>
                            <IoClose />
                        </Button>
                    </Tag>
                </Space>
            </Row>
        }</Translation>
    );
};

export default withRouter(CareGuideNoteListTags);