import {connect} from 'react-redux';
import {denormalize} from 'normalizr';

import PlanInput from '../components/PlanInput';
import {schema} from '../../../../../state/schema';
import {loadPlans} from '../../../../../state/modules/plans/actions';

const page = 1;
const limit = 10;
const order = 'order.asc';

const mapStateToProps = (state, ownProps) => {
  const result = state.plans.getIn(['results', 'pages', order, limit, page]);
  return {
    list: result 
      ? result.map(x => {
          return {
            key: x,
            ...denormalize(
              state.entities.getIn(['plans', x]),
              schema.plan,
              state.entities.toJS()
            )
          };
        }).toArray()
      : [],
    total: state.plans.getIn(['results', 'total']),
    isLoading: state.plans.get('isLoading')
  }
}

const mapDispatchToProps = dispatch => {
  return {
    load: (cb) => {
      dispatch(loadPlans(page, limit, order, cb));
    },
  }
}

const PlanInputContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(PlanInput);

export default PlanInputContainer;
