import { connect } from 'react-redux';
import { List } from 'immutable';
import { denormalize } from 'normalizr';
import moment from 'moment-timezone';

import { schema } from '../../../../../state/schema';
import {
  loadCalendarEvents,
  createCalendarEvent,
  updateCalendarEvent,
  patchCalendarEvent,
  deleteCalendarEvent,
  createCalendarEventException,
  calendarEventFormDestroy,
  calendarHideEventForm,
  createCalendarEventPartner,
  updateCalendarEventPartner,
  patchCalendarEventPartner,
  deleteCalendarEventPartner,
} from '../../../../../state/modules/calendar/actions';

import EventForm from '../components/EventForm';
import Config from '../../../../../Config';
import storage from '../../../../../lib/Storage';

const inputs = List([
  'title',
  'url_event_partner',
  'url_label',
  'description',
  'start_at_date',
  'start_at_time',
  'end_at_date',
  'end_at_time',
  'is_all_day',
  'participants',
  'recurring',
  'recurring_end_at',
  'parent_event_id',
]);

const defaultLimit = 100;

const mapStateToProps = (state, ownProps) => {
  const errors = {};
  for (const val of inputs.values()) {
    if (state.calendar.getIn(['eventForm', 'errors', val])) {
      errors[val] = state.calendar.getIn(['eventForm', 'errors', val]);
    }
  }

  const careGuideId = ownProps.careGuideId;

  const careGuide = careGuideId
    ? denormalize(
        state.entities.getIn(['careGuides', careGuideId]),
        schema.careGuide,
        state.entities.toJS()
      )
    : null;
  const profileId = state.session.get('profileId');

  const eventId = state.calendar.get('eventFormEventId');
  const event = eventId
    ? denormalize(
        state.entities.getIn(['calendarEvents', eventId]),
        schema.calendarEvent,
        state.entities.toJS()
      )
    : null;

  const tz = state.userAccount.getIn(
    ['data', 'timezone'],
    'America/Los_Angeles'
  );
  const startDate = state.calendar.get('eventFormStartDate');
  const startDateObj = startDate ? moment(startDate) : null;
  const clickedOnDate = startDate ? true : false;

  // True if non-recurring or if is the first instance of a recurring event
  const isFirstInstace = event
    ? event.recurring === 1 ||
      (event.recurring_event &&
        event.recurring_event.start_at === event.start_at)
      ? true
      : false
    : null;

  const data = {
    title: event?.title ?? '',
    url_event_partner: event?.url_event_partner ?? '',
    url_label: event?.url_label ?? '',
    is_all_day: event?.is_all_day ?? false,
    start_at_date: event
      ? moment.tz(event.start_at, Config.get('API_DATETIME_FORMAT'), tz)
      : startDateObj,
    start_at_time: event
      ? moment
          .tz(event.start_at, Config.get('API_DATETIME_FORMAT'), tz)
          .format('h:mm A')
      : '8:00 AM',
    end_at_date:
      event && event.end_at
        ? moment
            .tz(event.end_at, Config.get('API_DATETIME_FORMAT'), tz)
            .format('MM/DD/YYYY')
        : startDateObj
        ? startDateObj.format('MM/DD/YYYY')
        : null,
    end_at_time:
      event && event.end_at
        ? moment
            .tz(event.end_at, Config.get('API_DATETIME_FORMAT'), tz)
            .format('h:mm A')
        : '9:00 AM',
    description: event?.description ?? '',
    participants:
      event && event?.participants
        ? event.participants.map((x) => x.profile.id)
        : profileId !== careGuide?.owner?.profile?.id &&
          careGuide?.owner?.profile?.id
        ? [profileId, careGuide?.owner?.profile?.id]
        : [profileId],
    recurring: event?.recurring ?? 1,
    care_guide_id: event?.care_guide_id,
    recurring_end_at:
      event && event.recurring_end_at
        ? moment.tz(
            event.recurring_end_at,
            Config.get('API_DATETIME_FORMAT'),
            tz
          )
        : null,
    parent_event_id: event && event.parent_event ? event.parent_event.id : '',
    careGuide,
  };

  return {
    visible: state.calendar.get('eventFormIsVisible'),
    isSubmitting: state.calendar.getIn(['eventForm', 'isSubmitting']),
    success: state.calendar.getIn(['eventForm', 'success']),
    timezone: tz,
    eventId: eventId,
    event: event,
    profileId: profileId,
    data: data,
    errors: errors,
    clickedOnDate: clickedOnDate,
    isFirstInstace: isFirstInstace,
    partnershipActiveId: storage.get('local', 'partnershipId'),
    careGuide,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    load: (
      careGuideId,
      start,
      end,
      page = 1,
      limit = defaultLimit,
      order = 'start_at.asc',
      filter = null,
      cb = function () {}
    ) => {
      dispatch(
        loadCalendarEvents(
          careGuideId,
          start,
          end,
          page,
          limit,
          order,
          filter,
          (success, total) => {
            if (success) {
              // loop over all pages to get all events
              const pageCount = Math.ceil(total / limit);
              for (var i = 2; i <= pageCount; i++) {
                dispatch(
                  loadCalendarEvents(
                    careGuideId,
                    start,
                    end,
                    i,
                    limit,
                    order,
                    filter
                  )
                );
              }
              cb();
            }
          }
        )
      );
    },
    patchPartner: (eventId, partnershipId, data, cb) => {
      dispatch(patchCalendarEventPartner(eventId, partnershipId, data, cb));
    },
    duplicatePartner: (eventId, partnershipId, data, cb) => {
      dispatch(createCalendarEventPartner(eventId, partnershipId, data, cb));
    },
    create: (careGuideId, data, cb) => {
      dispatch(createCalendarEvent(careGuideId, data, cb));
    },
    update: (careGuideId, id, data, cb) => {
      dispatch(updateCalendarEvent(careGuideId, id, data, cb));
    },
    updatePartner: (partnershipId, id, data, cb) => {
      dispatch(updateCalendarEventPartner(partnershipId, id, data, cb));
    },
    patch: (careGuideId, id, data, cb) => {
      dispatch(patchCalendarEvent(careGuideId, id, data, cb));
    },
    delete: (careGuideId, id, cb) => {
      dispatch(deleteCalendarEvent(careGuideId, id, cb));
    },
    deletePartner: (partnershipId, id, cb) => {
      dispatch(deleteCalendarEventPartner(partnershipId, id, cb));
    },
    createException: (careGuideId, eventId, data, cb) => {
      dispatch(createCalendarEventException(careGuideId, eventId, data, cb));
    },
    formDestroy: (formState) => {
      dispatch(calendarEventFormDestroy(formState));
    },
    hideForm: () => {
      dispatch(calendarHideEventForm());
    },
  };
};

const EventFormContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(EventForm);

export default EventFormContainer;
