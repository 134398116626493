import React, { Component } from 'react';
import { Prompt } from 'react-router-dom';
import { Translation } from 'react-i18next';
import { Col, Row } from 'antd';

import CareGuideDisabled from './CareGuideDisabled';
import CareGuideWrapper from '../../../layouts/components/CareGuideWrapper';
import CareGuideMenu from './CareGuideMenu';
import CareGuideSummary from '../containers/CareGuideSummaryContainer';
import CareGuideSegment from '../containers/CareGuideSegmentContainer';
import CareGuideSegmentCreate from '../containers/CareGuideSegmentCreateContainer';
import Loading from '../../../elements/components/Loading';
import DocumentHead from '../../../elements/components/DocumentHead';
import scrollToTop from '../../../elements/lib/scrollToTop';
import Logger from '../../../../../lib/Logger';
import { Redirect } from 'react-router-dom';
import { pathTo } from '../../../Routes';
import hasPermission, {
  VIEW_ACCESS_CARE_GUIDE,
} from '../../../elements/lib/hasPermission';

class CareGuideDetailScreen extends Component {
  static defaultProps = {
    isLoading: true,
  };

  constructor(props) {
    super(props);
    this.state = {
      isEditingSummary: false,
      createFormIsVisible: false,
    };
  }

  onClickEditHandler = (e) => {
    this.setState({
      isEditingSummary: true,
    });
  };

  onClickSaveHandler = (e) => {
    this.setState({
      isEditingSummary: false,
    });
  };

  onClickCancelHandler = (e) => {
    this.setState({
      isEditingSummary: false,
    });
  };

  onClickAnchorHandler = (e, link) => {
    if (link.href === '#create') {
      e.preventDefault();
      this.setState({ createFormIsVisible: true }, () => {
        this.props.preventRoutingTransition(true);
      });
    }
  };

  onClickCreateCancelHandler = () => {
    this.setState({ createFormIsVisible: false }, () => {
      this.props.preventRoutingTransition(false);
    });
  };

  render() {
    const { careGuide, isCareGuideActive, isCareGuideLoading } = this.props;

    if (isCareGuideLoading) {
      return (
        <Translation>
          {(t) => (
            <CareGuideWrapper>
              <DocumentHead title={t('care_guides_route_view')} />
              <Loading />
            </CareGuideWrapper>
          )}
        </Translation>
      );
    }

    if (isCareGuideActive === null) {
      return <Redirect to={pathTo('HomeScreen')} />;
    }

    if (!isCareGuideActive) {
      return (
        <Translation>
          {(t) => (
            <CareGuideWrapper>
              <DocumentHead title={t('care_guides_route_view')} />
              <CareGuideDisabled careGuideId={this.props.id} />
            </CareGuideWrapper>
          )}
        </Translation>
      );
    }

    return (
      <Translation>
        {(t) => (
          <CareGuideWrapper>
            <Prompt
              when={this.props.isRoutingTransitionPrevented}
              message={(location) => t('care_guides_pompt_editing')}
            />

            <DocumentHead title={t('care_guides_route_view')} />

            <Row gutter={16}>
              <Col lg={6} md={24} sm={24} xs={24} className="care-guide-menu">
                <CareGuideMenu
                  careGuide={careGuide}
                  onClickAnchorHandler={this.onClickAnchorHandler.bind(this)}
                />
              </Col>

              <Col lg={18} md={24}>
                {careGuide ? (
                  <CareGuideSummary
                    key={careGuide.id}
                    careGuideId={careGuide.id}
                  />
                ) : null}

                <div className="care-guide-segment-cards">
                  {careGuide &&
                  careGuide.segments &&
                  careGuide.segments.length > 0
                    ? careGuide.segments
                        .sort((a, b) =>
                          a.order < b.order ? -1 : a.order > b.order ? 1 : 0
                        )
                        .map((segment, i) =>
                          segment ? (
                            <CareGuideSegment
                              careGuide={careGuide}
                              careGuideId={careGuide.id}
                              segmentId={segment.id}
                              maxOrder={careGuide.segments.length}
                              key={segment.id + segment.order}
                            />
                          ) : null
                        )
                    : null}

                  <div id="create">
                    {careGuide && this.state.createFormIsVisible ? (
                      <CareGuideSegmentCreate
                        careGuideId={careGuide.id}
                        maxOrder={careGuide.segments.length + 1}
                        onCancel={this.onClickCreateCancelHandler.bind(this)}
                      />
                    ) : null}
                  </div>
                </div>
              </Col>
            </Row>
          </CareGuideWrapper>
        )}
      </Translation>
    );
  }

  /* LIFECYCLE EVENTS */

  componentDidMount() {
    this.props.load(this.props.match.params.id);
    scrollToTop();
  }

  componentDidUpdate(prevProps) {
    if (this.props.isCareGuideActive) {
      if (this.props.careGuide?.partnership !== prevProps.partnershipActiveId) {
        if (this.props.careGuide.partnership) {
          this.props.setActiveAccess({
            access_role_admin_in_care_guide:
              this.props.careGuide.partnership.access_role_admin_in_care_guide,
          });
          if (this.props.accessCareGuide) {
            if (hasPermission(VIEW_ACCESS_CARE_GUIDE)) {
              if (!this.props.accessCareGuide?.care_guide) {
                this.setState({ redirect: 'HomeScreen' });
              }
            }
          }
        }
      }
    }
  }
}

export default CareGuideDetailScreen;

Logger.log('silly', `CareGuideDetailScreen loaded.`);
