import React from "react";
import { withRouter } from "react-router";
import { getI18n } from "react-i18next";
import { EllipsisOutlined } from "@ant-design/icons";
import { Button, Cascader } from "antd";


import { pinFilled, pin, edit, deleteIcon } from "../../../elements/components/Icons";

const CardActions = ({ id, is_pinned, handlePinned, handleDelete, showForm}) => {

  const menuItems = [
    {
      label: (
        <>
          {edit(17, 17)} {getI18n().t("care_guide_note_card_action_edit")}
        </>
      ),
      value: 1,
    },
    {
      label: (
        <>
         {is_pinned ? pinFilled(12, 15) : pin(12, 15)} {is_pinned ? getI18n().t("care_guide_note_card_action_un_pin") : getI18n().t("care_guide_note_card_action_pinned")}
        </>
      ),
      value: 2,
    },
    {
      label: (
        <>
          {deleteIcon(14, 16)} {getI18n().t("care_guide_note_card_action_delete")}
        </>
      ),
      value: 3,
    },
  ];

  const onChange = (item) => {

    const value = item[0];
    if(value===1){
      showForm(id)
    }
    else if(value === 2){
      handlePinned(id, is_pinned)
    }else if(value === 3){
      handleDelete(id);
    }

  };

  return (
    <span>
      <Cascader
        options={menuItems}
        onChange={onChange}
        dropdownMenuColumnStyle={{ height: "32px" }}
        defaultValue={[]}
        value={[]}
        placement={"bottomRight"}
      >
        <Button type="text">
          <EllipsisOutlined />
        </Button>
      </Cascader>
    </span>
  );
};

export default withRouter(CardActions);
