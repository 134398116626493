import {List, Map} from 'immutable';

import {TYPES} from './actions';
import {SESSION_DESTROY} from '../../actions';
import Logger from '../../../lib/Logger';

const initialState = Map({
  isLoadingRegions: false,
  isLoadingSubregions: false,
  isLoadingCities: false,
  isLoadingPostalCodes: false,
  regions: {},
  subregions: {},
  cities: {},
  postalCodes: {},
});

export default function locations(
  state=initialState,
  action
) {
  Logger.log('debug', `[state.locations.reducers] locations(%j, %j)`, state, action);

  switch(action.type) {

    case TYPES.LIST_REGIONS_REQUEST:
      return state.mergeDeep({
        isLoadingRegions: true
      });

    case TYPES.LIST_REGIONS_SUCCESS:
      return state.mergeDeep({
        isLoadingRegions: false,
        regions: {
            total: action.total,
            pages: {
              [action.countryCode]: {
                [action.order]: {
                  [action.limit]: {
                    [action.page]: null
                  }
                }
              }
            }
        },
        lastUpdated: action.receivedAt
      }).setIn(['regions', 'pages', action.countryCode, action.order, action.limit, action.page], List(action.result));

    case TYPES.LIST_REGIONS_FAILURE:
      return state.mergeDeep({
        isLoadingRegions: false
      });

    case TYPES.LIST_SUBREGIONS_REQUEST:
      return state.mergeDeep({
        isLoadingSubregions: true
      });

    case TYPES.LIST_SUBREGIONS_SUCCESS:
      return state.mergeDeep({
        isLoadingSubregions: false,
        subregions: {
            total: action.total,
            pages: {
              [action.countryCode]: {
                [action.regionCode]: {
                  [action.order]: {
                    [action.limit]: {
                      [action.page]: null
                    }
                  }
                }
              }
            }
        },
        lastUpdated: action.receivedAt
      }).setIn(['subregions', 'pages', action.countryCode, action.regionCode, action.order, action.limit, action.page], List(action.result));

    case TYPES.LIST_SUBREGIONS_FAILURE:
      return state.mergeDeep({
        isLoadingSubregions: false
      });

    case TYPES.LIST_CITIES_REQUEST:
      return state.mergeDeep({
        isLoadingCities: true
      });

    case TYPES.LIST_CITIES_SUCCESS:
      return state.mergeDeep({
        isLoadingCities: false,
        cities: {
            total: action.total,
            pages: {
              [action.countryCode]: {
                [action.regionCode]: {
                  [action.order]: {
                    [action.limit]: {
                      [action.page]: null
                    }
                  }
                }
              }
            }
        },
        lastUpdated: action.receivedAt
      }).setIn(['cities', 'pages', action.countryCode, action.regionCode, action.order, action.limit, action.page], List(action.result));

    case TYPES.LIST_CITIES_FAILURE:
      return state.mergeDeep({
        isLoadingCities: false
      });

    case TYPES.LIST_POSTAL_CODES_REQUEST:
      return state.mergeDeep({
        isLoadingPostalCodes: true
      });

    case TYPES.LIST_POSTAL_CODES_SUCCESS:
      return state.mergeDeep({
        isLoadingPostalCodes: false,
        postalCodes: {
            total: action.total,
            pages: {
              [action.countryCode]: {
                [action.regionCode]: {
                  [action.parent]: {
                    [action.parentID]: {
                      [action.order]: {
                        [action.limit]: {
                          [action.page]: null
                        }
                      }
                    }
                  }
                }
              }
            }
        },
        lastUpdated: action.receivedAt
      }).setIn(['postalCodes', 'pages', action.countryCode, action.regionCode, action.parent, action.parentID, action.order, action.limit, action.page], List(action.result));

    case TYPES.LIST_POSTAL_CODES_FAILURE:
      return state.mergeDeep({
        isLoadingPostalCodes: false
      });

    case SESSION_DESTROY:
      return initialState;

    default:
      return state;
  }
}

Logger.log('silly', `state.locations.reducers loaded.`);
