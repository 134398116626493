import React, { useEffect, useState } from 'react';
import { Translation } from 'react-i18next';
import { Form, Select } from 'antd';
import { denormalize } from 'normalizr';
import { schema } from '../../../../../state/schema';
import store from '../../../../../state/store';
import { List } from 'immutable';
import useDebounce from '../../../elements/components/useDebounce';

const { Option } = Select;

const CareGuidesPartnership = ({
  form,
  total,
  partnershipId,
  setCareGuideIds,
  name,
  label,
  isLoading,
  isSubmitting,
  handleChange,
  load,
  multiple = false,
  emptyOption = false,
  rules = [],
  careGuidepartnerTags = [],
  ...props
}) => {
  const [query, setQuery] = useState('');
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [lastPage, setLastPage] = useState(1);
  const [filter, setFilter] = useState({});
  const [firstLoad, setFirstLoad] = useState(true);
  const [debouncedTotal, setDebouncedTotal] = useState(0);
  const [useDebouncedTotal, setUseDebouncedTotal] = useState(false);
  const [firstDebouncedQuery, setFirstDebouncedQuery] = useState(true);
  const [result, setResult] = useState(List([]));
  const debouncedQuery = useDebounce(query, 1000);

  const limit = 10;
  const order = 'started_at.asc';
  const state = store.getState();

  const cleanDebounced = () => {
    setFilter({});
    setPage(lastPage);
    setFirstDebouncedQuery(true);
    setUseDebouncedTotal(false);
    setDebouncedTotal(0);
  };

  useEffect(() => {
    if (debouncedQuery.trim() === '') {
      cleanDebounced();
      return;
    } else {
      if (firstDebouncedQuery) {
        setLastPage(page);
      }
      setUseDebouncedTotal(true);
      setFirstDebouncedQuery(false);
      setPage(1);
      setFilter({ search: debouncedQuery });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedQuery]);

  useEffect(() => {
    if (partnershipId) {
      load(partnershipId, page, limit, order, filter, (success, result) => {
        if (success) {
          setResult(List(result));
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, limit, load, filter, partnershipId]);

  useEffect(() => {
    if (careGuidepartnerTags.length > 0) {
      setFilter({
        ...filter,
        exclude_care_guide_partner_tags: careGuidepartnerTags,
      });
      setPage(1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [careGuidepartnerTags]);

  const onScroll = async (event) => {
    const target = event.target;
    let totalCurrent = data.length;

    if (useDebouncedTotal) {
      totalCurrent = debouncedTotal;
    }
    if (
      !isLoading &&
      target.scrollTop + target.offsetHeight === target.scrollHeight &&
      total > totalCurrent
    ) {
      setPage(page + 1);
    }
  };

  useEffect(() => {
    const options = result
      ? result
          .map((x) => {
            return {
              key: x,
              ...denormalize(
                state.entities.getIn(['careGuides', x]),
                schema.careGuide,
                state.entities.toJS()
              ),
            };
          })
          .toArray()
      : [];
    if (useDebouncedTotal) {
      setDebouncedTotal((prevState) => prevState + options.length);
    }
    setData((prevState) => {
      const filteredOptions = options.filter(
        (newOption) =>
          !prevState.some(
            (existingOption) => existingOption.id === newOption.id
          )
      );

      return prevState.concat(filteredOptions);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [result]);

  useEffect(() => {
    if (firstLoad) {
      const care_guides = List(form.getFieldValue(name));
      const options = care_guides
        ? care_guides
            .map((x) => {
              return {
                key: x,
                ...denormalize(
                  state.entities.getIn(['careGuides', x]),
                  schema.careGuide,
                  state.entities.toJS()
                ),
              };
            })
            .toArray()
        : [];
      if (useDebouncedTotal) {
        setDebouncedTotal((prevState) => prevState + options.length);
      }
      setData((prevState) => {
        const filteredOptions = options.filter(
          (newOption) =>
            !prevState.some(
              (existingOption) => existingOption.id === newOption.id
            )
        );

        return prevState.concat(filteredOptions);
      });
      setFirstLoad(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firstLoad]);

  return (
    <Translation>
      {(t) => (
        <div className="form-group">
          <Form.Item
            name={name}
            label={label ? label : t('care_guide_helper_members')}
            rules={rules}
          >
            <Select
              onPopupScroll={onScroll}
              mode={multiple ? 'multiple' : null}
              showSearch
              optionFilterProp="children"
              disabled={isSubmitting}
              loading={isLoading}
              onChange={handleChange}
              maxTagCount={6}
              onSearch={(value) => setQuery(value)}
              onBlur={() => setQuery('')}
            >
              {emptyOption ? (
                <Option key="empty-option" value={null}>
                  {t('form_input_options_none')}
                </Option>
              ) : null}

              {data.map((x) => (
                <Option
                  key={x.id}
                  value={x.id}
                >{`${x.first_name} ${x.last_name} - ${x.owner.profile.first_name} ${x.owner.profile.last_name} - ${x.owner.profile.email}`}</Option>
              ))}

              {isLoading && <Option>{t('form_input_loading')}</Option>}
            </Select>
          </Form.Item>
        </div>
      )}
    </Translation>
  );
};

export default CareGuidesPartnership;
