import {connect} from 'react-redux';

import CalendarToolbar from '../components/CalendarToolbar';
import {calendarShowEventForm} from '../../../../../state/modules/calendar/actions';

const mapStateToProps = (state, ownProps) => {
  return {}
}

const mapDispatchToProps = dispatch => {
  return {
    showForm: (eventId) => {
      dispatch(calendarShowEventForm(eventId));
    },
  }
}

const CalendarToolbarContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(CalendarToolbar);

export default CalendarToolbarContainer;
