import {connect} from 'react-redux';
import {denormalize} from 'normalizr';

import CareGuideInvitationsQuickList from '../components/CareGuideInvitationsQuickList';
import {schema} from '../../../../../state/schema';
import {loadCareGuideInvited} from '../../../../../state/modules/careGuideHelpers/actions';
import {resendCareGuideInvitation} from '../../../../../state/modules/careGuideInvitations/actions';

const mapStateToProps = (state, ownProps) => {

  const careGuideId = ownProps.careGuideId;
  const page = ownProps.page;
  const limit = ownProps.limit;
  const order = ownProps.order;
  const result = state.careGuideHelpers.getIn(['invited', careGuideId, 'pages', order, limit, page]);

  return {
    list: result 
      ? result.map(x => {
          return {
            key: x,
            ...denormalize(
              state.entities.getIn(['careGuideMembers', x]),
              schema.careGuideMember,
              state.entities.toJS()
            )
          };
        }).toArray().map(x => {

          // flatten some nested objects
          return {
            email_address: x.invitation ? x.invitation.email : '',
            ...x
          };
        })
      : [],
    total: state.careGuideHelpers.getIn(['invited', careGuideId, 'total']),
    isLoading: state.careGuideHelpers.get('isInvitedLoading'),
    isResending: state.careGuideInvitations.get('isResending'),
  }
}

const mapDispatchToProps = dispatch => {
  return {
    load: (careGuideId, page, limit, order, filter, cb) => {
      dispatch(loadCareGuideInvited(careGuideId, page, limit, order, filter, cb));
    },
    resend: (careGuideId, invitationId, data, cb) => {
      dispatch(resendCareGuideInvitation(careGuideId, invitationId, data, cb));
    },
  }
}

const CareGuideInvitationsQuickListContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(CareGuideInvitationsQuickList);

export default CareGuideInvitationsQuickListContainer;
