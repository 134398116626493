import {normalize} from 'normalizr';

import api from '../../api';
import {schema} from '../../schema';
import {addEntities} from '../../actions';
import Logger from '../../../lib/Logger';

export const TYPES = {
  LIST_REQUEST: 'CARE_GUIDE_HELPER_TAGS/LIST_REQUEST',
  LIST_SUCCESS: 'CARE_GUIDE_HELPER_TAGS/LIST_SUCCESS',
  LIST_FAILURE: 'CARE_GUIDE_HELPER_TAGS/LIST_FAILURE',
};

export function careGuideHelperTagListRequest(page, limit, order, filter) {
  Logger.log('debug', `[state.careGuideHelperTags.actions] careGuideHelperTagListRequest(${page}, ${limit}, ${order}, %j)`, filter);
  return {
    type: TYPES.LIST_REQUEST,
    page: page,
    limit: limit,
    order: order,
    filter: filter,
  }
}

export function careGuideHelperTagListSuccess(data) {
  Logger.log('debug', `[state.careGuideHelperTags.actions] careGuideHelperTagListSuccess(%j)`, data);
  return {
    type: TYPES.LIST_SUCCESS,
    page: data.page,
    limit: data.limit,
    order: data.order,
    result: data.result,
    total: data.total,
    receivedAt: Date.now()
  }
}

export function careGuideHelperTagListFailure(error) {
  Logger.log('debug', `[state.careGuideHelperTags.actions] careGuideHelperTagListFailure( %j)`, error);
  return {
    type: TYPES.LIST_FAILURE,
    error: error
  }
}


// API THUNK ACTION CREATORS

export function loadcareGuideHelperTags(page=1, limit=10, order=null, filter=null, cb=function(){}) {
  Logger.log('debug', `[state.careGuideHelperTags.actions] loadcareGuideHelperTags(${page}, ${limit}, ${order}, %j, ###)`, filter);

  return async function(dispatch) {
    dispatch(careGuideHelperTagListRequest(page, limit, order, filter));

    // call API
    const response = await api.getCareGuideHelperTags(page, limit, order, filter);
    let success = false;

    // get Care Guide Helper Tags list success
    if (200 === response.get('status')) {

      Logger.log('info', `Get API Care Guide Helper Tags list success. Page: ${page}, Limit: ${limit}, Order: ${order}.`);

      const normalizedEntities = normalize(response.getIn(['data', 'helper_tags']), [schema.careGuideHelperTag]);
      const data = {
        page: response.getIn(['data', 'page']),
        limit: response.getIn(['data', 'limit']),
        order: order,
        total: response.getIn(['data', 'total']),
        result: normalizedEntities.result
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(careGuideHelperTagListSuccess(data));
      success = true;

    } else if (1 === page && 204 === response.get('status')) {

      Logger.log('info', `Get API Care Guide Helper Tags list success [empty]. Page: ${page}, Limit: ${limit}, Order: ${order}.`);
      const data = {
        page: page,
        limit: limit,
        order: order,
        total: 0,
        result: []
      };
      dispatch(careGuideHelperTagListSuccess(data));
      success = true;
      
    // get Care Guide Helper Tags list failure
    } else {
      Logger.log('info', `Get API Care Guide Helper Tags list failure. Page: ${page}, Limit: ${limit}, Order: ${order}.`);
      dispatch(careGuideHelperTagListFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  }
}

Logger.log('silly', `state.careGuideHelperTags.actions loaded.`);
