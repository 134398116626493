import {normalize} from 'normalizr';

import api from '../../api';
import {schema} from '../../schema';
import {addEntities} from '../../actions';
import Logger from '../../../lib/Logger';

export const TYPES = {
  LIST_REQUEST: 'ALERTS/LIST_REQUEST',
  LIST_SUCCESS: 'ALERTS/LIST_SUCCESS',
  LIST_FAILURE: 'ALERTS/LIST_FAILURE',
  PATCH_REQUEST: 'ALERTS/PATCH_REQUEST',
  PATCH_SUCCESS: 'ALERTS/PATCH_SUCCESS',
  PATCH_FAILURE: 'ALERTS/PATCH_FAILURE',
  SET_VIEWING_PAGE: 'ALERTS/SET_VIEWING_PAGE',
};

export function alertListRequest(page, limit, order, filter) {
  Logger.log('debug', `[state.alerts.actions] alertListRequest(${page}, ${limit}, ${order}, %j)`, filter);
  return {
    type: TYPES.LIST_REQUEST,
    page: page,
    limit: limit,
    order: order,
    filter: filter,
  }
}

export function alertListSuccess(data) {
  Logger.log('debug', `[state.alerts.actions] alertListSuccess(%j)`, data);
  return {
    type: TYPES.LIST_SUCCESS,
    page: data.page,
    limit: data.limit,
    order: data.order,
    result: data.result,
    total: data.total,
    new: data.new,
    receivedAt: Date.now()
  }
}

export function alertListFailure(error) {
  Logger.log('debug', `[state.alerts.actions] alertListFailure(%j)`, error);
  return {
    type: TYPES.LIST_FAILURE,
    error: error
  }
}

export function alertPatchRequest(id, data) {
  Logger.log('debug', `[state.alerts.actions] alertPatchRequest(${id}, %j)`, data);
  return {
    type: TYPES.PATCH_REQUEST,
    id: id,
  }
}

export function alertPatchSuccess(data) {
  Logger.log('debug', `[state.alerts.actions] alertPatchSuccess(%j)`, data);
  return {
    type: TYPES.PATCH_SUCCESS,
    id: data.id,
    receivedAt: Date.now()
  }
}

export function alertPatchFailure(error) {
  Logger.log('debug', `[state.alerts.actions] alertPatchFailure(%j)`, error);
  return {
    type: TYPES.PATCH_FAILURE,
    error: error
  }
}

export function alertSetViewingPage(page) {
  Logger.log('debug', `[state.alerts.actions] alertSetViewingPage(${page})`);
  return {
    type: TYPES.SET_VIEWING_PAGE,
    page: page,
  }
}

// API THUNK ACTION CREATORS

export function loadAlerts(page=1, limit=10, order=null, filter=null, cb=function(){}) {
  Logger.log('debug', `[state.alerts.actions] loadAlerts(${page}, ${limit}, ${order}, %j, ###)`, filter);

  return async function(dispatch) {
    dispatch(alertListRequest(page, limit, order, filter));

    // call API
    const response = await api.getAlerts(page, limit, order, filter);
    let success = false;

    // get alerts list success
    if (200 === response.get('status')) {

      Logger.log('info', `Get API alerts list success. Page: ${page}, Limit: ${limit}, Order: ${order}.`);

      const normalizedEntities = normalize(response.getIn(['data', 'alerts']), [schema.alert]);
      const data = {
        page: response.getIn(['data', 'page']),
        limit: response.getIn(['data', 'limit']),
        order: order,
        total: response.getIn(['data', 'total']),
        new: response.getIn(['data', 'new']),
        result: normalizedEntities.result
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(alertListSuccess(data));
      success = true;

    } else if (1 === page && 204 === response.get('status')) {

      Logger.log('info', `Get API alerts list success [empty]. Page: ${page}, Limit: ${limit}, Order: ${order}.`);
      const data = {
        page: page,
        limit: limit,
        order: order,
        total: 0,
        new: 0,
        result: []
      };
      dispatch(alertListSuccess(data));
      success = true;
      
    // get alerts list failure
    } else {
      Logger.log('info', `Get API alerts list failure. Page: ${page}, Limit: ${limit}, Order: ${order}.`);
      dispatch(alertListFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  }
}

export function patchAlert(id, data, cb=function(){}) {
  Logger.log('debug', `[state.alerts.actions] patchAlert(${id}, %j, ###)`, data);

  return async function(dispatch) {
    dispatch(alertPatchRequest(id, data));

    // call API
    const response = await api.patchAlert(id, data);
    let success = false;

    // patch alert success
    if (200 === response.get('status')) {

      Logger.log('info', `PATCH API alert success. ID: ${id}.`);

      const normalizedEntities = normalize([response.getIn(['data', 'alert'])], [schema.alert]);
      const data = {
        id: response.getIn(['data', 'alert', 'id']),
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(alertPatchSuccess(data));
      success = true;
      
    // patch alert failure
    } else {
      Logger.log('info', `PATCH API alert failure. ID: ${id}.`);
      dispatch(alertPatchFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  }
}

Logger.log('silly', `state.alerts.actions loaded.`);
