import { connect } from 'react-redux';
import { List } from 'immutable';

import {
  loadResourceAdmin,
  updateResourceAdmin,
  createResourceAdmin,
  deleteResourceAdmin,
  resourceAdminFormDestroy,
} from '../../../../../state/modules/resourcesAdmin/actions';

import {
  getImageUploadURL,
  uploadImage,
  createMediaImageCareGuidePartnership,
  deleteMediaImagePartnership,
  createMediaImagePartnership,
} from '../../../../../state/modules/media/actions';

import { loadTotalMembers } from '../.../../../../../../state/modules/careGuides/actions';

import ResourceAdminForm from '../components/ResourceAdminForm';
import storage from '../../../../../lib/Storage';

const inputs = List([
  'resources_care_partner_tags',
  'care_guide',
  'care_guides',
  'name',
  'description',
  'telephone',
  'website',
  'email',
  'tags',
  'service_tags',
  'status',
  'is_pinned',
  'logo',
]);

const mapStateToProps = (state, ownProps) => {
  const data = {},
    errors = {};
  for (const val of inputs.values()) {
    data[val] = state.entities.getIn(['resourcesAdmin', ownProps.id, val], '');
    if (state.resourcesAdmin.getIn(['form', 'errors', val])) {
      errors[val] = state.resourcesAdmin.getIn(['form', 'errors', val]);
    }
  }

  Object.keys(data).forEach((x) => {
    // change select number values to strings to work with ant design select inputs
    if (['status'].includes(x)) {
      data[x] = data[x].toString();
    }
  });

  const care_guide = data['care_guide'] ?? null;

  if (Array.isArray(data['care_guides'])) {
    if (data['care_guides'].length < 1) {
      data['care_guides'] = care_guide ? [care_guide] : [];
    }
  } else {
    data['care_guides'] = care_guide ? [care_guide] : [];
  }

  if (data['resources_care_partner_tags'] === '') {
    data['resources_care_partner_tags'] = [];
  }

  if (data['tags'] === '') {
    data['tags'] = [];
  }

  if (data['service_tags'] === '') {
    data['service_tags'] = [];
  }

  return {
    isLoading: state.resourcesAdmin.get('isLoading'),
    isSubmitting: state.resourcesAdmin.getIn(['form', 'isSubmitting']),
    isImageUploadUrlLoading: state.media.get('isImageUploadUrlLoading'),
    isImageUploading: state.media.get('isImageUploading'),
    success: state.resourcesAdmin.getIn(['form', 'success']),
    created_id: state.resourcesAdmin.getIn(['form', 'created_id']),
    totalMembers: state.careGuides.get('totalMembers'),
    totalMembersFiltered: state.careGuides.get('totalMembersFiltered'),
    idsFiltered: state.careGuides.get('idsFiltered'),
    partnershipName: storage.get('local', 'partnershipData')?.name,
    data: data,
    errors: errors,
    createdAt: state.entities.getIn(
      ['resourcesAdmin', ownProps.id, 'created_at'],
      null
    ),
    updatedAt: state.entities.getIn(
      ['resourcesAdmin', ownProps.id, 'updated_at'],
      null
    ),
    partnershipActiveId: storage.get('local', 'partnershipId'),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    load: (partnershipId, id, cb) => {
      dispatch(loadResourceAdmin(partnershipId, id, cb));
    },
    loadTotalMembers: (partnershipId, filter, cb) => {
      dispatch(loadTotalMembers(partnershipId, filter, cb));
    },
    update: (id, data, cb) => {
      dispatch(updateResourceAdmin(id, data, cb));
    },
    create: (data, cb) => {
      dispatch(createResourceAdmin(data, cb));
    },
    delete: (partnershipId, id, cb) => {
      dispatch(deleteResourceAdmin(partnershipId, id, cb));
    },
    destroyForm: (formState) => {
      dispatch(resourceAdminFormDestroy(formState));
    },
    getUploadUrl: (extension, directory, cb) => {
      dispatch(getImageUploadURL(extension, directory, cb));
    },
    upload: (path, file, cb) => {
      dispatch(uploadImage(path, file, cb));
    },
    createImage: (careGuideId, partnershipId, data, cb) => {
      dispatch(
        createMediaImageCareGuidePartnership(
          careGuideId,
          partnershipId,
          data,
          cb
        )
      );
    },
    createImagePartnership: (partnershipId, data, cb) => {
      dispatch(createMediaImagePartnership(partnershipId, data, cb));
    },
    deleteImage: (partnershipId, id, cb) => {
      dispatch(deleteMediaImagePartnership(partnershipId, id, cb));
    },
  };
};

const ResourceAdminFormContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ResourceAdminForm);

export default ResourceAdminFormContainer;
