import React, { Component, createRef } from 'react';
import i18next from 'i18next';
import { Translation } from 'react-i18next';
import { Button, Col, Form, Input, Row, Checkbox } from 'antd';
import { CheckCircleOutlined } from '@ant-design/icons';
import { Colorpicker } from 'antd-colorpicker';

import Logger from '../../../../../lib/Logger';
import message from '../../../elements/lib/MessageWrapper';

class PartnershipAccountForm extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.form = createRef();
  }

  // form column settings
  layout = {
    main: {
      labelCol: { span: 8 },
      wrapperCol: { span: 16 },
    },
    tail: {
      wrapperCol: {
        xs: {
          span: 22,
        },
        sm: {
          offset: 8,
          span: 16,
        },
      },
    },
  };

  // load values from props into inputs
  setFields = () => {
    this.form.current.setFieldsValue({
      name: this.props.data.name,
      color_hex: { hex: this.props.data.color_hex },
      hide_partner_tags: this.props.data.hide_partner_tags,
    });
  };

  // submit data handler
  submitData = async (values) => {
    Logger.log('debug', `PartnershipAccountForm.submitData(###)`);

    // API POST/PUT payload
    let payload = {};
    for (const input of Object.keys(this.props.data)) {
      if (['color_hex'].includes(input)) {
        payload[input] = values.color_hex ? values.color_hex.hex : 'FFFFFF';
      } else if (['hide_partner_tags'].includes(input)) {
        payload[input] = values[input] ? true : false;
      } else if (values[input]) {
        payload[input] = values[input];
      }
    }

    // update
    this.props.submit(this.props.activeId, payload, () => {
      this.parseFeedback(this.props.errors);
      if (this.props.success) {
        message.success(i18next.t('partnership_account_form_message_success'));
      } else {
        message.error(i18next.t('partnership_account_form_message_failure'));
      }
    });
  };

  parseFeedback = (errors, joinChar = ' ') => {
    let firstFieldName = '';
    for (const field in errors) {
      this.form.current.setFields([{ name: field, errors: errors[field] }]);
      if (firstFieldName === '') {
        firstFieldName = field;
      }
    }
    this.form.current.scrollToField(firstFieldName);
  };

  // form submit handler
  handleFinish = async (values) => {
    Logger.log('debug', `PartnershipAccountForm.handleFinish(###)`);
    if (!this.props.isSubmitting) {
      await this.submitData(values);
    }
  };

  // form error handler
  handleFinishFailed = ({ values, errorFields, outOfDate }) => {
    Logger.log('debug', `PartnershipAccountForm.handleFinishFailed(###)`);
    message.error(i18next.t('partnership_account_form_message_failure'));
    this.form.current.scrollToField(errorFields[0].name);
  };

  render() {
    const { isSubmitting, isLoading } = this.props;

    return (
      <Translation>
        {(t) => (
          <div className="partnership-account">
            <Form
              {...this.layout.main}
              name="partnership_account_form"
              onFinish={this.handleFinish}
              onFinishFailed={this.handleFinishFailed}
              ref={this.form}
            >
              <Row>
                <Col offset={this.layout.main.labelCol.span}>
                  <h4>
                    <strong>
                      {t('partnership_account_form_header_account')}
                    </strong>
                  </h4>
                </Col>
              </Row>

              <div className="form-group">
                <Form.Item
                  name="name"
                  label={t('partnership_account_form_input_name')}
                  rules={[
                    {
                      required: true,
                      message: t('feedback_validation_required'),
                    },
                    {
                      min: 2,
                      max: 20,
                      message: t('feedback_validation_length', {
                        min: 2,
                        max: 20,
                      }),
                    },
                    {
                      pattern: /^\w+$/,
                      message: t('feedback_validation_alphanumeric'),
                    },
                    {
                      pattern: /(?!^\d+$)^.+$/,
                      message: t('feedback_validation_not_number'),
                    },
                  ]}
                >
                  <Input disabled={isLoading || isSubmitting} />
                </Form.Item>
              </div>

              <div className="form-actions">
                <Form.Item
                  name={`color_hex`}
                  label={t('partnership_account_form_input_color')}
                >
                  <Colorpicker popup />
                </Form.Item>
              </div>

              <div className="form-group">
                <Form.Item
                  name="hide_partner_tags"
                  label={t('partnership_account_form_input_hide_tag')}
                  valuePropName="checked"
                >
                  <Checkbox></Checkbox>
                </Form.Item>
              </div>

              <div className="form-actions">
                <Form.Item {...this.layout.tail}>
                  <Button
                    type="primary"
                    htmlType="submit"
                    icon={<CheckCircleOutlined />}
                    loading={isSubmitting}
                  >
                    {t('partnership_account_form_button_submit')}
                  </Button>
                </Form.Item>
              </div>
            </Form>
          </div>
        )}
      </Translation>
    );
  }

  /* LIFECYCLE EVENTS */
  componentDidMount() {
    Logger.log('silly', `PartnershipAccountForm.componentDidMount()`);

    // initialize data from props (via the store)
    this.setFields();

    // initialize data from API
    this.props.load(this.props.activeId, () => {
      this.setFields();
    });
  }

  componentDidUpdate(prevProps) {
    Logger.log('silly', `PartnershipAccountForm.componentDidUpdate()`);

    if (this.props.activeId !== prevProps.activeId) {
      // initialize data from API
      this.props.load(this.props.activeId, () => {
        // initialize data from props (via the store)
        this.setFields();
      });
    }
  }

  componentWillUnmount() {
    Logger.log('silly', `PartnershipAccountForm.componentWillUnmount()`);
    this.props.formDestroy();
  }
}

export default PartnershipAccountForm;

Logger.log('silly', `PartnershipAccountForm loaded.`);
