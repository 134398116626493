import {connect} from 'react-redux';

import AdminScreen from '../components/AdminScreen';
import {uiChangeMenuAdminCollapsed} from '../../../../../state/ui/actions';
import storage from "../../../../../lib/Storage";

const mapStateToProps = (state, ownProps) => {
  //Is active partnerships o chooser
  return {
    isLoading: state.messages.get('isLoading'),
    isMenuAdminCollapsed: state.ui.get('isMenuAdminCollapsed'),
    updatePassword: state.session.get('updatePassword'),
    accessTabs: storage.get('local', 'partnershipData')?.access_tabs_in_app,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    uiChangeMenuAdminCollapsed: () => {
      dispatch(uiChangeMenuAdminCollapsed());
    }
  }
}

const AdminScreenContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminScreen);

export default AdminScreenContainer;