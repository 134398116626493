import { connect } from 'react-redux';
import { denormalize } from 'normalizr';

import CareGuidesSelector from '../components/CareGuidesSelector';
import { schema } from '../../../../../state/schema';
import {
  loadCareGuides,
  careGuideSetActive,
  careGuideSetAccess,
} from '../../../../../state/modules/careGuides/actions';
import hasPermission, {
  VIEW_SELECTOR_CARE_GUIDE,
} from '../../../elements/lib/hasPermission';

export const careGuideScreens = [
  'DashboardDetailScreen',
  'CareGuideDetailScreen',
  'CareGuideEditScreen',
  'CareGuideHelpersScreen',
  'JournalPostsScreen',
  'CalendarScreen',
  'ResourcesScreen',
  'CareTeamScreen',
];

const mapStateToProps = (state, ownProps) => {
  const isViewSelector = hasPermission(VIEW_SELECTOR_CARE_GUIDE);

  const page = state.careGuides.get('activePage') ?? 1;
  const limit = 10;
  const order = 'started_at.asc';
  const result = isViewSelector
    ? state.careGuides.getIn(['pages', order, limit, page])
    : null;
  let defaultId = state.careGuides.get('activeId');

  const defaultLabel = defaultId
    ? state.entities.getIn(['careGuides', defaultId, 'first_name'], '') +
      ' ' +
      state.entities.getIn(['careGuides', defaultId, 'last_name'], '')
    : null;

  const defaultImage = defaultId
    ? state.entities.getIn(['careGuides', defaultId, 'image'])
    : null;

  const profileId = state.session.get('profileId');

  const list = result
    ? result
        .map((x) => {
          return {
            key: x,
            ...denormalize(
              state.entities.getIn(['careGuides', x]),
              schema.careGuide,
              state.entities.toJS()
            ),
          };
        })
        .toArray()
        .filter(
          (x) =>
            x.account &&
            (x.account.is_subscription_valid ||
              x.account.owner_id === profileId)
        )
    : [];

  return {
    list,
    total: state.careGuides.get('total'),
    isLoading: state.careGuides.get('isLoading'),
    defaultId: defaultId,
    defaultLabel: defaultLabel,
    defaultImage: defaultImage,
    careGuideScreens: careGuideScreens,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    load: (page, limit, order, filter, partnershipId, cb) => {
      dispatch(loadCareGuides(page, limit, order, filter, partnershipId, cb));
    },
    setActive: (id) => {
      dispatch(careGuideSetActive(id));
    },
    setAccess: (data) => {
      dispatch(careGuideSetAccess(data));
    },
  };
};

const CareGuidesSelectorContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(CareGuidesSelector);

export default CareGuidesSelectorContainer;
