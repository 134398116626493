import { Map } from 'immutable';
import { TYPES } from './actions';
import { SESSION_DESTROY } from '../../actions';
import Logger from '../../../lib/Logger';

const initialState = Map({
  isLoading: false,
  isSubmitting: false,
  isSubmittingLogo: false,
  isDeletingLogo: false,
  isSubmittingAccess: false,
  isSubmittingAccessCareGuide: false,
  data: {},
  form: {},
  accessForm: {},
  accessCareGuideForm: {},
});

export default function partnershipAccount(state = initialState, action) {
  Logger.log(
    'debug',
    `[state.partnershipAccount.reducers] partnershipAccount(%j, %j)`,
    state,
    action
  );

  switch (action.type) {
    case TYPES.PARTNERSHIP_ACCOUNT_READ_REQUEST:
      return state.mergeDeep({
        isLoading: true,
      });

    case TYPES.PARTNERSHIP_ACCOUNT_READ_SUCCESS:
      return state.mergeDeep({
        isLoading: false,
        data: {
          name: action.name,
          color_hex: action.color_hex,
          hide_partner_tags: action.hide_partner_tags,
          access_role_admin_in_app: action.access_role_admin_in_app,
          access_role_admin_in_care_guide:
            action.access_role_admin_in_care_guide,
          access_tabs_in_app: action.access_tabs_in_app,
          logo: action.logo,
        },
      });

    case TYPES.PARTNERSHIP_ACCOUNT_READ_FAILURE:
      return state.mergeDeep({
        isLoading: false,
      });

    case TYPES.PARTNERSHIP_ACCOUNT_UPDATE_REQUEST:
      return state.mergeDeep({
        isSubmitting: true,
        form: null,
      });

    case TYPES.PARTNERSHIP_ACCOUNT_UPDATE_SUCCESS:
      return state.mergeDeep({
        isSubmitting: false,
        data: {
          name: action.name,
          color_hex: action.color_hex,
          hide_partner_tags: action.hide_partner_tags,
          access_role_admin_in_app: action.access_role_admin_in_app,
          access_role_admin_in_care_guide:
            action.access_role_admin_in_care_guide,
          access_tabs_in_app: action.access_tabs_in_app,
          logo: action.logo,
        },
        form: {
          success: true,
          errors: null,
        },
        lastUpdated: action.receivedAt,
      });

    case TYPES.PARTNERSHIP_ACCOUNT_UPDATE_FAILURE:
      return state
        .mergeDeep({
          isSubmitting: false,
          form: {
            success: false,
            errors: null,
          },
        })
        .setIn(['form', 'errors'], action.error);

    case TYPES.PARTNERSHIP_ACCOUNT_UPDATE_AVATAR_REQUEST:
      return state.mergeDeep({
        isSubmittingLogo: true,
      });

    case TYPES.PARTNERSHIP_ACCOUNT_UPDATE_AVATAR_SUCCESS:
      return state.mergeDeep({
        isSubmittingLogo: false,
        data: {
          name: action.name,
          color_hex: action.color_hex,
          hide_partner_tags: action.hide_partner_tags,
          access_role_admin_in_app: action.access_role_admin_in_app,
          access_role_admin_in_care_guide:
            action.access_role_admin_in_care_guide,
          access_tabs_in_app: action.access_tabs_in_app,
          logo: action.logo,
        },
        lastUpdated: action.receivedAt,
      });

    case TYPES.PARTNERSHIP_ACCOUNT_UPDATE_AVATAR_FAILURE:
      return state.mergeDeep({
        isSubmittingLogo: false,
      });

    case TYPES.PARTNERSHIP_ACCOUNT_DELETE_AVATAR_REQUEST:
      return state.mergeDeep({
        isDeletingLogo: true,
      });

    case TYPES.PARTNERSHIP_ACCOUNT_DELETE_AVATAR_SUCCESS:
      return state.mergeDeep({
        isDeletingLogo: false,
        data: {
          name: action.name,
          color_hex: action.color_hex,
          hide_partner_tags: action.hide_partner_tags,
          access_role_admin_in_app: action.access_role_admin_in_app,
          access_role_admin_in_care_guide:
            action.access_role_admin_in_care_guide,
          access_tabs_in_app: action.access_tabs_in_app,
          logo: action.logo,
        },
        lastUpdated: action.receivedAt,
      });

    case TYPES.PARTNERSHIP_ACCOUNT_DELETE_AVATAR_FAILURE:
      return state.mergeDeep({
        isDeletingLogo: false,
      });

    case TYPES.PARTNERSHIP_ACCOUNT_FORM_DESTROY:
      return state
        .mergeDeep({
          form: null,
        })
        .set('form', action.form);

    case TYPES.PARTNERSHIP_ACCOUNT_ACCESS_ADMIN_UPDATE_REQUEST:
      return state.mergeDeep({
        data: {
          access_role_admin_in_app: action.access_role_admin_in_app,
        },
        isSubmittingAccess: true,
        accessForm: null,
      });

    case TYPES.PARTNERSHIP_ACCOUNT_ACCESS_ADMIN_UPDATE_SUCCESS:
      return state.mergeDeep({
        isSubmittingAccess: false,
        data: {
          access_role_admin_in_app: action.access_role_admin_in_app,
        },
        accessForm: {
          success: true,
          errors: null,
        },
        lastUpdated: action.receivedAt,
      });

    case TYPES.PARTNERSHIP_ACCOUNT_ACCESS_ADMIN_UPDATE_FAILURE:
      return state
        .mergeDeep({
          isSubmittingAccess: false,
          accessForm: {
            success: false,
            errors: null,
          },
        })
        .setIn(['accessForm', 'errors'], action.error);

    case TYPES.PARTNERSHIP_ACCOUNT_ACCESS_CARE_GUIDE_UPDATE_REQUEST:
      return state.mergeDeep({
        data: {
          access_role_admin_in_care_guide:
            action.access_role_admin_in_care_guide,
        },
        isSubmittingAccessCareGuide: true,
        accessCareGuideForm: null,
      });

    case TYPES.PARTNERSHIP_ACCOUNT_ACCESS_CARE_GUIDE_UPDATE_SUCCESS:
      return state.mergeDeep({
        isSubmittingAccessCareGuide: false,
        data: {
          access_role_admin_in_care_guide:
            action.access_role_admin_in_care_guide,
        },
        accessCareGuideForm: {
          success: true,
          errors: null,
        },
        lastUpdated: action.receivedAt,
      });

    case TYPES.PARTNERSHIP_ACCOUNT_ACCESS_CARE_GUIDE_UPDATE_FAILURE:
      return state
        .mergeDeep({
          isSubmittingAccessCareGuide: false,
          accessCareGuideForm: {
            success: false,
            errors: null,
          },
        })
        .setIn(['accessCareGuideForm', 'errors'], action.error);

    case TYPES.PARTNERSHIP_ACCOUNT_ACCESS_ADMIN_FORM_DESTROY:
      return state
        .mergeDeep({
          accessForm: null,
        })
        .set('accessForm', action.form);

    case TYPES.PARTNERSHIP_ACCOUNT_ACCESS_CARE_GUIDE_FORM_DESTROY:
      return state
        .mergeDeep({
          accessCareGuideForm: null,
        })
        .set('accessCareGuideForm', action.form);

    case SESSION_DESTROY:
      return initialState;

    default:
      return state;
  }
}

Logger.log('silly', `state.partnershipAccount.reducers loaded.`);
