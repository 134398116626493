import {connect} from 'react-redux';
import {denormalize} from 'normalizr';

import {schema} from '../../../../../state/schema';
import {deleteSubscription} from '../../../../../state/modules/payments/actions';
import SubscriptionCancelForm from '../components/SubscriptionCancelForm';

const mapStateToProps = (state, ownProps) => {

  const activeSubscriptionId = state.payments.get('activeSubscriptionId');

  // get subscription from state
  let subscription = null;
  if (activeSubscriptionId) {
    subscription = denormalize(
      state.entities.getIn(['subscriptions', activeSubscriptionId]),
      schema.subscription,
      state.entities.toJS()
    );
  }

  return {
    isDeleting: state.payments.get('isDeletingSubscription'),
    subscription: subscription,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    deleteSubscription: (id, cb) => {
      dispatch(deleteSubscription(id, cb));
    },
  }
}

const SubscriptionCancelFormContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(SubscriptionCancelForm);

export default SubscriptionCancelFormContainer;
