import CareGuideNoteFormModal from '../components/CareGuideNoteFormModal';
import { schema } from '../../../../../state/schema';
import { List } from 'immutable';
import { careGuideNoteFormDestroy, careGuideNoteHideForm, createCareGuideNote, updateCareGuideNote, loadCareGuideNotes, careGuideNoteShowForm } from "../../../../../state/modules/careGuideNotes/actions";
import { denormalize } from 'normalizr';
import { connect } from 'react-redux';

const inputs = List([
  'body',
  'is_pinned'
]);

const mapStateToProps = (state, ownProps) => {

  const errors = {};
  for (const val of inputs.values()) {
    if (state.careGuideNotes.getIn(['form', 'errors', val])) {
      errors[val] = state.careGuideNotes.getIn(['form', 'errors', val]);
    }
  }

  const careGuideNoteId = state.careGuideNotes.get('careGuideNoteformId');

  const careGuideNote = careGuideNoteId
    ? denormalize(
      state.entities.getIn(['careGuideNotes', careGuideNoteId]),
      schema.careGuideNote,
      state.entities.toJS()
    )
    : null;

  const data = {
    body: careGuideNote ? careGuideNote.body : '',
    is_pinned: careGuideNote ? careGuideNote.is_pinned : false,
  };

  return {
    visible: state.careGuideNotes.get('careGuideNoteFormIsVisible'),
    isSubmitting: state.careGuideNotes.getIn(['form', 'isSubmitting']),
    success: state.careGuideNotes.getIn(['form', 'success']),
    careGuideNoteId: careGuideNoteId,
    careGuideNote: careGuideNote,
    data: data,
    errors: errors,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    formDestroy: (formState) => {
      dispatch(careGuideNoteFormDestroy(formState));
    },
    hideForm: () => {
      dispatch(careGuideNoteHideForm());
    },
    create: (careGuideId, data, cb) => {
      dispatch(createCareGuideNote(careGuideId, data, cb));
    },
    load: (careGuideId, page, limit, order, filter, cb) => {
      dispatch(loadCareGuideNotes(careGuideId, page, limit, order, filter, cb));
    },
    update: (careGuideId, id, data, cb) => {
      dispatch(updateCareGuideNote(careGuideId, id, data, cb));
    },
    showForm: (careGuideNoteId) => {
      dispatch(careGuideNoteShowForm(careGuideNoteId));
    },
  }
}

const CareGuideNoteFormModalContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(CareGuideNoteFormModal);

export default CareGuideNoteFormModalContainer;
