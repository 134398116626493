import React, {useState, useEffect} from 'react';
import {Translation, getI18n} from 'react-i18next';
import {Comment} from 'antd';
// import {BiBox, BiReply, BiReplyAll, BiTrash, BiUndo} from "react-icons/bi";
import {BiBox, BiUndo} from "react-icons/bi";

import UserAvatar from '../../../elements/components/UserAvatar';
import messageWrapper from '../../../elements/lib/MessageWrapper';
import Format from '../../../../../lib/Format';
import Logger from '../../../../../lib/Logger';


const MessageDetail = ({message, currentScreen, defaultOpen=false, ...props}) => {

  // const [open, setOpen] = useState(defaultOpen);
  const [visible, setVisible] = useState(true);

  // const onClickReply = () => {
  //   props.showForm(message.thread_id, message.profile ? [message.profile.id] : null);
  // }

  // const onClickReplyAll = () => {
  //   const fromId = message.profile ? [message.profile.id] : [];
  //   const toIds = message.recipients.filter(x => x.profile.id !== props.profileId).map(x => x.profile.id);
  //   const recicpientIds = [...new Set([...fromId, ...toIds])]
  //   props.showForm(message.thread_id, recicpientIds);
  // }

  const onClickRestore = () => {
    patchStatus(1, 'messages_feedback_restore_success', 'messages_feedback_restore_error');
  }

  const onClickArchive = () => {
    patchStatus(2, 'messages_feedback_archive_success', 'messages_feedback_archive_error');
  }

  // const onClickDelete = () => {
  //   patchStatus(3, 'messages_feedback_delete_success', 'messages_feedback_delete_error');
  // }

  const patchStatus = (status, successMessage, errorMessage) => {

    // recipients
    if (!props.isRecipientFormSubmitting) {
      const filteredRecipient = message.recipients.filter(x => x.profile.id === props.profileId);
      if (filteredRecipient.length > 0) {
        props.patchRecipient(message.id, filteredRecipient[0].id, {public_status: status}, (success) => {
          if (success) {
            setVisible(false);
            messageWrapper.success(getI18n().t(successMessage));
            props.load(1, 25, 'sent_at.desc');
          } else {
            messageWrapper.error(getI18n().t(errorMessage));
          }
        });
      }
    }

    // messages owners
    if (!props.isSubmitting) {
      if (message.profile.id === props.profileId) {
        props.patchMessage(message.id, {public_status: status}, (success) => {
          if (success) {
            setVisible(false);
            messageWrapper.success(getI18n().t(successMessage));
            props.load(1, 25, 'sent_at.desc');
          } else {
            messageWrapper.error(getI18n().t(errorMessage));
          }
        });
      }
    }
  }

  const setIsRead = () => {
    // item.messages.filter(x => x.recipients.filter(y => y.profile.id === props.profileId && y.is_read === false).length > 0).length
    const myRecipient = message.recipients.filter(x => x.profile.id === props.profileId && x.is_read === false);
    if (myRecipient.length > 0) {
      props.patchRecipient(message.id, myRecipient[0].id, {is_read: true}, (success) => {
        if (success) {
          props.loadCounts();
        }
      });
    }
    
    // props.patchMessage(message.id, {is_read: true});
  }

  useEffect(() => {
    setIsRead();
  });

  // const actions = [
  //   <span key="action-reply" onClick={onClickReply}><BiReply /> {getI18n().t('action_reply')}</span>,
  //   <span key="action-reply-all" onClick={onClickReplyAll}><BiReplyAll /> {getI18n().t('action_reply_all')}</span>
  // ];

  const actions = [];
  actions.push(currentScreen === 'MessagesArchivedThreadScreen'
    ? <span key="action-unarchive" onClick={onClickRestore}><BiUndo /> {getI18n().t('action_restore')}</span>
    : <span key="action-archive" onClick={onClickArchive}><BiBox /> {getI18n().t('action_archive')}</span>);

  // actions.push(currentScreen === 'MessagesTrashThreadScreen'
  //   ? <span key="action-undelete" onClick={onClickRestore}><BiUndo /> {getI18n().t('action_restore')}</span>
  //   : <span key="action-delete" onClick={onClickDelete}><BiTrash /> {getI18n().t('action_delete')}</span>);

  return (
    <Translation>{(t) => 
      <>
        {message && visible
          ? <Comment
              key={message.id}
              className="message-detail"
              actions={actions}
              author={(message.profile ? message.profile.first_name + ' ' + message.profile.last_name : '')}
              avatar={<UserAvatar profile={message.profile} showTooltp={false} />}
              content={
                <>
                  {/* <div className="message-to">To: {message.recipients.map(x => x.profile.first_name + ' ' + x.profile.last_name).join(', ')}</div> */}
                  <div className="message-body" dangerouslySetInnerHTML={{__html: message.body}} />
                </>}
              datetime={Format.date(message.sent_at, 'MMM D, YYYY [at] h:mm A')}
            />
          : null}
      </>
    }</Translation>
  )  
}

export default MessageDetail;

Logger.log('silly', `MessageDetail loaded.`);
