import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { Translation } from 'react-i18next';
import { Col, Row } from 'antd';

import { pathTo } from '../../../Routes';
import Logger from '../../../../../lib/Logger';
import LoginForm from '../containers/LoginFormContainer';
import DocumentHead from '../../../elements/components/DocumentHead';
import Config from '../../../../../Config';
import imageLogo from '../../../assets/images/logo-300x300.png';
import '../styles/Login.less';

class LoginScreen extends Component {
  render() {
    // support redirecting back to attempted (authenticated) URL, or just default dashboard
    const { from } = this.props.location.state || {
      from: { pathname: pathTo(Config.get('DEFAULT_LOGIN_REDIRECT')) },
    };

    if (this.props.isAuthenticated) {
      return <Redirect to={from} />;
    }

    return (
      <Translation>
        {(t) => (
          <div className="screen screen-public screen-login">
            <DocumentHead title={t('session_route_login')} />

            <div className="container-form-login">
              <Row type="flex" justify="space-around" align="middle">
                <Col xs={22} sm={18} md={16} lg={14} xl={8}>
                  <div class="outer-container">
                    <div class="inner-container">
                      <img
                        src={imageLogo}
                        height="42"
                        alt={t('app_name')}
                        className="auth-logo "
                      />
                      <LoginForm location={this.props.location} />
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        )}
      </Translation>
    );
  }

  /* LIFECYCLE EVENTS */

  componentDidMount() {
    Logger.log('silly', `LoginScreen.componentDidMount()`);
    if (typeof window !== 'undefined') {
      window.scrollTo(0, 0);
    }
  }

  componentDidUpdate() {
    Logger.log('silly', `LoginScreen.componentDidUpdate()`);
  }

  componentWillUnmount() {
    Logger.log('silly', `LoginScreen.componentWillUnmount()`);
  }
}

export default LoginScreen;

Logger.log('silly', `LoginScreen loaded.`);
