import {normalize} from 'normalizr';

import api from '../../api';
import {schema} from '../../schema';
import {addEntities} from '../../actions';
import Logger from '../../../lib/Logger';

export const TYPES = {
  LIST_REQUEST: 'JOURNAL_REPLIES/LIST_REQUEST',
  LIST_SUCCESS: 'JOURNAL_REPLIES/LIST_SUCCESS',
  LIST_FAILURE: 'JOURNAL_REPLIES/LIST_FAILURE',
  READ_REQUEST: 'JOURNAL_REPLIES/READ_REQUEST',
  READ_SUCCESS: 'JOURNAL_REPLIES/READ_SUCCESS',
  READ_FAILURE: 'JOURNAL_REPLIES/READ_FAILURE',
  CREATE_REQUEST: 'JOURNAL_REPLIES/CREATE_REQUEST',
  CREATE_SUCCESS: 'JOURNAL_REPLIES/CREATE_SUCCESS',
  CREATE_FAILURE: 'JOURNAL_REPLIES/CREATE_FAILURE',
  PATCH_REQUEST: 'JOURNAL_REPLIES/PATCH_REQUEST',
  PATCH_SUCCESS: 'JOURNAL_REPLIES/PATCH_SUCCESS',
  PATCH_FAILURE: 'JOURNAL_REPLIES/PATCH_FAILURE',
  FORM_DESTROY: 'JOURNAL_REPLIES/FORM_DESTROY',
  SHOW_FORM: 'JOURNAL_REPLIES/SHOW_FORM',
  HIDE_FORM: 'JOURNAL_REPLIES/HIDE_FORM',
};

export function journalReplyListRequest(careGuideId, journalPostId, page, limit, order, filter) {
  Logger.log('debug', `[state.journalReplies.actions] journalReplyListRequest(${careGuideId}, ${journalPostId}, ${page}, ${limit}, ${order}, %j)`, filter);
  return {
    type: TYPES.LIST_REQUEST,
    careGuideId: careGuideId,
    journalPostId: journalPostId,
    page: page,
    limit: limit,
    order: order,
    filter: filter,
  }
}

export function journalReplyListSuccess(careGuideId, journalPostId, data) {
  Logger.log('debug', `[state.journalReplies.actions] journalReplyListSuccess(${careGuideId}, ${journalPostId}, %j)`, data);
  return {
    type: TYPES.LIST_SUCCESS,
    careGuideId: careGuideId,
    journalPostId: journalPostId,
    page: data.page,
    limit: data.limit,
    order: data.order,
    result: data.result,
    total: data.total,
    receivedAt: Date.now()
  }
}

export function journalReplyListFailure(careGuideId, journalPostId, error) {
  Logger.log('debug', `[state.journalReplies.actions] journalReplyListFailure(${careGuideId}, ${journalPostId}, %j)`, error);
  return {
    type: TYPES.LIST_FAILURE,
    careGuideId: careGuideId,
    journalPostId: journalPostId,
    error: error
  }
}

export function journalReplyReadRequest(careGuideId, journalPostId, id) {
  Logger.log('debug', `[state.journalReplies.actions] journalReplyReadRequest(${careGuideId}, ${journalPostId}, ${id})`);
  return {
    type: TYPES.READ_REQUEST,
    careGuideId: careGuideId,
    journalPostId: journalPostId,
    id: id
  }
}

export function journalReplyReadSuccess(careGuideId, journalPostId, id, data) {
  Logger.log('debug', `[state.journalReplies.actions] journalReplyReadSuccess(${careGuideId}, ${journalPostId}, ${id}, %j)`, data);
  return {
    type: TYPES.READ_SUCCESS,
    careGuideId: careGuideId,
    journalPostId: journalPostId,
    id: data.id,
    receivedAt: Date.now()
  }
}

export function journalReplyReadFailure(careGuideId, journalPostId, id, error) {
  Logger.log('debug', `[state.journalReplies.actions] journalReplyReadFailure(${careGuideId}, ${journalPostId}, ${id}, %j)`, error);
  return {
    type: TYPES.READ_FAILURE,
    careGuideId: careGuideId,
    journalPostId: journalPostId,
    id: id,
    error: error
  }
}

export function journalReplyCreateRequest(careGuideId, journalPostId, data) {
  Logger.log('debug', `[state.journalReplies.actions] journalReplyCreateRequest(${careGuideId}, ${journalPostId}, %j)`, data);
  return {
    type: TYPES.CREATE_REQUEST,
    careGuideId: careGuideId,
    journalPostId: journalPostId,
  }
}

export function journalReplyCreateSuccess(careGuideId, journalPostId, data) {
  Logger.log('debug', `[state.journalReplies.actions] journalReplyCreateSuccess(${careGuideId}, ${journalPostId}, %j)`, data);
  return {
    type: TYPES.CREATE_SUCCESS,
    careGuideId: careGuideId,
    journalPostId: journalPostId,
    id: data.id,
    receivedAt: Date.now()
  }
}

export function journalReplyCreateFailure(careGuideId, journalPostId, error) {
  Logger.log('debug', `[state.journalReplies.actions] journalReplyCreateFailure(${careGuideId}, ${journalPostId}, %j)`, error);
  return {
    type: TYPES.CREATE_FAILURE,
    careGuideId: careGuideId,
    journalPostId: journalPostId,
    error: error
  }
}

export function journalReplyPatchRequest(careGuideId, journalPostId, id, data) {
  Logger.log('debug', `[state.journalReplies.actions] journalReplyPatchRequest(${careGuideId}, ${journalPostId}, ${id}, %j)`, data);
  return {
    type: TYPES.PATCH_REQUEST,
    careGuideId: careGuideId,
    journalPostId: journalPostId,
    id: id,
  }
}

export function journalReplyPatchSuccess(careGuideId, journalPostId, id, data) {
  Logger.log('debug', `[state.journalReplies.actions] journalReplyPatchSuccess(${careGuideId}, ${journalPostId}, ${id}, %j)`, data);
  return {
    type: TYPES.PATCH_SUCCESS,
    careGuideId: careGuideId,
    journalPostId: journalPostId,
    id: id,
    receivedAt: Date.now()
  }
}

export function journalReplyPatchFailure(careGuideId, journalPostId, id, error) {
  Logger.log('debug', `[state.journalReplies.actions] journalReplyPatchFailure(${careGuideId}, ${journalPostId}, ${id}, %j)`, error);
  return {
    type: TYPES.PATCH_FAILURE,
    careGuideId: careGuideId,
    journalPostId: journalPostId,
    id: id,
    error: error
  }
}

export function journalReplyFormDestroy(formState=null) {
  Logger.log('debug', `[state.journalReplies.actions] journalReplyFormDestroy(%j)`, formState);
  return {
    type: TYPES.FORM_DESTROY,
    form: formState
  }
}

export function journalReplyShowForm(postId=null, replyId=null) {
  Logger.log('debug', `[state.journalReplies.actions] journalReplyShowForm(${postId}, ${replyId})`);
  return {
    type: TYPES.SHOW_FORM,
    postId: postId,
    replyId: replyId,
  }
}

export function journalReplyHideForm() {
  Logger.log('debug', `[state.journalReplies.actions] journalReplyHideForm()`);
  return {
    type: TYPES.HIDE_FORM,
  }
}


// API THUNK ACTION CREATORS

export function loadJournalReplies(careGuideId, journalPostId, page=1, limit=10, order=null, filter=null, cb=function(){}) {
  Logger.log('debug', `[state.journalReplies.actions] loadJournalReplies(${careGuideId}, ${journalPostId}, ${page}, ${limit}, ${order}, %j, ###)`, filter);

  return async function(dispatch) {
    dispatch(journalReplyListRequest(careGuideId, journalPostId, page, limit, order, filter));

    // call API
    const response = await api.getCareGuideJournalPostReplies(careGuideId, journalPostId, page, limit, order, filter);
    let success = false;
    let total = 0;

    // get journal replies list success
    if (200 === response.get('status')) {

      Logger.log('info', `Get API journal replies list success. Care Guide ID: ${careGuideId}, Post ID: ${journalPostId}, Page: ${page}, Limit: ${limit}, Order: ${order}.`);

      const normalizedEntities = normalize(response.getIn(['data', 'journal_replies']), [schema.journalReply]);
      const data = {
        page: response.getIn(['data', 'page']),
        limit: response.getIn(['data', 'limit']),
        order: order,
        total: response.getIn(['data', 'total']),
        result: normalizedEntities.result
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(journalReplyListSuccess(careGuideId, journalPostId, data));
      success = true;
      total = response.getIn(['data', 'total']);

    } else if (1 === page && 204 === response.get('status')) {

      Logger.log('info', `Get API journal replies list success [empty]. Care Guide ID: ${careGuideId}, Post ID: ${journalPostId}, Page: ${page}, Limit: ${limit}, Order: ${order}.`);
      const data = {
        page: page,
        limit: limit,
        order: order,
        total: 0,
        result: []
      };
      dispatch(journalReplyListSuccess(careGuideId, journalPostId, data));
      success = true;
      
    // get journal replies list failure
    } else {
      Logger.log('info', `Get API journal replies list failure. Care Guide ID: ${careGuideId}, Post ID: ${journalPostId}, Page: ${page}, Limit: ${limit}, Order: ${order}.`);
      dispatch(journalReplyListFailure(careGuideId, journalPostId, response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success, total);
  }
}

export function loadJournalReply(careGuideId, journalPostId, id, cb=function(){}) {
  Logger.log('debug', `[state.journalReplies.actions] loadJournalReply(${careGuideId}, ${journalPostId}, ${id}, ###)`);

  return async function(dispatch) {
    dispatch(journalReplyReadRequest(careGuideId, journalPostId, id));

    // call API
    const response = await api.getCareGuideJournalPostReply(careGuideId, journalPostId, id);
    let success = false;

    // get journal reply success
    if (200 === response.get('status')) {

      Logger.log('info', `Get API journal reply success. Care Guide ID: ${careGuideId}, Post ID: ${journalPostId}, ID: ${id}.`);

      const normalizedEntities = normalize([response.getIn(['data', 'journal_reply'])], [schema.journalReply]);
      const data = {
        id: response.getIn(['data', 'journal_reply', 'id']),
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(journalReplyReadSuccess(careGuideId, journalPostId, id, data));
      success = true;
      
    // get journal reply failure
    } else {
      Logger.log('info', `Get API journal reply failure. Care Guide ID: ${careGuideId}, Post ID: ${journalPostId}, ID: ${id}.`);
      dispatch(journalReplyReadFailure(careGuideId, journalPostId, id, response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  }
}

export function createJournalReply(careGuideId, journalPostId, data, cb=function(){}) {
  Logger.log('debug', `[state.journalReplies.actions] createJournalReply(${careGuideId}, ${journalPostId}, %j, ###)`, data);

  return async function(dispatch) {
    dispatch(journalReplyCreateRequest(careGuideId, journalPostId, data));

    // call API
    const response = await api.postCareGuideJournalPostReplies(careGuideId, journalPostId, data);
    let success = false;

    // post journal reply success
    if (201 === response.get('status')) {

      Logger.log('info', `POST API journal reply success. Care Guide ID: ${careGuideId}, Post ID: ${journalPostId}.`);

      const normalizedEntities = normalize([response.getIn(['data', 'journal_reply'])], [schema.journalReply]);
      const data = {
        id: response.getIn(['data', 'journal_reply', 'id']),
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(journalReplyCreateSuccess(careGuideId, journalPostId, data));
      success = true;
      
    // post journal reply failure
    } else {
      Logger.log('info', `POST API journal reply failure. Care Guide ID: ${careGuideId}, Post ID: ${journalPostId}.`);
      dispatch(journalReplyCreateFailure(careGuideId, journalPostId, response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  }
}

export function patchJournalReply(careGuideId, journalPostId, id, data, cb=function(){}) {
  Logger.log('debug', `[state.journalReplies.actions] patchJournalReply(${careGuideId}, ${journalPostId}, ${id}, %j, ###)`, data);

  return async function(dispatch) {
    dispatch(journalReplyPatchRequest(careGuideId, journalPostId, id, data));

    // call API
    const response = await api.patchCareGuideJournalPostReply(careGuideId, journalPostId, id, data);
    let success = false;

    // patch journal reply success
    if (200 === response.get('status')) {

      Logger.log('info', `PATCH API journal reply success. Care Guide ID: ${careGuideId}, Post ID: ${journalPostId}, ID: ${id}.`);

      const normalizedEntities = normalize([response.getIn(['data', 'journal_reply'])], [schema.journalReply]);
      const data = {
        id: response.getIn(['data', 'journal_reply', 'id']),
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(journalReplyPatchSuccess(careGuideId, journalPostId, id, data));
      success = true;
      
    // patch journal reply failure
    } else {
      Logger.log('info', `PATCH API journal reply failure. Care Guide ID: ${careGuideId}, Post ID: ${journalPostId}, ID: ${id}.`);
      dispatch(journalReplyPatchFailure(careGuideId, journalPostId, id, response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  }
}

Logger.log('silly', `state.journalReplies.actions loaded.`);
