import React from 'react';
import {Translation} from 'react-i18next';
import {Form, Select} from 'antd';

const TableColumnTypeInput = ({name, onChange, disabled=false, ...props}) => {

  const options = [
    {
      value: 1,
      label: 'care_guides_table_column_type_input_type_text',
    },
    {
      value: 2,
      label: 'care_guides_table_column_type_input_type_textarea',
    },
    {
      value: 3,
      label: 'care_guides_table_column_type_input_type_check_mark',
    },
    {
      value: 4,
      label: 'care_guides_table_column_type_input_type_check_mark_text',
    },
    {
      value: 5,
      label: 'care_guides_table_column_type_input_type_cross_mark',
    },
    {
      value: 6,
      label: 'care_guides_table_column_type_input_type_cross_mark_text',
    },
    {
      value: 7,
      label: 'care_guides_table_column_type_input_type_phone_number',
    },
    {
      value: 8,
      label: 'care_guides_table_column_type_input_type_url',
    },
  ];

  return (
    <Translation>{(t) => 
      <Form.Item
        name={name}
        rules={[
          {required: true, message: t('feedback_validation_required')},
        ]}
        {...props}
      >
        <Select disabled={disabled} onChange={onChange}>
          {options.map(x => <Select.Option key={x.value} value={x.value}>{t(x.label)}</Select.Option>)}
        </Select>
      </Form.Item>
    }</Translation>
  );
}

export default TableColumnTypeInput;
