import React from 'react';
import {Translation, getI18n} from 'react-i18next';

import PlanCard from './PlanCard';
import Format from '../../../../../lib/Format';
import Logger from '../../../../../lib/Logger';


const PlanSelected = ({plan, id, cycle, ...props}) => {

  const cycle_data = {
    M: {
      name: getI18n().t('plans_name_monthly', {name: plan.name}),
      price: plan.price_month,
      priceLabel: getI18n().t('plans_price_label_month', {price: Format.currency(plan.price_month, true)}),
      description: plan.description,
      planValue: `${plan.id},M`,
      trialPeriod: plan.month_trial_period ? getI18n().t('plans_trial_period', {days: plan.month_trial_period}) : null,
    },
    Y: {
      name: getI18n().t('plans_name_yearly', {name: plan.name}),
      price: plan.price_year,
      priceLabel: getI18n().t('plans_price_label_year', {price: Format.currency(plan.price_year, true)}),
      description: plan.description,
      planValue: `${plan.id},Y`,
      trialPeriod: plan.year_trial_period ? getI18n().t('plans_trial_period', {days: plan.year_trial_period}) : null,
    },
  };

  if (!(cycle in cycle_data)) {
    return null;
  }

  const planData = cycle_data[cycle];

  return (
    <Translation>{(t) => 
      <PlanCard
        plan={planData}
        isSelected={true}
        canChange={false}
      />
    }</Translation>
  )  
}

export default PlanSelected;

Logger.log('silly', `PlanSelected loaded.`);
