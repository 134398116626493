import storage from './Storage';
import Logger from './Logger';
import Config from '../Config';

class Auth {
  constructor(storageType = 'session') {
    Logger.log('debug', `Auth.constructor(${storageType})`);
    this.storageType = storageType;
    this.storage = storage;
  }

  setStorageType(storageType) {
    Logger.log('debug', `Auth.setStorageType(${storageType})`);
    this.storageType = storageType;
  }

  saveSession(
    authToken,
    authIssuedAt,
    authExpires,
    userId,
    username,
    profileId
  ) {
    Logger.log('info', `Authentication success. User: ${userId}`);
    this.storage.set(this.storageType, 'authToken', authToken);
    this.storage.set(this.storageType, 'authIssuedAt', authIssuedAt);
    this.storage.set(this.storageType, 'authExpires', authExpires);
    this.storage.set(this.storageType, 'userId', userId);
    this.storage.set(this.storageType, 'profileId', profileId);
    this.storage.set(this.storageType, 'username', username);
    this.storage.set(this.storageType, 'partnershipId', null);
    this.storage.set(this.storageType, 'partnershipData', {
      access_role_admin_in_care_guide: null,
      access_role_admin_in_app: null,
      access_tabs_in_app: null,
      role: null,
      name: null,
    });
    this.storage.set(this.storageType, 'userProfilePartnershipId', null);
    this.storage.set(this.storageType, 'userProfilePartnershipPage', 1);
  }

  getSession() {
    Logger.log('debug', `Auth.getSession()`);
    return {
      authToken: this.storage.get(this.storageType, 'authToken'),
      authIssuedAt: this.storage.get(this.storageType, 'authIssuedAt'),
      authExpires: this.storage.get(this.storageType, 'authExpires'),
      userId: this.storage.get(this.storageType, 'userId'),
      profileId: this.storage.get(this.storageType, 'profileId'),
      username: this.storage.get(this.storageType, 'username'),
      partnershipId: this.storage.get(this.storageType, 'partnershipId'),
      partnershipData: this.storage.get(this.storageType, 'partnershipData'),
      userProfilePartnershipId: this.storage.get(
        this.storageType,
        'userProfilePartnershipId'
      ),
      userProfilePartnershipPage: this.storage.get(
        this.storageType,
        'userProfilePartnershipPage'
      ),
    };
  }

  deleteSession() {
    Logger.log('debug', `Auth.deleteSession()`);
    this.storage.remove(this.storageType, 'authToken');
    this.storage.remove(this.storageType, 'userId');
    this.storage.remove(this.storageType, 'profileId');
    this.storage.remove(this.storageType, 'username');
    this.storage.remove(this.storageType, 'authIssuedAt');
    this.storage.remove(this.storageType, 'authExpires');
    this.storage.remove(this.storageType, 'partnershipId');
    this.storage.remove(this.storageType, 'partnershipPage');
    this.storage.remove(this.storageType, 'partnershipData');
    this.storage.remove(this.storageType, 'userProfilePartnershipId');
    this.storage.remove(this.storageType, 'userProfilePartnershipPage');
    this.storage.remove(this.storageType, 'userProfilePartnershipData');
  }

  isAuthTokenValid(authToken, authExpires) {
    Logger.log('debug', `Auth.isAuthTokenValid(###, ###)`);
    return (
      authToken &&
      authExpires &&
      parseInt(authExpires) - 60 > Math.round(new Date().getTime() / 1000)
    );
  }

  parseJwt(token) {
    Logger.log('debug', `Auth.parseJwt(###)`);
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(
      Buffer.from(base64, 'base64')
        .toString()
        .split('')
        .map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join('')
    );
    return JSON.parse(jsonPayload);
  }
}

function findStorageType() {
  if (storage.get('session', 'authToken')) {
    return 'session';
  } else if (storage.get('local', 'authToken')) {
    return 'local';
  }
  return Config.get('DEFAULT_AUTH_STORAGE');
}

const auth = new Auth(findStorageType());
export default auth;

Logger.log('silly', `Auth loaded.`);
