import {getI18n} from 'react-i18next';

import Config from '../../../../Config';
import Logger from '../../../../lib/Logger';
import Events from '../../../../lib/EventEmitter';

import PaymentRegistrationScreen from './containers/PaymentRegistrationScreenContainer';
import PaymentStatusScreen from './components/PaymentStatusScreen';
import SubscriptionScreen from './containers/SubscriptionScreenContainer';

const register = () => {
  Logger.log('debug', `payments.register()`);

  if (Config.getIn(['MODULE_TOGGLES', 'payments', 'routes'])) {
    
    Events.dispatch('ADD_DEFAULT_ROUTES', 

      // screen name (key): [route type (element), path (prop), exact (prop), component (prop), route name]
      {
        'PaymentRegistrationScreen': ['PrivateRoute', '/payments/registration', true, PaymentRegistrationScreen, getI18n().t('payments_route_registration')],
        'PaymentStatusScreen': ['PrivateRoute', '/payments/status', true, PaymentStatusScreen, getI18n().t('payments_route_status')],
      }
    );

    Events.dispatch('ADD_MAIN_ROUTES', 

      // screen name (key): [route type (element), path (prop), exact (prop), component (prop), route name]
      {
        'SubscriptionScreen': ['PrivateRoute', '/subscription', true, SubscriptionScreen, getI18n().t('payments_route_subscription')],
      }
    );
  }
}

export default register;

Logger.log('silly', `payments.register() loaded.`);
