import {getI18n} from 'react-i18next';

import Config from '../../../../Config';
import Logger from '../../../../lib/Logger';
import Events from '../../../../lib/EventEmitter';

import ConsentScreen from './components/ConsentScreen';
import SurveyContent from './components/SurveyContent';
import PostSurveyScreen from './components/PostSurveyScreen';
import InterviewScreen from './components/InterviewScreen';

const register = () => {
  Logger.log('debug', `content.register()`);

  if (Config.getIn(['MODULE_TOGGLES', 'content', 'routes'])) {
    Events.dispatch('ADD_DEFAULT_ROUTES', 

      // screen name (key): [route type (element), path (prop), exact (prop), component (prop), route name]
      {
        'ConsentScreen': ['Route', '/consent', true, ConsentScreen, getI18n().t('content_route_consent')],
        'SurveyContent': ['Route', '/survey', true, SurveyContent, getI18n().t('content_route_survey')],
        'PostSurveyScreen': ['Route', '/post-survey', true, PostSurveyScreen, getI18n().t('content_route_post_survey')],
        'InterviewScreen': ['Route', '/interview', true, InterviewScreen, getI18n().t('content_route_interview')],
      }
    );
  }
}

export default register;

Logger.log('silly', `content.register() loaded.`);
