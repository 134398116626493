import {connect} from 'react-redux';

import {messageShowComposeForm} from '../../../../../state/modules/messages/actions';
import MessagesLayout from '../components/MessagesLayout';

const mapStateToProps = (state, ownProps) => {
  const page = parseInt(ownProps.match.params.page ? ownProps.match.params.page : 1);
  return {
    isLoading: state.journalPosts.get('isLoading'),
    page: page,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    showForm: () => {
      dispatch(messageShowComposeForm());
    },
  }
}

const MessagesLayoutContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(MessagesLayout);

export default MessagesLayoutContainer;
