import React, {useState, useEffect} from 'react';
import {Translation, getI18n} from 'react-i18next';
import {ConfigProvider, Input, Form, Radio, Table, Typography} from 'antd';
import {FaUserCheck, FaUserFriends, FaUserTimes} from "react-icons/fa";

import useWindowDimensions from '../../../../../lib/WindowDimensions';


const FieldTypeADLTableEditor = ({form, content, contentsPath, ...props}) => {

  const [selected, setSelected] = useState({});
  const [notes, setNotes] = useState({});

  // const addGroupRow = useRef({});

  const groups = form.getFieldValue([...contentsPath, `groups`]) || [undefined];

  const {width} = useWindowDimensions();
  const isMobile = width < 500;

  // populate table inputs (since they are not actually part of the form)
  useEffect(() => {
    const gs = form.getFieldValue([...contentsPath, `groups`]);
    const valueUpdates = {};
    const notesUpdates = {};
    
    for (const i in gs) {
      const group = gs[i];
      if ('fields' in group && Array.isArray(group['fields'])) {
        for (const j in group['fields']) {
          if (!group['fields'][j]) {
            group['fields'][j] = {};
          }
          if ('value' in group['fields'][j]) {
            valueUpdates[[...contentsPath, `groups`, i, `fields`, j, 'value'].join('//')] = group['fields'][j]['value'];
            notesUpdates[[...contentsPath, `groups`, i, `fields`, j, 'notes'].join('//')] = group['fields'][j]['notes'];
          }
        }
      }
    }

    // update state if needed
    if (Object.keys(valueUpdates).length > 0) {
      setSelected(s => { return {...s, ...valueUpdates} });
    }
    if (Object.keys(notesUpdates).length > 0) {
      setNotes(n => { return {...n, ...notesUpdates} });
    }
  // }, [form, contentsPath]);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const setNestedFieldValue = (e, fieldName) => {

    // get all form values (so we can updated nested values)
    const fields = form.getFieldsValue();

    // split radio input name by double slash and use values as keys in nested form ipnuts
    const paths = e.target.name.split('//');
    paths.pop(); // remove last element because we'll handle that manually at the end
    let field = fields;
    let path_count = 0;
    for (let path of paths) {
      path = Number.isInteger(path) ? parseInt(path) : path;
      if (path in field) {
        field = field[path];
        path_count++;
      }
    }

    // found path
    if (path_count === paths.length) {
      field[fieldName] = e.target.value;
    }
    
    // update form values with changes
    form.setFieldsValue(fields);
  }

  const onChangeRadio = e => {
    
    // manage local state so only 1 radio button in a group can be selected at a time
    setSelected({ ...selected, [e.target.name]: e.target.value });

    setNestedFieldValue(e, 'value');
  }

  const onChangeNotes = e => {
    setNotes({ ...notes, [e.target.name]: e.target.value });
    setNestedFieldValue(e, 'notes');
  }

  // const addNewRow = (add) => {
  //   console.log("Click add row");
  // }

  const renderCell = (text, row, index) => {
    return (index % 2 === 1)
      ? {props: {colSpan: 0}}
      : text;
  }

  const dataColumnWidth = isMobile ? 40 : 110;

  const tables = groups.filter(x => x.fields).map((group, groupIndex) => {
    const tableDefinitions = {
      cols: [
        {
          title: group.name,
          dataIndex: 'name',
          key: 'name',
          render: (text, row, index) => {
            return (index % 2 === 1)
              ? {
                  children: text,
                  props: {
                    colSpan: 4,
                  },
                }
              : text;
          }
        },
        {
          title: isMobile ? <FaUserCheck /> : getI18n().t('care_guides_adl_table_independent'),
          dataIndex: 'independent',
          key: 'independent',
          render: renderCell,
          width: dataColumnWidth,
          align: 'center',
        },
        {
          title: isMobile ? <FaUserFriends /> : getI18n().t('care_guides_adl_table_needs_help'),
          dataIndex: 'needsHelp',
          key: 'needsHelp',
          render: renderCell,
          width: dataColumnWidth,
          align: 'center',
        },
        {
          title: isMobile ? <FaUserTimes /> : getI18n().t('care_guides_adl_table_cannot_do'),
          dataIndex: 'cannotDo',
          key: 'cannotDo',
          render: renderCell,
          width: dataColumnWidth,
          align: 'center',
        },
      ],
      data: group.fields
        ? group.fields.flatMap((field, i) => {

            // value: radio buttons
            const valueFieldName = [...contentsPath, 'groups', groupIndex, 'fields', i, 'value'].join('//');
            const output = [
              {
                key: i,
                name: <Typography.Text strong>{field.hint ? `${field.name} (${field.hint})` : field.name}</Typography.Text>,
                independent: 
                  <Radio
                    name={valueFieldName}
                    value={1}
                    onChange={onChangeRadio}
                    checked={selected[valueFieldName] === 1}
                  />,
                needsHelp: 
                  <Radio
                    name={valueFieldName}
                    value={2}
                    onChange={onChangeRadio}
                    checked={selected[valueFieldName] === 2}
                  />,
                cannotDo:
                  <Radio
                    name={valueFieldName}
                    value={4}
                    onChange={onChangeRadio}
                    checked={selected[valueFieldName] === 4}
                  />,
              }
            ];

            // notes: textarea
            const notesFieldName = [...contentsPath, 'groups', groupIndex, 'fields', i, 'notes'].join('//');
            output.push(
              {
                key: `${i}-notes`,
                name:
                  <Input.TextArea
                    name={notesFieldName}
                    onChange={onChangeNotes}
                    value={notes[notesFieldName]}
                    autoSize
                    bordered={false}
                    placeholder={getI18n().t('care_guides_adl_table_notes')}
                  />
              }
            )
            return output;
          })
        : null,
    };

    // // add control for adding new row to table if able
    // if (group.is_adjustable === true) {
    //   if (tableDefinitions.data === null) {
    //     tableDefinitions.data = [];
    //   }
    //   tableDefinitions.data.push({
    //     key: 'add-row',
    //     name: <span>Add Row</span>
    //   });
    // }

    return tableDefinitions;
  });

  return (
    <Translation>{(t) => 
      <>

        <div className="adl-table-editor">

          {tables.map((table, i) =>
            <ConfigProvider key={i} renderEmpty={() => null}>
              <Table columns={table.cols} dataSource={table.data} pagination={false} size="small" bordered />
            </ConfigProvider>
          )}

          <div className="hidden-inputs">

            <Form.List name={[content.name, 'groups']}>

              {(gs) => {
                return (
                  <>
                    {gs.map((group, groupIndex) =>
                      <div key={groupIndex}>

                        <Form.Item
                          name={[group.name, 'name']}
                          fieldKey={[group.name, 'name']}
                        >
                          <Input />
                        </Form.Item>

                        <Form.List name={[group.name, 'fields']}>
                          {(fs) => {
                            return (
                              <>
                                {fs.map((field, fieldIndex) => 
                                  <div key={fieldIndex}>

                                    <Form.Item
                                      name={[field.name, 'name']}
                                      fieldKey={[field.name, 'name']}
                                    >
                                      <Input />
                                    </Form.Item>

                                    <Form.Item
                                      name={[field.name, 'hint']}
                                      fieldKey={[field.name, 'hint']}
                                    >
                                      <Input />
                                    </Form.Item>

                                    <Form.Item
                                      name={[field.name, 'value']}
                                      fieldKey={[field.name, 'value']}
                                    >
                                      <Input />
                                    </Form.Item>
                                    
                                    <Form.Item
                                      name={[field.name, 'notes']}
                                      fieldKey={[field.name, 'notes']}
                                    >
                                      <Input />
                                    </Form.Item>

                                  </div> 
                                )}
                              </>
                            )
                          }}
                        </Form.List>
                      </div>
                    )}
                  </>
                )
              }}

            </Form.List>
          </div>
        </div>
      </>
    }</Translation>
  )
}

export default FieldTypeADLTableEditor;
