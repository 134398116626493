import CareGuideDocumentModalExport from '../components/CareGuideDocumentModalExport';
import { downloadCareGuideDocumentsCSV } from '../../../../../state/modules/careGuideDocuments/actions';
import { connect } from 'react-redux';
import { denormalize } from 'normalizr';
import { schema } from '../../../../../state/schema';
import storage from '../../../../../lib/Storage';

const mapStateToProps = (state, ownProps) => {
  let allPageCareGuideDocumentIds = [];

  const order = ownProps.order ? ownProps.order : 'started_at.asc';
  const limit = ownProps.limit ? ownProps.limit : 10;
  const page = ownProps.page ? ownProps.page : 1;
  const careGuideId = ownProps.careGuideId;
  const result = state.careGuideDocuments.getIn([
    'pages',
    careGuideId,
    order,
    limit,
    page,
  ]);

  const list = result
    ? result
        .map((x) => {
          allPageCareGuideDocumentIds.push(x);
          return {
            key: x,
            ...denormalize(
              state.entities.getIn(['careGuideDocuments', x]),
              schema.careGuideDocument,
              state.entities.toJS()
            ),
          };
        })
        .toArray()
    : [];

  return {
    list,
    allPageCareGuideDocumentIds,
    partnershipName: storage.get('local', 'partnershipData')?.name ?? null,
    isDownloading: state.careGuideDocuments.get('isPDFDownloading'),
    total: state.careGuideDocuments.get('total')
      ? state.careGuideDocuments.get('total')
      : 0,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    download: (partnershipName, careGuideId, payload, order, filter, cb) => {
      dispatch(
        downloadCareGuideDocumentsCSV(
          partnershipName,
          careGuideId,
          payload,
          order,
          filter,
          cb
        )
      );
    },
  };
};

const CareGuideDocumentModalExportContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(CareGuideDocumentModalExport);

export default CareGuideDocumentModalExportContainer;
