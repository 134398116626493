import React from "react";
import CareGuideNoteCard from "./CareGuideNoteCard";
import { Typography } from 'antd';
import { Translation } from 'react-i18next';


const { Title } = Typography;

const CareGuideNoteListCard = ({ showForm, load, page, filter, order, limit, profileId, handleToggle, selectId, title, data, patch, careGuideId, remove, ...props }) => {

    return (
        <Translation>{(t) =>
            <>
                <Title
                    level={4}
                    className="note-title">
                    {t(title)}
                </Title>
                {
                    data.map((item, index) => (
                        <>
                            <CareGuideNoteCard
                                showForm={showForm}
                                load={load}
                                order={order}
                                limit={limit}
                                filter={filter}
                                page={page}
                                key={item.id}
                                profileId={profileId}
                                handleToggle={handleToggle}
                                item={item}
                                selectId={selectId}
                                patch={patch}
                                careGuideId={careGuideId}
                                remove={remove}
                            />
                        </>
                    ))
                }

            </>
        }</Translation>
    );
};


// Ejemplo de u
export default CareGuideNoteListCard;
