import React, {useState, createRef} from 'react';
import {Translation, getI18n} from 'react-i18next';
import {Button, Form, Input, Modal} from 'antd';
import {CheckCircleOutlined, CloseCircleOutlined} from '@ant-design/icons';

import message from '../../../elements/lib/MessageWrapper';
import Logger from '../../../../../lib/Logger';


const SubscriptionCancelForm = ({subscription, isDeleting, deleteSubscription, ...props}) => {

  const inputRef = createRef();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [showInputError, setShowInputError] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const confirmationWord = 'CANCEL';

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    if (inputValue === confirmationWord) {
      setShowInputError(false);
      if (subscription) {
        deleteSubscription(subscription.id, (success) => {
          if (success) {
            message.success(getI18n().t('payments_subscription_cancel_form_success'));
          } else {
            message.error(getI18n().t('payments_subscription_cancel_form_error'));
          }
          closeModal();
        });
      } else {
        message.error(getI18n().t('payments_subscription_cancel_form_error'));
        closeModal();
      }
    } else {
      setShowInputError(true);
      inputRef.current.focus();
    }
  };

  const handleCancel = () => {
    closeModal();
  };

  const onInputChange = e => {
    setInputValue(e.target.value);
    setShowInputError(false);
  }

  const onInputKeyDown = e => {
    if (e.key === 'Enter') {
      handleOk();
    }
  }

  const closeModal = () => {
    setIsModalVisible(false);
    setInputValue('');
    setShowInputError(false);
  }

  return (
    <Translation>{(t=>
      <>

        <Button
          type="primary"
          size="small"
          onClick={showModal}
          danger
        >
          {t('payments_subscription_btn_cancel')}
        </Button>

        <Modal
          title={t('payments_subscription_modal_cancel_title')}
          visible={isModalVisible}
          onOk={handleOk}
          onCancel={handleCancel}
          footer={[
            <Button
              key="cancel"
              onClick={handleCancel}
              icon={<CloseCircleOutlined />}
            >
              {t('payments_subscription_modal_cancel_btn_cancel')}
            </Button>,
            <Button
              key="ok"
              type="primary"
              danger
              loading={isDeleting}
              onClick={handleOk}
              icon={<CheckCircleOutlined />}
            >
              {t('payments_subscription_modal_cancel_btn_ok')}
            </Button>,
          ]}
        >
          <p>{t('payments_subscription_modal_cancel_text', {confirmationWord: confirmationWord})}</p>

          <Form>
            <Form.Item
              label={null}
              validateStatus={showInputError ? "error" : null}
              help={showInputError ? t('payments_subscription_modal_cancel_input_error', {confirmationWord: confirmationWord}) : null}
            >
              <Input
                placeholder={confirmationWord}
                autoFocus
                onChange={onInputChange}
                onKeyDown={onInputKeyDown}
                value={inputValue}
                ref={inputRef}
              />
            </Form.Item>
          </Form>
          
        </Modal>

      </>
    )}</Translation>
  );
};

export default SubscriptionCancelForm;

Logger.log('silly', `SubscriptionCancelForm loaded.`);
