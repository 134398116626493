import React, { useState, useEffect, useRef } from 'react';
import { Translation, getI18n } from 'react-i18next';
import {
  Button,
  Card,
  Form,
  Input,
  InputNumber,
  Popconfirm,
  Space,
} from 'antd';
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  DeleteOutlined,
  EditOutlined,
  FilePdfOutlined,
} from '@ant-design/icons';

import SegmentContentInput from './SegmentContentInput';
import CareGuideSegmentContent from './CareGuideSegmentContent';
import message from '../../../elements/lib/MessageWrapper';
import hasPermission, {
  UPDATE_CARE_GUIDE,
} from '../../../elements/lib/hasPermission';
import Logger from '../../../../../lib/Logger';
import useWindowDimensions from '../../../../../lib/WindowDimensions';
import {
  MixpanelTracker,
  mixpanelEventsEnum,
} from '../../../../../lib/Mixpanel';

const PDFButton = (props) => (
  <Button
    size="small"
    icon={<FilePdfOutlined />}
    loading={props.isGeneratingPDF}
    disabled={props.isGeneratingPDF}
    onClick={props.onClickExportHandler}
  >
    {props.text}
  </Button>
);

const CareGuideSegment = ({
  careGuideId,
  careGuide,
  segment,
  maxOrder,
  data,
  errors,
  preventRoutingTransition,
  ...props
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [form] = Form.useForm();
  const inputRef = useRef();
  const submitCallbackRef = useRef();

  const { width } = useWindowDimensions();
  const isSmall = width <= 576;
  // const isMedium = width <= 768;

  // handle errors reported by API
  useEffect(() => {
    let firstFieldName = '';
    for (const field in errors) {
      form.setFields([{ name: field, errors: errors[field] }]);
      if (firstFieldName === '') {
        firstFieldName = field;
      }
    }
    form.scrollToField(firstFieldName);
  }, [form, errors]);

  // focus on first input when entering edit mode
  useEffect(() => {
    if (isEditing) {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }
  }, [isEditing, inputRef]);

  // set care guide editing bumper on/off
  useEffect(() => {
    preventRoutingTransition(isEditing);
  }, [isEditing, preventRoutingTransition]);

  // submit data handler
  const submitData = async (values) => {
    Logger.log('debug', `CareGuideSegment.submitData(###)`);

    // strip `key` from contents
    const contents = [];
    if (
      'contents' in values &&
      Array.isArray(values['contents']) &&
      values['contents'].length > 0
    ) {
      for (const content of values['contents']) {
        const { key, ...vals } = content;
        contents.push(vals);
      }
    }

    // construct payload
    const payload = {
      name: 'name' in values ? values['name'] : segment.name,
      // 'order': segment.order,
      order: values['order'],
      contents: contents,
    };

    props.update(careGuideId, segment.id, payload, (success) => {
      if (success) {
        message.success(getI18n().t('feedback_form_success'));
        setIsEditing(false);
        props.load(careGuideId);
      } else {
        message.error(getI18n().t('feedback_form_error'));
      }
    });
  };

  // form submit handler
  const handleFinish = async (values) => {
    Logger.log('debug', `CareGuideSegment.handleFinish(###)`);
    if (!props.isSubmitting) {
      if (!submitCallbackRef.current()) {
        // custom validator from child
        message.error(getI18n().t('feedback_form_error'));
      } else {
        await submitData(values);
      }
    }
  };

  // form error handler
  const handleFinishFailed = ({ values, errorFields, outOfDate }) => {
    Logger.log('debug', `CareGuideSegment.handleFinishFailed(###)`);
    message.error(getI18n().t('feedback_form_error'));
    if (errorFields && errorFields.length > 0) {
      form.scrollToField(errorFields[0].name);
    }
  };

  // remove error message when input value changes
  const handleValuesChange = (changedValues, allValues) => {
    for (const key of Object.keys(changedValues)) {
      form.setFields([{ name: key, errors: [] }]);
    }
  };

  const deleteSegment = () => {
    if (!props.isSubmitting) {
      props.remove(careGuideId, segment.id, (success) => {
        if (success) {
          message.success(getI18n().t('feedback_delete_success'));
          preventRoutingTransition(false);
          setIsEditing(false);
          props.load(careGuideId);
        } else {
          message.error(getI18n().t('feedback_delete_error'));
        }
      });
    }
  };

  const onClickEditHandler = (e) => {
    setIsEditing(true);
  };

  // const onClickSaveHandler = e => {
  //   form.submit();
  // }

  const onClickCancelHandler = (e) => {
    setIsEditing(false);
  };

  const onClickDeleteHandler = (e) => {
    deleteSegment();
  };

  const onClickExportHandler = (event) => {
    props.generatePDF(careGuideId, segment.id, (success) => {
      if (success) {
        if (careGuide?.partnership) {
          MixpanelTracker.mixpanelTrack(
            mixpanelEventsEnum.CARE_GUIDE.EXPORT_PDF_SEGMENT,
            {
              careGuideName: `${careGuide.first_name} ${careGuide.last_name}`,
              partnershipName: careGuide.partnership.name,
              careGuideId: careGuide.id,
              partnershipId: careGuide.partnership.id,
            }
          );
        } else {
          MixpanelTracker.mixpanelTrack(
            mixpanelEventsEnum.CARE_GUIDE.EXPORT_PDF_SEGMENT,
            {
              careGuideName: `${careGuide.first_name} ${careGuide.last_name}`,
              careGuideId: careGuide.id,
            }
          );
        }
      }
    });
    event.stopPropagation();
  };

  submitCallbackRef.current = () => true;

  return (
    <Translation>
      {(t) => (
        <Form
          name="segment_content_form"
          className="segment-content-form"
          form={form}
          initialValues={data}
          onFinish={handleFinish}
          onFinishFailed={handleFinishFailed}
          onValuesChange={handleValuesChange}
          validateTrigger="onSubmit"
          requiredMark={false}
        >
          <Card
            title={
              isEditing ? (
                <>
                  <Form.Item
                    name="name"
                    rules={[
                      {
                        required: true,
                        message: t('feedback_validation_required'),
                      },
                    ]}
                    className="segment-input-name"
                  >
                    <Input />
                  </Form.Item>
                </>
              ) : (
                <div className="segment-name">{segment.name}</div>
              )
            }
            size="small"
            id={segment.id}
            extra={
              isEditing ? (
                <Space direction={isSmall ? 'vertical' : 'horizontal'}>
                  <Form.Item
                    key="order-input"
                    name="order"
                    label={t('care_guides_form_input_order')}
                    rules={[
                      {
                        required: true,
                        message: t('feedback_validation_required'),
                      },
                    ]}
                    className="segment-input-order"
                  >
                    <InputNumber size="small" min={1} max={maxOrder} />
                  </Form.Item>
                  <Button
                    key="cancel"
                    icon={<CloseCircleOutlined />}
                    size="small"
                    onClick={() => onClickCancelHandler()}
                  >
                    {t('action_cancel')}
                  </Button>
                  <Popconfirm
                    key="delete-confirm"
                    placement="bottom"
                    title={t('care_guides_form_delete_confirm_body')}
                    onConfirm={() => onClickDeleteHandler()}
                    okText={t('confirm_yes')}
                    cancelText={t('confirm_cancel')}
                  >
                    <Button
                      icon={<DeleteOutlined />}
                      size="small"
                      danger
                      loading={props.isDeleting}
                    >
                      {t('action_delete')}
                    </Button>
                  </Popconfirm>
                  <Button
                    key="submit"
                    icon={<CheckCircleOutlined />}
                    size="small"
                    type="primary"
                    htmlType="submit"
                    // onClick={() => onClickSaveHandler()}
                    loading={props.isSubmitting}
                  >
                    {t('action_save')}
                  </Button>
                </Space>
              ) : (
                <Space direction={isSmall ? 'vertical' : 'horizontal'}>
                  <PDFButton
                    key="export"
                    text={t('care_guides_button_download_pdf')}
                    isGeneratingPDF={props.isGeneratingPDF}
                    onClickExportHandler={onClickExportHandler.bind(this)}
                  />
                  {hasPermission(UPDATE_CARE_GUIDE) ? (
                    <Button
                      key="edit"
                      icon={<EditOutlined />}
                      size="small"
                      type="primary"
                      onClick={() => onClickEditHandler()}
                    >
                      {t('action_edit')}
                    </Button>
                  ) : null}
                </Space>
              )
            }
          >
            {isEditing ? (
              <Form.List name="contents">
                {(contents, { add, remove, move }) => {
                  return (
                    <div className="segment-content-form" key={segment.id}>
                      {contents.map((content, i) => (
                        <SegmentContentInput
                          content={content}
                          defaultContentType={form.getFieldValue([
                            `contents`,
                            content.name,
                            `type`,
                          ])}
                          remove={remove}
                          form={form}
                          index={i}
                          key={content.key}
                          inputRef={inputRef}
                          submitCallbackRef={submitCallbackRef}
                        />
                      ))}
                    </div>
                  );
                }}
              </Form.List>
            ) : (
              <CareGuideSegmentContent segment={segment} />
            )}
          </Card>
        </Form>
      )}
    </Translation>
  );
};

export default CareGuideSegment;
