import React, {useState, useRef} from 'react';
import {Translation, getI18n} from 'react-i18next';
import {Form, Input, Popover, Typography, Space} from 'antd';
import {CheckCircleFilled, CloseCircleOutlined} from '@ant-design/icons';
import NumberFormat from 'react-number-format';


const rePhoneNumber = new RegExp('^\\d{10,15}$');

const incompleteTextType = 'default';
const incompleteIcon = <CloseCircleOutlined style={{ color: '#00000040' }} />;

const completeTextType = 'secondary';
const completeIcon = <CheckCircleFilled style={{ color: '#52c41a' }} />;

const TelephoneInput = ({name, hasError=false, label=null, rules=null, disabled=false, autoFocus=false, useTooltip=true, tooltipPlacement='right', useSuccess=true, ...props}) => {

  const [isVisible, setIsVisible] = useState(false);

  const [isPhoneNumberComplete, setIsPhoneNumberComplete] = useState(false);

  const inputRef = useRef(null);

  const onChange = e => {
    const val = e.target.value;

    // check looks like phone number
    setIsPhoneNumberComplete(rePhoneNumber.test(val.replace(/\D/g, '')));
  }

  const validateTelephone = (rule, value) => {
    const val = value.replace(/\D/g, '');
    if (val && !rePhoneNumber.test(val)) {
      return Promise.reject(getI18n().t('feedback_validation_telephone'));
    } else {
      return Promise.resolve();
    }
  }

  return (
    <Translation>{(t) =>
      <div className="form-group">
        <Popover
          placement={tooltipPlacement}
          trigger="focus"
          title={null}
          visible={useTooltip && isVisible && !isPhoneNumberComplete}
          content={
            <>
              <Space direction="vertical">

                <Typography.Text
                  type={isPhoneNumberComplete ? completeTextType : incompleteTextType}
                >
                  {isPhoneNumberComplete ? completeIcon : incompleteIcon}{' '}
                  {t('input_requirment_telephone')}
                </Typography.Text>

              </Space>
            </>
          }
        >
          <Form.Item
            name={name}
            label={label ? label : t('register_input_telephone')}
            rules={rules
              ? rules
              : [
                  {required: true, message: t('feedback_validation_required')},
                  {validator: validateTelephone}
                ]}
            // hasFeedback={useSuccess && isPhoneNumberComplete && !hasError}
            hasFeedback={true}
            validateStatus={hasError ? "error" : (useSuccess && isPhoneNumberComplete ? "success" : null)}
          >
            <NumberFormat
              customInput={Input}
              placeholder="(###) ###-####"
              format="(###) ###-####"
              mask={['#', '#', '#', '#', '#', '#', '#', '#', '#', '#']}
              disabled={disabled}
              autoFocus={autoFocus}
              onChange={onChange}
              ref={inputRef}
              onFocus={() => setIsVisible(true)}
              onBlur={() => setIsVisible(false)}
            />
          </Form.Item>
        </Popover>
      </div>
    }</Translation>
  )
}

export default TelephoneInput;
