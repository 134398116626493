import { Button } from "antd";
import { Translation } from 'react-i18next';
import React from "react";
import { pinFilled } from "./Icons";

const PinButton = ({ isPinned, setIsPinned }) => {
  return (
    <Translation>{(t) =>
      <Button
        className={isPinned ? "note-pin-button-active" : "note-pin-button-disabled"}
        icon={pinFilled(12, 15)}
        size={'large'}
        onClick={(e) => {
          e.preventDefault();
          setIsPinned();
        }
        }
      />
    }</Translation>
  );
}

export default PinButton;
