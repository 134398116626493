import React from 'react';
import { withRouter } from 'react-router';
import { Translation } from 'react-i18next';
import { Col, Row, Steps, Typography } from 'antd';

const { Title } = Typography;

const ResourceAdminFormHeader = ({ id, step }) => {

  return (
    <Translation>
      {(t) => (
        <>
          <Row justify="space-between" align="top">
            <Col>
              <Title
                style={{ marginLeft: '24px' }}
                level={2}
                className="resource-title"
              >
                {id ? t('resource_form_title_edit') : t('resource_form_title_create')}
              </Title>
            </Col>
          </Row>

          <Row justify="center" align="top">
            <Col>
              <Steps size="small" current={step}>
                <Steps.Step
                  style={{ width: '500px' }}
                  title={id ? t('resource_form_title_step1_edit') : t('resource_form_title_step1_create')}
                />
                <Steps.Step title={t('resource_form_title_step2')} />
              </Steps>
            </Col>
          </Row>
        </>
      )}
    </Translation>
  );
};

export default withRouter(ResourceAdminFormHeader);
