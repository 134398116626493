import React, {useState, useEffect, useRef} from 'react';
import {Translation, getI18n} from 'react-i18next';
import {Button, Form, Input, Modal, Popconfirm} from 'antd';
import {CloseCircleOutlined, DeleteOutlined, SaveOutlined, SendOutlined} from '@ant-design/icons';
import BodyInputChat from './BodyInputChat';
import MemberInput from '../../careGuideHelpers/containers/MemberInputContainer';
import CareGuidePicker from '../../careGuides/containers/CareGuidePickerContainer';
import message from '../../../elements/lib/MessageWrapper';
import {pathTo} from '../../../Routes';
import Logger from '../../../../../lib/Logger';

const MessageComposeForm = ({threadId, messageId, page, limit, order, errors, load, destroyForm, isLoading, isSubmitting, isSaving, isDeleting, messageData, defaultCareGuideId, ...props}) => {

  const [resetBody, setResetBody] = useState(null);
  const [careGuideId, setCareGuideId] = useState(defaultCareGuideId);

  const [form] = Form.useForm();
  const editorRef = useRef();

  // handle errors reported by API
  useEffect(() => {
    let firstFieldName = '';
    for (const field in errors) {
      form.setFields([{name: field, errors: errors[field]}]);
      if (firstFieldName === '') {
        firstFieldName = field;
      }
    }
    form.scrollToField(firstFieldName);
  }, [form, errors]);

  // focus on first input when showing form
  useEffect(() => {
    const firstInputField = form.getFieldInstance('care_guide');
    if (props.visible) {
      // using timeout because there seems to be a timing issue conflicting with focus, maybe the modal
      if (firstInputField) {
        setTimeout(() => firstInputField.focus(), 250);
      } else {
        if (editorRef.current) {
          setTimeout(() => editorRef.current.focus(), 250);
        }
      }
    }
  }, [form, props.visible, editorRef]);

  useEffect(() => {
    form.setFieldsValue(messageData);
  }, [threadId, messageId, form]); // eslint-disable-line react-hooks/exhaustive-deps

  // force draft to get member names instead of IDs
  useEffect(() => {
    setCareGuideId(defaultCareGuideId);
  }, [setCareGuideId, defaultCareGuideId]);

  // submit data handler
  const submitData = async (values) => {
    Logger.log('debug', `MessageComposeForm.submitData(###)`);

    const payload = {
      is_draft: false
    };

    // transform values
    for (var key in values) {

      // handle recipients
      if (key === 'recipients' && Array.isArray(values[key])) {
        payload[key] = values[key].map((x, i) => {
          return {user_profile_id: x}
        });
      }

      // transform entity refs to IDs
      else if (['care_guide'].includes(key)) {
        payload[key + '_id'] = values[key];
      }

      else {
        payload[key] = values[key];
      }
    }

    // create URL template for message thread
    payload['url_template'] = window.location.href.split('/').slice(0, 3).join('/')
      + pathTo('MessagesInboxThreadScreen', {page: 1, threadId: '000'}).replace(/000/, '{thread_id}');

    props.send(messageId, payload, (success) => {
      if (success) {
        message.success(getI18n().t('feedback_form_success'));
        form.setFieldsValue({body: '', subject: '', recipients: []});
        setResetBody(Math.random());
        props.hideForm();
        props.loadInbox(page, limit, order);
        if (messageId) {
          props.loadDrafts(page, limit, 'created_at.desc');
        }
      } else {
        message.error(getI18n().t('feedback_form_error'));
      }
    });
  }

  // save draft handler
  const saveDraft = async (values) => {
    Logger.log('debug', `MessageComposeForm.saveDraft(###)`);

    const payload = {
      is_draft: true
    };
    if (threadId) {
      payload['thread_id'] = threadId;
    }

    // transform values
    for (var key in values) {

      // handle recipients
      if (key === 'recipients' && Array.isArray(values[key])) {
        payload[key] = values[key].map((x, i) => {
          return {user_profile_id: x}
        });
      }

      // transform entity refs to IDs
      else if (['care_guide'].includes(key)) {
        payload[key + '_id'] = values[key];
      }

      else {
        payload[key] = values[key];
      }
    }

    props.save(messageId, payload, (success) => {
      if (success) {
        message.success(getI18n().t('feedback_form_success'));
        props.loadDrafts(page, limit, 'created_at.desc');
      } else {
        message.error(getI18n().t('feedback_form_error'));
      }
    });
  }

  const clearForm = () => {
    props.hideForm();
    props.formDestroy();
    form.setFieldsValue({care_guide: '', subject: '', recipients: [], body: ''});
    for (const key of Object.keys(messageData)) {
      form.setFields([{name: key, errors: []}]);
    }
    setResetBody(Math.random());
  }

  // form submit handler
  const handleFinish = async (values) => {
    Logger.log('debug', `MessageComposeForm.handleFinish(###)`);
    if (!props.isSubmitting) {
      await submitData(values);
    }
  }

  // form error handler
  const handleFinishFailed = ({values, errorFields, outOfDate}) => {
    Logger.log('debug', `MessageComposeForm.handleFinishFailed(###)`);
    message.error(getI18n().t('feedback_form_error'));
    if (errorFields && errorFields.length > 0) {
      form.scrollToField(errorFields[0].name);
    }
  }

  // remove error message when input value changes
  const handleValuesChange = (changedValues, allValues) => {
    for (const key of Object.keys(changedValues)) {
      form.setFields([{name: key, errors: []}]);
    }
  }

  const onClickOK = () => {
    form.submit();
  }

  const onClickSave = () => {
    if (!isSaving) {
      saveDraft(form.getFieldsValue(['care_guide', 'subject', 'recipients', 'body']));
    }
  }

  const onClickDelete = () => {
    if (!isDeleting) {
      props.delete(messageId, (success) => {
        if (success) {
          message.success(getI18n().t('feedback_delete_success'));
          clearForm();
          props.loadDrafts(page, limit, 'created_at.desc');
        } else {
          message.error(getI18n().t('feedback_delete_error'));
        }
      });
    }
  }

  const onChangeCareGuideHandler = e => {
    if (e !== careGuideId) {
      setCareGuideId(e);
      form.setFieldsValue({care_guide: e, recipients: []});
    } else {
      form.setFieldsValue({care_guide: e});
    }
  }

  return (
    <Translation>{(t) => 
      <>
        <Modal
          title={threadId ? t('messages_form_title_reply') : t('messages_form_title_create')}
          centered
          visible={props.visible}
          maskClosable={false}
          onCancel={clearForm}
          wrapClassName="message-compose-modal"
          footer={[
            <Button shape="round" key="close" icon={<CloseCircleOutlined />} onClick={clearForm}>
              {t('action_close')}
            </Button>,
            <Button key="save" shape="round" icon={<SaveOutlined />} loading={isSaving} onClick={onClickSave}>
              {t('messages_form_button_save')}
            </Button>,
            messageId
              ? <Popconfirm
                  key="delete-confirm"
                  placement="top"
                  title={t('messages_form_form_delete_confirm_body')}
                  onConfirm={onClickDelete}
                  okText={t('confirm_yes')}
                  cancelText={t('confirm_cancel')}
                >
                  <Button shape="round"  key="delete" danger icon={<DeleteOutlined />} loading={isDeleting}>
                    {t('action_delete')}
                  </Button>
                </Popconfirm>
              : null,
            <Button key="send" shape="round"  type="primary" icon={<SendOutlined />} loading={isSubmitting} onClick={onClickOK}>
              {t('messages_form_button_submit')}
            </Button>,
          ]}
          width={1000}
          forceRender={true}
        >
          <div className="message-form">
            <Form
              name="message_form"
              form={form}
              initialValues={messageData}
              onFinish={handleFinish}
              onFinishFailed={handleFinishFailed}
              onValuesChange={handleValuesChange}
              validateTrigger="onSubmit"
              requiredMark={false}
              layout="vertical"
            >

              {!threadId
                ? <CareGuidePicker
                    name="care_guide"
                    label={null}
                    rules={[
                      {required: true, message: t('feedback_validation_required')},
                    ]}
                    onChange={onChangeCareGuideHandler}
                    careGuideId={careGuideId}
                  />
                : null}

              {!threadId
                ? <MemberInput
                    name="recipients"
                    label={null}
                    placeholder={t('messages_form_input_recipients')}
                    multiple={true}
                    careGuideId={careGuideId}
                    includeSelf={false}
                    rules={[
                      {required: true, message: t('feedback_validation_required')},
                    ]}
                  />
                : null}

              {threadId
                ? <Form.Item name="thread_id" style={{display: 'none'}}>
                    <Input type="hidden" />
                  </Form.Item>
                : <div className="form-group">
                    <Form.Item
                      name="subject"
                      label={null}
                      rules={[
                        {required: true, message: t('feedback_validation_required')},
                        {type: 'string', min: 1, max: 250, message: t('feedback_validation_char_range', {min: 1, max: 250})},
                      ]}
                    >
                      <Input
                        className='input-circle'
                        autoFocus
                        placeholder={t('messages_form_input_subject')}
                        disabled={isLoading || isSubmitting}
                      />
                    </Form.Item>
                  </div>
              }            
               <BodyInputChat
                name="body"
                placeholder='¡Hi!'
                label={null}
                form={form}
                defaultValue={messageData['body']}
                disabled={isLoading || isSubmitting}
                reset={resetBody}
                editorRef={editorRef}
              />

              <div style={{display: 'none'}}>
                <Button htmlType="submit"></Button>
              </div>
              
            </Form>
          </div>
         
        </Modal>
      </>
    }</Translation>
  );
}

export default MessageComposeForm;

Logger.log('silly', `MessageComposeForm loaded.`);
