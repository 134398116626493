import { normalize } from "normalizr";

import api from "../../api";
import { schema } from "../../schema";
import { addEntities } from "../../actions";
import Logger from "../../../lib/Logger";

// ACTION TYPES

export const CARE_GUIDES_PARTNERSHIP_LIST_REQUEST =
  "CARE_GUIDES_PARTNERSHIP_LIST_REQUEST";
export const CARE_GUIDES_PARTNERSHIP_LIST_SUCCESS =
  "CARE_GUIDES_PARTNERSHIP_LIST_SUCCESS";
export const CARE_GUIDES_PARTNERSHIP_LIST_FAILURE =
  "CARE_GUIDES_PARTNERSHIP_LIST_FAILURE";

  export function careGuidesPartnershipListRequest(page, limit, partnershipId) {
    Logger.log('debug', `[careGuidesPartnership.actions] careGuidesPartnershipListRequest(${page}, ${limit}, ${partnershipId})`);
    return {
      type: CARE_GUIDES_PARTNERSHIP_LIST_REQUEST,
      page: page,
      limit: limit,
      partnershipId: partnershipId,
    }
  }

  
  export function careGuidesPartnershipListSuccess(partnershipId, data) {
    Logger.log('debug', `[careGuidesPartnership.actions] careGuidesPartnershipListSuccess(%j)`, data);
    return {
      type:  CARE_GUIDES_PARTNERSHIP_LIST_SUCCESS,
      partnershipId: partnershipId,
      page: data.page,
      limit: data.limit,
      result: data.result,
      total: data.total,
      receivedAt: Date.now()
    }
  }
  
  export function careGuidesPartnershipListFailure(partnershipId, error) {
    Logger.log('debug', `[careGuidesAdmin.actions] careGuideAdminListFailure(${partnershipId}, %j)`, error);
    return {
      type:  CARE_GUIDES_PARTNERSHIP_LIST_FAILURE,
      error: error,
      partnershipId: partnershipId,
    }
  }

// API THUNK ACTION CREATORS

export function loadCareGuidesPartnership(page=1, limit=10, partnershipId=null, cb = function () {}) {
  Logger.log(
    "debug",
    `[careGuidesPartnership.actions] loadCareGuidesPartnership(${page}, ${limit}, ${partnershipId})`
  );

  return async function (dispatch) {
    dispatch(careGuidesPartnershipListRequest(page, limit, partnershipId));

    // call API
    const response = await api.getCareGuidesPartnership(page, limit, partnershipId);
    let success = false;
    let result = [];
    // get care guides partnership list success
    if (200 === response.get("status")) {
      Logger.log('info', `Get API care guides list admin success. Page: ${page}, Limit: ${limit}, Partnership ID: ${partnershipId}.`);


      const normalizedEntities = normalize(
        response.getIn(["data", "care_guides_partnership"]),
        [schema.careGuidePartnership]
      );
      const data = {
        page: response.getIn(['data', 'page']),
        limit: response.getIn(['data', 'limit']),
        total: response.getIn(['data', 'total']),
        result: normalizedEntities.result
      };
      
      result = normalizedEntities.result;
      dispatch(addEntities(normalizedEntities));
      dispatch(careGuidesPartnershipListSuccess(partnershipId, data));
      success = true;
    } else if (1 === page && 204 === response.get('status')) {
      Logger.log('info', `Get API care guides admin success [empty]. Page: ${page}, Limit: ${limit},  Partnership ID: ${partnershipId}.`);

      const data = {
        total: 0,
        result: [],
      };
      dispatch(careGuidesPartnershipListSuccess(partnershipId, data));
      success = true;

      // get care guide partnership list failure
    } else {
      Logger.log('info', `Get API care guides list failure. Page: ${page}, Limit: ${limit}, Partnership ID: ${partnershipId}.`);

      dispatch(
        careGuidesPartnershipListFailure(
          partnershipId,
          response.getIn(["data", "error"])
        )
      );
    }

    // callback function
    cb(success, result);
  };
}

Logger.log("silly", `careGuidesPartnership.actions loaded.`);
