import React, {useState} from 'react';
import {Redirect} from 'react-router-dom'
import {Translation, getI18n} from 'react-i18next';
import {Button, Typography} from 'antd';
import {CardElement, useStripe, useElements} from '@stripe/react-stripe-js';
import {CheckCircleOutlined} from '@ant-design/icons';

import {pathTo} from '../../../Routes';
import Loading from '../../../elements/components/Loading';
import message from '../../../elements/lib/MessageWrapper';
// import Config from '../../../../../Config';
import Logger from '../../../../../lib/Logger';


const CheckoutForm = props => {
  const stripe = useStripe();
  const elements = useElements();

  const [redirectTo, setRedirectTo] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);

  // form submit handler
  const handleSubmit = async (evt) => {
    evt.preventDefault();
    Logger.log('debug', `CheckoutForm.handleSubmit(###)`);

    if (!stripe || !elements) {
      return;
    }

    // generate return URL once checkout is complete
    // const returnURL = window.location.href.split('/').slice(0, 3).join('/') + pathTo(Config.get('DEFAULT_REGISTRATION_REDIRECT'));
    // const returnURL = window.location.href.split('/').slice(0, 3).join('/') + pathTo('PaymentStatusScreen');

    let result = null;

    // confirm payment with Stripe
    if (props.intent === 'payment') {
      result = await stripe.confirmCardPayment(
        props.clientSecret,
        {
          payment_method: {
            card: elements.getElement(CardElement),
          },
        }
      );

    // confirm setup with Stripe (collect payment method for free trials)
    } else if (props.intent === 'setup') {
      result = await stripe.confirmCardSetup(
        props.clientSecret,
        {
          payment_method: {
            card: elements.getElement(CardElement),
          },
        }
      );
    }

    if (result === null) {
      message.error(getI18n().t('payemnts_payment_message_failure'));
    } else if (result.error) {
      Logger.log('debug', result.error.message);
      message.error(result.error.message);
    } else {
      if (props.intent === 'payment') {
        message.success(getI18n().t('payemnts_payment_form_message_success'));
        setRedirectTo(pathTo('PaymentStatusScreen', null, {payment_intent: result.paymentIntent.id, payment_intent_client_secret: result.paymentIntent.client_secret}));
      } else if (props.intent === 'setup') {

        // free trials need to update customer with default payment method
        await props.updateCustomer({payment_method: result.setupIntent.payment_method}, (success) => {
          if (success) {
            message.success(getI18n().t('payemnts_payment_form_message_success'));
            setRedirectTo(pathTo('PaymentStatusScreen', null, {setup_intent: result.setupIntent.id, setup_intent_client_secret: result.setupIntent.client_secret}));
          } else {
            message.error(getI18n().t('payemnts_payment_message_failure'));
          }
        });
      }
    }
    setIsSubmitting(false);
  }

  const handleOnReady = element => {
    setIsLoading(false);
  }

  const handleOnClick = evt => {
    setIsSubmitting(true);
  }

  if (redirectTo) {
    return <Redirect to={redirectTo} />;
  }

  return (
    <Translation>{(t=>
      <form onSubmit={handleSubmit} >

        {isLoading
          ? <div style={{marginTop: 50}}><Loading /></div>
          : null}

        <div className='stripe-payment-form'>
          <CardElement 
            onReady={handleOnReady}
          />
        </div>

        <p className='stripe-payment-disclaimer'>
          <Typography.Text type="secondary">
            {t('payemnts_payment_form_disclaimer')}
          </Typography.Text>
        </p>

        {!isLoading
          ? <div className='payment-form-actions'>
              <Button
                type="primary"
                htmlType="submit"
                icon={<CheckCircleOutlined />}
                loading={isSubmitting}
                onClick={handleOnClick}
              >
                {t('payemnts_payment_form_button_submit')}
              </Button>
            </div>
          : null}
        
      </form>
    )}</Translation>
  );
};

export default CheckoutForm;

Logger.log('silly', `CheckoutForm loaded.`);
