import {connect} from 'react-redux';
import {List} from 'immutable';
import {denormalize} from 'normalizr';

import {schema} from '../../../../../state/schema';
import {
  patchJournalReply,
  loadJournalReplies,
  journalReplyFormDestroy,
  journalReplyHideForm
} from '../../../../../state/modules/journalReplies/actions';
import JournalReplyModalForm from '../components/JournalReplyModalForm';

const inputs = List([
  'body',
]);

const mapStateToProps = (state, ownProps) => {

  const careGuide = ownProps.careGuideId
    ? denormalize(
        state.entities.getIn(['careGuides', ownProps.careGuideId]),
        schema.careGuide,
        state.entities.toJS()
      )
    : null;

  const errors = {};
  for (const val of inputs.values()) {
    if (state.journalPosts.getIn(['form', 'errors', val])) {
      errors[val] = state.journalPosts.getIn(['form', 'errors', val]);
    }
  }

  const replyId = state.journalReplies.get('formReplyId');
  const reply = replyId
    ? denormalize(
        state.entities.getIn(['journalReplies', replyId]),
        schema.journalReply,
        state.entities.toJS()
      )
    : null;
  const data = {
    body: reply ? reply.body : '',
  };

  return {
    careGuide: careGuide,
    visible: state.journalReplies.get('formIsVisible'),
    isSubmitting: state.journalReplies.getIn(['form', 'isSubmittingEdit']),
    success: state.journalReplies.getIn(['form', 'success']),
    postId: state.journalReplies.get('formPostId'),
    replyId: replyId,
    data: data,
    errors: errors,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    patch: (careGuideId, journalPostId, id, data, cb) => {
      dispatch(patchJournalReply(careGuideId, journalPostId, id, data, cb));
    },
    load: (careGuideId, journalPostId, page, limit, order, filter, cb) => {
      dispatch(loadJournalReplies(careGuideId, journalPostId, page, limit, order, filter, cb));
    },
    formDestroy: (formState) => {
      dispatch(journalReplyFormDestroy(formState));
    },
    hideForm: () => {
      dispatch(journalReplyHideForm());
    },
  }
}

const JournalReplyModalFormContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(JournalReplyModalForm);

export default JournalReplyModalFormContainer;
