import { connect } from 'react-redux';
import { denormalize } from 'normalizr';
import { schema } from '../../../../../state/schema';

import DailyLogsScreen from '../components/DailyLogsScreen';
import {
  loadPartnershipFormAnswers,
  deletePartnershipFormAnswer,
  generatePartnershipFormAnswerPDF,
} from '../../../../../state/modules/partnershipFormAnswers/actions';

const mapStateToProps = (state, ownProps) => {
  const partnershipId = ownProps.partnershipId;
  const careGuideId = ownProps.careGuideId;
  const page = ownProps.page ?? 1;
  const limit = ownProps.limit ?? 10;
  const order = ownProps.order ?? 'created_at.asc';

  const result = state.partnershipFormAnswers.getIn([
    'pages',
    partnershipId,
    careGuideId,
    order,
    limit,
    page,
  ]);

  return {
    list: result
      ? result
          .map((x) => {
            return {
              key: x,
              ...denormalize(
                state.entities.getIn(['partnershipFormAnswers', x]),
                schema.partnershipFormAnswer,
                state.entities.toJS()
              ),
            };
          })
          .toArray()
      : [],
    isLoading: state.partnershipFormAnswers.get('isLoading'),
    total: state.partnershipFormAnswers.get('total'),
    isGeneratingPDF: state.partnershipFormAnswers.get('isGeneratingPDF'),
    page,
    limit,
    order,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    load: (partnershipId, careGuideId, page, limit, order, filter, cb) => {
      dispatch(
        loadPartnershipFormAnswers(
          partnershipId,
          careGuideId,
          page,
          limit,
          order,
          filter,
          cb
        )
      );
    },
    remove: (partnershipId, careGuideId, id, cb) => {
      dispatch(deletePartnershipFormAnswer(partnershipId, careGuideId, id, cb));
    },
    generatePDF: (partnershipId, careGuideId, id, cb) => {
      dispatch(
        generatePartnershipFormAnswerPDF(partnershipId, careGuideId, id, cb)
      );
    },
  };
};

const DailyLogsScreenContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(DailyLogsScreen);

export default DailyLogsScreenContainer;
