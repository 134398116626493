import React, { useState, useEffect } from 'react';
import { Translation } from 'react-i18next';
import { Form, Input } from 'antd';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.bubble.css';

export default function BodyInput({
  name,
  label,
  form,
  defaultValue = '',
  rules = null,
  reset = null,
  editorRef = null,
  placeholder = null,
  className = null,
  ...props
}) {
  const [value, setValue] = useState(defaultValue);

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue, setValue]);

  // allow parent to clear contents by changing `reset` to some random value
  useEffect(() => {
    if (reset) {
      setValue('');
    }
  }, [reset, setValue]);

  return (
    <Translation>
      {(t) => (
        <div className="form-group">
          <Form.Item label={label} style={{ marginBottom: 5 }}>
            <ReactQuill
              className={className ?? 'resource-note-form-add-body'}
              theme="bubble"
              value={value}
              placeholder={placeholder}
              onChange={(content, delta, source, editor) => {
                setValue(content);
                form.setFields([
                  {
                    name: [name],
                    value: editor.getHTML(),
                    errors: [],
                  },
                ]);
              }}
              ref={editorRef}
            />
          </Form.Item>

          <Form.Item
            name={name}
            rules={rules}
            hidden={false}
            className="hidden-input"
            {...props}
          >
            <Input type="hidden" />
          </Form.Item>
        </div>
      )}
    </Translation>
  );
}
