import React, { useEffect } from 'react';
import { Translation } from 'react-i18next';

import ResourceAdminForm from '../containers/ResourceAdminFormContainer';
import DocumentHead from '../../../elements/components/DocumentHead';
import Logger from '../../../../../lib/Logger';

const ResourceAdminEditScreen = ({history, ...props}) => {
  const scrollToTop = () => {
    Logger.log('debug', `ResourceAdminEditScreen.scrollToTop()`);
    if (typeof window !== 'undefined') {
      setTimeout(() => window.scrollTo(0, 0), 100);
    }
  };

  useEffect(() => {
    scrollToTop();
  }, []);

  return (
    <Translation>
      {(t) => (
        <div>
          <DocumentHead title={t('resources_route_edit')} />
          <ResourceAdminForm id={props.match.params.id}  history={history}/>
        </div>
      )}
    </Translation>
  );
};

export default ResourceAdminEditScreen;

Logger.log('silly', `ResourceAdminEditScreen loaded.`);
