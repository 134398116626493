import React, { useState, useEffect } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { Translation, getI18n } from 'react-i18next';
import { Button, Form, Input, Steps } from 'antd';
import { CheckCircleOutlined } from '@ant-design/icons';

import PasswordInput from '../../userAccount/components/PasswordInput';
import PasswordConfirmInput from '../../userAccount/components/PasswordConfirmInput';
import { pathTo } from '../../../Routes';
import message from '../../../elements/lib/MessageWrapper';
import Logger from '../../../../../lib/Logger';
import Config from '../../../../../Config';
import useWindowDimensions from '../../../../../lib/WindowDimensions';

const PasswordResetForm = ({ code, ...props }) => {
  const [redirectTo, setRedirectTo] = useState(null);
  const [passwordHasError, setPasswordHasError] = useState(false);
  const [password2HasError, setPassword2HasError] = useState(false);
  const [form] = Form.useForm();
  const { width } = useWindowDimensions();
  const tooltipPlacement = width <= 992 ? 'top' : 'right';

  // handle errors reported by API
  useEffect(() => {
    let firstFieldName = '';
    for (const field in props.errors) {
      form.setFields([{ name: field, errors: props.errors[field] }]);

      switch (field) {
        case 'password':
          setPasswordHasError(true);
          break;
        case 'password2':
          setPassword2HasError(true);
          break;
        default:
      }

      if (firstFieldName === '') {
        firstFieldName = field;
      }
    }
    form.scrollToField(firstFieldName);
  }, [form, props.errors]);

  const submitData = (values) => {
    Logger.log('debug', `PasswordResetForm.submit()`);

    // API POST/PUT payload
    let payload = {};
    for (const input of Object.keys(props.data)) {
      if (values[input]) {
        payload[input] = values[input];
      }
    }

    // register
    props.submit(payload, (success) => {
      if (success) {
        message.success(
          getI18n().t('session_password_reset_form_message_success')
        );
        setRedirectTo(pathTo(Config.get('DEFAULT_LOGIN_SCREEN')));
      } else {
        message.error(
          getI18n().t('session_password_reset_form_message_failure')
        );
      }
    });
  };

  // form submit handler
  const handleFinish = async (values) => {
    Logger.log('debug', `PasswordResetForm.handleFinish(###)`);
    if (!props.isSubmitting) {
      await submitData(values);
    }
  };

  // form error handler
  const handleFinishFailed = ({ values, errorFields, outOfDate }) => {
    Logger.log('debug', `PasswordResetForm.handleFinishFailed(###)`);
    message.error(getI18n().t('session_password_reset_form_message_failure'));
    if (errorFields && errorFields.length > 0) {
      form.scrollToField(errorFields[0].name);

      for (const field of errorFields) {
        switch (field.name[0]) {
          case 'password':
            setPasswordHasError(true);
            break;
          case 'password2':
            setPassword2HasError(true);
            break;
          default:
        }
      }
    }
  };

  // remove error message when input value changes
  const handleValuesChange = (changedValues, allValues) => {
    for (const key of Object.keys(changedValues)) {
      form.setFields([{ name: key, errors: [] }]);
      switch (key) {
        case 'password':
          setPasswordHasError(false);
          break;
        case 'password2':
          setPassword2HasError(false);
          break;
        default:
      }
    }
  };

  if (redirectTo) {
    return <Redirect to={redirectTo} />;
  }

  return (
    <Translation>
      {(t) => (
        <div>
          <Steps size="small" current={1} style={{ marginBottom: '30px' }}>
            <Steps.Step
              title={t('session_password_reset_form_steps_step1_title')}
              description={t(
                'session_password_reset_form_steps_step1_description'
              )}
            />
            <Steps.Step
              title={t('session_password_reset_form_steps_step2_title')}
            />
          </Steps>
          <Form
            name="password_reset_form"
            layout="vertical"
            form={form}
            initialValues={code ? { code: code } : {}}
            onFinish={handleFinish}
            onFinishFailed={handleFinishFailed}
            onValuesChange={handleValuesChange}
            validateTrigger="onSubmit"
          >
            {code ? (
              <div className="form-group hidden-inputs">
                <Form.Item name="code">
                  <Input type="hidden" />
                </Form.Item>
              </div>
            ) : (
              <div className="form-group">
                <Form.Item
                  name="code"
                  label={t('session_password_reset_form_input_code')}
                  rules={[
                    {
                      required: true,
                      message: t('feedback_validation_required'),
                    },
                  ]}
                >
                  <Input
                    disabled={props.isSubmitting}
                    onChange={(e) =>
                      form.setFieldsValue({
                        code: e.target.value.toUpperCase(),
                      })
                    }
                  />
                </Form.Item>
              </div>
            )}

            <PasswordInput
              name="password"
              label={t('session_password_reset_form_input_password1')}
              disabled={props.isSubmitting}
              useTooltip={true}
              tooltipPlacement={tooltipPlacement}
              hasError={passwordHasError}
            />

            <PasswordConfirmInput
              name="password2"
              label={t('session_password_reset_form_input_password2')}
              disabled={props.isSubmitting}
              hasError={password2HasError}
              form={form}
            />

            <div className="form-actions">
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="password-reset-button"
                  icon={<CheckCircleOutlined />}
                  loading={props.isSubmitting}
                  block={true}
                  size="large"
                  style={{ marginTop: '8px' }}
                >
                  {t('session_password_reset_form_button_submit')}
                </Button>

                <div className="new-code">
                  <Link to={pathTo('PasswordResetRequestScreen')}>
                    {t('session_password_reset_form_link_need_code')}
                  </Link>
                </div>
              </Form.Item>
            </div>
          </Form>
        </div>
      )}
    </Translation>
  );
};

export default PasswordResetForm;
