import React from "react";
import {Translation} from 'react-i18next';

import {happy, smiling, neutral, sad, sadTear} from './Emoji';

export default function RatingDisplay({rating, size=24, ...props}) {
  return (
    <Translation>{(t) => 
      <div className="journal-rating">
        <span className="label">{t('journal_posts_mood')}</span> <RatingIcon rating={rating} size={size} />
      </div>
    }</Translation>
  );
}

export function RatingIcon({rating, size=24, ...props}) {
  const ratings = {
    1: <span role="img" aria-label="angry face" className="rating-img">{sadTear(size)}</span>,
    2: <span role="img" aria-label="slightly frowning face" className="rating-img">{sad(size)}</span>,
    3: <span role="img" aria-label="neutral face" className="rating-img">{neutral(size)}</span>,
    4: <span role="img" aria-label="slightly smiling face" className="rating-img">{smiling(size)}</span>,
    5: <span role="img" aria-label="grinning face" className="rating-img">{happy(size)}</span>,
  };
  return (rating in ratings ? ratings[rating] : null);
}
