import React, { useState, useRef } from 'react';
import { Translation } from 'react-i18next';
import { Form, Input, Popover, Typography, Space } from 'antd';
import { CheckCircleFilled, CloseCircleOutlined } from '@ant-design/icons';

const reEmail = new RegExp(
  `^(([^<>()[\\]\\\\.,;:\\s@"]+(\\.[^<>()[\\]\\\\.,;:\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$`
);

const incompleteTextType = 'default';
const incompleteIcon = <CloseCircleOutlined style={{ color: '#00000040' }} />;

const completeTextType = 'secondary';
const completeIcon = <CheckCircleFilled style={{ color: '#52c41a' }} />;

const EmailAddressInput = ({
  name,
  hasError = false,
  label = null,
  rules = null,
  disabled = false,
  autoFocus = false,
  useTooltip = true,
  tooltipPlacement = 'right',
  useSuccess = true,
  help = '',
  ...props
}) => {
  const [isVisible, setIsVisible] = useState(false);

  const [isEmailComplete, setIsEmailComplete] = useState(false);

  const inputRef = useRef(null);

  const onChange = (e) => {
    const val = e.target.value;

    // check email
    setIsEmailComplete(reEmail.test(val));
  };

  return (
    <Translation>
      {(t) => (
        <div className="form-group">
          <Popover
            placement={tooltipPlacement}
            trigger="focus"
            title={null}
            visible={useTooltip && isVisible && !isEmailComplete}
            content={
              <>
                <Space direction="vertical">
                  <Typography.Text
                    type={
                      isEmailComplete ? completeTextType : incompleteTextType
                    }
                  >
                    {isEmailComplete ? completeIcon : incompleteIcon}{' '}
                    {t('input_requirment_email_address')}
                  </Typography.Text>
                </Space>
              </>
            }
          >
            <Form.Item
              name={name}
              label={label ? label : t('register_input_email_address')}
              rules={
                rules
                  ? rules
                  : [
                      {
                        required: true,
                        message: t('feedback_validation_required'),
                      },
                      {
                        type: 'email',
                        message: t('feedback_validation_email'),
                      },
                    ]
              }
              hasFeedback={true}
              validateStatus={
                hasError
                  ? 'error'
                  : useSuccess && isEmailComplete
                  ? 'success'
                  : null
              }
              help={help}
            >
              <Input
                disabled={disabled}
                autoFocus={autoFocus}
                onChange={onChange}
                ref={inputRef}
                onFocus={() => setIsVisible(true)}
                onBlur={() => setIsVisible(false)}
              />
            </Form.Item>
          </Popover>
        </div>
      )}
    </Translation>
  );
};

export default EmailAddressInput;
