import React, { useEffect, useState } from 'react';
import { Translation } from 'react-i18next';
import { Form, Select } from 'antd';
import { List } from 'immutable';
import { schema } from '../../../../../state/schema';
import store from '../../../../../state/store';
import { denormalize } from 'normalizr';

const { Option } = Select;

const PlanInputAdmin = ({
  name,
  form,
  label,
  total,
  isLoading,
  disabled,
  load,
  onChange,
  rules = null,
  ...props
}) => {
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [firstLoad, setFirstLoad] = useState(true);

  const [result, setResult] = useState(List([]));

  const limit = 10;
  const order = 'updated_at.desc';
  const state = store.getState();

  // load sections on mount
  useEffect(() => {
    load(page, limit, order, (success, result) => {
      if (success) {
        setResult(List(result));
      }
    });
  }, [page, limit, load]);

  const onScroll = async (event) => {
    const target = event.target;
    if (
      !isLoading &&
      target.scrollTop + target.offsetHeight === target.scrollHeight &&
      total > data.length
    ) {
      setPage(page + 1);
    }
  };

  useEffect(() => {
    const options = result
      ? result
          .map((x) => {
            return {
              key: x,
              ...denormalize(
                state.entities.getIn(['plansAdmin', x]),
                schema.planAdmin,
                state.entities.toJS()
              ),
            };
          })
          .toArray()
      : [];
    setData((prevState) => {
      const filteredOptions = options.filter(
        (newOption) =>
          !prevState.some(
            (x) => x.id === newOption.id
          )
      );

      return prevState.concat(filteredOptions);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [result]);

  useEffect(() => {
    if (firstLoad) {
      const planId = form.getFieldValue(name);
      if(planId){
        const plan = planId
        ? denormalize(
            state.entities.getIn(['plansAdmin', planId]),
            schema.planAdmin,
            state.entities.toJS()
          )
        : null;

      setData((prevState) => {
        let existObject = prevState.find((x) => x.id === plan.id);

        if (!existObject) {
          prevState.push(plan);
        }

        return prevState;
      });
      setFirstLoad(true);
      }
     
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firstLoad]);

  return (
    <Translation>
      {(t) => (
        <div className="form-group">
          <Form.Item name={name} label={label} rules={rules}>
            <Select
              showSearch
              onPopupScroll={onScroll}
              optionFilterProp="children"
              disabled={disabled}
              loading={isLoading}
              onChange={onChange}
              maxTagCount={6}
            >
              {data.map((x) => (
                <Select.Option key={x.id} value={x.id}>
                  {x.name}
                </Select.Option>
              ))}
              {isLoading && <Option>{t('form_input_loading')}</Option>}
            </Select>
          </Form.Item>
        </div>
      )}
    </Translation>
  );
};

export default PlanInputAdmin;
