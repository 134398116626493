import React from "react";
import { matchPath, Route, Switch } from "react-router-dom";
import { generatePath } from "react-router";
import { Map } from "immutable";
import { Card, Empty } from "antd";
// import {TransitionGroup, CSSTransition} from "react-transition-group";

import QueryString from "../../lib/QueryString";
import Logger from "../../lib/Logger";
import Events from "../../lib/EventEmitter";
import PrivateRoute from "./elements/containers/PrivateRouteContainer";
import Config from "../../Config";
import { lang, defaultLang } from "../../lib/Localization";

// layouts
const DefaultLayout = React.lazy(() =>
  import("./layouts/containers/DefaultLayoutContainer")
);

// public screens
const HomeScreen = React.lazy(() => import("./screens/HomeScreen"));

// screen name (key): [route type (element), path (prop), exact (prop), component (prop), name]
let defaultRoutes = Map({
  // home screen exact match should use its own layout
  HomeScreen: ["Route", "/", true, HomeScreen],
});

// all routes
let routes = Map();

// routes that use the default layout
let mainRoutes = Map();

// routes that use the admin layout
let adminRoutes = Map();

// routes that use the default+message layout
let messageRoutes = Map();

// routes that use the default+message+thread layout
let messageThreadRoutes = Map();

Events.subscribe("ADD_DEFAULT_ROUTES", (data) => {
  defaultRoutes = defaultRoutes.merge(data);
  routes = routes.merge(defaultRoutes);
});

Events.subscribe("ADD_MAIN_ROUTES", (data) => {
  mainRoutes = mainRoutes.merge(data);
  routes = routes.merge(mainRoutes);
});

Events.subscribe("ADD_MESSAGE_ROUTES", (data) => {
  messageRoutes = messageRoutes.merge(data);
  routes = routes.merge(messageRoutes);
});

Events.subscribe("ADD_MESSAGE_THREAD_ROUTES", (data) => {
  messageThreadRoutes = messageThreadRoutes.merge(data);
  routes = routes.merge(messageThreadRoutes);
});

Events.subscribe("ADD_ADMIN_ROUTES", (data) => {
  adminRoutes = adminRoutes.merge(data);
  routes = routes.merge(adminRoutes);
});

// if multiple languages are supported, use a language path prefix
const routePrefix = Config.get("LANGUAGES")
  ? `/:lang(${Config.get("LANGUAGES").join("|")})?`
  : "";
Logger.log("debug", `Routes routePrefix: ${routePrefix}`);

export { routes };

// test for existence of route
export function hasRoute(screen) {
  Logger.log("debug", `hasRoute(${screen})`);
  if (routes.has(screen)) {
    return true;
  }
}

// generate path to screen
export function pathTo(screen, params = null, queryParams = null) {
  Logger.log("debug", `pathTo(${screen}, %j)`, params);
  const path =
    defaultLang === lang
      ? generatePath(routes.get(screen)[1], params)
      : "/" + lang + generatePath(routes.get(screen)[1], params);
  return QueryString.append(path, queryParams);
}

// math path to screen
export function getRouteFromPath(path) {
  let output = null;
  routes.entrySeq().forEach((rt) => {
    const screen = rt[0];
    const route = rt[1];
    const name = route[4];
    const matched = matchPath(path, {
      path: route[1],
      exact: true, //route[2],
      strict: false,
      sensitive: false,
    });
    if (matched && name) {
      output = { screen: screen, params: matched.params, route: route };
    }
  });
  return output;
}

export function routesForBreadcrumb() {
  return mainRoutes
    .valueSeq()
    .map((x, i) => {
      return { path: x[1], exact: x[2], name: x[4], component: x[3] };
    })
    .toArray();
}

// define app routing
export function DefaultRoutes() {
  Logger.log("debug", `DefaultRoutes()`);
  return (
    <Switch>
      {defaultRoutes
        .valueSeq()
        .map((x, i) =>
          "PrivateRoute" === x[0] ? (
            <PrivateRoute
              key={i}
              path={routePrefix + x[1]}
              exact={x[2]}
              component={x[3]}
            />
          ) : (
            <Route
              key={i}
              path={routePrefix + x[1]}
              exact={x[2]}
              component={x[3]}
            />
          )
        )}
      <PrivateRoute
        key={99999}
        path="/"
        exact={false}
        component={DefaultLayout}
      />
      <Route render={() => <div> Sorry, this page does not exist. </div>} />
    </Switch>
  );
}

export function MainRoutes() {
  Logger.log("debug", `MainRoutes()`);
  return (
    <Route
      render={({ location }) => {
        return (
          <Switch location={location}>
            {mainRoutes
              .valueSeq()
              .map((x, i) =>
                "PrivateRoute" === x[0] ? (
                  <PrivateRoute
                    key={i}
                    path={routePrefix + x[1]}
                    exact={x[2]}
                    component={x[3]}
                  />
                ) : (
                  <Route
                    key={i}
                    path={routePrefix + x[1]}
                    exact={x[2]}
                    component={x[3]}
                  />
                )
              )}
            <Route
              render={() => <div> Sorry, this page does not exist. </div>}
            />
          </Switch>
        );
      }}
    />
  );
}

export function AdminRoutes() {
  Logger.log("debug", `AdminRoutes()`);
  return (
    <Switch>
      {adminRoutes
        .valueSeq()
        .map((x, i) =>
          "PrivateRoute" === x[0] ? (
            <PrivateRoute
              key={i}
              path={routePrefix + x[1]}
              exact={x[2]}
              component={x[3]}
            />
          ) : (
            <Route
              key={i}
              path={routePrefix + x[1]}
              exact={x[2]}
              component={x[3]}
            />
          )
        )}
      <Route render={() => <div> Sorry, this page does not exist. </div>} />
    </Switch>
  );
}

export function MessageRoutes() {
  Logger.log("debug", `MessageRoutes()`);
  return (
    <Switch>
      {messageRoutes
        .valueSeq()
        .map((x, i) =>
          "PrivateRoute" === x[0] ? (
            <PrivateRoute
              key={i}
              path={routePrefix + x[1]}
              exact={x[2]}
              component={x[3]}
            />
          ) : (
            <Route
              key={i}
              path={routePrefix + x[1]}
              exact={x[2]}
              component={x[3]}
            />
          )
        )}
      <Route render={() => <div> Sorry, this page does not exist. </div>} />
    </Switch>
  );
}

export function MessageThreadRoutes() {
  Logger.log("debug", `MessageThreadRoutes()`);
  return (
    <Switch>
      {messageThreadRoutes
        .valueSeq()
        .map((x, i) =>
          "PrivateRoute" === x[0] ? (
            <PrivateRoute
              key={i}
              path={routePrefix + x[1]}
              exact={x[2]}
              component={x[3]}
            />
          ) : (
            <Route
              key={i}
              path={routePrefix + x[1]}
              exact={x[2]}
              component={x[3]}
            />
          )
        )}
      <Route
        render={() => (
          <Card bordered={false} className="message-body-card">
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          </Card>
        )}
      />
    </Switch>
  );
}

Logger.log("silly", `Routes loaded.`);
