import {normalize} from 'normalizr';

import api from '../../api';
import {schema} from '../../schema';
import {addEntities, removeEntity} from '../../actions';
import Logger from '../../../lib/Logger';

export const TYPES = {
  CREATE_CUSTOMER_REQUEST: 'PAYMENTS/CREATE_CUSTOMER_REQUEST',
  CREATE_CUSTOMER_SUCCESS: 'PAYMENTS/CREATE_CUSTOMER_SUCCESS',
  CREATE_CUSTOMER_FAILURE: 'PAYMENTS/CREATE_CUSTOMER_FAILURE',
  UPDATE_CUSTOMER_REQUEST: 'PAYMENTS/UPDATE_CUSTOMER_REQUEST',
  UPDATE_CUSTOMER_SUCCESS: 'PAYMENTS/UPDATE_CUSTOMER_SUCCESS',
  UPDATE_CUSTOMER_FAILURE: 'PAYMENTS/UPDATE_CUSTOMER_FAILURE',
  CREATE_SUBSCRIPTION_REQUEST: 'PAYMENTS/CREATE_SUBSCRIPTION_REQUEST',
  CREATE_SUBSCRIPTION_SUCCESS: 'PAYMENTS/CREATE_SUBSCRIPTION_SUCCESS',
  CREATE_SUBSCRIPTION_FAILURE: 'PAYMENTS/CREATE_SUBSCRIPTION_FAILURE',
  READ_SUBSCRIPTION_REQUEST: 'PAYMENTS/READ_SUBSCRIPTION_REQUEST',
  READ_SUBSCRIPTION_SUCCESS: 'PAYMENTS/READ_SUBSCRIPTION_SUCCESS',
  READ_SUBSCRIPTION_FAILURE: 'PAYMENTS/READ_SUBSCRIPTION_FAILURE',
  DELETE_SUBSCRIPTION_REQUEST: 'PAYMENTS/DELETE_SUBSCRIPTION_REQUEST',
  DELETE_SUBSCRIPTION_SUCCESS: 'PAYMENTS/DELETE_SUBSCRIPTION_SUCCESS',
  DELETE_SUBSCRIPTION_FAILURE: 'PAYMENTS/DELETE_SUBSCRIPTION_FAILURE',
};

export function paymentsCreateCustomerRequest() {
  Logger.log('debug', `[state.payments.actions] paymentsCreateCustomerRequest())`);
  return {
    type: TYPES.CREATE_CUSTOMER_REQUEST,
  }
}

export function paymentsCreateCustomerSuccess(data) {
  Logger.log('debug', `[state.payments.actions] paymentsCreateCustomerSuccess(%j)`, data);
  return {
    type: TYPES.CREATE_CUSTOMER_SUCCESS,
    id: data.id,
    receivedAt: Date.now()
  }
}

export function paymentsCreateCustomerFailure(error) {
  Logger.log('debug', `[state.payments.actions] paymentsCreateCustomerFailure(%j)`, error);
  return {
    type: TYPES.CREATE_CUSTOMER_FAILURE,
    error: error
  }
}

export function paymentsUpdateCustomerRequest(data) {
  Logger.log('debug', `[state.payments.actions] paymentsUpdateCustomerRequest(%j), data`);
  return {
    type: TYPES.UPDATE_CUSTOMER_REQUEST,
  }
}

export function paymentsUpdateCustomerSuccess(data) {
  Logger.log('debug', `[state.payments.actions] paymentsUpdateCustomerSuccess(%j)`, data);
  return {
    type: TYPES.UPDATE_CUSTOMER_SUCCESS,
    id: data.id,
    receivedAt: Date.now()
  }
}

export function paymentsUpdateCustomerFailure(error) {
  Logger.log('debug', `[state.payments.actions] paymentsUpdateCustomerFailure(%j)`, error);
  return {
    type: TYPES.UPDATE_CUSTOMER_FAILURE,
    error: error
  }
}

export function paymentsCreateSubscriptionRequest(data) {
  Logger.log('debug', `[state.payments.actions] paymentsCreateSubscriptionRequest(%j)`, data);
  return {
    type: TYPES.CREATE_SUBSCRIPTION_REQUEST,
  }
}

export function paymentsCreateSubscriptionSuccess(data) {
  Logger.log('debug', `[state.payments.actions] paymentsCreateSubscriptionSuccess(%j)`, data);
  return {
    type: TYPES.CREATE_SUBSCRIPTION_SUCCESS,
    id: data.id,
    intent: data.intent,
    clientSecret: data.client_secret,
    planId: data.plan_id,
    subscriptionCycle: data.subscription_cycle,
    receivedAt: Date.now()
  }
}

export function paymentsCreateSubscriptionFailure(error) {
  Logger.log('debug', `[state.payments.actions] paymentsCreateSubscriptionFailure(%j)`, error);
  return {
    type: TYPES.CREATE_SUBSCRIPTION_FAILURE,
    error: error
  }
}

export function paymentsReadSubscriptionRequest() {
  Logger.log('debug', `[state.payments.actions] paymentsReadSubscriptionRequest()`);
  return {
    type: TYPES.READ_SUBSCRIPTION_REQUEST,
  }
}

export function paymentsReadSubscriptionSuccess(data) {
  Logger.log('debug', `[state.payments.actions] paymentsReadSubscriptionSuccess(%j)`, data);
  return {
    type: TYPES.READ_SUBSCRIPTION_SUCCESS,
    id: data.id,
    receivedAt: Date.now()
  }
}

export function paymentsReadSubscriptionFailure(error) {
  Logger.log('debug', `[state.payments.actions] paymentsReadSubscriptionFailure(%j)`, error);
  return {
    type: TYPES.READ_SUBSCRIPTION_FAILURE,
    error: error
  }
}

export function paymentsDeleteSubscriptionRequest(id) {
  Logger.log('debug', `[state.payments.actions] paymentsDeleteSubscriptionRequest(${id})`);
  return {
    type: TYPES.DELETE_SUBSCRIPTION_REQUEST,
    id: id,
  }
}

export function paymentsDeleteSubscriptionSuccess(id, data) {
  Logger.log('debug', `[state.payments.actions] paymentsDeleteSubscriptionSuccess(${id})`);
  return {
    type: TYPES.DELETE_SUBSCRIPTION_SUCCESS,
    id: id,
    newId: data.id,
  }
}

export function paymentsDeleteSubscriptionFailure(id, error) {
  Logger.log('debug', `[state.payments.actions] paymentsDeleteSubscriptionFailure(${id}, %j)`, error);
  return {
    type: TYPES.DELETE_SUBSCRIPTION_FAILURE,
    id: id,
    error: error,
  }
}


// API THUNK ACTION CREATORS

export function createCustomer(cb=function(){}) {
  Logger.log('debug', `[state.payments.actions] createCustomer(###)`);

  return async function(dispatch) {
    dispatch(paymentsCreateCustomerRequest());

    // call API
    const response = await api.postPaymentsCustomer();
    let success = false;

    // post customer success
    if ([201, 409].includes(response.get('status'))) {

      Logger.log('info', `POST API customer success..`);

      const data = {
        id: response.getIn(['data', 'customer', 'id']),
      };

      dispatch(paymentsCreateCustomerSuccess(data));
      success = true;
      
    // post customer failure
    } else {
      Logger.log('info', `POST API customer failure.`);
      dispatch(paymentsCreateCustomerFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  }
}

export function updateCustomer(payload, cb=function(){}) {
  Logger.log('debug', `[state.payments.actions] updateCustomer(%j, ###)`, payload);

  return async function(dispatch) {
    dispatch(paymentsUpdateCustomerRequest());

    // call API
    const response = await api.putPaymentsCustomer(payload);
    let success = false;

    // put customer success
    if ([200].includes(response.get('status'))) {

      Logger.log('info', `PUT API customer success..`);

      const data = {
        id: response.getIn(['data', 'customer', 'id']),
      };

      dispatch(paymentsUpdateCustomerSuccess(data));
      success = true;
      
    // put customer failure
    } else {
      Logger.log('info', `PUT API customer failure.`);
      dispatch(paymentsUpdateCustomerFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  }
}

export function createSubscription(data, cb=function(){}) {
  Logger.log('debug', `[state.payments.actions] createSubscription(%j, ###)`, data);

  return async function(dispatch) {
    dispatch(paymentsCreateSubscriptionRequest(data));

    // call API
    const response = await api.postPaymentsSubscription(data);
    let success = false;
    let clientSecret = null

    // post subscription success
    if ([201, 409].includes(response.get('status'))) {

      Logger.log('info', `POST API subscription success.`);

      const action = {
        id: response.getIn(['data', 'subscription', 'id']),
        intent: response.getIn(['data', 'subscription', 'intent']),
        client_secret: response.getIn(['data', 'subscription', 'client_secret']),
        plan_id: data.plan_id,
        subscription_cycle: data.subscription_cycle,
      };

      dispatch(paymentsCreateSubscriptionSuccess(action));
      success = true;
      clientSecret= data.client_secret;
      
    // post subscription failure
    } else {
      Logger.log('info', `POST API subscription failure.`);
      dispatch(paymentsCreateSubscriptionFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success, clientSecret);
  }
}

export function loadSubscription(cb=function(){}) {
  Logger.log('debug', `[state.payments.actions] loadSubscription(###)`);

  return async function(dispatch) {
    dispatch(paymentsReadSubscriptionRequest());

    // call API
    const response = await api.getPaymentsSubscription();
    let success = false;

    // get subscription success
    if (200 === response.get('status')) {

      Logger.log('info', `Get API subscription success.`);

      const normalizedEntities = normalize([response.getIn(['data', 'subscription'])], [schema.subscription]);
      const data = {
        id: response.getIn(['data', 'subscription', 'id']),
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(paymentsReadSubscriptionSuccess(data));
      success = true;
      
    // get subscription failure
    } else {
      Logger.log('info', `Get API subscription failure.`);
      dispatch(paymentsReadSubscriptionFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  }
}

export function deleteSubscription(id, cb=function(){}) {
  Logger.log('debug', `[state.payments.actions] deleteSubscription(${id}, ###)`);

  return async function(dispatch) {
    dispatch(paymentsDeleteSubscriptionRequest(id));

    // call API
    const response = await api.deletePaymentsSubscription(id);
    let success = false;

    // delete subscription success
    if ([200, 204].includes(response.get('status'))) {

      Logger.log('info', `DELETE API subscription success. ID: ${id}.`);

      const normalizedEntities = normalize([response.getIn(['data', 'subscription'])], [schema.subscription]);
      const data = {
        id: response.getIn(['data', 'subscription', 'id']),
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(removeEntity({entityType: 'subscription', id: id}));
      dispatch(paymentsDeleteSubscriptionSuccess(id, data));
      success = true;
      
    // get subscription failure
    } else {
      Logger.log('info', `DELETE API subscription failure. ID: ${id}.`);
      dispatch(paymentsDeleteSubscriptionFailure(id, response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  }
}

Logger.log('silly', `state.payments.actions loaded.`);
