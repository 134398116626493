import React, { useState } from 'react';
import { withRouter } from 'react-router';
import { Translation } from 'react-i18next';
import { Button, Space } from 'antd';

import { pathTo } from '../../Routes';
import {
  BarcodeOutlined,
  TagsOutlined,
  UserSwitchOutlined,
  CalendarOutlined,
  ShareAltOutlined,
  ContactsOutlined,
  PushpinOutlined,
  SolutionOutlined,
} from '@ant-design/icons';
import { MixpanelTracker, mixpanelEventsEnum } from '../../../../lib/Mixpanel';
import { Redirect } from 'react-router-dom';

const UserProfilesPartnershipsChooserListActions = ({
  item,
  profileId,
  setActive,
  careGuideCount,
  listCareGuide,
  setActiveCareGuide,
  pageUserProfilePartnership,
  page,
  partnershipActiveId,
  storage,
  ...props
}) => {
  const [redirectTo, setRedirectTo] = useState(null);

  const onClick = (event, pageUserProfilePartnership, item, path) => {
    event.preventDefault();
    storage.set('local', 'partnershipId', item.partnership_id);
    storage.set('local', 'partnershipData', {
      access_role_admin_in_care_guide:
        item.partnership.access_role_admin_in_care_guide,
      access_role_admin_in_app: item.partnership.access_role_admin_in_app,
      access_tabs_in_app: item.partnership.access_tabs_in_app,
      role: item.role,
      name: item.partnership_name,
    });
    setActive(item.id);
    storage.set('local', 'userProfilePartnershipId', item.id);
    storage.set(
      'local',
      'userProfilePartnershipPage',
      pageUserProfilePartnership
    );

    MixpanelTracker.mixpanelTrack(
      mixpanelEventsEnum.PAGES.ACCESS_PAGE_ADMINISTRATOR,
      {
        partnershipName: item.partnership_name,
        partnershipId: item.partnership_id,
        page: path,
      }
    );

    setRedirectTo(
      pathTo(path, {
        id: item.partnership_id,
        page: page,
        page_partnership: pageUserProfilePartnership,
      })
    );
  };

  const selectPartnership = (
    event,
    pageUserProfilePartnership,
    item,
    partnershipActiveId
  ) => {
    if (partnershipActiveId !== item.partnership_id) {
      setActive(item.id);
      storage.set('local', 'partnershipId', item.partnership_id);
      storage.set('local', 'partnershipData', {
        access_role_admin_in_care_guide:
          item.partnership.access_role_admin_in_care_guide,
        access_role_admin_in_app: item.partnership.access_role_admin_in_app,
        access_tabs_in_app: item.partnership.access_tabs_in_app,
        role: item.role,
        name: item.partnership_name,
      });
      storage.set('local', 'userProfilePartnershipId', item.id);
      storage.set(
        'local',
        'userProfilePartnershipPage',
        pageUserProfilePartnership
      );
    } else {
      setActive(null);
      storage.set('local', 'partnershipId', null);
      storage.set('local', 'partnershipData', {
        access_role_admin_in_care_guide: null,
        access_role_admin_in_app: null,
        access_tabs_in_app: null,
        role: null,
        name: null,
      });
      storage.set('local', 'userProfilePartnershipId', item.id);
      storage.set(
        'local',
        'userProfilePartnershipPage',
        pageUserProfilePartnership
      );
    }
  };

  if (redirectTo) {
    return <Redirect to={redirectTo} />;
  }

  return (
    <Translation>
      {(t) => (
        <>
          <Space>
            {item.partnership.access_role_admin_in_app.partnership_account ||
            item.role === 1 ? (
              <Button
                title={t('title_button_partnership')}
                icon={<ShareAltOutlined />}
                onClick={(e) =>
                  onClick(
                    e,
                    pageUserProfilePartnership,
                    item,
                    'PartnershipAccountScreen'
                  )
                }
              ></Button>
            ) : null}
            {item.partnership.access_role_admin_in_app.register_code ||
            item.role === 1 ? (
              <Button
                title={t('title_button_registration_code')}
                icon={<BarcodeOutlined />}
                onClick={(e) =>
                  onClick(
                    e,
                    pageUserProfilePartnership,
                    item,
                    'RegistrationCodesScreen'
                  )
                }
              ></Button>
            ) : null}
            {item.partnership.access_role_admin_in_app.journal_partner_tags ||
            item.role === 1 ? (
              <Button
                title={t('title_button_journal_partner_tags')}
                icon={<TagsOutlined />}
                onClick={(e) =>
                  onClick(
                    e,
                    pageUserProfilePartnership,
                    item,
                    'JournalPartnerTagsAdminScreen'
                  )
                }
              ></Button>
            ) : null}
            {item.partnership.access_role_admin_in_app.user_roles ||
            item.role === 1 ? (
              <Button
                title={t('title_button_user_roles')}
                icon={<UserSwitchOutlined />}
                onClick={(e) =>
                  onClick(
                    e,
                    pageUserProfilePartnership,
                    item,
                    'UserProfilesPartnershipsAdminScreen'
                  )
                }
              ></Button>
            ) : null}
            {item.partnership.access_role_admin_in_app.calendar ||
            item.role === 1 ? (
              <Button
                title={t('title_button_calendar_events')}
                icon={<CalendarOutlined />}
                onClick={(e) =>
                  onClick(
                    e,
                    pageUserProfilePartnership,
                    item,
                    'CalendarEventsAdminScreen'
                  )
                }
              ></Button>
            ) : null}
            {item.partnership.access_role_admin_in_app.resources ||
            item.role === 1 ? (
              <Button
                title={t('title_button_resources')}
                icon={<ContactsOutlined />}
                onClick={(e) =>
                  onClick(
                    e,
                    pageUserProfilePartnership,
                    item,
                    'ResourcesAdminScreen'
                  )
                }
              ></Button>
            ) : null}
            {item.partnership.access_role_admin_in_app.care_guides ||
            item.role === 1 ? (
              <Button
                title={t('title_button_care_guides')}
                icon={<SolutionOutlined />}
                onClick={(e) =>
                  onClick(
                    e,
                    pageUserProfilePartnership,
                    item,
                    'CareGuidesAdminScreen'
                  )
                }
              ></Button>
            ) : null}
            <Button
              type={
                partnershipActiveId === item.partnership_id
                  ? 'primary'
                  : 'default'
              }
              title={t('title_button_active_partnership')}
              icon={<PushpinOutlined />}
              onClick={(e) =>
                selectPartnership(
                  e,
                  pageUserProfilePartnership,
                  item,
                  partnershipActiveId
                )
              }
            ></Button>
          </Space>
        </>
      )}
    </Translation>
  );
};

export default withRouter(UserProfilesPartnershipsChooserListActions);
