import { connect } from 'react-redux';
import { getI18n } from 'react-i18next';
import { denormalize } from 'normalizr';
import { schema } from '../../../../../state/schema';
import {
  loadCareGuides,
  loadCareGuidesAllIds,
} from '../../../../../state/modules/careGuides/actions';
import { resourceShowForm } from '../../../../../state/modules/resources/actions';
import { loadCareGuideOwnersFilters } from '../../../../../state/modules/adminPartnerClasses/actions';
import { calendarShowEventForm } from '../../../../../state/modules/calendar/actions';
import MembersListTable from '../components/MembersListTable';
import Format from '../../../../../lib/Format';
import storage from '../../../../../lib/Storage';

import moment from 'moment-timezone';

const mapStateToProps = (state, ownProps) => {
  const subscriptionsStatusFilter = {
    care_guide_partner_tag: [
      {
        category: 'Subscription Status',
        color_hex: 'green',
        id: 'active_filter',
        tagText: getI18n().t('members_subscription_subscription_active'),
        value: '1',
        label: getI18n().t('members_subscription_status_active'),
        filterKey: 'account_subscription_status',
      },
      {
        category: 'Subscription Status',
        id: 'pending_filter',
        tagText: getI18n().t('members_subscription_subscription_pending'),
        value: '0,2,3,4',
        label: getI18n().t('members_subscription_status_pending'),
        filterKey: 'account_subscription_status',
      },
    ],
    id: 'subscription_status',
    name: getI18n().t('members_subscription_status'),
    filterKey: 'account_subscription_status',
  };

  const eventFormView = 'month';
  const eventFormStart = moment().startOf(eventFormView).format('YYYY-MM-DD');
  const eventFormEnd = moment(eventFormStart)
    .endOf(eventFormView)
    .format('YYYY-MM-DD');

  let allPageCareGuideOwnerIds = [];

  const order = ownProps.order ? ownProps.order : 'started_at.asc';
  const limit = ownProps.limit ? ownProps.limit : 10;
  const page = ownProps.page ? ownProps.page : 1;
  const result = state.careGuides.getIn(['pages', order, limit, page]);

  function getStatusValues(status) {
    const isActiveMember = status === 1;
    return {
      id: status,
      color_hex: isActiveMember ? 'green' : 'default',
      label: getI18n().t(
        isActiveMember
          ? 'members_subscription_status_active'
          : 'members_subscription_status_pending'
      ),
    };
  }

  const list = result
    ? result
        .map((x) => {
          allPageCareGuideOwnerIds.push(x);
          return {
            key: x,
            ...denormalize(
              state.entities.getIn(['careGuides', x]),
              schema.careGuide,
              state.entities.toJS()
            ),
          };
        })
        .toArray()
        .filter(
          (x) => x.partnership?.is_plan_valid && x.partnership?.status === 1
        )
        .map((x) => {
          const hasCarePartnerTags = !!Object.keys(x.care_partner_tags).length;
          const result = {
            ...x,
            owner_profile_name:
              x.owner.profile.first_name + ' ' + x.owner.profile.last_name,
            caregiver_mail: x.owner.profile.email,
            caregiver_phone: Format.telephone(x.owner.profile.telephone),
            care_guide_name: x.first_name + ' ' + x.last_name,
            hasCarePartnerTags,
          };
          if (hasCarePartnerTags) {
            Object.keys(x.care_partner_tags).forEach((key) => {
              result[`table column ${key}`] = x.care_partner_tags[key];
            });
          }
          result['table column Subscription status'] = [
            getStatusValues(x.account.subscription_status),
          ];
          return result;
        })
    : [];

  const filtersData = state.adminPartnerClasses.get('filters').toArray();
  function addValueProperty(data) {
    const newData = data.map((obj, key) => {
      const emptyTag = {
        id: key,
        label: 'Empty',
        tagText: `Empty - ${obj.name}`,
        value: 'empty',
        category: obj.name,
        filterKey: 'class_tags',
      };
      const careGuideTags = obj.care_guide_partner_tag.map((tag) => {
        return {
          ...tag,
          label: Format.capitalize(tag.label),
          tagText: obj.use_tag_yes_or_no
            ? `${obj.name} - ${tag.label}`
            : tag.label,
          value: tag.label,
          category: obj.name,
          filterKey: 'class_tags',
        };
      });
      return {
        ...obj,
        care_guide_partner_tag: [...careGuideTags, emptyTag],
        filterKey: 'class_tags',
      };
    });
    newData.push(subscriptionsStatusFilter);
    return newData;
  }
  const tableFilters = addValueProperty(filtersData);
  const profileId = state.session.get('profileId');

  return {
    list,
    profileId,
    eventFormStart,
    eventFormEnd,
    tableFilters,
    allUsersIds: state.careGuides.get('allIds').toArray(),
    total: state.careGuides.get('total'),
    isLoading: state.careGuides.get('isLoading'),
    isLoadingIds: state.careGuides.get('isLoadingIds'),
    partnershipActiveId: storage.get('local', 'partnershipId'),
    allPageCareGuideOwnerIds,
    accessCareGuide: storage.get('local', 'partnershipData')
      ?.access_role_admin_in_care_guide,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    load: (page, limit, order, filter, partnershipId, cb) => {
      dispatch(loadCareGuides(page, limit, order, filter, partnershipId, cb));
    },
    loadFilters: (id) => {
      dispatch(loadCareGuideOwnersFilters(id));
    },
    loadAllIds: (id, filter, cb) => {
      dispatch(loadCareGuidesAllIds(id, filter, cb));
    },
    showEventForm: (eventId, startDate) => {
      dispatch(calendarShowEventForm(eventId, startDate));
    },
    showResourceForm: (careGuideId) => {
      dispatch(resourceShowForm(careGuideId));
    },
  };
};

const UserProfilesChooserHomeListContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(MembersListTable);

export default UserProfilesChooserHomeListContainer;
