import { connect } from 'react-redux';
import { List } from 'immutable';

import {
  loadPartnershipAccount,
  updatePartnershipAccount,
  partnershipAccountFormDestroy,
} from '../../../../../state/modules/partnershipAccount/actions';

import PartnershipAccountForm from '../components/PartnershipAccountForm';
import storage from '../../../../../lib/Storage';

const inputs = List(['name', 'color_hex', 'hide_partner_tags']);

const mapStateToProps = (state) => {
  const data = {},
    errors = {};
  for (const val of inputs.values()) {
    data[val] = state.partnershipAccount.getIn(['data', val], '');
    if (state.partnershipAccount.getIn(['form', 'errors', val])) {
      errors[val] = state.partnershipAccount.getIn(['form', 'errors', val]);
    }
  }

  return {
    activeId: storage.get('local', 'partnershipId'),
    isLoading: state.partnershipAccount.get('isLoading'),
    isSubmitting: state.partnershipAccount.get('isSubmitting'),
    success: state.partnershipAccount.getIn(['form', 'success']),
    data: data,
    errors: errors,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    load: (partnership_id, cb) => {
      dispatch(loadPartnershipAccount(partnership_id, cb));
    },
    submit: (partnership_id, data, cb) => {
      dispatch(updatePartnershipAccount(partnership_id, data, cb));
    },
    formDestroy: (formState) => {
      dispatch(partnershipAccountFormDestroy(formState));
    },
  };
};

const PartnershipAccountFormContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(PartnershipAccountForm);

export default PartnershipAccountFormContainer;
