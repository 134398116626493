import {connect} from 'react-redux';
import {List} from 'immutable';

import {
  creatCareGuideInvitation,
  careGuideInvitationFormDestroy,
} from '../../../../../state/modules/careGuideInvitations/actions';
import {loadCareGuideHelpers} from '../../../../../state/modules/careGuideHelpers/actions';
import RegisterStep3Form from '../components/RegisterStep3Form';

const inputs = List([
  'email',
  'role',
  'tags',
]);

const mapStateToProps = (state, ownProps) => {

  const errors = {};
  for (const val of inputs.values()) {
    if (state.careGuideInvitations.getIn(['form', 'errors', val])) {
      errors[val] = state.careGuideInvitations.getIn(['form', 'errors', val]);
    }
  }

  const data = {
    email: '',
    role: 2,
    public_status: '',
    tags: [],
  };
  
  return {
    isSubmitting: state.careGuideInvitations.getIn(['form', 'isSubmitting']),
    success: state.careGuideInvitations.getIn(['form', 'success']),
    data: data,
    errors: errors
  }
}

const mapDispatchToProps = dispatch => {
  return {
    load: (careGuideId, page, limit, order, filter, cb) => {
      dispatch(loadCareGuideHelpers(careGuideId, page, limit, order, filter, cb));
    },
    create: (careGuideId, data, cb) => {
      dispatch(creatCareGuideInvitation(careGuideId, data, cb));
    },
    formDestroy: (formState) => {
      dispatch(careGuideInvitationFormDestroy(formState));
    },
  }
}

const RegisterStep3FormContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(RegisterStep3Form);

export default RegisterStep3FormContainer;
