import {connect} from 'react-redux';

import JournalPostDetail from '../components/JournalPostDetail';
import {
  journalPostShowForm,
  patchJournalPost,
  loadJournalPosts,
} from '../../../../../state/modules/journalPosts/actions';

const mapStateToProps = (state, ownProps) => {
  const profileId = state.session.get('profileId');
  return {
    profileId: profileId,
    isCareGuideOwner: profileId === state.entities.getIn(['careGuides', ownProps.careGuideId, 'owner', 'profile', 'id']),
  }
}

const mapDispatchToProps = dispatch => {
  return {
    showForm: (id) => {
      dispatch(journalPostShowForm(id));
    },
    patch: (careGuideId, id, data, cb) => {
      dispatch(patchJournalPost(careGuideId, id, data, cb));
    },
    load: (careGuideId, page, limit, order, filter, cb) => {
      dispatch(loadJournalPosts(careGuideId, page, limit, order, filter, cb));
    },
  }
}

const JournalPostDetailContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(JournalPostDetail);

export default JournalPostDetailContainer;
