import React, { useEffect } from "react";
import { Translation, getI18n } from "react-i18next";
import {
  Button,
  Form,
  Modal,
  Popconfirm,
} from "antd";
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import message from "../../../elements/lib/MessageWrapper";
import Logger from "../../../../../lib/Logger";
import PublicStatusInput from "./PublicStatusInput";
import MembersInput from "../../careGuideHelpers/containers/MembersInputContainer";
import CareGuidesMembersPartnership from "../../careGuideHelpers/components/CareGuidesMembersPartnership";

const EventParticipantForm = ({
  inputs,
  eventId,
  participantId,
  page,
  errors,
  isLoading,
  pageParticipants,
  loadParticipants,
  isSubmitting,
  data,
  isMultipleCareGuide,
  partnershipId,
  careGuideIds,
  options,
  ...props
}) => {

  const [form] = Form.useForm();

  // populate form with event data
  useEffect(() => {
    form.setFieldsValue(data);
  }, [participantId, form]); // eslint-disable-line react-hooks/exhaustive-deps

  // handle errors reported by API
  useEffect(() => {
    let firstFieldName = "";
    for (const field in errors) {
      form.setFields([{ name: field, errors: errors[field] }]);
      if (firstFieldName === "") {
        firstFieldName = field;
      }
    }
    form.scrollToField(firstFieldName);
  }, [form, errors]);

  // submit data handler
  const submitData = async (values) => {
    Logger.log("debug", `EventParticipantForm.submitData(###)`);

    const payload = {};

    // transform values
    for (var key in values) {
      // transform boolean values
      payload[key] = values[key];
    }


    if (participantId) {
      // update
      payload['event_id'] = eventId;

      props.update(participantId, payload, (success) => {
        if (success) {
          message.success(getI18n().t("feedback_form_success"));
          clearForm();
          loadParticipants(eventId, pageParticipants);
        } else {
          message.error(getI18n().t("feedback_form_error"));
        }
      });
    } else {
      // create
      props.create(eventId, payload, (success) => {
        if (success) {
          message.success(getI18n().t("feedback_form_success"));
          clearForm();
          loadParticipants(eventId, pageParticipants);
        } else {
          message.error(getI18n().t("feedback_form_error"));
        }
      });
    }
  };

  const clearForm = () => {
    props.hideForm();
    props.formDestroy();
    form.setFieldsValue({
      public_status: "",
      user_profile_id: null,
    });
    for (const key of Object.keys(data)) {
      form.setFields([{ name: key, errors: [] }]);
    }
  };

  // form submit handler
  const handleFinish = async (values) => {
    Logger.log("debug", `EventParticipantForm.handleFinish(###)`);
    if (!props.isSubmitting) {
      await submitData(values);
    }
  };

  // form error handler
  const handleFinishFailed = ({ values, errorFields, outOfDate }) => {
    Logger.log("debug", `EventParticipantForm.handleFinishFailed(###)`);
    message.error(getI18n().t("feedback_form_error"));
    if (errorFields && errorFields.length > 0) {
      form.scrollToField(errorFields[0].name);
    }
  };

  // remove error message when input value changes
  const handleValuesChange = (changedValues, allValues) => {
    for (const key of Object.keys(changedValues)) {
      form.setFields([{ name: key, errors: [] }]);
    }
  };

  const onClickOK = () => {
    form.submit();
  };

  const onClickDelete = () => {

    props.delete(participantId, (success) => {
      if (success) {
        message.success(getI18n().t("feedback_delete_success"));
        clearForm();
        loadParticipants(eventId, pageParticipants);
      } else {
        message.error(getI18n().t("feedback_delete_error"));
      }
    });
  };

  return (
    <Translation>
      {(t) => (
        <>
          <Modal
            title={
              participantId
                ? t("calendar_event_participant_form_title_edit")
                : t("calendar_event_participant_form_title_create")
            }
            centered
            visible={props.visible}
            onCancel={() => clearForm()}
            footer={[
              <Button
                key="close"
                icon={<CloseCircleOutlined />}
                onClick={() => clearForm()}
              >
                {t("action_close")}
              </Button>,
              participantId ? (
                <Popconfirm
                  key="delete-confirm"
                  placement="top"
                  title={t("calendar_event_participant_form_delete_confirm_body")}
                  onConfirm={onClickDelete}
                  okText={t("confirm_yes")}
                  cancelText={t("confirm_cancel")}
                >
                  <Button key="delete" danger icon={<DeleteOutlined />}>
                    {t("action_delete")}
                  </Button>
                </Popconfirm>
              ) : null,
              <Button
                key="submit"
                type="primary"
                icon={<CheckCircleOutlined />}
                loading={isSubmitting}
                onClick={onClickOK}
              >
                {t("calendar_event_form_button_submit")}
              </Button>,
            ]}
            width={500}
            bodyStyle={{ paddingBottom: 0 }}
            forceRender={true}
          >
            <div className="calendar-participant-event-form">
              <Form
                name="calendar_event_participant_form"
                form={form}
                initialValues={data}
                onFinish={handleFinish}
                onFinishFailed={handleFinishFailed}
                onValuesChange={handleValuesChange}
                validateTrigger="onSubmit"
                requiredMark={false}
                layout="vertical"
              >

                {isMultipleCareGuide ? (
                  <MembersInput
                    name={"user_profile_id"}
                    label={t("calendar_event_participant_user")}
                    careGuideIds={careGuideIds}
                    rules={[
                      {
                        required: true,
                        message: t("feedback_validation_required"),
                      },
                    ]}
                  />
                ) : (
                  <CareGuidesMembersPartnership
                    options={options}
                    name={"user_profile_id"}
                    label={t("calendar_event_participant_user")}
                    partnershipId={partnershipId}
                    rules={[
                      {
                        required: true,
                        message: t("feedback_validation_required"),
                      },
                    ]}
                  />
                )}

                <PublicStatusInput
                  name="public_status"
                  label={t("calendar_event_participant_public_status")}
                  rules={[
                    { required: true, message: t("feedback_validation_required") },
                  ]}
                  disabled={isLoading || isSubmitting}
                />

                <div style={{ display: "none" }}>
                  <Button htmlType="submit"></Button>
                </div>
              </Form>

            </div>
          </Modal>
        </>
      )}
    </Translation>
  );
};

export default EventParticipantForm;

Logger.log("silly", `EventParticipantForm loaded.`);
