import {connect} from 'react-redux';

import {loadcareGuideHelperTags} from '../../../../../state/modules/careGuideHelperTags/actions';
import TagInput from '../components/TagInput';

const mapStateToProps = (state) => {
  const tags = state.entities.get('careGuideHelperTags', {});
  return {
    options: Object.keys(tags)
      .map(id => {return {label: tags[id].label, value: tags[id].label}}),
    isLoading: state.careGuideHelperTags.get('isLoading'),
  }
}

const mapDispatchToProps = dispatch => {
  return {
    load: (page, limit, order, filter, cb) => {
      dispatch(loadcareGuideHelperTags(page, limit, order, filter, cb));
    },
  }
}

const TagInputContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(TagInput);

export default TagInputContainer;
