import { Map, List } from "immutable";

import { TYPES } from "./actions";
import Logger from "../../../lib/Logger";

export default function adminPartnerClasses(
  state = Map({
    isLoading: false,
    isCSVDownloading: false,
    filters: List([]),
    pages: {},
  }),
  action
) {
  Logger.log(
    "debug",
    `[adminPartnerClasses.reducers] adminPartnerClasses(%j, %j)`,
    state,
    action
  );

  switch (action.type) {
    case TYPES.ADMIN_PARTNER_CLASS_PARTNERSHIP_LIST_REQUEST:
      return state.mergeDeep({
        isLoading: true,
      });

    case TYPES.ADMIN_PARTNER_CLASS_PARTNERSHIP_LIST_SUCCESS:
      return state
        .mergeDeep({
          isLoading: false,
          adminPartnerClasses: {
            [action.partnershipId]: {
              total: action.total,
            },
          },
          lastUpdated: action.receivedAt,
        })
        .setIn(
          ["adminPartnerClasses", action.partnershipId],
          List(action.result)
        );

    case TYPES.ADMIN_PARTNER_CLASS_PARTNERSHIP_LIST_FAILURE:
      return state.mergeDeep({
        isLoading: false,
      });

    case TYPES.FILTERS_REQUEST:
      return state.mergeDeep({
        isLoading: true,
      });

    case TYPES.FILTERS_SUCCESS:
      return state
        .mergeDeep({
          isLoading: false,
        })
        .setIn(["filters"], List(action.data));

    case TYPES.FILTERS_FAILURE:
      return state.mergeDeep({
        isLoading: false,
      });

    default:
      return state;
  }
}

Logger.log("silly", `adminPartnerClasses.reducers loaded.`);
