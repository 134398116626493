import React, { Component } from 'react';
import { getI18n, Translation } from 'react-i18next';
import CareGuideDisabled from '../../careGuides/components/CareGuideDisabled';
import CareGuideWrapper from '../../../layouts/components/CareGuideWrapper';
import hasPermission, {
  VIEW_ACCESS_CARE_GUIDE,
} from '../../../elements/lib/hasPermission';
import Loading from '../../../elements/components/Loading';
import DocumentHead from '../../../elements/components/DocumentHead';
import scrollToTop from '../../../elements/lib/scrollToTop';
import Logger from '../../../../../lib/Logger';
import { Redirect } from 'react-router-dom';
import { pathTo } from '../../../Routes';
import ResourcesScreenHeader from './ResourcesScreenHeader';
import { Typography, Row, Col } from 'antd';
import ResourcesList from '../containers/ResourcesListContainer';
import ResourcePreview from '../containers/ResourcePreviewContainer';
import ResourceNotesModal from '../../resourceNotes/containers/ResourceNotesModalContainer';
import ResourceForm from '../containers/ResourceFormContainer';

const { Text } = Typography;

class ResourcesScreen extends Component {
  static defaultProps = {
    isLoading: true,
  };

  constructor(props) {
    super(props);
    this.state = {
      initialLoad: false,
      nationalHelplines: true,
      region:
        props.careGuide && props.careGuide.region
          ? props.careGuide.region.code_2
          : null,
      subregion:
        props.careGuide && props.careGuide.subregion
          ? `${props.careGuide.subregion.id}`
          : null,
      postalCode:
        props.careGuide && props.careGuide.postal_code
          ? props.careGuide.postal_code.postal_code
          : null,
      tab: 'all_resources',
      search: '',
      tags: [],
      serviceTags: [],
      width: window.innerWidth,
      height: window.innerHeight,
    };
  }

  handleResize = () => {
    this.setState({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  };

  render() {
    const {
      careGuideId,
      careGuide,
      partnershipId,
      page,
      limit,
      isCareGuideActive,
      isCareGuideLoading,
    } = this.props;

    if (isCareGuideLoading) {
      return (
        <Translation>
          {(t) => (
            <CareGuideWrapper>
              <DocumentHead title={t('resources_route_list')} />
              <Loading />
            </CareGuideWrapper>
          )}
        </Translation>
      );
    }

    if (isCareGuideActive === null) {
      return <Redirect to={pathTo('HomeScreen')} />;
    }

    if (!isCareGuideActive) {
      return (
        <Translation>
          {(t) => (
            <CareGuideWrapper>
              <DocumentHead title={t('resources_route_list')} />
              <CareGuideDisabled careGuideId={careGuideId} />
            </CareGuideWrapper>
          )}
        </Translation>
      );
    }

    const extraContent = (showList, total) => (
      <Text type="secondary">
        {getI18n().t('resource_screen_tabs_title_show_resources', {
          showList: showList,
          total: total,
        })}
      </Text>
    );

    const onChangeTab = (activeKey) => {
      this.setState({ tab: activeKey });
    };

    const onChangeSearch = (val) => {
      this.setState({ search: val });
    };

    const onChangeServiceTagsHandler = (val) => {
      this.setState({ serviceTags: val });
    };

    return (
      <Translation>
        {(t) => (
          <CareGuideWrapper>
            <DocumentHead title={t('resources_route_list')} />
            <div
              className={!(this.state.width <= 576) ? 'resource-screen' : ''}
            >
              <ResourcesScreenHeader
                showForm={this.props.showForm}
                search={this.state.search}
                onChangeSearch={onChangeSearch}
                onChangeServiceTagsHandler={onChangeServiceTagsHandler}
              />
              <Row>
                <Col span={24}>
                  <ResourcesList
                    onChangeTab={onChangeTab}
                    extraContent={extraContent}
                    width={this.state.width}
                    key={page}
                    component="ResourcesScreen"
                    careGuideId={careGuideId}
                    page={page}
                    limit={limit}
                    order={'status.asc'}
                    nationalHelplines={this.state.nationalHelplines}
                    region={this.state.region}
                    subregion={this.state.subregion}
                    postalCode={this.state.postalCode}
                    tab={this.state.tab}
                    tags={this.state.tags}
                    search={this.state.search}
                    serviceTags={this.state.serviceTags}
                  />
                </Col>
              </Row>

              <ResourceForm
                careGuide={careGuide}
                careGuideId={careGuideId}
                partnershipId={partnershipId}
                page={page}
                limit={limit}
                order={'status.asc'}
                tab={this.state.tab}
                search={this.state.search}
                serviceTags={this.state.serviceTags}
              />
              <ResourcePreview />
              <ResourceNotesModal careGuideId={careGuideId} />
            </div>
          </CareGuideWrapper>
        )}
      </Translation>
    );
  }

  /* LIFECYCLE EVENTS */

  componentDidMount() {
    scrollToTop();
    window.addEventListener('resize', this.handleResize);
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  componentDidUpdate(prevProps) {
    if (this.props.isCareGuideActive) {
      if (this.props.careGuide?.partnership !== prevProps.partnershipActiveId) {
        if (this.props.careGuide.partnership) {
          this.props.setActiveAccess({
            access_role_admin_in_care_guide:
              this.props.careGuide.partnership.access_role_admin_in_care_guide,
          });
          if (this.props.accessCareGuide) {
            if (hasPermission(VIEW_ACCESS_CARE_GUIDE)) {
              if (!this.props.accessCareGuide?.resources) {
                this.setState({ redirect: 'HomeScreen' });
              }
            }
          }
        }
      }
    }

    if (
      this.props.careGuide &&
      this.props.careGuide.postal_code &&
      !this.state.initialLoad
    ) {
      this.setState({
        initialLoad: true,
        region:
          this.props.careGuide && this.props.careGuide.region
            ? this.props.careGuide.region.code_2
            : null,
        subregion:
          this.props.careGuide && this.props.careGuide.subregion
            ? `${this.props.careGuide.subregion.id}`
            : null,
        postalCode:
          this.props.careGuide && this.props.careGuide.postal_code
            ? this.props.careGuide.postal_code.postal_code
            : null,
      });
    }
  }
}

export default ResourcesScreen;

Logger.log('silly', `ResourcesScreen loaded.`);
