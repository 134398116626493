import React, {useState, useEffect} from "react";
import {Translation} from 'react-i18next';
import {Form, Input} from 'antd';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

export default function BodyInputChat({name,theme,form,placeholder, defaultValue='', reset=null, editorRef=null, ...props}) {
  const [value, setValue] = useState(defaultValue);

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue, setValue]);
  
  useEffect(() => {
    if (reset) {
      setValue('');
    }
  }, [reset, setValue]);

  return (
    <Translation>{(t) => 
      <div className="chat-message">

        <Form.Item name={name} style={{display: 'none'}} {...props}>
          <Input type="hidden" />
        </Form.Item>

        <Form.Item label={null}>
          <ReactQuill
            theme={theme}
            value={value}
            placeholder={placeholder}
            onChange={(content, delta, source, editor) => {
              setValue(content);
              form.setFields([{
                name: [name],
                value: editor.getHTML()
              }]);
            }}
            ref={editorRef}
          />
        </Form.Item>

      </div>
    }</Translation>
  );
}
