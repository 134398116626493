import { Modal, Typography } from 'antd';
import { Translation, getI18n } from 'react-i18next';
import { Space, Button, Form, Radio } from 'antd';
import Logger from '../../../../../lib/Logger';
import notification from '../../../elements/lib/NotificationWrapper';
import React from 'react';

const { Text } = Typography;

const layout = {
  main: {
    labelCol: { span: 5 },
    wrapperCol: { span: 19 },
  },
};

const CareGuideOwnersModalExport = ({
  partnershipName,
  order,
  total,
  filter,
  download,
  isDownloading,
  partnershipId,
  openModalExport,
  selectedRowKeys,
  allPageMembersIds,
  setOpenModalExport,
}) => {
  const [form] = Form.useForm();

  const onClickOK = () => {
    form.submit();
  };

  // form submit handler
  const handleFinish = async (values) => {
    Logger.log('debug', `careGuideOwnersModalExportForm.handleFinish(###)`);
    if (!isDownloading) {
      await submitData(values);
    }
  };

  // form error handler
  const handleFinishFailed = () => {
    Logger.log(
      'debug',
      `careGuideOwnersModalExportForm.handleFinishFailed(###)`
    );
    notification.error(getI18n().t('care_guide_owners_message_export_failure'));
  };

  // submit data handler
  const submitData = async (values) => {
    Logger.log('debug', `careGuideOwnersModalExportForm.submitData(###)`);

    const payload = transformValues(values);
    if (partnershipId) {
      download(
        partnershipId,
        partnershipName,
        payload,
        order,
        filter,
        (success) => {
          if (success) {
            notification.success(
              getI18n().t('care_guide_owners_message_export_success')
            );
          } else {
            notification.error(
              getI18n().t('care_guide_owners_message_export_failure')
            );
          }
        }
      );
    } else {
      notification.error(
        getI18n().t('care_guide_owners_message_export_failure')
      );
    }
  };

  const transformValues = (values) => {
    const payload = {};
    // transform values
    for (var key in values) {
      if (['option-export'].includes(key)) {
        if (values[key] === 1) {
          payload['care_guide_ids'] = allPageMembersIds;
        } else if (values[key] === 3) {
          payload['care_guide_ids'] = selectedRowKeys;
        }
      }
    }
    return payload;
  };

  return (
    <Translation>
      {(t) => (
        <>
          <Modal
            title={t('care_guide_owners_export_title')}
            className="care-guide-owners-modal-export"
            centered
            visible={openModalExport}
            onCancel={() => setOpenModalExport(false)}
            footer={[
              <Button key="close" onClick={() => setOpenModalExport(false)}>
                {t('care_guide_owners_export_cancel')}
              </Button>,

              <Button
                key="submit"
                type="primary"
                loading={isDownloading}
                onClick={onClickOK}
                disabled={
                  allPageMembersIds.length < 1 &&
                  total < 1 &&
                  selectedRowKeys < 1
                }
              >
                {t('care_guide_owners_export_members')}
              </Button>,
            ]}
            forceRender={true}
          >
            <Form
              name="care_guide_owners_export_form"
              {...layout.main}
              initialValues={{
                'option-export': 1,
              }}
              form={form}
              onFinish={handleFinish}
              onFinishFailed={handleFinishFailed}
              validateTrigger="onSubmit"
              requiredMark={false}
              layout="vertical"
            >
              <div className="ant-col ant-col-24 ant-form-item-label">
                <label
                  className="ant-form-item-required export-care-guide-owners-options-title"
                  title="options-title"
                >
                  {t('care_guide_owners_export')}
                </label>
                <Text type="secondary">(required)</Text>
              </div>

              <Form.Item name="option-export">
                <Radio.Group size={'large'}>
                  <Space direction="vertical">
                    <Radio value={1} disabled={allPageMembersIds.length < 1}>
                      {t('care_guide_owners_option_current')}
                    </Radio>
                    <Radio value={2} disabled={total < 1}>
                      {t('care_guide_owners_export_option_all')}
                    </Radio>
                    <Radio value={3} disabled={selectedRowKeys < 1}>
                      {t('care_guide_owners_export_option_selected', {
                        count: selectedRowKeys.length,
                      })}
                    </Radio>
                  </Space>
                </Radio.Group>
              </Form.Item>
              <div style={{ display: 'none' }}>
                <Button htmlType="submit"></Button>
              </div>
            </Form>
          </Modal>
        </>
      )}
    </Translation>
  );
};

export default CareGuideOwnersModalExport;

Logger.log('silly', `careGuideOwnersModalExportForm loaded.`);
