import { connect } from 'react-redux';
import { List } from 'immutable';

import {
  loadCareGuideAdmin,
  updateCareGuideAdmin,
  careGuideAdminFormDestroy,
} from '../../../../../state/modules/careGuidesAdmin/actions';
import CareGuideAdminForm from '../components/CareGuideAdminForm';
import storage from '../../../../../lib/Storage';

const inputs = List([
  'care_partner_tags',
  'account',
  'partnership',
  'subscription_status',
]);

const mapStateToProps = (state, ownProps) => {
  const data = {},
    errors = {};
  for (const val of inputs.values()) {
    data[val] = state.entities.getIn(['careGuidesAdmin', ownProps.id, val], '');
    errors[val] = state.careGuidesAdmin.getIn(['form', 'errors', val]);
  }

  if (data['care_partner_tags'] === '') {
    data['care_partner_tags'] = [];
  }

  let result = {};

  Object.keys(data).forEach((x) => {
    if (['account'].includes(x)) {
      data['subscription_status'] = data[x]['subscription_status'];
    } else if (['care_partner_tags'].includes(x)) {
      const carePartnerTags = data[x];
      if (carePartnerTags && Array.isArray(carePartnerTags)) {
        carePartnerTags.forEach((item) => {
          const name = item.admin_partner_class.name;
          const uuid = item.id;
          result[name] = uuid;
        });
      }
    }
  });

  return {
    result,
    isLoading: state.careGuidesAdmin.get('isLoading'),
    isSubmitting: state.careGuidesAdmin.getIn(['form', 'isSubmitting']),
    success: state.careGuidesAdmin.getIn(['form', 'success']),
    created_id: state.careGuidesAdmin.getIn(['form', 'created_id']),
    data: data,
    errors: errors,
    createdAt: state.entities.getIn(
      ['careGuidesAdmin', ownProps.id, 'created_at'],
      null
    ),
    updatedAt: state.entities.getIn(
      ['careGuidesAdmin', ownProps.id, 'updated_at'],
      null
    ),
    activeId: storage.get('local', 'partnershipId'),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    load: (partnership_id, id, cb) => {
      dispatch(loadCareGuideAdmin(partnership_id, id, cb));
    },
    update: (activeId, id, data, cb) => {
      dispatch(updateCareGuideAdmin(activeId, id, data, cb));
    },
    destroyForm: (formState) => {
      dispatch(careGuideAdminFormDestroy(formState));
    },
  };
};

const CareGuideAdminFormContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(CareGuideAdminForm);

export default CareGuideAdminFormContainer;
