import React, { useState, useEffect, useRef } from 'react';
import { Translation, getI18n } from 'react-i18next';
import { Button, Form, Col, Row, Upload } from 'antd';
import { PlusCircleOutlined } from '@ant-design/icons';
import { FaFileImage } from 'react-icons/fa';

import BodyInput from './BodyInput';
import RatingInput from './RatingInput';
import TagInput from '../../journalTags/containers/TagInputContainer';

import message from '../../../elements/lib/MessageWrapper';
import useWindowDimensions from '../../../../../lib/WindowDimensions';
import Logger from '../../../../../lib/Logger';
import {
  MixpanelTracker,
  mixpanelEventsEnum,
} from '../../../../../lib/Mixpanel';

const JournalPostForm = ({
  careGuideId,
  careGuide,
  page,
  limit,
  order,
  errors,
  load,
  destroyForm,
  isLoading,
  isSubmitting,
  ...props
}) => {
  const [resetBody, setResetBody] = useState(null);
  const [fileList, setFileList] = useState([]);

  const { width } = useWindowDimensions();
  const isLarge = width > 768;

  const [form] = Form.useForm();

  // handle errors reported by API
  useEffect(() => {
    let firstFieldName = '';
    for (const field in errors) {
      form.setFields([{ name: field, errors: errors[field] }]);
      if (firstFieldName === '') {
        firstFieldName = field;
      }
    }
    form.scrollToField(firstFieldName);
  }, [form, errors]);

  // submit data handler
  const submitData = async (values) => {
    Logger.log('debug', `JournalPostForm.submitData(###)`);

    const payload = {
      media_ids: Object.keys(fileMap.current).map((x) => fileMap.current[x]),
    };

    // transform values
    for (var key in values) {
      payload[key] = values[key];
    }

    props.create(careGuideId, payload, (success) => {
      if (success) {
        message.success(getI18n().t('feedback_form_success'));
        load(careGuideId, page, limit, order);
        form.setFieldsValue({
          body: '',
          rating: '',
          tags: [],
          partner_tags: [],
        });
        setResetBody(Math.random());
        fileMap.current = {};
        setFileList([]);

        if (careGuide?.partnership) {
          MixpanelTracker.mixpanelTrack(
            mixpanelEventsEnum.JOURNAL.CREATE_POST,
            {
              careGuideName: `${careGuide.first_name} ${careGuide.last_name}`,
              partnershipName: careGuide.partnership.name,
              careGuideId: careGuide.id,
              partnershipId: careGuide.partnership.id,
            }
          );
        } else {
          MixpanelTracker.mixpanelTrack(
            mixpanelEventsEnum.JOURNAL.CREATE_POST,
            {
              careGuideName: `${careGuide.first_name} ${careGuide.last_name}`,
              careGuideId: careGuide.id,
            }
          );
        }
      } else {
        message.error(getI18n().t('feedback_form_error'));
      }
    });
  };

  // form submit handler
  const handleFinish = async (values) => {
    Logger.log('debug', `JournalPostForm.handleFinish(###)`);
    if (!props.isSubmitting) {
      await submitData(values);
    }
  };

  // form error handler
  const handleFinishFailed = ({ values, errorFields, outOfDate }) => {
    Logger.log('debug', `JournalPostForm.handleFinishFailed(###)`);
    message.error(getI18n().t('feedback_form_error'));
    if (errorFields && errorFields.length > 0) {
      form.scrollToField(errorFields[0].name);
    }
  };

  // remove error message when input value changes
  const handleValuesChange = (changedValues, allValues) => {
    for (const key of Object.keys(changedValues)) {
      form.setFields([{ name: key, errors: [] }]);
    }
  };

  // upload criteria
  const fileTypes = {
    'image/png': 'png',
    'image/jpeg': 'jpg',
  };
  const fileSizeLimit = 1024 * 1024 * 6;
  const reFileKey = /(?:(.*?)\/)?(.*?)\.(\w+)/;
  const fileMap = useRef({});
  const maxCount = 3;

  const errorMessage = () => {
    message.error(
      getI18n().t('journal_posts_form_feedback_image_upload_error')
    );
  };

  const beforeUpload = (file) => {
    const isUnderMaxCount = Object.keys(fileMap.current).length < maxCount;
    if (!isUnderMaxCount) {
      message.error(
        getI18n().t('journal_posts_form_feedback_image_file_count_limit')
      );
    }

    const isSupportedFileType = Object.keys(fileTypes).includes(file.type);
    if (!isSupportedFileType) {
      message.error(
        getI18n().t('journal_posts_form_feedback_image_file_types')
      );
    }

    const isUnderFileSizeLimit = file.size < fileSizeLimit;
    if (!isUnderFileSizeLimit) {
      message.error(
        getI18n().t('journal_posts_form_feedback_image_file_size_limit')
      );
    }

    return isUnderMaxCount && isSupportedFileType && isUnderFileSizeLimit
      ? true
      : Upload.LIST_IGNORE;
  };

  const customRequest = (e) => {
    props.getUploadUrl(
      e.file.name.split('.').pop(),
      'journal-posts',
      async (success, upload_url, file_id) => {
        if (success) {
          // upload image
          props.upload(upload_url, e.file, async (uploadSuccess) => {
            const directory = 'journal-posts/';
            file_id = file_id.replace(directory, '');
            if (uploadSuccess) {
              const matches = reFileKey.exec(file_id);
              const updateData = {
                file_directory: directory.slice(0, -1),
                file_name: matches[2],
                file_extension: matches[3],
              };

              // process image
              props.createImage(
                careGuideId,
                updateData,
                (createSuccess, imageId) => {
                  if (createSuccess) {
                    fileMap.current[e.file.uid] = imageId;
                    e.onSuccess(null, e.file);
                  } else {
                    errorMessage();
                  }
                }
              );
            } else {
              errorMessage();
            }
          });
        } else {
          errorMessage();
        }
      }
    );
  };

  const onUploadChange = (info) => {
    setFileList(info.fileList);
  };

  const onRemove = async (file) => {
    if (file.uid in fileMap.current) {
      await props.deleteImage(
        careGuideId,
        fileMap.current[file.uid],
        (success) => {
          if (success) {
            delete fileMap.current[file.uid];
          }
        }
      );
    }
  };

  return (
    <Translation>
      {(t) => (
        <>
          <div className="journal-post-form">
            <Form
              name="journal_post_form"
              form={form}
              onFinish={handleFinish}
              onFinishFailed={handleFinishFailed}
              onValuesChange={handleValuesChange}
              validateTrigger="onSubmit"
              requiredMark={true}
              colon={false}
              layout={isLarge ? 'inline' : 'vertical'}
              // layout="inline"
              labelWrap={true}
            >
              <RatingInput
                name="rating"
                label={t('journal_posts_form_input_rating', {
                  name: careGuide ? careGuide.first_name : 'recipient',
                })}
                rules={[
                  {
                    required: true,
                    message: t('feedback_validation_required'),
                  },
                ]}
                disabled={isLoading || isSubmitting}
              />

              <BodyInput
                name="body"
                label={false}
                form={form}
                rules={[
                  {
                    required: true,
                    message: t('feedback_validation_required'),
                  },
                  {
                    type: 'string',
                    min: 1,
                    max: 8000,
                    message: t('feedback_validation_char_range', {
                      min: 1,
                      max: 8000,
                    }),
                  },
                ]}
                disabled={isLoading || isSubmitting}
                reset={resetBody}
              />

              <Row>
                <Col xs={{ span: 24, order: 2 }} md={{ span: 8, order: 1 }}>
                  <Button
                    type="primary"
                    icon={<PlusCircleOutlined />}
                    htmlType="submit"
                    className="journal-post-btn-submit"
                    loading={isSubmitting}
                  >
                    {t('journal_posts_form_button_submit')}
                  </Button>

                  <Upload
                    beforeUpload={beforeUpload}
                    customRequest={customRequest}
                    onChange={onUploadChange}
                    onRemove={onRemove}
                    maxCount={maxCount}
                    fileList={fileList}
                    listType="picture"
                  >
                    <Button
                      icon={
                        <span className="anticon">
                          <FaFileImage />
                        </span>
                      }
                    >
                      {t('journal_posts_form_button_upload_image')}
                    </Button>
                  </Upload>
                </Col>

                <Col
                  className="journal-tags-container"
                  xs={{ span: 24, order: 1 }}
                  md={{ span: 16, order: 2 }}
                  xl={{ span: 14, order: 2 }}
                >
                  {' '}
                  {/*  style={{height: 32}} setting height here because for some reason it grows as list options are selected */}
                  <TagInput
                    name="tags"
                    label={t('journal_posts_form_input_tags')}
                    rules={[
                      {
                        required: true,
                        message: t('feedback_validation_required'),
                      },
                    ]}
                    disabled={isLoading || isSubmitting}
                  />
                </Col>
              </Row>
            </Form>
          </div>
        </>
      )}
    </Translation>
  );
};

export default JournalPostForm;

Logger.log('silly', `JournalPostForm loaded.`);
