import React, { useEffect } from "react";
import { Translation, getI18n } from "react-i18next";
import { Button, Modal, Row, Space, Table, Image } from "antd";
import {
  CloseCircleOutlined,
} from "@ant-design/icons";

import UserAvatar from "../../../elements/components/UserAvatar";
import Format from "../../../../../lib/Format";
import Config from "../../../../../Config";
import Logger from "../../../../../lib/Logger";
import { GlobalOutlined } from "@ant-design/icons";
import imageURL from "../../../elements/lib/imageURL";

const EventModal = ({ eventId, event, load, activeId, total, setPage, pageParticipants, areParticipantEventsLoading, list, loadParticipants, ...props }) => {

  
  const withHttp = (url) =>
    !/^https?:\/\//i.test(url) ? `http://${url}` : url;

  const datetimeFormat = "MMM D, YYYY [at] h:mm A";
  const dateFormat = Config.get("DEFAULT_DATE_FORMAT");

  const onClickCancel = () => {
    props.hideDetail();
  };

  // load record data from API
  useEffect(() => {
    if (eventId) {
      load(activeId, eventId);
    }
    }, [activeId, eventId, load]);

    useEffect(() => {
      if (eventId) {
        loadParticipants(eventId, pageParticipants, 10)
      }
    }, [eventId, pageParticipants, loadParticipants]);
  
    useEffect(() => {
      if (props.visible) {
        setPage(1);
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.visible]);
  

    const pagination = eventId
    ? {
        onChange: (newPage) => {
          setPage(newPage);
        },
        current: pageParticipants,
        total: total,
        showSizeChanger: false,
      }
    : null;

  const eventColumns = [
    {
      title: "Label",
      dataIndex: "label",
      key: "label",
      width: 125,
    },
    {
      title: "Value",
      dataIndex: "value",
      key: "value",
    },
  ];

  const eventDataSource = [];
  if (event) {
    if (event.is_all_day) {
      eventDataSource.push({
        key: "date",
        label: getI18n().t("calendar_event_detail_date"),
        value: Format.date(event.start_at, dateFormat),
      });
    } else {
      eventDataSource.push({
        key: "start-date",
        label: getI18n().t("calendar_event_detail_start_at_date"),
        value: Format.date(event.start_at, datetimeFormat),
      });
      eventDataSource.push({
        key: "end-date",
        label: getI18n().t("calendar_event_detail_end_at_date"),
        value: Format.date(event.end_at, datetimeFormat),
      });
    }
    eventDataSource.push({
      key: "description",
      label: getI18n().t("calendar_event_detail_description"),
      value: (
        <div
          className="event-description"
          dangerouslySetInnerHTML={{ __html: event.description }}
        />
      ),
    });
    
    if(event.care_guide == null) {
      eventDataSource.push({
        key: "profile",
        label: getI18n().t("calendar_event_profile_created"),
        value:                     
        <>
            <UserAvatar
              profile={event.profile}
              size={22}
            />
            {event.profile.first_name}{" "}
            {event.profile.last_name}
       </>,
      });
    }
  }


  const participantColumns = [
    {
      title: "Value",
      dataIndex: "value",
      key: "value",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: 100,
    },
  ];

  return (
    <Translation>
      {(t) => (
        <>
          <Modal
            title={
              event ? (
                event.partnership &&
                event.partnership.logo &&
                event.partnership.logo.file_name &&
                event.partnership.logo.file_extension &&
                event.partnership.logo.file_directory ? (
                  <>
                    <Row>
                      <Image
                        preview={false}
                        src={
                          imageURL(
                            event.partnership.logo.file_directory,
                            event.partnership.logo.file_name,
                            event.partnership.logo.file_extension,
                            400,
                            400,
                            "ratio"
                          )
                        }
                        height="100px"
                      />
                    </Row>
                    <br />
                    <Row>{event.title}</Row>
                  </>
                ) : (
                  event.title
                )
              ) : null
            }
            centered
            visible={props.visible}
            onCancel={onClickCancel}
            footer={[
              <Button
                key="close"
                icon={<CloseCircleOutlined />}
                onClick={onClickCancel}
              >
                {t("action_close")}
              </Button>,
            ]}
            width={500}
            bodyStyle={{ paddingTop: 0}}
            style={{border: event?.partnership ? 
              `2px solid ${event.partnership.color_hex}` 
              : null}}
            forceRender={true}
          >
            <div className="calendar-event-detail">
              {event ? (
                <div>
                  <div className="calendar-event-detail-summary">
                    <Table
                      columns={eventColumns}
                      dataSource={eventDataSource}
                      size="small"
                      showHeader={false}
                      pagination={false}
                    />
                  </div>

                  {event.partnership && event.url_event_partner && event.url_label && (
                    <Row justify="center" className="event-response-actions">
                      <Space>
                        <div className="website-display">
                          <a
                            href={withHttp(event.url_event_partner)}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <GlobalOutlined />
                            {event.url_label}
                          </a>
                        </div>
                      </Space>
                    </Row>
                  )}

                  {list && list.length > 0 && event.care_guide ? (
                    <>
                      <h3>{t("calendar_event_detail_participants_title")}</h3>

                      <div 
                          className="calendar-event-detail-participants">
                        <Table
                          pagination={pageParticipants === 1 && total <= 10  ? false : pagination}
                          columns={participantColumns}
                          dataSource={
                            list
                              ? list.map((x) => {
                                  return {
                                    key: x.id,
                                    value: (
                                      <>
                                        <UserAvatar
                                          profile={x.profile}
                                          size={22}
                                        />
                                        {x.profile.first_name}{" "}
                                        {x.profile.last_name}
                                      </>
                                    ),
                                    status: t(
                                      "calendar_event_detail_public_status_" +
                                        x.public_status
                                    ),
                                  };
                                })
                              : []
                          }
                          size="small"
                          showHeader={false}
                        />
                      </div>
                    </>
                  ) : null}

                </div>
              ) : null}
            </div>
          </Modal>
        </>
      )}
    </Translation>
  );
};

export default EventModal;

Logger.log("silly", `EventModal loaded.`);
