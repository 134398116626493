import {connect} from 'react-redux';

import DashboardScreen from '../components/DashboardScreen';

const mapStateToProps = (state, ownProps) => {
  return {
    isLoading: state.careGuides.get('isLoading'),
    activeId: state.careGuides.get('activeId'),
    careGuideCount: state.careGuides.get('total'),
    userProfilesPartneshipsCount: state.userProfilesPartnerships.get('total'),
    invitationListCount: state.careGuideInvitations.get('total', 0),
  }
}

const DashboardScreenContainer = connect(
  mapStateToProps
)(DashboardScreen);

export default DashboardScreenContainer;
