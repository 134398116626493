import Config from '../../../../Config';

const imageURL = (directory, file_id, extension, width, height, action='ratio') => {
  const cacheURL = Config.get('IMAGE_CACHE_URL');
  if (cacheURL && directory && file_id && extension && width && height && action) {
    return `${cacheURL}/${action}/${width}x${height}/${directory}/${file_id}.${extension}`;
  }
  return null;
}

export default imageURL;
