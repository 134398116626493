import { Table } from 'antd';
import React, { useEffect } from 'react';
import { withRouter } from 'react-router';
import Logger from '../../../../../lib/Logger';
import { Translation, getI18n } from 'react-i18next';
import UserProfilesPartnershipsChooserListActions from '../../../elements/components/UserProfilesPartnershipsChooserListActions';
import QueryString from '../../../../../lib/QueryString';
import { pathTo } from '../../../Routes';
import { getColumnSearchProps } from '../../../elements/components/TableColumnFilters';
import storage from '../../../../../lib/Storage';

const UserProfilesPartnershipsChooserHomeList = ({
  component,
  pageUserProfilePartnership,
  limit,
  orderUserProfilePartnership,
  filter,
  total,
  page,
  load,
  history,
  profileId,
  setActive,
  careGuideCount,
  listCareGuide,
  setActiveCareGuide,
  partnershipActiveId,
  ...props
}) => {
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
  };

  const handleReset = (clearFilters, dataIndex) => {
    clearFilters();
    delete filter[dataIndex];
    history.push(
      QueryString.append(props.location.pathname, {
        orderUserProfilePartnership,
        ...filter,
      })
    );
  };

  const columns = [
    {
      title: getI18n().t('table_header_partnership_name'),
      dataIndex: 'partnership_name',
      key: 'partnership_name',
      ...getColumnSearchProps(
        'partnership_name',
        handleSearch,
        handleReset,
        getI18n().t('table_header_partnership_name'),
        filter
      ),
    },
    {
      title: getI18n().t('table_header_partnership_plan'),
      dataIndex: 'partnership_plan',
      key: 'partnership_plan',
      ...getColumnSearchProps(
        'partnership_plan',
        handleSearch,
        handleReset,
        getI18n().t('table_header_partnership_plan'),
        filter
      ),
    },
    {
      title: getI18n().t('role'),
      dataIndex: 'role',
      key: 'role',
      render: (item) => getI18n().t('role_' + item),
    },
    {
      title: getI18n().t('table_header_tool_tips'),
      key: 'actions',
      width: '30%',
      render: (item) => (
        <UserProfilesPartnershipsChooserListActions
          item={item}
          profileId={profileId}
          setActive={setActive}
          careGuideCount={careGuideCount}
          listCareGuide={listCareGuide}
          setActiveCareGuide={setActiveCareGuide}
          partnershipActiveId={partnershipActiveId}
          pageUserProfilePartnership={pageUserProfilePartnership}
          page={page}
          storage={storage}
        />
      ),
    },
  ];

  const pagination = {
    current: pageUserProfilePartnership,
    pageSize: limit,
    total: total,
    showSizeChanger: false,
    position: ['bottomCenter'],
  };

  const handleTableChange = (pagination, filters, sorter) => {
    let path = props.location.pathname;
    const params = {};

    // handle pagination
    if ('current' in pagination && pagination['current']) {
      path = pathTo(component, {
        page_partnership: pagination['current'],
        page: page,
      });
    }

    // handle sorting
    if ('field' in sorter && 'orderUserProfilePartnership' in sorter) {
      if (sorter['field'] && sorter['orderUserProfilePartnership']) {
        const orderUserProfilePartnership =
          sorter['field'] +
          '.' +
          (sorter['orderUserProfilePartnership'] === 'ascend' ? 'asc' : 'desc');
        params['orderUserProfilePartnership'] = orderUserProfilePartnership;
      }
    }

    // handle filters
    if (filters) {
      for (const key in filters) {
        if (filters[key]) {
          params[key] = filters[key].join(',');
        }
      }
    }

    history.push(QueryString.append(path, params));
  };

  const filterString = JSON.stringify(filter);

  useEffect(() => {
    load(
      pageUserProfilePartnership,
      limit,
      orderUserProfilePartnership,
      JSON.parse(filterString)
    );
  }, [
    pageUserProfilePartnership,
    limit,
    orderUserProfilePartnership,
    filterString,
    load,
  ]);

  useEffect(() => {
    if (props.list.length > 0 && partnershipActiveId === null) {
      const item = props.list[0];
      storage.set('local', 'partnershipId', item.partnership_id);
      storage.set('local', 'partnershipData', {
        access_role_admin_in_care_guide:
          item.partnership.access_role_admin_in_care_guide,
        access_role_admin_in_app: item.partnership.access_role_admin_in_app,
        access_tabs_in_app: item.partnership.access_tabs_in_app,
        role: item.role,
        name: item.partnership_name,
      });

      setActive(item.id);
      storage.set(
        'local',
        'userProfilePartnershipPage',
        pageUserProfilePartnership
      );
      storage.set('local', 'userProfilePartnershipId', item.id);
    }
  }, [props.list]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Translation>
      {(t) => (
        <Table
          columns={columns}
          dataSource={props.list}
          loading={props.isLoading}
          pagination={
            pageUserProfilePartnership === 1 && total < limit
              ? false
              : pagination
          }
          scroll={{ x: true }}
          onChange={handleTableChange}
        />
      )}
    </Translation>
  );
};

export default withRouter(UserProfilesPartnershipsChooserHomeList);

Logger.log('silly', `UserProfilesChooserHomeList loaded.`);
