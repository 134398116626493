import React from 'react';
import { Translation } from 'react-i18next';

const care = (size = 24) => {
  return (
    <Translation>
      {(t) => (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={size + 'px'}
          height={size + 'px'}
          viewBox="0 0 72.052 72"
        >
          {/* <title>{t('journal_emojis_label_care')}</title> */}
          <defs>
            <clipPath id="clipPath">
              <rect
                id="Rectangle_4"
                data-name="Rectangle 4"
                width="67.509"
                height="67.509"
                fill="none"
              />
            </clipPath>
          </defs>
          <g id="care" transform="translate(-1373 -405)">
            <g id="Group_22" data-name="Group 22">
              <g
                id="Group_12"
                data-name="Group 12"
                transform="translate(1375 407.245)"
                opacity="0.6"
              >
                <g
                  id="Group_11"
                  data-name="Group 11"
                  transform="translate(0 0)"
                >
                  <g id="Group_10" data-name="Group 10">
                    <path
                      id="Path_34"
                      data-name="Path 34"
                      d="M360.478,80.96a33.755,33.755,0,1,1,33.755-33.755A33.792,33.792,0,0,1,360.478,80.96"
                      transform="translate(-326.723 -13.451)"
                      fill="#55b1ff"
                    />
                  </g>
                </g>
              </g>
              <path
                id="Path_35"
                data-name="Path 35"
                d="M361.223,16.442a31.509,31.509,0,1,1-31.509,31.509,31.545,31.545,0,0,1,31.509-31.509m0-4.491a36,36,0,1,0,36,36,36,36,0,0,0-36-36"
                transform="translate(1047.777 393.049)"
                fill="#175391"
              />
              <path
                id="Path_37"
                data-name="Path 37"
                d="M350.741,37.683c0,5.73-3.024-2.364-6.753-2.364s-6.754,8.094-6.754,2.364,3.024-10.375,6.754-10.375,6.753,4.645,6.753,10.375"
                transform="translate(1053.746 400.681)"
                fill="#175391"
              />
              <path
                id="Path_38"
                data-name="Path 38"
                d="M365.306,37.683c0,5.73-3.024-2.364-6.753-2.364s-6.754,8.094-6.754,2.364,3.024-10.375,6.754-10.375,6.753,4.645,6.753,10.375"
                transform="translate(1060.984 400.681)"
                fill="#175391"
              />
              <path
                id="Path_39"
                data-name="Path 39"
                d="M545.91,49.685a15.7,15.7,0,0,0-24.176,0c-2.052,2.437,1.653,5.513,3.686,3.076a11.249,11.249,0,0,1,16.8,0c2.016,2.4,5.757-.6,3.687-3.076"
                transform="translate(1942.515 506.634) rotate(180)"
                fill="#175391"
              />
            </g>
            <path
              id="Path_40"
              data-name="Path 40"
              d="M234.221,33.117a1.333,1.333,0,0,1-1.975,0l-8.3-8.916a6.6,6.6,0,0,1,.4-9.269,5.755,5.755,0,0,1,8.047.583l.846.907.846-.907a5.755,5.755,0,0,1,8.047-.583,6.6,6.6,0,0,1,.392,9.269Z"
              transform="translate(1200.765 442.445)"
              fill="#f58b1f"
            />
            <path
              id="Path_42"
              data-name="Path 42"
              d="M234.221,33.117a1.333,1.333,0,0,1-1.975,0l-8.3-8.916a6.6,6.6,0,0,1,.4-9.269,5.755,5.755,0,0,1,8.047.583l.846.907.846-.907a5.755,5.755,0,0,1,8.047-.583,6.6,6.6,0,0,1,.392,9.269Z"
              transform="translate(1150.765 400.445)"
              fill="#f58b1f"
            />
            <path
              id="Path_41"
              data-name="Path 41"
              d="M234.221,33.117a1.333,1.333,0,0,1-1.975,0l-8.3-8.916a6.6,6.6,0,0,1,.4-9.269,5.755,5.755,0,0,1,8.047.583l.846.907.846-.907a5.755,5.755,0,0,1,8.047-.583,6.6,6.6,0,0,1,.392,9.269Z"
              transform="translate(1240.316 283.144) rotate(30)"
              fill="#f58b1f"
            />
          </g>
        </svg>
      )}
    </Translation>
  );
};

const frustrated = (size = 24) => {
  return (
    <Translation>
      {(t) => (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={size + 'px'}
          height={size + 'px'}
          viewBox="0 0 72 71.998"
        >
          {/* <title>{t('journal_emojis_label_frustrated')}</title> */}
          <g id="mad" transform="translate(-1184.738 -413.952)">
            <path
              id="Path_5"
              data-name="Path 5"
              d="M630.886,80.959a33.754,33.754,0,1,1,33.861-33.648,33.792,33.792,0,0,1-33.861,33.648"
              transform="translate(589.745 402.745)"
              fill="#ff9688"
            />
            <path
              id="Path_6"
              data-name="Path 6"
              d="M631.838,16.443a31.508,31.508,0,1,1-31.609,31.409,31.544,31.544,0,0,1,31.609-31.409m.013-4.491a36,36,0,1,0,35.886,36.112,36,36,0,0,0-35.886-36.112"
              transform="translate(589 402)"
              fill="#9a1c0a"
            />
            <path
              id="Path_7"
              data-name="Path 7"
              d="M614.949,42.364a4.787,4.787,0,0,0,4.81-4.786,4.891,4.891,0,0,0-.25-1.238c.09,0,.181.039.254.039a2.43,2.43,0,0,0,2.311-1.7,2.383,2.383,0,0,0-1.606-2.986L608.49,28.054a2.39,2.39,0,1,0-1.394,4.572l4.645,1.421a4.745,4.745,0,0,0-1.591,3.5,4.8,4.8,0,0,0,4.8,4.816"
              transform="translate(593.794 409.95)"
              fill="#9a1c0a"
            />
            <path
              id="Path_8"
              data-name="Path 8"
              d="M636.045,49.722a15.7,15.7,0,0,0-24.175-.076c-2.06,2.433,1.636,5.519,3.676,3.09a11.248,11.248,0,0,1,16.8.052c2.007,2.406,5.759-.584,3.7-3.066"
              transform="translate(596.716 417.928)"
              fill="#9a1c0a"
            />
            <path
              id="Path_9"
              data-name="Path 9"
              d="M639.72,29.734a2.4,2.4,0,0,0-2.974-1.6l-12.017,3.563a2.4,2.4,0,0,0,.675,4.69,1.228,1.228,0,0,0,.254-.037,4.722,4.722,0,0,0-.257,1.236,4.8,4.8,0,1,0,8.039-3.48l4.656-1.392a2.39,2.39,0,0,0,1.626-2.976"
              transform="translate(602.558 409.991)"
              fill="#9a1c0a"
            />
          </g>
        </svg>
      )}
    </Translation>
  );
};

const like = (size = 24) => {
  return (
    <Translation>
      {(t) => (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={size + 'px'}
          height={size + 'px'}
          viewBox="0 0 72 72"
        >
          {/* <title>{t('journal_emojis_label_like')}</title> */}
          <g id="like" transform="translate(-589 -405)">
            <rect
              id="Rectangle_9"
              data-name="Rectangle 9"
              width="72"
              height="72"
              transform="translate(589 405)"
              fill="#fff"
              opacity="0.001"
            />
            <path
              id="Path_1"
              data-name="Path 1"
              d="M23.845,76.552h-9a4.5,4.5,0,0,1-4.5-4.5v-31.5a4.5,4.5,0,0,1,4.5-4.5h9a4.5,4.5,0,0,1,4.5,4.5v31.5a4.5,4.5,0,0,1-4.5,4.5M76.054,47.269a6.713,6.713,0,0,1-3.715,11.166,6.673,6.673,0,0,1-3.671,9.886,6.706,6.706,0,0,1-6.575,8.23H53.841a22.5,22.5,0,0,1-13.5-4.5l-4.8-3.6a6.747,6.747,0,0,1-2.7-5.4V40.569l.031-.031a6.7,6.7,0,0,1,2.5-5.239l2.29-1.831c11.513-9.207,6.343-19.915,15.22-19.915a6.8,6.8,0,0,1,6.955,6.765c0,2.129-1.653,8.172-4.854,13.5h20.6a6.731,6.731,0,0,1,.46,13.447"
              transform="translate(578.654 396.446)"
              fill="#0066bc"
            />
          </g>
        </svg>
      )}
    </Translation>
  );
};

const love = (size = 24) => {
  return (
    <Translation>
      {(t) => (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={size + 'px'}
          height={size + 'px'}
          viewBox="0 0 72 72"
        >
          {/* <title>{t('journal_emojis_label_love')}</title> */}
          <g id="lov2" transform="translate(-803 -405)">
            <g id="Group_19" data-name="Group 19">
              <rect
                id="Rectangle_11"
                data-name="Rectangle 11"
                width="72"
                height="72"
                transform="translate(803 405)"
                fill="#fff"
                opacity="0"
              />
            </g>
            <path
              id="Path_4"
              data-name="Path 4"
              d="M261.462,75.176a4.477,4.477,0,0,1-6.464,0L227.836,47.092a20.24,20.24,0,0,1,1.3-29.2c7.632-6.607,19.313-5.416,26.337,1.836l2.767,2.857L261,19.731c7.024-7.251,18.7-8.443,26.334-1.837a20.228,20.228,0,0,1,1.284,29.2Z"
              transform="translate(580.765 396.445)"
              fill="#f58b1f"
            />
          </g>
        </svg>
      )}
    </Translation>
  );
};

const laugh = (size = 24) => {
  return (
    <Translation>
      {(t) => (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={size + 'px'}
          height={size + 'px'}
          viewBox="0 0 72 72"
        >
          {/* <title>{t('journal_emojis_label_laugh')}</title> */}
          <defs>
            <clipPath id="clipPath">
              <rect
                id="Rectangle_4"
                data-name="Rectangle 4"
                width="67.509"
                height="67.509"
                fill="none"
              />
            </clipPath>
          </defs>
          <g id="happy" transform="translate(-914.223 -413.951)">
            <g
              id="Group_12"
              data-name="Group 12"
              transform="translate(916.468 416.196)"
              opacity="0.6"
            >
              <g id="Group_11" data-name="Group 11" transform="translate(0 0)">
                <g id="Group_10" data-name="Group 10">
                  <path
                    id="Path_34"
                    data-name="Path 34"
                    d="M360.478,80.96a33.755,33.755,0,1,1,33.755-33.755A33.792,33.792,0,0,1,360.478,80.96"
                    transform="translate(-326.723 -13.451)"
                    fill="#55b1ff"
                  />
                </g>
              </g>
            </g>
            <path
              id="Path_35"
              data-name="Path 35"
              d="M361.223,16.442a31.509,31.509,0,1,1-31.509,31.509,31.545,31.545,0,0,1,31.509-31.509m0-4.491a36,36,0,1,0,36,36,36,36,0,0,0-36-36"
              transform="translate(589 402)"
              fill="#175391"
            />
            <path
              id="Path_36"
              data-name="Path 36"
              d="M370.246,40.72a1.565,1.565,0,0,1,2.028,1.772c-1.358,7.622-8.57,13.44-17.263,13.44s-15.9-5.819-17.263-13.44a1.566,1.566,0,0,1,2.028-1.774,49.51,49.51,0,0,0,15.239,2.561,49.4,49.4,0,0,0,15.23-2.56"
              transform="translate(595.212 416.255)"
              fill="#175391"
            />
            <path
              id="Path_37"
              data-name="Path 37"
              d="M350.741,37.683c0,5.73-3.024-2.364-6.753-2.364s-6.754,8.094-6.754,2.364,3.024-10.375,6.754-10.375,6.753,4.645,6.753,10.375"
              transform="translate(594.969 409.632)"
              fill="#175391"
            />
            <path
              id="Path_38"
              data-name="Path 38"
              d="M365.306,37.683c0,5.73-3.024-2.364-6.753-2.364s-6.754,8.094-6.754,2.364,3.024-10.375,6.754-10.375,6.753,4.645,6.753,10.375"
              transform="translate(602.207 409.632)"
              fill="#175391"
            />
          </g>
        </svg>
      )}
    </Translation>
  );
};

const sad = (size = 24) => {
  return (
    <Translation>
      {(t) => (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={size + 'px'}
          height={size + 'px'}
          viewBox="0 0 72 72"
        >
          {/* <title>{t('journal_emojis_label_sad')}</title> */}
          <defs>
            <clipPath id="clipPath">
              <rect
                id="Rectangle_2"
                data-name="Rectangle 2"
                width="67.509"
                height="67.509"
                fill="none"
              />
            </clipPath>
          </defs>
          <g id="sad" transform="translate(-1094.566 -413.951)">
            <g
              id="Group_6"
              data-name="Group 6"
              transform="translate(1096.811 416.196)"
              opacity="0.6"
            >
              <g id="Group_5" data-name="Group 5" transform="translate(0 0)">
                <g id="Group_4" data-name="Group 4">
                  <path
                    id="Path_18"
                    data-name="Path 18"
                    d="M540.821,80.96a33.755,33.755,0,1,1,33.755-33.755A33.792,33.792,0,0,1,540.821,80.96"
                    transform="translate(-507.066 -13.451)"
                    fill="#55b1ff"
                  />
                </g>
              </g>
            </g>
            <path
              id="Path_19"
              data-name="Path 19"
              d="M541.566,16.442a31.509,31.509,0,1,1-31.509,31.509,31.545,31.545,0,0,1,31.509-31.509m0-4.491a36,36,0,1,0,36,36,36,36,0,0,0-36-36"
              transform="translate(589 402)"
              fill="#175391"
            />
            <path
              id="Path_20"
              data-name="Path 20"
              d="M545.91,49.685a15.7,15.7,0,0,0-24.176,0c-2.052,2.437,1.653,5.513,3.686,3.076a11.249,11.249,0,0,1,16.8,0c2.016,2.4,5.757-.6,3.687-3.076"
              transform="translate(596.736 417.929)"
              fill="#175391"
            />
            <path
              id="Path_21"
              data-name="Path 21"
              d="M544.174,36a4.877,4.877,0,1,1-4.877-4.877A4.877,4.877,0,0,1,544.174,36"
              transform="translate(603.339 411.528)"
              fill="#175391"
            />
            <path
              id="Path_22"
              data-name="Path 22"
              d="M528.12,36a4.877,4.877,0,1,1-4.877-4.877A4.877,4.877,0,0,1,528.12,36"
              transform="translate(595.361 411.528)"
              fill="#175391"
            />
            <path
              id="Path_23"
              data-name="Path 23"
              d="M531.043,30.877a26.175,26.175,0,0,1-12.931,6.22c-2.018.386-4.406-.793-4.955-2.34s.678-2.717,2.428-2.883a21.45,21.45,0,0,0,11.485-4.6,3.172,3.172,0,0,1,3.877-.093,2.3,2.3,0,0,1,.1,3.7"
              transform="translate(592.705 409.263)"
              fill="#175391"
            />
            <path
              id="Path_24"
              data-name="Path 24"
              d="M534.463,30.877a26.174,26.174,0,0,0,12.931,6.22c2.018.388,4.406-.793,4.955-2.34s-.678-2.717-2.428-2.883a21.449,21.449,0,0,1-11.485-4.6,3.172,3.172,0,0,0-3.877-.093,2.3,2.3,0,0,0-.1,3.7"
              transform="translate(602.923 409.263)"
              fill="#175391"
            />
            <path
              id="Path_25"
              data-name="Path 25"
              d="M522.553,46.462a4.567,4.567,0,1,1-9.134,0c0-2.522,4.566-10.182,4.566-10.182s4.569,7.66,4.569,10.182"
              transform="translate(592.903 414.09)"
              fill="#175391"
            />
          </g>
        </svg>
      )}
    </Translation>
  );
};

export { care, frustrated, like, love, laugh, sad };
