import api from '../../api';
import Logger from '../../../lib/Logger';

export const TYPES = {
  PARTNERSHIP_ACCOUNT_READ_REQUEST: 'PARTNERSHIP_ACCOUNT_READ_REQUEST',
  PARTNERSHIP_ACCOUNT_READ_SUCCESS: 'PARTNERSHIP_ACCOUNT_READ_SUCCESS',
  PARTNERSHIP_ACCOUNT_READ_FAILURE: 'PARTNERSHIP_ACCOUNT_READ_FAILURE',
  PARTNERSHIP_ACCOUNT_UPDATE_REQUEST: 'PARTNERSHIP_ACCOUNT_UPDATE_REQUEST',
  PARTNERSHIP_ACCOUNT_UPDATE_SUCCESS: 'PARTNERSHIP_ACCOUNT_UPDATE_SUCCESS',
  PARTNERSHIP_ACCOUNT_UPDATE_FAILURE: 'PARTNERSHIP_ACCOUNT_UPDATE_FAILURE',
  PARTNERSHIP_ACCOUNT_UPDATE_AVATAR_REQUEST:
    'PARTNERSHIP_ACCOUNT_UPDATE_AVATAR_REQUEST',
  PARTNERSHIP_ACCOUNT_UPDATE_AVATAR_SUCCESS:
    'PARTNERSHIP_ACCOUNT_UPDATE_AVATAR_SUCCESS',
  PARTNERSHIP_ACCOUNT_UPDATE_AVATAR_FAILURE:
    'PARTNERSHIP_ACCOUNT_UPDATE_AVATAR_FAILURE',
  PARTNERSHIP_ACCOUNT_DELETE_AVATAR_REQUEST:
    'PARTNERSHIP_ACCOUNT_DELETE_AVATAR_REQUEST',
  PARTNERSHIP_ACCOUNT_DELETE_AVATAR_SUCCESS:
    'PARTNERSHIP_ACCOUNT_DELETE_AVATAR_SUCCESS',
  PARTNERSHIP_ACCOUNT_DELETE_AVATAR_FAILURE:
    'PARTNERSHIP_ACCOUNT_DELETE_AVATAR_FAILURE',
  PARTNERSHIP_ACCOUNT_FORM_DESTROY: 'PARTNERSHIP_ACCOUNT_FORM_DESTROY',
  PARTNERSHIP_ACCOUNT_ACCESS_ADMIN_UPDATE_REQUEST:
    'PARTNERSHIP_ACCOUNT_ACCESS_ADMIN_UPDATE_REQUEST',
  PARTNERSHIP_ACCOUNT_ACCESS_ADMIN_UPDATE_SUCCESS:
    'PARTNERSHIP_ACCOUNT_ACCESS_ADMIN_UPDATE_SUCCESS',
  PARTNERSHIP_ACCOUNT_ACCESS_ADMIN_UPDATE_FAILURE:
    'PARTNERSHIP_ACCOUNT_ACCESS_ADMIN_UPDATE_FAILURE',
  PARTNERSHIP_ACCOUNT_ACCESS_ADMIN_FORM_DESTROY:
    'PARTNERSHIP_ACCOUNT_ACCESS_ADMIN_FORM_DESTROY',
  PARTNERSHIP_ACCOUNT_ACCESS_CARE_GUIDE_UPDATE_REQUEST:
    'PARTNERSHIP_ACCOUNT_ACCESS_CARE_GUIDE_UPDATE_REQUEST',
  PARTNERSHIP_ACCOUNT_ACCESS_CARE_GUIDE_UPDATE_SUCCESS:
    'PARTNERSHIP_ACCOUNT_ACCESS_CARE_GUIDE_UPDATE_SUCCESS',
  PARTNERSHIP_ACCOUNT_ACCESS_CARE_GUIDE_UPDATE_FAILURE:
    'PARTNERSHIP_ACCOUNT_ACCESS_CARE_GUIDE_UPDATE_FAILURE',
  PARTNERSHIP_ACCOUNT_ACCESS_CARE_GUIDE_FORM_DESTROY:
    'PARTNERSHIP_ACCOUNT_ACCESS_CARE_GUIDE_FORM_DESTROY',
};

export function partnershipAccountReadRequest() {
  Logger.log(
    'debug',
    `[state.partnershipAccount.actions] partnershipAccountReadRequest()`
  );
  return {
    type: TYPES.PARTNERSHIP_ACCOUNT_READ_REQUEST,
  };
}

export function partnershipAccountReadSuccess(data) {
  Logger.log(
    'debug',
    `[state.partnershipAccount.actions] partnershipAccountReadSuccess(###)`,
    data
  );
  return {
    type: TYPES.PARTNERSHIP_ACCOUNT_READ_SUCCESS,
    id: data.id,
    name: data.name,
    color_hex: data.color_hex,
    hide_partner_tags: data.hide_partner_tags,
    logo: data.logo,
    access_role_admin_in_app: data.access_role_admin_in_app,
    access_role_admin_in_care_guide: data.access_role_admin_in_care_guide,
    access_tabs_in_app: data.access_tabs_in_app,
    receivedAt: Date.now(),
  };
}

export function partnershipAccountReadFailure(error) {
  Logger.log(
    'debug',
    `[state.partnershipAccount.actions] partnershipAccountReadFailure(###)`,
    error
  );
  return {
    type: TYPES.PARTNERSHIP_ACCOUNT_READ_FAILURE,
    error: error,
  };
}

export function partnershipAccountUpdateRequest(data) {
  Logger.log(
    'debug',
    `[state.partnershipAccount.actions] partnershipAccountUpdateRequest(###)`,
    data
  );
  return {
    type: TYPES.PARTNERSHIP_ACCOUNT_UPDATE_REQUEST,
    name: data.name,
    color_hex: data.color_hex,
    hide_partner_tags: data.hide_partner_tags,
    access_role_admin_in_app: data.access_role_admin_in_app,
    access_role_admin_in_care_guide: data.access_role_admin_in_care_guide,
    access_tabs_in_app: data.access_tabs_in_app,
  };
}

export function partnershipAccountUpdateSuccess(data) {
  Logger.log(
    'debug',
    `[state.partnershipAccount.actions] partnershipAccountUpdateSuccess(###)`,
    data
  );
  return {
    type: TYPES.PARTNERSHIP_ACCOUNT_UPDATE_SUCCESS,
    id: data.id,
    name: data.name,
    color_hex: data.color_hex,
    hide_partner_tags: data.hide_partner_tags,
    access_role_admin_in_app: data.access_role_admin_in_app,
    access_role_admin_in_care_guide: data.access_role_admin_in_care_guide,
    access_tabs_in_app: data.access_tabs_in_app,
    logo: data.logo,
    receivedAt: Date.now(),
  };
}

export function partnershipAccountUpdateFailure(error) {
  Logger.log(
    'debug',
    `[state.partnershipAccount.actions] partnershipAccountUpdateFailure(###)`,
    error
  );
  return {
    type: TYPES.PARTNERSHIP_ACCOUNT_UPDATE_FAILURE,
    error: error,
  };
}

export function partnershipAccountUpdateLogoRequest(data) {
  Logger.log(
    'debug',
    `[state.partnershipAccount.actions] partnershipAccountUpdateLogoRequest(###)`,
    data
  );
  return {
    type: TYPES.PARTNERSHIP_ACCOUNT_UPDATE_AVATAR_REQUEST,
  };
}

export function partnershipAccountUpdateLogoSuccess(data) {
  Logger.log(
    'debug',
    `[state.partnershipAccount.actions] partnershipAccountUpdateLogoSuccess(###)`,
    data
  );
  return {
    type: TYPES.PARTNERSHIP_ACCOUNT_UPDATE_AVATAR_SUCCESS,
    id: data.id,
    name: data.name,
    color_hex: data.color_hex,
    hide_partner_tags: data.hide_partner_tags,
    access_role_admin_in_app: data.access_role_admin_in_app,
    access_role_admin_in_care_guide: data.access_role_admin_in_care_guide,
    access_tabs_in_app: data.access_tabs_in_app,
    logo: data.logo,
  };
}

export function partnershipAccountUpdateLogoFailure(error) {
  Logger.log(
    'debug',
    `[state.partnershipAccount.actions] partnershipAccountUpdateLogoFailure(###)`,
    error
  );
  return {
    type: TYPES.PARTNERSHIP_ACCOUNT_UPDATE_AVATAR_FAILURE,
    error: error,
  };
}

export function partnershipAccountDeleteLogoRequest() {
  Logger.log(
    'debug',
    `[state.partnershipAccount.actions] partnershipAccountDeleteLogoRequest(###)`
  );
  return {
    type: TYPES.PARTNERSHIP_ACCOUNT_DELETE_AVATAR_REQUEST,
  };
}

export function partnershipAccountDeleteLogoSuccess(data) {
  Logger.log(
    'debug',
    `[state.partnershipAccount.actions] partnershipAccountDeleteLogoSuccess()`,
    data
  );
  return {
    type: TYPES.PARTNERSHIP_ACCOUNT_DELETE_AVATAR_SUCCESS,
    id: data.id,
    name: data.name,
    color_hex: data.color_hex,
    hide_partner_tags: data.hide_partner_tags,
    access_role_admin_in_app: data.access_role_admin_in_app,
    access_role_admin_in_care_guide: data.access_role_admin_in_care_guide,
    access_tabs_in_app: data.access_tabs_in_app,
    logo: data.logo,
  };
}

export function partnershipAccountDeleteLogoFailure(error) {
  Logger.log(
    'debug',
    `[state.partnershipAccount.actions] partnershipAccountDeleteLogoFailure(%j)`,
    error
  );
  return {
    type: TYPES.PARTNERSHIP_ACCOUNT_DELETE_AVATAR_FAILURE,
    error: error,
  };
}

export function partnershipAccountFormDestroy(formState = null) {
  Logger.log(
    'debug',
    `[state.partnershipAccount.actions] partnershipAccountFormDestroy(###)`,
    formState
  );
  return {
    type: TYPES.PARTNERSHIP_ACCOUNT_FORM_DESTROY,
    form: formState,
  };
}

export function partnershipAccountAccessUpdateRequest(data) {
  Logger.log(
    'debug',
    `[state.partnershipAccount.actions] partnershipAccountAccessUpdateRequest(###)`,
    data
  );
  return {
    type: TYPES.PARTNERSHIP_ACCOUNT_ACCESS_ADMIN_UPDATE_REQUEST,
    access_role_admin_in_app: data.access_role_admin_in_app,
    access_tabs_in_app: data.access_tabs_in_app,
  };
}

export function partnershipAccountAccessUpdateSuccess(data) {
  Logger.log(
    'debug',
    `[state.partnershipAccount.actions] partnershipAccountAccessUpdateSuccess(###)`,
    data
  );
  return {
    type: TYPES.PARTNERSHIP_ACCOUNT_ACCESS_ADMIN_UPDATE_SUCCESS,
    id: data.id,
    name: data.name,
    color_hex: data.color_hex,
    hide_partner_tags: data.hide_partner_tags,
    access_role_admin_in_app: data.access_role_admin_in_app,
    access_role_admin_in_care_guide: data.access_role_admin_in_care_guide,
    access_tabs_in_app: data.access_tabs_in_app,
    logo: data.logo,
    receivedAt: Date.now(),
  };
}

export function partnershipAccountAccessUpdateFailure(error) {
  Logger.log(
    'debug',
    `[state.partnershipAccount.actions] partnershipAccountAccessUpdateFailure(###)`,
    error
  );
  return {
    type: TYPES.PARTNERSHIP_ACCOUNT_ACCESS_ADMIN_UPDATE_FAILURE,
    error: error,
  };
}

export function partnershipAccountAccessCareGuideUpdateRequest(data) {
  Logger.log(
    'debug',
    `[state.partnershipAccount.actions] partnershipAccountAccessCareGuideUpdateRequest(###)`,
    data
  );
  return {
    type: TYPES.PARTNERSHIP_ACCOUNT_ACCESS_CARE_GUIDE_UPDATE_REQUEST,
    access_role_admin_in_care_guide: data.access_role_admin_in_care_guide,
  };
}

export function partnershipAccountAccessCareGuideUpdateSuccess(data) {
  Logger.log(
    'debug',
    `[state.partnershipAccount.actions] partnershipAccountAccessCareGuideUpdateSuccess(###)`,
    data
  );
  return {
    type: TYPES.PARTNERSHIP_ACCOUNT_ACCESS_CARE_GUIDE_UPDATE_SUCCESS,
    id: data.id,
    name: data.name,
    color_hex: data.color_hex,
    hide_partner_tags: data.hide_partner_tags,
    access_role_admin_in_app: data.access_role_admin_in_app,
    access_role_admin_in_care_guide: data.access_role_admin_in_care_guide,
    access_tabs_in_app: data.access_tabs_in_app,
    logo: data.logo,
    receivedAt: Date.now(),
  };
}

export function partnershipAccountAccessCareGuideUpdateFailure(error) {
  Logger.log(
    'debug',
    `[state.partnershipAccount.actions] partnershipAccountAccessCareGuideUpdateFailure(###)`,
    error
  );
  return {
    type: TYPES.PARTNERSHIP_ACCOUNT_ACCESS_CARE_GUIDE_UPDATE_FAILURE,
    error: error,
  };
}

export function partnershipAccountAccessFormDestroy(formState = null) {
  Logger.log(
    'debug',
    `[state.partnershipAccount.actions] partnershipAccountAccessFormDestroy(%j)`,
    formState
  );
  return {
    type: TYPES.PARTNERSHIP_ACCOUNT_ACCESS_ADMIN_FORM_DESTROY,
    form: formState,
  };
}

export function partnershipAccountAccessCareGuideFormDestroy(formState = null) {
  Logger.log(
    'debug',
    `[state.partnershipAccount.actions] partnershipAccountAccessCareGuideFormDestroy(%j)`,
    formState
  );
  return {
    type: TYPES.PARTNERSHIP_ACCOUNT_ACCESS_CARE_GUIDE_FORM_DESTROY,
    form: formState,
  };
}

// API THUNK ACTION CREATORS

export function loadPartnershipAccount(partnership_id, cb = function () {}) {
  Logger.log(
    'debug',
    `[state.partnershipAccount.actions] loadPartnershipAccount(${partnership_id})`
  );

  return async function (dispatch) {
    dispatch(partnershipAccountReadRequest());

    // call API
    const response = await api.getPartnershipAccount(partnership_id);
    let success = false;

    // get partnership account success
    if (200 === response.get('status')) {
      Logger.log('info', `GET API partnership account success.`);
      success = true;
      const data = {
        id: response.getIn(['data', 'partnership_account', 'id']),
        name: response.getIn(['data', 'partnership_account', 'name']),
        logo: response.getIn(['data', 'partnership_account', 'logo']),
        color_hex: response.getIn(['data', 'partnership_account', 'color_hex']),
        hide_partner_tags: response.getIn([
          'data',
          'partnership_account',
          'hide_partner_tags',
        ]),
        access_role_admin_in_app: response.getIn([
          'data',
          'partnership_account',
          'access_role_admin_in_app',
        ]),
        access_role_admin_in_care_guide: response.getIn([
          'data',
          'partnership_account',
          'access_role_admin_in_care_guide',
        ]),
        access_tabs_in_app: response.getIn([
          'data',
          'partnership_account',
          'access_tabs_in_app',
        ]),
      };

      dispatch(partnershipAccountReadSuccess(data));

      // get partnership account failure
    } else {
      Logger.log('info', `GET API partnership account failure.`);
      dispatch(
        partnershipAccountReadFailure(response.getIn(['data', 'error']))
      );
    }

    // callback function
    cb(success);
  };
}

export function updatePartnershipAccount(
  partnership_id,
  data,
  cb = function () {}
) {
  Logger.log(
    'debug',
    `[state.partnershipAccount.actions] updatePartnershipAccount(${partnership_id} ,%j)`,
    data
  );

  return async function (dispatch) {
    dispatch(partnershipAccountUpdateRequest(data));

    // call API
    const response = await api.putPartnershipAccount(partnership_id, data);

    // put partnership account success
    if (200 === response.get('status')) {
      Logger.log('info', `PUT API partnership account success.`);

      const data = {
        id: response.getIn(['data', 'partnership_account', 'id']),
        name: response.getIn(['data', 'partnership_account', 'name']),
        logo: response.getIn(['data', 'partnership_account', 'logo']),
        color_hex: response.getIn(['data', 'partnership_account', 'color_hex']),
        hide_partner_tags: response.getIn([
          'data',
          'partnership_account',
          'hide_partner_tags',
        ]),
        access_role_admin_in_app: response.getIn([
          'data',
          'partnership_account',
          'access_role_admin_in_app',
        ]),
        access_role_admin_in_care_guide: response.getIn([
          'data',
          'partnership_account',
          'access_role_admin_in_care_guide',
        ]),
        access_tabs_in_app: response.getIn([
          'data',
          'partnership_account',
          'access_tabs_in_app',
        ]),
      };

      dispatch(partnershipAccountUpdateSuccess(data));

      // get partnership account failure
    } else {
      Logger.log('info', `PUT API partnership account failure.`);
      dispatch(
        partnershipAccountUpdateFailure(response.getIn(['data', 'error']))
      );
    }

    // callback function
    cb();
  };
}

export function updatePartnershipAccountLogo(
  partnership_id,
  data,
  cb = function () {}
) {
  Logger.log(
    'debug',
    `[state.partnershipAccount.actions] updatePartnershipAccountLogo(${partnership_id},%j)`,
    data
  );

  return async function (dispatch) {
    dispatch(partnershipAccountUpdateLogoRequest(data));

    // call API
    const response = await api.postPartnershipAccountLogo(partnership_id, data);
    let success = false;

    // post partnership account logo success
    if (201 === response.get('status')) {
      Logger.log('info', `POST API partnership account logo success.`);

      const data = {
        id: response.getIn(['data', 'partnership_account', 'id']),
        name: response.getIn(['data', 'partnership_account', 'name']),
        logo: response.getIn(['data', 'partnership_account', 'logo']),
        color_hex: response.getIn(['data', 'partnership_account', 'color_hex']),
        hide_partner_tags: response.getIn([
          'data',
          'partnership_account',
          'hide_partner_tags',
        ]),
        access_role_admin_in_app: response.getIn([
          'data',
          'partnership_account',
          'access_role_admin_in_app',
        ]),
        access_role_admin_in_care_guide: response.getIn([
          'data',
          'partnership_account',
          'access_role_admin_in_care_guide',
        ]),
        access_tabs_in_app: response.getIn([
          'data',
          'partnership_account',
          'access_tabs_in_app',
        ]),
      };

      dispatch(partnershipAccountUpdateLogoSuccess(data));
      success = true;

      // post partnership account logo failure
    } else {
      Logger.log('info', `POST API partnership account logo failure.`);
      dispatch(
        partnershipAccountUpdateLogoFailure(response.getIn(['data', 'error']))
      );
    }

    // callback function
    cb(success);
  };
}

export function deletePartnershipAccountLogo(
  partnership_id,
  cb = function () {}
) {
  Logger.log(
    'debug',
    `[state.partnershipAccount.actions] deletePartnershipAccountLogo(${partnership_id})`
  );

  return async function (dispatch) {
    dispatch(partnershipAccountDeleteLogoRequest());

    // call API
    const response = await api.deletePartnershipAccountLogo(partnership_id);
    let success = false;

    // delete partnership account logo success
    if (200 === response.get('status')) {
      Logger.log('info', `DELETE API partnership account logo success.`);

      const data = {
        id: response.getIn(['data', 'partnership_account', 'id']),
        name: response.getIn(['data', 'partnership_account', 'name']),
        logo: response.getIn(['data', 'partnership_account', 'logo']),
        color_hex: response.getIn(['data', 'partnership_account', 'color_hex']),
        hide_partner_tags: response.getIn([
          'data',
          'partnership_account',
          'hide_partner_tags',
        ]),
        access_role_admin_in_app: response.getIn([
          'data',
          'partnership_account',
          'access_role_admin_in_app',
        ]),
        access_role_admin_in_care_guide: response.getIn([
          'data',
          'partnership_account',
          'access_role_admin_in_care_guide',
        ]),
        access_tabs_in_app: response.getIn([
          'data',
          'partnership_account',
          'access_tabs_in_app',
        ]),
      };

      dispatch(partnershipAccountDeleteLogoSuccess(data));
      success = true;

      // delete partnership account logo failure
    } else {
      Logger.log('info', `DELETE API partnership account logo failure.`);
      dispatch(
        partnershipAccountDeleteLogoFailure(response.getIn(['data', 'error']))
      );
    }

    // callback function
    cb(success);
  };
}

export function updatePartnershipAccountAccess(
  partnership_id,
  data,
  cb = function () {}
) {
  Logger.log(
    'debug',
    `[state.partnershipAccount.actions] updatePartnershipAccountAccess(%j, ###)`,
    data
  );

  return async function (dispatch) {
    dispatch(partnershipAccountAccessUpdateRequest(partnership_id, data));

    // call API
    const response = await api.putPartnershipAccountAccessAdmin(
      partnership_id,
      data
    );
    let success = false;
    // put partnership account access success
    if (200 === response.get('status')) {
      Logger.log('info', `PUT API partnership account access success.`);

      const data = {
        id: response.getIn(['data', 'partnership_account', 'id']),
        name: response.getIn(['data', 'partnership_account', 'name']),
        logo: response.getIn(['data', 'partnership_account', 'logo']),
        color_hex: response.getIn(['data', 'partnership_account', 'color_hex']),
        hide_partner_tags: response.getIn([
          'data',
          'partnership_account',
          'hide_partner_tags',
        ]),
        access_role_admin_in_app: response.getIn([
          'data',
          'partnership_account',
          'access_role_admin_in_app',
        ]),
        access_role_admin_in_care_guide: response.getIn([
          'data',
          'partnership_account',
          'access_role_admin_in_care_guide',
        ]),
        access_tabs_in_app: response.getIn([
          'data',
          'partnership_account',
          'access_tabs_in_app',
        ]),
      };

      dispatch(partnershipAccountAccessUpdateSuccess(data));
      success = true;

      // get partnership account access failure
    } else {
      Logger.log('info', `PUT API partnership account access failure.`);
      dispatch(
        partnershipAccountAccessUpdateFailure(response.getIn(['data', 'error']))
      );
    }

    // callback function
    cb(success);
  };
}

export function updatePartnershipAccountAccessCareGuide(
  partnership_id,
  data,
  cb = function () {}
) {
  Logger.log(
    'debug',
    `[state.partnershipAccount.actions] updatePartnershipAccountAccessCareGuide(%j, ###)`,
    data
  );

  return async function (dispatch) {
    dispatch(
      partnershipAccountAccessCareGuideUpdateRequest(partnership_id, data)
    );

    // call API
    const response = await api.putPartnershipAccountAccessCareGuideAdmin(
      partnership_id,
      data
    );
    let success = false;
    // put partnership account access care guide success
    if (200 === response.get('status')) {
      Logger.log(
        'info',
        `PUT API partnership account access care guide success.`
      );

      const data = {
        id: response.getIn(['data', 'partnership_account', 'id']),
        name: response.getIn(['data', 'partnership_account', 'name']),
        logo: response.getIn(['data', 'partnership_account', 'logo']),
        color_hex: response.getIn(['data', 'partnership_account', 'color_hex']),
        hide_partner_tags: response.getIn([
          'data',
          'partnership_account',
          'hide_partner_tags',
        ]),
        access_role_admin_in_app: response.getIn([
          'data',
          'partnership_account',
          'access_role_admin_in_app',
        ]),
        access_role_admin_in_care_guide: response.getIn([
          'data',
          'partnership_account',
          'access_role_admin_in_care_guide',
        ]),
        access_tabs_in_app: response.getIn([
          'data',
          'partnership_account',
          'access_tabs_in_app',
        ]),
      };

      dispatch(partnershipAccountAccessCareGuideUpdateSuccess(data));
      success = true;

      // get partnership account access failure
    } else {
      Logger.log(
        'info',
        `PUT API partnership account access care guide failure.`
      );
      dispatch(
        partnershipAccountAccessCareGuideUpdateFailure(
          response.getIn(['data', 'error'])
        )
      );
    }

    // callback function
    cb(success);
  };
}

Logger.log('silly', `state.partnershipAccount.actions loaded.`);
