import {connect} from 'react-redux';
import {schema} from '../../../../../state/schema';
import {denormalize} from 'normalizr';

import {
  calendarEventAdminHideEventDetail,
  loadCalendarEventAdmin,
  loadCalendarEventParticipants,
  calendarEventParticipantSetPage,
} from '../../../../../state/modules/calendarEventsAdmin/actions';

import EventModal from '../components/EventModal';
import storage from "../../../../../lib/Storage";
const mapStateToProps = (state, ownProps) => {

  const eventId = state.calendarEventsAdmin.get('eventDetailEventId');

  const total = state.calendarEventsAdmin.getIn(['calendarEventParticipants', eventId, 'total']);
  const pageParticipants = state.calendarEventsAdmin.get('pageParticipants');
  const areParticipantEventsLoading = state.calendarEventsAdmin.get('areParticipantEventsLoading');

  const event = eventId
    ? denormalize(
        state.entities.getIn(['calendarEventsAdmin', eventId]),
        schema.calendarEventAdmin,
        state.entities.toJS()
      )
    : null;
    
    const result = state.calendarEventsAdmin.getIn(['calendarEventParticipants', eventId, pageParticipants, 10]);

    const list = result
    ? result.map(x => {
      return {
        key: x,
        ...denormalize(
          state.entities.getIn(['calendarEventParticipants', x]),
          schema.calendarEventParticipant,
          state.entities.toJS()
        )
      };
    }).toArray()
    : [];

  return {
    areParticipantEventsLoading: areParticipantEventsLoading,
    pageParticipants: pageParticipants,
    total: total,
    list: list,
    visible: state.calendarEventsAdmin.get('eventDetailIsVisible'),
    eventId: eventId,
    event: event,
    activeId: storage.get('local', 'partnershipId'),
  }
}

const mapDispatchToProps = dispatch => {
  return {
    hideDetail: () => {
      dispatch(calendarEventAdminHideEventDetail());
    },
    load: (partnership_id, id, cb) => {
      dispatch(loadCalendarEventAdmin(partnership_id, id, cb));
    },
    loadParticipants: (calendarEventId, page, limit, cb) =>{
      dispatch(loadCalendarEventParticipants(calendarEventId, page, limit, cb))
    },
    setPage: (page) => {
      dispatch(calendarEventParticipantSetPage(page));
    },
  }
}

const EventModalContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(EventModal);

export default EventModalContainer;
