import {connect} from 'react-redux';
import {denormalize} from 'normalizr';

import {schema} from '../../../../../state/schema';
import {loadCareGuidePartnerTagsAdmin, deleteCareGuidePartnerTagAdmin} from '../../../../../state/modules/careGuidePartnerTagsAdmin/actions';
import {sendMessage} from '../../../../../state/actions';
import CareGuidePartnerTagsAdminList from '../components/CareGuidePartnerTagsAdminList';
import storage from "../../../../../lib/Storage";

const mapStateToProps = (state, ownProps) => {

  const page = ownProps.page;
  const limit = ownProps.limit;
  const order = ownProps.order;
  const result = state.careGuidePartnerTagsAdmin.getIn(['pages', order, limit, page]);

  return {
    list: result 
      ? result.map(x => {
          return {
            key: x,
            ...denormalize(
              state.entities.getIn(['careGuidePartnerTagsAdmin', x]),
              schema.careGuidePartnerTagAdmin,
              state.entities.toJS()
            )
          };
        }).toArray()
      : [],
    isLoading: state.careGuidePartnerTagsAdmin.get('isLoading'),
    total: state.careGuidePartnerTagsAdmin.get('total'),
    activeId: storage.get('local', 'partnershipId'),
  }
}

const mapDispatchToProps = dispatch => {
  return {
    load: (parntership_id, page, limit, order, filter, cb) => {
      dispatch(loadCareGuidePartnerTagsAdmin(parntership_id, page, limit, order, filter, cb));
    },
    remove: (partnership_id, id, cb) => {
      dispatch(deleteCareGuidePartnerTagAdmin(partnership_id, id, cb));
    },
    sendMessage: (level, title, body, expires) => {
      dispatch(sendMessage(level, title, body, expires));
    },
  }
}

const CareGuidePartnerTagsAdminListContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(CareGuidePartnerTagsAdminList);

export default CareGuidePartnerTagsAdminListContainer;