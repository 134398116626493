import { connect } from 'react-redux';
import { List } from 'immutable';
import { denormalize } from 'normalizr';

import { schema } from '../../../../../state/schema';
import {
  createJournalReply,
  loadJournalReplies,
  journalReplyFormDestroy,
} from '../../../../../state/modules/journalReplies/actions';
import JournalReplyForm from '../components/JournalReplyForm';

const inputs = List(['rating', 'body']);

const mapStateToProps = (state, ownProps) => {
  const errors = {};
  for (const val of inputs.values()) {
    if (state.journalReplies.getIn(['form', 'errors', val])) {
      errors[val] = state.journalReplies.getIn(['form', 'errors', val]);
    }
  }

  const userProfile = state.userAccount.getIn(['data', 'profile_id'])
    ? {
        id: state.userAccount.getIn(['data', 'profile_id']),
        first_name: state.userAccount.getIn(['data', 'first_name']),
        last_name: state.userAccount.getIn(['data', 'last_name']),
        avatar: state.userAccount.getIn(['data', 'avatar']),
      }
    : null;

  const careGuide = ownProps.careGuideId
    ? denormalize(
        state.entities.getIn(['careGuides', ownProps.careGuideId]),
        schema.careGuide,
        state.entities.toJS()
      )
    : null;

  return {
    careGuide,
    isSubmitting: state.journalReplies.getIn(['form', 'isSubmitting']),
    success: state.journalReplies.getIn(['form', 'success']),
    userProfile: userProfile,
    errors: errors,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    create: (careGuideId, journalPostId, data, cb) => {
      dispatch(createJournalReply(careGuideId, journalPostId, data, cb));
    },
    load: (careGuideId, journalPostId, page, limit, order, filter, cb) => {
      dispatch(
        loadJournalReplies(
          careGuideId,
          journalPostId,
          page,
          limit,
          order,
          filter,
          cb
        )
      );
    },
    formDestroy: (formState) => {
      dispatch(journalReplyFormDestroy(formState));
    },
  };
};

const JournalReplyFormContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(JournalReplyForm);

export default JournalReplyFormContainer;
