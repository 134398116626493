import {connect} from 'react-redux';
import {destroySession} from '../../../../state/actions';
import Auth from '../../../../lib/Auth';
import DrawerMenuAdmin from '../components/DrawerMenuAdmin';
import storage from "../../../../lib/Storage";

const mapStateToProps = (state, ownProps) => {

  return {
    accessAdmin: storage.get('local', 'partnershipData')?.access_role_admin_in_app,
    role: storage.get('local', 'partnershipData')?.role,
    activeId: storage.get('local', 'partnershipId'),
    isAuthenticated: Auth.isAuthTokenValid(state.session.get('authToken'), state.session.get('authExpires')),
  }
}

const mapDispatchToProps = dispatch => {
  return {
    destroySession: (cb) => {
      dispatch(destroySession(cb));
    }
  }
}

const DrawerMenuAdminContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(DrawerMenuAdmin);

export default DrawerMenuAdminContainer;