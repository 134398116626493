import React from 'react';
import {Translation, getI18n} from 'react-i18next';
import {Avatar, Button, Space, Upload} from 'antd';
import {CloseOutlined, LoadingOutlined, PlusOutlined, UploadOutlined, UserOutlined} from '@ant-design/icons';

import imageURL from '../../../elements/lib/imageURL';
import message from '../../../elements/lib/MessageWrapper';
import Logger from '../../../../../lib/Logger';


const CareGuideImageForm = ({careGuideId, isEditing, ...props}) => {

  // upload criteria
  const fileTypes = {
    'image/png': 'png',
    'image/jpeg': 'jpg'
  };
  const fileSizeLimit = 1024 * 1024 * 4;

  const reFileKey = /(?:(.*?)\/)?(.*?)\.(\w+)/;

  const beforeUpload = file => {

    const isSupportedFileType = Object.keys(fileTypes).includes(file.type);
    if (!isSupportedFileType) {
      message.error(getI18n().t('care_guides_image_form_feedback_file_types'));
    }

    const isUnderFileSizeLimit = file.size < fileSizeLimit;
    if (!isUnderFileSizeLimit) {
      message.error(getI18n().t('care_guides_image_form_feedback_file_size_limit'));
    }

    return isSupportedFileType && isUnderFileSizeLimit;
  }

  const uploadButton = (
    <Translation>{(t) => 
      <div>
        {props.isSubmitting ? <LoadingOutlined /> : <PlusOutlined />}
        <div style={{ marginTop: 8 }}>{t('action_upload')}</div>
      </div>
    }</Translation>
  );

  const errorMessage = () => {
    message.error(getI18n().t('care_guides_image_form_feedback_error'));
  }

  const submit = async (data) => {

    // get upload URL for S3 bucket
    props.getUploadUrl(fileTypes[data.file.type], 'care-guide-profile', async (success, upload_url, file_id) => {
      if (success) {

        // upload image
        props.upload(upload_url, data.file, async (uploadSuccess) => {

          const directory = 'care-guide-profile/';
          file_id = file_id.replace(directory, '')
          if (uploadSuccess) {
            const matches = reFileKey.exec(file_id);
            const updateData = {
              file_directory: directory.slice(0,-1),
              file_name: matches[2],
              file_extension: matches[3],
            };

            // process image
            props.update(careGuideId, updateData, (updateSuccess) => {
              if (updateSuccess) {
                message.success(getI18n().t('care_guides_image_form_feedback_success'));
              } else {
                errorMessage();    
              }
            })
          } else {
            errorMessage();
          }
        });
      } else {
        errorMessage();
      }
    });
  }

  const onClickDeleteHandler = () => {
    props.delete(careGuideId, (success) => {
      if (success) {
        message.success(getI18n().t('care_guides_image_form_feedback_delete_success'));
      } else {
        message.error(getI18n().t('care_guides_image_form_feedback_delete_error'));
      }
    });
  }

  const size = 160;
  const imageUrl = props.image && props.image.file_name && props.image.file_extension && props.image.file_directory
    ? imageURL(props.image.file_directory, props.image.file_name, props.image.file_extension, size, size, 'crop')
    : null;

  return (
    <Translation>{(t) => 
      <div className="care-guide-image-form">

        {isEditing
          ? <>
              <Upload
                name="care_guide_image"
                listType="picture-card"
                className="care-guide-image-uploader"
                showUploadList={false}
                customRequest={submit}
                beforeUpload={beforeUpload}
              >
                  {imageUrl ? <img src={imageUrl} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
              </Upload>
            
              <Space direction="vertical">
                <Upload
                  name="care_guide_image"
                  className="care-guide-image-button"
                  showUploadList={false}
                  customRequest={submit}
                  beforeUpload={beforeUpload}
                >
                  <Button
                    block
                    icon={<UploadOutlined />}
                    loading={props.isSubmitting}
                  >
                    {t('action_upload')}
                  </Button>
                </Upload>
              
                <Button
                  block
                  icon={<CloseOutlined />}
                  loading={props.isDeleting}
                  onClick={onClickDeleteHandler}
                >
                  {t('action_remove')}
                </Button>
              </Space>
              
            </>
          : <>
              <div className="care-guide-image-container">
                {imageUrl
                  // ? <img src={imageUrl} alt="avatar" style={{ width: '100%' }} />
                  ? <Avatar size={size + 2} src={imageUrl} />
                  : <Avatar size={size + 2} icon={<UserOutlined />} />}
              </div>
            </>}

      </div>
    }</Translation>
  );
}

export default CareGuideImageForm;

Logger.log('silly', `CareGuideImageForm loaded.`);
