import {connect} from 'react-redux';

import {loadTimezones} from '../../../../../state/modules/timezones/actions';
import TimezoneInput from '../components/TimezoneInput';

const mapStateToProps = (state, ownProps) => {
  const timezones = state.timezones.get('data', {});
  return {
    options: Object.keys(timezones)
      .map(id => {return {label: timezones[id], value: id}}),
    isLoading: state.timezones.get('isLoading'),
  }
}

const mapDispatchToProps = dispatch => {
  return {
    load: (cb) => {
      dispatch(loadTimezones(cb));
    },
  }
}

const TimezoneInputContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(TimezoneInput);

export default TimezoneInputContainer;
