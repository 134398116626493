import { normalize } from 'normalizr';

import api from '../../api';
import { schema } from '../../schema';
import { addEntities, removeEntity } from '../../actions';
import Logger from '../../../lib/Logger';

export const TYPES = {
  LIST_EVENTS_REQUEST: 'CALENDAR/LIST_EVENTS_REQUEST',
  LIST_EVENTS_SUCCESS: 'CALENDAR/LIST_EVENTS_SUCCESS',
  LIST_EVENTS_FAILURE: 'CALENDAR/LIST_EVENTS_FAILURE',
  CREATE_EVENT_REQUEST: 'CALENDAR/CREATE_EVENT_REQUEST',
  CREATE_EVENT_SUCCESS: 'CALENDAR/CREATE_EVENT_SUCCESS',
  CREATE_EVENT_FAILURE: 'CALENDAR/CREATE_EVENT_FAILURE',
  CREATE_EVENT_PARTNER_REQUEST: 'CALENDAR/CREATE_EVENT_PARTNER_REQUEST',
  CREATE_EVENT_PARTNER_SUCCESS: 'CALENDAR/CREATE_EVENT_PARTNER_SUCCESS',
  CREATE_EVENT_PARTNER_FAILURE: 'CALENDAR/CREATE_EVENT_PARTNER_FAILURE',
  UPDATE_EVENT_REQUEST: 'CALENDAR/UPDATE_EVENT_REQUEST',
  UPDATE_EVENT_SUCCESS: 'CALENDAR/UPDATE_EVENT_SUCCESS',
  UPDATE_EVENT_FAILURE: 'CALENDAR/UPDATE_EVENT_FAILURE',
  UPDATE_EVENT_PARTNER_REQUEST: 'CALENDAR/UPDATE_EVENT_PARTNER_REQUEST',
  UPDATE_EVENT_PARTNER_SUCCESS: 'CALENDAR/UPDATE_EVENT_PARTNER_SUCCESS',
  UPDATE_EVENT_PARTNER_FAILURE: 'CALENDAR/UPDATE_EVENT_PARTNER_FAILURE',
  PATCH_EVENT_REQUEST: 'CALENDAR/PATCH_EVENT_REQUEST',
  PATCH_EVENT_SUCCESS: 'CALENDAR/PATCH_EVENT_SUCCESS',
  PATCH_EVENT_FAILURE: 'CALENDAR/PATCH_EVENT_FAILURE',
  PATCH_EVENT_PARTNER_REQUEST: 'CALENDAR/PATCH_EVENT_PARTNER_REQUEST',
  PATCH_EVENT_PARTNER_SUCCESS: 'CALENDAR/PATCH_EVENT_PARTNER_SUCCESS',
  PATCH_EVENT_PARTNER_FAILURE: 'CALENDAR/PATCH_EVENT_PARTNER_FAILURE',
  DELETE_EVENT_REQUEST: 'CALENDAR/DELETE_EVENT_REQUEST',
  DELETE_EVENT_SUCCESS: 'CALENDAR/DELETE_EVENT_SUCCESS',
  DELETE_EVENT_FAILURE: 'CALENDAR/DELETE_EVENT_FAILURE',
  DELETE_EVENT_PARTNER_REQUEST: 'CALENDAR/DELETE_EVENT_PARTNER_REQUEST',
  DELETE_EVENT_PARTNER_SUCCESS: 'CALENDAR/DELETE_EVENT_PARTNER_SUCCESS',
  DELETE_EVENT_PARTNER_FAILURE: 'CALENDAR/DELETE_EVENT_PARTNER_FAILURE',
  LIST_PARTICIPANT_EVENTS_REQUEST: 'CALENDAR/LIST_PARTICIPANT_EVENTS_REQUEST',
  LIST_PARTICIPANT_EVENTS_SUCCESS: 'CALENDAR/LIST_PARTICIPANT_EVENTS_SUCCESS',
  LIST_PARTICIPANT_EVENTS_FAILURE: 'CALENDAR/LIST_PARTICIPANT_EVENTS_FAILURE',
  RESPOND_PARTICIPANT_EVENT_REQUEST:
    'CALENDAR/RESPOND_PARTICIPANT_EVENT_REQUEST',
  RESPOND_PARTICIPANT_EVENT_SUCCESS:
    'CALENDAR/RESPOND_PARTICIPANT_EVENT_SUCCESS',
  RESPOND_PARTICIPANT_EVENT_FAILURE:
    'CALENDAR/RESPOND_PARTICIPANT_EVENT_FAILURE',
  CREATE_EVENT_EXCEPTION_REQUEST: 'CALENDAR/CREATE_EVENT_EXCEPTION_REQUEST',
  CREATE_EVENT_EXCEPTION_SUCCESS: 'CALENDAR/CREATE_EVENT_EXCEPTION_SUCCESS',
  CREATE_EVENT_EXCEPTION_FAILURE: 'CALENDAR/CREATE_EVENT_EXCEPTION_FAILURE',
  EVENT_FORM_DESTROY: 'CALENDAR/EVENT_FORM_DESTROY',
  SHOW_EVENT_FORM: 'CALENDAR/SHOW_EVENT_FORM',
  HIDE_EVENT_FORM: 'CALENDAR/HIDE_EVENT_FORM',
  SHOW_EVENT_DETAIL: 'CALENDAR/SHOW_EVENT_DETAIL',
  HIDE_EVENT_DETAIL: 'CALENDAR/HIDE_EVENT_DETAIL',
  LIST_CARE_GUIDES_EVENT_PARTICIPANTS_REQUEST:
    'CALENDAR/LIST_CARE_GUIDES_EVENT_PARTICIPANTS_REQUEST',
  LIST_CARE_GUIDES_EVENT_PARTICIPANTS_SUCCESS:
    'CALENDAR/LIST_CARE_GUIDES_EVENT_PARTICIPANTS_SUCCESS',
  LIST_CARE_GUIDES_EVENT_PARTICIPANTS_FAILURE:
    'CALENDAR/LIST_CARE_GUIDES_EVENT_PARTICIPANTS_FAILURE',
  CREATE_PARTNERSHIP_EVENT_REQUEST: 'CALENDAR/CREATE_PARTNERSHIP_EVENT_REQUEST',
  CREATE_PARTNERSHIP_EVENT_SUCCESS: 'CALENDAR/CREATE_PARTNERSHIP_EVENT_SUCCESS',
  CREATE_PARTNERSHIP_EVENT_FAILURE: 'CALENDAR/CREATE_PARTNERSHIP_EVENT_FAILURE',
};

export function calendarListEventsRequest(
  careGuideId,
  start,
  end,
  page,
  limit,
  order,
  filter
) {
  Logger.log(
    'debug',
    `[state.calendar.actions] calendarListEventsRequest(${careGuideId}, ${start}, ${end}, ${page}, ${limit}, ${order}, %j)`,
    filter
  );
  return {
    type: TYPES.LIST_EVENTS_REQUEST,
    careGuideId: careGuideId,
    start: start,
    end: end,
    page: page,
    limit: limit,
    order: order,
    filter: filter,
  };
}

export function calendarListEventsSuccess(careGuideId, start, end, data) {
  Logger.log(
    'debug',
    `[state.calendar.actions] calendarListEventsSuccess(${careGuideId}, ${start}, ${end}, %j)`,
    data
  );
  return {
    type: TYPES.LIST_EVENTS_SUCCESS,
    careGuideId: careGuideId,
    start: start,
    end: end,
    page: data.page,
    limit: data.limit,
    order: data.order,
    result: data.result,
    total: data.total,
    receivedAt: Date.now(),
  };
}

export function calendarListEventsFailure(careGuideId, start, end, error) {
  Logger.log(
    'debug',
    `[state.calendar.actions] calendarListEventsFailure(${careGuideId}, ${start}, ${end}, %j)`,
    error
  );
  return {
    type: TYPES.LIST_EVENTS_FAILURE,
    careGuideId: careGuideId,
    start: start,
    end: end,
    error: error,
  };
}

export function calendarEventCreateRequest(careGuideId, data) {
  Logger.log(
    'debug',
    `[state.calendar.actions] calendarEventCreateRequest(${careGuideId}, %j)`,
    data
  );
  return {
    type: TYPES.CREATE_EVENT_REQUEST,
    careGuideId: careGuideId,
  };
}

export function calendarEventCreateSuccess(careGuideId, data) {
  Logger.log(
    'debug',
    `[state.calendar.actions] calendarEventCreateSuccess(${careGuideId}, %j)`,
    data
  );
  return {
    type: TYPES.CREATE_EVENT_SUCCESS,
    careGuideId: careGuideId,
    id: data.id,
    receivedAt: Date.now(),
  };
}

export function calendarEventCreateFailure(careGuideId, error) {
  Logger.log(
    'debug',
    `[state.calendar.actions] calendarEventCreateFailure(${careGuideId}, %j)`,
    error
  );
  return {
    type: TYPES.CREATE_EVENT_FAILURE,
    careGuideId: careGuideId,
    error: error,
  };
}

export function calendarEventPartnerCreateRequest(
  eventId,
  partnershipId,
  data
) {
  Logger.log(
    'debug',
    `[state.calendar.actions] calendarEventPartnerCreateRequest(${eventId}, ${partnershipId}, %j)`,
    data
  );
  return {
    type: TYPES.CREATE_EVENT_PARTNER_REQUEST,
    eventId: eventId,
    partnershipId: partnershipId,
  };
}

export function calendarEventPartnerCreateSuccess(
  eventId,
  partnershipId,
  data
) {
  Logger.log(
    'debug',
    `[state.calendar.actions] calendarEventPartnerCreateSuccess(${eventId}, ${partnershipId}, %j)`,
    data
  );
  return {
    type: TYPES.CREATE_EVENT_PARTNER_SUCCESS,
    eventId: eventId,
    partnershipId: partnershipId,
    id: data.id,
    receivedAt: Date.now(),
  };
}

export function calendarEventPartnerCreateFailure(
  eventId,
  partnershipId,
  error
) {
  Logger.log(
    'debug',
    `[state.calendar.actions] calendarEventPartnerCreateFailure(${eventId}, ${partnershipId}, %j)`,
    error
  );
  return {
    type: TYPES.CREATE_EVENT_PARTNER_FAILURE,
    eventId: eventId,
    partnershipId: partnershipId,
    error: error,
  };
}

export function calendarEventUpdateRequest(careGuideId, id, data) {
  Logger.log(
    'debug',
    `[state.calendar.actions] calendarEventUpdateRequest(${careGuideId}, ${id}, %j)`,
    data
  );
  return {
    type: TYPES.UPDATE_EVENT_REQUEST,
    careGuideId: careGuideId,
    id: id,
  };
}

export function calendarEventUpdateSuccess(careGuideId, data) {
  Logger.log(
    'debug',
    `[state.calendar.actions] calendarEventUpdateSuccess(${careGuideId}, %j)`,
    data
  );
  return {
    type: TYPES.UPDATE_EVENT_SUCCESS,
    careGuideId: careGuideId,
    id: data.id,
    receivedAt: Date.now(),
  };
}

export function calendarEventUpdateFailure(careGuideId, error) {
  Logger.log(
    'debug',
    `[state.calendar.actions] calendarEventUpdateFailure(${careGuideId}, %j)`,
    error
  );
  return {
    type: TYPES.UPDATE_EVENT_FAILURE,
    careGuideId: careGuideId,
    error: error,
  };
}

export function calendarEventPartnerUpdateRequest(partnershipId, id, data) {
  Logger.log(
    'debug',
    `[state.calendar.actions] calendarEventUpdateRequest(${partnershipId}, ${id}, %j)`,
    data
  );
  return {
    type: TYPES.UPDATE_EVENT_PARTNER_REQUEST,
    partnershipId: partnershipId,
    id: id,
  };
}

export function calendarEventPartnerUpdateSuccess(partnershipId, data) {
  Logger.log(
    'debug',
    `[state.calendar.actions] calendarEventUpdateSuccess(${partnershipId}, %j)`,
    data
  );
  return {
    type: TYPES.UPDATE_EVENT_PARTNER_SUCCESS,
    partnershipId: partnershipId,
    id: data.id,
    receivedAt: Date.now(),
  };
}

export function calendarEventPartnerUpdateFailure(partnershipId, error) {
  Logger.log(
    'debug',
    `[state.calendar.actions] calendarEventUpdateFailure(${partnershipId}, %j)`,
    error
  );
  return {
    type: TYPES.UPDATE_EVENT_PARTNER_FAILURE,
    partnershipId: partnershipId,
    error: error,
  };
}

export function calendarEventPatchRequest(careGuideId, id, data) {
  Logger.log(
    'debug',
    `[state.calendar.actions] calendarEventPatchRequest(${careGuideId}, ${id}, %j)`,
    data
  );
  return {
    type: TYPES.PATCH_EVENT_REQUEST,
    careGuideId: careGuideId,
    id: id,
  };
}

export function calendarEventPatchSuccess(careGuideId, data) {
  Logger.log(
    'debug',
    `[state.calendar.actions] calendarEventPatchSuccess(${careGuideId}, %j)`,
    data
  );
  return {
    type: TYPES.PATCH_EVENT_SUCCESS,
    careGuideId: careGuideId,
    id: data.id,
    receivedAt: Date.now(),
  };
}

export function calendarEventPatchFailure(careGuideId, error) {
  Logger.log(
    'debug',
    `[state.calendar.actions] calendarEventPatchFailure(${careGuideId}, %j)`,
    error
  );
  return {
    type: TYPES.PATCH_EVENT_FAILURE,
    careGuideId: careGuideId,
    error: error,
  };
}

export function calendarEventPartnerPatchRequest(eventId, partnershipId, data) {
  Logger.log(
    'debug',
    `[state.calendar.actions] calendarEventPartnerPatchRequest(${eventId}, ${partnershipId}, %j)`,
    data
  );
  return {
    type: TYPES.PATCH_EVENT_PARTNER_REQUEST,
    eventId: eventId,
    partnershipId: partnershipId,
  };
}

export function calendarEventPartnerPatchSuccess(partnershipId, data) {
  Logger.log(
    'debug',
    `[state.calendar.actions] calendarEventPartnerPatchSuccess(${partnershipId}, %j)`,
    data
  );
  return {
    type: TYPES.PATCH_EVENT_PARTNER_SUCCESS,
    partnershipId: partnershipId,
    id: data.id,
    receivedAt: Date.now(),
  };
}

export function calendarEventPartnerPatchFailure(partnershipId, error) {
  Logger.log(
    'debug',
    `[state.calendar.actions] calendarEventPartnerPatchFailure(${partnershipId}, %j)`,
    error
  );
  return {
    type: TYPES.PATCH_EVENT_PARTNER_FAILURE,
    partnershipId: partnershipId,
    error: error,
  };
}

export function calendarEventDeleteRequest(careGuideId, id) {
  Logger.log(
    'debug',
    `[state.calendar.actions] calendarEventDeleteRequest(${careGuideId}, ${id})`
  );
  return {
    type: TYPES.DELETE_EVENT_REQUEST,
    careGuideId: careGuideId,
    id: id,
  };
}

export function calendarEventDeleteSuccess(careGuideId, id) {
  Logger.log(
    'debug',
    `[state.calendar.actions] calendarEventDeleteSuccess(${careGuideId}, ${id})`
  );
  return {
    type: TYPES.DELETE_EVENT_SUCCESS,
    careGuideId: careGuideId,
    id: id,
  };
}

export function calendarEventDeleteFailure(careGuideId, error) {
  Logger.log(
    'debug',
    `[state.calendar.actions] calendarEventDeleteFailure(${careGuideId}, %j)`,
    error
  );
  return {
    type: TYPES.DELETE_EVENT_FAILURE,
    careGuideId: careGuideId,
    error: error,
  };
}

export function calendarEventPartnerDeleteRequest(partnershipId, eventId) {
  Logger.log(
    'debug',
    `[state.calendar.actions] calendarEventPartnerDeleteRequest(${eventId}, ${partnershipId})`
  );
  return {
    type: TYPES.DELETE_EVENT_PARTNER_REQUEST,
    eventId: eventId,
    partnershipId: partnershipId,
  };
}

export function calendarEventPartnerDeleteSuccess(partnershipId, eventId) {
  Logger.log(
    'debug',
    `[state.calendar.actions] calendarEventPartnerDeleteSuccess(${eventId}, ${partnershipId})`
  );
  return {
    type: TYPES.DELETE_EVENT_PARTNER_SUCCESS,
    eventId: eventId,
    partnershipId: partnershipId,
  };
}

export function calendarEventPartnerDeleteFailure(partnershipId, error) {
  Logger.log(
    'debug',
    `[state.calendar.actions] calendarEventPartnerDeleteFailure(%j)`,
    error
  );
  return {
    type: TYPES.DELETE_EVENT_PARTNER_FAILURE,
    error: error,
    partnershipId: partnershipId,
  };
}

export function calendarListParticipantEventsRequest(
  careGuideId,
  start,
  end,
  page,
  limit,
  order,
  filter
) {
  Logger.log(
    'debug',
    `[state.calendar.actions] calendarListParticipantEventsRequest(${careGuideId}, ${start}, ${end}, ${page}, ${limit}, ${order}, %j)`,
    filter
  );
  return {
    type: TYPES.LIST_PARTICIPANT_EVENTS_REQUEST,
    careGuideId: careGuideId,
    start: start,
    end: end,
    page: page,
    limit: limit,
    order: order,
    filter: filter,
  };
}

export function calendarListParticipantEventsSuccess(
  careGuideId,
  start,
  end,
  data
) {
  Logger.log(
    'debug',
    `[state.calendar.actions] calendarListParticipantEventsSuccess(${careGuideId}, ${start}, ${end}, %j)`,
    data
  );
  return {
    type: TYPES.LIST_PARTICIPANT_EVENTS_SUCCESS,
    careGuideId: careGuideId,
    start: start,
    end: end,
    page: data.page,
    limit: data.limit,
    order: data.order,
    result: data.result,
    total: data.total,
    receivedAt: Date.now(),
  };
}

export function calendarListParticipantEventsFailure(
  careGuideId,
  start,
  end,
  error
) {
  Logger.log(
    'debug',
    `[state.calendar.actions] calendarListParticipantEventsFailure(${careGuideId}, ${start}, ${end}, %j)`,
    error
  );
  return {
    type: TYPES.LIST_PARTICIPANT_EVENTS_FAILURE,
    careGuideId: careGuideId,
    start: start,
    end: end,
    error: error,
  };
}

export function calendarParticipantEventRespondRequest(id, data) {
  Logger.log(
    'debug',
    `[state.calendar.actions] calendarParticipantEventRespondRequest(${id}, %j)`,
    data
  );
  return {
    type: TYPES.RESPOND_PARTICIPANT_EVENT_REQUEST,
    id: id,
  };
}

export function calendarParticipantEventRespondSuccess(data) {
  Logger.log(
    'debug',
    `[state.calendar.actions] calendarParticipantEventRespondSuccess(%j)`,
    data
  );
  return {
    type: TYPES.RESPOND_PARTICIPANT_EVENT_SUCCESS,
    id: data.id,
    receivedAt: Date.now(),
  };
}

export function calendarParticipantEventRespondFailure(error) {
  Logger.log(
    'debug',
    `[state.calendar.actions] calendarParticipantEventRespondFailure(%j)`,
    error
  );
  return {
    type: TYPES.RESPOND_PARTICIPANT_EVENT_FAILURE,
    error: error,
  };
}

export function calendarEventExceptionCreateRequest(
  careGuideId,
  eventId,
  data
) {
  Logger.log(
    'debug',
    `[state.calendar.actions] calendarEventExceptionCreateRequest(${careGuideId}, ${eventId}, %j)`,
    data
  );
  return {
    type: TYPES.CREATE_EVENT_REQUEST,
    careGuideId: careGuideId,
    eventId: eventId,
  };
}

export function calendarEventExceptionCreateSuccess(
  careGuideId,
  eventId,
  data
) {
  Logger.log(
    'debug',
    `[state.calendar.actions] calendarEventExceptionCreateSuccess(${careGuideId}, ${eventId}, %j)`,
    data
  );
  return {
    type: TYPES.CREATE_EVENT_SUCCESS,
    careGuideId: careGuideId,
    eventId: eventId,
    id: data.id,
    receivedAt: Date.now(),
  };
}

export function calendarEventExceptionCreateFailure(
  careGuideId,
  eventId,
  error
) {
  Logger.log(
    'debug',
    `[state.calendar.actions] calendarEventExceptionCreateFailure(${careGuideId}, ${eventId}, %j)`,
    error
  );
  return {
    type: TYPES.CREATE_EVENT_FAILURE,
    careGuideId: careGuideId,
    eventId: eventId,
    error: error,
  };
}

export function calendarEventFormDestroy(formState = null) {
  Logger.log(
    'debug',
    `[state.calendar.actions] calendarEventFormDestroy(%j)`,
    formState
  );
  return {
    type: TYPES.EVENT_FORM_DESTROY,
    form: formState,
  };
}

export function calendarShowEventForm(eventId = null, startDate = null) {
  Logger.log('debug', `[state.calendar.actions] calendarShowEventForm()`);
  return {
    type: TYPES.SHOW_EVENT_FORM,
    eventId: eventId,
    startDate: startDate,
  };
}

export function calendarHideEventForm() {
  Logger.log('debug', `[state.calendar.actions] calendarHideEventForm()`);
  return {
    type: TYPES.HIDE_EVENT_FORM,
  };
}

export function calendarShowEventDetail(eventId = null, view = true) {
  Logger.log('debug', `[state.calendar.actions] calendarShowEventDetail()`);
  return {
    type: TYPES.SHOW_EVENT_DETAIL,
    eventId: eventId,
    view: view,
  };
}

export function calendarHideEventDetail() {
  Logger.log('debug', `[state.calendar.actions] calendarHideEventDetail()`);
  return {
    type: TYPES.HIDE_EVENT_DETAIL,
  };
}

export function careGuidesMembersPartnershipRequest(
  partnershipId,
  page,
  limit,
  order,
  filter,
  data,
) {
  Logger.log(
    'debug',
    `[state.careGuideHelpers.actions] careGuidesMembersPartnershipRequest(${partnershipId},  ${page}, ${limit}, ${order}, %j)`,
    data
  );
  return {
    type: TYPES.LIST_CARE_GUIDES_EVENT_PARTICIPANTS_REQUEST,
    partnershipId: partnershipId,
    page: page,
    limit: limit,
    order: order,
    filter: filter,
    data: data,
  };
}

export function careGuidesMembersPartnershipSuccess(partnershipId, data) {
  Logger.log(
    'debug',
    `[state.careGuideHelpers.actions] careGuidesMembersPartnershipSuccess(${partnershipId}, %j)`,
    data
  );
  return {
    type: TYPES.LIST_CARE_GUIDES_EVENT_PARTICIPANTS_SUCCESS,
    partnershipId: partnershipId,
    page: data.page,
    limit: data.limit,
    order: data.order,
    result: data.result,
    total: data.total,
    receivedAt: Date.now(),
  };
}

export function careGuidesMembersPartnershipFailure(partnershipId, error) {
  Logger.log(
    'debug',
    `[state.careGuideHelpers.actions] careGuidesMembersPartnershipFailure(${partnershipId}, %j)`,
    error
  );
  return {
    type: TYPES.LIST_CARE_GUIDES_EVENT_PARTICIPANTS_FAILURE,
    partnershipId: partnershipId,
    error: error,
  };
}

export function calendarEventPartnershipCreateRequest(partnershipId, data) {
  Logger.log(
    'debug',
    `[state.calendar.actions] calendarEventPartnershipCreateRequest(${partnershipId}, %j)`,
    data
  );
  return {
    type: TYPES.CREATE_PARTNERSHIP_EVENT_REQUEST,
    partnershipId: partnershipId,
  };
}

export function calendarEventPartnershipCreateSuccess(partnershipId, data) {
  Logger.log(
    'debug',
    `[state.calendar.actions] calendarEventPartnershipCreateSuccess(${partnershipId}, %j)`,
    data
  );
  return {
    type: TYPES.CREATE_PARTNERSHIP_EVENT_SUCCESS,
    partnershipId: partnershipId,
    id: data.id,
    receivedAt: Date.now(),
  };
}

export function calendarEventPartnershipCreateFailure(partnershipId, error) {
  Logger.log(
    'debug',
    `[state.calendar.actions] calendarEventPartnershipCreateFailure(${partnershipId}, %j)`,
    error
  );
  return {
    type: TYPES.CREATE_PARTNERSHIP_EVENT_FAILURE,
    partnershipId: partnershipId,
    error: error,
  };
}

// API THUNK ACTION CREATORS

export function loadCalendarEvents(
  careGuideId,
  start,
  end,
  page = 1,
  limit = 10,
  order = null,
  filter = null,
  cb = function () {}
) {
  Logger.log(
    'debug',
    `[state.calendar.actions] loadCalendarEvents(${careGuideId}, ${start}, ${end}, ${page}, ${limit}, ${order}, %j, ###)`,
    filter
  );

  return async function (dispatch) {
    dispatch(
      calendarListEventsRequest(
        careGuideId,
        start,
        end,
        page,
        limit,
        order,
        filter
      )
    );

    // call API
    const response = await api.getCareGuideCalendarEvents(
      careGuideId,
      page,
      limit,
      order,
      { start: start, end: end, ...filter }
    );
    let success = false;
    let total = 0;

    // get calendar events list success
    if (200 === response.get('status')) {
      Logger.log(
        'info',
        `Get API calendar events list success. Care Guide ID: ${careGuideId}, Start: ${start}, End: ${end}, Page: ${page}, Limit: ${limit}, Order: ${order}.`
      );

      const normalizedEntities = normalize(
        response.getIn(['data', 'calendar_events']),
        [schema.calendarEvent]
      );
      const data = {
        page: response.getIn(['data', 'page']),
        limit: response.getIn(['data', 'limit']),
        order: order,
        total: response.getIn(['data', 'total']),
        result: normalizedEntities.result,
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(calendarListEventsSuccess(careGuideId, start, end, data));
      success = true;
      total = response.getIn(['data', 'total']);
    } else if (1 === page && 204 === response.get('status')) {
      Logger.log(
        'info',
        `Get API calendar events list success [empty]. Care Guide ID: ${careGuideId}, Start: ${start}, End: ${end}, Page: ${page}, Limit: ${limit}, Order: ${order}.`
      );
      const data = {
        page: page,
        limit: limit,
        order: order,
        total: 0,
        result: [],
      };
      dispatch(calendarListEventsSuccess(careGuideId, start, end, data));
      success = true;

      // get calendar events list failure
    } else {
      Logger.log(
        'info',
        `Get API calendar events list failure. Care Guide ID: ${careGuideId}, Start: ${start}, End: ${end}, Page: ${page}, Limit: ${limit}, Order: ${order}.`
      );
      dispatch(
        calendarListEventsFailure(
          careGuideId,
          start,
          end,
          response.getIn(['data', 'error'])
        )
      );
    }

    // callback function
    cb(success, total);
  };
}

export function createCalendarEventPartner(
  eventId,
  partnershipId,
  data,
  cb = function () {}
) {
  Logger.log(
    'debug',
    `[state.calendar.actions] createCalendarEventPartner(${eventId}, ${partnershipId}, %j, ###)`,
    data
  );

  return async function (dispatch) {
    dispatch(calendarEventPartnerCreateRequest(eventId, partnershipId, data));

    // call API
    const response = await api.postCareGuideCalendarEventsPartner(
      eventId,
      partnershipId,
      data
    );
    let success = false;

    // post calendar event success
    if (201 === response.get('status')) {
      Logger.log(
        'info',
        `POST API calendar event success. Event ID: ${eventId} Partnership ID: ${partnershipId}.`
      );

      const normalizedEntities = normalize(
        [response.getIn(['data', 'calendar_event'])],
        [schema.calendarEvent]
      );
      const data = {
        id: response.getIn(['data', 'calendar_event', 'id']),
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(calendarEventPartnerCreateSuccess(eventId, partnershipId, data));
      success = true;

      // post calendar event failure
    } else {
      Logger.log(
        'info',
        `POST API calendar event failure. Event ID: ${eventId} Partnership ID: ${partnershipId}.`
      );
      dispatch(
        calendarEventPartnerCreateFailure(
          eventId,
          partnershipId,
          response.getIn(['data', 'error'])
        )
      );
    }

    // callback function
    cb(success);
  };
}

export function createCalendarEvent(careGuideId, data, cb = function () {}) {
  Logger.log(
    'debug',
    `[state.calendar.actions] createCalendarEvent(${careGuideId}, %j, ###)`,
    data
  );

  return async function (dispatch) {
    dispatch(calendarEventCreateRequest(careGuideId, data));

    // call API
    const response = await api.postCareGuideCalendarEvents(careGuideId, data);
    let success = false;

    // post calendar event success
    if (201 === response.get('status')) {
      Logger.log(
        'info',
        `POST API calendar event success. Care Guide ID: ${careGuideId}.`
      );

      const normalizedEntities = normalize(
        [response.getIn(['data', 'calendar_event'])],
        [schema.calendarEvent]
      );
      const data = {
        id: response.getIn(['data', 'calendar_event', 'id']),
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(calendarEventCreateSuccess(careGuideId, data));
      success = true;

      // post calendar event failure
    } else {
      Logger.log(
        'info',
        `POST API calendar event failure. Care Guide ID: ${careGuideId}.`
      );
      dispatch(
        calendarEventCreateFailure(
          careGuideId,
          response.getIn(['data', 'error'])
        )
      );
    }

    // callback function
    cb(success);
  };
}

export function updateCalendarEvent(
  careGuideId,
  id,
  data,
  cb = function () {}
) {
  Logger.log(
    'debug',
    `[state.calendar.actions] updateCalendarEvent(${careGuideId}, ${id}, %j, ###)`,
    data
  );

  return async function (dispatch) {
    dispatch(calendarEventUpdateRequest(careGuideId, id, data));

    // call API
    const response = await api.putCareGuideCalendarEvent(careGuideId, id, data);
    let success = false;

    // PUT calendar event success
    if (200 === response.get('status')) {
      Logger.log(
        'info',
        `PUT API calendar event success. Care Guide ID: ${careGuideId}, User: ${id}.`
      );

      const normalizedEntities = normalize(
        [response.getIn(['data', 'calendar_event'])],
        [schema.calendarEvent]
      );
      const data = {
        id: response.getIn(['data', 'calendar_event', 'id']),
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(calendarEventUpdateSuccess(careGuideId, data));
      success = true;

      // PUT calendar event failure
    } else {
      Logger.log(
        'info',
        `PUT API calendar event failure. Care Guide ID: ${careGuideId}, ID: ${id}.`
      );
      dispatch(
        calendarEventUpdateFailure(
          careGuideId,
          response.getIn(['data', 'error'])
        )
      );
    }

    // callback function
    cb(success);
  };
}

export function updateCalendarEventPartner(
  partnershipId,
  id,
  data,
  cb = function () {}
) {
  Logger.log(
    'debug',
    `[state.calendar.actions] updateCalendarEventPartner(${partnershipId}, ${id}, %j, ###)`,
    data
  );

  return async function (dispatch) {
    dispatch(calendarEventPartnerUpdateRequest(partnershipId, id, data));

    // call API
    const response = await api.putCareGuideCalendarEventPartner(
      partnershipId,
      id,
      data
    );
    let success = false;

    // PUT calendar event success
    if (200 === response.get('status')) {
      Logger.log(
        'info',
        `PUT API calendar event success. Care Guide ID: ${partnershipId}, Event: ${id}.`
      );

      const normalizedEntities = normalize(
        [response.getIn(['data', 'calendar_event'])],
        [schema.calendarEvent]
      );
      const data = {
        id: response.getIn(['data', 'calendar_event', 'id']),
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(calendarEventUpdateSuccess(partnershipId, data));
      success = true;

      // PUT calendar event failure
    } else {
      Logger.log(
        'info',
        `PUT API calendar event failure. Care Guide ID: ${partnershipId}, ID: ${id}.`
      );
      dispatch(
        calendarEventPartnerUpdateFailure(
          partnershipId,
          response.getIn(['data', 'error'])
        )
      );
    }

    // callback function
    cb(success);
  };
}

export function patchCalendarEvent(careGuideId, id, data, cb = function () {}) {
  Logger.log(
    'debug',
    `[state.calendar.actions] patchCalendarEvent(${careGuideId}, ${id}, %j, ###)`,
    data
  );

  return async function (dispatch) {
    dispatch(calendarEventPatchRequest(careGuideId, id, data));

    // call API
    const response = await api.patchCareGuideCalendarEvent(
      careGuideId,
      id,
      data
    );
    let success = false;

    // PATCH calendar event success
    if (200 === response.get('status')) {
      Logger.log(
        'info',
        `PATCH API calendar event success. Care Guide ID: ${careGuideId}, User: ${id}.`
      );

      const normalizedEntities = normalize(
        [response.getIn(['data', 'calendar_event'])],
        [schema.calendarEvent]
      );
      const data = {
        id: response.getIn(['data', 'calendar_event', 'id']),
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(calendarEventPatchSuccess(careGuideId, data));
      success = true;

      // PATCH calendar event failure
    } else {
      Logger.log(
        'info',
        `PATCH API calendar event failure. Care Guide ID: ${careGuideId}, ID: ${id}.`
      );
      dispatch(
        calendarEventPatchFailure(
          careGuideId,
          response.getIn(['data', 'error'])
        )
      );
    }

    // callback function
    cb(success);
  };
}

export function patchCalendarEventPartner(
  eventId,
  partnershipId,
  data,
  cb = function () {}
) {
  Logger.log(
    'debug',
    `[state.calendar.actions] patchCalendarEventPartner(${eventId}, ${partnershipId}, %j, ###)`,
    data
  );

  return async function (dispatch) {
    dispatch(calendarEventPartnerPatchRequest(eventId, partnershipId, data));

    // call API
    const response = await api.patchCareGuideCalendarEventPartner(
      eventId,
      partnershipId,
      data
    );
    let success = false;

    // PATCH calendar event success
    if (200 === response.get('status')) {
      Logger.log(
        'info',
        `PATCH API calendar event success. Event ID: ${eventId}, Partnership ID: ${partnershipId}.`
      );

      const normalizedEntities = normalize(
        [response.getIn(['data', 'calendar_event'])],
        [schema.calendarEvent]
      );
      const data = {
        id: response.getIn(['data', 'calendar_event', 'id']),
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(calendarEventPartnerPatchSuccess(partnershipId, data));
      success = true;

      // PATCH calendar event failure
    } else {
      Logger.log(
        'info',
        `PATCH API calendar event failure. Event ID: ${eventId}, Partnership ID: ${partnershipId}.`
      );
      dispatch(
        calendarEventPartnerPatchFailure(
          partnershipId,
          response.getIn(['data', 'error'])
        )
      );
    }

    // callback function
    cb(success);
  };
}

export function deleteCalendarEvent(careGuideId, id, cb = function () {}) {
  Logger.log(
    'debug',
    `[state.calendar.actions] deleteCalendarEvent(${id}, ###)`
  );

  return async function (dispatch) {
    dispatch(calendarEventDeleteRequest(careGuideId, id));

    // call API
    const response = await api.deleteCareGuideCalendarEvent(careGuideId, id);
    let success = false;

    // delete calendar event success
    if (204 === response.get('status')) {
      Logger.log(
        'info',
        `DELETE API calendar event success. Care Guide ID: ${careGuideId}, ID: ${id}.`
      );

      dispatch(removeEntity({ entityType: 'calendarEvents', id: id }));
      dispatch(calendarEventDeleteSuccess(careGuideId, id));
      success = true;

      // get calendar event failure
    } else {
      Logger.log(
        'info',
        `DELETE API calendar event failure. Care Guide ID: ${careGuideId}, ID: ${id}.`
      );
      dispatch(
        calendarEventDeleteFailure(
          careGuideId,
          response.getIn(['data', 'error'])
        )
      );
    }

    // callback function
    cb(success);
  };
}

export function deleteCalendarEventPartner(
  partnershipId,
  id,
  cb = function () {}
) {
  Logger.log(
    'debug',
    `[state.calendar.actions] deleteCalendarEventPartner(${partnershipId}, ${id}, ###)`
  );

  return async function (dispatch) {
    dispatch(calendarEventPartnerDeleteRequest(partnershipId, id));

    // call API
    const response = await api.deleteCalendarPartnerEvent(partnershipId, id);
    let success = false;

    // delete calendar event success
    if (204 === response.get('status')) {
      Logger.log(
        'info',
        `DELETE API calendar event success. ID: ${id}. Partnership: ${partnershipId}`
      );

      dispatch(removeEntity({ entityType: 'calendarEvents', id: id }));
      dispatch(calendarEventPartnerDeleteSuccess(id));
      success = true;

      // get calendar event failure
    } else {
      Logger.log('info', `DELETE API calendar event failure. ID: ${id}.`);
      dispatch(
        calendarEventPartnerDeleteFailure(
          partnershipId,
          response.getIn(['data', 'error'])
        )
      );
    }

    // callback function
    cb(success);
  };
}
export function loadCalendarParticipantEvents(
  careGuideId,
  start,
  end,
  page = 1,
  limit = 10,
  order = null,
  filter = null,
  cb = function () {}
) {
  Logger.log(
    'debug',
    `[state.calendar.actions] loadCalendarParticipantEvents(${careGuideId}, ${start}, ${end}, ${page}, ${limit}, ${order}, %j, ###)`,
    filter
  );

  return async function (dispatch) {
    dispatch(
      calendarListParticipantEventsRequest(
        careGuideId,
        start,
        end,
        page,
        limit,
        order,
        filter
      )
    );

    // call API
    const response = await api.getCareGuideCalendarParticipantEvents(
      careGuideId,
      page,
      limit,
      order,
      { start: start, end: end, ...filter }
    );
    let success = false;
    let total = 0;

    // get calendar participant events list success
    if (200 === response.get('status')) {
      Logger.log(
        'info',
        `Get API calendar participant events list success. Care Guide ID: ${careGuideId}, Start: ${start}, End: ${end}, Page: ${page}, Limit: ${limit}, Order: ${order}.`
      );

      const normalizedEntities = normalize(
        response.getIn(['data', 'calendar_events']),
        [schema.calendarEvent]
      );
      const data = {
        page: response.getIn(['data', 'page']),
        limit: response.getIn(['data', 'limit']),
        order: order,
        total: response.getIn(['data', 'total']),
        result: normalizedEntities.result,
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(
        calendarListParticipantEventsSuccess(careGuideId, start, end, data)
      );
      success = true;
      total = response.getIn(['data', 'total']);
    } else if (1 === page && 204 === response.get('status')) {
      Logger.log(
        'info',
        `Get API calendar participant events list success [empty]. Care Guide ID: ${careGuideId}, Start: ${start}, End: ${end}, Page: ${page}, Limit: ${limit}, Order: ${order}.`
      );
      const data = {
        page: page,
        limit: limit,
        order: order,
        total: 0,
        result: [],
      };
      dispatch(
        calendarListParticipantEventsSuccess(careGuideId, start, end, data)
      );
      success = true;

      // get calendar participant events list failure
    } else {
      Logger.log(
        'info',
        `Get API calendar participant events list failure. Care Guide ID: ${careGuideId}, Start: ${start}, End: ${end}, Page: ${page}, Limit: ${limit}, Order: ${order}.`
      );
      dispatch(
        calendarListParticipantEventsFailure(
          careGuideId,
          start,
          end,
          response.getIn(['data', 'error'])
        )
      );
    }

    // callback function
    cb(success, total);
  };
}

export function respondCalendarParticipantEvent(id, data, cb = function () {}) {
  Logger.log(
    'debug',
    `[state.calendar.actions] respondCalendarParticipantEvent(${id}, %j, ###)`,
    data
  );

  return async function (dispatch) {
    dispatch(calendarParticipantEventRespondRequest(id, data));

    // call API
    const response = await api.putCalendarEventParticipantResponse(id, data);
    let success = false;

    // respond calendar event invitation success
    if (200 === response.get('status')) {
      Logger.log(
        'info',
        `respond API calendar event invitation success. ID: ${id}.`
      );

      const normalizedEntities = normalize(
        [response.getIn(['data', 'calendar_event'])],
        [schema.calendarEvent]
      );
      const data = {
        id: response.getIn(['data', 'calendar_event', 'id']),
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(calendarParticipantEventRespondSuccess(data));
      success = true;

      // respond calendar event invitation failure
    } else {
      Logger.log(
        'info',
        `respond API calendar event invitation failure. ID: ${id}.`
      );
      dispatch(
        calendarParticipantEventRespondFailure(
          response.getIn(['data', 'error'])
        )
      );
    }

    // callback function
    cb(success);
  };
}

export function createCalendarEventException(
  careGuideId,
  eventId,
  data,
  cb = function () {}
) {
  Logger.log(
    'debug',
    `[state.calendar.actions] createCalendarEventException(${careGuideId}, ${eventId}, %j, ###)`,
    data
  );

  return async function (dispatch) {
    dispatch(calendarEventExceptionCreateRequest(careGuideId, eventId, data));

    // call API
    const response = await api.postCareGuideCalendarEventExceptions(
      careGuideId,
      eventId,
      data
    );
    let success = false;

    // post calendar event exception success
    if (201 === response.get('status')) {
      Logger.log(
        'info',
        `POST API calendar event exception success. Care Guide ID: ${careGuideId}, Event ID: ${eventId}.`
      );

      const normalizedEntities = normalize(
        [response.getIn(['data', 'calendar_event_exception'])],
        [schema.calendarEventException]
      );
      const data = {
        id: response.getIn(['data', 'calendar_event_exception', 'id']),
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(calendarEventExceptionCreateSuccess(careGuideId, eventId, data));
      success = true;

      // post calendar event exception failure
    } else {
      Logger.log(
        'info',
        `POST API calendar event exception failure. Care Guide ID: ${careGuideId}, Event ID: ${eventId}.`
      );
      dispatch(
        calendarEventExceptionCreateFailure(
          careGuideId,
          eventId,
          response.getIn(['data', 'error'])
        )
      );
    }

    // callback function
    cb(success);
  };
}

export function loadCareGuidesMembersPartnership(
  partnershipId = null,
  page = 1,
  limit = 10,
  order = null,
  filter = null,
  data = {},
  cb = function () {}
) {
  Logger.log(
    'debug',
    `[state.calendar.actions] loadCareguidesEventParticipants(${partnershipId}, ${page}, ${limit}, ${order}, %j,  %j,)`,
    filter,
    data
  );

  return async function (dispatch) {
    dispatch(
      careGuidesMembersPartnershipRequest(
        partnershipId,
        page,
        limit,
        order,
        filter,
        data
      )
    );

    // call API
    const response = await api.postCareguidesMembersPartnership(
      partnershipId,
      page,
      limit,
      order,
      filter,
      data
    );
    let success = false;
    let result = [];

    // get care guide members partnership list success
    if (200 === response.get('status')) {
      Logger.log(
        'info',
        `Get API care guide members partnership list success. partnership ID: ${partnershipId}, Page: ${page}, Limit: ${limit}, Order: ${order}.`
      );

      const normalizedEntities = normalize(
        response.getIn(['data', 'care_guides_members_partnership']),
        [schema.careGuidesMembersPartnership]
      );

      const data = {
        page: response.getIn(['data', 'page']),
        limit: response.getIn(['data', 'limit']),
        order: order,
        total: response.getIn(['data', 'total']),
        result: normalizedEntities.result,
      };
      result = normalizedEntities.result;
      dispatch(addEntities(normalizedEntities));
      dispatch(careGuidesMembersPartnershipSuccess(partnershipId, data));
      success = true;
    } else if (1 === page && 204 === response.get('status')) {
      Logger.log(
        'info',
        `Get API care guide members partnership list success. Partnership ID: ${partnershipId}, Page: ${page}, Limit: ${limit}, Order: ${order}.`
      );

      const data = {
        page: page,
        limit: limit,
        order: order,
        total: 0,
        result: [],
      };
      dispatch(careGuidesMembersPartnershipSuccess(partnershipId, data));
      success = true;

      // get care guide members partnership list failure
    } else {
      Logger.log(
        'info',
        `Get API care guide members partnership list failure. partnership ID: ${partnershipId}.`
      );
      dispatch(
        careGuidesMembersPartnershipFailure(
          partnershipId,
          response.getIn(['data', 'error'])
        )
      );
    }

    // callback function
    cb(success, result);
  };
}

export function createCalendarEventPartnership(
  partnershipId,
  data,
  cb = function () {}
) {
  Logger.log(
    'debug',
    `[state.calendar.actions] createCalendarEventPartnership(${partnershipId}, %j, ###)`,
    data
  );

  return async function (dispatch) {
    dispatch(calendarEventPartnershipCreateRequest(partnershipId, data));

    // call API
    const response = await api.postCareguidesPartnershipCalendarEvent(
      partnershipId,
      data
    );
    let success = false;

    // post calendar partnership event success
    if (201 === response.get('status')) {
      Logger.log(
        'info',
        `POST API calendar partnership event success. Care Guide ID: ${partnershipId}.`
      );

      const normalizedEntities = normalize(
        [response.getIn(['data', 'calendar_event'])],
        [schema.calendarEvent]
      );
      const data = {
        id: response.getIn(['data', 'calendar_event', 'id']),
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(calendarEventPartnershipCreateSuccess(partnershipId, data));
      success = true;

      // post calendar partnership event failure
    } else {
      Logger.log(
        'info',
        `POST API calendar partnership event failure. Care Guide ID: ${partnershipId}.`
      );
      dispatch(
        calendarEventPartnershipCreateFailure(
          partnershipId,
          response.getIn(['data', 'error'])
        )
      );
    }

    // callback function
    cb(success);
  };
}

Logger.log('silly', `state.calendar.actions loaded.`);
