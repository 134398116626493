
import Config from "../../../../Config";
import Logger from "../../../../lib/Logger";
import Events from "../../../../lib/EventEmitter";

import AdminScreen from "./containers/AdminScreenContainer";

const register = () => {
  Logger.log("debug", `admin.register()`);
  if (Config.getIn(["MODULE_TOGGLES", "admin", "routes"])) {
    Events.dispatch("ADD_MAIN_ROUTES", {
      AdminScreen: [
        "PrivateRoute",
        "/admin",
        false,
        AdminScreen,
      ],
    });
  }
};

export default register;

Logger.log("silly", `admin.register() loaded.`);
