import { connect } from 'react-redux';
import { denormalize } from 'normalizr';
import { schema } from '../../../../../state/schema';
import { List } from 'immutable';
import ResourceNotesList from '../components/ResourceNotesList';
import {
  loadResourceNotes,
  patchResourceNote,
  deleteResourceNote,
} from '../../../../../state/modules/resourceNotes/actions';
import storage from '../../../../../lib/Storage';

const mapStateToProps = (state, ownProps) => {
  const careGuideId = ownProps.careGuideId;

  const careGuide = careGuideId
    ? denormalize(
        state.entities.getIn(['careGuides', careGuideId]),
        schema.careGuide,
        state.entities.toJS()
      )
    : null;

  const resourceId = ownProps.resourceId;
  const resource = resourceId
    ? denormalize(
        state.entities.getIn(['resources', resourceId]),
        schema.resource,
        state.entities.toJS()
      )
    : null;

  const page = ownProps.page;
  const limit = ownProps.limit;
  const order = ownProps.order;

  let result = List();
  for (let i = 1; i <= page; i++) {
    result = result.merge(
      state.resourceNotes.getIn([
        'results',
        careGuideId,
        resourceId,
        'pages',
        order,
        limit,
        i,
      ])
    );
  }

  const profileId = state.session.get('profileId');

  return {
    list: result
      ? result
          .map((x) => {
            return {
              key: x,
              ...denormalize(
                state.entities.getIn(['resourceNotes', x]),
                schema.resourceNote,
                state.entities.toJS()
              ),
            };
          })
          .toArray()
          .filter((x) => x.key) // for some reason there's an empty result, remove
          .filter((v, i, a) => a.findIndex((t) => t.key === v.key) === i) // dedupe - sometimes merging pagination results have multiples of same
          .map((x) => {
            // flatten some nested objects to make table easier to work with
            return {
              profile_name: x.profile
                ? x.profile.first_name + ' ' + x.profile.last_name
                : '',
              ...x,
            };
          })
      : [],
    total: state.resourceNotes.getIn(
      ['results', careGuideId, resourceId, 'total'],
      0
    ),
    isLoading: state.resourceNotes.get('isLoading'),
    isSubmittingEdit: state.resourceNotes.get('isSubmittingEdit'),
    careGuide,
    partnershipId: storage.get('local', 'partnershipId'),
    partnershipName: storage.get('local', 'partnershipData')?.name,
    profileId: profileId,
    resource,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    patch: (careGuideId, resourceId, id, data, cb) => {
      dispatch(patchResourceNote(careGuideId, resourceId, id, data, cb));
    },
    delete: (careGuideId, resourceId, id, cb) => {
      dispatch(deleteResourceNote(careGuideId, resourceId, id, cb));
    },
    load: (careGuideId, resourceId, page, limit, order, filter, cb) => {
      dispatch(
        loadResourceNotes(
          careGuideId,
          resourceId,
          page,
          limit,
          order,
          filter,
          cb
        )
      );
    },
    loadAll: (
      careGuideId,
      resourceId,
      page,
      limit,
      order,
      filter,
      cb = function () {}
    ) => {
      dispatch(
        loadResourceNotes(
          careGuideId,
          resourceId,
          page,
          limit,
          order,
          filter,
          (success, total) => {
            if (success) {
              // loop over all pages to get all replies
              const pageCount = Math.ceil(total / limit);
              for (var i = pageCount - 1; i >= 1; i--) {
                dispatch(
                  loadResourceNotes(
                    careGuideId,
                    resourceId,
                    i,
                    limit,
                    order,
                    filter
                  )
                );
              }
              cb();
            }
          }
        )
      );
    },
  };
};

const ResourceNotesListContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ResourceNotesList);

export default ResourceNotesListContainer;
