import { List, Map } from 'immutable';

import { TYPES } from './actions';
import { SESSION_DESTROY } from '../../actions';
import Logger from '../../../lib/Logger';

const initialState = Map({
  isLoading: false,
  isLoadingAdmin: false,
  results: {},
  resultsAdmin: {},
  form: {},
  listIsVisible: false,
  listResourceId: null,
  listAdminIsVisible: false,
  listAdminResourceId: null,
  isCSVDownloading: false,
  isSubmittingEdit: false,
});

export default function resourceNotes(state = initialState, action) {
  Logger.log(
    'debug',
    `[state.resourceNotes.reducers] resourceNotes(%j, %j)`,
    state,
    action
  );

  switch (action.type) {
    case TYPES.LIST_REQUEST:
      return state.mergeDeep({
        isLoading: true,
      });

    case TYPES.LIST_SUCCESS:
      return state
        .mergeDeep({
          isLoading: false,
          results: {
            [action.careGuideId]: {
              [action.resourceId]: {
                total: action.total,
                pages: {
                  [action.order]: {
                    [action.limit]: {
                      [action.page]: null,
                    },
                  },
                },
              },
            },
          },
          lastUpdated: action.receivedAt,
        })
        .setIn(
          [
            'results',
            action.careGuideId,
            action.resourceId,
            'pages',
            action.order,
            action.limit,
            action.page,
          ],
          List(action.result)
        );

    case TYPES.LIST_FAILURE:
      return state.mergeDeep({
        isLoading: false,
      });

    case TYPES.LIST_REQUEST_ADMIN:
      return state.mergeDeep({
        isLoadingAdmin: true,
      });

    case TYPES.LIST_SUCCESS_ADMIN:
      return state
        .mergeDeep({
          isLoadingAdmin: false,
          resultsAdmin: {
            [action.partnershipId]: {
              [action.resourceId]: {
                total: action.total,
                pages: {
                  [action.order]: {
                    [action.limit]: {
                      [action.page]: null,
                    },
                  },
                },
              },
            },
          },
          lastUpdated: action.receivedAt,
        })
        .setIn(
          [
            'resultsAdmin',
            action.partnershipId,
            action.resourceId,
            'pages',
            action.order,
            action.limit,
            action.page,
          ],
          List(action.result)
        );

    case TYPES.LIST_FAILURE_ADMIN:
      return state.mergeDeep({
        isLoadingAdmin: false,
      });

    case TYPES.DELETE_REQUEST:
      return state.mergeDeep({
        isLoading: false,
        lastUpdated: action.receivedAt,
      });

    case TYPES.DELETE_SUCCESS:
      return state.mergeDeep({
        isLoading: false,
      });

    case TYPES.DELETE_FAILURE:
      return state
        .mergeDeep({
          form: null,
        })
        .set('form', action.form);

    case TYPES.READ_REQUEST:
      return state.mergeDeep({
        isLoading: true,
      });

    case TYPES.READ_SUCCESS:
      return state.mergeDeep({
        isLoading: false,
        lastUpdated: action.receivedAt,
      });

    case TYPES.READ_FAILURE:
      return state.mergeDeep({
        isLoading: false,
      });

    case TYPES.CREATE_REQUEST:
      return state.mergeDeep({
        form: {
          isSubmitting: true,
          success: null,
          errors: null,
        },
      });

    case TYPES.CREATE_SUCCESS:
      return state.mergeDeep({
        form: {
          isSubmitting: false,
          success: true,
          errors: null,
        },
        lastUpdated: action.receivedAt,
      });

    case TYPES.CREATE_FAILURE:
      return state
        .mergeDeep({
          form: {
            isSubmitting: false,
            success: false,
            errors: null,
          },
        })
        .setIn(['form', 'errors'], action.error);

    case TYPES.PATCH_REQUEST:
      return state.mergeDeep({
        form: {
          isSubmittingEdit: true,
          success: null,
          errors: null,
        },
      });

    case TYPES.PATCH_SUCCESS:
      return state.mergeDeep({
        form: {
          isSubmittingEdit: false,
          success: true,
          errors: null,
        },
        lastUpdated: action.receivedAt,
      });

    case TYPES.PATCH_FAILURE:
      return state
        .mergeDeep({
          form: {
            isSubmittingEdit: false,
            success: false,
            errors: null,
          },
        })
        .setIn(['form', 'errors'], action.error);

    case TYPES.FORM_DESTROY:
      return state
        .mergeDeep({
          form: {},
        })
        .set('form', action.form);

    case TYPES.SHOW_LIST:
      return state.mergeDeep({
        listIsVisible: true,
        listResourceId: action.resourceId,
      });

    case TYPES.HIDE_LIST:
      return state.mergeDeep({
        listIsVisible: false,
        listResourceId: null,
      });

    case TYPES.SHOW_LIST_ADMIN:
      return state.mergeDeep({
        listAdminIsVisible: true,
        listAdminResourceId: action.resourceId,
      });

    case TYPES.HIDE_LIST_ADMIN:
      return state.mergeDeep({
        listAdminIsVisible: false,
        listAdminResourceId: null,
      });

    case TYPES.CSV_DOWNLOAD_REQUEST:
      return state.mergeDeep({
        isCSVDownloading: true,
      });

    case TYPES.CSV_DOWNLOAD_SUCCESS:
      return state.mergeDeep({
        isCSVDownloading: false,
      });

    case TYPES.CSV_DOWNLOAD_FAILURE:
      return state.mergeDeep({
        isCSVDownloading: false,
      });

    case SESSION_DESTROY:
      return initialState;

    default:
      return state;
  }
}

Logger.log('silly', `state.resourceNotes.reducers loaded.`);
