import React from 'react';
import {withRouter} from "react-router";
import {Divider} from 'antd';

import PageHeaderPartnership from '../../elements/containers/PageHeaderPartnershipContainer';

const PartnershipWrapper = props => {
  return (
    <>
      <PageHeaderPartnership pathname={props.location.pathname} />
      <Divider className="page-header-divider" />
      {props.children}
    </>
  )
}

export default withRouter(PartnershipWrapper);
