import React, { useEffect, useState } from 'react';
import { Translation } from 'react-i18next';
import { Card, Empty, Typography, Button } from 'antd';
import DocumentHead from '../../../elements/components/DocumentHead';
import Logger from '../../../../../lib/Logger';
import { withRouter } from "react-router";
import ReactMarkdown from "react-markdown";
import ComponentMedia from "./ComponentMedia";
import { LeftOutlined, RightOutlined } from '@ant-design/icons';

function LearningContent({ component, activeItem, activeItemId, setActive, setActiveId, api_url_strapi, list, options, history, ...props }) {

  const [videos, setVideos] = useState([]);
  const [currentVideoIndex, setCurrentVideoIndex] = useState(0);
  const [currentItemIndex, setCurrentItemIndex] = useState(0);
  const [currentNextItemIndex, setCurrentNextItemIndex] = useState(0);
  const [currentPreviousItemIndex, setCurrentPreviousItemIndex] = useState(0);
  const [videoData, setVideoData] = useState({});
  const { Title } = Typography;

  const flush = () => {
    setVideos([]);
    setActive(null);
    setActiveId(null);
    setCurrentVideoIndex(0);
  }

  const handleNextVideo = () => {
    if (currentVideoIndex < videos.length - 1 && videos.length !== 0) {

      setCurrentVideoIndex(currentVideoIndex + 1);
      if (videos[currentVideoIndex + 1]?.VideoYoutubeURL) {

        setVideoData(
          {
            url: videos[currentVideoIndex + 1]?.VideoYoutubeURL,
            type: 1,
          });

      } else if (videos[currentVideoIndex + 1]?.VideoVimeoURL) {

        setVideoData(
          {
            url: videos[currentVideoIndex + 1]?.VideoVimeoURL,
            type: 2,
          });

      } else if (videos[currentVideoIndex + 1]?.VideoFile && videos[currentVideoIndex + 1]?.VideoFile?.data &&
        videos[currentVideoIndex + 1]?.VideoFile?.data?.attributes &&
        videos[currentVideoIndex + 1]?.VideoFile?.data?.attributes.url) {
        setVideoData(
          {
            url: api_url_strapi + videos[currentVideoIndex + 1]?.VideoFile?.data?.attributes.url,
            type: 3,
          });
      } else {
        setVideoData({});
      }

    } else {

      if (currentNextItemIndex !== options.length && currentNextItemIndex !== currentItemIndex) {
        flush();
        setCurrentItemIndex(currentNextItemIndex);
        setActive(options[currentNextItemIndex]);
        setActiveId(options[currentNextItemIndex].key);

        if (videos[currentVideoIndex]?.VideoYoutubeURL) {
          setVideoData(
            {
              url: videos[currentVideoIndex]?.VideoYoutubeURL,
              type: 1,

            });

        } else if (videos[currentVideoIndex + 1]?.VideoVimeoURL) {

          setVideoData(
            {
              url: videos[currentVideoIndex + 1]?.VideoVimeoURL,
              type: 2,
            });
  
        } else if (videos[currentVideoIndex]?.VideoFile && videos[currentVideoIndex]?.VideoFile?.data &&
          videos[currentVideoIndex]?.VideoFile?.data?.attributes &&
          videos[currentVideoIndex]?.VideoFile?.data?.attributes.url) {
          setVideoData(
            {
              url: api_url_strapi + videos[currentVideoIndex]?.VideoFile?.data?.attributes.url,
              type: 3,
            });

        } else {
          setVideoData({});
        }

      }
    }
  };

  const handlePreviousVideo = () => {
    if (currentVideoIndex !== 0 && videos.length !== 0) {
      setCurrentVideoIndex(currentVideoIndex - 1);

      if (videos[currentVideoIndex - 1]?.VideoYoutubeURL) {

        setVideoData(
          {
            url: videos[currentVideoIndex - 1]?.VideoYoutubeURL,
            type: 1,
          });

      } else if (videos[currentVideoIndex - 1]?.VideoVimeoURL) {

        setVideoData(
          {
            url: videos[currentVideoIndex - 1]?.VideoVimeoURL,
            type: 2,
          });

      } else if (videos[currentVideoIndex - 1]?.VideoFile && videos[currentVideoIndex - 1]?.VideoFile?.data &&
        videos[currentVideoIndex - 1]?.VideoFile?.data?.attributes &&
        videos[currentVideoIndex - 1]?.VideoFile?.data?.attributes.url) {
        setVideoData(
          {
            url: api_url_strapi + videos[currentVideoIndex - 1]?.VideoFile?.data?.attributes.url,
            type: 3,
          });
      } else {
        setVideoData({});
      }

    } else {

      if (currentPreviousItemIndex !== -1 && currentPreviousItemIndex !== currentItemIndex) {
        flush();

        if(options[currentPreviousItemIndex]?.videos?.length > 0){
          setCurrentVideoIndex(options[currentPreviousItemIndex].videos.length - 1);
        }

        setCurrentItemIndex(currentPreviousItemIndex);
        setActive(options[currentPreviousItemIndex]);
        setActiveId(options[currentPreviousItemIndex].key);

        if (videos[currentPreviousItemIndex]?.VideoYoutubeURL) {
          setVideoData(
            {
              url: videos[currentPreviousItemIndex]?.VideoYoutubeURL,
              type: 1,
            });

        } else if (videos[currentPreviousItemIndex]?.VideoVimeoURL) {
          setVideoData(
            {
              url: videos[currentPreviousItemIndex]?.VideoVimeoURL,
              type: 2,
            });

        } else if (videos[currentPreviousItemIndex]?.VideoFile && videos[currentPreviousItemIndex]?.VideoFile?.data &&
          videos[currentPreviousItemIndex]?.VideoFile?.data?.attributes &&
          videos[currentPreviousItemIndex]?.VideoFile?.data?.attributes.url) {
          setVideoData(
            {
              url: api_url_strapi + videos[currentPreviousItemIndex]?.VideoFile?.data?.attributes.url,
              type: 3,
            });

        } else {
          setVideoData({});
        }
        
      }
    }
  };

  const handleNextItem = () => {
    if (currentItemIndex !== options.length - 1) {
      setCurrentItemIndex(currentItemIndex + 1);
      setActive(options[currentItemIndex + 1]);
      setActiveId(options[currentItemIndex + 1].key);
    }
  };

  const handlePreviousItem = () => {
    if (currentItemIndex !== 0) {
      setCurrentItemIndex(currentItemIndex - 1);
      setActive(options[currentItemIndex - 1]);
      setActiveId(options[currentItemIndex - 1].key);
    }
  };

  useEffect(() => {

    if (activeItemId) {
      const index = options.findIndex(item => item.key === activeItemId);
      setActive(options[index]);
      setCurrentItemIndex(index);
      setVideos(options[index].videos);

      if (options[index].videos[currentVideoIndex]?.VideoYoutubeURL) {
        setVideoData(
          {
            url: options[index].videos[currentVideoIndex]?.VideoYoutubeURL,
            type: 1,
          });

      } else if (options[index].videos[currentVideoIndex]?.VideoVimeoURL) {
        setVideoData(
          {
            url: options[index].videos[currentVideoIndex]?.VideoVimeoURL,
            type: 2,
          });  
      } else if (options[index].videos[currentVideoIndex]?.VideoFile && options[index].videos[currentVideoIndex]?.VideoFile?.data &&
        options[index].videos[currentVideoIndex]?.VideoFile?.data?.attributes &&
        options[index].videos[currentVideoIndex]?.VideoFile?.data?.attributes.url) {
        setVideoData(
          {
            url: api_url_strapi + options[index].videos[currentVideoIndex]?.VideoFile?.data?.attributes.url,
            type: 3,
          });

      } else {
        setVideoData({});
      }

      setCurrentNextItemIndex(index);
      setCurrentPreviousItemIndex(index);
      for (let i = (index + 1); i < options.length; i++) {
        if (options[i]?.videos.length > 0) {
          setCurrentNextItemIndex(i);
          break;
        } else if (options[i]?.imageUrl) {
          setCurrentNextItemIndex(i);
          break;
        }
      }

      for (let k = (index + -1); k > -1; k--) {
        if (options[k]?.videos.length > 0) {
          setCurrentPreviousItemIndex(k);
          break;
        } else if (options[k]?.imageUrl) {
          setCurrentPreviousItemIndex(k);
          break;
        }
      }
    } else {
      flush();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setActive, activeItemId]);

  return (

    <Translation>
      {(t) => (
        <>
          <DocumentHead title={t("learnings_route_list")} />
          {activeItem ?
            <>
              <Card
                title={<Title level={3}>{activeItem.titleMedia ? activeItem.titleMedia : t('learning_title_media')}</Title>}
                bordered={true}
                className="learning-content-card"
              >
              <ComponentMedia videoData={videoData} activeItem={activeItem} api_url_strapi = {api_url_strapi}/>

              </Card>
                
              <div className="container">
                <div className="button-container-media">
                  <Button
                    onClick={handlePreviousVideo}
                    key="previous-media"
                    disabled={(currentPreviousItemIndex === currentItemIndex) && currentVideoIndex === 0}

                  >
                    {t("learnings_content_button_previous_media")}
                  </Button>
                  <Button

                    onClick={handleNextVideo}
                    key="next-media"
                    type="primary"
                    disabled={(currentNextItemIndex === currentItemIndex) && (currentVideoIndex === videos.length - 1 || videos.length === 0)}
                  >
                    {t("learnings_content_button_next_media")}
                  </Button>
                </div>
              </div>


              {activeItem.content ?
                <Card
                  title={<Title level={3}>{activeItem.titleContent ? activeItem.titleContent : t('learning_title_content')}</Title>}
                  bordered={false} 
                  className='learning-content-card-bottom'
                >
                  <ReactMarkdown linkTarget="_blank" className='learning-content'>
                    {activeItem.content}
                  </ReactMarkdown>
                </Card> : <Card

                  bordered={false}
                >
                </Card>}

              {options.length > 0 &&
                <div className="container">
                  <div className="button-container-page">
                    <Button
                      onClick={handlePreviousItem}
                      disabled={options.length < 1 || currentItemIndex === 0}
                      key="previous-item"
                      style={{ border: 'none' }}
                      icon={<LeftOutlined />}>
                    </Button>
                    <Button
                      onClick={handleNextItem}
                      disabled={options.length < 1 || (currentItemIndex === options.length - 1)}
                      key="next-item"
                      style={{ border: 'none' }}
                      icon={<RightOutlined />}
                    >
                    </Button>
                  </div>
                </div>
              }

            </>
            : <>
              <Card
                title={<Title level={3}>{t('learning_title_media')}</Title>}
                bordered={true} style={{ marginBottom: "20px", borderRadius: "20px" }}
                className="learning-content-card-empty"
              >
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
              </Card>

            </>}
        </>
      )}
    </Translation>
  );
}

export default withRouter(LearningContent);

Logger.log('silly', `LearningContent loaded.`);


