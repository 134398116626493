import React, { useState } from 'react';
import { Translation } from 'react-i18next';
import { Modal, Typography } from 'antd';

import ResourceNoteForm from '../containers/ResourceNoteFormContainer';
import ResourceNotesList from '../containers/ResourceNotesListContainer';
import Logger from '../../../../../lib/Logger';
import hasPermission, {
  VIEW_PARTNER,
} from '../../../elements/lib/hasPermission';

const { Title } = Typography;

const ResourceNotesModal = ({
  careGuideId,
  careGuide,
  resourceId,
  resource,
  ...props
}) => {
  const [page, setPage] = useState(1);
  const limit = 5;

  const onClickOK = () => {
    props.hideList();
    setPage(1);
  };

  const onClickCancel = () => {
    props.hideList();
    setPage(1);
  };

  return (
    <Translation>
      {(t) => (
        <>
          <Modal
            title={
              <Title level={3} className="resource-note-modal-title">
                {t('resource_notes_list_title', {
                  name: resource ? resource.name : null,
                })}
              </Title>
            }
            className="resource-note-modal"
            centered
            visible={props.visible}
            onOk={onClickOK}
            onCancel={onClickCancel}
            width={600}
            forceRender={true}
            footer={null}
          >
            {!hasPermission(VIEW_PARTNER) && (
              <ResourceNoteForm
                careGuideId={careGuideId}
                resourceId={resourceId}
                page={page}
                limit={limit}
                order={'published_at.desc'}
              />
            )}

            <div className="resource-notes-list">
              <ResourceNotesList
                careGuideId={careGuideId}
                resourceId={resourceId}
                page={page}
                limit={limit}
                order={'published_at.desc'}
                setPage={setPage.bind(this)}
              />
            </div>
          </Modal>
        </>
      )}
    </Translation>
  );
};

export default ResourceNotesModal;

Logger.log('silly', `ResourceNotesModal loaded.`);
