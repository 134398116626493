import {connect} from 'react-redux';
import {denormalize} from 'normalizr';
import moment from 'moment';

import EventList from '../components/EventList';
import {schema} from '../../../../../state/schema';
import {loadCalendarEvents, 
  calendarShowEventDetail} from '../../../../../state/modules/calendar/actions';

const mapStateToProps = (state, ownProps) => {

  const careGuideId = ownProps.careGuideId;
  const start = ownProps.start;
  const end = ownProps.end;
  const page = ownProps.page;
  const limit = ownProps.limit;
  const order = ownProps.order;
  
  const period = start + '.' + end;
  const result = state.calendar.getIn(['events', careGuideId, period, order, limit, page]);
  // const result = state.calendar.getIn(['events', careGuideId, period, 'start_at.asc', limit, page]);

  return {
    list: result 
      ? result.map(x => {
          return {
            key: x,
            ...denormalize(
              state.entities.getIn(['calendarEvents', x]),
              schema.calendarEvent,
              state.entities.toJS()
            )
          };
        }).toArray()
      : [],
    total: state.calendar.getIn(['events', careGuideId, 'total']),
    isLoading: state.calendar.get('areEventsLoading')
  }
}

const mapDispatchToProps = dispatch => {
  return {
    load: (careGuideId, start, end, page, limit, order, filter, cb) => {
      dispatch(loadCalendarEvents(careGuideId, start, end, page, limit, order, {tz: moment().utcOffset()/60, ...filter}, cb));
    },
    showDetail: (eventId, view) => {
      dispatch(calendarShowEventDetail(eventId, view));
    },
  }
}

const EventListContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(EventList);

export default EventListContainer;
