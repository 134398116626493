import { Map, List } from 'immutable';

import {
  CALENDAR_EVENT_ADMIN_LIST_REQUEST,
  CALENDAR_EVENT_ADMIN_LIST_SUCCESS,
  CALENDAR_EVENT_ADMIN_LIST_FAILURE,
  CALENDAR_EVENT_ADMIN_READ_REQUEST,
  CALENDAR_EVENT_ADMIN_READ_SUCCESS,
  CALENDAR_EVENT_ADMIN_READ_FAILURE,
  CALENDAR_EVENT_ADMIN_UPDATE_REQUEST,
  CALENDAR_EVENT_ADMIN_UPDATE_SUCCESS,
  CALENDAR_EVENT_ADMIN_UPDATE_FAILURE,
  CALENDAR_EVENT_ADMIN_CREATE_REQUEST,
  CALENDAR_EVENT_ADMIN_CREATE_SUCCESS,
  CALENDAR_EVENT_ADMIN_CREATE_FAILURE,
  CALENDAR_EVENT_ADMIN_DELETE_REQUEST,
  CALENDAR_EVENT_ADMIN_DELETE_SUCCESS,
  CALENDAR_EVENT_ADMIN_DELETE_FAILURE,
  CALENDAR_EVENT_ADMIN_FORM_DESTROY,
  CALENDAR_EVENT_ADMIN_SHOW_EVENT_DETAIL,
  CALENDAR_EVENT_ADMIN_HIDE_EVENT_DETAIL,
  CALENDAR_EVENT_ADMIN_SHOW_EVENT_PARTICIPANT_FORM,
  CALENDAR_EVENT_ADMIN_HIDE_EVENT_PARTICIPANT_FORM,
  CALENDAR_EVENT_ADMIN_LIST_PARTICIPANTS_REQUEST,
  CALENDAR_EVENT_ADMIN_LIST_PARTICIPANTS_SUCCESS,
  CALENDAR_EVENT_ADMIN_LIST_PARTICIPANTS_FAILURE,
  CALENDAR_EVENT_ADMIN_DELETE_PARTICIPANT_REQUEST,
  CALENDAR_EVENT_ADMIN_DELETE_PARTICIPANT_SUCCESS,
  CALENDAR_EVENT_ADMIN_DELETE_PARTICIPANT_FAILURE,
  CALENDAR_EVENT_ADMIN_UPDATE_PARTICIPANT_REQUEST,
  CALENDAR_EVENT_ADMIN_UPDATE_PARTICIPANT_SUCCESS,
  CALENDAR_EVENT_ADMIN_UPDATE_PARTICIPANT_FAILURE,
  CALENDAR_EVENT_ADMIN_CREATE_PARTICIPANT_REQUEST,
  CALENDAR_EVENT_ADMIN_CREATE_PARTICIPANT_SUCCESS,
  CALENDAR_EVENT_ADMIN_CREATE_PARTICIPANT_FAILURE,
  CALENDAR_EVENT_PARTICIPANT_SET_PAGE,
  CALENDAR_EVENT_ADMIN_EVENT_PARTICIPANT_FORM_DESTROY,
} from './actions';
import Logger from '../../../lib/Logger';

export default function calendarEventsAdmin(
  state = Map({
    isLoading: false,
    pages: {},
    total: 0,
    form: null,
    eventDetailIsVisible: false,
    eventDetailEventId: null,
    pageParticipants: 1,
    areParticipantEventsLoading: false,
    eventParticipantFormIsVisible: false,
    eventParticipantFormParticipantId: null,
    eventForm: {},
  }),
  action
) {
  Logger.log(
    'debug',
    `[calendarEventsAdmin.reducers] calendarEventsAdmin(%j, %j)`,
    state,
    action
  );

  switch (action.type) {
    case CALENDAR_EVENT_ADMIN_LIST_REQUEST:
      return state.mergeDeep({
        isLoading: true,
      });

    case CALENDAR_EVENT_ADMIN_LIST_SUCCESS:
      return state
        .mergeDeep({
          isLoading: false,
          pages: {
            [action.order]: {
              [action.limit]: {
                [action.page]: null,
              },
            },
          },
          total: action.total,
          lastUpdated: action.receivedAt,
        })
        .setIn(
          ['pages', action.order, action.limit, action.page],
          List(action.result)
        );

    case CALENDAR_EVENT_ADMIN_LIST_FAILURE:
      return state.mergeDeep({
        isLoading: false,
      });

    case CALENDAR_EVENT_ADMIN_READ_REQUEST:
      return state.mergeDeep({
        isLoading: true,
      });

    case CALENDAR_EVENT_ADMIN_READ_SUCCESS:
      return state.mergeDeep({
        isLoading: false,
        lastUpdated: action.receivedAt,
      });

    case CALENDAR_EVENT_ADMIN_READ_FAILURE:
      return state.mergeDeep({
        isLoading: false,
      });

    case CALENDAR_EVENT_ADMIN_UPDATE_REQUEST:
      return state.mergeDeep({
        isLoading: true,
        form: {
          isSubmitting: true,
          success: null,
          errors: null,
        },
      });

    case CALENDAR_EVENT_ADMIN_UPDATE_SUCCESS:
      return state.mergeDeep({
        isLoading: false,
        form: {
          isSubmitting: false,
          success: true,
          errors: null,
        },
        lastUpdated: action.receivedAt,
      });

    case CALENDAR_EVENT_ADMIN_UPDATE_FAILURE:
      return state
        .mergeDeep({
          isLoading: false,
          form: {
            isSubmitting: false,
            success: false,
            errors: null,
          },
        })
        .setIn(['form', 'errors'], action.error);

    case CALENDAR_EVENT_ADMIN_CREATE_REQUEST:
      return state.mergeDeep({
        isLoading: true,
        form: {
          isSubmitting: true,
          success: null,
          errors: null,
        },
      });

    case CALENDAR_EVENT_ADMIN_CREATE_SUCCESS:
      return state.mergeDeep({
        isLoading: false,
        form: {
          isSubmitting: false,
          success: true,
          created_id: action.id,
          errors: null,
        },
        lastUpdated: action.receivedAt,
      });

    case CALENDAR_EVENT_ADMIN_CREATE_FAILURE:
      return state
        .mergeDeep({
          isLoading: false,
          form: {
            isSubmitting: false,
            success: false,
            errors: null,
          },
        })
        .setIn(['form', 'errors'], action.error);

    case CALENDAR_EVENT_ADMIN_DELETE_REQUEST:
      return state.mergeDeep({
        isLoading: true,
      });

    case CALENDAR_EVENT_ADMIN_DELETE_SUCCESS:
      return state.mergeDeep({
        isLoading: false,
        lastUpdated: action.receivedAt,
      });

    case CALENDAR_EVENT_ADMIN_DELETE_FAILURE:
      return state.mergeDeep({
        isLoading: false,
      });

    case CALENDAR_EVENT_ADMIN_FORM_DESTROY:
      return state
        .mergeDeep({
          form: null,
        })
        .set('form', action.form);

    case CALENDAR_EVENT_ADMIN_SHOW_EVENT_DETAIL:
      return state.mergeDeep({
        eventDetailIsVisible: true,
        eventDetailEventId: action.eventId,
      });

    case CALENDAR_EVENT_ADMIN_HIDE_EVENT_DETAIL:
      return state.mergeDeep({
        eventDetailIsVisible: false,
        eventDetailEventId: null,
      });

    case CALENDAR_EVENT_ADMIN_SHOW_EVENT_PARTICIPANT_FORM:
      return state.mergeDeep({
        eventParticipantFormIsVisible: true,
        eventParticipantFormParticipantId: action.participantId,
      });

    case CALENDAR_EVENT_ADMIN_HIDE_EVENT_PARTICIPANT_FORM:
      return state.mergeDeep({
        eventParticipantFormIsVisible: false,
        eventParticipantFormParticipantId: null,
      });

    case CALENDAR_EVENT_ADMIN_EVENT_PARTICIPANT_FORM_DESTROY:
      return state
        .mergeDeep({
          eventForm: null,
        })
        .set('eventForm', action.form);

    case CALENDAR_EVENT_ADMIN_LIST_PARTICIPANTS_REQUEST:
      return state.mergeDeep({
        areParticipantEventsLoading: true,
      });

    case CALENDAR_EVENT_ADMIN_LIST_PARTICIPANTS_SUCCESS:
      return state
        .mergeDeep({
          areParticipantEventsLoading: false,
          calendarEventParticipants: {
            [action.calendarEventId]: {
              total: action.total,
              [action.page]: {
                [action.limit]: null,
              },
            },
          },
          lastUpdated: action.receivedAt,
        })
        .setIn(
          [
            'calendarEventParticipants',
            action.calendarEventId,
            action.page,
            action.limit,
          ],
          List(action.result)
        );

    case CALENDAR_EVENT_ADMIN_LIST_PARTICIPANTS_FAILURE:
      return state.mergeDeep({
        areParticipantEventsLoading: false,
      });

    case CALENDAR_EVENT_ADMIN_DELETE_PARTICIPANT_REQUEST:
      return state.mergeDeep({
        areParticipantEventsLoading: true,
      });

    case CALENDAR_EVENT_ADMIN_DELETE_PARTICIPANT_SUCCESS:
      return state.mergeDeep({
        areParticipantEventsLoading: false,
        lastUpdated: action.receivedAt,
      });

    case CALENDAR_EVENT_ADMIN_DELETE_PARTICIPANT_FAILURE:
      return state.mergeDeep({
        areParticipantEventsLoading: false,
      });

    case CALENDAR_EVENT_ADMIN_CREATE_PARTICIPANT_REQUEST:
      return state.mergeDeep({
        eventForm: {
          isSubmitting: true,
          success: null,
          errors: null,
        },
      });

    case CALENDAR_EVENT_ADMIN_CREATE_PARTICIPANT_SUCCESS:
      return state.mergeDeep({
        eventForm: {
          isSubmitting: false,
          success: true,
          errors: null,
        },
        lastUpdated: action.receivedAt,
      });

    case CALENDAR_EVENT_ADMIN_CREATE_PARTICIPANT_FAILURE:
      return state
        .mergeDeep({
          eventForm: {
            isSubmitting: false,
            success: false,
            errors: null,
          },
        })
        .setIn(['eventForm', 'errors'], action.error);

    case CALENDAR_EVENT_ADMIN_UPDATE_PARTICIPANT_REQUEST:
      return state.mergeDeep({
        eventForm: {
          isSubmitting: true,
          success: null,
          errors: null,
        },
      });

    case CALENDAR_EVENT_ADMIN_UPDATE_PARTICIPANT_SUCCESS:
      return state.mergeDeep({
        eventForm: {
          isSubmitting: false,
          success: true,
          errors: null,
        },
        lastUpdated: action.receivedAt,
      });

    case CALENDAR_EVENT_ADMIN_UPDATE_PARTICIPANT_FAILURE:
      return state
        .mergeDeep({
          eventForm: {
            isSubmitting: false,
            success: false,
            errors: null,
          },
        })
        .setIn(['eventForm', 'errors'], action.error);

    case CALENDAR_EVENT_PARTICIPANT_SET_PAGE:
      return state.mergeDeep({
        pageParticipants: action.page,
      });

    default:
      return state;
  }
}

Logger.log('silly', `calendarEventsAdmin.reducers loaded.`);
