import { connect } from "react-redux";
import { denormalize } from "normalizr";
import { schema } from "../../../../../state/schema";
import UserProfilesPartnershipsChooserHomeList from "../components/UserProfilesPartnershipsChooserHomeList";
import {
  loadUserProfilesPartnerships,
  userProfilesPartnershipSetActive,
} from "../../../../../state/modules/userProfilesPartnerships/actions";
import { careGuideSetActive, careGuideSetAccess } from "../../../../../state/modules/careGuides/actions";
import storage from "../../../../../lib/Storage";

const mapStateToProps = (state, ownProps) => {

  const page = ownProps.page ? ownProps.page : 1;
  const limit = 10;
  const profileId = state.session.get("profileId");
  const orderCareGuide = "started_at.asc";
  
  const resultCareGuide = state.careGuides.getIn([
    "pages",
    orderCareGuide,
    limit,
    page,
  ]);

  const listCareGuide = resultCareGuide
  ? resultCareGuide
      .map((x) => {
        return {
          key: x,
          ...denormalize(
            state.entities.getIn(["careGuides", x]),
            schema.careGuide,
            state.entities.toJS()
          ),
        };
      })
      .toArray()
      .filter(
        (x) =>
          x.account &&
          (x.account.is_subscription_valid ||
            x.account.owner_id === profileId)
      )
      .filter((x) => {
        if (x.partnership) {
          return x.partnership.status === 1 && x;
        } else {
          return x;
        }
      })
  : [];

  const orderUserProfilePartnership = "updated_at.asc";
  const pageUserProfilePartnership = ownProps.pageUserProfilePartnership ? ownProps.pageUserProfilePartnership : 1;

  const result = state.userProfilesPartnerships.getIn([
    "pages",
    orderUserProfilePartnership,
    limit,
    pageUserProfilePartnership,
  ]);

  const list = result
  ? result
      .map((x) => {
        return {
          key: x,
          ...denormalize(
            state.entities.getIn(["userProfilesPartnerships", x]),
            schema.userProfilePartnership,
            state.entities.toJS()
          ),
        };
      })
      .toArray()
      .filter((x) => x.partnership?.is_plan_valid)
      .filter((x) => x.partnership?.status === 1).map(x => {
        // flatten some nested objects to make table easier to work with
        return {
          partnership_name: x.partnership ? x.partnership.name : '',
          partnership_plan: x.partnership && x.partnership.plan ? x.partnership.plan.name : '',
          ...x
        };
      })
  : [];

  return {
    listCareGuide, 
    list,
    total: state.userProfilesPartnerships.get("total"),
    isLoading: state.userProfilesPartnerships.get("isLoading"),
    careGuideCount: state.careGuides.get("total"),
    partnershipActiveId: storage.get('local', 'partnershipId'),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    load: (page, limit, order, filter, cb) => {
      dispatch(loadUserProfilesPartnerships(page, limit, order, filter, cb));
    },
    setActive: (id) => {
      dispatch(userProfilesPartnershipSetActive(id));
    },
    setActiveCareGuide: (id) => {
      dispatch(careGuideSetActive(id));
    },
    setActiveCareGuideAccess: (data) => {
      dispatch(careGuideSetAccess(data));
    },
  };
};

const UserProfilesChooserHomeListContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(UserProfilesPartnershipsChooserHomeList);

export default UserProfilesChooserHomeListContainer;
