import { getI18n } from 'react-i18next';

import Config from '../../../../Config';
import Logger from '../../../../lib/Logger';
import Events from '../../../../lib/EventEmitter';

import ResourcesScreen from './containers/ResourcesScreenContainer';

const register = () => {
  Logger.log('debug', `resources.register()`);

  if (Config.getIn(['MODULE_TOGGLES', 'resources', 'routes'])) {
    Events.dispatch(
      'ADD_MAIN_ROUTES',

      // screen name (key): [route type (element), path (prop), exact (prop), component (prop), route name]
      {
        // 'ResourcesScreen': ['PrivateRoute', '/care-guide/:id([\\w\\-]+)/resources/:page(\\d+)?/:limit(\\d+)?', true, ResourcesScreen, getI18n().t('resources_route_list')],
        ResourcesScreen: [
          'PrivateRoute',
          '/care-guide/:id([\\w\\-]+)/resources/:page(\\d+)?',
          true,
          ResourcesScreen,
          getI18n().t('resources_route_list'),
        ],
      }
    );
  }
};

export default register;

Logger.log('silly', `resources.register() loaded.`);
