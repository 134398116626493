import {connect} from 'react-redux';

import UserProfilesPartnershipsAdminScreen from '../components/UserProfilesPartnershipsAdminScreen';
import storage from "../../../../../lib/Storage";

const mapStateToProps = state => {
  return {
    total: state.userProfilesPartnershipsAdmin.get('total'),
    isLoading: state.userProfilesPartnershipsAdmin.get('isLoading'),
    activeId: storage.get('local', 'partnershipId'),
    access_role_admin_in_app: storage.get('local', 'partnershipData')?.access_role_admin_in_app,

  }
}

const UserProfilesPartnershipsAdminScreenContainer = connect(
  mapStateToProps
)(UserProfilesPartnershipsAdminScreen);

export default UserProfilesPartnershipsAdminScreenContainer;
