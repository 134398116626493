import React from 'react';
import {Translation, getI18n} from 'react-i18next';
import {ConfigProvider, Table, Typography} from 'antd';
import {CheckOutlined, CloseOutlined} from '@ant-design/icons';
import {FaCircle, FaUserCheck, FaUserFriends, FaQuestionCircle, FaUserTimes} from "react-icons/fa";

import useWindowDimensions from '../../../../../lib/WindowDimensions';


const withHttp = url => !/^https?:\/\//i.test(url) ? `http://${url}` : url;

const TextView = ({content, ...props}) => {
  return (
    <p><strong>{content}</strong></p>
  );
}

const TextAreaView = ({content, ...props}) => {
  return (
    <p>{content}</p>
  );
}

const WYSIWYGView = ({content, ...props}) => {
  return (
    <div dangerouslySetInnerHTML={{__html: content}} />
  );
}

const TableView = ({columns, rows, ...props}) => {
  const cols = columns
    ? columns.map((x, i) => {
        return {
          title: x.label,
          dataIndex: i,
          key: i.toString(),
          render: val => [

              // text
              val,

              // textarea
              val,

              // check mark
              val ? <CheckOutlined /> : null,

              // text w/check mark
              val ? <span><CheckOutlined /> {val}</span> : null,

              // cross mark
              val ? <CloseOutlined /> : null,

              // text w/cross mark
              val ? <span><CloseOutlined /> {val}</span> : null,

              // phone number
              <a href={"tel:" + val}>{val}</a>,

              // URL
              <a href={withHttp(val)} target="_blank" rel='noopener noreferrer'>{val}</a>,

            ][x.type - 1],
        }
      })
    : [];

  const data = rows ? rows.map((_, i) => {return {key: i.toString(), ...{...rows[i].content}}}) : [];
  
  return (
    <Table columns={cols} dataSource={data} pagination={false} size="small" />
  );
}

const ADLTableView = ({groups, ...props}) => {

  const {width} = useWindowDimensions();
  const isMobile = width < 500;

  const renderCell = (text, row, index) => {
    return (index % 2 === 1)
      ? {props: {colSpan: 0}}
      : text;
  }

  const dataColumnWidth = isMobile ? 40 : 110;

  const checkMark = <FaCircle style={{color: '#232f66'}} />;
  const questionMark = <FaQuestionCircle style={{color: '#7c829b'}} />;

  const tables = groups.filter(g => g.fields && g.fields.length > 0).map(group => {
    return {
      cols: [
        {
          title: group.name,
          dataIndex: 'name',
          key: 'name',
          render: (text, row, index) => {
            return (index % 2 === 1)
              ? {
                  children: text,
                  props: {
                    colSpan: 4,
                  },
                }
              : text;
          }
        },
        {
          title: isMobile ? <FaUserCheck /> : getI18n().t('care_guides_adl_table_independent'),
          dataIndex: 'independent',
          key: 'independent',
          render: renderCell,
          width: dataColumnWidth,
          align: 'center',
        },
        {
          title: isMobile ? <FaUserFriends /> : getI18n().t('care_guides_adl_table_needs_help'),
          dataIndex: 'needsHelp',
          key: 'needsHelp',
          render: renderCell,
          width: dataColumnWidth,
          align: 'center',
        },
        {
          title: isMobile ? <FaUserTimes /> : getI18n().t('care_guides_adl_table_cannot_do'),
          dataIndex: 'cannotDo',
          key: 'cannotDo',
          render: renderCell,
          width: dataColumnWidth,
          align: 'center',
        },
      ],
      data: group.fields
        ? group.fields.flatMap((field, i) => {
            const output = [
              {
                key: i,
                name: <Typography.Text strong>{field.hint ? `${field.name} (${field.hint})` : field.name}</Typography.Text>,
                independent: field.value ? (field.value & 1 ? checkMark : null) : questionMark,
                needsHelp: field.value ? (field.value & 2 ? checkMark : null) : questionMark,
                cannotDo: field.value ? (field.value & 4 ? checkMark : null) : questionMark,
              }
            ];
            if (field.notes || true) {
              output.push(
                {
                  key: `${i}-notes`,
                  name: <Typography.Text>{field.notes || getI18n().t('care_guides_adl_table_notes')}</Typography.Text>,
                }
              )
            }
            return output;
          })
        : null,
    };
  });
  
  return (
    <div className='adl-table'>
      {tables.map((table, i) =>
        <ConfigProvider key={i} renderEmpty={() => null}>
          <Table columns={table.cols} dataSource={table.data} pagination={false} size="small" bordered />
        </ConfigProvider>
      )}
    </div>
  );
}

const MedTableView = ({groups, ...props}) => {

  const {width} = useWindowDimensions();
  const isMobile = width < 500;

  const renderCell = (text, row, index) => {
    return (index % 2 === 1)
      ? {props: {colSpan: 0}}
      : text;
  }

  const renderTimeCell = (text, row, index) => {
    return renderCell(text ?? getI18n().t('care_guides_med_table_time_as_needed'), row, index);
  }

  const doseColumnWidth = isMobile ? 100 : 150;
  const timeColumnWidth = isMobile ? 60 : 120;

  const tables = groups && Array.isArray(groups)
    ? groups.filter(g => g.fields).map(group => {
        return {
          cols: [
            {
              title: group.name,
              dataIndex: 'medication',
              key: 'medication',
              render: (text, row, index) => {
                return (index % 2 === 1)
                  ? {
                      children: text,
                      props: {
                        colSpan: 4,
                      },
                    }
                  : text;
              }
            },
            {
              title: getI18n().t('care_guides_med_table_dose'),
              dataIndex: 'dose',
              key: 'dose',
              render: renderCell,
              width: doseColumnWidth,
              align: 'center',
            },
            {
              title: getI18n().t('care_guides_med_table_time'),
              dataIndex: 'time',
              key: 'time',
              render: renderTimeCell,
              width: timeColumnWidth,
              align: 'center',
            },
          ],
          data: group.fields && group.fields.length > 0
            ? group.fields.flatMap((field, i) => {
                const output = [
                  {
                    key: i,
                    medication: field ? <Typography.Text strong>{field.medication}</Typography.Text> : null,
                    dose: field ? field.dose : null,
                    time: field ? field.time : null,
                  }
                ];
                if (true) {
                  output.push(
                    {
                      key: `${i}-notes`,
                      medication: <Typography.Text>{(field && field.notes) || getI18n().t('care_guides_med_table_notes')}</Typography.Text>,
                    }
                  )
                }
                return output;
              })
            : null,
        };
      })
    : [];
  
  return (
    <div className='med-table'>
      {tables.map((table, i) =>
        <ConfigProvider key={i} renderEmpty={() => null}>
          <Table columns={table.cols} dataSource={table.data} pagination={false} size="small" bordered />
        </ConfigProvider>
      )}
    </div>
  );
}

const CareGuideSegmentContent = ({segment, ...props}) => {
  return (
    <Translation>{(t) => 
      <>
        {segment.contents && segment.contents.length > 0
          ? segment.contents.map((content, i) => 
              <div key={i} className="segment-content">

                {/* text */}
                {content.type === 1 ? <TextView content={content.content} /> : null}

                {/* textarea */}
                {content.type === 2 ? <TextAreaView content={content.content} /> : null}

                {/* wysiwyg */}
                {content.type === 3 ? <WYSIWYGView content={content.content} /> : null}

                {/* table */}
                {content.type === 4 ? <TableView columns={content.columns} rows={content.rows} /> : null}

                {/* ADL table */}
                {content.type === 5 ? <ADLTableView groups={content.groups} /> : null}

                {/* Med table */}
                {content.type === 6 ? <MedTableView groups={content.groups} /> : null}

              </div>
            )
          : null}
      </>
    }</Translation>
  )
}

export default CareGuideSegmentContent;
