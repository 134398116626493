import { Translation } from 'react-i18next';
import { Button, Badge, Card, Empty } from 'antd';
import UserAvatar from '../../../elements/components/UserAvatar';
import React, { Component } from 'react';
import MessageChat from '../containers/MessageChatContainer';
import { pathTo, getRouteFromPath } from '../../../Routes';
import DocumentHead from '../../../elements/components/DocumentHead';
import MessageDetail from '../containers/MessageDetailContainer';
import Logger from '../../../../../lib/Logger';
import { Link } from 'react-router-dom';
import { AiOutlineArrowLeft } from 'react-icons/ai';

class MessagesThreadScreen extends Component {
  constructor(props) {
    super(props);
    this.handleResize = this.handleResize.bind(this);
  }

  static defaultProps = {
    isLoading: true,
  };
  state = {
    windowWidth: 0,
  };

  componentDidMount() {
    this.handleResize();
    window.addEventListener("resize", this.handleResize);
    if (this.props.thread?.id) {
      this.props.thread_id(this.props.thread.id);
    }
  }

  componentDidUpdate() {
    if (this.props.thread?.id) {
      this.props.thread_id(this.props.thread.id);
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  handleResize() {
    this.setState({ windowWidth: window.innerWidth });
  }

  render() {
    const { thread, currentScreen, profileId } = this.props;
    const currentRoute = getRouteFromPath(this.props.location.pathname);
    const screens = {
      MessagesInboxThreadScreen: "MessagesInboxScreen",
      MessagesArchivedThreadScreen: "MessagesArchivedScreen",
    };

    return (
      <Translation>
        {(t) => (
          <>
            <DocumentHead title={t("messages_route_list_sent")} />

            {thread ? (
              <Card
                className="message-thread-card"
                title={
                  <>
                    {thread && thread.messages && thread.messages.length > 0 ? (
                      <div className="message-thread-care-guide-image">
                        <UserAvatar
                          profile={thread.messages[0].profile}
                          showTooltp={false}
                        />
                      </div>
                    ) : null}
                    <div className="message-thread-subject">
                      {thread && thread.messages && thread.messages[0].subject
                        ? thread.messages[0].subject
                        : t("messages_no_subject")}
                    </div>
                    <div className="message-thread-subtitle">
                      {thread && thread.care_guide
                        ? t("messages_care_guide_for", {
                            name:
                              thread.care_guide.first_name +
                              " " +
                              thread.care_guide.last_name,
                          })
                        : null}
                    </div>
                  </>
                }
                extra={
                  this.state.windowWidth < 768 && (
                    <Button style={{ border: "none" }}>
                      <Link to={pathTo(screens[currentRoute.screen])}>
                        <Badge>
                          <AiOutlineArrowLeft
                            style={{ fontSize: 24, color: "#4E77B8" }}
                          />
                        </Badge>
                      </Link>
                    </Button>
                  )
                }
                bordered={false}
              >
                {thread && thread.messages
                  ? thread.messages.map((x, i) => (
                      <MessageDetail
                        key={x.id}
                        messageId={x.id}
                        defaultOpen={
                          currentScreen === "MessagesSentThreadScreen"
                            ? x.profile.id === profileId
                            : i === thread.messages.length - 1
                        }
                      />
                    ))
                  : null}
              </Card>
            ) : (
              <Card bordered={false} className="message-body-card">
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
              </Card>
            )}

            {thread && <MessageChat />}

          </>
        )}
      </Translation>
    );
  }
}

export default MessagesThreadScreen;

Logger.log('silly', `MessagesThreadScreen loaded.`);
