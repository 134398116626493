import { connect } from 'react-redux';
import { denormalize } from 'normalizr';

import { schema } from '../../../../../state/schema';
import { resourceNoteHideList } from '../../../../../state/modules/resourceNotes/actions';
import ResourceNotesModal from '../components/ResourceNotesModal';

const mapStateToProps = (state, ownProps) => {
  const careGuide = ownProps.careGuideId
    ? denormalize(
        state.entities.getIn(['careGuides', ownProps.careGuideId]),
        schema.careGuide,
        state.entities.toJS()
      )
    : null;

  const resourceId = state.resourceNotes.get('listResourceId');
  const resource = resourceId
    ? denormalize(
        state.entities.getIn(['resources', resourceId]),
        schema.resource,
        state.entities.toJS()
      )
    : null;

  return {
    careGuide: careGuide,
    resource: resource,
    resourceId: resourceId,
    visible: state.resourceNotes.get('listIsVisible'),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    hideList: () => {
      dispatch(resourceNoteHideList());
    },
  };
};

const ResourceNotesModalContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ResourceNotesModal);

export default ResourceNotesModalContainer;
