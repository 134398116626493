import React, {useEffect} from 'react';
import {withRouter} from "react-router";
import {Translation, getI18n} from 'react-i18next';
import {Table, Space} from 'antd';
import {pathTo} from '../../../Routes';
import QueryString from '../../../../../lib/QueryString';
import Logger from '../../../../../lib/Logger';
import ListActions from '../../../elements/components/ListActions';
import { getColumnSearchProps } from '../../../elements/components/TableColumnFilters';

const CareGuidesAdminList = ({component, page, limit, order, filter, total, load, remove, history, activeId, loadCareGuides, ...props}) => {

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
  };

  const handleReset = (clearFilters, dataIndex) => {
    clearFilters();
    delete filter[dataIndex];
    history.push(QueryString.append(props.location.pathname, { order, ...filter }));
  };

  const columns = [
    {
      title: getI18n().t("owner_first_name"),
      dataIndex: "owner_profile_first_name",
      key: "owner_profile_first_name",
    ...getColumnSearchProps('owner_profile_first_name', handleSearch, handleReset, getI18n().t('owner_first_name'), filter),
    },
    {
      title: getI18n().t("owner_last_name"),
      dataIndex: "owner_profile_last_name",
      key: "owner_profile_last_name",      
      ...getColumnSearchProps('owner_profile_last_name', handleSearch, handleReset, getI18n().t('owner_last_name'), filter),
    },
    {
      title: getI18n().t("care_guide_first_name"),
      dataIndex: "care_guide_first_name",
      key: "care_guide_first_name",
      ...getColumnSearchProps('care_guide_first_name', handleSearch, handleReset, getI18n().t('care_guide_first_name'), filter),
    },
    {
      title: getI18n().t("care_guide_last_name"),
      dataIndex: "care_guide_last_name",
      key: "care_guide_last_name",
      ...getColumnSearchProps('care_guide_last_name', handleSearch, handleReset, getI18n().t('care_guide_last_name'), filter),
    },
    {
      title: getI18n().t("owner_email"),
      dataIndex: "owner_profile_email",
      key: "owner_profile_email",
      ...getColumnSearchProps('owner_profile_email', handleSearch, handleReset, getI18n().t('owner_email'), filter),
    },
    {
      title: getI18n().t('table_header_actions'),
      key: 'actions',
      render: item =>
      <Space>
          <ListActions
          id={item.id}
          id_partnership={activeId}
          page={page}
          limit={limit}
          order={order}
          filter={filter}
          load={load.bind(this)}
          remove={null}
          activeDelete={false}
          editScreen="CareGuideAdminEditScreen"
          activeEdit={true}
        />,
        </Space>
    },
  ];

  const pagination = {
    current: page,
    pageSize: limit,
    total: total,
    showSizeChanger: false,
    position: ['bottomCenter'],
  }

  const handleTableChange = (pagination, filters, sorter) => {

    let path = props.location.pathname;
    const params = {};

    // handle pagination
    if ('current' in pagination && pagination['current']) {
      path = pathTo(component, {page: pagination['current']});
    }

    // handle sorting
    if ('field' in sorter && 'order' in sorter) {
      if (sorter['field'] && sorter['order']) {
        const order = sorter['field'] + '.' + (sorter['order'] === 'ascend' ? 'asc' : 'desc');
        params['order'] = order;
      }
    }

    // handle filters
    if (filters) {
      for (const key in filters) {
        if (filters[key]) {
          params[key] = filters[key].join(',');
        }
      }
    }

    history.push(QueryString.append(path, params));
  }

  const filterString = JSON.stringify(filter);
  useEffect(() => {
    load(activeId, page, limit, order, JSON.parse(filterString));
    loadCareGuides(page, limit, order, JSON.parse(filterString), activeId);
  }, [activeId, page, limit, order, filterString, load, loadCareGuides]);

  return (
    <Translation>{(t) => 

      <Table
        dataSource={props.list}
        columns={columns}
        pagination={page === 1 && total < limit  ? false : pagination}
        loading={props.isLoading}
        scroll={{x: true}}
        onChange={handleTableChange}
      />

    }</Translation>
  );
}

export default withRouter(CareGuidesAdminList);

Logger.log('silly', `CareGuidesAdminList loaded.`);
