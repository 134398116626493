import { normalize } from 'normalizr';

import api from '../../api';
import { schema } from '../../schema';
import { addEntities } from '../../actions';
import Logger from '../../../lib/Logger';

export const TYPES = {
  LIST_REQUEST: 'CARE_GUIDE_NOTES/LIST_REQUEST',
  LIST_SUCCESS: 'CARE_GUIDE_NOTES/LIST_SUCCESS',
  LIST_FAILURE: 'CARE_GUIDE_NOTES/LIST_FAILURE',
  PDF_DOWNLOAD_REQUEST: 'CARE_GUIDE_NOTES/PDF_DOWNLOAD_REQUEST',
  PDF_DOWNLOAD_SUCCESS: 'CARE_GUIDE_NOTES/PDF_DOWNLOAD_SUCCESS',
  PDF_DOWNLOAD_FAILURE: 'CARE_GUIDE_NOTES/PDF_DOWNLOAD_FAILURE',
  CREATE_REQUEST: 'CARE_GUIDE_NOTES/CREATE_REQUEST',
  CREATE_SUCCESS: 'CARE_GUIDE_NOTES/CREATE_SUCCESS',
  CREATE_FAILURE: 'CARE_GUIDE_NOTES/CREATE_FAILURE',
  UPDATE_REQUEST: 'CARE_GUIDE_NOTES/UPDATE_REQUEST',
  UPDATE_SUCCESS: 'CARE_GUIDE_NOTES/UPDATE_SUCCESS',
  UPDATE_FAILURE: 'CARE_GUIDE_NOTES/UPDATE_FAILURE',
  PATCH_REQUEST: 'CARE_GUIDE_NOTES/PATCH_REQUEST',
  PATCH_SUCCESS: 'CARE_GUIDE_NOTES/PATCH_SUCCESS',
  PATCH_FAILURE: 'CARE_GUIDE_NOTES/PATCH_FAILURE',
  DELETE_REQUEST: 'CARE_GUIDE_NOTES/DELETE_REQUEST',
  DELETE_SUCCESS: 'CARE_GUIDE_NOTES/DELETE_SUCCESS',
  DELETE_FAILURE: 'CARE_GUIDE_NOTES/DELETE_FAILURE',
  FORM_DESTROY: 'CARE_GUIDE_NOTES/FORM_DESTROY',
  SHOW_FORM: 'CARE_GUIDE_NOTES/SHOW_FORM',
  HIDE_FORM: 'CARE_GUIDE_NOTES/HIDE_FORM',
};

export function careGuideNoteListRequest(
  careGuideId,
  page,
  limit,
  order,
  filter
) {
  Logger.log(
    'debug',
    `[state.careGuideNotes.actions] careGuideNoteListRequest(${careGuideId}, ${page}, ${limit}, ${order}, %j)`,
    filter
  );
  return {
    type: TYPES.LIST_REQUEST,
    careGuideId: careGuideId,
    page: page,
    limit: limit,
    order: order,
    filter: filter,
  };
}

export function careGuideNoteListSuccess(careGuideId, data) {
  Logger.log(
    'debug',
    `[state.careGuideNotes.actions] careGuideNoteListSuccess(%j)`,
    data
  );
  return {
    type: TYPES.LIST_SUCCESS,
    careGuideId: careGuideId,
    page: data.page,
    limit: data.limit,
    order: data.order,
    result: data.result,
    total: data.total,
    receivedAt: Date.now(),
  };
}

export function careGuideNoteListFailure(careGuideId, error) {
  Logger.log(
    'debug',
    `[state.careGuideNotes.actions] careGuideNoteListFailure(%j)`,
    error
  );
  return {
    type: TYPES.LIST_FAILURE,
    careGuideId: careGuideId,
    error: error,
  };
}

export function careGuideNotesPDFDownloadRequest(careGuideId, order, filter) {
  Logger.log(
    'debug',
    `[state.careGuideNotes.actions] careGuideNotesPDFDownloadRequest(${careGuideId}, ${order}, %j)`,
    filter
  );
  return {
    type: TYPES.PDF_DOWNLOAD_REQUEST,
    careGuideId: careGuideId,
    order: order,
    filter: filter,
  };
}

export function careGuideNotesPDFDownloadSuccess() {
  Logger.log(
    'debug',
    `[state.careGuideNotes.actions] careGuideNotesPDFDownloadSuccess()`
  );
  return {
    type: TYPES.PDF_DOWNLOAD_SUCCESS,
  };
}

export function careGuideNotesPDFDownloadFailure() {
  Logger.log(
    'debug',
    `[state.careGuideNotes.actions] careGuideNotesPDFDownloadFailure()`
  );
  return {
    type: TYPES.PDF_DOWNLOAD_FAILURE,
  };
}

export function careGuideNoteCreateRequest(careGuideId, data) {
  Logger.log(
    'debug',
    `[state.careGuideNotes.actions] careGuideNoteCreateRequest(${careGuideId}, %j)`,
    data
  );
  return {
    type: TYPES.CREATE_REQUEST,
    careGuideId: careGuideId,
  };
}

export function careGuideNoteCreateSuccess(careGuideId, data) {
  Logger.log(
    'debug',
    `[state.careGuideNotes.actions] careGuideNoteCreateSuccess(${careGuideId}, %j)`,
    data
  );
  return {
    type: TYPES.CREATE_SUCCESS,
    careGuideId: careGuideId,
    id: data.id,
    receivedAt: Date.now(),
  };
}

export function careGuideNoteCreateFailure(careGuideId, error) {
  Logger.log(
    'debug',
    `[state.careGuideNotes.actions] careGuideNoteCreateFailure(${careGuideId}, %j)`,
    error
  );
  return {
    type: TYPES.CREATE_FAILURE,
    careGuideId: careGuideId,
    error: error,
  };
}

export function careGuideNoteUpdateRequest(careGuideId, id, data) {
  Logger.log(
    'debug',
    `[state.careGuideNotes.actions] careGuideNoteUpdateRequest(${careGuideId}, ${id}, %j)`,
    data
  );
  return {
    type: TYPES.UPDATE_REQUEST,
    careGuideId: careGuideId,
    id: id,
  };
}

export function careGuideNoteUpdateSuccess(careGuideId, data) {
  Logger.log(
    'debug',
    `[state.careGuideNotes.actions] careGuideNoteUpdateSuccess(${careGuideId}, %j)`,
    data
  );
  return {
    type: TYPES.UPDATE_SUCCESS,
    careGuideId: careGuideId,
    id: data.id,
    receivedAt: Date.now(),
  };
}

export function careGuideNoteUpdateFailure(careGuideId, error) {
  Logger.log(
    'debug',
    `[state.careGuideNotes.actions] careGuideNoteUpdateFailure(${careGuideId}, %j)`,
    error
  );
  return {
    type: TYPES.UPDATE_FAILURE,
    careGuideId: careGuideId,
    error: error,
  };
}

export function careGuideNoteDeleteRequest(careGuideId, id) {
  Logger.log(
    'debug',
    `[state.careGuideNotes.actions] careGuideNoteDeleteRequest(${careGuideId}, ${id})`
  );
  return {
    type: TYPES.DELETE_REQUEST,
    careGuideId: careGuideId,
    id: id,
  };
}

export function careGuideNoteDeleteSuccess(careGuideId, id) {
  Logger.log(
    'debug',
    `[state.careGuideNotes.actions] careGuideNoteDeleteSuccess(${id})`
  );
  return {
    type: TYPES.DELETE_SUCCESS,
    careGuideId: careGuideId,
    id: id,
  };
}

export function careGuideNoteDeleteFailure(careGuideId, error) {
  Logger.log(
    'debug',
    `[state.careGuideNotes.actions] careGuideNoteDeleteFailure(${careGuideId}, %j)`,
    error
  );
  return {
    type: TYPES.DELETE_FAILURE,
    careGuideId: careGuideId,
    error: error,
  };
}

export function careGuideNotePatchRequest(careGuideId, id, data) {
  Logger.log(
    'debug',
    `[state.careGuideNotes.actions] careGuideNotePatchRequest(${careGuideId}, ${id}, %j)`,
    data
  );
  return {
    type: TYPES.PATCH_REQUEST,
    careGuideId: careGuideId,
    id: id,
  };
}

export function careGuideNotePatchSuccess(careGuideId, id, data) {
  Logger.log(
    'debug',
    `[state.careGuideNotes.actions] careGuideNotePatchSuccess(${careGuideId}, ${id}, %j)`,
    data
  );
  return {
    type: TYPES.PATCH_SUCCESS,
    careGuideId: careGuideId,
    id: id,
    receivedAt: Date.now(),
  };
}

export function careGuideNotePatchFailure(careGuideId, id, error) {
  Logger.log(
    'debug',
    `[state.careGuideNotes.actions] careGuideNotePatchFailure(${careGuideId}, ${id}, %j)`,
    error
  );
  return {
    type: TYPES.PATCH_FAILURE,
    careGuideId: careGuideId,
    id: id,
    error: error,
  };
}

export function careGuideNoteFormDestroy(formState = null) {
  Logger.log(
    'debug',
    `[state.careGuideNotes.actions] careGuideNoteFormDestroy(%j)`,
    formState
  );
  return {
    type: TYPES.FORM_DESTROY,
    form: formState,
  };
}

export function careGuideNoteShowForm(id = null) {
  Logger.log('debug', `[state.careGuideNotes.actions] careGuideNoteShowForm()`);
  return {
    type: TYPES.SHOW_FORM,
    id: id,
  };
}

export function careGuideNoteHideForm() {
  Logger.log('debug', `[state.careGuideNotes.actions] careGuideNoteHideForm()`);
  return {
    type: TYPES.HIDE_FORM,
  };
}

// API THUNK ACTION CREATORS
export function loadCareGuideNotes(
  careGuideId = null,
  page = 1,
  limit = 15,
  order = null,
  filter = null,
  cb = function () {}
) {
  Logger.log(
    'debug',
    `[state.careGuideNotes.actions] loadCareGuideNotes(${careGuideId}, ${page}, ${limit}, ${order}, %j, ###)`,
    filter
  );
  return async function (dispatch) {
    dispatch(careGuideNoteListRequest(careGuideId, page, limit, order, filter));

    // call API
    const response = await api.getCareGuideNotes(
      careGuideId,
      page,
      limit,
      order,
      filter
    );
    let success = false;

    // get care guide notes list success
    if (200 === response.get('status')) {
      Logger.log(
        'info',
        `Get API care guide notes list success. Care Guide ID: ${careGuideId} Page: ${page}, Limit: ${limit}, Order: ${order}.`
      );

      const normalizedEntities = normalize(
        response.getIn(['data', 'care_guide_notes']),
        [schema.careGuideNote]
      );

      const data = {
        page: response.getIn(['data', 'page']),
        limit: response.getIn(['data', 'limit']),
        order: order,
        total: response.getIn(['data', 'total']),
        result: normalizedEntities.result,
      };
      dispatch(addEntities(normalizedEntities));
      dispatch(careGuideNoteListSuccess(careGuideId, data));
      success = true;
    } else if (1 === page && 204 === response.get('status')) {
      Logger.log(
        'info',
        `Get API care guide notes list success [empty]. Care Guide ID: ${careGuideId} Page: ${page}, Limit: ${limit}, Order: ${order}.`
      );
      const data = {
        page: page,
        limit: limit,
        order: order,
        total: 0,
        result: [],
      };
      dispatch(careGuideNoteListSuccess(careGuideId, data));
      success = true;

      // get care guide notes list failure
    } else {
      Logger.log(
        'info',
        `Get API care guide notes list failure. Care Guide ID: ${careGuideId} Page: ${page}, Limit: ${limit}, Order: ${order}.`
      );
      dispatch(
        careGuideNoteListFailure(careGuideId, response.getIn(['data', 'error']))
      );
    }

    // callback function
    cb(success);
  };
}

export function createCareGuideNote(careGuideId, data, cb = function () {}) {
  Logger.log(
    'debug',
    `[state.careGuideNotes.actions] createCareGuideNote(${careGuideId}, %j, ###)`,
    data
  );

  return async function (dispatch) {
    dispatch(careGuideNoteCreateRequest(careGuideId, data));

    // call API
    const response = await api.postCareGuideNote(careGuideId, data);
    let success = false;

    // post care guide note success
    if (201 === response.get('status')) {
      Logger.log(
        'info',
        `POST API care guide note success. Care Guide ID: ${careGuideId}.`
      );

      const normalizedEntities = normalize(
        [response.getIn(['data', 'care_guide_note'])],
        [schema.careGuideNote]
      );
      const data = {
        id: response.getIn(['data', 'care_guide_note', 'id']),
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(careGuideNoteCreateSuccess(careGuideId, data));
      success = true;

      // post care guide note failure
    } else {
      Logger.log(
        'info',
        `POST API care guide note failure. Care Guide ID: ${careGuideId}.`
      );
      dispatch(
        careGuideNoteCreateFailure(
          careGuideId,
          response.getIn(['data', 'error'])
        )
      );
    }

    // callback function
    cb(success);
  };
}

export function updateCareGuideNote(
  careGuideId,
  id,
  data,
  cb = function () {}
) {
  Logger.log(
    'debug',
    `[state.careGuideNotes.actions] updateCareGuideNote(${careGuideId}, ${id}, %j, ###)`,
    data
  );

  return async function (dispatch) {
    dispatch(careGuideNoteUpdateRequest(careGuideId, id, data));

    // call API
    const response = await api.putCareGuideNote(careGuideId, id, data);
    let success = false;

    // put care guide note success
    if (200 === response.get('status')) {
      Logger.log(
        'info',
        `UPDATE API care guide note success. Care Guide ID: ${careGuideId}, User: ${id}.`
      );

      const normalizedEntities = normalize(
        [response.getIn(['data', 'care_guide_note'])],
        [schema.careGuideNote]
      );
      const data = {
        id: response.getIn(['data', 'care_guide_note', 'id']),
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(careGuideNoteUpdateSuccess(careGuideId, data));
      success = true;

      // put care guide note failure
    } else {
      Logger.log(
        'info',
        `UPDATE API care guide note failure. Care Guide ID: ${careGuideId}, ID: ${id}.`
      );
      dispatch(
        careGuideNoteUpdateFailure(
          careGuideId,
          response.getIn(['data', 'error'])
        )
      );
    }

    // callback function
    cb(success);
  };
}

export function deleteCareGuideNote(
  careGuideId = null,
  id = null,
  cb = function () {}
) {
  Logger.log(
    'debug',
    `[state.careGuideNotes.actions] deleteCareGuideNote(${careGuideId}, ${id}, ###)`
  );

  return async function (dispatch) {
    dispatch(careGuideNoteDeleteRequest(careGuideId, id));

    // call API
    const response = await api.deleteCareGuideNote(careGuideId, id);
    let success = false;

    // delete care guide note success
    if (204 === response.get('status')) {
      Logger.log(
        'info',
        `Delete API care guide note success. Care Guide ID: ${careGuideId} ID: ${id}.`
      );
      dispatch(careGuideNoteDeleteSuccess(careGuideId, id));
      success = true;

      // get care guide note failure
    } else {
      Logger.log(
        'info',
        `Delete API care guide note failure. Care Guide ID: ${careGuideId}  ID: ${id}.`
      );
      dispatch(
        careGuideNoteDeleteFailure(
          careGuideId,
          response.getIn(['data', 'error'])
        )
      );
    }

    // callback function
    cb(success);
  };
}

export function patchCareGuideNote(
  careGuideId = null,
  id = null,
  data = {},
  cb = function () {}
) {
  Logger.log(
    'debug',
    `[state.careGuideNotes.actions] patchCareGuideNote(${careGuideId}, ${id}, %j, ###)`,
    data
  );

  return async function (dispatch) {
    dispatch(careGuideNotePatchRequest(careGuideId, id, data));

    // call API
    const response = await api.patchCareGuideNote(careGuideId, id, data);
    let success = false;

    // patch care guide note success
    if (200 === response.get('status')) {
      Logger.log(
        'info',
        `Patch API care guide note success. Care Guide ID: ${careGuideId} ID: ${id}.`
      );

      const normalizedEntities = normalize(
        [response.getIn(['data', 'care_guide_note'])],
        [schema.careGuideNote]
      );
      const data = {
        id: response.getIn(['data', 'care_guide_note', 'id']),
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(careGuideNotePatchSuccess(careGuideId, id, data));
      success = true;

      // patch care guide note failure
    } else {
      Logger.log(
        'info',
        `Patch API care guide note failure. Care Guide ID: ${careGuideId} ID: ${id}.`
      );
      dispatch(
        careGuideNotePatchFailure(
          careGuideId,
          id,
          response.getIn(['data', 'error'])
        )
      );
    }

    // callback function
    cb(success);
  };
}

export function downloadCareGuideNotesPDF(
  partnershipName = null,
  careGuideId = null,
  payload = {},
  order = null,
  filter = null,
  cb = function () {}
) {
  Logger.log(
    'debug',
    `[state.careGuideNotes.actions] downloadCareGuideNotesPDF(${careGuideId}, ${order}, %j, %j)`,
    payload,
    filter
  );

  return async function (dispatch) {
    dispatch(careGuideNotesPDFDownloadRequest(careGuideId, order, filter));

    // call API
    const response = await api.postCareGuideNotesPDF(
      careGuideId,
      payload,
      order,
      filter
    );
    let success = false;

    // get care guide notes PDF success
    if (200 === response.get('status')) {
      // trigger browser download
      const url = window.URL.createObjectURL(
        new Blob([response.get('data')], { type: 'application/pdf' })
      );
      const link = document.createElement('a');
      link.href = url;

      link.setAttribute(
        'download',
        `cv-${partnershipName}--care-guide-note.pdf`
      );
      document.body.appendChild(link);
      link.click();
      dispatch(careGuideNotesPDFDownloadSuccess());
      success = true;

      // get care guide notes PDF failure
    } else {
      Logger.log('info', `Get API care guide notes PDF failure.`);
      dispatch(careGuideNotesPDFDownloadFailure());
    }

    // callback function
    cb(success);
  };
}

Logger.log('silly', `state.careGuideNotes.actions loaded.`);
