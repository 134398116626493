import {connect} from 'react-redux';
import {List} from 'immutable';

import {
  loadJournalPartnerTagAdmin,
  updateJournalPartnerTagAdmin,
  createJournalPartnerTagAdmin,
  deleteJournalPartnerTagAdmin,
  journalPartnerTagAdminFormDestroy
} from '../../../../../state/modules/journalPartnerTagsAdmin/actions';
import JournalPartnerTagAdminForm from '../components/JournalPartnerTagAdminForm';
import storage from "../../../../../lib/Storage";

const inputs = List([
  'label',
  'status',
  'partnership_id'
]);

const mapStateToProps = (state, ownProps) => {


  const data = {}, errors = {};
  for (const val of inputs.values()) {
    data[val] = state.entities.getIn(['journalPartnerTagsAdmin', ownProps.id, val], '');
    errors[val] = state.journalPartnerTagsAdmin.getIn(['form', 'errors', val]);
  }

  // change select number values to strings to work with ant design select inputs
  Object.keys(data).forEach(x => {
    if (['status'].includes(x)) {
      data[x] = data[x].toString();
    }
  });

  return {
    isLoading: state.journalPartnerTagsAdmin.get('isLoading'),
    isSubmitting: state.journalPartnerTagsAdmin.getIn(['form', 'isSubmitting']),
    success: state.journalPartnerTagsAdmin.getIn(['form', 'success']),
    created_id: state.journalPartnerTagsAdmin.getIn(['form', 'created_id']),
    data: data,
    errors: errors,
    createdAt: state.entities.getIn(['journalPartnerTagsAdmin', ownProps.id, 'created_at'], null),
    updatedAt: state.entities.getIn(['journalPartnerTagsAdmin', ownProps.id, 'updated_at'], null),
    activeId: storage.get('local', 'partnershipId'),

  }
}

const mapDispatchToProps = dispatch => {
  return {
    load: (partnership_id, id, cb) => {
      dispatch(loadJournalPartnerTagAdmin(partnership_id, id, cb));
    },
    update: (id, data, cb) => {
      dispatch(updateJournalPartnerTagAdmin(id, data, cb));
    },
    create: (data, cb) => {
      dispatch(createJournalPartnerTagAdmin(data, cb));
    },
    delete: (partnership_id, id, cb) => {
      dispatch(deleteJournalPartnerTagAdmin(partnership_id, id, cb))
    },
    destroyForm: (formState) => {
      dispatch(journalPartnerTagAdminFormDestroy(formState));
    },
   
  }
}

const JournalPartnerTagAdminFormContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(JournalPartnerTagAdminForm);

export default JournalPartnerTagAdminFormContainer;