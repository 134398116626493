import {connect} from 'react-redux';

import {updateAccountPlan} from '../../../../../state/modules/accounts/actions';
import PlanForm from '../components/PlanForm';

const mapStateToProps = (state) => {
  return {
    isLoading: state.accounts.get('isLoading'),
    isSubmitting: state.accounts.getIn(['planForm', 'isSubmitting']),
  }
}

const mapDispatchToProps = dispatch => {
  return {
    update: (id, data, cb) => {
      dispatch(updateAccountPlan(id, data, cb));
    },
  }
}

const PlanFormContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(PlanForm);

export default PlanFormContainer;
