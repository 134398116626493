import React, { useState } from 'react';
import { Translation } from 'react-i18next';
import { Modal, Typography, Button } from 'antd';
import ServiceTagInput from '../../resourceServiceTags/containers/ServiceTagInputContainer';
import Logger from '../../../../../lib/Logger';

const { Title } = Typography;

const ResourceModalFilters = ({
  setVisible,
  visible,
  onChangeServiceTagsHandler,
  ...props
}) => {
  const [serviceTags, setServiceTags] = useState([]);

  const handleApplyFilters = () => {
    onChangeServiceTagsHandler(serviceTags);
    setVisible(false);
  };

  const onChangeServiceTags = (value) => {
    setServiceTags(value);
  };

  return (
    <Translation>
      {(t) => (
        <>
          <Modal
            title={
              <Title level={3} className="resource-filters-title">
                {t('resources_filters')}
              </Title>
            }
            centered
            className="resource-filters"
            visible={visible}
            onCancel={() => setVisible(false)}
            footer={
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  width: '100%',
                }}
              >
                <Button
                  block
                  size={'large'}
                  key="back"
                  onClick={() => setVisible(false)}
                  style={{ marginRight: '8px', flex: 1 }}
                >
                  {t('resources_filters_btn_cancel')}
                </Button>

                <Button
                  block
                  size={'large'}
                  key="submit"
                  onClick={handleApplyFilters}
                  style={{ marginLeft: '8px', flex: 1 }}
                  type="primary"
                >
                  {t('resources_filters_btn_apply')}
                </Button>
              </div>
            }
            width={600}
            bodyStyle={{ paddingTop: 0, height: '260px' }}
            forceRender={true}
            zIndex={1}
          >
            <div style={{ marginTop: '20px' }}>
              <Title level={5}>
                {t('resources_filters_resource_category')}
              </Title>

              <ServiceTagInput
                label={null}
                onChange={onChangeServiceTags}
                placeholder={t('resource_form_input_service_tags')}
              />
            </div>
          </Modal>
        </>
      )}
    </Translation>
  );
};

export default ResourceModalFilters;

Logger.log('silly', `ResourceModalFilters loaded.`);
