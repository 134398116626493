import {normalize} from 'normalizr';

import api from '../../api';
import {schema} from '../../schema';
import {addEntities} from '../../actions';
import Logger from '../../../lib/Logger';

export const TYPES = {
  LIST_REQUEST: 'SEARCH/LIST_REQUEST',
  LIST_SUCCESS: 'SEARCH/LIST_SUCCESS',
  LIST_FAILURE: 'SEARCH/LIST_FAILURE',
};

export function searchListRequest(careGuideId, q, page, limit) {
  Logger.log('debug', `[state.search.actions] searchListRequest(${careGuideId}, ${q}, ${page}, ${limit})`);
  return {
    type: TYPES.LIST_REQUEST,
    careGuideId: careGuideId,
    q: q,
    page: page,
    limit: limit,
  }
}

export function searchListSuccess(careGuideId, data) {
  Logger.log('debug', `[state.search.actions] searchListSuccess(${careGuideId}, %j)`, data);
  return {
    type: TYPES.LIST_SUCCESS,
    careGuideId: careGuideId,
    page: data.page,
    limit: data.limit,
    journalPosts: data.journalPosts,
    calendarEvents: data.calendarEvents,
    messages: data.messages,
    resources: data.resources,
    careGuideHelpers: data.careGuideHelpers,
    careGuides: data.careGuides,
    journalPostsTotal: data.journalPostsTotal,
    calendarEventsTotal: data.calendarEventsTotal,
    messagesTotal: data.messagesTotal,
    resourcesTotal: data.resourcesTotal,
    careGuideHelpersTotal: data.careGuideHelpersTotal,
    careGuidesTotal: data.careGuidesTotal,
    receivedAt: Date.now()
  }
}

export function searchListFailure(careGuideId, error) {
  Logger.log('debug', `[state.search.actions] searchListFailure(${careGuideId}, %j)`, error);
  return {
    type: TYPES.LIST_FAILURE,
    careGuideId: careGuideId,
    error: error
  }
}


// API THUNK ACTION CREATORS

export function loadSearch(careGuideId, q, page=1, limit=10, cb=function(){}) {
  Logger.log('debug', `[state.search.actions] loadSearch(${careGuideId}, ${q}, ${page}, ${limit}, ###)`);

  let success = false;

  return async function(dispatch) {
    dispatch(searchListRequest(careGuideId, q, page, limit));

    // if q is empty, don't make an API request
    if (!q) {

      Logger.log('info', `Empty search query. Care Guide ID: ${careGuideId}, Query: ${q}, Page: ${page}, Limit: ${limit}.`);
      const data = {
        page: page,
        limit: limit,
        journalPostsTotal: 0,
        calendarEventsTotal: 0,
        messagesTotal: 0,
        resourcesTotal: 0,
        careGuideHelpersTotal: 0,
        careGuidesTotal: 0,
        journalPosts: [],
        calendarEvents: [],
        messages: [],
        resources: [],
        careGuideHelpers: [],
        careGuides: [],
      };
      dispatch(searchListSuccess(careGuideId, data));
      success = true;

    } else {

      // call API
      const response = await api.getCareGuideSearch(careGuideId, q, page, limit);

      // get search list success
      if (200 === response.get('status')) {

        Logger.log('info', `Get API search list success. Care Guide ID: ${careGuideId}, Query: ${q}, Page: ${page}, Limit: ${limit}.`);

        const normalizedJournalPosts = normalize(response.getIn(['data', 'journal_posts', 'results']), [schema.journalPost]);
        const normalizedCalendarEvents = normalize(response.getIn(['data', 'calendar_events', 'results']), [schema.calendarEvent]);
        const normalizedMessages = normalize(response.getIn(['data', 'messages', 'results']), [schema.message]);
        const normalizedResources = normalize(response.getIn(['data', 'resources', 'results']), [schema.resource]);
        const normalizedCareGuideHelpers = normalize(response.getIn(['data', 'care_guide_helpers', 'results']), [schema.careGuideHelper]);
        const normalizedCareGuides = normalize(response.getIn(['data', 'care_guides', 'results']), [schema.careGuide]);
        
        const data = {
          page: response.getIn(['data', 'page']),
          limit: response.getIn(['data', 'limit']),
          journalPostsTotal: response.getIn(['data', 'journal_posts', 'total']),
          calendarEventsTotal: response.getIn(['data', 'calendar_events', 'total']),
          messagesTotal: response.getIn(['data', 'messages', 'total']),
          resourcesTotal: response.getIn(['data', 'resources', 'total']),
          careGuideHelpersTotal: response.getIn(['data', 'care_guide_helpers', 'total']),
          careGuidesTotal: response.getIn(['data', 'care_guides', 'total']),
          journalPosts: normalizedJournalPosts.result,
          calendarEvents: normalizedCalendarEvents.result,
          messages: normalizedMessages.result,
          resources: normalizedResources.result,
          careGuideHelpers: normalizedCareGuideHelpers.result,
          careGuides: normalizedCareGuides.result
        };

        dispatch(addEntities(normalizedJournalPosts));
        dispatch(addEntities(normalizedCalendarEvents));
        dispatch(addEntities(normalizedMessages));
        dispatch(addEntities(normalizedResources));
        dispatch(addEntities(normalizedCareGuideHelpers));
        dispatch(addEntities(normalizedCareGuides));
        dispatch(searchListSuccess(careGuideId, data));
        success = true;

      } else if (1 === page && 204 === response.get('status')) {

        Logger.log('info', `Get API search list success [empty]. Care Guide ID: ${careGuideId}, Query: ${q}, Page: ${page}, Limit: ${limit}.`);
        const data = {
          page: page,
          limit: limit,
          journalPostsTotal: 0,
          calendarEventsTotal: 0,
          messagesTotal: 0,
          resourcesTotal: 0,
          careGuideHelpersTotal: 0,
          careGuidesTotal: 0,
          journalPosts: [],
          calendarEvents: [],
          messages: [],
          resources: [],
          careGuideHelpers: [],
          careGuides: [],
        };
        dispatch(searchListSuccess(careGuideId, data));
        success = true;
        
      // get resources list failure
      } else {
        Logger.log('info', `Get API search list failure. Care Guide ID: ${careGuideId}, Query: ${q}, Page: ${page}, Limit: ${limit}.`);
        dispatch(searchListFailure(careGuideId, response.getIn(['data', 'error'])));
      }
    }

    // callback function
    cb(success);
  }
}

Logger.log('silly', `state.search.actions loaded.`);
