import { normalize } from 'normalizr';

import api from '../../api';
import { schema } from '../../schema';
import { addEntities } from '../../actions';
import Logger from '../../../lib/Logger';

// ACTION TYPES

export const TYPES = {
  ADMIN_PARTNER_CLASS_PARTNERSHIP_LIST_REQUEST:
    'ADMIN_PARTNER_CLASS_PARTNERSHIP_LIST_REQUEST',
  ADMIN_PARTNER_CLASS_PARTNERSHIP_LIST_SUCCESS:
    'ADMIN_PARTNER_CLASS_PARTNERSHIP_LIST_SUCCESS',
  ADMIN_PARTNER_CLASS_PARTNERSHIP_LIST_FAILURE:
    'ADMIN_PARTNER_CLASS_PARTNERSHIP_LIST_FAILURE',
  FILTERS_REQUEST: 'FILTERS_REQUEST',
  FILTERS_SUCCESS: 'FILTERS_SUCCESS',
  FILTERS_FAILURE: 'FILTERS_FAILURE',
};

// ACTION CREATORS

export function adminPartnerClassPartnershipListRequest(partnershipId) {
  Logger.log(
    'debug',
    `[adminPartnerClasses.actions] adminPartnerClassPartnershipListRequest(${partnershipId})`
  );
  return {
    type: TYPES.ADMIN_PARTNER_CLASS_PARTNERSHIP_LIST_REQUEST,
    partnershipId: partnershipId,
  };
}

export function adminPartnerClassPartnershipListSuccess(partnershipId, data) {
  Logger.log(
    'debug',
    `[adminPartnerClasses.actions] adminPartnerClassPartnershipListSuccess(${partnershipId}, %j)`,
    data
  );
  return {
    type: TYPES.ADMIN_PARTNER_CLASS_PARTNERSHIP_LIST_SUCCESS,
    partnershipId: partnershipId,
    result: data.result,
    total: data.total,
    receivedAt: Date.now(),
  };
}

export function adminPartnerClassPartnershipListFailure(partnershipId, error) {
  Logger.log(
    'debug',
    `[adminPartnerClasses.actions] adminPartnerClassPartnershipListFailure(${partnershipId}, %j)`,
    error
  );
  return {
    type: TYPES.ADMIN_PARTNER_CLASS_PARTNERSHIP_LIST_FAILURE,
    partnershipId: partnershipId,
    error: error,
  };
}

export function filtersRequest() {
  Logger.log('debug', `[state.adminPartnerClasses.actions] filtersRequest()`);
  return {
    type: TYPES.FILTERS_REQUEST,
  };
}

export function filtersSuccess(data) {
  Logger.log(
    'debug',
    `[state.adminPartnerClasses.actions] filtersSuccess(%j)`,
    data
  );
  return {
    type: TYPES.FILTERS_SUCCESS,
    data: data,
  };
}

export function filtersFailure(error) {
  Logger.log(
    'debug',
    `[state.adminPartnerClasses.actions] filtersFailure(%j)`,
    error
  );
  return {
    type: TYPES.FILTERS_FAILURE,
    error: error,
  };
}

// API THUNK ACTION CREATORS

export function loadAdminPartnerClassPartnershipRegister(
  partnershipId = null,
  cb = function () {}
) {
  Logger.log(
    'debug',
    `[adminPartnerClasses.actions] loadAdminPartnerClassPartnershipRegister(${partnershipId}`
  );

  return async function (dispatch) {
    dispatch(adminPartnerClassPartnershipListRequest(partnershipId));

    // call API
    const response = await api.getAdminPartnerClassesRegister(partnershipId);

    let success = false;

    // get admin partner class partnership list success
    if (200 === response.get('status')) {
      Logger.log(
        'info',
        `Get API admin partner class partnership list success. ID: ${partnershipId}`
      );

      const normalizedEntities = normalize(
        response.getIn(['data', 'admin_partner_classes']),
        [schema.adminPartnerClass]
      );

      const data = {
        total: response.getIn(['data', 'total']),
        result: normalizedEntities.result,
      };
      dispatch(addEntities(normalizedEntities));
      dispatch(adminPartnerClassPartnershipListSuccess(partnershipId, data));
      success = true;
    } else if (204 === response.get('status')) {
      Logger.log(
        'info',
        `Get API admin partner class partnership success [empty]. ID: ${partnershipId}.`
      );
      const data = {
        total: 0,
        result: [],
      };
      dispatch(adminPartnerClassPartnershipListSuccess(partnershipId, data));
      success = true;

      // get admin partner class partnership list failure
    } else {
      Logger.log(
        'info',
        `Get API admin partner class partnership list failure. ID: ${partnershipId}.`
      );
      dispatch(
        adminPartnerClassPartnershipListFailure(
          partnershipId,
          response.getIn(['data', 'error'])
        )
      );
    }

    // callback function
    cb(success);
  };
}

export function loadCareGuideOwnersFilters(
  partnership_id = null,
  cb = function () {}
) {
  Logger.log(
    'debug',
    `[state.adminPartnerClasses.actions] loadCareguideOwnersFilters(###)`
  );

  return async function (dispatch) {
    dispatch(filtersRequest());

    // call API
    const response = await api.getCareGuideOwnersFilters(partnership_id);
    let success = false;

    // get care guide owners filters success
    if (200 === response.get('status')) {
      Logger.log('info', `Get API care guide owners filters success.`);

      const data = response.getIn(['data', 'admin_partner_classes']);

      dispatch(filtersSuccess(data));
      success = true;

      // get care guide owners filters failure
    } else {
      Logger.log('info', `Get API filters failure.`);
      dispatch(filtersFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  };
}

Logger.log('silly', `adminPartnerClasses.actions loaded.`);
