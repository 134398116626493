import { connect } from 'react-redux';
import { List } from 'immutable';
import {
  resourceFormDestroy,
  resourceHideForm,
} from '../../../../../state/modules/resources/actions';

import { createResourceAdmin } from '../../../../../state/modules/resourcesAdmin/actions';

import {
  getImageUploadURL,
  uploadImage,
  createMediaImageCareGuidePartnership,
  deleteMediaImagePartnership,
  createMediaImagePartnership,
} from '../../../../../state/modules/media/actions';

import { loadTotalMembers } from '../../../../../state/modules/careGuides/actions';

import MemberResourceForm from '../components/MemberResourceForm';
import storage from '../../../../../lib/Storage';

const inputs = List([
  'name',
  'description',
  'telephone',
  'website',
  'email',
  'tags',
  'service_tags',
  'logo',
  'is_pinned',
  'care_guide',
  'care_guides',
  'resources_care_partner_tags',
]);

const mapStateToProps = (state, ownProps) => {
  const data = {},
    errors = {};
  for (const val of inputs.values()) {
    data[val] = state.entities.getIn(
      ['resources', state.resources.get('formId'), val],
      ''
    );
    if (state.resources.getIn(['form', 'errors', val])) {
      errors[val] = state.resources.getIn(['form', 'errors', val]);
    }
  }

  const care_guide_id = state.resources.get('formId');

  if (care_guide_id) {
    data['care_guides'] = [care_guide_id];
  } else {
    const careGuideIds = ownProps.careGuideIds;

    if (Array.isArray(careGuideIds)) {
      data['care_guides'] = careGuideIds;
    } else {
      data['care_guides'] = [];
    }
  }

  if (data['resources_care_partner_tags'] === '') {
    data['resources_care_partner_tags'] = [];
  }

  if (data['tags'] === '') {
    data['tags'] = [];
  }

  if (data['service_tags'] === '') {
    data['service_tags'] = [];
  }

  return {
    visible: state.resources.get('formIsVisible'),
    isSubmitting: state.resources.getIn(['form', 'isSubmitting']),
    success: state.resources.getIn(['form', 'success']),
    totalMembers: state.careGuides.get('totalMembers'),
    totalMembersFiltered: state.careGuides.get('totalMembersFiltered'),
    idsFiltered: state.careGuides.get('idsFiltered'),
    data: data,
    errors: errors,
    partnershipId: storage.get('local', 'partnershipId'),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    create: (careGuideId, data, cb) => {
      dispatch(createResourceAdmin(careGuideId, data, cb));
    },
    loadTotalMembers: (partnershipId, filter, cb) => {
      dispatch(loadTotalMembers(partnershipId, filter, cb));
    },

    formDestroy: (formState) => {
      dispatch(resourceFormDestroy(formState));
    },
    hideForm: () => {
      dispatch(resourceHideForm());
    },
    getUploadUrl: (extension, directory, cb) => {
      dispatch(getImageUploadURL(extension, directory, cb));
    },
    upload: (path, file, cb) => {
      dispatch(uploadImage(path, file, cb));
    },
    createImage: (careGuideId, partnershipId, data, cb) => {
      dispatch(
        createMediaImageCareGuidePartnership(
          careGuideId,
          partnershipId,
          data,
          cb
        )
      );
    },
    createImagePartnership: (partnershipId, data, cb) => {
      dispatch(createMediaImagePartnership(partnershipId, data, cb));
    },

    deleteImage: (partnershipId, id, cb) => {
      dispatch(deleteMediaImagePartnership(partnershipId, id, cb));
    },
  };
};

const MemberResourceFormContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(MemberResourceForm);

export default MemberResourceFormContainer;
