import React from 'react';
import {Translation} from 'react-i18next';

import PartnershipsSelector from '../../modules/userProfilesPartnerships/containers/PartnershipsSelectorContainer';
import Logger from '../../../../lib/Logger';
import {getRouteFromPath} from '../../Routes';


const PageHeaderPartnership = ({pathname, ...props}) => {
  const route = getRouteFromPath(pathname);
  
  if (!route || ! route.route || route.route.length < 5) {
    return null;
  }
  return (
    <Translation>{(t) => 
      <>
        {props.partnershipsCount && props.partnershipsCount >= 1
          ? <h1 className="page-header">
               {t('layout_header_partnership')} <PartnershipsSelector currentPath={pathname} page_partnership={route.params?.page_partnership ? route.params.page_partnership : 1}/>
            </h1>
          : null}
      </>
    }</Translation>
  )
}

export default PageHeaderPartnership;

Logger.log('silly', `PageHeaderPartnership loaded.`);
