import store from "../../../../state/store";

export const hasPermissionCareGuide = () => {
  const state = store.getState();

  const access = state.careGuides.get("accessCareGuide");

  if (access) {
    if (access.journal_post) {
      return "journal_post";
    } else if (access.care_guide) {
      return "care_guide";
    } else if (access.calendar) {
      return "calendar";
    } else if (access.care_team) {
      return "care_team";
    } else if (access.resources) {
      return "resources";
    }
    }
  return null;
};

export const pathRedirectCareGuide = (access) => {
  switch (access) {
    case "journal_post":
      return "JournalPostsScreen";
    case "care_guide":
      return "CareGuideDetailScreen";
    case "calendar":
      return "CalendarScreen";
    case "care_team":
      return "CareTeamScreen";
    case "resources":
      return "ResourcesScreen";
    default:
      return null;
  }
};
