import CareGuideNoteModalExport from '../components/CareGuideNoteModalExport';
import { downloadCareGuideNotesPDF } from '../../../../../state/modules/careGuideNotes/actions';
import { connect } from 'react-redux';
import { denormalize } from 'normalizr';
import { schema } from '../../../../../state/schema';
import storage from '../../../../../lib/Storage';

const mapStateToProps = (state, ownProps) => {
  let allPageCareGuideNoteIds = [];

  const order = ownProps.order ? ownProps.order : 'started_at.asc';
  const limit = ownProps.limit ? ownProps.limit : 10;
  const page = ownProps.page ? ownProps.page : 1;
  const careGuideId = ownProps.careGuideId;
  const result = state.careGuideNotes.getIn([
    'pages',
    careGuideId,
    order,
    limit,
    page,
  ]);

  const list = result
    ? result
        .map((x) => {
          allPageCareGuideNoteIds.push(x);
          return {
            key: x,
            ...denormalize(
              state.entities.getIn(['careGuideNotes', x]),
              schema.careGuideNote,
              state.entities.toJS()
            ),
          };
        })
        .toArray()
    : [];

  return {
    list,
    allPageCareGuideNoteIds,
    partnershipName: storage.get('local', 'partnershipData')?.name,
    isDownloading: state.careGuideNotes.get('isPDFDownloading'),
    total: state.careGuideNotes.get('total')
      ? state.careGuideNotes.get('total')
      : 0,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    download: (partnershipName, careGuideId, payload, order, filter, cb) => {
      dispatch(
        downloadCareGuideNotesPDF(
          partnershipName,
          careGuideId,
          payload,
          order,
          filter,
          cb
        )
      );
    },
  };
};

const CareGuideNoteModalExportContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(CareGuideNoteModalExport);

export default CareGuideNoteModalExportContainer;
