import UserAvatar from '../../../elements/components/UserAvatar';
import React from 'react';
import notification from '../../../elements/lib/NotificationWrapper';
import Format from '../../../../../lib/Format';
import Config from '../../../../../Config';
import CardActions from './CardActions';
import { Translation, getI18n } from 'react-i18next';
import { Space, Row, Col, Button, Typography } from 'antd';
import { pinFilled, arrowRight } from '../../../elements/components/Icons';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import '../styles/CareGuideNote.less';

const { Text, Title } = Typography;

const CareGuideNoteListCard = ({
  showForm,
  load,
  order,
  limit,
  filter,
  page,
  handleToggle,
  selectId,
  patch,
  careGuideId,
  item,
  remove,
  profileId,
  ...props
}) => {
  const handlePinned = (id, is_pinned) => {
    const payload = { is_pinned: !is_pinned };

    patch(careGuideId, id, payload, (success) => {
      if (success) {
        notification.success(
          getI18n().t('care_guide_note_message_form_success')
        );
      } else {
        notification.error(
          getI18n().t('care_guide_note_message_form_failure_update')
        );
      }
    });
  };
  const handleDelete = (id) => {
    remove(careGuideId, id, (success) => {
      if (success) {
        const filterString = JSON.stringify(filter);
        load(careGuideId, page, limit, order, JSON.parse(filterString));
        notification.success(
          getI18n().t('care_guide_note_message_form_success_delete')
        );
      } else {
        notification.error(
          getI18n().t('care_guide_note_message_form_failure_delete')
        );
      }
    });
  };

  const modules = {
    toolbar: '#toolbar-read-only',
  };

  return (
    <Translation>
      {(t) => (
        <>
          <div className="notes-list-content-card">
            <Row justify="space-between">
              <Col>
                <Space align="center">
                  <Button
                    className={`icon-button ${
                      item.id === selectId ? 'arrow-down' : 'arrow-right'
                    } icon-transition`}
                    type="text"
                    icon={arrowRight(10, 17)}
                    onClick={() => handleToggle(item.id)}
                  />
                  <UserAvatar profile={item.profile} />
                  <Title style={{ marginTop: '10px' }} level={5}>
                    {`${item.profile.first_name} ${item.profile.last_name}`}
                  </Title>
                  <Text type="secondary" style={{ color: '#7B7B7B' }}>
                    {Format.date(
                      item.edited_at,
                      Config.get('DEFAULT_DATETIME_FORMAT')
                    )}
                  </Text>
                </Space>
              </Col>
              <Col>
                {profileId === item.profile.id && (
                  <>
                    <Button
                      className={
                        item.is_pinned
                          ? 'icon-button-active'
                          : 'icon-button-disabled'
                      }
                      icon={pinFilled(12, 15)}
                      size={'large'}
                      type="text"
                      onClick={() => handlePinned(item.id, item.is_pinned)}
                    />
                    <CardActions
                      showForm={showForm}
                      handleDelete={handleDelete}
                      handlePinned={handlePinned}
                      is_pinned={item.is_pinned}
                      id={item.id}
                    />
                  </>
                )}
              </Col>
            </Row>
            <div className="box">
              <div id="toolbar-read-only"></div>
              <ReactQuill
                modules={modules}
                readOnly
                className={`text ${
                  item.id === selectId ? 'text-expanded' : ''
                } note-content-readonly`}
                value={item.body}
              />
            </div>
          </div>
        </>
      )}
    </Translation>
  );
};

// Ejemplo de u
export default CareGuideNoteListCard;
