import { Badge, Col, Form, Row, Select, Space, Tag, Typography } from 'antd';
import Title from 'antd/lib/typography/Title';
import React from 'react';
import { Translation } from 'react-i18next';
import UserAvatar from '../../../elements/components/UserAvatar';
import Format from '../../../../../lib/Format';
const { Text } = Typography;

const MemberProfileCard = ({
  children,
  data,
  tagText,
  smallVariant,
  isEditionMode,
}) => {
  const displayPersonalInfo = data?.email || data?.telephone || data?.username;
  const displayCareMemberInfo = data?.relationship;

  const isActiveUser = data?.status === 1;

  return (
    <Translation>
      {(t) => (
        <Row className="member-content-panel">
          <Col flex="auto">
            <Row className={`${isEditionMode ? 'edition-mode' : ''} info-row`}>
              {data?.first_name && (
                <UserAvatar size={smallVariant ? 76 : 112} profile={data} />
              )}
              <Col className="member-basic-data-content">
                <Title
                  style={{ color: '#10407D' }}
                  level={3}
                >{`${data?.first_name} ${data?.last_name}`}</Title>
                <Tag color="#5399DD" style={{ borderRadius: '50px' }}>
                  {tagText}
                </Tag>

                {!smallVariant && (
                  <Form.Item name="subscription_status">
                    <Select
                      className="member-profile-status-select"
                      disabled={!isEditionMode}
                      bordered={isEditionMode}
                      showArrow={isEditionMode}
                      size={isEditionMode ? 'large' : 'small'}
                    >
                      <Select.Option value={1}>
                        <span
                          style={{
                            color: '#22C55E',
                            fontSize: '16px',
                          }}
                        >
                          <Badge color="#22C55E" />
                          {t('member_profile_state_active')}
                        </span>
                      </Select.Option>
                      <Select.Option
                        key={isActiveUser ? 3 : data?.status}
                        value={isActiveUser ? 3 : data?.status}
                      >
                        <span
                          style={{
                            color: '#A5A5A5',
                            fontSize: '16px',
                          }}
                        >
                          <Badge color={'#A5A5A5'} className="status-badge" />
                          {t('member_profile_state_pending')}
                        </span>
                      </Select.Option>
                    </Select>
                  </Form.Item>
                )}
              </Col>
            </Row>
            {children}
            {displayPersonalInfo && (
              <Space size={20} style={{ padding: '20px', paddingTop: '0' }}>
                <Row
                  align="top"
                  justify="space-between"
                  className="member-data-row"
                  flex={'auto'}
                >
                  <Title level={5}>
                    {t('member_profile_personal_information')}
                  </Title>
                </Row>
                {data?.username && (
                  <Row
                    align="center"
                    justify="space-between"
                    className="member-data-row"
                    flex={'auto'}
                  >
                    <p className="info-subititle">
                      {t('member_profile_user_name')}
                      <span>{data?.username}</span>
                    </p>
                  </Row>
                )}
                {data?.email && (
                  <Row
                    align="center"
                    justify="space-between"
                    className="member-data-row"
                    flex={'auto'}
                  >
                    <p className="info-subititle">
                      {t('member_profile_email')}
                      <span>{data?.email}</span>
                    </p>
                  </Row>
                )}
                {data?.telephone && (
                  <Row
                    align="center"
                    justify="space-between"
                    className="member-data-row"
                    flex={'auto'}
                  >
                    <p className="info-subititle">
                      {t('member_profile_phone_number')}
                      <span>{data?.telephone}</span>
                    </p>
                  </Row>
                )}
              </Space>
            )}
            {displayCareMemberInfo && (
              <Space style={{ paddingTop: '0' }}>
                <Row
                  align="top"
                  style={{
                    paddingLeft: '20px',
                  }}
                  flex={'auto'}
                >
                  <Title level={5}>
                    {t('member_profile_care_member_information')}
                  </Title>
                </Row>

                {data?.created_at && (
                  <>
                    <Row justify="start" style={{ gap: '28px' }}>
                      <Text
                        className="info-subititle"
                        style={{
                          paddingLeft: '20px',
                          paddingBottom: '20px',
                          width: smallVariant ? '110px' : '112px',
                        }}
                      >
                        {t('member_profile_created_at')}
                      </Text>

                      <Text
                        className="info-subititle"
                        style={{
                          paddingBottom: '20px',
                        }}
                      >
                        {Format.dateTable(data?.created_at)}
                      </Text>
                    </Row>
                  </>
                )}

                {data?.postal_code && (
                  <>
                    <Row justify="start" style={{ gap: '28px' }}>
                      <Text
                        className="info-subititle"
                        style={{
                          paddingLeft: '20px',
                          paddingBottom: '20px',
                          width: smallVariant ? '110px' : '112px',
                        }}
                      >
                        {t('member_profile_postal_code')}
                      </Text>

                      <Text
                        className="info-subititle"
                        style={{
                          paddingBottom: '20px',
                        }}
                      >
                        {data?.postal_code?.postal_code}
                      </Text>
                    </Row>
                  </>
                )}

                {data?.relationship && (
                  <>
                    <Row justify="start" style={{ gap: '28px' }}>
                      <Text
                        className="info-subititle"
                        style={{
                          paddingLeft: '20px',
                          paddingBottom: '20px',
                          width: smallVariant ? '110px' : '112px',
                        }}
                      >
                        {t('member_profile_relationship')}
                      </Text>

                      <Text
                        className="info-subititle"
                        style={{
                          paddingBottom: '20px',
                        }}
                      >
                        {data?.relationship?.name}
                      </Text>
                    </Row>
                  </>
                )}
              </Space>
            )}
          </Col>
        </Row>
      )}
    </Translation>
  );
};

export default MemberProfileCard;
