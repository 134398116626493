import React from "react";
import QueryString from "../../../../../lib/QueryString";
import CareGuideNoteListTags from "./CareGuideNoteListTags";
import CareGuideNoteListHeader from "./CareGuideNoteListHeader";
import CareGuideNoteListContent from "../containers/CareGuideNoteListContentContainer";
import { withRouter } from "react-router";
import { Translation } from "react-i18next";
import { pathTo } from '../../../Routes';
import { Divider, Col } from "antd";

const CareGuideNoteList = ({ component, page, limit, setPage, careGuideId, history, filter, order, showForm, ...props }) => {

  let params = QueryString.parse(history.location.search);
  const dateStart = 'start' in params ? params['start'] : null;
  const dateEnd = 'end' in params ? params['end'] : null;

  const handleResetDate = () => {
    const path = pathTo(component, { id: careGuideId });
    delete params['start'];
    delete params['end'];
    history.push(QueryString.append(path, params));
  }

  return (
    <Translation>{(t) =>
      <Col span={24} flex={"auto"} className="notes-content-panel">
        <CareGuideNoteListHeader
          component={component}
          order={order}
          limit={limit}
          filter={filter}
          page={page}
          careGuideId={careGuideId}
          showForm={showForm}
        />
        <Divider style={{ margin: "10px 0" }} />
        {dateStart && dateEnd && <CareGuideNoteListTags dateStart={dateStart} dateEnd={dateEnd} handleResetDate={handleResetDate} />}
        <CareGuideNoteListContent
          order={order}
          limit={limit}
          filter={filter}
          page={page}
          component={component}
          careGuideId={careGuideId}
        />

      </Col>
    }</Translation>
  );
};

export default withRouter(CareGuideNoteList);
