import React, {Component} from 'react';
import {Translation} from 'react-i18next';
import {Card, Col, Row} from 'antd';

import CareGuideDisabled from '../../careGuides/components/CareGuideDisabled';
import CareGuideWrapper from '../../../layouts/components/CareGuideWrapper';
import JournalPostDetail from './JournalPostDetail';
import JournalPostForm from '../containers/JournalPostFormContainer';
import JournalPostsList from '../containers/JournalPostsListContainer';
import JournalPostsFilter from '../containers/JournalPostsFilterContainer';
import JournalPostModalForm from '../containers/JournalPostModalFormContainer';
import JournalReplyModalForm from '../../journalReplies/containers/JournalReplyModalFormContainer';
import Loading from '../../../elements/components/Loading';
import DocumentHead from '../../../elements/components/DocumentHead';
import QueryString from '../../../../../lib/QueryString';
import scrollToTop from '../../../elements/lib/scrollToTop';
import hasPermission, { CREATE_JOURNAL_POST, VIEW_ACCESS_CARE_GUIDE } from '../../../elements/lib/hasPermission';
import Config from '../../../../../Config';
import Logger from '../../../../../lib/Logger';
import { Redirect } from "react-router-dom";
import {pathTo} from '../../../Routes';

class JournalPostsScreen extends Component {

  static defaultProps = {
    limit: Config.get('DEFAULT_LIST_LENGTH', 10),
    isLoading: true,
  }

  render() {
    const {careGuideId, careGuideParnetshipStatus, page, limit, journal, isCareGuideActive, isCareGuideLoading} = this.props;
    const params = QueryString.parse(this.props.location.search);

    if (isCareGuideLoading) {
      return (
        <Translation>{(t) => 
          <CareGuideWrapper>
            <DocumentHead title={t('journal_posts_route_list')} />
            <Loading />
          </CareGuideWrapper>
        }</Translation>
      );
    }
    if(isCareGuideActive === null){
      return (
        <Redirect to={pathTo("HomeScreen")} />
      );
    }
    if (!isCareGuideActive) {
      return (
        <Translation>{(t) => 
          <CareGuideWrapper>
            <DocumentHead title={t('journal_posts_route_list')} />
            <CareGuideDisabled careGuideId={careGuideId} />
          </CareGuideWrapper>
        }</Translation>
      );
    }

    return (
      <Translation>{(t) => 
        <CareGuideWrapper>

          <DocumentHead title={t('journal_posts_route_list')} />

          <div className="journal-posts-screen">

            <Row gutter={16}>

              <Col
                lg={{span: 16, order: 1}}
                md={{span: 24, order: 2}}
                sm={{span: 24, order: 2}}
                xs={{span: 24, order: 2}}
              >

                {journal
                  ? <div className="journal-detail">
                      <JournalPostDetail careGuideId={careGuideId} journal={journal} />
                    </div>
                  : null}

                {hasPermission(CREATE_JOURNAL_POST)
                  ? <Card title={t('journal_posts_form_title')}>
                      <JournalPostForm
                        careGuideId={careGuideId}
                        page={page}
                        limit={limit}
                        order={'published_at.desc'}
                      />
                    </Card>
                  : null}

                <div className="inline-filter">
                  <JournalPostsFilter
                    careGuideId={careGuideId}
                    statusPartnership = {careGuideParnetshipStatus}
                    title={t('journal_posts_filter_title')}
                    isCollapsible={true}
                  />
                </div>

                <JournalPostsList
                  key={page}
                  component="JournalPostsScreen"
                  careGuideId={careGuideId}
                  page={page}
                  limit={limit}
                  order={'published_at.desc'}
                  tags={'tags' in params ? params['tags'] : null}
                  partner_tags={'partner_tags' in params ? params['partner_tags'] : null}
                  members={'members' in params ? params['members'] : null}
                  ratings={'ratings' in params ? params['ratings'] : null}
                  keywords={'keywords' in params ? params['keywords'] : null}
                  start={'start' in params ? params['start'] : null}
                  end={'end' in params ? params['end'] : null}
                  exclude={'id' in params ? params['id'] : null}
                />

              </Col>

              <Col
                lg={{span: 8, order: 2}}
                md={{span: 24, order: 1}}
                sm={{span: 24, order: 1}}
                xs={{span: 24, order: 1}}
                className="content-sidebar"
              >
                <JournalPostsFilter
                  careGuideId={careGuideId}
                  statusPartnership = {careGuideParnetshipStatus}
                  title={t('journal_posts_filter_title')}
                  isCollapsible={false}
                />
              </Col>
            </Row>

          </div>

          <JournalPostModalForm
            careGuideId={careGuideId}
            page={page}
            limit={limit}
            order={'published_at.desc'}
          />

          <JournalReplyModalForm
            careGuideId={careGuideId}
          />

        </CareGuideWrapper>
      }</Translation>
    )
  }

  /* LIFECYCLE EVENTS */

  componentDidMount() {
    scrollToTop();
    const params = QueryString.parse(this.props.location.search);
    if ('id' in params) {
      this.props.loadJournal(this.props.careGuideId, params['id']);
    }    
  }

  componentDidUpdate(prevProps) {
    const params = QueryString.parse(this.props.location.search);

    if (this.props.isCareGuideActive) {
      if (this.props.careGuide?.partnership !== prevProps.partnershipActiveId) {
        if (this.props.careGuide.partnership) {
          this.props.setActiveAccess({ access_role_admin_in_care_guide: this.props.careGuide.partnership.access_role_admin_in_care_guide })

          if (this.props.accessCareGuide) {
            if (hasPermission(VIEW_ACCESS_CARE_GUIDE)) {
              if (!this.props.accessCareGuide?.journal_post) {
                this.setState({ redirect: "HomeScreen" });
              }
            }
          }
        }
      }
      if ('id' in params) {
        this.props.loadJournal(this.props.careGuideId, params['id']);
      }
    }
  }

}

export default JournalPostsScreen;

Logger.log('silly', `JournalPostsScreen loaded.`);
