import { connect } from "react-redux";

import storage from "../../../../../lib/Storage";

import ChatScreen from "../components/ChatScreen";

const mapStateToProps = (state, ownProps) => {
 
  return {
    accessTabs: storage.get("local", "partnershipData")?.access_tabs_in_app,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

const ChatScreenContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ChatScreen);

export default ChatScreenContainer;
