import { connect } from "react-redux";
import { denormalize } from "normalizr";

import { schema } from "../../../../../state/schema";
import MemberProfileTags from "../components/MemberProfileTags";
import {
  loadAdminPartnerClassPartnershipAdmin,
} from "../../../../../state/modules/adminPartnerClassesAdmin/actions";

const mapStateToProps = (state, ownProps) => {
  const partnershipId = ownProps.partnershipId;
  const adminPartnerClass = state.adminPartnerClassesAdmin.getIn([
    "adminPartnerClassesAdmin",
    partnershipId,
  ]);
  let nameClasses = [];

  const adminPartnerClassesAdmin = adminPartnerClass
    ? adminPartnerClass
        .map((x) => {
          return {
            key: x,
            ...denormalize(
              state.entities.getIn(["adminPartnerClassesAdmin", x]),
              schema.adminPartnerClassAdmin,
              state.entities.toJS()
            ),
          };
        })
        .toArray()
        .map((x) => {
          nameClasses.push(x.name);
          return {
            ...x,
          };
        })
    : [];

  return {
    options: adminPartnerClassesAdmin,
    isLoading: state.adminPartnerClassesAdmin.get("isLoading"),
    nameClasses: nameClasses,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    load: (partnershipId, cb) => {
      dispatch(loadAdminPartnerClassPartnershipAdmin(partnershipId, cb));
    },
  };
};

const MemberProfileTagsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(MemberProfileTags);

export default MemberProfileTagsContainer;
