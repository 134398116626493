import { normalize } from 'normalizr';

import api from '../../api';
import { schema } from '../../schema';
import { addEntities } from '../../actions';
import Logger from '../../../lib/Logger';

// ACTION TYPES

export const TYPES = {
  LIST_REQUEST: 'PARTNERSHIP_FORM_ANSWER/LIST_REQUEST',
  LIST_SUCCESS: 'PARTNERSHIP_FORM_ANSWER/LIST_SUCCESS',
  LIST_FAILURE: 'PARTNERSHIP_FORM_ANSWER/LIST_FAILURE',
  DELETE_REQUEST: 'PARTNERSHIP_FORM_ANSWER/DELETE_REQUEST',
  DELETE_SUCCESS: 'PARTNERSHIP_FORM_ANSWER/DELETE_SUCCESS',
  DELETE_FAILURE: 'PARTNERSHIP_FORM_ANSWER/DELETE_FAILURE',
  PDF_REQUEST: 'PARTNERSHIP_FORM_ANSWER/PDF_REQUEST',
  PDF_SUCCESS: 'PARTNERSHIP_FORM_ANSWER/PDF_SUCCESS',
  PDF_FAILURE: 'PARTNERSHIP_FORM_ANSWER/PDF_FAILURE',
};
// ACTION CREATORS

export function partnershipFormAnswerListRequest(
  partnershipId,
  careGuideId,
  page,
  limit,
  order,
  filter
) {
  Logger.log(
    'debug',
    `[state.partnershipFormAnswers.actions] partnershipFormAnswerListRequest(${partnershipId}, ${careGuideId}, ${page}, ${limit}, ${order}, %j)`,
    filter
  );
  return {
    type: TYPES.LIST_REQUEST,
    partnershipId: partnershipId,
    careGuideId: careGuideId,
    page: page,
    limit: limit,
    order: order,
    filter: filter,
  };
}

export function partnershipFormAnswerListSuccess(
  partnershipId,
  careGuideId,
  data
) {
  Logger.log(
    'debug',
    `[state.partnershipFormAnswers.actions] partnershipFormAnswerListSuccess(%j)`,
    data
  );

  return {
    type: TYPES.LIST_SUCCESS,
    partnershipId: partnershipId,
    careGuideId: careGuideId,
    page: data.page,
    limit: data.limit,
    order: data.order,
    result: data.result,
    total: data.total,
    receivedAt: Date.now(),
  };
}

export function partnershipFormAnswerListFailure(error) {
  Logger.log(
    'debug',
    `[state.partnershipFormAnswers.actions] partnershipFormAnswerListFailure(%j)`,
    error
  );
  return {
    type: TYPES.LIST_FAILURE,
    error: error,
  };
}

export function partnershipFormAnswerPDFRequest(
  partnershipId,
  careGuideId,
  id
) {
  Logger.log(
    'debug',
    `[state.partnershipFormAnswers.actions] partnershipFormAnswerPDFRequest(${id}, ${partnershipId}, ${careGuideId})`
  );
  return {
    type: TYPES.PDF_REQUEST,
    id: id,
    partnershipId: partnershipId,
    careGuideId: careGuideId,
  };
}

export function partnershipFormAnswerPDFSuccess(
  partnershipId,
  careGuideId,
  id
) {
  Logger.log(
    'debug',
    `[state.partnershipFormAnswers.actions] partnershipFormAnswerPDFSuccess(${id}, ${partnershipId}, ${careGuideId})`
  );
  return {
    type: TYPES.PDF_SUCCESS,
    id: id,
    partnershipId: partnershipId,
    careGuideId: careGuideId,
    receivedAt: Date.now(),
  };
}

export function partnershipFormAnswerPDFFailure(
  partnershipId,
  careGuideId,
  id,
  error
) {
  Logger.log(
    'debug',
    `[state.partnershipFormAnswers.actions] partnershipFormAnswerPDFFailure(${id}, ${partnershipId}, ${careGuideId}, %j)`,
    error
  );
  return {
    type: TYPES.PDF_FAILURE,
    id: id,
    partnershipId: partnershipId,
    careGuideId: careGuideId,
    error: error,
  };
}

export function partnershipFormAnswerDeleteRequest(
  partnershipId,
  careGuideId,
  id
) {
  Logger.log(
    'debug',
    `[state.partnershipFormAnswers.actions] partnershipFormAnswerDeleteRequest(${partnershipId}, ${careGuideId}, ${id})`
  );
  return {
    type: TYPES.DELETE_REQUEST,
    partnershipId: partnershipId,
    careGuideId: careGuideId,
    id: id,
  };
}

export function partnershipFormAnswerDeleteSuccess(
  partnershipId,
  careGuideId,
  id
) {
  Logger.log(
    'debug',
    `[state.partnershipFormAnswers.actions] partnershipFormAnswerDeleteSuccess(${partnershipId}, ${careGuideId}, ${id})`
  );
  return {
    type: TYPES.DELETE_SUCCESS,
    partnershipId: partnershipId,
    careGuideId: careGuideId,
    id: id,
  };
}

export function partnershipFormAnswerDeleteFailure(
  partnershipId,
  careGuideId,
  error
) {
  Logger.log(
    'debug',
    `[state.partnershipFormAnswers.actions] partnershipFormAnswerDeleteFailure(${partnershipId}, ${careGuideId}, %j)`,
    error
  );
  return {
    type: TYPES.DELETE_FAILURE,
    partnershipId: partnershipId,
    careGuideId: careGuideId,
    error: error,
  };
}

// API THUNK ACTION CREATORS
export function loadPartnershipFormAnswers(
  partnershipId = null,
  careGuideId = null,
  page = 1,
  limit = 10,
  order = null,
  filter = null,
  cb = function () {}
) {
  Logger.log(
    'debug',
    `[state.partnershipFormAnswers.actions] loadPartnershipFormAnswers(${partnershipId}, ${careGuideId}, ${page}, ${limit}, ${order}, ###)`
  );

  return async function (dispatch) {
    dispatch(
      partnershipFormAnswerListRequest(
        partnershipId,
        careGuideId,
        page,
        limit,
        order,
        filter
      )
    );

    // call API
    const response = await api.getPartnershipFormAnswers(
      partnershipId,
      careGuideId,
      page,
      limit,
      order,
      filter
    );
    let success = false;

    // get partnership form answers list success
    if (200 === response.get('status')) {
      Logger.log(
        'info',
        `Get API partnershipFormAnswers list success. Partnership ID: ${partnershipId}, Care Guide ID: ${careGuideId}, Page: ${page}, Limit: ${limit}, Order: ${order}.`
      );

      const normalizedEntities = normalize(
        response.getIn(['data', 'partnership_form_answers']),
        [schema.partnershipFormAnswer]
      );

      const data = {
        page: response.getIn(['data', 'page']),
        limit: response.getIn(['data', 'limit']),
        order: order,
        total: response.getIn(['data', 'total']),
        result: normalizedEntities.result,
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(
        partnershipFormAnswerListSuccess(partnershipId, careGuideId, data)
      );
      success = true;
    } else if (1 === page && 204 === response.get('status')) {
      Logger.log(
        'info',
        `Get API partnershipFormAnswers list success [empty]. Partnership ID: ${partnershipId}, Care Guide ID: ${careGuideId}, Page: ${page}, Limit: ${limit}, Order: ${order}.`
      );
      const data = {
        page: page,
        limit: limit,
        order: order,
        total: 0,
        result: [],
      };
      dispatch(
        partnershipFormAnswerListSuccess(partnershipId, careGuideId, data)
      );

      // get partnershipFormAnswers list failure
    } else {
      Logger.log(
        'info',
        `Get API partnershipFormAnswers list failure. Partnership ID: ${partnershipId}, Care Guide ID: ${careGuideId}, Page: ${page}, Limit: ${limit}, Order: ${order}.`
      );
      dispatch(
        partnershipFormAnswerListFailure(response.getIn(['data', 'error']))
      );
    }

    // callback function
    cb(success);
  };
}

export function generatePartnershipFormAnswerPDF(
  partnershipId = null,
  careGuideId = null,
  id = null,
  cb = function () {}
) {
  Logger.log(
    'debug',
    `[state.careGuides.actions] generatePartnershipFormAnswerPDF(${partnershipId}, ${careGuideId}, ${id}, ###)`
  );

  return async function (dispatch) {
    dispatch(partnershipFormAnswerPDFRequest(partnershipId, careGuideId, id));

    // call API
    const response = await api.getPartnershipFormAnswerPDF(
      partnershipId,
      careGuideId,
      id
    );
    let success = false;

    // get partnership form answer PDF success
    if (200 === response.get('status')) {
      Logger.log(
        'info',
        `Get API partnership form answer PDF success. Partnership ID: ${partnershipId}, Care Guide ID: ${careGuideId} ID: ${id}.`
      );

      // trigger browser download
      const url = window.URL.createObjectURL(
        new Blob([response.get('data')], { type: 'application/pdf' })
      );
      const link = document.createElement('a');
      link.href = url;
      const contentDisposition = response.get('headers')['content-disposition'];
      link.setAttribute(
        'download',
        contentDisposition
          ? contentDisposition.substring(
              contentDisposition.indexOf('"') + 1,
              contentDisposition.lastIndexOf('"')
            )
          : 'partnership-form-answer.pdf'
      );
      document.body.appendChild(link);
      link.click();

      dispatch(partnershipFormAnswerPDFSuccess(partnershipId, careGuideId, id));
      success = true;

      // get partnership form answer PDF failure
    } else {
      Logger.log(
        'info',
        `Get API partnership form answer PDF failure.  Partnership ID: ${partnershipId}, Care Guide ID: ${careGuideId} ID: ${id}.`
      );
      dispatch(
        partnershipFormAnswerPDFFailure(
          partnershipId,
          careGuideId,
          id,
          response.getIn(['data', 'error'])
        )
      );
    }

    // callback function
    cb(success);
  };
}

export function deletePartnershipFormAnswer(
  partnershipId = null,
  careGuideId = null,
  id = null,
  cb = function () {}
) {
  Logger.log(
    'debug',
    `[state.partnershipFormAnswers.actions] deletePartnershipFormAnswer(${partnershipId}, ${careGuideId}, ${id}, ###)`
  );

  return async function (dispatch) {
    dispatch(
      partnershipFormAnswerDeleteRequest(partnershipId, careGuideId, id)
    );

    // call API
    const response = await api.deletePartnershipFormAnswer(
      partnershipId,
      careGuideId,
      id
    );
    let success = false;

    // delete partnership form answer success
    if (204 === response.get('status')) {
      Logger.log(
        'info',
        `Delete API partnership form answer success. Partnership ID: ${partnershipId}, Care Guide ID: ${careGuideId}, ID: ${id}.`
      );
      dispatch(
        partnershipFormAnswerDeleteSuccess(partnershipId, careGuideId, id)
      );
      success = true;

      // get partnership form answer failure
    } else {
      Logger.log(
        'info',
        `Delete API partnership form answer failure. Partnership ID: ${partnershipId} Care Guide ID: ${careGuideId}  ID: ${id}.`
      );
      dispatch(
        partnershipFormAnswerDeleteFailure(
          partnershipId,
          careGuideId,
          response.getIn(['data', 'error'])
        )
      );
    }

    // callback function
    cb(success);
  };
}

Logger.log('silly', `state.partnershipFormAnswers.actions loaded.`);
