import "react-quill/dist/quill.snow.css";
import { SaveOutlined, SendOutlined } from "@ant-design/icons";
//import ReactQuill from "react-quill";
import React, { useState, useRef, useEffect } from "react";
import { Button, Form, Input } from "antd";
import { Translation, getI18n } from "react-i18next";
import Logger from "../../../../../lib/Logger";
import { pathTo } from "../../../Routes";
import message from "../../../elements/lib/MessageWrapper";
import BodyInputChat from "./BodyInputChat"
const MessageChat = ({
  threadId,
  messageId,
  page,
  limit,
  order,
  errors,
  load,
  destroyForm,
  isLoading,
  isSubmitting,
  isSaving,
  isDeleting,
  messageData,
  defaultCareGuideId,
  ...props
}) => {
  
  const [form] = Form.useForm();
  const [resetBody, setResetBody] = useState(null);
  const editorRef = useRef();

  const onClickOK = () => {
    form.submit();
  };

  const onClickSave = () => {
    if (!isSaving) {
      saveDraft(
        form.getFieldsValue(["care_guide", "subject", "recipients", "body"])
      );
    }
  };

  useEffect(() => {
    form.setFieldsValue(messageData);
  }, [threadId, messageId, form]); // eslint-disable-line react-hooks/exhaustive-deps


  // save draft handler
  const saveDraft = async (values) => {
    Logger.log("debug", `MessageChat.saveDraft(###)`);

    const payload = {
      is_draft: true,
    };
    if (threadId) {
      payload["thread_id"] = threadId;
    }

    // transform values
    for (var key in values) {
      // handle recipients
      if (key === "recipients" && Array.isArray(values[key])) {
        payload[key] = values[key].map((x, i) => {
          return { user_profile_id: x };
        });
      }

      // transform entity refs to IDs
      else if (["care_guide"].includes(key)) {
        payload[key + "_id"] = values[key];
      } else {
        payload[key] = values[key];
      }
    }

    props.save(messageId, payload, (success) => {
      if (success) {
        message.success(getI18n().t("feedback_form_success"));
        props.loadDrafts(page, limit, "created_at.desc");
      } else {
        message.error(getI18n().t("feedback_form_error"));
      }
    });
  };

  const submitData = async (values) => {
    Logger.log("debug", `MessageChat.submitData(###)`);

    const payload = {
      is_draft: false,
    };
    // transform values
    for (var key in values) {
      payload[key] = values[key];    
    }

    // create URL template for message thread
    payload["url_template"] =
      window.location.href.split("/").slice(0, 3).join("/") +
      pathTo("MessagesInboxThreadScreen", { page: 1, messagei: "000" }).replace(
        /000/,
        "{thread_id}"
      );
    props.send(messageId, payload, (success) => {
      if (success) {
        message.success(getI18n().t("feedback_form_success"));
        form.setFieldsValue({ body: "", subject: "", recipients: [] });
        setResetBody(Math.random());
        props.loadInbox(page, limit, order);
        if (messageId) {
          props.loadDrafts(page, limit, "created_at.desc");
        }
      } else {
        message.error(getI18n().t("feedback_form_error"));
      }
    });
  };
  // remove error message when input value changes
  const handleValuesChange = (changedValues, allValues) => {
    for (const key of Object.keys(changedValues)) {
      form.setFields([{ name: key, errors: [] }]);
    }
  };

  // form submit handler
  const handleFinish = async (values) => {
    Logger.log("debug", `MessageChat.handleFinish(###)`);
    if (!props.isSubmitting) {
      await submitData(values);
    }
  };

  // form error handler
  const handleFinishFailed = ({ values, errorFields, outOfDate }) => {
    Logger.log("debug", `MessageChat.handleFinishFailed(###)`);
    message.error(getI18n().t("feedback_form_error"));
    if (errorFields && errorFields.length > 0) {
      form.scrollToField(errorFields[0].name);
    }
  };

  return (
    <Translation>
      {(t) => (
        
          <Form
            name="chat_form"
            form={form}
            initialValues={messageData}
            onFinish={handleFinish}
            onFinishFailed={handleFinishFailed}
            onValuesChange={handleValuesChange}
            validateTrigger="onSubmit"
            requiredMark={false}
            layout="vertical"
          >
            <div className="form-group">
              <Form.Item name="thread_id" style={{ display: "none" }}>
                <Input type="hidden" />
              </Form.Item>

              <BodyInputChat
                name="body"
                placeholder='¡Hi!'
                form={form}
                disabled={isLoading || isSubmitting}
                reset={resetBody}
                editorRef={editorRef}
              />
              
            </div>
            <div className='button-group'>
                  <Button
                    shape="round" 
                    key="save"
                    icon={<SaveOutlined />}
                    loading={isSaving}
                    onClick={onClickSave}
                  >
                    {t("messages_form_button_save")}
                  </Button>
                  <Button
                    shape="round" 
                    key="send"
                    type="primary"
                    icon={<SendOutlined />}
                    loading={isSubmitting}
                    onClick={onClickOK}
                  >
                    {t("messages_form_button_submit")}
                  </Button>
                </div>
            <div style={{ display: "none" }}>
              <Button htmlType="submit"></Button>
            </div>
          </Form>
      )}
    </Translation>
  );
};

export default MessageChat;

Logger.log("silly", `MessageChat loaded.`);
