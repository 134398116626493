import {getI18n} from 'react-i18next';

import Config from '../../../../Config';
import Logger from '../../../../lib/Logger';
import Events from '../../../../lib/EventEmitter';

import LearningLayout from './containers/LearningLayoutContainer';


const register = () => {
  Logger.log('debug', `learnings.register()`);

  if (Config.getIn(['MODULE_TOGGLES', 'learnings', 'routes'])) {
    
    Events.dispatch('ADD_MAIN_ROUTES',

      // screen name (key): [route type (element), path (prop), exact (prop), component (prop), route name]
      {
        'LearningLayout': ['PrivateRoute', '/care-guide/:id([\\w\\-]+)/learnings', false, LearningLayout, getI18n().t('learnings_route_main')],
        
      }
    );
    }
}

export default register;

Logger.log('silly', `learnings.register() loaded.`);
