import React from 'react';
import {withRouter} from "react-router";
import {Translation, getI18n} from 'react-i18next';
import {Button, Popconfirm, Space} from 'antd';
import {EditOutlined, DeleteOutlined, EyeOutlined} from '@ant-design/icons';

import message from '../lib/MessageWrapper';
import {pathTo} from '../../Routes';


const ListActions = ({id, id_partnership, page, limit, order, filter, load, remove, editScreen, history, activeDelete, activeEdit, activePreview, showDetail, ...props}) => {

  const confirm = id => {
    remove(id_partnership, id, (success) => {
      if (success) {
          load(id_partnership, page, limit, order, filter);
          message.success(getI18n().t('feedback_delete_success'));
      } else {
        message.error(getI18n().t('feedback_delete_error'));
      }
    });
  }

  const confirmShowEvent = id => {
    showDetail(id);
  }
 
  return (
    <Translation>{(t) =>
      <>
        <Space>
        {activePreview && 
          <Button
            icon={<EyeOutlined />}
            onClick={()=>confirmShowEvent(id)}
          />
        }
          {activeEdit && 
          <Button
            icon={<EditOutlined />}
            onClick={e => history.push(pathTo(editScreen, {id_partnership: id_partnership, id: id}))}
          />
          }
        
          
          {activeDelete  && 
          <Popconfirm
            placement="topRight"
            title={t('delete_confirm_body')}
            onConfirm={e => confirm(id)}
            okText={t('confirm_yes')}
            cancelText={t('confirm_cancel')}
          >
            <Button
              danger
              icon={<DeleteOutlined />}
            />
          </Popconfirm>}
        </Space>
      </>
    }</Translation>
  )
}

export default withRouter(ListActions);