import React from 'react';
import {Translation} from 'react-i18next';
import {Form, Input} from 'antd';

const { Search } = Input;

const KeywordFilter = ({name, label, onSearch=null, ...props}) => {
  return (
    <Translation>{(t) => 
      <div className="form-group">

        <Form.Item
          name={name}
          label={label}
        >
          <Search
            disabled={props.isLoading || props.isSubmitting}
            onSearch={onSearch}
          />
        </Form.Item>
      </div>
    }</Translation>
  );
}

export default KeywordFilter;
