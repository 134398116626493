import {connect} from 'react-redux';
import {denormalize} from 'normalizr';

import InviteButton from '../components/InviteButton';
import {schema} from '../../../../../state/schema';
import {careGuideInvitationShowForm} from '../../../../../state/modules/careGuideInvitations/actions';

const mapStateToProps = (state, ownProps) => {

  const careGuideId = ownProps.careGuideId;
  const careGuide = careGuideId
    ? denormalize(
        state.entities.getIn(['careGuides', careGuideId]),
        schema.careGuide,
        state.entities.toJS()
      )
    : null;

  const count = state.careGuideHelpers.getIn(['helperCounts', careGuideId]);
  const limit = careGuide && careGuide.account ? careGuide.account.helper_limit : null;

  return {
    count: count,
    limit: limit,
    canInvite: Number.isInteger(count) && Number.isInteger(limit) && limit > count ? true : false,
    isLoading: state.careGuideHelpers.get('isLoading'),
  }
}

const mapDispatchToProps = dispatch => {
  return {
    showForm: (helperId) => {
      dispatch(careGuideInvitationShowForm(helperId));
    },
  }
}

const InviteButtonContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(InviteButton);

export default InviteButtonContainer;
