import Config from '../../../../Config';
import Logger from '../../../../lib/Logger';
import Events from '../../../../lib/EventEmitter';

const register = () => {
  Logger.log('debug', `adminPartnerClassesAdmin.register()`);

  if (Config.getIn(['MODULE_TOGGLES', 'adminPartnerClassesAdmin', 'routes'])) {
    Events.dispatch('ADD_MAIN_ROUTES', 
      {
      }
    );
  }
}

export default register;

Logger.log('silly', `adminPartnerClassesAdmin.register() loaded.`);
