import React, {Component} from 'react';
import {Redirect} from 'react-router-dom'
import {Translation, getI18n} from 'react-i18next';
import {Col, Row} from 'antd';

import {pathTo} from '../../../Routes';
import DocumentHead from '../../../elements/components/DocumentHead';
import LoadingIcon from '../../../elements/components/LoadingIcon';
import scrollToTop from '../../../elements/lib/scrollToTop';
import message from '../../../elements/lib/MessageWrapper';
import Logger from '../../../../../lib/Logger';


class CareGuideInvitationAccept extends Component {

  static defaultProps = {
    isResponding: true,
    redirectTo: null
  }

  constructor(props) {
    super(props);
    this.state = {
      redirectTo: null,
    };
  }

  render() {
    const {isResponding} = this.props;

    if (this.state.redirectTo) {
      return <Redirect to={this.state.redirectTo} />;
    }

    return (
      <Translation>{(t) => 
        <>

          <DocumentHead title={t('care_guide_invitations_response_accept_title')} />

          <Row className="invitation-respond">
            <Col span={24}>

              {isResponding ? <><LoadingIcon /> Accepting....</> : null}

            </Col>
          </Row>

        </>
      }</Translation>
    )
  }

  /* LIFECYCLE EVENTS */

  componentDidMount() {
    scrollToTop();
    this.props.respond(this.props.invitationId, {public_status: 1}, (success) => {
      if (success) {
        message.success(getI18n().t('care_guide_invitations_feedback_accept_success'));
        this.setState({redirectTo: pathTo('CareGuideDetailScreen', {id: this.props.careGuideId})})
      } else {
        message.error(getI18n().t('care_guide_invitations_feedback_accept_error'));
      }
    });  // accept
  }
}

export default CareGuideInvitationAccept;

Logger.log('silly', `CareGuideInvitationAccept loaded.`);
