import Toolbar from "./Toolbar";
import ReactQuill from 'react-quill';
import React, { useState, useEffect } from "react";
import PinButton from "../../../elements/components/PinButton";
import notification from "../../../elements/lib/NotificationWrapper";
import Logger from "../../../../../lib/Logger";
import { Translation, getI18n } from "react-i18next";
import { Modal, Row, Col, Typography, Button, Form, Input } from 'antd';
import ConfirmationModal from "../../../elements/components/ConfirmationModal";

const { Title } = Typography;

const layout = {
    main: {
        labelCol: { span: 5 },
        wrapperCol: { span: 24 },
    },
};
const confirmTexts = {
    title: getI18n().t("care_guide_note_confirm_modal_title"),
    description: null,
    leaveText: getI18n().t("care_guide_note_confirm_modal_leave"),
    saveText: getI18n().t("care_guide_note_confirm_modal_save"),
  };

const CareGuideNoteFormModal = ({ careGuideId, careGuideNoteId, page, limit, filter, order, isSubmitting, data, errors, ...props }) => {

    const [form] = Form.useForm();
    const [isPinned, setIsPinned] = useState(false);
    const [value, setValue] = useState('');
    const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
    const [isChangeValue, setIsChangeValue] = useState(false);

    // handle errors reported by API
    useEffect(() => {
        let firstFieldName = '';
        for (const field in errors) {
            form.setFields([{ name: field, errors: errors[field] }]);
            if (firstFieldName === '') {
                firstFieldName = field;
            }
        }
        form.scrollToField(firstFieldName);
    }, [form, errors]);

    useEffect(() => {
        form.setFieldsValue(data);
        setValue(data.body)
        setIsPinned(data.is_pinned);
    }, [careGuideNoteId, form]); // eslint-disable-line react-hooks/exhaustive-deps

    // submit data handler
    const submitData = async (values) => {
        Logger.log('debug', `CareGuideNoteModalForm.submitData(###)`);

        const payload = {};

        // transform values
        for (var key in values) {
            if (["is_pinned"].includes(key)) {
                payload[key] = isPinned ? true : false;
            }
            else if (values[key]) {
                payload[key] = values[key];
            }
        }

        if (careGuideNoteId) {
            // update
            props.update(careGuideId, careGuideNoteId, payload, (success) => {
                if (success) {
                    notification.success(getI18n().t("care_guide_note_message_form_success"));
                    clearForm();
                    props.load(careGuideId, page, limit, order, filter);
                } else {
                    notification.error(getI18n().t("care_guide_note_message_form_failure_update"));
                }
            });
        } else {
            // create
            props.create(careGuideId, payload, (success) => {
                if (success) {
                    notification.success(getI18n().t("care_guide_note_message_form_success"));
                    clearForm();
                    props.load(careGuideId, page, limit, order, filter);
                } else {
                    notification.error(getI18n().t("care_guide_note_message_form_failure_create"));
                }
            });
        }
    }

    const onConfirmModal = () => {
        props.hideForm();
        setConfirmationModalOpen(true)
    }

    const onCancelConfirmModal = () => {
        setConfirmationModalOpen(false);
        props.showForm(careGuideNoteId);;
    }

    const onLeaveConfirmModal = () => {
        setConfirmationModalOpen(false);
        clearForm();
    }

    const onSaveConfirmModal = () =>{
        onClickOK();
        setConfirmationModalOpen(false);
    }

    const onSetIsPinned = () =>{
        setIsPinned(!isPinned);
        setIsChangeValue(true);
        form.setFields([{
            name: ["is_pinned"],
            value: !isPinned,
        }]);
    }

    const clearForm = () => {
        props.hideForm();
        props.formDestroy();
        setIsChangeValue(false);
        setIsPinned(false);
        setValue('')
        data = {};
        form.setFieldsValue({ body: '', is_pinned: false });
    }

    // form submit handler
    const handleFinish = async (values) => {
        Logger.log('debug', `CareGuideNoteModalForm.handleFinish(###)`);
        if (!isSubmitting) {
            await submitData(values);
        }
    }

    // form error handler
    const handleFinishFailed = ({ values, errorFields, outOfDate }) => {
        Logger.log('debug', `CareGuideNoteModalForm.handleFinishFailed(###)`);
        notification.error(getI18n().t('care_guide_note_message_form_failure'));
        if (errorFields && errorFields.length > 0) {
            form.scrollToField(errorFields[0].name);
        }
    }

    // remove error notification when input value changes
    const handleValuesChange = (changedValues, allValues) => {
        for (const key of Object.keys(changedValues)) {
            form.setFields([{ name: key, errors: [] }]);
        }
    };

    const onClickOK = () => {
        form.submit();
    };

    const modules = {
        toolbar: '#toolbar-care-guide-note',
    };

    return (
        <Translation>{(t) =>
            <>
                <Modal
                    title={
                        <Row justify="space-between">
                            <Col>
                                <Title
                                    level={3}
                                    className="note-title">
                                     {careGuideNoteId ? t("care_guide_note_update_note_modal_header_title") : t("care_guide_note_create_note_modal_header_title")}   
                                    
                                </Title>
                            </Col>
                            <Col>
                                <PinButton
                                    isPinned={isPinned}
                                    setIsPinned={()=>{onSetIsPinned()}} />
                            </Col>
                        </Row>
                    }
                    closable={false}
                    className="note-modal-export"
                    centered
                    visible={props.visible}
                    onOk={onClickOK}
                    onCancel={isChangeValue ? onConfirmModal : clearForm }
                    footer={[
                        <Button
                            key="back"
                            onClick={clearForm}
                        >
                            {t("care_guide_note_modal_cancel")}
                        </Button>,
                        <Button
                            key="submit"
                            type="primary"
                            loading={isSubmitting}
                            onClick={onClickOK}
                        >
                            {t("care_guide_note_modal_save")}
                        </Button>
                    ]}>
                    <Form
                        name="care_guide_note_form"
                        {...layout.main}
                        form={form}
                        initialValues={data}
                        onFinish={handleFinish}
                        onFinishFailed={handleFinishFailed}
                        onValuesChange={handleValuesChange}
                        validateTrigger="onSubmit"
                        className="form-note-modal"
                    >
                        <Form.Item name={"is_pinned"} style={{ display: 'none' }}>
                            <Input type="hidden" />
                        </Form.Item>
                        <Form.Item name={"body"} style={{ display: 'none' }}>
                            <Input type="hidden" />
                        </Form.Item>
                        <Form.Item>
                            <ReactQuill
                                className={'note-input-content'}
                                value={value}
                                onChange={(content, delta, source, editor) => {

                                    setValue(content);
                                    form.setFields([{
                                        name: ["body"],
                                        value: editor.getHTML()
                                    }]);
                                    if(content !== "<p><br></p>" && content !== data['body']){
                                        setIsChangeValue(true);
                                    }
                                }}
                                modules={modules}
                                placeholder={t('care_guide_note_modal_input_placeholder_body')}
                            />
                        </Form.Item>
                    </Form>

                    <Row justify={'space-between'} align='top'>
                        <Col className="note-input-content-column" >
                            <Toolbar />
                        </Col>
                    </Row>

                </Modal>
                <ConfirmationModal
                    texts={confirmTexts}
                    openConfirmationModal={confirmationModalOpen}
                    onLeaveConfirmation={onLeaveConfirmModal}
                    onSaveConfirmation={onSaveConfirmModal}
                    onCancel={onCancelConfirmModal}
                    setConfirmationModalOpen={setConfirmationModalOpen}
          />
            </>
        }</Translation>
    );
};

export default CareGuideNoteFormModal;