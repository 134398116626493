import React, {useState, useEffect, useRef} from 'react';
import {Translation, getI18n} from 'react-i18next';
import {Button, Card, Form, Input, InputNumber, Space} from 'antd';
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  FileTextOutlined,
  TableOutlined
} from '@ant-design/icons';

import SegmentContentInput from './SegmentContentInput';
import message from '../../../elements/lib/MessageWrapper';
import Logger from '../../../../../lib/Logger';

const CareGuideSegmentCreate = ({careGuideId, maxOrder, data, errors, ...props}) => {

  // const CONTENT_TYPE_TEXT = 1;
  // const CONTENT_TYPE_TEXTAREA = 2;
  const CONTENT_TYPE_WYSIWYG = 3;
  const CONTENT_TYPE_TABLE = 4;

  const [form] = Form.useForm();
  const [buttonsAreVisible, setButtonsAreVisible] = useState(false);
  const [contentType, setContentType] = useState(null);
  const isLoaded = useRef(false);
  
  // focus on first summary input when entering edit mode
  useEffect(() => {
    const firstInputField = form.getFieldInstance('name');
    if (firstInputField) {
      // using timeout because there seems to be a timing issue conflicting with focus, maybe the scrolling effect
      setTimeout(() => firstInputField.focus(), 250);
    }
  }, [form]);

  // handle errors reported by API
  useEffect(() => {
    let firstFieldName = '';
    for (const field in errors) {
      form.setFields([{name: field, errors: errors[field]}]);
      if (firstFieldName === '') {
        firstFieldName = field;
      }
    }
    form.scrollToField(firstFieldName);
  }, [form, errors]);

  // submit data handler
  const submitData = async (values) => {
    Logger.log('debug', `CareGuideSegmentCreate.submitData(###)`);

    // strip `key` from contents
    const contents = [];
    if ('contents' in values && Array.isArray(values['contents']) && values['contents'].length > 0) {
      for (const content of values['contents']) {
        contents.push({order: 1, type: contentType, ...content});
      }
    }

    // construct payload
    const payload = {
      'name': values['name'],
      'order': values['order'],
      'contents': contents,
    };

    props.create(careGuideId, payload, (success) => {
      if (success) {
        message.success(getI18n().t('feedback_form_success'));
        props.load(careGuideId);
        props.onCancel();
      } else {
        message.error(getI18n().t('feedback_form_error'));
      }
    });
  }

  // form submit handler
  const handleFinish = async (values) => {
    Logger.log('debug', `CareGuideSegmentCreate.handleFinish(###)`);
    if (!props.isSubmitting) {
      await submitData(values);
    }
  }

  // form error handler
  const handleFinishFailed = ({values, errorFields, outOfDate}) => {
    Logger.log('debug', `CareGuideSegmentCreate.handleFinishFailed(###)`);
    message.error(getI18n().t('feedback_form_error'));
    if (errorFields && errorFields.length > 0) {
      form.scrollToField(errorFields[0].name);
    }
  }

  // remove error message when input value changes
  const handleValuesChange = (changedValues, allValues) => {
    for (const key of Object.keys(changedValues)) {
      form.setFields([{name: key, errors: []}]);
    }
  }

  const onClickCancelHandler = e => {
    props.onCancel();
  }

  const onClickSaveHandler = e => {
    form.submit();
  }

  const onClickWYSIWYGHandler = (add) => {
    setButtonsAreVisible(false);
    setContentType(CONTENT_TYPE_WYSIWYG);
    add();
  }

  const onClickTableHandler = (add) => {
    setButtonsAreVisible(false);
    setContentType(CONTENT_TYPE_TABLE);
    add();
  }

  return (
    <Translation>{(t) => 
      <>
        <Form
          name="segment_create_form"
          className="segment-create-form"
          form={form}
          initialValues={data}
          onFinish={handleFinish}
          onFinishFailed={handleFinishFailed}
          onValuesChange={handleValuesChange}
          validateTrigger="onSubmit"
          requiredMark={false}
        >
          <Card
            title={
              <Form.Item
                name="name"
                rules={[
                  {required: true, message: t('feedback_validation_required')},
                ]}
                className="segment-input-name"
              >
                <Input placeholder={t('care_guides_form_placeholder_name')} />
              </Form.Item>
            }
            size="small"
            extra={
              <Space>
                <Form.Item
                  name="order"
                  label={t('care_guides_form_input_order')}
                  rules={[
                    {required: true, message: t('feedback_validation_required')},
                  ]}
                  className="segment-input-order"
                >
                  <InputNumber size="small" min={1} max={maxOrder} />
                </Form.Item>
                <Button
                  icon={<CloseCircleOutlined />}
                  size="small"
                  onClick={onClickCancelHandler}
                >
                  {t('action_cancel')}
                </Button>
                <Button
                  icon={<CheckCircleOutlined />}
                  size="small"
                  type="primary"
                  onClick={onClickSaveHandler}
                  loading={props.isSubmitting}
                >
                  {t('action_save')}
                </Button>
              </Space>
            }
          >

            <Form.List name="contents">
              {(contents, {add, remove, move}) => {

                // override buttons and only show WYSIWYG editor
                if (isLoaded.current === false) {
                  isLoaded.current = true;
                  onClickWYSIWYGHandler(add);
                }

                return(
                  <>

                    {buttonsAreVisible
                      ? <Space>
                          <Button
                            icon={<FileTextOutlined />}
                            onClick={() => onClickWYSIWYGHandler(add)}
                          >
                            {t('care_guides_form_btn_wysiwyg')}
                          </Button>
                          <Button
                            icon={<TableOutlined />}
                            onClick={() => onClickTableHandler(add)}
                          >
                            {t('care_guides_form_btn_table')}
                          </Button>
                        </Space>
                      : null}

                    <div className="segment-content-form">

                      {contents.map((content, i) => (
                        <SegmentContentInput
                          content={content}
                          defaultContentType={contentType}
                          remove={remove}
                          form={form}
                          index={i}
                          key={i}
                        />
                      ))}
                    </div>
                  </>
                );
              }}
            </Form.List>

          </Card>
        </Form>
      </>
    }</Translation>
  )
}

export default CareGuideSegmentCreate;
