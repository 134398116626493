import { connect } from 'react-redux';

import PartnershipAccountScreen from '../components/PartnershipAccountScreen';
import storage from '../../../../../lib/Storage';

const mapStateToProps = (state) => {
  return {
    isLoading: state.partnershipAccount.get('isLoading'),
    activeId: storage.get('local', 'partnershipId'),
    access_role_admin_in_app: storage.get('local', 'partnershipData')
      ?.access_role_admin_in_app,
    role: storage.get('local', 'partnershipData')?.role,
  };
};

const PartnershipAccountScreenContainer = connect(mapStateToProps)(
  PartnershipAccountScreen
);

export default PartnershipAccountScreenContainer;
