import React from 'react';
import {withRouter} from "react-router";
import {Divider} from 'antd';

import PageHeader from '../../elements/containers/PageHeaderContainer';

const CareGuideWrapper = props => {
  return (
    <>
      <PageHeader pathname={props.location.pathname} />
      <Divider className="page-header-divider" />
      {props.children}
    </>
  )
}

export default withRouter(CareGuideWrapper);
