import React from 'react';
import { GlobalOutlined } from '@ant-design/icons';
import { globalIcon } from './Icons';

const WebsiteDisplay = ({ website, key = null, ...props }) => {
  const withHttp = (url) =>
    !/^https?:\/\//i.test(url) ? `http://${url}` : url;
  if (website) {
    return (
      <div className="website-display-resource" key={key}>
        <a href={withHttp(website)} target="_blank" rel="noopener noreferrer">
          <GlobalOutlined />
          {website}
        </a>
      </div>
    );
  }
  return null;
};

const WebsiteDisplayAdmin = ({ website, key = null, onClick, ...props }) => {
  const withHttp = (url) =>
    !/^https?:\/\//i.test(url) ? `http://${url}` : url;
  if (website) {
    return (
      <div key={key} onClick={onClick}>
        <a
          href={withHttp(website)}
          target="_blank"
          rel="noopener noreferrer"
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          {globalIcon()}
          <span
            style={{
              marginLeft: '8px',
              maxWidth: '100%',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            }}
          >
            {website}
          </span>
        </a>
      </div>
    );
  }
  return null;
};

const WebsiteDisplayCard = ({ website, key = null, onClick, ...props }) => {
  const withHttp = (url) =>
    !/^https?:\/\//i.test(url) ? `http://${url}` : url;
  if (website) {
    return (
      <>
        <div key={key} onClick={onClick}>
          <div className="icon-text-container">
            <span className="icon-container">{globalIcon()}</span>
            <a
              href={withHttp(website)}
              target="_blank"
              rel="noopener noreferrer"
              className="responsive-text"
            >
              <span style={{ marginLeft: '8px' }}>{website}</span>
            </a>
          </div>
        </div>
      </>
    );
  }
  return null;
};

export { WebsiteDisplay, WebsiteDisplayAdmin, WebsiteDisplayCard };
