import React, { Component } from "react";
import { Translation } from "react-i18next";
import { Card, Col, Row, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import {
  hasPermissionAdmin,
  pathRedirect,
} from "../../../elements/lib/hasPermissionAdmin";
import { getRouteFromPath } from "../../../Routes";
import PartnershipAccountAccessForm from "../containers/PartnershipAccountAccessFormContainer";

import PartnershipAccountForm from "../containers/PartnershipAccountFormContainer";
import PartnershipAccountAccessCareGuideForm from "../containers/PartnershipAccountAccessCareGuideFormContainer";

import PartnershipLogoForm from "../containers/PartnershipLogoFormContainer";
import DocumentHead from "../../../elements/components/DocumentHead";
import Logger from "../../../../../lib/Logger";
import { Redirect } from "react-router-dom";
import { pathTo } from "../../../Routes";
class PartnershipAccountScreen extends Component {
  scrollToTop = () => {
    Logger.log("debug", `PartnershipAccountScreen.scrollToTop()`);
    if (typeof window !== "undefined") {
      setTimeout(() => window.scrollTo(0, 0), 100);
    }
  };

  state = {
    redirect: null,
  };

  render() {
    const loadingIcon = (
      <Spin indicator={<LoadingOutlined style={{ fontSize: 20 }} spin />} />
    );

    const { redirect } = this.state;

    if (redirect) {
      return <Redirect to={pathTo(redirect)} />;
    }

    return (
      <Translation>
        {(t) => (
          <div>
            <DocumentHead title={t("partnership_account_route_manage")} />

            <Row gutter={24}>
              <Col xl={12} lg={24} md={24} sm={24} xs={24}>
                <Card
                  title={t("partnership_account_form_title")}
                  extra={this.props.isLoading ? loadingIcon : null}
                >
                  <PartnershipAccountForm />
                </Card>
                {this.props.role === 1 && (

                <Card
                    title={t("partnership_account_access_admin_form_title")}
                    extra={this.props.isLoading ? loadingIcon : null}
                  >
                    <PartnershipAccountAccessForm />
                  </Card>
                )}
              </Col>

              <Col xl={12} lg={24} md={24} sm={24} xs={24}>
                
                  <Card title={t("partnership_logo_form_title")}>
                    <PartnershipLogoForm />
                  </Card>
                  {this.props.role === 1 && (
                  <Card
                    title={t("partnership_account_access_admin_care_guide_form_title")}
                    extra={this.props.isLoading ? loadingIcon : null}
                  >
                    <PartnershipAccountAccessCareGuideForm />
                  </Card>
                  )}

              </Col>
            </Row>
          </div>
        )}
      </Translation>
    );
  }

  /* LIFECYCLE EVENTS */

  componentDidMount() {
    Logger.log("silly", `PartnershipAccountScreen.componentDidMount()`);
    this.scrollToTop();
  }

  componentDidUpdate(prevProps) {
    Logger.log("silly", `PartnershipAccountScreen.componentDidUpdate()`);
    const route = getRouteFromPath(this.props.location.pathname);
    const screen =  hasPermissionAdmin();
    if (this.props.activeId !== prevProps.activeId && route.screen !== pathRedirect(screen)[0]) {
        this.setState({ redirect: pathRedirect(screen)[0]});
      }
  }
}

export default PartnershipAccountScreen;

Logger.log("silly", `PartnershipAccountScreen loaded.`);
