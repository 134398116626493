import {getI18n} from 'react-i18next';

import Config from '../../../../Config';
import Logger from '../../../../lib/Logger';
import Events from '../../../../lib/EventEmitter';

import CareGuideDetailScreen from './containers/CareGuideDetailScreenContainer';

const register = () => {
  Logger.log('debug', `careGuides.register()`);

  if (Config.getIn(['MODULE_TOGGLES', 'careGuides', 'routes'])) {
    Events.dispatch('ADD_MAIN_ROUTES', 

      // screen name (key): [route type (element), path (prop), exact (prop), component (prop), route name]
      {
        'CareGuideDetailScreen': ['PrivateRoute', '/care-guide/:id([\\w\\-]+)/view', true, CareGuideDetailScreen, getI18n().t('care_guides_route_view')],
      }
    );
  }
}

export default register;

Logger.log('silly', `careGuides.register() loaded.`);
