import CareGuideDocumentPreviewModal from '../components/CareGuideDocumentPreviewModal';
import { schema } from '../../../../../state/schema';
import {
  careGuideDocumentPreviewDestroy,
  careGuideDocumentHidePreview,
  loadCareGuideDocuments,
  careGuideDocumentShowPreview,
  careGuideDocumentShowForm,
  downloadDocument,
} from '../../../../../state/modules/careGuideDocuments/actions';

import { denormalize } from 'normalizr';
import { connect } from 'react-redux';

const mapStateToProps = (state) => {
  const careGuideDocumentId = state.careGuideDocuments.get('id');

  const careGuideDocument = careGuideDocumentId
    ? denormalize(
        state.entities.getIn(['careGuideDocuments', careGuideDocumentId]),
        schema.careGuideDocument,
        state.entities.toJS()
      )
    : null;

  const data = {
    user_file_name: careGuideDocument ? careGuideDocument.user_file_name : '',
    description: careGuideDocument ? careGuideDocument.description : '',
    is_pinned: careGuideDocument ? careGuideDocument.is_pinned : false,
    file_name: careGuideDocument ? careGuideDocument.file_name : '',
    file_directory: careGuideDocument ? careGuideDocument.file_directory : '',
    file_bucket: careGuideDocument ? careGuideDocument.file_bucket : '',
    file_extension: careGuideDocument ? careGuideDocument.file_extension : '',
    size_bytes: careGuideDocument ? careGuideDocument.size_bytes : '',
  };

  return {
    visible: state.careGuideDocuments.get('isPreviewVisible'),
    careGuideDocumentId: careGuideDocumentId,
    data: data,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    previewDestroy: (previewState) => {
      dispatch(careGuideDocumentPreviewDestroy(previewState));
    },
    hidePreview: () => {
      dispatch(careGuideDocumentHidePreview());
    },
    load: (careGuideId, page, limit, order, filter, cb) => {
      dispatch(
        loadCareGuideDocuments(careGuideId, page, limit, order, filter, cb)
      );
    },
    showPreview: (careGuideDocumentId) => {
      dispatch(careGuideDocumentShowPreview(careGuideDocumentId));
    },
    showForm: (careGuideDocumentId) => {
      dispatch(careGuideDocumentShowForm(careGuideDocumentId));
    },
    download: (path, fileName, cb) => {
      dispatch(downloadDocument(path, fileName, cb));
    },
  };
};

const CareGuideDocumentPreviewModalContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(CareGuideDocumentPreviewModal);

export default CareGuideDocumentPreviewModalContainer;
