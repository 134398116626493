import {getI18n} from 'react-i18next';

import Config from '../../../../Config';
import Logger from '../../../../lib/Logger';
import Events from '../../../../lib/EventEmitter';

import CareGuidePartnerTagsAdminScreen from './containers/CareGuidePartnerTagsAdminScreenContainer';
import CareGuidePartnerTagAdminAddScreen from './containers/CareGuidePartnerTagAdminAddScreenContainer';
import CareGuidePartnerTagAdminEditScreen from './containers/CareGuidePartnerTagAdminEditScreenContainer';

const register = () => {
  Logger.log('debug', `careGuidePartnerTagsAdmin.register()`);

  if (Config.getIn(['MODULE_TOGGLES', 'careGuidePartnerTagsAdmin', 'routes'])) {
    Events.dispatch('ADD_ADMIN_ROUTES', 
      {
        'CareGuidePartnerTagAdminAddScreen': ['PrivateRoute', '/admin/care-guide-partner-tags/partnership/:id_partnership([\\w\\-]+)/add', true, CareGuidePartnerTagAdminAddScreen, getI18n().t('care_guide_partner_tag_route_add')],
        'CareGuidePartnerTagAdminEditScreen': ['PrivateRoute', '/admin/care-guide-partner-tags/partnership/:id_partnership([\\w\\-]+)/edit/:id([\\w\\-]+)', true, CareGuidePartnerTagAdminEditScreen, getI18n().t('care_guide_partner_tag_route_edit')],
        'CareGuidePartnerTagsAdminScreen': ['PrivateRoute', '/admin/care-guide-partner-tags/:page(\\d+)?', true, CareGuidePartnerTagsAdminScreen, getI18n().t('care_guide_partner_tags_route_list')],
      }
    );
  }
}

export default register;

Logger.log('silly', `careGuidePartnerTagsAdmin.register() loaded.`);
