import Logger from './Logger';

const QueryStringStrapi = {

  buildParams: function buildQueryString(data, esc, prefix = '') {
    let query_string = '';
    for (let key in data) {
        if (data.hasOwnProperty(key)) {
            let value = data[key];
            if (typeof value === 'object') {
                query_string += buildQueryString(value, esc, `${prefix}[${key}]`);
            } else {
                if (prefix) {
                    query_string += `${prefix}[${key}]=${esc(value)}&`;
                } else {
                    query_string += `${key}=${esc(value)}&`;
                }
            }
        }
    }
    return query_string;
},

  make: function (params) {
    if (!params) {
      return '';
    }
    const esc = encodeURIComponent;
    let query = "";
    query = QueryStringStrapi.buildParams(params, esc, "");
    return query;
  },

  append: function (path, params) {
    const qs = QueryStringStrapi.make(params);
    return qs
      ? (path.includes('?')
        ? path + qs
        : path + '?' + qs)
      : path;
  },

  parse: function (qs) {
    const params = {};
    const pairs = (qs[0] === '?' ? qs.substr(1) : qs).split('&');
    for (var i = 0; i < pairs.length; i++) {
      const pair = pairs[i].split('=');
      if (pair[0]) {
        params[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '');
      }
    }
    return params;
  }
};

export default QueryStringStrapi;

Logger.log('silly', `QueryStringStrapi loaded.`);
