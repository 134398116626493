import React, {useEffect, useState} from 'react';
import {Translation, getI18n} from 'react-i18next';
import {Col, Form, Input, Row} from 'antd';

import PlanCard from './PlanCard';
import Format from '../../../../../lib/Format';
import Logger from '../../../../../lib/Logger';


const PlanInput = ({page, limit, order, total, load, form, canChange=true, ...props}) => {

  const [value, setValue] = useState(form.getFieldValue('plan'))

  useEffect(() => {
    load(page, limit, order);
  }, [page, limit, order, load]);

  const plans = [];
  if (props.list) {
    for (const item of props.list){
      if (item.price_month !== null && item.month_price_id !== null) {
        plans.push({
          name: getI18n().t('plans_name_monthly', {name: item.name}),
          price: item.price_month,
          priceLabel: getI18n().t('plans_price_label_month', {price: Format.currency(item.price_month, true)}),
          description: item.description,
          planValue: `${item.id},M`,
          trialPeriod: item.month_trial_period ? getI18n().t('plans_trial_period', {days: item.month_trial_period}) : null,
        });
      }
      if (item.price_year !== null && item.year_price_id !== null) {
        plans.push({
          name: getI18n().t('plans_name_yearly', {name: item.name}),
          price: item.price_year,
          priceLabel: getI18n().t('plans_price_label_year', {price: Format.currency(item.price_year, true)}),
          description: item.description,
          planValue: `${item.id},Y`,
          trialPeriod: item.year_trial_period ? getI18n().t('plans_trial_period', {days: item.year_trial_period}) : null,
        });
      }
    }
  }

  const gridXS = 24;
  const gridSM = 12;
  const gridXL = plans.length % 2 === 0 ? 12 : 8;

  const selectPlan = (priceValue) => {
    if (canChange) {
      setValue(priceValue);
      form.setFieldsValue({'plan': priceValue});
    }
  }

  return (
    <Translation>{(t) => 
      <div className="plans-list">
        <Row gutter={16}>
          {plans
            ? plans.map((plan, i) => {
              const isSelected = value === plan.planValue;
              return (
                <Col key={i} xs={gridXS} sm={gridSM} xl={gridXL} style={{display: 'grid'}}>
                  <PlanCard
                    plan={plan}
                    isSelected={isSelected}
                    canChange={canChange}
                    onClick={() => selectPlan(plan.planValue)}
                  />
                </Col>
              );
            })
            : null}
        </Row>
        <Form.Item name='plan' hidden={true} rules={[{required: true, message: t('feedback_validation_required')}]}>
          <Input type="hidden" />
        </Form.Item>
      </div>
    }</Translation>
  )  
}

export default PlanInput;

Logger.log('silly', `PlanInput loaded.`);
