import { connect } from 'react-redux';

import { loadAdminPartnerClasses } from '../../../../../state/modules/adminPartnerClassesAdmin/actions';

import AdminPartnerClassInput from '../components/AdminPartnerClassInput';
import storage from "../../../../../lib/Storage";

const mapStateToProps = (state) => {

  const partnershipId = storage.get('local', 'partnershipId');
  
  return {
    partnershipId: partnershipId, 
    total: state.adminPartnerClassesAdmin.get('total'),
    isLoading: state.adminPartnerClassesAdmin.get('isLoading'),

  }
}

const mapDispatchToProps = dispatch => {
  return {
    load: (partnershipId, page, limit, order, filter, cb) => {
      dispatch(loadAdminPartnerClasses(partnershipId, page, limit, order, filter, cb));
    },
  }
}

const AdminPartnerClassInputContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminPartnerClassInput);

export default AdminPartnerClassInputContainer;
