import React from 'react';
import { Translation } from 'react-i18next';

const DescriptionToolbar = ({ isChangeValue }) => {
  return (
    <Translation>
      {(t) => (
        <>
          <div
            className="ql-toolbar ql-snow"
            id="toolbar-resource"
            style={
              !isChangeValue
                ? { display: 'none', borderBottom: '0px' }
                : { borderBottom: '0px', backgroundColor: '#FFFFFF' }
            }
          >
            <span class="ql-formats">
              <select class="ql-size"></select>
            </span>
            <span className="ql-formats">
              <button className="ql-bold"></button>
              <button className="ql-italic"></button>
              <button className="ql-underline"></button>
              <button class="ql-link"></button>
            </span>

            <span className="ql-formats">
              <button className="ql-list" value="ordered"></button>
              <button className="ql-list" value="bullet"></button>
            </span>
            <span className="ql-formats">
              <button className="ql-clean"></button>
            </span>
          </div>
        </>
      )}
    </Translation>
  );
};

export default DescriptionToolbar;
