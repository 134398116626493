import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { Translation, getI18n } from 'react-i18next';
import { Button, Checkbox, Form, Input } from 'antd';
import { CheckCircleOutlined } from '@ant-design/icons';

import FirstNameInput from './FirstNameInput';
import LastNameInput from './LastNameInput';
import PostalCodeInput from './PostalCodeInput';
import RelationshipInput from '../../relationships/containers/RelationshipInputContainer';
import QueryString from '../../../../../lib/QueryString';
import { pathTo } from '../../../Routes';
import message from '../../../elements/lib/MessageWrapper';
import Config from '../../../../../Config';
import Logger from '../../../../../lib/Logger';
import useWindowDimensions from '../../../../../lib/WindowDimensions';
import TagInput from '../containers/TagInputContainer';

const RegisterStep2Form = (props) => {
  const [firstNameHasError, setFirstNameHasError] = useState(false);
  const [lastNameHasError, setLastNameHasError] = useState(false);
  const [postalCodeHasError, setPostalCodeHasError] = useState(false);
  const [relationshipHasError, setRelationshipHasError] = useState(false);

  const [redirectTo, setRedirectTo] = useState(null);
  const [classes, setClasses] = useState([]);

  const [form] = Form.useForm();
  const { width } = useWindowDimensions();
  const tooltipPlacement = width <= 992 ? 'top' : 'right';

  // handle errors reported by API
  useEffect(() => {
    let firstFieldName = '';
    for (const field in props.errors) {
      form.setFields([{ name: field, errors: props.errors[field] }]);

      switch (field) {
        case 'care_guide_first_name':
          setFirstNameHasError(true);
          break;
        case 'care_guide_last_name':
          setLastNameHasError(true);
          break;
        case 'care_guide_postal_code':
          setPostalCodeHasError(true);
          break;
        case 'care_guide_relationship_id':
          setRelationshipHasError(true);
          break;
        default:
      }

      if (firstFieldName === '') {
        firstFieldName = field;
      }
    }
    form.scrollToField(firstFieldName);
  }, [form, props.errors]);

  // submit registration step 2 handler
  const submitData = (values) => {
    Logger.log('debug', `RegisterStep2Form.submitRegistrationStep2()`);
    // API POST/PUT payload
    let payload = {
      id: props.registrationId,
    };

    let registrations_care_partner_tags = [];

    for (const input of Object.keys(props.data)) {
      if (values[input]) {
        payload[input] = values[input];
      }
    }
    if (values['tos_id']) {
      payload['tos_id'] = props.tos_id;
    }

    for (const input of classes) {
      if (values[input] && values[input] !== '') {
        registrations_care_partner_tags.push(values[input]);
      }
    }

    payload['registrations_care_partner_tags'] =
      registrations_care_partner_tags;

    props.submit(payload, (success, registrationId, registrationCode) => {
      if (success) {
        if (registrationCode === 3) {
          props.createAccountClient(
            { id: registrationId },
            (createAccountClientSuccess) => {
              if (createAccountClientSuccess) {
                message.success(getI18n().t('register_client_message_success'));
                setRedirectTo(
                  pathTo(Config.get('DEFAULT_REGISTRATION_REDIRECT'))
                );
              } else {
                message.error(getI18n().t('register_client_message_failure'));
              }
            }
          );
        } else {
          props.createAccount(
            { id: registrationId },
            (
              createAccountSuccess,
              accountId,
              careGuideId,
              userID,
              consentURL,
              price
            ) => {
              if (createAccountSuccess) {
                // if plan has a consent URL redirect to that
                if (consentURL) {
                  // create external URL to redirect back to once consent is submitted
                  const redirectURL =
                    window.location.href.split('/').slice(0, 3).join('/') +
                    (Config.getIn(['MODULE_TOGGLES', 'payments', 'enabled'])
                      ? pathTo('PaymentRegistrationScreen', null, {
                          aid: accountId,
                          rid: registrationId,
                        })
                      : pathTo(Config.get('DEFAULT_REGISTRATION_REDIRECT')));

                  // redirect
                  window.location.replace(
                    QueryString.append(consentURL, {
                      uid: userID,
                      redirect: redirectURL,
                    })
                  );
                } else {
                  // payments module turned on - determine if user should go to checkout or dashboard
                  if (Config.getIn(['MODULE_TOGGLES', 'payments', 'enabled'])) {
                    if (!price) {
                      // plan has no price, skip checkout and go to dashboard or consent URL
                      setRedirectTo(
                        pathTo(Config.get('DEFAULT_REGISTRATION_REDIRECT'))
                      );
                    } else {
                      // plan has a price, go to checkout
                      setRedirectTo(
                        pathTo('PaymentRegistrationScreen', null, {
                          aid: accountId,
                          rid: registrationId,
                        })
                      );
                    }
                    // payments module turned off, go to dashboard or consent URL
                  } else {
                    message.success(
                      getI18n().t('register_form2_message_success')
                    );
                    setRedirectTo(
                      pathTo(Config.get('DEFAULT_REGISTRATION_REDIRECT'))
                    );
                  }
                }
              } else {
                message.error(getI18n().t('register_form2_message_failure'));
              }
            }
          );
        }
      } else {
        message.error(getI18n().t('register_form2_message_failure'));
      }
    });
  };

  // form submit handler
  const handleFinish = async (values) => {
    Logger.log('debug', `RegisterStep2Form.handleFinish(###)`);
    if (!props.isSubmitting) {
      await submitData(values);
    }
  };

  // form error handler
  const handleFinishFailed = ({ values, errorFields, outOfDate }) => {
    Logger.log('debug', `RegisterStep2Form.RegisterStep1Form(###)`);
    message.error(getI18n().t('register_form2_message_failure'));
    if (errorFields && errorFields.length > 0) {
      form.scrollToField(errorFields[0].name);

      for (const field of errorFields) {
        switch (field.name[0]) {
          case 'care_guide_first_name':
            setFirstNameHasError(true);
            break;
          case 'care_guide_last_name':
            setLastNameHasError(true);
            break;
          case 'care_guide_postal_code':
            setPostalCodeHasError(true);
            break;
          case 'care_guide_relationship_id':
            setRelationshipHasError(true);
            break;
          default:
        }
      }
    }
  };

  // remove error message when input value changes
  const handleValuesChange = (changedValues, allValues) => {
    for (const key of Object.keys(changedValues)) {
      form.setFields([{ name: key, errors: [] }]);
      switch (key) {
        case 'care_guide_first_name':
          setFirstNameHasError(false);
          break;
        case 'care_guide_last_name':
          setLastNameHasError(false);
          break;
        case 'care_guide_postal_code':
          setPostalCodeHasError(false);
          break;
        case 'care_guide_relationship_id':
          setRelationshipHasError(false);
          break;
        default:
      }
    }
  };

  if (redirectTo) {
    return <Redirect to={redirectTo} />;
  }

  return (
    <Translation>
      {(t) => (
        <div className="register-form register-form-step2">
          <Form
            name="register_step2_form"
            layout="vertical"
            form={form}
            onFinish={handleFinish}
            onFinishFailed={handleFinishFailed}
            onValuesChange={handleValuesChange}
            validateTrigger="onSubmit"
          >
            <FirstNameInput
              name="care_guide_first_name"
              label={t('register_form2_input_first_name')}
              disabled={props.isSubmitting}
              autoFocus
              useTooltip={false}
              tooltipPlacement={tooltipPlacement}
              hasError={firstNameHasError}
            />

            <LastNameInput
              name="care_guide_last_name"
              label={t('register_form2_input_last_name')}
              disabled={props.isSubmitting}
              useTooltip={false}
              tooltipPlacement={tooltipPlacement}
              hasError={lastNameHasError}
            />

            <PostalCodeInput
              name="care_guide_postal_code"
              label={t('register_form2_input_postal_code')}
              disabled={props.isSubmitting}
              useTooltip={false}
              tooltipPlacement={tooltipPlacement}
              hasError={postalCodeHasError}
            />

            <RelationshipInput
              name="care_guide_relationship_id"
              label={t('relationships_form_input_relationship_id')}
              hasError={relationshipHasError}
            />

            {props.partnershipId && (
              <>
                <div className="form-group">
                  <TagInput
                    partnershipId={props.partnershipId}
                    label={t('register_form_care_guide_partner_tags')}
                    disabled={props.isSubmitting}
                    setClasses={setClasses}
                  />
                </div>
                <Form.Item
                  style={{ display: 'none' }}
                  name="registrations_care_partner_tags"
                >
                  <Input type="hidden" />
                </Form.Item>
                {form.getFieldError('registrations_care_partner_tags') && (
                  <h2 className="error-label">
                    {form.getFieldError('registrations_care_partner_tags')}
                  </h2>
                )}
              </>
            )}

            <div className="form-group">
              <Form.Item
                name="tos_id"
                valuePropName="checked"
                className="tos-row"
                rules={[
                  {
                    required: true,
                    transform: (value) => value || undefined,
                    type: 'boolean',
                    message: t('feedback_validation_tos'),
                  },
                ]}
              >
                <Checkbox disabled={props.isSubmitting}>
                  {t('register_form1_input_tos')}{' '}
                  <span className="required-mark">*</span>
                </Checkbox>
              </Form.Item>
            </div>

            <div className="form-actions">
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  size={'large'}
                  className="login-button btn-large-round"
                  icon={<CheckCircleOutlined />}
                  loading={props.isSubmitting}
                  block={true}
                >
                  {t('register_form2_button_submit')}
                </Button>
              </Form.Item>
            </div>
          </Form>
        </div>
      )}
    </Translation>
  );
};

export default RegisterStep2Form;
