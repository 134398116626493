import {getI18n} from 'react-i18next';

import Config from '../../../../Config';
import Logger from '../../../../lib/Logger';
import Events from '../../../../lib/EventEmitter';

import JournalPostsScreen from './containers/JournalPostsScreenContainer';

const register = () => {
  Logger.log('debug', `journalPosts.register()`);

  if (Config.getIn(['MODULE_TOGGLES', 'journalPosts', 'routes'])) {
    Events.dispatch('ADD_MAIN_ROUTES', 

      // screen name (key): [route type (element), path (prop), exact (prop), component (prop), route name]
      {
        'JournalPostsScreen': ['PrivateRoute', '/care-guide/:id([\\w\\-]+)/journal/:page(\\d+)?', true, JournalPostsScreen, getI18n().t('journal_posts_route_list')],
      }
    );
  }
}

export default register;

Logger.log('silly', `journalPosts.register() loaded.`);
