import React, {useEffect} from 'react';
import {Translation} from 'react-i18next';
import {Alert, Spin} from 'antd';

import Logger from '../../../../../lib/Logger';

const EmailConfirmation = ({token, isVerified, confirmEmailAddress, ...props}) => {

  useEffect(() => {
    if (!isVerified) {
      confirmEmailAddress({
        token: token,
      });  
    }
  }, [token, isVerified, confirmEmailAddress]);

  return(
    <Translation>{(t) => 
      <>
        <Spin spinning={props.isSubmitting}>
          <Alert
            message={isVerified
              ? t('user_account_email_confirmation_title_success')
              : (props.isSubmitting
                ? t('user_account_email_confirmation_title_submitting')
                : t('user_account_email_confirmation_title_failure')
            )}
            description={isVerified
              ? t('user_account_email_confirmation_body_success')
              : (props.isSubmitting
                ? t('user_account_email_confirmation_body_submitting')
                : t('user_account_email_confirmation_body_failure')
            )}
            type={isVerified ? "success" : (props.isSubmitting ? "info" : "error")}
          />
        </Spin>
      </>
    }</Translation>
  );
}

export default EmailConfirmation;

Logger.log('silly', `EmailConfirmation loaded.`);
