import {connect} from 'react-redux';
import {denormalize} from 'normalizr';
import {
  messageComposeChat
} from '../../../../../state/modules/messages/actions';

import MessagesThreadScreen from '../components/MessagesThreadScreen';
import {schema} from '../../../../../state/schema';
import {getRouteFromPath} from '../../../Routes';

const mapStateToProps = (state, ownProps) => {

  const currentScreen = getRouteFromPath(ownProps.location.pathname).screen;
  const threadId = ownProps.match.params.threadId;

  return {
    thread: denormalize(
      state.entities.getIn(['messageThreads', threadId]),
      schema.messageThread,
      state.entities.toJS()
    ),
    isLoading: state.messages.get('isLoading'),
    currentScreen: currentScreen,
    profileId: state.session.get('profileId'),
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    thread_id: (threadId, recipientIds) => {
      dispatch(messageComposeChat(threadId, null, recipientIds));
      }
  }
}

const MessagesThreadScreenContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(MessagesThreadScreen);

export default MessagesThreadScreenContainer;
