import {connect} from 'react-redux';
import {denormalize} from 'normalizr';

import {schema} from '../../../../../state/schema';
import PlanSelected from '../components/PlanSelected';

const mapStateToProps = (state, ownProps) => {

  // get plan from state
  const planId = 'id' in ownProps ? ownProps.id : null;
  const plan = planId
    ? denormalize(
        state.entities.getIn(['plans', planId]),
        schema.plan,
        state.entities.toJS()
      )
    : null;

  return {
    plan: plan,
  }
}

const PlanSelectedContainer = connect(
  mapStateToProps
)(PlanSelected);

export default PlanSelectedContainer;
