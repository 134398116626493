import React, { useEffect, useState, useRef } from 'react';
import { Translation, getI18n } from 'react-i18next';
import { Button, Form, Modal, Upload, Col, Row } from 'antd';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { FaFileImage } from 'react-icons/fa';

import BodyInput from './BodyInput';
import RatingInput from './RatingInput';
import TagInput from '../../journalTags/containers/TagInputContainer';

import message from '../../../elements/lib/MessageWrapper';
import imageURL from '../../../elements/lib/imageURL';
import Logger from '../../../../../lib/Logger';
import {
  MixpanelTracker,
  mixpanelEventsEnum,
} from '../../../../../lib/Mixpanel';

const JournalPostModalForm = ({
  careGuideId,
  careGuide,
  postId,
  page,
  limit,
  order,
  errors,
  isSubmitting,
  data,
  ...props
}) => {
  const [resetBody, setResetBody] = useState(null);
  const [fileList, setFileList] = useState([]);
  const [form] = Form.useForm();

  const editorRef = useRef();

  // handle errors reported by API
  useEffect(() => {
    let firstFieldName = '';
    for (const field in errors) {
      form.setFields([{ name: field, errors: errors[field] }]);
      if (firstFieldName === '') {
        firstFieldName = field;
      }
    }
    form.scrollToField(firstFieldName);
  }, [form, errors]);

  // focus on body input when showing form
  useEffect(() => {
    if (props.visible) {
      if (editorRef.current) {
        setTimeout(() => editorRef.current.focus(), 250);
      }
    }
  }, [props.visible, editorRef]);

  useEffect(() => {
    form.setFieldsValue(data);
    fileMap.current = {};
    setFileList(
      data.media.map((x) => {
        const filename = x.file_name + '.' + x.file_extension;
        fileMap.current[x.id] = x.id;
        const thumb =
          x && x.file_name && x.file_extension && x.file_directory
            ? imageURL(
                x.file_directory,
                x.file_name,
                x.file_extension,
                60,
                60,
                'ratio'
              )
            : null;
        return {
          name: filename,
          thumbUrl: thumb,
          uid: x.id,
        };
      })
    );
  }, [postId, form, setFileList]); // eslint-disable-line react-hooks/exhaustive-deps

  const trackerJournalPost = () => {
    if (careGuide?.partnership) {
      MixpanelTracker.mixpanelTrack(mixpanelEventsEnum.JOURNAL.EDIT_POST, {
        careGuideName: `${careGuide.first_name} ${careGuide.last_name}`,
        partnershipName: careGuide.partnership.name,
        careGuideId: careGuide.id,
        partnershipId: careGuide.partnership.id,
        journalPostId: postId,
      });
    } else {
      MixpanelTracker.mixpanelTrack(mixpanelEventsEnum.JOURNAL.EDIT_POST, {
        careGuideName: `${careGuide.first_name} ${careGuide.last_name}`,
        careGuideId: careGuide.id,
        journalPostId: postId,
      });
    }
  };

  // submit data handler
  const submitData = async (values) => {
    Logger.log('debug', `JournalPostModalForm.submitData(###)`);

    const payload = {
      media_ids: Object.keys(fileMap.current).map((x) => fileMap.current[x]),
    };

    // transform values
    for (var key in values) {
      if (values[key]) {
        payload[key] = values[key];
      }
    }

    props.patch(careGuideId, postId, payload, (success) => {
      if (success) {
        message.success(getI18n().t('feedback_form_success'));
        clearForm();
        fileMap.current = {};
        setFileList([]);
        trackerJournalPost();
      } else {
        message.error(getI18n().t('feedback_form_error'));
      }
    });
  };

  const clearForm = () => {
    props.hideForm();
    props.formDestroy();
    form.setFieldsValue({ body: '', rating: '', tags: [] });
    setResetBody(Math.random());
    for (const key of Object.keys(data)) {
      form.setFields([{ name: key, errors: [] }]);
    }
  };

  // form submit handler
  const handleFinish = async (values) => {
    Logger.log('debug', `JournalPostModalForm.handleFinish(###)`);
    if (!props.isSubmitting) {
      await submitData(values);
    }
  };

  // form error handler
  const handleFinishFailed = ({ values, errorFields, outOfDate }) => {
    Logger.log('debug', `JournalPostModalForm.handleFinishFailed(###)`);
    message.error(getI18n().t('feedback_form_error'));
    if (errorFields && errorFields.length > 0) {
      form.scrollToField(errorFields[0].name);
    }
  };

  // remove error message when input value changes
  const handleValuesChange = (changedValues, allValues) => {
    for (const key of Object.keys(changedValues)) {
      form.setFields([{ name: key, errors: [] }]);
    }
  };

  // upload criteria
  const fileTypes = {
    'image/png': 'png',
    'image/jpeg': 'jpg',
  };
  const fileSizeLimit = 1024 * 1024 * 6;
  const reFileKey = /(?:(.*?)\/)?(.*?)\.(\w+)/;
  const fileMap = useRef({});
  const maxCount = 3;

  const errorMessage = () => {
    message.error(
      getI18n().t('journal_posts_form_feedback_image_upload_error')
    );
  };

  const beforeUpload = (file) => {
    const isUnderMaxCount = Object.keys(fileMap.current).length < maxCount;
    if (!isUnderMaxCount) {
      message.error(
        getI18n().t('journal_posts_form_feedback_image_file_count_limit')
      );
    }

    const isSupportedFileType = Object.keys(fileTypes).includes(file.type);
    if (!isSupportedFileType) {
      message.error(
        getI18n().t('journal_posts_form_feedback_image_file_types')
      );
    }

    const isUnderFileSizeLimit = file.size < fileSizeLimit;
    if (!isUnderFileSizeLimit) {
      message.error(
        getI18n().t('journal_posts_form_feedback_image_file_size_limit')
      );
    }

    return isUnderMaxCount && isSupportedFileType && isUnderFileSizeLimit
      ? true
      : Upload.LIST_IGNORE;
  };

  const customRequest = (e) => {
    props.getUploadUrl(
      e.file.name.split('.').pop(),
      'journal-posts',
      async (success, upload_url, file_id) => {
        if (success) {
          // upload image
          props.upload(upload_url, e.file, async (uploadSuccess) => {
            const directory = 'journal-posts/';
            file_id = file_id.replace(directory, '');
            if (uploadSuccess) {
              const matches = reFileKey.exec(file_id);
              const updateData = {
                file_directory: directory.slice(0, -1),
                file_name: matches[2],
                file_extension: matches[3],
              };

              // process image
              props.createImage(
                careGuideId,
                updateData,
                (createSuccess, imageId) => {
                  if (createSuccess) {
                    fileMap.current[e.file.uid] = imageId;
                    e.onSuccess(null, e.file);
                    props.patch(careGuideId, postId, {
                      media_ids: Object.keys(fileMap.current).map(
                        (x) => fileMap.current[x]
                      ),
                    });
                    trackerJournalPost();
                  } else {
                    errorMessage();
                  }
                }
              );
            } else {
              errorMessage();
            }
          });
        } else {
          errorMessage();
        }
      }
    );
  };

  const onUploadChange = (info) => {
    setFileList(info.fileList);
  };

  const onRemove = async (file) => {
    if (file.uid in fileMap.current) {
      await props.deleteImage(
        careGuideId,
        fileMap.current[file.uid],
        (success) => {
          delete fileMap.current[file.uid];
          if (success) {
            props.patch(careGuideId, postId, {
              media_ids: Object.keys(fileMap.current).map(
                (x) => fileMap.current[x]
              ),
            });
            trackerJournalPost();
          }
        }
      );
    }
  };

  const onClickOK = () => {
    form.submit();
  };

  return (
    <Translation>
      {(t) => (
        <>
          <Modal
            title={t('journal_posts_form_title_edit')}
            centered
            visible={props.visible}
            onOk={onClickOK}
            onCancel={clearForm}
            footer={[
              <Button
                key="close"
                icon={<CloseCircleOutlined />}
                onClick={clearForm}
              >
                {t('action_close')}
              </Button>,
              <Button
                key="submit"
                type="primary"
                icon={<CheckCircleOutlined />}
                loading={isSubmitting}
                onClick={onClickOK}
              >
                {t('journal_posts_form_button_update')}
              </Button>,
            ]}
            width={600}
            forceRender={true}
          >
            <div className="journal-post-form">
              <Form
                name="journal_post_form"
                form={form}
                initialValues={data}
                onFinish={handleFinish}
                onFinishFailed={handleFinishFailed}
                onValuesChange={handleValuesChange}
                validateTrigger="onSubmit"
                requiredMark={true}
                colon={false}
                layout="inline"
                labelWrap={true}
              >
                <RatingInput
                  name="rating"
                  label={t('journal_posts_form_input_rating', {
                    name: careGuide ? careGuide.first_name : 'recipient',
                  })}
                  rules={[
                    {
                      required: true,
                      message: t('feedback_validation_required'),
                    },
                  ]}
                  disabled={isSubmitting}
                />

                <BodyInput
                  name="body"
                  label={false}
                  form={form}
                  defaultValue={data['body']}
                  rules={[
                    {
                      required: true,
                      message: t('feedback_validation_required'),
                    },
                    {
                      type: 'string',
                      min: 1,
                      max: 8000,
                      message: t('feedback_validation_char_range', {
                        min: 1,
                        max: 8000,
                      }),
                    },
                  ]}
                  disabled={isSubmitting}
                  reset={resetBody}
                  editorRef={editorRef}
                />

                <Row>
                  <Col xs={{ span: 24, order: 2 }} sm={{ span: 6, order: 1 }}>
                    <Upload
                      beforeUpload={beforeUpload}
                      customRequest={customRequest}
                      onChange={onUploadChange}
                      onRemove={onRemove}
                      maxCount={maxCount}
                      fileList={fileList}
                      listType="picture"
                    >
                      <Button
                        icon={
                          <span className="anticon">
                            <FaFileImage />
                          </span>
                        }
                      >
                        {t('journal_posts_form_button_upload_image')}
                      </Button>
                    </Upload>
                  </Col>

                  <Col
                    xs={{ span: 24, order: 1 }}
                    sm={{ span: 18, order: 2 }}
                    className="journal-tags-input-container"
                  >
                    <TagInput
                      name="tags"
                      label={t('journal_posts_form_input_tags')}
                      rules={[
                        {
                          required: true,
                          message: t('feedback_validation_required'),
                        },
                      ]}
                      disabled={isSubmitting}
                    />
                  </Col>
                </Row>

                <div style={{ display: 'none' }}>
                  <Button htmlType="submit"></Button>
                </div>
              </Form>
            </div>
          </Modal>
        </>
      )}
    </Translation>
  );
};

export default JournalPostModalForm;

Logger.log('silly', `JournalPostModalForm loaded.`);
