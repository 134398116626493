import React, { useState, useEffect } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { Translation, getI18n } from 'react-i18next';
import { Button, Form, Steps } from 'antd';
import { CheckCircleOutlined } from '@ant-design/icons';

import EmailAddressInput from '../../register/components/EmailAddressInput';
import { pathTo } from '../../../Routes';
import message from '../../../elements/lib/MessageWrapper';
import Logger from '../../../../../lib/Logger';

const PasswordResetRequestForm = (props) => {
  const [redirectTo, setRedirectTo] = useState(null);
  const [emailHasError, setEmailHasError] = useState(false);
  const [form] = Form.useForm();

  // handle errors reported by API
  useEffect(() => {
    let firstFieldName = '';
    for (const field in props.errors) {
      form.setFields([{ name: field, errors: props.errors[field] }]);

      switch (field) {
        case 'email':
          setEmailHasError(true);
          break;
        default:
      }

      if (firstFieldName === '') {
        firstFieldName = field;
      }
    }
    form.scrollToField(firstFieldName);
  }, [form, props.errors]);

  const submitData = (values) => {
    Logger.log('debug', `PasswordResetRequestForm.submit()`);

    // API POST/PUT payload
    let payload = {};
    for (const input of Object.keys(props.data)) {
      if (values[input]) {
        payload[input] = values[input];
      }
    }

    // register
    props.submit(payload, (success) => {
      if (success) {
        message.success(
          getI18n().t('session_password_reset_request_form_message_success')
        );
        setRedirectTo(pathTo('PasswordResetScreen'));
      } else {
        message.error(
          getI18n().t('session_password_reset_request_form_message_failure')
        );
      }
    });
  };

  // form submit handler
  const handleFinish = async (values) => {
    Logger.log('debug', `PasswordResetRequestForm.handleFinish(###)`);
    if (!props.isSubmitting) {
      await submitData(values);
    }
  };

  // form error handler
  const handleFinishFailed = ({ values, errorFields, outOfDate }) => {
    Logger.log('debug', `PasswordResetRequestForm.handleFinishFailed(###)`);
    message.error(
      getI18n().t('session_password_reset_request_form_message_failure')
    );
    if (errorFields && errorFields.length > 0) {
      form.scrollToField(errorFields[0].name);

      for (const field of errorFields) {
        switch (field.name[0]) {
          case 'email':
            setEmailHasError(true);
            break;
          default:
        }
      }
    }
  };

  // remove error message when input value changes
  const handleValuesChange = (changedValues, allValues) => {
    for (const key of Object.keys(changedValues)) {
      form.setFields([{ name: key, errors: [] }]);
      switch (key) {
        case 'email':
          setEmailHasError(false);
          break;
        default:
      }
    }
  };

  if (redirectTo) {
    return <Redirect to={redirectTo} />;
  }

  return (
    <Translation>
      {(t) => (
        <div>
          <Steps size="small" current={0} style={{ marginBottom: '30px' }}>
            <Steps.Step
              title={t('session_password_reset_form_steps_step1_title')}
              description={t(
                'session_password_reset_form_steps_step1_description'
              )}
            />
            <Steps.Step
              title={t('session_password_reset_form_steps_step2_title')}
            />
          </Steps>
          <Form
            hideRequiredMark
            layout="vertical"
            name="password_request_form"
            form={form}
            onFinish={handleFinish}
            onFinishFailed={handleFinishFailed}
            onValuesChange={handleValuesChange}
            validateTrigger="onSubmit"
          >
            <EmailAddressInput
              name="email"
              label={t(
                'session_password_reset_request_form_input_email_address'
              )}
              disabled={props.isSubmitting}
              useTooltip={false}
              tooltipPlacement={false}
              hasError={emailHasError}
              help={t('session_password_reset_screen_instructions')}
            />

            <div className="form-actions">
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="password-reset-request-button login-button btn-large-round"
                  icon={<CheckCircleOutlined />}
                  loading={props.isSubmitting}
                  block={true}
                  size="large"
                  style={{ marginTop: '8px' }}
                >
                  {t('session_password_reset_request_form_button_submit')}
                </Button>
              </Form.Item>

              <div className="already-code">
                <Link to={pathTo('PasswordResetScreen')}>
                  {t('session_password_reset_request_form_link_have_code')}
                </Link>
              </div>
            </div>
          </Form>
        </div>
      )}
    </Translation>
  );
};

export default PasswordResetRequestForm;
