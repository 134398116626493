import React, {useEffect, useState} from 'react';
import {Translation, getI18n} from 'react-i18next';
import {Button, Col, Row, Typography} from 'antd';
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';

import CheckoutForm from '../containers/CheckoutFormContainer';
import PlanForm from '../../plans/containers/PlanFormContainer';
import PlanSelected from '../../plans/containers/PlanSelectedContainer';
import Loading from '../../../elements/components/Loading';
import message from '../../../elements/lib/MessageWrapper';
import Config from '../../../../../Config';
import Logger from '../../../../../lib/Logger';
import imageCreditCards from "../../../assets/images/credit-card-logos-stripe1.png";


const stripePromise = Config.get('STRIPE_API_KEY') ? loadStripe(Config.get('STRIPE_API_KEY')) : null;

const PaymentRegistrationFlow = ({account, accountId, planId, subscriptionCycle, registrationId, loadAccount, createCustomer, createSubscription, ...props}) => {

  const canChangePlan = false;
  const [isAccountLoaded, setIsAccountLoaded] = useState(false);
  const [isChangingPlans, setIsChangingPlans] = useState(false);

  // load account
  useEffect(() => {
    loadAccount(accountId, (success) => {
      if (success) {
        setIsAccountLoaded(true);
      }
    });
  }, [accountId, loadAccount, setIsAccountLoaded]);

  // create customer and subscription
  useEffect(() => {
    if (accountId && planId && subscriptionCycle) {
      createCustomer((ccSuccess) => {
        if (ccSuccess) {
          const payload = {
            account_id: accountId,
            plan_id: planId,
            subscription_cycle: subscriptionCycle,
            registration_id: registrationId,
          };
          createSubscription(payload, (csSuccess) => {
            if (!csSuccess) {
              message.error(getI18n().t('payments_screen_message_failure'));
            }
          });
        } else {
          message.error(getI18n().t('payments_screen_message_failure'));
        }
      });
    }
  }, [accountId, planId, subscriptionCycle, registrationId, createCustomer, createSubscription]);

  const onClickChangePlansHandler = (e) => {
    setIsChangingPlans(true);
  }

  const options = props.clientSecret
    ? {clientSecret: props.clientSecret}
    : null;

  return (
    <Translation>{(t=>
      <>

        {isAccountLoaded && (isChangingPlans || (!planId || !subscriptionCycle))
          ? <PlanForm accountId={accountId} setIsChangingPlans={setIsChangingPlans.bind(this)} />
          : null}

        {isAccountLoaded && planId && subscriptionCycle && !isChangingPlans
          ? <Row type="flex" justify="space-around" align="middle">
              <Col xs={24} sm={18} md={16} lg={14} xl={12} xxl={10}>
                <PlanSelected id={planId} cycle={subscriptionCycle} />
                {canChangePlan ? <Button type="link" onClick={onClickChangePlansHandler}>Change plans</Button> : null}
              </Col>
            </Row>
          : null}

        {props.isSubmittingCustomer || props.isSubmittingSubscription
          ? <div style={{marginTop: 50}}><Loading /></div>
          : null}

        {stripePromise && options && !isChangingPlans
          ? <>

              <h3>{t('payemnts_payment_form_header')}</h3>
              <div className="payments-powered-by">
                <img src={imageCreditCards} alt={t('payments_powered_by')} />
              </div>

              <Elements stripe={stripePromise} options={options}>
                <CheckoutForm intent={props.intent} clientSecret={props.clientSecret} />
              </Elements>

              {props.trialPeriod
                ? <div className='stripe-payment-contact-support'>
                    <Typography.Text type="default">
                      <div dangerouslySetInnerHTML={{__html: t('payemnts_payment_form_contact_support', {trialPeriod: props.trialPeriod, interpolation: {escapeValue: false}})}} />
                    </Typography.Text>
                  </div>
                : null}
              
            </>
          : null}
      </>
    )}</Translation>
  );
};

export default PaymentRegistrationFlow;

Logger.log('silly', `PaymentRegistrationFlow loaded.`);
