import React from 'react';
import { Translation } from 'react-i18next';
import { Divider } from 'antd';


const Toolbar = () => {

    return (
        <Translation>{(t) =>
            <>
                <div
                    className='ql-toolbar ql-snow'
                    id='toolbar-care-guide-note'>
                    <span className='ql-formats'>
                        <button className='ql-bold'></button>
                        <button className='ql-italic'></button>
                        <button className='ql-underline'></button>
                        <button className='ql-strike'></button>
                    </span>
                    <Divider type="vertical" className="format-divider" />
                    <span className="ql-formats">
                        <button className="ql-align" value=""></button>
                        <button className="ql-align" value="center"></button>
                        <button className="ql-align" value="justify"></button>
                    </span>
                    <Divider type="vertical" className="format-divider" />
                    <span className="ql-formats">
                        <button className="ql-list" value="bullet"></button>
                    </span>
                </div>
            </>
        }</Translation>
    );
};

export default Toolbar;