import React from 'react';
import {Translation} from 'react-i18next';
import {Anchor} from 'antd';
import {PlusCircleOutlined} from '@ant-design/icons';

import hasPermission, { UPDATE_CARE_GUIDE } from '../../../elements/lib/hasPermission';
import useWindowDimensions from '../../../../../lib/WindowDimensions';

const CareGuideMenu = ({careGuide, onClickAnchorHandler, ...props}) => {

  const {width} = useWindowDimensions();

  return (
    <Translation>{(t) => 
      <Anchor
        className="care-guide-segments"
        onClick={onClickAnchorHandler}
        affix={width > 992}
      >
        <Anchor.Link title={t('care_guides_submenu_item_summary')} href="#summary"></Anchor.Link>
        {careGuide && careGuide.segments && careGuide.segments.length > 0
          ? careGuide.segments.map((segment, i) => segment
            ? <Anchor.Link key={i} title={segment.name} href={'#' + segment.id}></Anchor.Link>
            : null)
          : null}
        {hasPermission(UPDATE_CARE_GUIDE)
          ? <Anchor.Link
              title={<><PlusCircleOutlined /> {t('care_guides_submenu_item_create')}</>}
              href="#create"
              className="create-link"
            ></Anchor.Link>
          : null}
      </Anchor>
    }</Translation>
  );
}

export default CareGuideMenu;
