import { Map, List } from 'immutable';

import {
  CARE_GUIDES_PARTNERSHIP_LIST_REQUEST,
  CARE_GUIDES_PARTNERSHIP_LIST_SUCCESS,
  CARE_GUIDES_PARTNERSHIP_LIST_FAILURE,
} from './actions';
import Logger from '../../../lib/Logger';

export default function careGuidesPartnership(
  state = Map({
    isLoading: false,
    areCareGuidesLoading: false,
  }),
  action
) {
  Logger.log(
    'debug',
    `[careGuidesPartnership.reducers] careGuidesPartnership(%j, %j)`,
    state,
    action
  );

  switch (action.type) {
    case CARE_GUIDES_PARTNERSHIP_LIST_REQUEST:
      return state.mergeDeep({
        areCareGuidesLoading: true,
      });

    case CARE_GUIDES_PARTNERSHIP_LIST_SUCCESS:
      return state
        .mergeDeep({
          areCareGuidesLoading: false,
          pages: {
            [action.partnershipId]: {
              [action.limit]: {
                [action.page]: null,
              },
            },
          },
          total: action.total,
          lastUpdated: action.receivedAt,
        })
        .setIn(
          ['pages', action.partnershipId, action.limit, action.page],
          List(action.result)
        );

    case CARE_GUIDES_PARTNERSHIP_LIST_FAILURE:
      return state.mergeDeep({
        areCareGuidesLoading: false,
      });

    default:
      return state;
  }
}

Logger.log('silly', `careGuidesPartnership.reducers loaded.`);
