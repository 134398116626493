import {connect} from 'react-redux';
import {denormalize} from 'normalizr';

import JournalPostsScreen from '../components/JournalPostsScreen';
import {schema} from '../../../../../state/schema';
import {loadJournalPost} from '../../../../../state/modules/journalPosts/actions';
import QueryString from '../../../../../lib/QueryString';
import { careGuideSetActive, careGuideSetAccess } from '../../../../../state/modules/careGuides/actions';


const mapStateToProps = (state, ownProps) => {
  const page = parseInt(ownProps.match.params.page ? ownProps.match.params.page : 1);
  const params = QueryString.parse(ownProps.location.search);

  const activeId = state.careGuides.get("activeId") ?? ownProps.match.params.id;

  const careGuide = activeId
    ? denormalize(
      state.entities.getIn(['careGuides', activeId]),
      schema.careGuide,
      state.entities.toJS()
    )
    : null;

  return {
    journal: 'id' in params
      ? {...denormalize(
          state.entities.getIn(['journalPosts', params['id']]),
          schema.journalPost,
          state.entities.toJS()
        )}
      : null,
    isLoading: state.journalPosts.get('isLoading'),
    page: page,
    careGuide: careGuide,
    careGuideId: activeId,
    isCareGuideLoading: careGuide === undefined ? true : false,
    isCareGuideActive: careGuide?.account?.is_subscription_valid,
    careGuideParnetshipStatus: careGuide?.partnership?.status,
    accessCareGuide: state.careGuides.get('accessCareGuide'),
  }
}

const mapDispatchToProps = dispatch => {
  return {
    loadJournal: (careGuideId, id, cb) => {
      dispatch(loadJournalPost(careGuideId, id, cb));
    },
    setActive: (id) => {
      dispatch(careGuideSetActive(id));
    },
    setActiveAccess: (data) => {
      dispatch(careGuideSetAccess(data));
    },
  }
}

const JournalPostsScreenContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(JournalPostsScreen);

export default JournalPostsScreenContainer;
