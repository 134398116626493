import { connect } from 'react-redux';

import { loadPartnerships } from '../../../../../state/modules/partnerships/actions';

import PartnershipInput from '../components/PartnershipInput';

const mapStateToProps = (state) => {
  const partnerships = state.entities.get('partnerships', {});
  return {
    options: Object.keys(partnerships).map((id) => {
      return { value: id, label: partnerships[id].name };
    }),
    isLoading: state.partnerships.get('isLoading'),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    load: (page, limit, order, filter, cb) => {
      dispatch(loadPartnerships(page, limit, order, filter, cb));
    },
  };
};

const PartnershipInputContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(PartnershipInput);

export default PartnershipInputContainer;
