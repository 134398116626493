import React, { Component } from 'react';
import { Translation } from 'react-i18next';
import { Col, PageHeader, Row } from 'antd';

import Logger from '../../../../../lib/Logger';
import PasswordResetRequestForm from '../containers/PasswordResetRequestFormContainer';
import { pathTo } from '../../../Routes';
import DocumentHead from '../../../elements/components/DocumentHead';
import '../styles/PasswordReset.less';

class PasswordResetRequestScreen extends Component {
  render() {
    return (
      <Translation>
        {(t) => (
          <div className="screen screen-public screen-password-reset">
            <DocumentHead title={t('session_route_password_reset_request')} />

            <PageHeader
              onBack={() => this.props.history.push(pathTo('LoginScreen'))}
              title={t('session_password_reset_screen_title')}
              subTitle={t('session_password_reset_screen_form1_header')}
            />

            <div className="container-form-login">
              <Row type="flex" justify="space-around" align="middle">
                <Col xs={22} sm={18} md={16} lg={14} xl={8}>
                  <div class="outer-container">
                    <div class="inner-container">
                      <PasswordResetRequestForm
                        location={this.props.location}
                      />
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        )}
      </Translation>
    );
  }

  /* LIFECYCLE EVENTS */

  componentDidMount() {
    Logger.log('silly', `PasswordResetRequestScreen.componentDidMount()`);
    if (typeof window !== 'undefined') {
      window.scrollTo(0, 0);
    }
  }

  componentDidUpdate() {
    Logger.log('silly', `PasswordResetRequestScreen.componentDidUpdate()`);
  }

  componentWillUnmount() {
    Logger.log('silly', `PasswordResetRequestScreen.componentWillUnmount()`);
  }
}

export default PasswordResetRequestScreen;

Logger.log('silly', `PasswordResetRequestScreen loaded.`);
