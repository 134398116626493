import CareGuideDocumentFormModal from '../components/CareGuideDocumentFormModal';
import { schema } from '../../../../../state/schema';
import { List } from 'immutable';
import {
  careGuideDocumentFormDestroy,
  careGuideDocumentHideForm,
  loadCareGuideDocuments,
  careGuideDocumentShowForm,
  createCareGuideDocument,
  updateCareGuideDocument,
  archiveDocument,
  destroyDocument,
  uploadDocument,
} from '../../../../../state/modules/careGuideDocuments/actions';

import { createPresignedUrlAWS } from '../../../../../state/actions';

import { denormalize } from 'normalizr';
import { connect } from 'react-redux';

const inputs = List([
  'user_file_name',
  'description',
  'doc-title',
  'doc-description',
  'doc-file',
  'is_pinned',
]);

const mapStateToProps = (state, ownProps) => {
  const errors = {};
  for (const val of inputs.values()) {
    if (state.careGuideDocuments.getIn(['form', 'errors', val])) {
      errors[val] = state.careGuideDocuments.getIn(['form', 'errors', val]);
    }
  }

  const careGuideDocumentId = state.careGuideDocuments.get('id');

  const careGuideDocument = careGuideDocumentId
    ? denormalize(
        state.entities.getIn(['careGuideDocuments', careGuideDocumentId]),
        schema.careGuideDocument,
        state.entities.toJS()
      )
    : null;

  const data = {
    user_file_name: careGuideDocument ? careGuideDocument.user_file_name : '',
    description: careGuideDocument ? careGuideDocument.description : '',
    is_pinned: careGuideDocument ? careGuideDocument.is_pinned : false,
    file_name: careGuideDocument ? careGuideDocument.file_name : '',
    file_directory: careGuideDocument ? careGuideDocument.file_directory : '',
    file_bucket: careGuideDocument ? careGuideDocument.file_bucket : '',
    file_extension: careGuideDocument ? careGuideDocument.file_extension : '',
    size_bytes: careGuideDocument ? careGuideDocument.size_bytes : '',
  };

  return {
    visible: state.careGuideDocuments.get('isFormVisible'),
    isSubmitting:
      state.careGuideDocuments.getIn(['form', 'isSubmitting']) ||
      state.careGuideDocuments.get('isDocumentUploading') ||
      state.careGuideDocuments.get('isDocumentArchiving') ||
      state.careGuideDocuments.get('isDocumentDestroying') ||
      state.session.get('isAwsAction'),
    success: state.careGuideDocuments.getIn(['form', 'success']),
    careGuideDocumentId: careGuideDocumentId,
    careGuideDocument: careGuideDocument,
    data: data,
    errors: errors,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    formDestroy: (formState) => {
      dispatch(careGuideDocumentFormDestroy(formState));
    },
    hideForm: () => {
      dispatch(careGuideDocumentHideForm());
    },
    create: (careGuideId, data, cb) => {
      dispatch(createCareGuideDocument(careGuideId, data, cb));
    },
    update: (careGuideId, id, data, cb) => {
      dispatch(updateCareGuideDocument(careGuideId, id, data, cb));
    },
    load: (careGuideId, page, limit, order, filter, cb) => {
      dispatch(
        loadCareGuideDocuments(careGuideId, page, limit, order, filter, cb)
      );
    },
    getUploadUrl: (path, fileType, cb) => {
      dispatch(createPresignedUrlAWS(path, fileType, cb));
    },
    upload: (path, file, cb) => {
      dispatch(uploadDocument(path, file, cb));
    },
    archive: (path, cb) => {
      dispatch(archiveDocument(path, cb));
    },
    destroy: (path, cb) => {
      dispatch(destroyDocument(path, cb));
    },
    showForm: (careGuideDocumentId) => {
      dispatch(careGuideDocumentShowForm(careGuideDocumentId));
    },
  };
};

const CareGuideDocumentFormModalContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(CareGuideDocumentFormModal);

export default CareGuideDocumentFormModalContainer;
