import {connect} from 'react-redux';

import MessagesDraftsScreen from '../components/MessagesDraftsScreen';

const mapStateToProps = (state, ownProps) => {
  const page = parseInt(ownProps.match.params.page ? ownProps.match.params.page : 1);
  return {
    isLoading: state.journalPosts.get('isLoading'),
    page: page,
  }
}

const MessagesDraftsScreenContainer = connect(
  mapStateToProps
)(MessagesDraftsScreen);

export default MessagesDraftsScreenContainer;
