import React from "react";

const VimeoPlayer = ({ vimeoURL}) => {

  const regex = {
    vimeoUrl: /vimeo\.com\/(?:video\/|)(?<videoID>\d+)/,
  }
      
  const vimeoId = vimeoURL?.match(regex.vimeoUrl).groups.videoID;
  
  return (
    <div className='learning-video-container'>
    <iframe
        src={`https://player.vimeo.com/video/${vimeoId}`}
        title="Vimeo video player"
        frameborder="0"
        allow="accelerometer; fullscreen; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        webkitallowfullscreen
        mozallowfullscreen
        allowfullscreen
      ></iframe>
    </div>
  );
};

export default VimeoPlayer;