import {connect} from 'react-redux';
import {List} from 'immutable';
import {denormalize} from 'normalizr';

import {schema} from '../../../../../state/schema';
import {
  creatCareGuideInvitation,
  patchCareGuideInvitation,
  deleteCareGuideInvitation,
  resendCareGuideInvitation,
  careGuideInvitationFormDestroy,
  careGuideInvitationHideForm
} from '../../../../../state/modules/careGuideInvitations/actions';
import {loadCareGuideHelpers} from '../../../../../state/modules/careGuideHelpers/actions';
import CareGuideInvitationForm from '../components/CareGuideInvitationForm';

const inputs = List([
  'email',
  'role',
  'tags',
]);

const mapStateToProps = (state, ownProps) => {

  const errors = {};
  for (const val of inputs.values()) {
    if (state.careGuideInvitations.getIn(['form', 'errors', val])) {
      errors[val] = state.careGuideInvitations.getIn(['form', 'errors', val]);
    }
  }

  const helperId = state.careGuideInvitations.get('formHelperId');
  const helper = helperId
    ? denormalize(
        state.entities.getIn(['careGuideHelpers', helperId]),
        schema.careGuideHelper,
        state.entities.toJS()
      )
    : null;
  const data = {
    email: helper && helper.invitation ? helper.invitation.email : null,
    role: helper ? helper.role : 2,
    public_status: helper ? (helper.public_status === 1 ? true : false) : null,
    tags: helper ? helper.tags : [],
  };
  
  return {
    visible: state.careGuideInvitations.get('formIsVisible'),
    isLoading: state.careGuideInvitations.get('isLoading'),
    isResending: state.careGuideInvitations.get('isResending'),
    isSubmitting: state.careGuideInvitations.getIn(['form', 'isSubmitting']),
    success: state.careGuideInvitations.getIn(['form', 'success']),
    email: data.email,
    helperId: helperId,
    invitationId: helper && helper.invitation ? helper.invitation.id : null,
    data: data,
    errors: errors
  }
}

const mapDispatchToProps = dispatch => {
  return {
    load: (careGuideId, page, limit, order, filter, cb) => {
      dispatch(loadCareGuideHelpers(careGuideId, page, limit, order, filter, cb));
    },
    create: (careGuideId, data, cb) => {
      dispatch(creatCareGuideInvitation(careGuideId, data, cb));
    },
    patch: (careGuideId, invitationId, data, cb) => {
      dispatch(patchCareGuideInvitation(careGuideId, invitationId, data, cb));
    },
    resend: (careGuideId, invitationId, data, cb) => {
      dispatch(resendCareGuideInvitation(careGuideId, invitationId, data, cb));
    },
    delete: (careGuideId, invitationId, data, cb) => {
      dispatch(deleteCareGuideInvitation(careGuideId, invitationId, data, cb));
    },
    formDestroy: (formState) => {
      dispatch(careGuideInvitationFormDestroy(formState));
    },
    hideForm: () => {
      dispatch(careGuideInvitationHideForm());
    },
  }
}

const CareGuideInvitationFormContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(CareGuideInvitationForm);

export default CareGuideInvitationFormContainer;
