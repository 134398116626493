import React, { Component } from 'react';
import { Translation } from 'react-i18next';
import { Col, Row } from 'antd';
import DocumentHead from '../../../elements/components/DocumentHead';
import CareGuideDisabled from '../../careGuides/components/CareGuideDisabled';
import CareGuideWrapper from '../../../layouts/components/CareGuideWrapper';
import Logger from '../../../../../lib/Logger';
import LearningTree from '../containers/LearningTreeContainer';
import LearningContent from '../containers/LearningContentContainer';
import Loading from '../../../elements/components/Loading';
import { Redirect } from "react-router-dom";
import { pathTo } from '../../../Routes';
class LearningLayout extends Component {

  constructor(props) {
    super(props);
    this.handleResize = this.handleResize.bind(this);
  }

  static defaultProps = {
    isLoading: true,
  };

  state = {
    windowWidth: 0,
  };


  render() {

    const { careGuideId, isCareGuideActive, isCareGuideLoading, plan_name, page, limit, options, list } = this.props;

    if (isCareGuideLoading) {
      return (
        <Translation>{(t) =>
          <CareGuideWrapper>
            <DocumentHead title={t('journal_posts_route_list')} />
            <Loading />
          </CareGuideWrapper>
        }</Translation>
      );
    }
    if (isCareGuideActive === null) {
      return (
        <Redirect to={pathTo("HomeScreen")} />
      );
    }
    if (!isCareGuideActive) {
      return (
        <Translation>{(t) =>
          <CareGuideWrapper>
            <DocumentHead title={t('journal_posts_route_list')} />
            <CareGuideDisabled careGuideId={careGuideId} />
          </CareGuideWrapper>
        }</Translation>
      );
    }

    return (
      <Translation>
        {(t) => (
          <CareGuideWrapper>
            <>
              <DocumentHead title={t("learnings_route_list")} />

              <Row className="learning-panel">
                <>
                  <Col lg={7} md={24} sm={24} xs={24}>
                    <LearningTree window_width={this.state.windowWidth} plan_name={plan_name} page={page} limit={limit} options={options} list={list} />
                  </Col>

                  <Col lg={17} md={24} sm={24} xs={24}>
                    <LearningContent options={options} list={list} />
                  </Col>
                </>

              </Row>
            </>
          </CareGuideWrapper>

        )}
      </Translation>
    );
  }

  componentDidMount() {
    this.handleResize();
    if (this.props.activeItemId === null || this.props.activeItemId === undefined) {
      if (this.props.options.length > 0) {
        this.props.setActiveId(this.props.options[0].key);
      } else {
        this.props.setActiveId(null);
      }
    }
    window.addEventListener("resize", this.handleResize);
  }

  componentDidUpdate(prevProps) {

    if (prevProps.activeItemId === null || prevProps.activeItemId === undefined) {
      if (this.props.options.length > 0) {
        this.props.setActiveId(this.props.options[0].key);
      } else {
        this.props.setActiveId(null);
      }
    }
  }

  componentWillUnmount() {
    this.props.setActiveId(null);     
    window.removeEventListener("resize", this.handleResize);
  }

  handleResize() {
    this.setState({ windowWidth: window.innerWidth });
  }
}

export default LearningLayout;

Logger.log("silly", `LearningLayout loaded.`);
