import React, {useState, useEffect, useRef} from 'react';
import {Translation, getI18n} from 'react-i18next';
import {Button, Col, ConfigProvider, Input, Form, Popconfirm, Row, Select, Table} from 'antd';
import {CloseOutlined, PlusOutlined} from '@ant-design/icons';

import useWindowDimensions from '../../../../../lib/WindowDimensions';
import { t } from 'i18next';


const TimeInput = ({name, interval=60, emptyOption=null, value=null, onChange=null, className=null, ...props}) => {

  const options = [];
  const ap = ['AM', 'PM'];
  let tt = 0;

  if (emptyOption) {
    options.push({
      value: null,
      label: emptyOption
    });
  }
  
  for (let i = emptyOption ? 1 : 0; tt < 24 * 60; i++) {
    let hh = Math.floor(tt / 60);
    let mm = (tt % 60);
    let label = ("" + ((hh === 12 || hh === 0) ? 12 : hh % 12)).slice(-2) + ':' + ("0" + mm).slice(-2) + ' ' + ap[Math.floor(hh / 12)];
    options[i] = {
      value: label,
      label: label
    }
    tt = tt + interval;
  }

  return (
    <Translation>{(t) => 
      <Select
        name={name}
        value={value}
        onChange={onChange}
        className={className}
      >
        {options.map(x =>
          <Select.Option
            key={x.value}
            value={x.value}
          >
            {x.label}
          </Select.Option>)}
      </Select>
    }</Translation>
  );
}

const FieldTypeMedTableEditor = ({form, content, contentsPath, ...props}) => {

  const [sections, setSections] = useState([]);
  const [groupName, setGroupName] = useState({});
  const [groupNameStatus, setGroupNameStatus] = useState({});
  const [medication, setMedication] = useState({});
  const [medicationStatus, setMedicationStatus] = useState({});
  const [dose, setDose] = useState({});
  const [doseStatus, setDoseStatus] = useState({});
  const [time, setTime] = useState({});
  const [notes, setNotes] = useState({});
  const [rnd, setRnd] = useState(Math.random());

  const addGroup = useRef(null);
  const removeGroup = useRef(null);

  const addGroupRow = useRef({});
  const addGroupRowButton = useRef({});
  const removeGroupRow = useRef({});

  const options = form.getFieldValue([...contentsPath, `options`]) || null;

  const {width} = useWindowDimensions();
  const isMobile = width < 500;

  // populate table inputs (since they are not actually part of the form)
  useEffect(() => {
    const gs = form.getFieldValue([...contentsPath, `groups`]);
    const sectionUpdates = [];
    const groupNameUpdates = {};
    const medicationUpdates = {};
    const doseUpdates = {};
    const timeUpdates = {};
    const notesUpdates = {};
    
    for (const i in gs) {
      const group = gs[i] || {name: '', is_adjustable: true, fields: []};

      // for some reason `is_adjustable` keeps disappearing in new sections, so here's my kludge
      if (!('is_adjustable' in group) || group['is_adjustable'] === undefined) {
        group['is_adjustable'] = true;
      }
      if (!('is_deletable' in group) || group['is_deletable'] === undefined) {
        group['is_deletable'] = true;
      }
      if (!('name' in group) || group['name'] === undefined) {
        group['name'] = '';
      }

      if ('name' in group) {
        groupNameUpdates[[...contentsPath, `groups`, i, `name`].join('//')] = group['name'];
      }
      group['order'] = parseInt(i) + 1;

      if ('fields' in group && Array.isArray(group['fields'])) {
        for (const j in group['fields']) {
          if (!group['fields'][j]) {
            group['fields'][j] = {
              medication: '', dose: '', time: null, notes: '',
            };
          }
          if ('medication' in group['fields'][j]) {
            medicationUpdates[[...contentsPath, `groups`, i, `fields`, j, 'medication'].join('//')] = group['fields'][j]['medication'];
          }
          if ('dose' in group['fields'][j]) {
            doseUpdates[[...contentsPath, `groups`, i, `fields`, j, 'dose'].join('//')] = group['fields'][j]['dose'];
          }
          if ('time' in group['fields'][j]) {
            timeUpdates[[...contentsPath, `groups`, i, `fields`, j, 'time'].join('//')] = group['fields'][j]['time'];
          }
          if ('notes' in group['fields'][j]) {
            notesUpdates[[...contentsPath, `groups`, i, `fields`, j, 'notes'].join('//')] = group['fields'][j]['notes'];
          }
        }
      }
      sectionUpdates.push(group);
    }

    setSections(sectionUpdates);
    setGroupName(groupNameUpdates);
    setMedication(medicationUpdates);
    setDose(doseUpdates);
    setTime(timeUpdates);
    setNotes(notesUpdates);

  }, [rnd]); // eslint-disable-line react-hooks/exhaustive-deps

  const setNestedFieldValue = (path, fieldName, value) => {

    // get all form values (so we can updated nested values)
    const fields = form.getFieldsValue();

    // split radio input name by double slash and use values as keys in nested form ipnuts
    const paths = path.split('//');
    paths.pop(); // remove last element because we'll handle that manually at the end
    let field = fields;
    let path_count = 0;
    for (let path of paths) {
      path = Number.isInteger(path) ? parseInt(path) : path;
      if (path in field) {
        field = field[path];
        path_count++;
      }
    }

    // found path
    if (field && path_count === paths.length) {
      field[fieldName] = value;
    }
    
    // update form values with changes
    form.setFieldsValue(fields);
  }

  const onChangeGroupName = e => {
    setGroupName({ ...groupName, [e.target.name]: e.target.value });
    setNestedFieldValue(e.target.name, 'name', e.target.value);
    if (groupNameStatus[e.target.name]) {
      setGroupNameStatus({...groupNameStatus, ...{[e.target.name]: null}});
    }
  }

  const onChangeMedication = e => {
    setMedication({ ...medication, [e.target.name]: e.target.value });
    setNestedFieldValue(e.target.name, 'medication', e.target.value);
    if (medicationStatus[e.target.name]) {
      setMedicationStatus({...medicationStatus, ...{[e.target.name]: null}});
    }
  }

  const onChangeDose = e => {
    setDose({ ...dose, [e.target.name]: e.target.value });
    setNestedFieldValue(e.target.name, 'dose', e.target.value);
    if (doseStatus[e.target.name]) {
      setDoseStatus({...doseStatus, ...{[e.target.name]: null}});
    }
  }

  const onChangeTime = (name, val) => {
    setTime({ ...time, [name]: val });
    setNestedFieldValue(name, 'time', val);
  }

  const onChangeNotes = e => {
    setNotes({ ...notes, [e.target.name]: e.target.value });
    setNestedFieldValue(e.target.name, 'notes', e.target.value);
  }

  const addNewGroup = () => {
    if (addGroup.current) {
      addGroup.current();
      setRnd(Math.random());
    }
  }

  const deleteGroup = (field) => {
    if (removeGroup.current) {
      removeGroup.current(field);
      setRnd(Math.random());
    }
  }

  const addNewRow = (add) => {
    if (add) {
      add();
      setRnd(Math.random());
    }
  }

  const removeRow = (remove, field) => {
    if (remove) {
      remove(field);
      setRnd(Math.random());
    }
  }

  const renderCell = (text, row, index) => {
    return (index % 2 === 1)
      ? {props: {colSpan: 0}}
      : text;
  }

  props.submitCallbackRef.current = () => {
    let success = true;

    const groupNameStatusUpdates = {};
    for (const key in groupName) {
      if (!groupName[key]) {
        groupNameStatusUpdates[key] = getI18n().t('feedback_validation_required_short');
      } else if (groupName[key].length < 2) {
        groupNameStatusUpdates[key] = getI18n().t('feedback_validation_length_short', {min: 2, max: 60});
      } else if (groupName[key].length > 60) {
        groupNameStatusUpdates[key] = getI18n().t('feedback_validation_length_short', {min: 2, max: 60});
      }
    }
    if (Object.keys(groupNameStatusUpdates).length > 0) {
      setGroupNameStatus({...groupNameStatus, ...groupNameStatusUpdates});
      success = false;
    }

    const medicationStatusUpdates = {};
    for (const key in medication) {
      if (!medication[key]) {
        medicationStatusUpdates[key] = getI18n().t('feedback_validation_required_short');
      } else if (medication[key].length < 2) {
        medicationStatusUpdates[key] = getI18n().t('feedback_validation_length_short', {min: 2, max: 150});
      } else if (medication[key].length > 150) {
        medicationStatusUpdates[key] = getI18n().t('feedback_validation_length_short', {min: 2, max: 150});
      }
    }
    if (Object.keys(medicationStatusUpdates).length > 0) {
      setMedicationStatus({...medicationStatus, ...medicationStatusUpdates});
      success = false;
    }

    const doseStatusUpdates = {};
    for (const key in dose) {
      if (!dose[key]) {
        doseStatusUpdates[key] = getI18n().t('feedback_validation_required_short');
      } else if (dose[key].length < 2) {
        doseStatusUpdates[key] = getI18n().t('feedback_validation_length_short', {min: 2, max: 25});
      } else if (dose[key].length > 25) {
        doseStatusUpdates[key] = getI18n().t('feedback_validation_length_short', {min: 2, max: 25});
      }
    }
    if (Object.keys(doseStatusUpdates).length > 0) {
      setDoseStatus({...doseStatus, ...doseStatusUpdates});
      success = false;
    }

    return success;
  }

  const doseColumnWidth = isMobile ? 100 : 150;
  const timeColumnWidth = isMobile ? 60 : 120;
  const deleteColumnWidth = 50;

  const tables = sections.filter(x => x && x.fields).map((group, groupIndex) => {

    // inputs
    const nameFieldName = [...contentsPath, 'groups', groupIndex, 'name'].join('//');

    const tableDefinitions = {
      cols: [
        {
          // title: group.name,
          title: group.is_adjustable === true
            ? <>
                <Input
                  name={nameFieldName}
                  value={groupName[nameFieldName]}
                  placeholder={options && 'group_name_placeholder' in options && options.group_name_placeholder
                    ? options.group_name_placeholder
                    : getI18n().t('care_guides_med_table_name_placeholder')}
                  className='med-table-input-name'
                  onChange={onChangeGroupName}
                  status={groupNameStatus[nameFieldName] ? 'error' : null}
                />
                {groupNameStatus[nameFieldName]
                  ? <div className="ant-form-item-explain ant-form-item-explain-connected" style={{height: 'auto', opacity: 1}}>
                      <div role="alert" className="ant-form-item-explain-error">{groupNameStatus[nameFieldName]}</div>
                    </div>
                  : null}
              </>
            : group.name,
          dataIndex: 'medication',
          key: 'medication',
          render: (text, row, index) => {
            return (index % 2 === 1)
              ? {
                  children: text,
                  props: {
                    colSpan: 3,
                  },
                }
              : text;
          }
        },
        {
          title: getI18n().t('care_guides_med_table_dose'),
          dataIndex: 'dose',
          key: 'dose',
          render: renderCell,
          width: doseColumnWidth,
          align: 'center',
        },
        {
          title: getI18n().t('care_guides_med_table_time'),
          dataIndex: 'time',
          key: 'time',
          render: renderCell,
          width: timeColumnWidth,
          align: 'center',
        },
        {
          title: group.is_deletable === true
            ? <Popconfirm
                key="delete-confirm"
                placement="left"
                title={t('care_guides_form_delete_table_group_confirm_body')}
                onConfirm={() => deleteGroup(groupIndex)}
                okText={t('confirm_yes')}
                cancelText={t('confirm_cancel')}
              >
                <Button
                  danger
                  type="primary"
                  shape="circle"
                  size="small"
                  icon={<CloseOutlined />}
                />
              </Popconfirm>
            : null,
          dataIndex: 'control',
          key: 'control',
          width: deleteColumnWidth,
          align: 'center',
          onCell: (_, index) => {
            return { rowSpan: index % 2 === 0 ? 2 : 0, className: 'med-table-delete-col' };
          },
          onHeaderCell: () => {
            return { className: 'med-table-delete-col' };
          },
        }
      ],
      data: group.fields
        ? group.fields.flatMap((field, i) => {

            // inputs
            const medicationFieldName = [...contentsPath, 'groups', groupIndex, 'fields', i, 'medication'].join('//');
            const doseFieldName = [...contentsPath, 'groups', groupIndex, 'fields', i, 'dose'].join('//');
            const timeFieldName = [...contentsPath, 'groups', groupIndex, 'fields', i, 'time'].join('//');
            const output = [
              {
                key: i,
                medication: group.is_adjustable === true
                  ? <>
                      <Input
                        name={medicationFieldName}
                        value={medication[medicationFieldName]}
                        placeholder={options && 'medication_placeholder' in options && options.medication_placeholder
                          ? options.medication_placeholder
                          : getI18n().t('care_guides_med_table_medication_placeholder')}
                        className='med-table-input-medication'
                        onChange={onChangeMedication}
                        status={medicationStatus[medicationFieldName] ? 'error' : null}
                      />
                      {medicationStatus[medicationFieldName]
                        ? <div className="ant-form-item-explain ant-form-item-explain-connected" style={{height: 'auto', opacity: 1}}>
                            <div role="alert" className="ant-form-item-explain-error">{medicationStatus[medicationFieldName]}</div>
                          </div>
                        : null}
                    </>
                  : <span className="med-table-span-medication">{medication[medicationFieldName]}</span>,
                dose: 
                  <>
                    <Input
                      name={doseFieldName}
                      value={dose[doseFieldName]}
                      placeholder={options && 'dose_placeholder' in options && options.dose_placeholder
                        ? options.dose_placeholder
                        : getI18n().t('care_guides_med_table_dose_placeholder')}
                      className='med-table-input-dose'
                      onChange={onChangeDose}
                      status={doseStatus[doseFieldName] ? 'error' : null}
                    />
                    {doseStatus[doseFieldName]
                      ? <div className="ant-form-item-explain ant-form-item-explain-connected" style={{height: 'auto', opacity: 1}}>
                          <div role="alert" className="ant-form-item-explain-error">{doseStatus[doseFieldName]}</div>
                        </div>
                      : null}
                  </>,
                time: 
                  <TimeInput
                    name={timeFieldName}
                    value={time[timeFieldName]}
                    emptyOption={getI18n().t('care_guides_med_table_time_as_needed')}
                    className='med-table-input-time'
                    onChange={e => onChangeTime(timeFieldName, e)}
                  />,
                control: group.is_adjustable === true
                  ? <Popconfirm
                      key="delete-confirm"
                      placement="left"
                      title={t('care_guides_form_delete_table_row_confirm_body')}
                      onConfirm={() => removeRow(removeGroupRow.current[groupIndex], i)}
                      okText={t('confirm_yes')}
                      cancelText={t('confirm_cancel')}
                    >
                      <Button
                        danger
                        type="primary"
                        shape="circle"
                        size="small"
                        icon={<CloseOutlined />}
                      />
                    </Popconfirm>
                  : null,
              },
            ];

            // notes: textarea
            const notesFieldName = [...contentsPath, 'groups', groupIndex, 'fields', i, 'notes'].join('//');
            output.push(
              {
                key: `${i}-notes`,
                medication:
                  <Input.TextArea
                    name={notesFieldName}
                    onChange={onChangeNotes}
                    value={notes[notesFieldName]}
                    autoSize
                    bordered={false}
                    placeholder={options && 'notes_placeholder' in options && options.notes_placeholder
                    ? options.notes_placeholder
                    : getI18n().t('care_guides_med_table_notes_placeholder')}
                  />
              }
            );
            return output;
          })
        : null,
    };

    // add control for adding new row to table if able
    addGroupRowButton.current[groupIndex] = group.is_adjustable === true
      ? <Row justify="space-around" align="middle">
          <Col>
            <Button
              type="primary"
              shape="round"
              icon={<PlusOutlined />}
              className="med-table-add-row-button"
              onClick={() => addNewRow(addGroupRow.current[groupIndex])}
            >
              {t('care_guides_med_table_btn_add_row')}
            </Button>
          </Col>
        </Row>
      : null;

    return tableDefinitions;
  });

  return (
    <Translation>{(t) => 
      <>

        <div className="med-table-editor">

          {tables.map((table, i) =>
            <ConfigProvider key={i} renderEmpty={() => null}>
              <Table columns={table.cols} dataSource={table.data} pagination={false} size="small" bordered />
              {addGroupRowButton.current[i]}
            </ConfigProvider>
          )}

          {options && 'is_adjustable' in options && options.is_adjustable === true
            ? <Row justify="space-around" align="middle">
                <Col>
                  <Button
                    type="primary"
                    shape="round"
                    icon={<PlusOutlined />}
                    className="med-table-add-section-button"
                    onClick={addNewGroup}
                  >
                    {t('care_guides_med_table_btn_add_group')}
                  </Button>
                </Col>
              </Row>
            : null}

          <div className="hidden-inputs">

            <Form.List name={[content.name, 'groups']}>

              {(gs, {add, remove}) => {
                if (addGroup.current === null) addGroup.current = add;
                if (removeGroup.current === null) removeGroup.current = remove;
                return (
                  <>
                    {gs.map((group, groupIndex) =>
                      <div key={groupIndex}>

                        <Form.Item
                          name={[group.name, 'name']}
                          fieldKey={[group.name, 'name']}
                        >
                          <Input />
                        </Form.Item>

                        <Form.Item
                          name={[group.name, 'order']}
                          fieldKey={[group.name, 'order']}
                        >
                          <Input />
                        </Form.Item>

                        <Form.Item
                          name={[group.name, 'is_adjustable']}
                          fieldKey={[group.name, 'is_adjustable']}
                        >
                          <Input />
                        </Form.Item>

                        <Form.Item
                          name={[group.name, 'is_deletable']}
                          fieldKey={[group.name, 'is_deletable']}
                        >
                          <Input />
                        </Form.Item>

                        <Form.List name={[group.name, 'fields']}>
                          {(fs, {add, remove}) => {
                            addGroupRow.current[group.name] = add;
                            removeGroupRow.current[group.name] = remove;
                            return (
                              <>
                                {fs.map((field, fieldIndex) => 
                                  <div key={fieldIndex}>
                                    <Form.Item
                                      name={[field.name, 'medication']}
                                      fieldKey={[field.name, 'medication']}
                                    >
                                      <Input />
                                    </Form.Item>

                                    <Form.Item
                                      name={[field.name, 'dose']}
                                      fieldKey={[field.name, 'dose']}
                                    >
                                      <Input />
                                    </Form.Item>

                                    <Form.Item
                                      name={[field.name, 'time']}
                                      fieldKey={[field.name, 'time']}
                                    >
                                      <Input />
                                    </Form.Item>
                                    
                                    <Form.Item
                                      name={[field.name, 'notes']}
                                      fieldKey={[field.name, 'notes']}
                                    >
                                      <Input />
                                    </Form.Item>

                                  </div> 
                                )}
                              </>
                            )
                          }}
                        </Form.List>
                      </div>
                    )}
                  </>
                )
              }}

            </Form.List>
          </div>
        </div>
      </>
    }</Translation>
  )
}

export default FieldTypeMedTableEditor;
