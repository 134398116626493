import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router';
import { Translation } from 'react-i18next';

import moment from 'moment';
import { BsFileEarmarkArrowDown } from 'react-icons/bs';
import { Button, Input, DatePicker } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import Title from 'antd/lib/typography/Title';
import { pathTo } from '../../../Routes';
import QueryString from '../../../../../lib/QueryString';
import CareGuideDocumentModalExport from "../containers/CareGuideDocumentModalExportContainer";

const CareGuideDocumentsHeader = ({
  showForm,
  component,
  careGuideId,
  history,
  order, 
  filter,
  page,
  limit,
}) => {
  let params = QueryString.parse(history.location.search);
  const defaultStart = 'start' in params ? moment(params['start']) : null;
  const defaultEnd = 'end' in params ? moment(params['end']) : null;
  const defaultSearch = 'search' in params ? params['search'] : null;

  const [openModalExport, setOpenModalExport] = useState(false);
  const [search, setSearch] = useState(defaultSearch);

  useEffect(() => {
    setSearch(defaultSearch ?? '');
  }, [defaultSearch]);

  const onChangeDateHandler = (dates) => {
    params['start'] = dates && dates[0] ? dates[0].format('YYYY-MM-DD') : '';
    if (params['start'] === '') {
      delete params['start'];
    }
    params['end'] = dates && dates[1] ? dates[1].format('YYYY-MM-DD') : '';
    if (params['end'] === '') {
      delete params['end'];
    }
    const path = pathTo(component, { id: careGuideId });
    history.push(QueryString.append(path, params));
  };

  const onSearch = (value) => {
    params['search'] = value;
    if (params['search'] === '') {
      delete params['search'];
    }
    const path = pathTo(component, { id: careGuideId });
    history.push(QueryString.append(path, params));
  };

  return (
    <Translation>
      {(t) => (
        <>
          <div className="user-documents-action-bar">
            <Title level={2}>{t('care_guide_documents_documents')}</Title>
            <Input.Search
              allowClear
              onSearch={onSearch}
              enterButton
              className="search-input"
              size="large"
              placeholder={t('care_guide_documents_search')}
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
            <DatePicker.RangePicker
              size="large"
              showTime
              format="MM/DD/YYYY"
              onChange={onChangeDateHandler}
              value={[defaultStart, defaultEnd]}
            />
            <Button
              icon={<BsFileEarmarkArrowDown />}
              className="export-btn"
              size="large"
              onClick={() => setOpenModalExport(true)}

            >
              {t('action_export')}
            </Button>
            <Button
              icon={<PlusOutlined />}
              type="primary"
              className="add-doc-btn"
              size="large"
              onClick={() => showForm()}
            >
              {t('care_guide_documents_add_document')}
            </Button>
            <CareGuideDocumentModalExport
              page={page}
              limit={limit}
              order={order}
              filter={filter}
              careGuideId={careGuideId}
              openModalExport={openModalExport}
              setOpenModalExport={setOpenModalExport}
            />
          </div>
        </>
      )}
    </Translation>
  );
};

export default withRouter(CareGuideDocumentsHeader);
