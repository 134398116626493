import React, {useState, useEffect, useRef} from 'react';
import {Translation} from 'react-i18next';
import {Form, Input, Popover, Typography, Space} from 'antd';
import {CheckCircleFilled, CloseCircleOutlined} from '@ant-design/icons';


const reDigits = new RegExp('[0-9]');
const reNonAlphaNumeric = new RegExp('[\\W]');

const incompleteTextType = 'default';
const incompleteIcon = <CloseCircleOutlined style={{ color: '#00000040' }} />;

const completeTextType = 'secondary';
const completeIcon = <CheckCircleFilled style={{ color: '#52c41a' }} />;

const PasswordInput = ({name, hasError=false, label=null, rules=null, disabled=false, autoFocus=false, useTooltip=true, tooltipPlacement='right', useSuccess=true, ...props}) => {

  const [isVisible, setIsVisible] = useState(false);

  const [isLengthComplete, setIsLengthComplete] = useState(false);
  const [isOptionsComplete, setIsOptionsComplete] = useState(false);
  const [isLowercaseComplete, setIsLowercaseComplete] = useState(false);
  const [isUppercaseComplete, setIsUppercaseComplete] = useState(false);
  const [isNumberComplete, setIsNumberComplete] = useState(false);
  const [isNonAlphaComplete, setIsNonAlphaComplete] = useState(false);

  const inputRef = useRef(null);

  // check 3 out of 4 options
  useEffect(() => {
    setIsOptionsComplete(isLowercaseComplete + isUppercaseComplete + isNumberComplete + isNonAlphaComplete >= 3);
  }, [isLowercaseComplete, isUppercaseComplete, isNumberComplete, isNonAlphaComplete, setIsOptionsComplete]);

  const onChange = e => {
    const val = e.target.value;
    
    // check length
    setIsLengthComplete(val.length >= 8);
    
    // check lowercase
    setIsLowercaseComplete(val.toUpperCase() !== val);
    
    // check uppercase
    setIsUppercaseComplete(val.toLowerCase() !== val);
    
    // check number
    setIsNumberComplete(reDigits.test(val));
    
    // check non-alphanumeric
    setIsNonAlphaComplete(reNonAlphaNumeric.test(val));
  }

  return (
    <Translation>{(t) =>
      <div className="form-group">
        <Popover
          placement={tooltipPlacement}
          trigger="focus"
          title={null}
          visible={useTooltip && isVisible && !(isLengthComplete && isOptionsComplete)}
          content={
            <>
              <Space direction="vertical">

                <Typography.Text
                  type={isLengthComplete ? completeTextType : incompleteTextType}
                >
                  {isLengthComplete ? completeIcon : incompleteIcon}{' '}
                  {t('user_account_input_password_req_length')}
                </Typography.Text>

                <Typography.Text type={isOptionsComplete ? completeTextType : incompleteTextType}>
                  {isOptionsComplete ? completeIcon : incompleteIcon}{' '}
                  {t('user_account_input_password_req_options_header')}:
                  <br />

                  <ul style={{paddingTop: 2, paddingLeft: 10, fontSize: 12}}>

                    <li>
                      <Typography.Text delete={isLowercaseComplete} type={isOptionsComplete ? completeTextType : incompleteTextType}>
                        {t('user_account_input_password_req_lowercase')}
                      </Typography.Text>
                    </li>

                    <li>
                      <Typography.Text delete={isUppercaseComplete} type={isOptionsComplete ? completeTextType : incompleteTextType}>
                        {t('user_account_input_password_req_uppercase')}
                      </Typography.Text>
                    </li>

                    <li>
                      <Typography.Text delete={isNumberComplete} type={isOptionsComplete ? completeTextType : incompleteTextType}>
                        {t('user_account_input_password_req_number')}
                      </Typography.Text>
                    </li>

                    <li>
                      <Typography.Text delete={isNonAlphaComplete} type={isOptionsComplete ? completeTextType : incompleteTextType}>
                        {t('user_account_input_password_req_non_alpha')}
                      </Typography.Text>
                    </li>
                    
                  </ul>


                </Typography.Text>

              </Space>
            </>
          }
        >
          <Form.Item
            name={name}
            label={label ? label : t('user_account_input_password')}
            rules={rules
              ? rules
              : [
                  {required: true, message: t('feedback_validation_required')},
                  {min: 8, max: 40, message: t('feedback_validation_length', {min: 2, max: 40})},
                  {pattern: /^(?:(?=.*[a-z])(?:(?=.*[A-Z])(?=.*[\d\W])|(?=.*\W)(?=.*\d))|(?=.*\W)(?=.*[A-Z])(?=.*\d)).{8,40}$/, message: t('feedback_validation_password_complexity')}
                ]}
            // hasFeedback={useSuccess && isLengthComplete && isOptionsComplete && !hasError}
            hasFeedback={true}
            validateStatus={hasError ? "error" : (useSuccess && isLengthComplete && isOptionsComplete ? "success" : null)}
          >
            <Input.Password
              disabled={disabled}
              autoFocus={autoFocus}
              onChange={onChange}
              ref={inputRef}
              onFocus={() => setIsVisible(true)}
              onBlur={() => setIsVisible(false)}
            />
          </Form.Item>
        </Popover>
      </div>
    }</Translation>
  )
}

export default PasswordInput;
