import {Map} from 'immutable';

import {TYPES} from './actions';
import {SESSION_DESTROY} from '../../actions';
import Logger from '../../../lib/Logger';

const initialState = Map({
  isLoading: false,
  planForm: {},
});

export default function accounts(
  state=initialState,
  action
) {
  Logger.log('debug', `[state.accounts.reducers] accounts(%j, %j)`, state, action);

  switch(action.type) {

    case TYPES.READ_REQUEST:
      return state.mergeDeep({
        isLoading: true
      });

    case TYPES.READ_SUCCESS:
      return state.mergeDeep({
        isLoading: false,
        lastUpdated: action.receivedAt
      });

    case TYPES.READ_FAILURE:
      return state.mergeDeep({
        isLoading: false
      });

    case TYPES.UPDATE_PLAN_REQUEST:
      return state.mergeDeep({
        isLoading: true,
        planForm: {
          isSubmitting: true,
          success: null,
          errors: null
        }
      });

    case TYPES.UPDATE_PLAN_SUCCESS:
      return state.mergeDeep({
        isLoading: false,
        planForm: {
          isSubmitting: false,
          success: true,
          errors: null
        },
        lastUpdated: action.receivedAt
      });

    case TYPES.UPDATE_PLAN_FAILURE:
      return state.mergeDeep({
        isLoading: false,
        planForm: {
          isSubmitting: false,
          success: false,
          errors: null
        }
      }).setIn(['planForm', 'errors'], action.error);

    case SESSION_DESTROY:
      return initialState;

    default:
      return state;
  }
}

Logger.log('silly', `state.accounts.reducers loaded.`);
