import { normalize } from 'normalizr';

import api from '../../api';
import { schema } from '../../schema';
import { addEntities } from '../../actions';
import Logger from '../../../lib/Logger';

export const TYPES = {
  LIST_REQUEST: 'USER_PROFILES_PARTNERSHIP/LIST_REQUEST',
  LIST_SUCCESS: 'USER_PROFILES_PARTNERSHIP/LIST_SUCCESS',
  LIST_FAILURE: 'USER_PROFILES_PARTNERSHIP/LIST_FAILURE',
  SET_ACTIVE: 'USER_PROFILES_PARTNERSHIP/SET_ACTIVE',
  SET_ACTIVE_PAGE: 'USER_PROFILES_PARTNERSHIP/SET_ACTIVE_PAGE',
};

export function userProfilesPartnershipListRequest(page, limit, order, filter) {
  Logger.log(
    'debug',
    `[state.userProfilesPartnerships.actions] userProfilesPartnershipListRequest(${page}, ${limit}, ${order}, %j)`,
    filter
  );
  return {
    type: TYPES.LIST_REQUEST,
    page: page,
    limit: limit,
    order: order,
    filter: filter,
  };
}

export function userProfilesPartnershipListSuccess(data) {
  Logger.log(
    'debug',
    `[state.userProfilesPartnerships.actions] userProfilesPartnershipListSuccess(%j)`,
    data
  );
  return {
    type: TYPES.LIST_SUCCESS,
    page: data.page,
    limit: data.limit,
    order: data.order,
    result: data.result,
    total: data.total,
    receivedAt: Date.now(),
  };
}

export function userProfilesPartnershipListFailure(error) {
  Logger.log(
    'debug',
    `[state.userProfilesPartnerships.actions] userProfilesPartnershipListFailure(%j)`,
    error
  );
  return {
    type: TYPES.LIST_FAILURE,
    error: error,
  };
}

export function userProfilesPartnershipSetActive(id) {
  Logger.log(
    'debug',
    `[state.userProfilesPartnerships.actions] userProfilesPartnershipSetActive(${id})`
  );
  return {
    type: TYPES.SET_ACTIVE,
    id: id,
  };
}

export function userProfilesPartnershipPageSetActive(page) {
  Logger.log(
    'debug',
    `[state.userProfilesPartnerships.actions] userProfilesPartnershipPageSetActive(${page})`
  );
  return {
    type: TYPES.SET_ACTIVE_PAGE,
    page: page,
  };
}

// API THUNK ACTION CREATORS

export function loadUserProfilesPartnerships(
  page = 1,
  limit = 10,
  order = null,
  filter = null,
  cb = function () {}
) {
  Logger.log(
    'debug',
    `[state.userProfilesPartnerships.actions] loadPartnerships(${page}, ${limit}, ${order}, %j, ###)`,
    filter
  );
  return async function (dispatch) {
    dispatch(userProfilesPartnershipListRequest(page, limit, order, filter));
    // call API
    const response = await api.getUserProfilesPartnerships(
      page,
      limit,
      order,
      filter
    );
    let success = false;

    // get user profiles partnerships list success
    if (200 === response.get('status')) {
      Logger.log(
        'info',
        `Get API user profiles partnerships list success. Page: ${page}, Limit: ${limit}, Order: ${order}.`
      );

      const normalizedEntities = normalize(
        response.getIn(['data', 'user_profiles_partnerships']),
        [schema.userProfilePartnership]
      );

      const data = {
        page: response.getIn(['data', 'page']),
        limit: response.getIn(['data', 'limit']),
        order: order,
        total: response.getIn(['data', 'total']),
        result: normalizedEntities.result,
      };
      dispatch(addEntities(normalizedEntities));
      dispatch(userProfilesPartnershipListSuccess(data));
      success = true;
    } else if (1 === page && 204 === response.get('status')) {
      Logger.log(
        'info',
        `Get API user profiles partnerships list success [empty]. Page: ${page}, Limit: ${limit}, Order: ${order}.`
      );
      const data = {
        page: page,
        limit: limit,
        order: order,
        total: 0,
        result: [],
      };
      dispatch(userProfilesPartnershipListSuccess(data));
      success = true;

      // get user profiles partnerships list failure
    } else {
      Logger.log(
        'info',
        `Get API user profiles partnerships list failure. Page: ${page}, Limit: ${limit}, Order: ${order}.`
      );
      dispatch(
        userProfilesPartnershipListFailure(response.getIn(['data', 'error']))
      );
    }

    // callback function
    cb(success);
  };
}

export function loadUserProfilesPartnership(
  partnershipId = null,
  cb = function () {}
) {
  Logger.log(
    'debug',
    `[state.userProfilesPartnerships.actions] loadUserProfilesPartnership(${partnershipId})`
  );
  return async function (dispatch) {
    const response = await api.getUserProfilesPartnership(partnershipId);
    let data = {};
    let success = false;
    // get user profiles partnership success
    if (200 === response.get('status')) {
      Logger.log(
        'info',
        `Get API user profiles partnership success. Partnership ID: ${partnershipId}`
      );
      success = true;
      data = {
        access_role_admin_in_care_guide: response.getIn([
          'data',
          'partnership',
          'access_role_admin_in_care_guide',
        ]),
        access_role_admin_in_app: response.getIn([
          'data',
          'partnership',
          'access_role_admin_in_app',
        ]),
        access_tabs_in_app: response.getIn([
          'data',
          'partnership',
          'access_tabs_in_app',
        ]),
        role: response.getIn(['data', 'role']),
        name: response.getIn(['data', 'partnership', 'name']),
      };
    }

    // callback function
    cb(success, data);
  };
}

Logger.log('silly', `state.partnerships.actions loaded.`);
