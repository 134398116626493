import React from 'react';
import {Avatar, Tooltip} from 'antd';
import {UserOutlined} from '@ant-design/icons';

import imageURL from '../lib/imageURL';

// ant design primary colors
const colors = ['#F79221', '#BD4242', '#10A64F', '#9D2AC3'];

// param defaultOutput: initials | icon
const UserAvatar = ({profile, showTooltp=true, defaultOutput='initials', size=32, key=null, ...props}) => {
  const name = profile ? profile.first_name + ' ' + profile.last_name : null;
  const initials = profile ? profile.first_name.substring(0, 1).toUpperCase() + profile.last_name.substring(0, 1).toUpperCase() : <UserOutlined />;
  const source = profile && profile.avatar && profile.avatar.file_name && profile.avatar.file_extension && profile.avatar.file_directory
    ? imageURL(profile.avatar.file_directory, profile.avatar.file_name, profile.avatar.file_extension, size, size, 'crop')
    : null;
  const icon = source
    ? null 
    : (defaultOutput === 'icon' ? <UserOutlined /> : initials);

  // generate a color for the avatar based on the profile id
  const color = profile ? colors[parseInt('0x' + profile.id.substring(0, 4)) % colors.length] : colors[0];

  if (showTooltp && name) {
    return (
      <Tooltip key={key} title={name} placement="top">
        <Avatar src={source} icon={icon} size={size} style={{backgroundColor: color}} />
      </Tooltip>
    )
  } else {
    return (
      <Avatar key={key} src={source} icon={icon} size={size} style={{backgroundColor: color}} />
    )
  }
}

export default UserAvatar;
