import {connect} from 'react-redux';
import {denormalize} from 'normalizr';

import {schema} from '../../../../../state/schema';
import {loadUserProfilesPartnershipsAdmin} from '../../../../../state/modules/userProfilesPartnershipsAdmin/actions';
import UserProfilesPartnershipsAdminList from '../components/UserProfilesPartnershipsAdminList';
import storage from "../../../../../lib/Storage";

const mapStateToProps = (state, ownProps) => {

  const page = ownProps.page;
  const limit = ownProps.limit;
  const order = ownProps.order;
  const result = state.userProfilesPartnershipsAdmin.getIn(['pages', order, limit, page]);
  

  
  return {
    list: result 
      ? result.map(x => {
          return {
            key: x,
            ...denormalize(
              state.entities.getIn(['userProfilesPartnershipsAdmin', x]),
              schema.userProfilePartnershipAdmin,
              state.entities.toJS()
            )
          };
        }).toArray().map(x => {

          // flatten some nested objects to make table easier to work with
          return {
            profile_id: x.profile ? x.profile.id : '',
            profile_username: x.profile && x.profile.user ? x.profile.user.username : '',
            partnership_id: x.partnership ? x.partnership.id : '',
            ...x
          };
        })
      : [],
    isLoading: state.userProfilesPartnershipsAdmin.get('isLoading'),
    total: state.userProfilesPartnershipsAdmin.get('total'),
    activeId: storage.get('local', 'partnershipId'),
  }
}

const mapDispatchToProps = dispatch => {
  return {
    load: (parntership_id, page, limit, order, filter, cb) => {
      dispatch(loadUserProfilesPartnershipsAdmin(parntership_id, page, limit, order, filter, cb));
    },
  }
}

const UserProfilesPartnershipsAdminListContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(UserProfilesPartnershipsAdminList);

export default UserProfilesPartnershipsAdminListContainer;
