import { normalize } from 'normalizr';

import api from '../../api';
import { schema } from '../../schema';
import { addEntities, removeEntity } from '../../actions';
import Logger from '../../../lib/Logger';

export const TYPES = {
  LIST_REQUEST: 'RESOURCES/LIST_REQUEST',
  LIST_SUCCESS: 'RESOURCES/LIST_SUCCESS',
  LIST_FAILURE: 'RESOURCES/LIST_FAILURE',
  CREATE_REQUEST: 'RESOURCES/CREATE_REQUEST',
  CREATE_SUCCESS: 'RESOURCES/CREATE_SUCCESS',
  CREATE_FAILURE: 'RESOURCES/CREATE_FAILURE',
  UPDATE_REQUEST: 'RESOURCES/UPDATE_REQUEST',
  UPDATE_SUCCESS: 'RESOURCES/UPDATE_SUCCESS',
  UPDATE_FAILURE: 'RESOURCES/UPDATE_FAILURE',
  PATCH_REQUEST: 'RESOURCES/PATCH_REQUEST',
  PATCH_SUCCESS: 'RESOURCES/PATCH_SUCCESS',
  PATCH_FAILURE: 'RESOURCES/PATCH_FAILURE',
  PATCH_FAVORITE_REQUEST: 'RESOURCES/PATCH_FAVORITE_REQUEST',
  PATCH_FAVORITE_SUCCESS: 'RESOURCES/PATCH_FAVORITE_SUCCESS',
  PATCH_FAVORITE_FAILURE: 'RESOURCES/PATCH_FAVORITE_FAILURE',
  DELETE_REQUEST: 'RESOURCES/DELETE_REQUEST',
  DELETE_SUCCESS: 'RESOURCES/DELETE_SUCCESS',
  DELETE_FAILURE: 'RESOURCES/DELETE_FAILURE',
  PDF_REQUEST: 'RESOURCES/PDF_REQUEST',
  PDF_SUCCESS: 'RESOURCES/PDF_SUCCESS',
  PDF_FAILURE: 'RESOURCES/PDF_FAILURE',
  FORM_DESTROY: 'RESOURCES/FORM_DESTROY',
  SHOW_FORM: 'RESOURCES/SHOW_FORM',
  HIDE_FORM: 'RESOURCES/HIDE_FORM',
  SHOW_PREVIEW_DETAIL: 'RESOURCES/SHOW_PREVIEW_DETAIL',
  HIDE_PREVIEW_DETAIL: 'RESOURCES/HIDE_PREVIEW_DETAIL',
  READ_REQUEST: 'RESOURCES/READ_REQUEST',
  READ_SUCCESS: 'RESOURCES/READ_SUCCESS',
  READ_FAILURE: 'RESOURCES/READ_FAILURE',
  CREATE_INTERACTION_REQUEST: 'RESOURCES/CREATE_INTERACTION_REQUEST',
  CREATE_INTERACTION_SUCCESS: 'RESOURCES/CREATE_INTERACTION_SUCCESS',
  CREATE_INTERACTION_FAILURE: 'RESOURCES/CREATE_INTERACTION_FAILURE',
};

export function resourceListRequest(careGuideId, page, limit, order, filter) {
  Logger.log(
    'debug',
    `[state.resources.actions] resourceListRequest(${careGuideId}, ${page}, ${limit}, ${order}, %j)`,
    filter
  );
  return {
    type: TYPES.LIST_REQUEST,
    careGuideId: careGuideId,
    page: page,
    limit: limit,
    order: order,
    filter: filter,
  };
}

export function resourceListSuccess(careGuideId, data) {
  Logger.log(
    'debug',
    `[state.resources.actions] resourceListSuccess(${careGuideId}, %j)`,
    data
  );
  return {
    type: TYPES.LIST_SUCCESS,
    careGuideId: careGuideId,
    page: data.page,
    limit: data.limit,
    order: data.order,
    result: data.result,
    total: data.total,
    receivedAt: Date.now(),
  };
}

export function resourceListFailure(careGuideId, error) {
  Logger.log(
    'debug',
    `[state.resources.actions] resourceListFailure(${careGuideId}, %j)`,
    error
  );
  return {
    type: TYPES.LIST_FAILURE,
    careGuideId: careGuideId,
    error: error,
  };
}

export function resourceCreateRequest(careGuideId, data) {
  Logger.log(
    'debug',
    `[state.resources.actions] resourceCreateRequest(${careGuideId}, %j)`,
    data
  );
  return {
    type: TYPES.CREATE_REQUEST,
    careGuideId: careGuideId,
  };
}

export function resourceCreateSuccess(careGuideId, data) {
  Logger.log(
    'debug',
    `[state.resources.actions] resourceCreateSuccess(${careGuideId}, %j)`,
    data
  );
  return {
    type: TYPES.CREATE_SUCCESS,
    careGuideId: careGuideId,
    id: data.id,
    receivedAt: Date.now(),
  };
}

export function resourceCreateFailure(careGuideId, error) {
  Logger.log(
    'debug',
    `[state.resources.actions] resourceCreateFailure(${careGuideId}, %j)`,
    error
  );
  return {
    type: TYPES.CREATE_FAILURE,
    careGuideId: careGuideId,
    error: error,
  };
}

export function resourceUpdateRequest(careGuideId, id, data) {
  Logger.log(
    'debug',
    `[state.resources.actions] resourceUpdateRequest(${careGuideId}, ${id}, %j)`,
    data
  );
  return {
    type: TYPES.UPDATE_REQUEST,
    careGuideId: careGuideId,
    id: id,
  };
}

export function resourceUpdateSuccess(careGuideId, data) {
  Logger.log(
    'debug',
    `[state.resources.actions] resourceUpdateSuccess(${careGuideId}, %j)`,
    data
  );
  return {
    type: TYPES.UPDATE_SUCCESS,
    careGuideId: careGuideId,
    id: data.id,
    receivedAt: Date.now(),
  };
}

export function resourceUpdateFailure(careGuideId, error) {
  Logger.log(
    'debug',
    `[state.resources.actions] resourceUpdateFailure(${careGuideId}, %j)`,
    error
  );
  return {
    type: TYPES.UPDATE_FAILURE,
    careGuideId: careGuideId,
    error: error,
  };
}

export function resourcePatchRequest(careGuideId, id, data) {
  Logger.log(
    'debug',
    `[state.resources.actions] resourcePatchRequest(${careGuideId}, ${id}, %j)`,
    data
  );
  return {
    type: TYPES.PATCH_REQUEST,
    careGuideId: careGuideId,
    id: id,
  };
}

export function resourcePatchSuccess(careGuideId, id, data) {
  Logger.log(
    'debug',
    `[state.resources.actions] resourcePatchSuccess(${careGuideId}, ${id}, %j)`,
    data
  );
  return {
    type: TYPES.PATCH_SUCCESS,
    careGuideId: careGuideId,
    id: id,
    receivedAt: Date.now(),
  };
}

export function resourcePatchFailure(careGuideId, id, error) {
  Logger.log(
    'debug',
    `[state.resources.actions] resourcePatchFailure(${careGuideId}, ${id}, %j)`,
    error
  );
  return {
    type: TYPES.PATCH_FAILURE,
    careGuideId: careGuideId,
    id: id,
    error: error,
  };
}

export function resourceFavoritePatchRequest(careGuideId, id, data) {
  Logger.log(
    'debug',
    `[state.resources.actions] resourceFavoritePatchRequest(${careGuideId}, ${id}, %j)`,
    data
  );
  return {
    type: TYPES.PATCH_FAVORITE_REQUEST,
    careGuideId: careGuideId,
    id: id,
  };
}

export function resourceFavoritePatchSuccess(careGuideId, id, data) {
  Logger.log(
    'debug',
    `[state.resources.actions] resourceFavoritePatchSuccess(${careGuideId}, ${id}, %j)`,
    data
  );
  return {
    type: TYPES.PATCH_FAVORITE_SUCCESS,
    careGuideId: careGuideId,
    id: id,
    receivedAt: Date.now(),
  };
}

export function resourceFavoritePatchFailure(careGuideId, id, error) {
  Logger.log(
    'debug',
    `[state.resources.actions] resourceFavoritePatchFailure(${careGuideId}, ${id}, %j)`,
    error
  );
  return {
    type: TYPES.PATCH_FAVORITE_FAILURE,
    careGuideId: careGuideId,
    id: id,
    error: error,
  };
}

export function resourceDeleteRequest(careGuideId, id) {
  Logger.log(
    'debug',
    `[state.resources.actions] resourceDeleteRequest(${careGuideId}, ${id})`
  );
  return {
    type: TYPES.DELETE_REQUEST,
    careGuideId: careGuideId,
    id: id,
  };
}

export function resourceDeleteSuccess(careGuideId, id) {
  Logger.log(
    'debug',
    `[state.resources.actions] resourceDeleteSuccess(${careGuideId}, ${id})`
  );
  return {
    type: TYPES.DELETE_SUCCESS,
    careGuideId: careGuideId,
    id: id,
  };
}

export function resourceDeleteFailure(careGuideId, error) {
  Logger.log(
    'debug',
    `[state.resources.actions] resourceDeleteFailure(${careGuideId}, %j)`,
    error
  );
  return {
    type: TYPES.DELETE_FAILURE,
    careGuideId: careGuideId,
    error: error,
  };
}

export function resourcePDFRequest(careGuideId, page, limit, order, filter) {
  Logger.log(
    'debug',
    `[state.resources.actions] resourcePDFRequest(${careGuideId}, ${page}, ${limit}, ${order}, %j)`,
    filter
  );
  return {
    type: TYPES.PDF_REQUEST,
    careGuideId: careGuideId,
    page: page,
    limit: limit,
    order: order,
    filter: filter,
  };
}

export function resourcePDFSuccess(careGuideId) {
  Logger.log(
    'debug',
    `[state.resources.actions] resourcePDFSuccess(${careGuideId})`
  );
  return {
    type: TYPES.PDF_SUCCESS,
    careGuideId: careGuideId,
    receivedAt: Date.now(),
  };
}

export function resourcePDFFailure(careGuideId, error) {
  Logger.log(
    'debug',
    `[state.resources.actions] resourcePDFFailure(${careGuideId}, %j)`,
    error
  );
  return {
    type: TYPES.PDF_FAILURE,
    careGuideId: careGuideId,
    error: error,
  };
}

export function resourceFormDestroy(formState = null) {
  Logger.log(
    'debug',
    `[state.resources.actions] resourceFormDestroy(%j)`,
    formState
  );
  return {
    type: TYPES.FORM_DESTROY,
    form: formState,
  };
}

export function resourceShowForm(id = null) {
  Logger.log('debug', `[state.resources.actions] resourceShowForm()`);
  return {
    type: TYPES.SHOW_FORM,
    id: id,
  };
}

export function resourceHideForm() {
  Logger.log('debug', `[state.resources.actions] resourceHideForm()`);
  return {
    type: TYPES.HIDE_FORM,
  };
}

export function resourceShowPreviewDetail(resourceId = null) {
  Logger.log('debug', `[state.resources.actions] resourceShowPreviewDetail()`);
  return {
    type: TYPES.SHOW_PREVIEW_DETAIL,
    resourceId: resourceId,
  };
}

export function resourceHidePreviewDetail() {
  Logger.log('debug', `[state.resources.actions] resourceHidePreviewDetail()`);
  return {
    type: TYPES.HIDE_PREVIEW_DETAIL,
  };
}

export function resourceReadRequest(id) {
  Logger.log('debug', `[state.resources.actions] resourceReadRequest(${id})`);
  return {
    type: TYPES.READ_REQUEST,
    id: id,
  };
}

export function resourceReadSuccess(data) {
  Logger.log(
    'debug',
    `[state.resources.actions] resourceReadSuccess(%j)`,
    data
  );
  return {
    type: TYPES.READ_SUCCESS,
    id: data.id,
    receivedAt: Date.now(),
  };
}

export function resourceReadFailure(error) {
  Logger.log(
    'debug',
    `[state.resources.actions] resourceReadFailure(%j)`,
    error
  );
  return {
    type: TYPES.READ_FAILURE,
    error: error,
  };
}

export function resourceCSVDownloadRequest(
  partnershipId,
  resourceId,
  order,
  filter
) {
  Logger.log(
    'debug',
    `[state.resources.actions] resourceCSVDownloadRequest(${partnershipId}, ${resourceId})`
  );
  return {
    type: TYPES.CSV_DOWNLOAD_REQUEST,
    order: order,
    filter: filter,
  };
}

export function resourceCSVDownloadSuccess() {
  Logger.log('debug', `[state.resources.actions] resourceCSVDownloadSuccess()`);
  return {
    type: TYPES.CSV_DOWNLOAD_SUCCESS,
  };
}

export function resourceCSVDownloadFailure() {
  Logger.log('debug', `[state.resources.actions] resourceCSVDownloadFailure()`);
  return {
    type: TYPES.CSV_DOWNLOAD_FAILURE,
  };
}

export function resourceInteractionCreateRequest(careGuideId, id, data) {
  Logger.log(
    'debug',
    `[state.resources.actions] resourceInteractionCreateRequest(${careGuideId}, ${id}, %j)`,
    data
  );
  return {
    type: TYPES.CREATE_INTERACTION_REQUEST,
    careGuideId: careGuideId,
    id: id,
  };
}

export function resourceInteractionCreateSuccess(careGuideId, id) {
  Logger.log(
    'debug',
    `[state.resources.actions] resourceInteractionCreateSuccess(${careGuideId}, ${id})`
  );
  return {
    type: TYPES.CREATE_INTERACTION_SUCCESS,
    careGuideId: careGuideId,
    id: id,
    receivedAt: Date.now(),
  };
}

export function resourceInteractionCreateFailure(careGuideId, id) {
  Logger.log(
    'debug',
    `[state.resources.actions] resourceInteractionCreateFailure(${careGuideId}, ${id})`
  );
  return {
    type: TYPES.CREATE_INTERACTION_FAILURE,
    careGuideId: careGuideId,
    id: id,
  };
}

// API THUNK ACTION CREATORS

export function loadResources(
  careGuideId,
  page = 1,
  limit = 10,
  order = null,
  filter = null,
  cb = function () {}
) {
  Logger.log(
    'debug',
    `[state.resources.actions] loadResources(${careGuideId}, ${page}, ${limit}, ${order}, %j, ###)`,
    filter
  );

  return async function (dispatch) {
    dispatch(resourceListRequest(careGuideId, page, limit, order, filter));

    // call API
    const response = await api.getCareGuideResources(
      careGuideId,
      page,
      limit,
      order,
      filter
    );
    let success = false;

    // get resources list success
    if (200 === response.get('status')) {
      Logger.log(
        'info',
        `Get API resources list success. Care Guide ID: ${careGuideId}, Page: ${page}, Limit: ${limit}, Order: ${order}.`
      );

      const normalizedEntities = normalize(
        response.getIn(['data', 'resources']),
        [schema.resource]
      );
      const data = {
        page: response.getIn(['data', 'page']),
        limit: response.getIn(['data', 'limit']),
        order: order,
        total: response.getIn(['data', 'total']),
        result: normalizedEntities.result,
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(resourceListSuccess(careGuideId, data));
      success = true;
    } else if (1 === page && 204 === response.get('status')) {
      Logger.log(
        'info',
        `Get API resources list success [empty]. Care Guide ID: ${careGuideId}, Page: ${page}, Limit: ${limit}, Order: ${order}.`
      );
      const data = {
        page: page,
        limit: limit,
        order: order,
        total: 0,
        result: [],
      };
      dispatch(resourceListSuccess(careGuideId, data));
      success = true;

      // get resources list failure
    } else {
      Logger.log(
        'info',
        `Get API resources list failure. Care Guide ID: ${careGuideId}, Page: ${page}, Limit: ${limit}, Order: ${order}.`
      );
      dispatch(
        resourceListFailure(careGuideId, response.getIn(['data', 'error']))
      );
    }

    // callback function
    cb(success);
  };
}

export function createResource(careGuideId, data, cb = function () {}) {
  Logger.log(
    'debug',
    `[state.resources.actions] createResource(${careGuideId}, %j, ###)`,
    data
  );

  return async function (dispatch) {
    dispatch(resourceCreateRequest(careGuideId, data));

    // call API
    const response = await api.postCareGuideResources(careGuideId, data);
    let success = false;

    // post resource success
    if (201 === response.get('status')) {
      Logger.log(
        'info',
        `POST API resource success. Care Guide ID: ${careGuideId}.`
      );

      const normalizedEntities = normalize(
        [response.getIn(['data', 'resource'])],
        [schema.resource]
      );
      const data = {
        id: response.getIn(['data', 'resource', 'id']),
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(resourceCreateSuccess(careGuideId, data));
      success = true;

      // post resource failure
    } else {
      Logger.log(
        'info',
        `POST API resource failure. Care Guide ID: ${careGuideId}.`
      );
      dispatch(
        resourceCreateFailure(careGuideId, response.getIn(['data', 'error']))
      );
    }

    // callback function
    cb(success);
  };
}

export function loadResource(careGuideId, id, cb = function () {}) {
  Logger.log(
    'debug',
    `[state.resources.actions] loadResource(${careGuideId}, ${id}, ###)`
  );

  return async function (dispatch) {
    dispatch(resourceReadRequest(id));

    // call API
    const response = await api.getCareGuideResource(careGuideId, id);
    let success = false;

    // get resource success
    if (200 === response.get('status')) {
      Logger.log('info', `Get API resource success. ID: ${id}.`);

      const normalizedEntities = normalize(
        [response.getIn(['data', 'resource'])],
        [schema.resource]
      );
      const data = {
        id: response.getIn(['data', 'resource', 'id']),
      };
      dispatch(addEntities(normalizedEntities));
      dispatch(resourceReadSuccess(data));
      success = true;

      // get resource failure
    } else {
      Logger.log('info', `Get API resource failure. ID: ${id}.`);
      dispatch(resourceReadFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  };
}

export function updateResource(careGuideId, id, data, cb = function () {}) {
  Logger.log(
    'debug',
    `[state.resources.actions] updateResource(${careGuideId}, ${id}, %j, ###)`,
    data
  );

  return async function (dispatch) {
    dispatch(resourceUpdateRequest(careGuideId, id, data));

    // call API
    const response = await api.putCareGuideResource(careGuideId, id, data);
    let success = false;

    // put resource success
    if (200 === response.get('status')) {
      Logger.log(
        'info',
        `UPDATE API resource success. Care Guide ID: ${careGuideId}, User: ${id}.`
      );

      const normalizedEntities = normalize(
        [response.getIn(['data', 'resource'])],
        [schema.resource]
      );
      const data = {
        id: response.getIn(['data', 'resource', 'id']),
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(resourceUpdateSuccess(careGuideId, data));
      success = true;

      // put resource failure
    } else {
      Logger.log(
        'info',
        `UPDATE API resource failure. Care Guide ID: ${careGuideId}, ID: ${id}.`
      );
      dispatch(
        resourceUpdateFailure(careGuideId, response.getIn(['data', 'error']))
      );
    }

    // callback function
    cb(success);
  };
}

export function patchResource(careGuideId, id, data, cb = function () {}) {
  Logger.log(
    'debug',
    `[state.resources.actions] patchResource(${careGuideId}, ${id}, %j, ###)`,
    data
  );

  return async function (dispatch) {
    dispatch(resourcePatchRequest(careGuideId, id, data));
    // call API
    const response = await api.patchCareGuideResource(careGuideId, id, data);
    let success = false;

    // patch resource success
    if (200 === response.get('status')) {
      Logger.log(
        'info',
        `PATCH API resource success. Care Guide ID: ${careGuideId}, ID: ${id}.`
      );

      const normalizedEntities = normalize(
        [response.getIn(['data', 'resource'])],
        [schema.resource]
      );
      const data = {
        id: response.getIn(['data', 'resource', 'id']),
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(resourcePatchSuccess(careGuideId, id, data));
      success = true;

      // patch resource failure
    } else {
      Logger.log(
        'info',
        `PATCH API resource failure. Care Guide ID: ${careGuideId}, ID: ${id}.`
      );
      dispatch(
        resourcePatchFailure(careGuideId, id, response.getIn(['data', 'error']))
      );
    }

    // callback function
    cb(success);
  };
}

export function patchResourceFavorite(
  careGuideId,
  id,
  data,
  cb = function () {}
) {
  Logger.log(
    'debug',
    `[state.resources.actions] patchResourceFavorite(${careGuideId}, ${id}, %j, ###)`,
    data
  );

  return async function (dispatch) {
    dispatch(resourceFavoritePatchRequest(careGuideId, id, data));
    // call API
    const response = await api.patchResourceFavorite(careGuideId, id, data);
    let success = false;

    // patch resource favorite success
    if (200 === response.get('status')) {
      Logger.log(
        'info',
        `PATCH API resource favorite success. Care Guide ID: ${careGuideId}, ID: ${id}.`
      );

      const normalizedEntities = normalize(
        [response.getIn(['data', 'resource'])],
        [schema.resource]
      );
      const data = {
        id: response.getIn(['data', 'resource', 'id']),
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(resourceFavoritePatchSuccess(careGuideId, id, data));
      success = true;

      // patch resource favorite failure
    } else {
      Logger.log(
        'info',
        `PATCH API resource favorite failure. Care Guide ID: ${careGuideId}, ID: ${id}.`
      );
      dispatch(
        resourceFavoritePatchFailure(
          careGuideId,
          id,
          response.getIn(['data', 'error'])
        )
      );
    }

    // callback function
    cb(success);
  };
}

export function deleteResource(careGuideId, id, cb = function () {}) {
  Logger.log('debug', `[state.resources.actions] deleteResource(${id}, ###)`);

  return async function (dispatch) {
    dispatch(resourceDeleteRequest(careGuideId, id));

    // call API
    const response = await api.deleteCareGuideResource(careGuideId, id);
    let success = false;

    // delete resource success
    if (204 === response.get('status')) {
      Logger.log(
        'info',
        `DELETE API resource success. Care Guide ID: ${careGuideId}, ID: ${id}.`
      );

      dispatch(removeEntity({ entityType: 'resources', id: id }));
      dispatch(resourceDeleteSuccess(careGuideId, id));
      success = true;

      // get resource failure
    } else {
      Logger.log(
        'info',
        `DELETE API resource failure. Care Guide ID: ${careGuideId}, ID: ${id}.`
      );
      dispatch(
        resourceDeleteFailure(careGuideId, response.getIn(['data', 'error']))
      );
    }

    // callback function
    cb(success);
  };
}

export function generateResourcesPDF(
  careGuideId,
  page = 1,
  limit = 100,
  order = null,
  filter = null,
  cb = function () {}
) {
  Logger.log(
    'debug',
    `[state.resources.actions] generateResourcesPDF(${careGuideId}, ${page}, ${limit}, ${order}, %j, ###)`,
    filter
  );

  return async function (dispatch) {
    dispatch(resourcePDFRequest(careGuideId, page, limit, order, filter));

    // call API
    const response = await api.getCareGuideResourcesPDF(
      careGuideId,
      page,
      limit,
      order,
      filter
    );
    let success = false;

    // get resources PDF success
    if (200 === response.get('status')) {
      Logger.log(
        'info',
        `Get API resources PDF success. Care Guide ID: ${careGuideId}, Page: ${page}, Limit: ${limit}, Order: ${order}.`
      );

      // trigger browser download
      const url = window.URL.createObjectURL(
        new Blob([response.get('data')], { type: 'application/pdf' })
      );
      const link = document.createElement('a');
      link.href = url;
      const contentDisposition = response.get('headers')['content-disposition'];
      link.setAttribute(
        'download',
        contentDisposition
          ? contentDisposition.substring(
              contentDisposition.indexOf('"') + 1,
              contentDisposition.lastIndexOf('"')
            )
          : 'resources.pdf'
      );
      document.body.appendChild(link);
      link.click();

      dispatch(resourcePDFSuccess(careGuideId));
      success = true;

      // get resources PDF failure
    } else {
      Logger.log(
        'info',
        `Get API resources PDF failure. Care Guide ID: ${careGuideId}, Page: ${page}, Limit: ${limit}, Order: ${order}.`
      );
      dispatch(
        resourcePDFFailure(careGuideId, response.getIn(['data', 'error']))
      );
    }

    // callback function
    cb(success);
  };
}

export function createResourceInteraction(
  careGuideId,
  id,
  data,
  cb = function () {}
) {
  Logger.log(
    'debug',
    `[state.resources.actions] postResourceInteraction(${careGuideId}, ${id}, %j, ###)`,
    data
  );

  return async function (dispatch) {
    dispatch(resourceInteractionCreateRequest(careGuideId, id, data));
    // call API
    const response = await api.postResourceInteraction(careGuideId, id, data);
    let success = false;

    // patch resource interaction success
    if (200 === response.get('status')) {
      Logger.log(
        'info',
        `PATCH API resource interaction success. Care Guide ID: ${careGuideId}, ID: ${id}.`
      );

      dispatch(resourceInteractionCreateSuccess(careGuideId, id, data));
      success = true;

      // patch resource interaction failure
    } else {
      Logger.log(
        'info',
        `PATCH API resource interaction failure. Care Guide ID: ${careGuideId}, ID: ${id}.`
      );
      dispatch(resourceInteractionCreateFailure(careGuideId, id));
    }

    // callback function
    cb(success);
  };
}

Logger.log('silly', `state.resources.actions loaded.`);
