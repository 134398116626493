import api from './api';
import Auth from '../lib/Auth';
import Logger from '../lib/Logger';
import Events from '../lib/EventEmitter';
import storage from '../lib/Storage';
import Config from '../Config';
import { MixpanelTracker, mixpanelEventsEnum } from '../lib/Mixpanel';

export const ADD_ENTITIES = 'ADD_ENTITIES';
export const REMOVE_ENTITY = 'REMOVE_ENTITY';
export const SEND_MESSAGE = 'SEND_MESSAGE';
export const REMOVE_MESSAGE = 'REMOVE_MESSAGE';
export const SESSION_CREATE_REQUEST = 'SESSION_CREATE_REQUEST';
export const SESSION_CREATE_SUCCESS = 'SESSION_CREATE_SUCCESS';
export const SESSION_CREATE_FAILURE = 'SESSION_CREATE_FAILURE';
export const SESSION_REQUIRES_2FA = 'SESSION_REQUIRES_2FA';
export const SESSION_FORM_DESTROY = 'SESSION_FORM_DESTROY';
export const SESSION_HYDRATE = 'SESSION_HYDRATE';
export const SESSION_DESTROY = 'SESSION_DESTROY';
export const PASSWORD_RESET_CODE_REQUEST = 'PASSWORD_RESET_CODE_REQUEST';
export const PASSWORD_RESET_CODE_SUCCESS = 'PASSWORD_RESET_CODE_SUCCESS';
export const PASSWORD_RESET_CODE_FAILURE = 'PASSWORD_RESET_CODE_FAILURE';
export const PASSWORD_RESET_CODE_FORM_DESTROY =
  'PASSWORD_RESET_CODE_FORM_DESTROY';
export const PASSWORD_RESET_REQUEST = 'PASSWORD_RESET_REQUEST';
export const PASSWORD_RESET_SUCCESS = 'PASSWORD_RESET_SUCCESS';
export const PASSWORD_RESET_FAILURE = 'PASSWORD_RESET_FAILURE';
export const PASSWORD_RESET_FORM_DESTROY = 'PASSWORD_RESET_FORM_DESTROY';
export const SESSION_PASSWORD_EXIPRED = 'SESSION_PASSWORD_EXIPRED';
export const REMOVE_ENTITYS = 'REMOVE_ENTITYS';
export const PRESIGNED_URL_AWS_REQUEST = 'PRESIGNED_URL_AWS_REQUEST';
export const PRESIGNED_URL_AWS_SUCCESS = 'PRESIGNED_URL_AWS_SUCCESS';
export const PRESIGNED_URL_AWS_FAILURE = 'PRESIGNED_URL_AWS_FAILURE';

export const addEntities = (entities) => {
  Logger.log('debug', `[state.actions] addEntities(###)`, entities);
  return {
    type: ADD_ENTITIES,
    payload: entities,
  };
};

export const removeEntity = (payload) => {
  Logger.log('debug', `[state.actions] removeEntity(###)`, payload);
  return {
    type: REMOVE_ENTITY,
    payload: payload,
  };
};

export const removeEntitys = (payload) => {
  Logger.log('debug', `[state.actions] removeEntity(###)`, payload);
  return {
    type: REMOVE_ENTITYS,
    payload: payload,
  };
};

export const sendMessage = (level, title, body, expires) => {
  Logger.log(
    'debug',
    `[actions] sendMessage("${level}", "${title}", "${body}", ${expires})`
  );
  if (typeof expires === 'undefined') {
    expires = Config.get('DEFAULT_MESSAGE_TIMEOUT');
  }
  return {
    type: SEND_MESSAGE,
    level: level,
    title: title,
    body: body,
    expires: expires,
  };
};

export const removeMessage = (key) => {
  Logger.log('debug', `[actions] removeMessage("${key}")`);
  return {
    type: REMOVE_MESSAGE,
    key: key,
  };
};

export function sessionCreateRequest(data) {
  Logger.log('debug', `[state.actions] sessionCreateRequest(###)`);
  Events.dispatch('SESSION_CREATE_REQUEST');
  return {
    type: SESSION_CREATE_REQUEST,
    username: data.username,
    password: data.password,
  };
}

export function sessionCreateSuccess(data) {
  Logger.log('debug', `[state.actions] sessionCreateSuccess(###)`, data);

  const tokenClaims = Auth.parseJwt(data.authToken);

  Auth.setStorageType(data.remember ? 'local' : 'session');
  Auth.saveSession(
    data.authToken,
    tokenClaims.iat,
    tokenClaims.exp,
    tokenClaims.id,
    tokenClaims.username,
    tokenClaims.profile_id
  );
  Events.dispatch('SESSION_CREATE_SUCCESS');

  // remember username
  if (data.remember_username) {
    storage.set('local', 'session_username', tokenClaims.username);
  } else {
    storage.set('local', 'session_username', false);
  }

  return {
    type: SESSION_CREATE_SUCCESS,
    authToken: data.authToken,
    authIssuedAt: tokenClaims.iat,
    authExpires: tokenClaims.exp,
    userId: tokenClaims.id,
    username: tokenClaims.username,
    profileId: tokenClaims.profile_id,
    receivedAt: Date.now(),
  };
}

export function sessionCreateFailure(error) {
  Logger.log('debug', `[state.actions] sessionCreateFailure(${error})`);
  Events.dispatch('SESSION_CREATE_FAILURE');
  return {
    type: SESSION_CREATE_FAILURE,
    error: error,
  };
}

export function sessionRequires2FA(data) {
  Logger.log('debug', `[state.actions] sessionRequires2FA(%j)`, data);
  const tokenClaims = Auth.parseJwt(data.otpToken);
  return {
    type: SESSION_REQUIRES_2FA,
    otpToken: data.otpToken,
    authIssuedAt: tokenClaims.iat,
    authExpires: tokenClaims.exp,
    userId: tokenClaims.id,
    username: tokenClaims.username,
    challenge: data.challenge,
    secret: data.secret,
    provisioning_uri: data.provisioning_uri,
    receivedAt: Date.now(),
  };
}

export function sessionFormDestroy(formState = null) {
  Logger.log('debug', `[state.actions] sessionFormDestroy(###)`, formState);
  return {
    type: SESSION_FORM_DESTROY,
    form: formState,
  };
}

export function sessionHydrate(data) {
  Logger.log('debug', `[state.actions] sessionHydrate()`);
  Events.dispatch('SESSION_HYDRATE');
  return {
    type: SESSION_HYDRATE,
    authToken: data.authToken,
    authIssuedAt: data.authIssuedAt,
    authExpires: data.authExpires,
    userId: data.userId,
    profileId: data.profileId,
    username: data.username,
  };
}

export function sessionDestroy() {
  Logger.log('debug', `[state.actions] sessionDestroy()`);
  Auth.deleteSession();
  Events.dispatch('SESSION_DESTROY');
  return {
    type: SESSION_DESTROY,
  };
}

export function passwordResetCodeRequest(data) {
  Logger.log('debug', `[state.actions] passwordResetCodeRequest(###)`);
  return {
    type: PASSWORD_RESET_CODE_REQUEST,
    email: data.email,
  };
}

export function passwordResetCodeSuccess(data) {
  Logger.log('debug', `[state.actions] passwordResetCodeSuccess(###)`, data);
  return {
    type: PASSWORD_RESET_CODE_SUCCESS,
    success: data.success,
    sent: data.sent,
    receivedAt: Date.now(),
  };
}

export function passwordResetCodeFailure(error) {
  Logger.log('debug', `[state.actions] passwordResetCodeFailure(${error})`);
  return {
    type: PASSWORD_RESET_CODE_FAILURE,
    error: error,
  };
}

export function passwordResetCodeFormDestroy(formState = null) {
  Logger.log(
    'debug',
    `[state.actions] passwordResetCodeFormDestroy(###)`,
    formState
  );
  return {
    type: PASSWORD_RESET_CODE_FORM_DESTROY,
    form: formState,
  };
}

export function passwordResetRequest(data) {
  Logger.log('debug', `[state.actions] passwordResetRequest(###)`);
  return {
    type: PASSWORD_RESET_REQUEST,
    email: data.email,
    code: data.code,
    password1: data.password1,
    password2: data.password2,
  };
}

export function passwordResetSuccess(data) {
  Logger.log('debug', `[state.actions] passwordResetSuccess(###)`, data);
  return {
    type: PASSWORD_RESET_SUCCESS,
    success: data.success,
    receivedAt: Date.now(),
  };
}

export function passwordResetFailure(error) {
  Logger.log('debug', `[state.actions] passwordResetFailure(${error})`);
  return {
    type: PASSWORD_RESET_FAILURE,
    error: error,
  };
}

export function passwordResetFormDestroy(formState = null) {
  Logger.log(
    'debug',
    `[state.actions] passwordResetFormDestroy(###)`,
    formState
  );
  return {
    type: PASSWORD_RESET_FORM_DESTROY,
    form: formState,
  };
}

export function sessionPasswordExpired() {
  Logger.log('debug', `[state.actions] sessionPasswordExpired()`);
  return {
    type: SESSION_PASSWORD_EXIPRED,
  };
}

export function createPresignedUrlAWSRequest(data) {
  Logger.log(
    'debug',
    `[state.actions] createPresignedUrlAWSRequest(###)`,
    data
  );
  return {
    type: PRESIGNED_URL_AWS_REQUEST,
    fileType: data.fileType,
    path: data.path,
  };
}

export function createPresignedUrlAWSSuccess(data) {
  Logger.log(
    'debug',
    `[state.actions] createPresignedUrlAWSSuccess(###)`,
    data
  );
  return {
    type: PRESIGNED_URL_AWS_SUCCESS,
    success: data.success,
    receivedAt: Date.now(),
  };
}

export function createPresignedUrlAWSFailure(error) {
  Logger.log('debug', `[state.actions] createPresignedUrlAWSFailure(${error})`);
  return {
    type: PRESIGNED_URL_AWS_FAILURE,
    error: error,
  };
}

// API THUNK ACTION CREATORS

export function createSession(data, cb = function () {}) {
  Logger.log('debug', `[state.actions] createSession(###, ###)`);

  return async function (dispatch) {
    dispatch(sessionCreateRequest(data));

    // call API
    const response = await api.getToken(
      data.username,
      data.password,
      data.token,
      data.otp
    );
    let success = false;
    let requires2FA = false;
    let accountLocked = false;

    // get token success
    if (200 === response.get('status')) {
      Logger.log(
        'info',
        `GET API token success. User: ${response.getIn(['data', 'user_id'])}`
      );
      success = true;

      const sessionSuccessData = {
        authToken: response.getIn(['data', 'token']),
        remember: data.remember,
        remember_username: data.remember_username,
      };

      dispatch(sessionCreateSuccess(sessionSuccessData));
      MixpanelTracker.mixpanelTrack(mixpanelEventsEnum.LOGIN.SUCCESSFUL_LOGIN);
      // get token failure
    } else {
      MixpanelTracker.mixpanelTrack(
        mixpanelEventsEnum.LOGIN.CREDENTIALS_FAILED,
        {
          username: data.username,
        }
      );

      // 2FA challenge
      if (
        ['TOTP', 'TOTP-Enroll'].includes(response.getIn(['data', 'challenge']))
      ) {
        Logger.log(
          'info',
          `GET API token failure - requires two factor authentication.`
        );
        requires2FA = true;
        dispatch(
          sessionRequires2FA({
            otpToken: response.getIn(['data', 'token']),
            challenge: response.getIn(['data', 'challenge']),
            secret: response.getIn(['data', 'secret']),
            provisioning_uri: response.getIn(['data', 'provisioning_uri']),
          })
        );

        // incorrect credentials
      } else {
        Logger.log('info', `GET API token failure.`);
        if (response.getIn(['data', 'error']) === 'Account locked')
          accountLocked = true;
        dispatch(sessionCreateFailure(response.getIn(['data', 'error'])));
      }
    }

    // callback function
    cb(success, requires2FA, accountLocked);
  };
}

export function destroySession(cb = function () {}) {
  Logger.log('debug', `[actions] destroySession(###)`);
  return async function (dispatch) {
    dispatch(sessionDestroy());
    cb();
  };
}

export function requestPasswordResetCode(data, cb = function () {}) {
  Logger.log('debug', `[state.actions] requestPasswordResetCode(###, ###)`);

  return async function (dispatch) {
    dispatch(passwordResetCodeRequest(data));

    // call API
    const response = await api.postPasswordRequestResetCode(data);
    let success = false;

    // post password reset code success
    if (201 === response.get('status')) {
      Logger.log('info', `POST API password reset code success.`);

      success = true;
      const resetSuccessData = {
        success: response.getIn(['data', 'success']),
        sent: response.getIn(['data', 'sent']),
      };

      dispatch(passwordResetCodeSuccess(resetSuccessData));

      // post password reset code failure
    } else {
      Logger.log('info', `POST API password reset code failure.`);
      dispatch(passwordResetCodeFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  };
}

export function resetPassword(data, cb = function () {}) {
  Logger.log('debug', `[state.actions] resetPassword(###, ###)`);

  return async function (dispatch) {
    dispatch(passwordResetRequest(data));

    // call API
    const response = await api.putPasswordReset(data);
    let success = false;

    // put password reset success
    if (200 === response.get('status')) {
      Logger.log('info', `PUT API password reset success.`);

      success = true;
      const resetSuccessData = {
        success: response.getIn(['data', 'success']),
      };

      dispatch(passwordResetSuccess(resetSuccessData));

      // put password reset failure
    } else {
      Logger.log('info', `PUT API password reset failure.`);
      dispatch(passwordResetFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  };
}

export function createPresignedUrlAWS(path, fileType, cb = function () {}) {
  Logger.log(
    'debug',
    `[state.actions] createPresignedUrlAWS(###, ${fileType}})`,
    path
  );

  return async function (dispatch) {
    dispatch(createPresignedUrlAWSRequest({ path, fileType }));

    // call API
    const response = await api.createPutPresignedUrlAWS(path, fileType);
    let success = false;

    const imageUploadUrlSuccessData = {
      upload_url: null,
      file_key: null,
      bucket: null,
    };

    // create presigned url aws success
    if (response['success']) {
      Logger.log('info', `PUT S3 Presigned Url Aws success.`);

      success = true;

      imageUploadUrlSuccessData.upload_url = response['presignedUrl'];
      imageUploadUrlSuccessData.file_key = response['key'];
      imageUploadUrlSuccessData.bucket = response['bucket'];

      dispatch(createPresignedUrlAWSSuccess({ success }));

      // create presigned url aws failure
    } else {
      Logger.log('info', `PUT S3 Presigned Url Aws failure.`);
      dispatch(createPresignedUrlAWSFailure('Upload error.'));
    }

    // callback function
    cb(
      success,
      imageUploadUrlSuccessData.upload_url,
      imageUploadUrlSuccessData.file_key,
      imageUploadUrlSuccessData.bucket
    );
  };
}

Logger.log('silly', `state.actions loaded.`);
