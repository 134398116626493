import {List, Map} from 'immutable';

import {TYPES} from './actions';
import {SESSION_DESTROY} from '../../actions';
import Logger from '../../../lib/Logger';

const initialState = Map({
  isLoading: false,
  isLoadingCounts: false,
  isDeleting: false,
  inbox: {},
  drafts: {},
  sent: {},
  archived: {},
  deleted: {},
  form: {},
  recipientForm: {},
  composeFormIsVisible: false,
  composeFormMessageId: null,
  composeFormRecipientIds: [],
  composeChatMessageId: null,
  composeChatRecipientIds: [],
  unread: 0,
});

export default function messages(
  state=initialState,
  action
) {
  Logger.log('debug', `[state.messages.reducers] messages(%j, %j)`, state, action);

  switch(action.type) {

    case TYPES.LIST_INBOX_REQUEST:
      return state.mergeDeep({
        isLoading: true
      });

    case TYPES.LIST_INBOX_SUCCESS:
      return state.mergeDeep({
        isLoading: false,
        inbox: {
          total: action.total,
          pages: {
            [action.order]: {
              [action.limit]: {
                [action.page]: null
              }
            }
          }
        },
        lastUpdated: action.receivedAt
      }).setIn(['inbox', 'pages', action.order, action.limit, action.page], List(action.result));

    case TYPES.LIST_INBOX_FAILURE:
      return state.mergeDeep({
        isLoading: false
      });

    case TYPES.LIST_DRAFTS_REQUEST:
      return state.mergeDeep({
        isLoading: true
      });

    case TYPES.LIST_DRAFTS_SUCCESS:
      return state.mergeDeep({
        isLoading: false,
        drafts: {
          total: action.total,
          pages: {
            [action.order]: {
              [action.limit]: {
                [action.page]: null
              }
            }
          }
        },
        lastUpdated: action.receivedAt
      }).setIn(['drafts', 'pages', action.order, action.limit, action.page], List(action.result));

    case TYPES.LIST_DRAFTS_FAILURE:
      return state.mergeDeep({
        isLoading: false
      });

    case TYPES.LIST_SENT_REQUEST:
      return state.mergeDeep({
        isLoading: true
      });

    case TYPES.LIST_SENT_SUCCESS:
      return state.mergeDeep({
        isLoading: false,
        sent: {
          total: action.total,
          pages: {
            [action.order]: {
              [action.limit]: {
                [action.page]: null
              }
            }
          }
        },
        lastUpdated: action.receivedAt
      }).setIn(['sent', 'pages', action.order, action.limit, action.page], List(action.result));

    case TYPES.LIST_SENT_FAILURE:
      return state.mergeDeep({
        isLoading: false
      });

    case TYPES.LIST_ARCHIVED_REQUEST:
      return state.mergeDeep({
        isLoading: true
      });

    case TYPES.LIST_ARCHIVED_SUCCESS:
      return state.mergeDeep({
        isLoading: false,
        archived: {
          total: action.total,
          pages: {
            [action.order]: {
              [action.limit]: {
                [action.page]: null
              }
            }
          }
        },
        lastUpdated: action.receivedAt
      }).setIn(['archived', 'pages', action.order, action.limit, action.page], List(action.result));

    case TYPES.LIST_ARCHIVED_FAILURE:
      return state.mergeDeep({
        isLoading: false
      });

    case TYPES.LIST_DELETED_REQUEST:
      return state.mergeDeep({
        isLoading: true
      });

    case TYPES.LIST_DELETED_SUCCESS:
      return state.mergeDeep({
        isLoading: false,
        deleted: {
          total: action.total,
          pages: {
            [action.order]: {
              [action.limit]: {
                [action.page]: null
              }
            }
          }
        },
        lastUpdated: action.receivedAt
      }).setIn(['deleted', 'pages', action.order, action.limit, action.page], List(action.result));

    case TYPES.LIST_DELETED_FAILURE:
      return state.mergeDeep({
        isLoading: false
      });

    case TYPES.SEND_REQUEST:
      return state.mergeDeep({
        form: {
          isSubmitting: true,
          isSaving: false,
          success: null,
          errors: null
        }
      });

    case TYPES.SEND_SUCCESS:
      return state.mergeDeep({
        form: {
          isSubmitting: false,
          isSaving: false,
          success: true,
          errors: null
        },
        lastUpdated: action.receivedAt
      });

    case TYPES.SEND_FAILURE:
      return state.mergeDeep({
        form: {
          isSubmitting: false,
          isSaving: false,
          success: false,
          errors: null
        }
      }).setIn(['form', 'errors'], action.error);

    case TYPES.PATCH_RECIPIENT_REQUEST:
      return state.mergeDeep({
        recipientForm: {
          isSubmitting: true,
          success: null,
          errors: null
        }
      });

    case TYPES.PATCH_RECIPIENT_SUCCESS:
      return state.mergeDeep({
        recipientForm: {
          isSubmitting: false,
          success: true,
          errors: null
        },
        lastUpdated: action.receivedAt
      });

    case TYPES.PATCH_RECIPIENT_FAILURE:
      return state.mergeDeep({
        recipientForm: {
          isSubmitting: false,
          success: false,
          errors: null
        }
      }).setIn(['recipientForm', 'errors'], action.error);

    case TYPES.PATCH_REQUEST:
      return state.mergeDeep({
        form: {
          isSubmitting: true,
          isSaving: false,
          success: null,
          errors: null
        }
      });

    case TYPES.PATCH_SUCCESS:
      return state.mergeDeep({
        form: {
          isSubmitting: false,
          isSaving: false,
          success: true,
          errors: null
        },
        lastUpdated: action.receivedAt
      });

    case TYPES.PATCH_FAILURE:
      return state.mergeDeep({
        form: {
          isSubmitting: false,
          isSaving: false,
          success: false,
          errors: null
        }
      }).setIn(['form', 'errors'], action.error);

    case TYPES.FORM_DESTROY:
      return state.mergeDeep({
        form: {}
      }).set('form', action.form);

    case TYPES.SHOW_COMPOSE_FORM:
      return state.mergeDeep({
        composeFormIsVisible: true,
        composeFormThreadId: action.threadId,
        composeFormMessageId: action.message,
        composeFormRecipientIds: action.recipientIds,
      });
    
    case TYPES.COMPOSE_CHAT:
      return state.mergeDeep({
        composeChatThreadId: action.threadId,
        composeChatMessageId: action.message,
        composeChatRecipientIds: action.recipientIds,
      });
      
    case TYPES.HIDE_COMPOSE_FORM:
      return state.mergeDeep({
        composeFormIsVisible: false,
        composeFormThreadId: null,
        composeFormMessageId: null,
      }).set('composeFormRecipientIds', []);

    case TYPES.SAVE_DRAFT_REQUEST:
      return state.mergeDeep({
        form: {
          isSubmitting: false,
          isSaving: true,
          success: null,
          errors: null
        }
      });

    case TYPES.SAVE_DRAFT_SUCCESS:
      return state.mergeDeep({
        form: {
          isSubmitting: false,
          isSaving: false,
          success: true,
          errors: null
        },
        lastUpdated: action.receivedAt
      });

    case TYPES.SAVE_DRAFT_FAILURE:
      return state.mergeDeep({
        form: {
          isSubmitting: false,
          isSaving: false,
          success: false,
          errors: null
        }
      }).setIn(['form', 'errors'], action.error);

    case TYPES.DELETE_REQUEST:
      return state.mergeDeep({
        isDeleting: false,
      });

    case TYPES.DELETE_SUCCESS:
      return state.mergeDeep({
        isDeleting: false,
      });

    case TYPES.DELETE_FAILURE:
      return state.mergeDeep({
        isDeleting: false,
      });

    case TYPES.COUNTS_REQUEST:
      return state.mergeDeep({
        isLoadingCounts: true
      });

    case TYPES.COUNTS_SUCCESS:
      return state.mergeDeep({
        isLoadingCounts: false,
        unread: action.unread,
        lastUpdated: action.receivedAt
      });

    case TYPES.COUNTS_FAILURE:
      return state.mergeDeep({
        isLoadingCounts: false
      });

    case SESSION_DESTROY:
      return initialState;

    default:
      return state;
  }
}

Logger.log('silly', `state.messages.reducers loaded.`);
