import {connect} from 'react-redux';
import {List} from 'immutable';
import PartnershipAccountAccessCareGuideForm from '../components/PartnershipAccountAccessCareGuideForm';

import {
  updatePartnershipAccountAccessCareGuide,
  partnershipAccountAccessCareGuideFormDestroy
} from '../../../../../state/modules/partnershipAccount/actions';
import storage from "../../../../../lib/Storage";

const inputs = List([
  'access_role_admin_in_care_guide',
]);

const mapStateToProps = (state) => {

  const data = {}, errors = {};
  for (const val of inputs.values()) {
    data[val] = state.partnershipAccount.getIn(['data', val], ['access_role_admin_in_care_guide'].includes(val) ? {} : null);
    if (state.partnershipAccount.getIn(['accessCareGuideForm', 'errors', val])) {
      errors[val] = state.partnershipAccount.getIn(['accessCareGuideForm', 'errors', val]);
    }
  }

  return {
    activeId: storage.get('local', 'partnershipId'),
    isLoading: state.partnershipAccount.get('isLoading'),
    isSubmitting: state.partnershipAccount.get('isSubmittingAccessCareGuide'),
    success: state.partnershipAccount.getIn(['accessCareGuideForm', 'success']),
    data: data,
    errors: errors
  }
}

const mapDispatchToProps = dispatch => {
  return {
    submit: (partnership_id, data, cb) => {
      dispatch(updatePartnershipAccountAccessCareGuide(partnership_id, data, cb));
    },
    formDestroy: (formState) => {
      dispatch(partnershipAccountAccessCareGuideFormDestroy(formState));
    },
  }
}

const PartnershipAccountAccessCareGuideFormContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(PartnershipAccountAccessCareGuideForm);

export default PartnershipAccountAccessCareGuideFormContainer
