import {connect} from 'react-redux';
import {denormalize} from 'normalizr';
import {withRouter} from "react-router";

import MessagesList from '../components/MessagesList';
import {schema} from '../../../../../state/schema';
import {
  loadMessagesInbox,
  loadMessagesDrafts,
  loadMessagesSent,
  loadMessagesArchived,
  loadMessagesDeleted,
  messageShowComposeForm,
} from '../../../../../state/modules/messages/actions';
import {getRouteFromPath} from '../../../Routes';

const mapStateToProps = (state, ownProps) => {

  const type = ownProps.type;
  const page = ownProps.page;
  const limit = ownProps.limit;
  const order = ownProps.order;
  const result = state.messages.getIn([type, 'pages', order, limit, page]);

  const currentRoute = getRouteFromPath(ownProps.location.pathname);
  const threadId = currentRoute && currentRoute.params ? currentRoute.params.threadId : null;

  return {
    list: result 
      ? result.map(x => {
          return {
            key: x,
            ...denormalize(
              state.entities.getIn(['messageThreads', x]),
              schema.messageThread,
              state.entities.toJS()
            )
          };
        }).toArray()
      : [],
    total: state.messages.getIn([type, 'total']),
    isLoading: state.messages.get('isLoading'),
    threadId: threadId,
    profileId: state.session.get('profileId'),
  }
}

const mapDispatchToProps = dispatch => {
  return {
    load: (type, page, limit, order, filter, cb) => {
      switch(type) {
        case 'inbox':
          dispatch(loadMessagesInbox(page, limit, order, filter, cb));
          break;
        case 'drafts':
          dispatch(loadMessagesDrafts(page, limit, order, filter, cb));
          break;
        case 'sent':
          dispatch(loadMessagesSent(page, limit, order, filter, cb));
          break;
        case 'archived':
          dispatch(loadMessagesArchived(page, limit, order, filter, cb));
          break;
        case 'deleted':
          dispatch(loadMessagesDeleted(page, limit, order, filter, cb));
          break;
        default:
      }
    },
    showForm: (messageId) => {
      dispatch(messageShowComposeForm(messageId));
    },
  }
}

const MessagesListContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(MessagesList);

export default withRouter(MessagesListContainer);
