import React from 'react';
import {Translation} from 'react-i18next';
import {Link} from 'react-router-dom';
import {MailOutlined} from '@ant-design/icons';

import QueryString from '../../../../../lib/QueryString';
import {pathTo} from '../../../Routes';


const MessageLink = ({careGuideId, profile, key=null, ...props}) => {
  if (profile) {
    return (
      <Translation>{(t) => 
        <div className="message-link" key={key}>
          <Link to={QueryString.append(pathTo('MessagesInboxScreen'), {composeThreadId: careGuideId, composeProfileId: profile.id})}>
            <MailOutlined />
            {t('messages_action_send_message')}
          </Link>
        </div>
      }</Translation>
    )
  }
  return null;
}

export default MessageLink;
