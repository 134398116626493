import {connect} from 'react-redux';

import CareGuideAdminEditScreen from '../components/CareGuideAdminEditScreen';

const mapStateToProps = state => {
  return {
    isLoading: state.careGuidesAdmin.get('isLoading'),
    success: state.careGuidesAdmin.getIn(['form', 'success']),
  }
}

const CareGuideAdminEditScreenContainer = connect(
  mapStateToProps
)(CareGuideAdminEditScreen);

export default CareGuideAdminEditScreenContainer;