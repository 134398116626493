import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router';
import { Translation, getI18n } from 'react-i18next';
import { Button, Comment, Dropdown, Menu, Modal, List } from 'antd';
import {
  CloseCircleOutlined,
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  ReloadOutlined,
} from '@ant-design/icons';
import { FaEllipsisV } from 'react-icons/fa';

import UserAvatar from '../../../elements/components/UserAvatar';
import JournalReplyForm from '../containers/JournalReplyFormContainer';
import hasPermission, {
  CREATE_JOURNAL_COMMENT,
} from '../../../elements/lib/hasPermission';
import message from '../../../elements/lib/MessageWrapper';
import Format from '../../../../../lib/Format';
import Logger from '../../../../../lib/Logger';
import useWindowDimensions from '../../../../../lib/WindowDimensions';
import Config from '../../../../../Config';
import {
  MixpanelTracker,
  mixpanelEventsEnum,
} from '../../../../../lib/Mixpanel';

const JournalRepliesList = ({
  careGuideId,
  careGuide,
  journalPostId,
  component,
  page,
  limit,
  order,
  total,
  load,
  history,
  ...props
}) => {
  const [loadingComments, setLoadingComments] = useState(false);

  useEffect(() => {
    load(careGuideId, journalPostId, page, limit, order, null, () => {
      setLoadingComments(false);
    });
  }, [careGuideId, journalPostId, page, limit, order, load]);

  const onLoadPreviousClick = () => {
    setLoadingComments(true);
    props.setPage(page + 1);
  };

  const onClickEditHandler = (id) => {
    props.showForm(journalPostId, id);
  };

  const onClickDeleteHandler = (id) => {
    props.patch(
      careGuideId,
      journalPostId,
      id,
      { public_status: 3 },
      (success) => {
        if (success) {
          message.success(getI18n().t('feedback_archive_success'));
          props.loadAll(careGuideId, journalPostId, page, limit, order);
          if (careGuide?.partnership) {
            MixpanelTracker.mixpanelTrack(
              mixpanelEventsEnum.JOURNAL.DELETE_REPLY_POST,
              {
                careGuideName: `${careGuide.first_name} ${careGuide.last_name}`,
                partnershipName: careGuide.partnership.name,
                careGuideId: careGuide.id,
                partnershipId: careGuide.partnership.id,
                journalPostId: journalPostId,
                journalPostReplyId: id,
              }
            );
          } else {
            MixpanelTracker.mixpanelTrack(
              mixpanelEventsEnum.JOURNAL.DELETE_REPLY_POST,
              {
                careGuideName: `${careGuide.first_name} ${careGuide.last_name}`,
                careGuideId: careGuide.id,
                journalPostId: journalPostId,
                journalPostReplyId: id,
              }
            );
          }
        } else {
          message.error(getI18n().t('feedback_archive_error'));
        }
      }
    );
  };

  const confirmDelete = (id) => {
    Modal.confirm({
      title: getI18n().t('journal_replies_delete_confirm_title'),
      icon: <ExclamationCircleOutlined />,
      content: getI18n().t('journal_replies_delete_confirm_body'),
      okType: 'danger',
      okText: getI18n().t('action_delete'),
      okButtonProps: {
        icon: <DeleteOutlined />,
      },
      cancelText: getI18n().t('confirm_cancel'),
      cancelButtonProps: {
        icon: <CloseCircleOutlined />,
      },
      onOk() {
        onClickDeleteHandler(id);
      },
    });
  };

  const { width } = useWindowDimensions();
  const isLarge = width > 992;

  const pagination = false;

  // using ternary to keep button visible while loading
  const showLoadMore = loadingComments
    ? Math.ceil((page - 1) * limit) < total
    : Math.ceil(page * limit) < total;

  return (
    <Translation>
      {(t) => (
        <>
          {showLoadMore ? (
            <div style={{ textAlign: 'center' }}>
              <Button
                size="small"
                onClick={onLoadPreviousClick}
                icon={<ReloadOutlined />}
                loading={loadingComments}
              >
                {t('journal_replies_button_load_previous')}
              </Button>
            </div>
          ) : null}

          {total ? (
            <List
              className="journal-replies-list"
              itemLayout="vertical"
              dataSource={props.list}
              pagination={pagination}
              renderItem={(item) => {
                // if reply has been edited, append label to last element
                if (
                  item.edited_at &&
                  !item.body.includes("<span class='edited-label'>")
                ) {
                  const match = item.body.match(/^(.*)(<(?:.*?)>)$/);
                  if (match) {
                    item.body =
                      match[1] +
                      "<span class='edited-label'>(" +
                      t('label_edited') +
                      ')</span>' +
                      match[2];
                  }
                }

                return (
                  <li key={item.id}>
                    <Comment
                      author={item.profile_name}
                      avatar={
                        <UserAvatar
                          profile={item.profile}
                          showTooltp={false}
                          size={isLarge ? 32 : 24}
                        />
                      }
                      content={
                        <div dangerouslySetInnerHTML={{ __html: item.body }} />
                      }
                      datetime={
                        <>
                          {Format.date(
                            item.published_at,
                            Config.get('DEFAULT_DATETIME_FORMAT')
                          )}
                          {props.profileId === item.profile.id ? (
                            <Dropdown
                              overlay={
                                <Menu>
                                  <Menu.Item
                                    key={'edit-' + item.id}
                                    icon={<EditOutlined />}
                                    onClick={() => onClickEditHandler(item.id)}
                                  >
                                    {t('action_edit')}
                                  </Menu.Item>
                                  <Menu.Item
                                    key={'delete-' + item.id}
                                    danger
                                    icon={<DeleteOutlined />}
                                    onClick={() => confirmDelete(item.id)}
                                  >
                                    {t('action_delete')}
                                  </Menu.Item>
                                </Menu>
                              }
                              trigger={['click']}
                            >
                              <span className="anticon" tabIndex={-1}>
                                <FaEllipsisV />
                              </span>
                            </Dropdown>
                          ) : null}
                        </>
                      }
                    />
                  </li>
                );
              }}
            />
          ) : null}

          {hasPermission(CREATE_JOURNAL_COMMENT) ? (
            <JournalReplyForm
              careGuideId={careGuideId}
              journalPostId={journalPostId}
              page={page}
              limit={limit}
              order={order}
            />
          ) : null}
        </>
      )}
    </Translation>
  );
};

export default withRouter(JournalRepliesList);

Logger.log('silly', `JournalRepliesList loaded.`);
