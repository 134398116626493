import React, { useEffect, useRef, useState } from 'react';
import { Translation, getI18n } from 'react-i18next';
import { IoChevronBackOutline } from 'react-icons/io5';
import { Button, Row, Tabs, Typography, Col } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import { Redirect } from 'react-router-dom';
import { pathTo } from '../../../Routes';
import MemberProfile from '../containers/MemberProfileContainer';
import CareGuideNotesScreen from '../../careGuideNotes/components/CareGuideNotesScreen';
import { withRouter } from 'react-router';
import ConfirmationModal from '../../../elements/components/ConfirmationModal';
import QueryString from '../../../../../lib/QueryString';
import CareGuideDocuments from '../../careGuideDocuments/containers/CareGuideDocumentsScreenContainer';
import Config from '../../../../../Config';
import hasPermission, {
  VIEW_ACCESS_CARE_GUIDE,
} from '../../../elements/lib/hasPermission';
import DocumentHead from '../../../elements/components/DocumentHead';
import {
  MixpanelTracker,
  mixpanelEventsEnum,
} from '../../../../../lib/Mixpanel';
import DailyLogsScreen from '../containers/DailyLogsScreenContainer';

const { Text } = Typography;

const modalTexts = {
  title: getI18n().t('member_action_modal_title'),
  description: getI18n().t('member_action_modal_description'),
  leaveText: getI18n().t('member_action_modal_leave'),
  saveText: getI18n().t('member_action_modal_save'),
};

const tabScreens = {
  1: 'MemberProfileScreen',
  2: 'CareGuideNotesScreen',
  3: 'CareGuideDocumentsScreen',
  4: 'DailyLogsScreen',
};

const MemberActions = ({
  careGuideId,
  careGuide,
  owner,
  profileId,
  partnershipId,
  partnershipName,
  data,
  errors,
  isLoading,
  history,
  accessCareGuide,
  ...props
}) => {
  const limit =
    parseInt(props.match.params.limit ? props.match.params.limit : 10) ||
    Config.get('DEFAULT_LIST_LENGTH', 10);
  const page = parseInt(props.match.params.page ? props.match.params.page : 1);

  const { order, ...filter } = QueryString.parse(props.location.search);

  const [redirect, setRedirect] = useState(null);
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [redirectConfirmation, setRedirectConfirmation] = useState(false);
  const [cognitoId, setCognitoId] = useState(null);
  const [cognitoKey, setCognitoKey] = useState(null);
  const [activeKey, setActiveKey] = useState('1');

  const saveChanges = useRef(false);

  const backToMembersList = () => {
    setRedirect(pathTo('CareGuideOwnersScreen'));
  };

  const goBackAction = () => {
    if (redirectConfirmation) {
      setConfirmationModalOpen(true);
      setRedirectConfirmation(false);
    } else {
      backToMembersList();
    }
  };

  const onModalConfirmation = () => {
    saveChanges.current = true;
    setConfirmationModalOpen(false);
  };

  const handleReset = (activeKey) => {
    setActiveKey(activeKey);

    const path = pathTo('CareGuideMembersActions', {
      id: careGuideId,
    });

    history.push(QueryString.append(path, {}));

    MixpanelTracker.mixpanelTrack(
      mixpanelEventsEnum.PAGES.ACCESS_PAGE_ADMINISTRATOR,
      {
        partnershipName: partnershipName,
        partnershipId: partnershipId,
        page: tabScreens[activeKey],
      }
    );
  };

  useEffect(() => {
    if (careGuide) {
      if (careGuide.partnership) {
        if (
          careGuide?.partnership?.cognito_form_id != null &&
          !careGuide?.partnership?.cognito_form_key != null
        ) {
          if (
            !careGuide.partnership.cognito_form_id.trim() !== 0 &&
            !careGuide.partnership.cognito_form_key.trim() !== 0
          ) {
            setCognitoId(careGuide.partnership.cognito_form_id);
            setCognitoKey(careGuide.partnership.cognito_form_key);
          } else {
            setCognitoId(null);
            setCognitoKey(null);
          }
        } else {
          setCognitoId(null);
          setCognitoKey(null);
        }

        if (accessCareGuide) {
          if (hasPermission(VIEW_ACCESS_CARE_GUIDE, careGuide.id, careGuide)) {
            if (!accessCareGuide?.member_profile) {
              setRedirect(pathTo('CareGuideOwnersScreen', {}));
            }
          }
        }
      }
    }
  }, [careGuideId, careGuide, accessCareGuide]);

  if (redirect) {
    return <Redirect to={redirect} />;
  }

  return (
    <Translation>
      {(t) => (
        <Content className="member-profile-layout">
          <Row>
            <Button type="link" onClick={goBackAction} className="back-link">
              <IoChevronBackOutline /> {t('members_list_back_to_list')}
            </Button>
          </Row>

          {activeKey !== '1' && careGuide && (
            <Row style={{ paddingLeft: '8px', fontSize: '20px' }}>
              <Col>
                <Text strong>{t('member_profile_role_caregiver') + ': '}</Text>
                <Text>
                  {owner.first_name} {owner.last_name}
                </Text>
              </Col>
              <Col>
                <Text strong>{t('member_profile_role_caremember') + ': '}</Text>
                <Text>
                  {careGuide.first_name} {careGuide.last_name}
                </Text>
              </Col>
            </Row>
          )}
          <Tabs
            destroyInactiveTabPane={true}
            onChange={handleReset}
            type="card"
            className="member-section-tabs"
          >
            <Tabs.TabPane
              destroyInactiveTabPane={true}
              tab={t('care_guide_owners_profile')}
              key={'1'}
            >
              <DocumentHead title={t('care_guide_owners_profile')} />
              <MemberProfile
                submitForm={saveChanges.current}
                setRedirectConfirmation={setRedirectConfirmation}
                careGuideId={careGuideId}
                partnershipId={partnershipId}
              />
            </Tabs.TabPane>
            <Tabs.TabPane
              destroyInactiveTabPane={true}
              tab={t('care_guide_owners_member_notes')}
              key={'2'}
            >
              <DocumentHead title={t('care_guide_owners_member_notes')} />
              <CareGuideNotesScreen
                component={'CareGuideMembersActions'}
                careGuideId={careGuideId}
                page={page}
                order={order || 'is_pinned.desc'}
                filter={filter}
                limit={limit}
              />
            </Tabs.TabPane>
            <Tabs.TabPane tab={t('care_guide_owners_documents')} key={'3'}>
              <DocumentHead title={t('care_guide_owners_documents')} />
              <CareGuideDocuments
                limit={limit}
                filter={filter}
                page={page}
                careGuideId={careGuideId}
                order={order || 'updated_at.asc'}
              />
            </Tabs.TabPane>

            {cognitoId && cognitoKey && (
              <Tabs.TabPane
                destroyInactiveTabPane={true}
                tab={t('care_guide_owners_cognito')}
                key={'4'}
              >
                <DocumentHead title={t('care_guide_owners_cognito')} />
                <DailyLogsScreen
                  component={'CareGuideMembersActions'}
                  careGuideId={careGuideId}
                  careGuide={careGuide}
                  partnershipId={partnershipId}
                  profileId={profileId}
                  cognitoId={cognitoId}
                  cognitoKey={cognitoKey}
                  page={page}
                  order={order || 'created_at.asc'}
                  filter={filter}
                  limit={limit}
                />
              </Tabs.TabPane>
            )}
          </Tabs>
          <ConfirmationModal
            texts={modalTexts}
            openConfirmationModal={confirmationModalOpen}
            onLeaveConfirmation={backToMembersList}
            onSaveConfirmation={onModalConfirmation}
            onCancel={() => setConfirmationModalOpen(false)}
            setConfirmationModalOpen={setConfirmationModalOpen}
          />
        </Content>
      )}
    </Translation>
  );
};

export default withRouter(MemberActions);
