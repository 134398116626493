import {connect} from 'react-redux';
import {denormalize} from 'normalizr';

import {schema} from '../../../../../state/schema';
import {loadJournalPartnerTagsAdmin, deleteJournalPartnerTagAdmin} from '../../../../../state/modules/journalPartnerTagsAdmin/actions';
import {sendMessage} from '../../../../../state/actions';
import JournalPartnerTagsAdminList from '../components/JournalPartnerTagsAdminList';
import storage from "../../../../../lib/Storage";

const mapStateToProps = (state, ownProps) => {

  const page = ownProps.page;
  const limit = ownProps.limit;
  const order = ownProps.order;
  const result = state.journalPartnerTagsAdmin.getIn(['pages', order, limit, page]);

  return {
    list: result 
      ? result.map(x => {
          return {
            key: x,
            ...denormalize(
              state.entities.getIn(['journalPartnerTagsAdmin', x]),
              schema.journalPartnerTagAdmin,
              state.entities.toJS()
            )
          };
        }).toArray()
      : [],
    isLoading: state.journalPartnerTagsAdmin.get('isLoading'),
    total: state.journalPartnerTagsAdmin.get('total'),
    activeId: storage.get('local', 'partnershipId'),
  }
}

const mapDispatchToProps = dispatch => {
  return {
    load: (parntership_id, page, limit, order, filter, cb) => {
      dispatch(loadJournalPartnerTagsAdmin(parntership_id, page, limit, order, filter, cb));
    },
    remove: (partnership_id, id, cb) => {
      dispatch(deleteJournalPartnerTagAdmin(partnership_id, id, cb));
    },
    sendMessage: (level, title, body, expires) => {
      dispatch(sendMessage(level, title, body, expires));
    },
  }
}

const JournalPartnerTagsAdminListContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(JournalPartnerTagsAdminList);

export default JournalPartnerTagsAdminListContainer;