import React, {Component} from 'react';
import {Redirect} from 'react-router-dom';
import {Translation} from 'react-i18next';
import {Col, PageHeader, Row} from 'antd';

import RegisterStep3Form from '../containers/RegisterStep3FormContainer'
import RegisterStep3List from '../containers/RegisterStep3ListContainer'
import DocumentHead from '../../../elements/components/DocumentHead';
import QueryString from '../../../../../lib/QueryString';
import Config from '../../../../../Config';
import Logger from '../../../../../lib/Logger';
import {pathTo} from '../../../Routes';
import '../styles/Register.less';

class RegisterStep3Screen extends Component {

  render() {
    const params = QueryString.parse(this.props.location.search);
    const careGuideId = 'id' in params ? params.id : null;

    if (!careGuideId) {
      return <Redirect to={pathTo(Config.get('DEFAULT_LOGIN_REDIRECT'))} />;
    }

    return (
      <Translation>{(t) => 
        <div className="screen screen-public screen-register">

          <DocumentHead title={t('register_route_step3')} />

          <PageHeader
            onBack={() => this.props.history.push(pathTo('RegisterStep2Screen'))}
            title={t('register_screen_step3_page_title')}
            subTitle={t('register_screen_form3_header')}
          />

          <div className="container-form-register">
            <Row type="flex" justify="space-around" align="middle">
              <Col xs={22} sm={12} md={10} lg={8} xl={6}>

                <h1>{t('register_screen_step3_title')}</h1>
                <h2>{t('register_screen_step3_subtitle')}</h2>

                <RegisterStep3Form
                  careGuideId={careGuideId}
                  page={1}
                  limit={50}
                  order={'joined_at.asc'}
                />

                <RegisterStep3List
                  careGuideId={careGuideId}
                  page={1}
                  limit={50}
                  order={'joined_at.asc'}
                />

              </Col>
            </Row>
          </div>

        </div>
      }</Translation>
    )
  }

  /* LIFECYCLE EVENTS */

  componentDidMount() {
    if (typeof window !== 'undefined') {
      window.scrollTo(0, 0);
    }
  }
}

export default RegisterStep3Screen;

Logger.log('silly', `RegisterStep3Screen loaded.`);
