import {List, Map} from 'immutable';

import {TYPES} from './actions';
import {SESSION_DESTROY} from '../../actions';
import Logger from '../../../lib/Logger';

const initialState = Map({
  isLoading: false,
  results: {},
  form: {},
});

export default function journalEmojis(
  state=initialState,
  action
) {
  Logger.log('debug', `[state.journalEmojis.reducers] journalEmojis(%j, %j)`, state, action);

  switch(action.type) {

    case TYPES.LIST_REQUEST:
      return state.mergeDeep({
        isLoading: true
      });

    case TYPES.LIST_SUCCESS:
      return state.mergeDeep({
        isLoading: false,
        results: {
          [action.careGuideId]: {
            [action.journalPostId]: {
              total: action.total,
              pages: {
                [action.order]: {
                  [action.limit]: {
                    [action.page]: null
                  }
                }
              }
            }
          }
        },
        lastUpdated: action.receivedAt
      }).setIn(['results', action.careGuideId, action.journalPostId, 'pages', action.order, action.limit, action.page], List(action.result));

    case TYPES.LIST_FAILURE:
      return state.mergeDeep({
        isLoading: false
      });

    case TYPES.CREATE_REQUEST:
      return state.mergeDeep({
        form: {
          isSubmitting: true,
          success: null,
          errors: null
        }
      });

    case TYPES.CREATE_SUCCESS:
      return state.mergeDeep({
        form: {
          isSubmitting: false,
          success: true,
          errors: null
        },
        lastUpdated: action.receivedAt
      });

    case TYPES.CREATE_FAILURE:
      return state.mergeDeep({
        form: {
          isSubmitting: false,
          success: false,
          errors: null
        }
      }).setIn(['form', 'errors'], action.error);

    case TYPES.DELETE_REQUEST:
      return state.mergeDeep({
        isLoading: false,
        lastUpdated: action.receivedAt
      });

    case TYPES.DELETE_SUCCESS:
      return state.mergeDeep({
        isLoading: false
      });

    case TYPES.DELETE_FAILURE:
      return state.mergeDeep({
        form: null
      }).set('form', action.form);

    case TYPES.FORM_DESTROY:
      return state.mergeDeep({
        form: {}
      }).set('form', action.form);

    case SESSION_DESTROY:
      return initialState;

    default:
      return state;
  }
}

Logger.log('silly', `state.journalEmojis.reducers loaded.`);
