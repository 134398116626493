import { List, Map } from 'immutable';

import { TYPES } from './actions';
import { SESSION_DESTROY } from '../../actions';
import Logger from '../../../lib/Logger';

const initialState = Map({
  isLoading: false,
  isInvitedLoading: false,
  results: {},
  members: {},
  invited: {},
  form: {},
  formIsVisible: false,
  formHelperId: null,
});

export default function careGuideHelpers(state = initialState, action) {
  Logger.log(
    'debug',
    `[state.careGuideHelpers.reducers] careGuideHelpers(%j, %j)`,
    state,
    action
  );

  switch (action.type) {
    case TYPES.LIST_REQUEST:
      return state.mergeDeep({
        isLoading: true,
      });

    case TYPES.LIST_SUCCESS:
      return state
        .mergeDeep({
          isLoading: false,
          helperCounts: {
            [action.careGuideId]: action.helperCount,
          },
          results: {
            [action.careGuideId]: {
              total: action.total,
              pages: {
                [action.order]: {
                  [action.limit]: {
                    [action.page]: null,
                  },
                },
              },
            },
          },
          lastUpdated: action.receivedAt,
        })
        .setIn(
          [
            'results',
            action.careGuideId,
            'pages',
            action.order,
            action.limit,
            action.page,
          ],
          List(action.result)
        );

    case TYPES.LIST_FAILURE:
      return state.mergeDeep({
        isLoading: false,
      });

    case TYPES.UPDATE_REQUEST:
      return state.mergeDeep({
        form: {
          isSubmitting: true,
          success: null,
          errors: null,
        },
      });

    case TYPES.UPDATE_SUCCESS:
      return state.mergeDeep({
        form: {
          isSubmitting: false,
          success: true,
          errors: null,
        },
        lastUpdated: action.receivedAt,
      });

    case TYPES.UPDATE_FAILURE:
      return state
        .mergeDeep({
          form: {
            isSubmitting: false,
            success: false,
            errors: null,
          },
        })
        .setIn(['form', 'errors'], action.error);

    case TYPES.PATCH_REQUEST:
      return state.mergeDeep({
        form: {
          isSubmitting: true,
          success: null,
          errors: null,
        },
      });

    case TYPES.PATCH_SUCCESS:
      return state.mergeDeep({
        form: {
          isSubmitting: false,
          success: true,
          errors: null,
        },
        lastUpdated: action.receivedAt,
      });

    case TYPES.PATCH_FAILURE:
      return state
        .mergeDeep({
          form: {
            isSubmitting: false,
            success: false,
            errors: null,
          },
        })
        .setIn(['form', 'errors'], action.error);

    case TYPES.DELETE_REQUEST:
      return state.mergeDeep({
        isLoading: false,
        lastUpdated: action.receivedAt,
      });

    case TYPES.DELETE_SUCCESS:
      return state.mergeDeep({
        isLoading: false,
      });

    case TYPES.DELETE_FAILURE:
      return state
        .mergeDeep({
          form: null,
        })
        .set('form', action.form);

    case TYPES.LIST_MEMBERS_REQUEST:
      return state.mergeDeep({
        isLoading: true,
      });

    case TYPES.LIST_MEMBERS_SUCCESS:
      return state
        .mergeDeep({
          isLoading: false,
          helperCounts: {
            [action.careGuideId]: action.helperCount,
          },
          members: {
            [action.careGuideId]: {
              total: action.total,
              pages: {
                [action.order]: {
                  [action.limit]: {
                    [action.page]: null,
                  },
                },
              },
            },
          },
          lastUpdated: action.receivedAt,
        })
        .setIn(
          [
            'members',
            action.careGuideId,
            'pages',
            action.order,
            action.limit,
            action.page,
          ],
          List(action.result)
        );

    case TYPES.LIST_MEMBERS_FAILURE:
      return state.mergeDeep({
        isLoading: false,
      });

    case TYPES.LIST_MEMBERS_ADMIN_REQUEST:
      return state.mergeDeep({
        isLoading: true,
      });

    case TYPES.LIST_MEMBERS_ADMIN_SUCCESS:
      return state
        .mergeDeep({
          isLoading: false,
          helperCounts: action.helperCount,
          members: {
            total: action.total,
            pages: [],
          },
          lastUpdated: action.receivedAt,
        })
        .setIn(['members', 'pages'], List(action.result));

    case TYPES.LIST_MEMBERS_ADMIN_FAILURE:
      return state.mergeDeep({
        isLoading: false,
      });

    case TYPES.LIST_INVITED_REQUEST:
      return state.mergeDeep({
        isInvitedLoading: true,
      });

    case TYPES.LIST_INVITED_SUCCESS:
      return state
        .mergeDeep({
          isInvitedLoading: false,
          invited: {
            [action.careGuideId]: {
              total: action.total,
              pages: {
                [action.order]: {
                  [action.limit]: {
                    [action.page]: null,
                  },
                },
              },
            },
          },
          lastUpdated: action.receivedAt,
        })
        .setIn(
          [
            'invited',
            action.careGuideId,
            'pages',
            action.order,
            action.limit,
            action.page,
          ],
          List(action.result)
        );

    case TYPES.LIST_INVITED_FAILURE:
      return state.mergeDeep({
        isInvitedLoading: false,
      });

    case TYPES.FORM_DESTROY:
      return state
        .mergeDeep({
          form: {},
        })
        .set('eventForm', action.form);

    case TYPES.SHOW_FORM:
      return state.mergeDeep({
        formIsVisible: true,
        formHelperId: action.helperId,
      });

    case TYPES.HIDE_FORM:
      return state.mergeDeep({
        formIsVisible: false,
        formHelperId: null,
      });

    case SESSION_DESTROY:
      return initialState;

    default:
      return state;
  }
}

Logger.log('silly', `state.careGuideHelpers.reducers loaded.`);
