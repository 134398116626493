import {normalize} from 'normalizr';

import api from '../../api';
import {schema} from '../../schema';
import {addEntities, removeEntity} from '../../actions';
import Logger from '../../../lib/Logger';

export const TYPES = {
  LIST_REQUEST: 'CARE_GUIDE_HELPERS/LIST_REQUEST',
  LIST_SUCCESS: 'CARE_GUIDE_HELPERS/LIST_SUCCESS',
  LIST_FAILURE: 'CARE_GUIDE_HELPERS/LIST_FAILURE',
  UPDATE_REQUEST: 'CARE_GUIDE_HELPERS/UPDATE_REQUEST',
  UPDATE_SUCCESS: 'CARE_GUIDE_HELPERS/UPDATE_SUCCESS',
  UPDATE_FAILURE: 'CARE_GUIDE_HELPERS/UPDATE_FAILURE',
  PATCH_REQUEST: 'CARE_GUIDE_HELPERS/PATCH_REQUEST',
  PATCH_SUCCESS: 'CARE_GUIDE_HELPERS/PATCH_SUCCESS',
  PATCH_FAILURE: 'CARE_GUIDE_HELPERS/PATCH_FAILURE',
  DELETE_REQUEST: 'CARE_GUIDE_HELPERS/DELETE_REQUEST',
  DELETE_SUCCESS: 'CARE_GUIDE_HELPERS/DELETE_SUCCESS',
  DELETE_FAILURE: 'CARE_GUIDE_HELPERS/DELETE_FAILURE',
  LIST_MEMBERS_REQUEST: 'CARE_GUIDE_HELPERS/LIST_MEMBERS_REQUEST',
  LIST_MEMBERS_SUCCESS: 'CARE_GUIDE_HELPERS/LIST_MEMBERS_SUCCESS',
  LIST_MEMBERS_FAILURE: 'CARE_GUIDE_HELPERS/LIST_MEMBERS_FAILURE',
  LIST_MEMBERS_ADMIN_REQUEST: 'CARE_GUIDE_HELPERS/LIST_MEMBERS_ADMIN_REQUEST',
  LIST_MEMBERS_ADMIN_SUCCESS: 'CARE_GUIDE_HELPERS/LIST_MEMBERS_ADMIN_SUCCESS',
  LIST_MEMBERS_ADMIN_FAILURE: 'CARE_GUIDE_HELPERS/LIST_MEMBERS_ADMIN_FAILURE',
  LIST_INVITED_REQUEST: 'CARE_GUIDE_HELPERS/LIST_INVITED_REQUEST',
  LIST_INVITED_SUCCESS: 'CARE_GUIDE_HELPERS/LIST_INVITED_SUCCESS',
  LIST_INVITED_FAILURE: 'CARE_GUIDE_HELPERS/LIST_INVITED_FAILURE',
  FORM_DESTROY: 'CARE_GUIDE_HELPERS/FORM_DESTROY',
  SHOW_FORM: 'CARE_GUIDE_HELPERS/SHOW_FORM',
  HIDE_FORM: 'CARE_GUIDE_HELPERS/HIDE_FORM',
};

export function careGuideHelperListRequest(careGuideId, page, limit, order, filter) {
  Logger.log('debug', `[state.careGuideHelpers.actions] careGuideHelperListRequest(${careGuideId}, ${page}, ${limit}, ${order}, %j)`, filter);
  return {
    type: TYPES.LIST_REQUEST,
    careGuideId: careGuideId,
    page: page,
    limit: limit,
    order: order,
    filter: filter,
  }
}

export function careGuideHelperListSuccess(careGuideId, data) {
  Logger.log('debug', `[state.careGuideHelpers.actions] careGuideHelperListSuccess(${careGuideId}, %j)`, data);
  return {
    type: TYPES.LIST_SUCCESS,
    careGuideId: careGuideId,
    page: data.page,
    limit: data.limit,
    order: data.order,
    result: data.result,
    total: data.total,
    helperCount: data.helper_count,
    receivedAt: Date.now()
  }
}

export function careGuideHelperListFailure(careGuideId, error) {
  Logger.log('debug', `[state.careGuideHelpers.actions] careGuideHelperListFailure(${careGuideId}, %j)`, error);
  return {
    type: TYPES.LIST_FAILURE,
    careGuideId: careGuideId,
    error: error
  }
}

export function careGuideHelperUpdateRequest(careGuideId, id, data) {
  Logger.log('debug', `[state.calendar.actions] careGuideHelperUpdateRequest(${careGuideId}, ${id}, %j)`, data);
  return {
    type: TYPES.UPDATE_REQUEST,
    careGuideId: careGuideId,
    id: id,
  }
}

export function careGuideHelperUpdateSuccess(careGuideId, data) {
  Logger.log('debug', `[state.careGuideHelpers.actions] careGuideHelperUpdateSuccess(${careGuideId}, %j)`, data);
  return {
    type: TYPES.UPDATE_SUCCESS,
    careGuideId: careGuideId,
    id: data.id,
    receivedAt: Date.now()
  }
}

export function careGuideHelperUpdateFailure(careGuideId, error) {
  Logger.log('debug', `[state.careGuideHelpers.actions] careGuideHelperUpdateFailure(${careGuideId}, %j)`, error);
  return {
    type: TYPES.UPDATE_FAILURE,
    careGuideId: careGuideId,
    error: error
  }
}

export function careGuideHelperPatchRequest(careGuideId, id, data) {
  Logger.log('debug', `[state.careGuideHelpers.actions] careGuideHelperPatchRequest(${careGuideId}, ${id}, %j)`, data);
  return {
    type: TYPES.PATCH_REQUEST,
    careGuideId: careGuideId,
    id: id,
  }
}

export function careGuideHelperPatchSuccess(careGuideId, data) {
  Logger.log('debug', `[state.careGuideHelpers.actions] careGuideHelperPatchSuccess(${careGuideId}, %j)`, data);
  return {
    type: TYPES.PATCH_SUCCESS,
    careGuideId: careGuideId,
    id: data.id,
    receivedAt: Date.now()
  }
}

export function careGuideHelperPatchFailure(careGuideId, error) {
  Logger.log('debug', `[state.careGuideHelpers.actions] careGuideHelperPatchFailure(${careGuideId}, %j)`, error);
  return {
    type: TYPES.PATCH_FAILURE,
    careGuideId: careGuideId,
    error: error
  }
}

export function careGuideHelperDeleteRequest(careGuideId, id) {
  Logger.log('debug', `[state.careGuideHelpers.actions] careGuideHelperDeleteRequest(${careGuideId}, ${id})`);
  return {
    type: TYPES.DELETE_REQUEST,
    careGuideId: careGuideId,
    id: id
  }
}

export function careGuideHelperDeleteSuccess(careGuideId, id) {
  Logger.log('debug', `[state.careGuideHelpers.actions] careGuideHelperDeleteSuccess(${careGuideId}, ${id})`);
  return {
    type: TYPES.DELETE_SUCCESS,
    careGuideId: careGuideId,
    id: id,
  }
}

export function careGuideHelperDeleteFailure(careGuideId, error) {
  Logger.log('debug', `[state.careGuideHelpers.actions] careGuideHelperDeleteFailure(${careGuideId}, %j)`, error);
  return {
    type: TYPES.DELETE_FAILURE,
    careGuideId: careGuideId,
    error: error
  }
}

export function careGuideHelperListMembersRequest(careGuideId, page, limit, order, filter) {
  Logger.log('debug', `[state.careGuideHelpers.actions] careGuideHelperListMembersRequest(${careGuideId}, ${page}, ${limit}, ${order}, %j)`, filter);
  return {
    type: TYPES.LIST_MEMBERS_REQUEST,
    careGuideId: careGuideId,
    page: page,
    limit: limit,
    order: order,
    filter: filter,
  }
}

export function careGuideHelperListMembersSuccess(careGuideId, data) {
  Logger.log('debug', `[state.careGuideHelpers.actions] careGuideHelperListMembersSuccess(${careGuideId}, %j)`, data);
  return {
    type: TYPES.LIST_MEMBERS_SUCCESS,
    careGuideId: careGuideId,
    page: data.page,
    limit: data.limit,
    order: data.order,
    result: data.result,
    total: data.total,
    helperCount: data.helper_count,
    receivedAt: Date.now()
  }
}

export function careGuideHelperListMembersFailure(careGuideId, error) {
  Logger.log('debug', `[state.careGuideHelpers.actions] careGuideHelperListMembersFailure(${careGuideId}, %j)`, error);
  return {
    type: TYPES.LIST_MEMBERS_FAILURE,
    careGuideId: careGuideId,
    error: error
  }
}

export function careGuideHelperListInvitedRequest(careGuideId, page, limit, order, filter) {
  Logger.log('debug', `[state.careGuideHelpers.actions] careGuideHelperListInvitedRequest(${careGuideId}, ${page}, ${limit}, ${order}, %j)`, filter);
  return {
    type: TYPES.LIST_INVITED_REQUEST,
    careGuideId: careGuideId,
    page: page,
    limit: limit,
    order: order,
    filter: filter,
  }
}

export function careGuideHelperListInvitedSuccess(careGuideId, data) {
  Logger.log('debug', `[state.careGuideHelpers.actions] careGuideHelperListInvitedSuccess(${careGuideId}, %j)`, data);
  return {
    type: TYPES.LIST_INVITED_SUCCESS,
    careGuideId: careGuideId,
    page: data.page,
    limit: data.limit,
    order: data.order,
    result: data.result,
    total: data.total,
    receivedAt: Date.now()
  }
}

export function careGuideHelperListInvitedFailure(careGuideId, error) {
  Logger.log('debug', `[state.careGuideHelpers.actions] careGuideHelperListInvitedFailure(${careGuideId}, %j)`, error);
  return {
    type: TYPES.LIST_INVITED_FAILURE,
    careGuideId: careGuideId,
    error: error
  }
}

export function careGuideHelperFormDestroy(formState=null) {
  Logger.log('debug', `[state.careGuideHelpers.actions] careGuideHelperFormDestroy(%j)`, formState);
  return {
    type: TYPES.FORM_DESTROY,
    form: formState
  }
}

export function careGuideHelperShowForm(helperId=null) {
  Logger.log('debug', `[state.careGuideHelpers.actions] careGuideHelperShowForm()`);
  return {
    type: TYPES.SHOW_FORM,
    helperId: helperId,
  }
}

export function careGuideHelperHideForm() {
  Logger.log('debug', `[state.careGuideHelpers.actions] careGuideHelperHideForm()`);
  return {
    type: TYPES.HIDE_FORM,
  }
}

export function careGuideHelperListMembersAdminRequest(filter) {
  Logger.log('debug', `[state.careGuideHelpers.actions] careGuideHelperListMembersAdminRequest(%j)`, filter);
  return {
    type: TYPES.LIST_MEMBERS_ADMIN_REQUEST,
    filter: filter,
  }
}

export function careGuideHelperListMembersAdminSuccess(data) {
  Logger.log('debug', `[state.careGuideHelpers.actions] careGuideHelperListMembersAdminSuccess(%j)`, data);
  return {
    type: TYPES.LIST_MEMBERS_ADMIN_SUCCESS,
    result: data.result,
    total: data.total,
    helperCount: data.helper_count,
    receivedAt: Date.now()
  }
}

export function careGuideHelperListMembersAdminFailure(error) {
  Logger.log('debug', `[state.careGuideHelpers.actions] careGuideHelperListMembersAdminFailure(%j)`, error);
  return {
    type: TYPES.LIST_MEMBERS_ADMIN_FAILURE,
    error: error
  }
}



// API THUNK ACTION CREATORS

export function loadCareGuideHelpers(careGuideId, page=1, limit=10, order=null, filter=null, cb=function(){}) {
  Logger.log('debug', `[state.careGuideHelpers.actions] loadCareGuideHelpers(${careGuideId}, ${page}, ${limit}, ${order}, %j, ###)`, filter);

  return async function(dispatch) {
    dispatch(careGuideHelperListRequest(careGuideId, page, limit, order, filter));

    // call API
    const response = await api.getCareGuideHelpers(careGuideId, page, limit, order, filter);
    let success = false;

    // get care guide helpers list success
    if (200 === response.get('status')) {

      Logger.log('info', `Get API care guide helpers list success. Care Guide ID: ${careGuideId}, Page: ${page}, Limit: ${limit}, Order: ${order}.`);

      const normalizedEntities = normalize(response.getIn(['data', 'care_guide_helpers']), [schema.careGuideHelper]);
      const data = {
        page: response.getIn(['data', 'page']),
        limit: response.getIn(['data', 'limit']),
        order: order,
        total: response.getIn(['data', 'total']),
        helper_count: response.getIn(['data', 'helper_count']),
        result: normalizedEntities.result
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(careGuideHelperListSuccess(careGuideId, data));
      success = true;

    } else if (1 === page && 204 === response.get('status')) {

      Logger.log('info', `Get API care guide helpers list success [empty]. Care Guide ID: ${careGuideId}, Page: ${page}, Limit: ${limit}, Order: ${order}.`);
      const data = {
        page: page,
        limit: limit,
        order: order,
        total: 0,
        helper_count: 0,
        result: []
      };
      dispatch(careGuideHelperListSuccess(careGuideId, data));
      success = true;
      
    // get care guide helpers list failure
    } else {
      Logger.log('info', `Get API care guide helpers list failure. Care Guide ID: ${careGuideId}, Page: ${page}, Limit: ${limit}, Order: ${order}.`);
      dispatch(careGuideHelperListFailure(careGuideId, response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  }
}

export function updateCareGuideHelper(careGuideId, id, data, cb=function(){}) {
  Logger.log('debug', `[state.careGuideHelpers.actions] updateCareGuideHelper(${careGuideId}, ${id}, %j, ###)`, data);

  return async function(dispatch) {
    dispatch(careGuideHelperUpdateRequest(careGuideId, id, data));

    // call API
    const response = await api.putCareGuideHelper(careGuideId, id, data);
    let success = false;

    // PUT care guide helper success
    if (200 === response.get('status')) {

      Logger.log('info', `PUT API care guide helper success. Care Guide ID: ${careGuideId}, User: ${id}.`);

      const normalizedEntities = normalize([response.getIn(['data', 'care_guide_helper'])], [schema.careGuideHelper]);
      const data = {
        id: response.getIn(['data', 'care_guide_helper', 'id']),
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(careGuideHelperUpdateSuccess(careGuideId, data));
      success = true;
      
    // PUT care guide helper failure
    } else {
      Logger.log('info', `PUT API care guide helper failure. Care Guide ID: ${careGuideId}, ID: ${id}.`);
      dispatch(careGuideHelperUpdateFailure(careGuideId, response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  }
}

export function patchCareGuideHelper(careGuideId, id, data, cb=function(){}) {
  Logger.log('debug', `[state.careGuideHelpers.actions] patchCareGuideHelper(${careGuideId}, ${id}, %j, ###)`, data);

  return async function(dispatch) {
    dispatch(careGuideHelperPatchRequest(careGuideId, id, data));

    // call API
    const response = await api.patchCareGuideHelper(careGuideId, id, data);
    let success = false;

    // patch care guide helper success
    if (200 === response.get('status')) {

      Logger.log('info', `PATCH API care guide helper success. Care Guide ID: ${careGuideId}, User: ${id}.`);

      const normalizedEntities = normalize([response.getIn(['data', 'care_guide_helper'])], [schema.careGuideHelper]);
      const data = {
        id: response.getIn(['data', 'care_guide_helper', 'id']),
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(careGuideHelperPatchSuccess(careGuideId, data));
      success = true;
      
    // patch care guide helper failure
    } else {
      Logger.log('info', `PATCH API care guide helper failure. Care Guide ID: ${careGuideId}, ID: ${id}.`);
      dispatch(careGuideHelperPatchFailure(careGuideId, response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  }
}

export function deleteCareGuideHelper(careGuideId, id, cb=function(){}) {
  Logger.log('debug', `[state.careGuideHelpers.actions] deleteCareGuideHelper(${id}, ###)`);

  return async function(dispatch) {
    dispatch(careGuideHelperDeleteRequest(careGuideId, id));

    // call API
    const response = await api.deleteCareGuideHelper(careGuideId, id);
    let success = false;

    // delete care guide helper success
    if (204 === response.get('status')) {

      Logger.log('info', `DELETE API care guide helper success. Care Guide ID: ${careGuideId}, ID: ${id}.`);

      dispatch(removeEntity({entityType: 'care_guide_helpers', id: id}));
      dispatch(careGuideHelperDeleteSuccess(careGuideId, id));
      success = true;
      
    // get care guide helper failure
    } else {
      Logger.log('info', `DELETE API care guide helper failure. Care Guide ID: ${careGuideId}, ID: ${id}.`);
      dispatch(careGuideHelperDeleteFailure(careGuideId, response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  }
}

export function loadCareGuideMembers(careGuideId, page=1, limit=50, order=null, filter=null, cb=function(){}) {
  Logger.log('debug', `[state.careGuideHelpers.actions] loadCareGuideMembers(${careGuideId}, ${page}, ${limit}, ${order}, %j, ###)`, filter);

  return async function(dispatch) {
    dispatch(careGuideHelperListMembersRequest(careGuideId, page, limit, order, filter));

    // call API
    const response = await api.getCareGuideMembers(careGuideId, page, limit, order, filter);
    let success = false;

    // get care guide members list success
    if (200 === response.get('status')) {

      Logger.log('info', `Get API care guide members list success. Care Guide ID: ${careGuideId}, Page: ${page}, Limit: ${limit}, Order: ${order}.`);

      const normalizedEntities = normalize(response.getIn(['data', 'care_guide_members']), [schema.careGuideMember]);
      const data = {
        page: response.getIn(['data', 'page']),
        limit: response.getIn(['data', 'limit']),
        order: order,
        total: response.getIn(['data', 'total']),
        helper_count: response.getIn(['data', 'helper_count']),
        result: normalizedEntities.result
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(careGuideHelperListMembersSuccess(careGuideId, data));
      success = true;

    } else if (1 === page && 204 === response.get('status')) {

      Logger.log('info', `Get API care guide members list success [empty]. Care Guide ID: ${careGuideId}, Page: ${page}, Limit: ${limit}, Order: ${order}.`);
      const data = {
        page: page,
        limit: limit,
        order: order,
        total: 0,
        helper_count: 0,
        result: []
      };
      dispatch(careGuideHelperListMembersSuccess(careGuideId, data));
      success = true;
      
    // get care guide members list failure
    } else {
      Logger.log('info', `Get API care guide members list failure. Care Guide ID: ${careGuideId}, Page: ${page}, Limit: ${limit}, Order: ${order}.`);
      dispatch(careGuideHelperListMembersFailure(careGuideId, response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  }
}

export function loadCareGuideMembersAdmin(filter=null, cb=function(){}) {
  Logger.log('debug', `[state.careGuideHelpers.actions] loadCareGuideMembers(%j, ###)`, filter);

  return async function(dispatch) {
    dispatch(careGuideHelperListMembersAdminRequest(filter));

    // call API
    const response = await api.getCareGuideMembersAdmin(filter);
    let success = false;

    // get care guide members admin list success
    if (200 === response.get('status')) {

      Logger.log('info', `Get API care guide members admin list success`);

      const normalizedEntities = normalize(response.getIn(['data', 'care_guide_members']), [schema.careGuideMember]);
      const data = {
        total: response.getIn(['data', 'total']),
        helper_count: response.getIn(['data', 'helper_count']),
        result: normalizedEntities.result
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(careGuideHelperListMembersAdminSuccess(data));
      success = true;

    } else if (204 === response.get('status')) {

      Logger.log('info', `Get API care guide members admin list success [empty].`);
      const data = {
        total: 0,
        helper_count: 0,
        result: []
      };
      dispatch(careGuideHelperListMembersAdminSuccess(data));
      success = true;
      
    // get care guide members admin list failure
    } else {
      Logger.log('info', `Get API care guide members admin list failure.`);
      dispatch(careGuideHelperListMembersFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  }
}


export function loadCareGuideInvited(careGuideId, page=1, limit=50, order=null, filter={public_status: 3}, cb=function(){}) {
  Logger.log('debug', `[state.careGuideHelpers.actions] loadCareGuideInvited(${careGuideId}, ${page}, ${limit}, ${order}, %j, ###)`, filter);

  return async function(dispatch) {
    dispatch(careGuideHelperListInvitedRequest(careGuideId, page, limit, order, filter));

    // call API
    const response = await api.getCareGuideHelpers(careGuideId, page, limit, order, filter);
    let success = false;

    // get care guide invited list success
    if (200 === response.get('status')) {

      Logger.log('info', `Get API care guide invited list success. Care Guide ID: ${careGuideId}, Page: ${page}, Limit: ${limit}, Order: ${order}.`);

      const normalizedEntities = normalize(response.getIn(['data', 'care_guide_helpers']), [schema.careGuideMember]);
      const data = {
        page: response.getIn(['data', 'page']),
        limit: response.getIn(['data', 'limit']),
        order: order,
        total: response.getIn(['data', 'total']),
        result: normalizedEntities.result
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(careGuideHelperListInvitedSuccess(careGuideId, data));
      success = true;

    } else if (1 === page && 204 === response.get('status')) {

      Logger.log('info', `Get API care guide invited list success [empty]. Care Guide ID: ${careGuideId}, Page: ${page}, Limit: ${limit}, Order: ${order}.`);
      const data = {
        page: page,
        limit: limit,
        order: order,
        total: 0,
        result: []
      };
      dispatch(careGuideHelperListInvitedSuccess(careGuideId, data));
      success = true;
      
    // get care guide invited list failure
    } else {
      Logger.log('info', `Get API care guide invited list failure. Care Guide ID: ${careGuideId}, Page: ${page}, Limit: ${limit}, Order: ${order}.`);
      dispatch(careGuideHelperListInvitedFailure(careGuideId, response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  }
}

Logger.log('silly', `state.careGuideHelpers.actions loaded.`);
