import {connect} from 'react-redux';

import CareGuideInvitationAccept from '../components/CareGuideInvitationAccept';
import {respondCareGuideInvitation} from '../../../../../state/modules/careGuideInvitations/actions';
import QueryString from '../../../../../lib/QueryString';

const mapStateToProps = (state, ownProps) => {

  const queryParams = QueryString.parse(ownProps.location.search);
  const invitationId = ownProps.match.params.invitationId;
  const code = 'code' in queryParams ? queryParams['code'] : null;

  return {
    isResponding: state.careGuideInvitations.get('isResponding'),
    careGuideId: state.entities.getIn(['careGuideInvitations', invitationId, 'care_guide']),
    invitationId: invitationId,
    code: code,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    respond: (id, data, cb) => {
      dispatch(respondCareGuideInvitation(id, data, cb));
    },
  }
}

const CareGuideInvitationAcceptContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(CareGuideInvitationAccept);

export default CareGuideInvitationAcceptContainer;
