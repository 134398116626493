import {connect} from 'react-redux';
import {denormalize} from 'normalizr';
import {withRouter} from "react-router";

import RegisterStep3List from '../components/RegisterStep3List';
import {schema} from '../../../../../state/schema';
import {
  loadCareGuideHelpers,
  deleteCareGuideHelper,
} from '../../../../../state/modules/careGuideHelpers/actions';

const mapStateToProps = (state, ownProps) => {

  const careGuideId = ownProps.careGuideId;
  const page = ownProps.page;
  const limit = ownProps.limit;
  const order = ownProps.order;
  const result = state.careGuideHelpers.getIn(['results', careGuideId, 'pages', order, limit, page]);

  return {
    list: result 
      ? result.map(x => {
          return {
            key: x,
            ...denormalize(
              state.entities.getIn(['careGuideHelpers', x]),
              schema.careGuideHelper,
              state.entities.toJS()
            )
          };
        }).toArray().filter(x => !x.is_owner).filter(x => x.invitation)
      : [],
    total: state.careGuideHelpers.getIn(['results', careGuideId, 'total']),
    isLoading: state.careGuideHelpers.get('isLoading')
  }
}

const mapDispatchToProps = dispatch => {
  return {
    load: (careGuideId, page, limit, order, filter, cb) => {
      dispatch(loadCareGuideHelpers(careGuideId, page, limit, order, filter, cb));
    },
    remove: (careGuideId, id, cb) => {
      dispatch(deleteCareGuideHelper(careGuideId, id, cb));
    },
  }
}

const RegisterStep3ListContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(RegisterStep3List);

export default withRouter(RegisterStep3ListContainer);
