import React, {useState} from 'react';
import {Translation, getI18n} from 'react-i18next';
import {Form, Input} from 'antd';


const PasswordConfirmInput = ({name, form, hasError=false, label=null, rules=null, disabled=false, autoFocus=false, useSuccess=true, ...props}) => {

  const [isSameComplete, setIsSameComplete] = useState(false);

  const onChange = e => {
    const val = e.target.value;
    
    // check same as password
    setIsSameComplete(val === form.getFieldValue('password'));
  }

  const compareToFirstPassword = (rule, value) => {
    if (value && value !== form.getFieldValue('password')) {
      return Promise.reject(getI18n().t('feedback_validation_password_match'));
    } else {
      return Promise.resolve();
    }
  }

  return (
    <Translation>{(t) =>
      <div className="form-group">
        <Form.Item
          name={name}
          label={label ? label : t('user_account_input_password')}
          rules={rules
            ? rules
            : [
                {required: true, message: t('feedback_validation_required')},
                {validator: compareToFirstPassword}
              ]}
          // hasFeedback={useSuccess && isSameComplete && !hasError}
          hasFeedback={true}
          validateStatus={hasError ? "error" : (useSuccess && isSameComplete ? "success" : null)}
        >
          <Input.Password
            disabled={disabled}
            autoFocus={autoFocus}
            onChange={onChange}
          />
        </Form.Item>
      </div>
    }</Translation>
  )
}

export default PasswordConfirmInput;
