import {connect} from 'react-redux';
import {denormalize} from 'normalizr';

import {schema} from '../../../../../state/schema';
import {loadCareGuidesAdmin} from '../../../../../state/modules/careGuidesAdmin/actions';
import CareGuidesAdminList from '../components/CareGuidesAdminList';
import {
  careGuideSetActive,
  careGuideSetAccess,
  loadCareGuides
} from "../../../../../state/modules/careGuides/actions";
import storage from "../../../../../lib/Storage";

const mapStateToProps = (state, ownProps) => {

  const page = ownProps.page;
  const limit = ownProps.limit;
  const order = ownProps.order;
  const result = state.careGuidesAdmin.getIn(['pages', order, limit, page]);

  return {
    list: result 
      ? result.map(x => {
          return {
            key: x,
            ...denormalize(
              state.entities.getIn(['careGuidesAdmin', x]),
              schema.careGuideAdmin,
              state.entities.toJS()
            )
          };
        }).toArray().map(x => {
          return {
            owner_profile_first_name: x.owner && x.owner.profile ? x.owner.profile.first_name : '',
            owner_profile_last_name: x.owner && x.owner.profile ? x.owner.profile.last_name : '',
            care_guide_first_name: x.owner && x.owner.profile ? x.first_name : '',
            care_guide_last_name: x.owner && x.owner.profile ? x.last_name : '',
            owner_profile_email: x.owner && x.owner.profile ? x.owner.profile.email : '',
            ...x
          };
        })
      : [],
    isLoading: state.careGuidesAdmin.get('isLoading'),
    total: state.careGuidesAdmin.get('total'),
    activeId: storage.get('local', 'partnershipId'),
  }
}

const mapDispatchToProps = dispatch => {
  return {
    load: (parntershipId, page, limit, order, filter, cb) => {
      dispatch(loadCareGuidesAdmin(parntershipId, page, limit, order, filter, cb));
    },
    loadCareGuides: (page, limit, order, filter, partnershipId, cb) => {
      dispatch(loadCareGuides(page, limit, order, filter, partnershipId, cb));
    },    
    setActive: (id) => {
      dispatch(careGuideSetActive(id));
    },
    setActiveAccess: (data) => {
      dispatch(careGuideSetAccess(data));
    },
  }
}

const CareGuidesAdminListContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(CareGuidesAdminList);

export default CareGuidesAdminListContainer;
