import {connect} from 'react-redux';

import {
    getImageUploadURL,
    uploadImage,
} from '../../../../../state/modules/media/actions';
import {
  updatePartnershipAccountLogo,
  deletePartnershipAccountLogo
} from '../../../../../state/modules/partnershipAccount/actions';
import PartnershipLogoForm from '../components/PartnershipLogoForm';
import storage from "../../../../../lib/Storage";

const mapStateToProps = (state) => {
  
  return {
    activeId: storage.get('local', 'partnershipId'),
    isLoading: state.media.get('isImageUploadUrlLoading'),
    isUploading: state.media.get('isImageUploading'),
    isProcessing: state.partnershipAccount.get('isSubmittingLogo'),
    isSubmitting: state.media.get('isImageUploadUrlLoading')
      || state.media.get('isImageUploading')
      || state.partnershipAccount.get('isSubmittingLogo'),
    upload_url: state.media.getIn(['upload', 'upload_url']),
    file_id: state.media.getIn(['upload', 'file_id']),
    logo: state.partnershipAccount.getIn(['data', 'logo']),
    isDeleting: state.partnershipAccount.get('isDeletingLogo'),
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getUploadUrl: (extension, directory, cb) => {
      dispatch(getImageUploadURL(extension, directory, cb));
    },
    upload: (path, file, cb) => {
      dispatch(uploadImage(path, file, cb));
    },
    update: (partnership_id, data, cb) => {
      dispatch(updatePartnershipAccountLogo(partnership_id, data, cb));
    },
    delete: (partnership_id, cb) => {
      dispatch(deletePartnershipAccountLogo(partnership_id, cb));
    },
  }
}

const PartnershipLogoFormContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(PartnershipLogoForm);

export default PartnershipLogoFormContainer
