import React, {useEffect, useState} from 'react';
import {Translation} from 'react-i18next';
import {Form, Select} from 'antd';


const RelationshipInput = ({name, options, load, hasError=false, label=null, rules=null, isSubmitting=false, isLoading=false, disabled=false, autoFocus=false, useTooltip=true, tooltipPlacement='right', useSuccess=true, ...props}) => {

  const [isSelected, setIsSelected] = useState(false);

  // load countries on mount
  useEffect(() => {
    load();
  }, [load]);

  const onChange = e => {
    
    // check is selected
    setIsSelected(Boolean(e));
  }

  return (
    <Translation>{(t) => 
      <div className="form-group">
        <Form.Item
          name={name}
          label={label ? label : t('relationships_relationship')}
          rules={[
            {required: true, message: t('feedback_validation_required')},
          ]}
          // hasFeedback={useSuccess && isSelected && !hasError}
          hasFeedback={true}
          validateStatus={hasError ? "error" : (useSuccess && isSelected ? "success" : null)}
          // validateStatus={"error"}
        >
          <Select
            disabled={isLoading || disabled}
            autoFocus={autoFocus}
            loading={isLoading}
            onChange={onChange}
          >
            {options.map(x => <Select.Option key={x.value} value={x.value}>{x.label}</Select.Option>)}
          </Select>
        </Form.Item>
      </div>
    }</Translation>
  );
}

export default RelationshipInput;
