import React from 'react';
import { MailOutlined } from '@ant-design/icons';
import { emailIcon } from './Icons';

const EmailDisplay = ({ email, key = null, ...props }) => {
  if (email) {
    return (
      <div className="email-display" key={key}>
        <a href={'mailto:' + email}>
          <MailOutlined />
          {email}
        </a>
      </div>
    );
  }
  return null;
};

const EmailDisplayAdmin = ({ email, key = null, onClick, ...props }) => {
  if (email) {
    return (
      <div className="email-display" key={key} onClick={onClick}>
        <a
          href={'mailto:' + email}
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          {emailIcon()}
          <span
            style={{
              marginLeft: '8px',
              maxWidth: '100%',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            }}
          >
            {email}
          </span>
        </a>
      </div>
    );
  }
  return null;
};

const EmailDisplayCard = ({ email, key = null, onClick, ...props }) => {
  if (email) {
    return (
      <div className="email-display" key={key} onClick={onClick}>
        <div className="icon-text-container">
          <span className="icon-container">{emailIcon()}</span>
          <a
            target="_blank"
            href={'mailto:' + email}
            className="responsive-text"
            rel="noreferrer"
          >
            <span style={{ marginLeft: '8px' }}>{email}</span>
          </a>
        </div>
      </div>
    );
  }
  return null;
};

export { EmailDisplay, EmailDisplayAdmin, EmailDisplayCard };
