import React from 'react';
import Format from '../../../../lib/Format';
import { telephoneIcon } from './Icons';
import { PhoneOutlined } from '@ant-design/icons';
import { Space } from 'antd';

const TelephoneDisplay = ({ telephone, key = null, ...props }) => {
  const allDigitsTelephone = telephone ? telephone.replace(/\D/g, '') : null;
  if (allDigitsTelephone) {
    return (
      <div className="telephone-display" key={key}>
        <a href={'tel:' + allDigitsTelephone}>
          <PhoneOutlined />
          {Format.telephone(allDigitsTelephone)}
        </a>
      </div>
    );
  }
  return null;
};

const TelephoneDisplayAdmin = ({
  telephone,
  key = null,
  onClick,
  ...props
}) => {
  const allDigitsTelephone = telephone ? telephone.replace(/\D/g, '') : null;
  if (allDigitsTelephone) {
    return (
      <div className="telephone-display" key={key} onClick={onClick}>
        <a
          target="_blank"
          href={'tel:' + allDigitsTelephone}
          style={{ textDecoration: 'none' }}
          rel="noreferrer"
        >
          <Space style={{ display: 'flex', alignItems: 'center' }}>
            {telephoneIcon()}
            <span>{Format.telephone(allDigitsTelephone)}</span>
          </Space>
        </a>
      </div>
    );
  }
  return null;
};

const TelephoneDisplayCard = ({ telephone, key = null, onClick, ...props }) => {
  const allDigitsTelephone = telephone ? telephone.replace(/\D/g, '') : null;
  if (allDigitsTelephone) {
    return (
      <div className="telephone-display" key={key} onClick={onClick}>
        <a
          href={'tel:' + allDigitsTelephone}
          style={{ textDecoration: 'none' }}
        >
          <Space style={{ display: 'flex', alignItems: 'center' }}>
            {telephoneIcon()}
            <span>{Format.telephone(allDigitsTelephone)}</span>
          </Space>
        </a>
      </div>
    );
  }
  return null;
};

export { TelephoneDisplay, TelephoneDisplayAdmin, TelephoneDisplayCard };
