import { connect } from 'react-redux';

import { loadCareGuidesPartnership } from '../../../../../state/modules/careGuides/actions';
import CareGuidesResourcesPartnership from '../components/CareGuidesResourcesPartnership';

const mapStateToProps = (state, ownProps) => {
  return {
    total: state.careGuides.get('total'),
    isLoading: state.careGuides.get('areCareGuidesLoading'),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    load: (partnershipId, page, limit, order, filter, cb) => {
      dispatch(
        loadCareGuidesPartnership(partnershipId, page, limit, order, filter, cb)
      );
    },
  };
};

const CareGuidesResourcesPartnershipContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(CareGuidesResourcesPartnership);

export default CareGuidesResourcesPartnershipContainer;
