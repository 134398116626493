import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { Translation } from 'react-i18next';
import { Col, PageHeader, Row } from 'antd';

import Logger from '../../../../../lib/Logger';
import RegisterStep1Form from '../containers/RegisterStep1FormContainer';
import TermsOfServiceModal from '../containers/TermsOfServiceModalContainer';
import QueryString from '../../../../../lib/QueryString';
import DocumentHead from '../../../elements/components/DocumentHead';
import { pathTo } from '../../../Routes';
// import Config from '../../../../../Config';
import imageLogo from '../../../assets/images/logo-300x300.png';
import '../styles/Register.less';

class RegisterStep1Screen extends Component {
  render() {
    const params = QueryString.parse(this.props.location.search);

    if (this.props.isAuthenticated) {
      if ('invite_id' in params && !('invite_id' in params)) {
        return (
          <Redirect
            to={pathTo('CareGuideInvitationAccept', {
              invitationId: params.invite_id,
            })}
          />
        );
      }
    }

    return (
      <Translation>
        {(t) => (
          <div className="screen screen-public screen-register">
            <DocumentHead title={t('register_route_step1')} />

            <PageHeader
              onBack={() => this.props.history.push(pathTo('LoginScreen'))}
              title={t('register_screen_step1_page_title')}
              subTitle={t('register_screen_form1_header')}
            />

            <div className="logo">
              <img
                src={imageLogo}
                height="42"
                alt={t('app_name')}
                className=""
              />
            </div>

            <div className="container-form-register">
              <Row type="flex" justify="space-around" align="middle">
                <Col xs={22} sm={18} md={16} lg={14} xl={8}>
                  <div class="outer-container">
                    <div class="inner-container">
                      <h1 style={{ fontWeight: 'bold' }}>
                        {t('register_screen_step1_title')}
                      </h1>
                      <h2>{t('register_screen_step1_subtitle')}</h2>

                      <RegisterStep1Form
                        registrationCode={
                          'registration_code' in params
                            ? params.registration_code
                            : null
                        }
                        inviteCode={
                          'invite_code' in params ? params.invite_code : null
                        }
                        inviteId={
                          'invite_id' in params ? params.invite_id : null
                        }
                        planId={'plan' in params ? params.plan : null}
                        subscriptionCycle={
                          'cycle' in params ? params.cycle : null
                        }
                      />
                      <TermsOfServiceModal />
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        )}
      </Translation>
    );
  }

  /* LIFECYCLE EVENTS */

  componentDidMount() {
    Logger.log('silly', `RegisterStep1Screen.componentDidMount()`);
    if (typeof window !== 'undefined') {
      window.scrollTo(0, 0);
    }
  }

  componentDidUpdate() {
    Logger.log('silly', `RegisterStep1Screen.componentDidUpdate()`);
  }

  componentWillUnmount() {
    Logger.log('silly', `RegisterStep1Screen.componentWillUnmount()`);
  }
}

export default RegisterStep1Screen;

Logger.log('silly', `RegisterStep1Screen loaded.`);
