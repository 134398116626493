import {normalize} from 'normalizr';

import api from '../../api';
import {schema} from '../../schema';
import {addEntities, removeEntity} from '../../actions';
import Logger from '../../../lib/Logger';

// ACTION TYPES

export const CARE_GUIDE_PARTNER_TAG_ADMIN_LIST_REQUEST = 'CARE_GUIDE_PARTNER_TAG_ADMIN_LIST_REQUEST';
export const CARE_GUIDE_PARTNER_TAG_ADMIN_LIST_SUCCESS = 'CARE_GUIDE_PARTNER_TAG_ADMIN_LIST_SUCCESS';
export const CARE_GUIDE_PARTNER_TAG_ADMIN_LIST_FAILURE = 'CARE_GUIDE_PARTNER_TAG_ADMIN_LIST_FAILURE';
export const CARE_GUIDE_PARTNER_TAG_ADMIN_READ_REQUEST = 'CARE_GUIDE_PARTNER_TAG_ADMIN_READ_REQUEST';
export const CARE_GUIDE_PARTNER_TAG_ADMIN_READ_SUCCESS = 'CARE_GUIDE_PARTNER_TAG_ADMIN_READ_SUCCESS';
export const CARE_GUIDE_PARTNER_TAG_ADMIN_READ_FAILURE = 'CARE_GUIDE_PARTNER_TAG_ADMIN_READ_FAILURE';
export const CARE_GUIDE_PARTNER_TAG_ADMIN_UPDATE_REQUEST = 'CARE_GUIDE_PARTNER_TAG_ADMIN_UPDATE_REQUEST';
export const CARE_GUIDE_PARTNER_TAG_ADMIN_UPDATE_SUCCESS = 'CARE_GUIDE_PARTNER_TAG_ADMIN_UPDATE_SUCCESS';
export const CARE_GUIDE_PARTNER_TAG_ADMIN_UPDATE_FAILURE = 'CARE_GUIDE_PARTNER_TAG_ADMIN_UPDATE_FAILURE';
export const CARE_GUIDE_PARTNER_TAG_ADMIN_CREATE_REQUEST = 'CARE_GUIDE_PARTNER_TAG_ADMIN_CREATE_REQUEST';
export const CARE_GUIDE_PARTNER_TAG_ADMIN_CREATE_SUCCESS = 'CARE_GUIDE_PARTNER_TAG_ADMIN_CREATE_SUCCESS';
export const CARE_GUIDE_PARTNER_TAG_ADMIN_CREATE_FAILURE = 'CARE_GUIDE_PARTNER_TAG_ADMIN_CREATE_FAILURE';
export const CARE_GUIDE_PARTNER_TAG_ADMIN_DELETE_REQUEST = 'CARE_GUIDE_PARTNER_TAG_ADMIN_DELETE_REQUEST';
export const CARE_GUIDE_PARTNER_TAG_ADMIN_DELETE_SUCCESS = 'CARE_GUIDE_PARTNER_TAG_ADMIN_DELETE_SUCCESS';
export const CARE_GUIDE_PARTNER_TAG_ADMIN_DELETE_FAILURE = 'CARE_GUIDE_PARTNER_TAG_ADMIN_DELETE_FAILURE';
export const CARE_GUIDE_PARTNER_TAG_PARTNERSHIP_LIST_REQUEST = 'CARE_GUIDE_PARTNER_TAG_PARTNERSHIP_LIST_REQUEST';
export const CARE_GUIDE_PARTNER_TAG_PARTNERSHIP_LIST_SUCCESS = 'CARE_GUIDE_PARTNER_TAG_PARTNERSHIP_LIST_SUCCESS';
export const CARE_GUIDE_PARTNER_TAG_PARTNERSHIP_LIST_FAILURE = 'CARE_GUIDE_PARTNER_TAG_PARTNERSHIP_LIST_FAILURE';
export const CARE_GUIDE_PARTNER_TAG_ADMIN_FORM_DESTROY = 'CARE_GUIDE_PARTNER_TAG_ADMIN_FORM_DESTROY';

// ACTION CREATORS

export function careGuidePartnerTagAdminListRequest(page, limit, order, filter) {
  Logger.log('debug', `[careGuidePartnerTagsAdmin.actions] careGuidePartnerTagAdminListRequest(${page}, ${limit}, ${order}, %j)`, filter);
  return {
    type: CARE_GUIDE_PARTNER_TAG_ADMIN_LIST_REQUEST,
    page: page,
    limit: limit,
    order: order,
    filter: filter
  }
}

export function careGuidePartnerTagAdminListSuccess(data) {
  Logger.log('debug', `[careGuidePartnerTagsAdmin.actions] careGuidePartnerTagAdminListSuccess(%j)`, data);
  return {
    type: CARE_GUIDE_PARTNER_TAG_ADMIN_LIST_SUCCESS,
    page: data.page,
    limit: data.limit,
    order: data.order,
    result: data.result,
    total: data.total,
    receivedAt: Date.now()
  }
}

export function careGuidePartnerTagAdminListFailure(error) {
  Logger.log('debug', `[careGuidePartnerTagsAdmin.actions] careGuidePartnerTagAdminListFailure(%j)`, error);
  return {
    type: CARE_GUIDE_PARTNER_TAG_ADMIN_LIST_FAILURE,
    error: error
  }
}


export function careGuidePartnerTagReadAdminRequest(id) {
  Logger.log('debug', `[careGuidePartnerTagsAdmin.actions] careGuidePartnerTagReadAdminRequest(${id})`);
  return {
    type: CARE_GUIDE_PARTNER_TAG_ADMIN_READ_REQUEST,
    id: id
  }
}

export function careGuidePartnerTagReadAdminSuccess(data) {
  Logger.log('debug', `[careGuidePartnerTagsAdmin.actions] careGuidePartnerTagReadAdminSuccess(%j)`, data);
  return {
    type: CARE_GUIDE_PARTNER_TAG_ADMIN_READ_SUCCESS,
    id: data.id,
    receivedAt: Date.now()
  }
}

export function careGuidePartnerTagReadAdminFailure(error) {
  Logger.log('debug', `[careGuidePartnerTagsAdmin.actions] careGuidePartnerTagReadAdminFailure(%j)`, error);
  return {
    type: CARE_GUIDE_PARTNER_TAG_ADMIN_READ_FAILURE,
    error: error
  }
}

export function careGuidePartnerTagAdminUpdateRequest(id, data) {
  Logger.log('debug', `[careGuidePartnerTagsAdmin.actions] careGuidePartnerTagAdminUpdateRequest(${id}, %j)`, data);
  return {
    type: CARE_GUIDE_PARTNER_TAG_ADMIN_UPDATE_REQUEST,
  }
}

export function careGuidePartnerTagAdminUpdateSuccess(data) {
  Logger.log('debug', `[careGuidePartnerTagsAdmin.actions] careGuidePartnerTagAdminUpdateSuccess(%j)`, data);
  return {
    type: CARE_GUIDE_PARTNER_TAG_ADMIN_UPDATE_SUCCESS,
    id: data.id,
    receivedAt: Date.now()
  }
}

export function careGuidePartnerTagAdminUpdateFailure(error) {
  Logger.log('debug', `[careGuidePartnerTagsAdmin.actions] careGuidePartnerTagAdminUpdateFailure(%j)`, error);
  return {
    type: CARE_GUIDE_PARTNER_TAG_ADMIN_UPDATE_FAILURE,
    error: error
  }
}

export function careGuidePartnerTagAdminCreateRequest(data) {
  Logger.log('debug', `[careGuidePartnerTagsAdmin.actions] careGuidePartnerTagAdminCreateRequest(%j)`, data);
  return {
    type: CARE_GUIDE_PARTNER_TAG_ADMIN_CREATE_REQUEST,
  }
}

export function careGuidePartnerTagAdminCreateSuccess(data) {
  Logger.log('debug', `[careGuidePartnerTagsAdmin.actions] careGuidePartnerTagAdminCreateSuccess(%j)`, data);
  return {
    type: CARE_GUIDE_PARTNER_TAG_ADMIN_CREATE_SUCCESS,
    id: data.id,
    receivedAt: Date.now()
  }
}

export function careGuidePartnerTagAdminCreateFailure(error) {
  Logger.log('debug', `[careGuidePartnerTagsAdmin.actions] careGuidePartnerTagAdminCreateFailure(%j)`, error);
  return {
    type: CARE_GUIDE_PARTNER_TAG_ADMIN_CREATE_FAILURE,
    error: error
  }
}

export function careGuidePartnerTagAdminDeleteRequest(id) {
  Logger.log('debug', `[careGuidePartnerTagsAdmin.actions] careGuidePartnerTagAdminDeleteRequest(${id})`);
  return {
    type: CARE_GUIDE_PARTNER_TAG_ADMIN_DELETE_REQUEST,
    id: id
  }
}

export function careGuidePartnerTagAdminDeleteSuccess(id) {
  Logger.log('debug', `[careGuidePartnerTagsAdmin.actions] careGuidePartnerTagAdminDeleteSuccess(${id})`);
  return {
    type: CARE_GUIDE_PARTNER_TAG_ADMIN_DELETE_SUCCESS,
    id: id,
  }
}

export function careGuidePartnerTagAdminDeleteFailure(error) {
  Logger.log('debug', `[careGuidePartnerTagsAdmin.actions] careGuidePartnerTagAdminDeleteFailure(%j)`, error);
  return {
    type: CARE_GUIDE_PARTNER_TAG_ADMIN_DELETE_FAILURE,
    error: error
  }
}

export function careGuidePartnerTagAdminFormDestroy(formState=null) {
  Logger.log('debug', `[careGuidePartnerTagsAdmin.actions] careGuidePartnerTagAdminFormDestroy(%j)`, formState);
  return {
    type: CARE_GUIDE_PARTNER_TAG_ADMIN_FORM_DESTROY,
    form: formState
  }
}

export function careGuidePartnerTagPartnershipListRequest(partnershipId) {
  Logger.log('debug', `[careGuidePartnerTagsAdmin.actions] careGuidePartnerTagPartnershipListRequest(${partnershipId})`);
  return {
    type: CARE_GUIDE_PARTNER_TAG_PARTNERSHIP_LIST_REQUEST,
    partnershipId: partnershipId,
  }
}

export function careGuidePartnerTagPartnershipListSuccess(partnershipId, data) {
  Logger.log('debug', `[careGuidePartnerTagsAdmin.actions] careGuidePartnerTagPartnershipListSuccess(${partnershipId}, %j)`, data);
  return {
    type: CARE_GUIDE_PARTNER_TAG_PARTNERSHIP_LIST_SUCCESS,
    partnershipId: partnershipId,
    result: data.result,
    total: data.total,
    receivedAt: Date.now()
  }
}

export function careGuidePartnerTagPartnershipListFailure(partnershipId, error) {
  Logger.log('debug', `[careGuidePartnerTagsAdmin.actions] careGuidePartnerTagPartnershipListFailure(${partnershipId}, %j)`, error);
  return {
    type: CARE_GUIDE_PARTNER_TAG_PARTNERSHIP_LIST_FAILURE,
    partnershipId: partnershipId,
    error: error
  }
}


// API THUNK ACTION CREATORS

export function loadCareGuidePartnerTagsAdmin(parntership_id = null, page=1, limit=10, order=null, filter=null, cb=function(){}) {
  Logger.log('debug', `[careGuidePartnerTagsAdmin.actions] loadCareGuidePartnerTagsAdmin(${page}, ${limit}, ${order}, %j, ###)`, filter);

  return async function(dispatch) {
    dispatch(careGuidePartnerTagAdminListRequest(page, limit, order, filter));

    // call API
    const response = await api.getCareGuidePartnerTagsAdmin(parntership_id, page, limit, order, filter);
    let success = false;

    // get care guide partner tags admin list success
    if (200 === response.get('status')) {

      Logger.log('info', `Get API care guide partner tags admin list success. Page: ${page}, Limit: ${limit}, Order: ${order}.`);

      const normalizedEntities = normalize(response.getIn(['data', 'care_guide_partner_tags']), [schema.careGuidePartnerTagAdmin]);
      const data = {
        page: response.getIn(['data', 'page']),
        limit: response.getIn(['data', 'limit']),
        order: order,
        total: response.getIn(['data', 'total']),
        result: normalizedEntities.result
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(careGuidePartnerTagAdminListSuccess(data));
      success = true;

    } else if (1 === page && 204 === response.get('status')) {

      Logger.log('info', `Get API care guide partner tags admin success [empty]. Page: ${page}, Limit: ${limit}, Order: ${order}.`);
      const data = {
        page: page,
        limit: limit,
        order: order,
        total: 0,
        result: []
      };
      dispatch(careGuidePartnerTagAdminListSuccess(data));
      success = true;

    // get care guide partner tags admin list failure
    } else {
      Logger.log('info', `Get API care guide partner tags admin list failure. Page: ${page}, Limit: ${limit}, Order: ${order}.`);
      dispatch(careGuidePartnerTagAdminListFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  }
}

export function loadCareGuidePartnerTagAdmin(partnership_id, id, cb=function(){}) {
  Logger.log('debug', `[careGuidePartnerTagsAdmin.actions] loadCareGuidePartnerTagAdmin(${id}, ###)`);

  return async function(dispatch) {
    dispatch(careGuidePartnerTagReadAdminRequest(id));

    // call API
    const response = await api.getCareGuidePartnerTagAdmin(partnership_id, id);
    let success = false;

    // get care guide partner tag admin success
    if (200 === response.get('status')) {

      Logger.log('info', `Get API care guide partner tag admin success. ID: ${id}.`);

      const normalizedEntities = normalize([response.getIn(['data', 'care_guide_partner_tag'])], [schema.careGuidePartnerTagAdmin]);
      const data = {
        id: response.getIn(['data', 'care_guide_partner_tag', 'id']),
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(careGuidePartnerTagReadAdminSuccess(data));
      success = true;

    // get care guide partner tag admin failure
    } else {
      Logger.log('info', `Get API care guide partner tag admin failure. ID: ${id}.`);
      dispatch(careGuidePartnerTagReadAdminFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  }
}

export function updateCareGuidePartnerTagAdmin(id, data, cb=function(){}) {
  Logger.log('debug', `[careGuidePartnerTagsAdmin.actions] updateCareGuidePartnerTagAdmin(${id}, %j, ###)`, data);

  return async function(dispatch) {
    dispatch(careGuidePartnerTagAdminUpdateRequest(id, data));

    // call API
    const response = await api.putCareGuidePartnerTagAdmin(id, data);
    let success = false;

    // put care guide partner tag admin success
    if (200 === response.get('status')) {

      Logger.log('info', `PUT API care guide partner tag admin success. User: ${id}.`);

      const normalizedEntities = normalize([response.getIn(['data', 'care_guide_partner_tag'])], [schema.careGuidePartnerTagAdmin]);
      const data = {
        id: response.getIn(['data', 'care_guide_partner_tag', 'id']),
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(careGuidePartnerTagAdminUpdateSuccess(data));
      success = true;

    // get care guide partner tag admin failure
    } else {
      Logger.log('info', `PUT API care guide partner tag admin failure. ID: ${id}.`);
      dispatch(careGuidePartnerTagAdminUpdateFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  }
}

export function createCareGuidePartnerTagAdmin(data, cb=function(){}) {
  Logger.log('debug', `[careGuidePartnerTagsAdmin.actions] createCareGuidePartnerTagAdmin(%j, ###)`, data);

  return async function(dispatch) {
    dispatch(careGuidePartnerTagAdminCreateRequest(data));

    // call API
    const response = await api.postCareGuidePartnerTagsAdmin(data);
    let success = false;

    // post care guide partner tag admin success
    if (201 === response.get('status')) {

      Logger.log('info', `POST API care guide partner tags admin success. Post: ${response.getIn(['data', 'care_guide_partner_tag', 'id'])}.`);

      const normalizedEntities = normalize([response.getIn(['data', 'care_guide_partner_tag'])], [schema.careGuidePartnerTagAdmin]);
      const data = {
        id: response.getIn(['data', 'care_guide_partner_tag', 'id']),
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(careGuidePartnerTagAdminCreateSuccess(data));
      success = true;

    // get care guide partner tags admin failure
    } else {
      Logger.log('info', `POST API care guide partner tags admin failure.`);
      dispatch(careGuidePartnerTagAdminCreateFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  }
}

export function deleteCareGuidePartnerTagAdmin(partnership_id, id, cb=function(){}) {
  Logger.log('debug', `[careGuidePartnerTagsAdmin.actions] deleteCareGuidePartnerTagAdmin(${id}, ###)`);

  return async function(dispatch) {
    dispatch(careGuidePartnerTagAdminDeleteRequest(id));

    // call API
    const response = await api.deleteCareGuidePartnerTagAdmin(partnership_id, id);
    let success = false;

    // delete care guide partner tag admin success
    if (204 === response.get('status')) {

      Logger.log('info', `DELETE API care guide partner tag admin success. ID: ${id}.`);

      dispatch(removeEntity({entityType: 'careGuidePartnerTagsAdmin', id: id}));
      dispatch(careGuidePartnerTagAdminDeleteSuccess(id));
      success = true;

    // get care guide partner tag admin failure
    } else {
      Logger.log('info', `DELETE API care guide partner tag admin failure. ID: ${id}.`);
      dispatch(careGuidePartnerTagAdminDeleteFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  }
}

export function loadCareGuidePartnerTagsPartnershipAdmin(partnershipId=null, cb=function(){}) {
  Logger.log('debug', `[careGuidePartnerTagsAdmin.actions] loadCareGuidePartnerTagsPartnership(${partnershipId}`);

  return async function(dispatch) {
    dispatch(careGuidePartnerTagPartnershipListRequest(partnershipId));

    // call API
    const response = await api.getCareGuidePartnerTagPartnershipAdmin(partnershipId);
    let success = false;

    // get care guide partner tags partnership list success
    if (200 === response.get('status')) {

      Logger.log('info', `Get API care guide partner tags partnership list success. ID: ${partnershipId}`);

      const normalizedEntities = normalize(response.getIn(['data', 'care_guide_partner_tags_partnership']), [schema.careGuidePartnerTagsAdminPartnership]);

      const data = {
        total: response.getIn(['data', 'total']),
        result: normalizedEntities.result
      };
      dispatch(addEntities(normalizedEntities));
      dispatch(careGuidePartnerTagPartnershipListSuccess(partnershipId, data));
      success = true;

    } else if (204 === response.get('status')) {

      Logger.log('info', `Get API care guide partner tags partnership success [empty]. ID: ${partnershipId}.`);
      const data = {
        total: 0,
        result: []
      };
      dispatch(careGuidePartnerTagPartnershipListSuccess(partnershipId, data));
      success = true;
      
    // get care guide partner tags partnership list failure
  } else {
      Logger.log('info', `Get API care guide partner tags partnership list failure. ID: ${partnershipId}.`);
      dispatch(careGuidePartnerTagPartnershipListFailure(partnershipId, response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  }
}

export function loadCareGuidePartnerTagsPartnershipHome(partnershipId=null, cb=function(){}) {
  Logger.log('debug', `[careGuidePartnerTagsAdmin.actions] loadCareGuidePartnerTagsPartnership(${partnershipId}`);

  return async function(dispatch) {
    dispatch(careGuidePartnerTagPartnershipListRequest(partnershipId));

    // call API
    const response = await api.getCareGuidePartnerTagPartnershipHome(partnershipId);
    let success = false;

    // get care guide partner tags partnership list success
    if (200 === response.get('status')) {

      Logger.log('info', `Get API care guide partner tags partnership list success. ID: ${partnershipId}`);

      const normalizedEntities = normalize(response.getIn(['data', 'care_guide_partner_tags_partnership']), [schema.careGuidePartnerTagsAdminPartnership]);

      const data = {
        total: response.getIn(['data', 'total']),
        result: normalizedEntities.result
      };
      dispatch(addEntities(normalizedEntities));
      dispatch(careGuidePartnerTagPartnershipListSuccess(partnershipId, data));
      success = true;

    } else if (204 === response.get('status')) {

      Logger.log('info', `Get API care guide partner tags partnership success [empty]. ID: ${partnershipId}.`);
      const data = {
        total: 0,
        result: []
      };
      dispatch(careGuidePartnerTagPartnershipListSuccess(partnershipId, data));
      success = true;
      
    // get care guide partner tags partnership list failure
  } else {
      Logger.log('info', `Get API care guide partner tags partnership list failure. ID: ${partnershipId}.`);
      dispatch(careGuidePartnerTagPartnershipListFailure(partnershipId, response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  }
}

Logger.log('silly', `careGuidePartnerTagsAdmin.actions loaded.`);
