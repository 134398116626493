import {connect} from 'react-redux';
import {denormalize} from 'normalizr';
import {withRouter} from "react-router";

import DraftsList from '../components/DraftsList';
import {schema} from '../../../../../state/schema';
import {
  loadMessagesDrafts,
  messageShowComposeForm,
} from '../../../../../state/modules/messages/actions';


const mapStateToProps = (state, ownProps) => {

  const page = ownProps.page;
  const limit = ownProps.limit;
  const order = ownProps.order;
  const result = state.messages.getIn(['drafts', 'pages', order, limit, page]);

  return {
    list: result 
      ? result.map(x => {
          return {
            key: x,
            ...denormalize(
              state.entities.getIn(['messages', x]),
              schema.message,
              state.entities.toJS()
            )
          };
        }).toArray()
      : [],
    total: state.messages.getIn(['drafts', 'total']),
    isLoading: state.messages.get('isLoading'),
  }
}

const mapDispatchToProps = dispatch => {
  return {
    load: (page, limit, order, filter, cb) => {
      dispatch(loadMessagesDrafts(page, limit, order, filter, cb));
    },
    showForm: (threadId, messageId) => {
      dispatch(messageShowComposeForm(threadId, messageId));
    },
  }
}

const DraftsListContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(DraftsList);

export default withRouter(DraftsListContainer);
