import {connect} from 'react-redux';
import {denormalize} from 'normalizr';

import {schema} from '../../../../../state/schema';
import {
  loadCareGuides,
  careGuideSetActive,
  careGuidePageSetActive,
  careGuideSetAccess,
} from "../../../../../state/modules/careGuides/actions";
import CareGuideChooserHomeList from '../components/CareGuideChooserHomeList';
import { userProfilesPartnershipSetActive } from "../../../../../state/modules/userProfilesPartnerships/actions";
import storage from "../../../../../lib/Storage";

const mapStateToProps = (state, ownProps) => {

  const page = ownProps.page ? ownProps.page : 1;
  const limit = 10;
  const order = "started_at.asc";
  const result = state.careGuides.getIn(["pages", order, limit, page]);
  const profileId = state.session.get("profileId");

  const pageUserProfilePartnership = ownProps.pageUserProfilePartnership ? ownProps.pageUserProfilePartnership : 1;
  const orderUserProfilePartnership = "updated_at.asc";
  const resultPartnerships = state.userProfilesPartnerships.getIn([
    "pages",
    orderUserProfilePartnership,
    limit,
    pageUserProfilePartnership,
  ]);

  return {
    list: result
      ? result
          .map((x) => {
            return {
              key: x,
              ...denormalize(
                state.entities.getIn(["careGuides", x]),
                schema.careGuide,
                state.entities.toJS()
              ),
            };
          })
          .toArray()
          .filter(
            (x) =>
              x.account &&
              (x.account.is_subscription_valid ||
                x.account.owner_id === profileId)
          ).filter((x) => {
            if (x.partnership) {
              return x.partnership.status === 1 && x;
            } else {
              return x;
            }
          }).map(x => {
            // flatten some nested objects to make table easier to work with
            return {
              owner_profile_first_name: x.owner && x.owner.profile ? x.owner.profile.first_name : '',
              owner_profile_last_name: x.owner && x.owner.profile ? x.owner.profile.last_name : '',
              care_guide_first_name: x.owner && x.owner.profile ? x.first_name : '',
              care_guide_last_name: x.owner && x.owner.profile ? x.last_name : '',
              owner_profile_email: x.owner && x.owner.profile ? x.owner.profile.email : '',
              ...x
            };
          })
      : [],
      listPartnerships: resultPartnerships
      ? resultPartnerships
          .map((x) => {
            return {
              key: x,
              ...denormalize(
                state.entities.getIn(["userProfilesPartnerships", x]),
                schema.userProfilePartnership,
                state.entities.toJS()
              ),
            };
          })
          .toArray()
      : [],
      total: state.careGuides.get("total"),
      isLoading: state.careGuides.get("isLoading"),
      profileId: profileId,
      partnershipsCount: state.userProfilesPartnerships.get("total"),
      partnershipActiveId: storage.get('local', 'partnershipId')
  }
}

const mapDispatchToProps = dispatch => {
  return {
    load: (page, limit, order, filter, partnershipId, cb) => {
      dispatch(loadCareGuides(page, limit, order, filter, partnershipId, cb));
    },
    setActive: (id) => {
      dispatch(careGuideSetActive(id));
    },
    setActiveAccess: (data) => {
      dispatch(careGuideSetAccess(data));
    },
    setActivePage: (page) =>{
      dispatch(careGuidePageSetActive(page));
    },
    setActiveUserProfilePartnership: (id) => {
      dispatch(userProfilesPartnershipSetActive(id));
    },
  }
}

const CareGuideChooserHomeListContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(CareGuideChooserHomeList);

export default CareGuideChooserHomeListContainer;