import React, { useEffect } from 'react';
import { Translation } from 'react-i18next';
import { Form, Select, Tag } from 'antd';

const ServiceTagInput = ({
  name,
  label,
  options,
  load,
  onChange = null,
  rules = null,
  placeholder = null,
  style = null,
  wrapperCol = null,
  ...props
}) => {
  // load tags on mount
  useEffect(() => {
    load(1, 500);
  }, [load]);

  const tagColorMap = new Map(
    options.map((item) => [item.value, item.color_hex])
  );

  const tagRender = (props) => {
    const { value, label, closable, onClose } = props;

    const tagColor = value ? tagColorMap.get(value) : 'blue';

    const onPreventMouseDown = (event) => {
      event.preventDefault();
      event.stopPropagation();
    };

    return (
      <Tag
        color={tagColor}
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
        style={{
          marginRight: 3,
        }}
      >
        {label}
      </Tag>
    );
  };

  return (
    <Translation>
      {(t) => (
        <div className="form-group">
          <Form.Item
            name={name}
            label={label}
            rules={rules}
            style={style}
            wrapperCol={wrapperCol}
          >
            <Select
              showSearch
              showArrow={true}
              mode="multiple"
              placeholder={placeholder}
              disabled={props.isLoading || props.isSubmitting}
              loading={props.isLoading}
              onChange={onChange}
              tagRender={tagRender}
            >
              {options.map((x) => (
                <Select.Option key={x.value} value={x.value}>
                  {x.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </div>
      )}
    </Translation>
  );
};

export default ServiceTagInput;
