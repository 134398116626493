import React from 'react';
import {Translation} from 'react-i18next';
import {Card, List, Space, Spin, Tag, Typography} from 'antd';
import {LoadingOutlined} from '@ant-design/icons';
import moment from 'moment';

import SubscriptionCancelForm from '../containers/SubscriptionCancelFormContainer';
import Format from '../../../../../lib/Format';
import Logger from '../../../../../lib/Logger';


const SubscriptionSettings = ({subscription, isLoading, ...props}) => {

  const {Text} = Typography;

  const loadingIcon = <Spin style={{height: 18}} indicator={<LoadingOutlined style={{ fontSize: 18 }} spin />} />;
  const trialDays = subscription && subscription.is_trial
    ? moment(subscription.trial_end_at).diff(moment(), 'days')
    : null;

  const statusColor = status => {
    switch (status) {
      case 'none':
        return 'default';
      case 'active':
        return 'success';
      case 'pending':
        return 'processing';
      default:
        return 'error';
    }
  }

  return (
    <Translation>{(t=>
      <>
        <Card
          title={t('payments_subscription_settings_title')}
          size="default"
          extra={isLoading ? loadingIcon : null}
        >

          {subscription
            ? <>
                <List size="small" className='subscription-settings'>

                  <List.Item>
                    <List.Item.Meta
                      title={t('payments_subscription_subscription_status')}
                    />
                      {subscription.cancel_scheduled
                        ? <Tag color="warning">{t('payments_subscription_status_canceled_at_end_of_period')}</Tag>
                        : (subscription.status === 'active' && subscription.is_trial
                          ? <Space size={5}>
                              <Tag color="success">{t('payments_subscription_status_trial')}</Tag>
                              <Text type="secondary">{t('payments_subscription_days_remaining', {count: trialDays})}</Text>
                            </Space>
                          : <Tag
                              color={statusColor(subscription.status)}
                              style={{marginRight: 0}}
                            >
                              {t('payments_subscription_status_' + subscription.status)}
                            </Tag>)}
                  </List.Item>

                  <List.Item>
                    <List.Item.Meta
                      title={t('payments_subscription_card_type')}
                    />
                    <Text>{subscription.card_type ? subscription.card_type : '-'}</Text>
                  </List.Item>

                  <List.Item>
                    <List.Item.Meta
                      title={t('payments_subscription_card_ending')}
                    />
                    <Text>{subscription.card_last4 ? '********' + subscription.card_last4 : '-'}</Text>
                  </List.Item>

                  <List.Item>
                    <List.Item.Meta
                      title={t('payments_subscription_next_invoice')}
                    />
                    <Text>{subscription.current_period_end_at && !subscription.cancel_scheduled ? Format.date(subscription.current_period_end_at, 'MMM Do, YYYY') : '-'}</Text>
                  </List.Item>

                  {subscription.cancel_scheduled && subscription.cancel_at
                    ? <List.Item>
                      <List.Item.Meta
                        title={t('payments_subscription_cancel_at')}
                      />
                      <Text>{Format.date(subscription.cancel_at, 'MMM Do, YYYY')}</Text>
                    </List.Item>
                    : null}

                </List>

                {subscription.status === 'active' && !subscription.cancel_scheduled
                  ? <div style={{textAlign: 'center'}}>
                      <SubscriptionCancelForm />
                    </div>
                  : null}

              </>
            : null}

        </Card>
      </>
    )}</Translation>
  );
};

export default SubscriptionSettings;

Logger.log('silly', `SubscriptionSettings loaded.`);
