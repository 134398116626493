import {connect} from 'react-redux';
import CareGuidePartnerTagsAdminScreen from '../components/CareGuidePartnerTagsAdminScreen';
import storage from "../../../../../lib/Storage";

const mapStateToProps = state => {
  return {
    total: state.careGuidePartnerTagsAdmin.get('total'),
    isLoading: state.careGuidePartnerTagsAdmin.get('isLoading'),
    activeId: storage.get('local', 'partnershipId'),
    access_role_admin_in_app: storage.get('local', 'partnershipData')?.access_role_admin_in_app,

  }
}

const CareGuidePartnerTagsAdminScreenContainer = connect(
  mapStateToProps
)(CareGuidePartnerTagsAdminScreen);

export default CareGuidePartnerTagsAdminScreenContainer;