import React, {useState, useEffect} from 'react';
import {Translation, getI18n} from 'react-i18next';
import {Button, Card, List, Popconfirm, Space} from 'antd';
import {CheckCircleOutlined, CloseCircleOutlined} from '@ant-design/icons';

import message from '../../../elements/lib/MessageWrapper';
import Format from '../../../../../lib/Format';
import Config from '../../../../../Config';
import Logger from '../../../../../lib/Logger';


const InvitationItem = ({item, respond, load, loadCareGuides, page, limit, order, ...props}) => {

  const [isAccepting, setIsAccepting] = useState(false);
  const [isRejecting, setIsRejecting] = useState(false);

  const onClickAcceptHandler = (id) => {
    setIsAccepting(true);
    respond(id, {public_status: 1}, (success) => {
      if (success) {
        message.success(getI18n().t('care_guide_invitations_feedback_accept_success'));
        load(page, limit, order);
        loadCareGuides(1, 10, 'started_at.asc');
      } else {
        message.error(getI18n().t('care_guide_invitations_feedback_accept_error'));
        setIsAccepting(false);
      }
    });
  }

  const onClickRejectHandler = (id) => {
    setIsRejecting(true);
    respond(id, {public_status: 2}, (success) => {
      if (success) {
        message.success(getI18n().t('care_guide_invitations_feedback_reject_success'));
        load(page, limit, order);
      } else {
        message.error(getI18n().t('care_guide_invitations_feedback_reject_error'));
        setIsRejecting(false);
      }
    });
  }

  return (
    <Translation>{(t) => 
      <List.Item key={item.id}>

        <List.Item.Meta
          title={t('care_guide_invitations_name', {name: item.care_guide.first_name + ' ' + item.care_guide.last_name})}
          description={t('care_guide_invitations_expires', {date: Format.date(item.expires_at, Config.get('DEFAULT_DATE_FORMAT'))})}
        />

        <Space>

          <Button
            key="accept"
            icon={<CheckCircleOutlined />}
            loading={isAccepting}
            disabled={isAccepting || isRejecting}
            onClick={() => onClickAcceptHandler(item.id)}
          >
            {t('care_guide_invitations_btn_accecpt')}
          </Button>

          <Popconfirm
            key="reject-confirm"
            placement="topRight"
            title={t('care_guide_invitations_reject_confirm_body')}
            onConfirm={() => onClickRejectHandler(item.id)}
            okText={t('confirm_yes')}
            cancelText={t('confirm_cancel')}
            disabled={isAccepting || isRejecting}
          >
            <Button
              danger
              icon={<CloseCircleOutlined />}
              loading={isRejecting}
              disabled={isAccepting || isRejecting}
            >{t('care_guide_invitations_btn_reject')}</Button>
          </Popconfirm>
        </Space>

      </List.Item>
    }</Translation>
  )
}

const InvitationsList = ({component, page, limit, order, total, load, respond, loadCareGuides, ...props}) => {

  useEffect(() => {
    load(page, limit, order);
  }, [page, limit, order, load]);

  return (
    <Translation>{(t) => 
      <>
        {total > 0
          ? <Card title={t('dashboard_invitations_list_title')}>
              <List
                className="invitations-list"
                itemLayout="horizontal"
                dataSource={props.list}
                renderItem={item => 
                  <InvitationItem
                    item={item}
                    respond={respond}
                    load={load}
                    loadCareGuides={loadCareGuides}
                    page={page}
                    limit={limit}
                    order={order}
                  />}
              />
            </Card>
          : null}
      </>
    }</Translation>
  )  
}

export default InvitationsList;

Logger.log('silly', `InvitationsList loaded.`);
