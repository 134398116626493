import {connect} from 'react-redux';

import {
    getImageUploadURL,
    uploadImage,
} from '../../../../../state/modules/media/actions';
import {
  updateCareGuideImage,
  deleteCareGuideImage
} from '../../../../../state/modules/careGuides/actions';
import {sendMessage} from '../../../../../state/actions';
import CareGuideImageForm from '../components/CareGuideImageForm';


const mapStateToProps = (state, ownProps) => {
  
  const careGuideId = ownProps.careGuideId;

  return {
    isLoading: state.media.get('isImageUploadUrlLoading'),
    isUploading: state.media.get('isImageUploading'),
    isProcessing: state.careGuides.get('isSubmittingImage'),
    isDeleting: state.careGuides.get('isDeletingImage'),
    isSubmitting: state.media.get('isImageUploadUrlLoading')
      || state.media.get('isImageUploading')
      || state.careGuides.get('isSubmittingImage'),
    upload_url: state.media.getIn(['upload', 'upload_url']),
    file_id: state.media.getIn(['upload', 'file_id']),
    image: state.entities.getIn(['careGuides', careGuideId, 'image']),
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getUploadUrl: (extension, directory, cb) => {
      dispatch(getImageUploadURL(extension, directory, cb));
    },
    upload: (path, file, cb) => {
      dispatch(uploadImage(path, file, cb));
    },
    update: (careGuideId, data, cb) => {
      dispatch(updateCareGuideImage(careGuideId, data, cb));
    },
    delete: (careGuideId, cb) => {
      dispatch(deleteCareGuideImage(careGuideId, cb));
    },
    sendMessage: (level, title, body, expires) => {
      dispatch(sendMessage(level, title, body, expires));
    }
  }
}

const CareGuideImageFormContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(CareGuideImageForm);

export default CareGuideImageFormContainer
