import {connect} from 'react-redux';

import {
    getImageUploadURL,
    uploadImage,
} from '../../../../../state/modules/media/actions';
import {
  updateUserAccountAvatar,
  deleteUserAccountAvatar
} from '../../../../../state/modules/userAccount/actions';
import {sendMessage} from '../../../../../state/actions';
import UserAvatarForm from '../components/UserAvatarForm';


const mapStateToProps = (state) => {
  
  return {
    isLoading: state.media.get('isImageUploadUrlLoading'),
    isUploading: state.media.get('isImageUploading'),
    isProcessing: state.userAccount.get('isSubmittingAvatar'),
    isSubmitting: state.media.get('isImageUploadUrlLoading')
      || state.media.get('isImageUploading')
      || state.userAccount.get('isSubmittingAvatar'),
    upload_url: state.media.getIn(['upload', 'upload_url']),
    file_id: state.media.getIn(['upload', 'file_id']),
    avatar: state.userAccount.getIn(['data', 'avatar']),
    isDeleting: state.userAccount.get('isDeletingAvatar'),
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getUploadUrl: (extension, directory, cb) => {
      dispatch(getImageUploadURL(extension, directory, cb));
    },
    upload: (path, file, cb) => {
      dispatch(uploadImage(path, file, cb));
    },
    update: (data, cb) => {
      dispatch(updateUserAccountAvatar(data, cb));
    },
    delete: (cb) => {
      dispatch(deleteUserAccountAvatar(cb));
    },
    sendMessage: (level, title, body, expires) => {
      dispatch(sendMessage(level, title, body, expires));
    }
  }
}

const UserAvatarFormContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(UserAvatarForm);

export default UserAvatarFormContainer
