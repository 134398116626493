import { List, Map } from 'immutable';

import { TYPES } from './actions';
import { SESSION_DESTROY } from '../../actions';
import Logger from '../../../lib/Logger';

const initialState = Map({
  isLoading: false,
  pages: {},
  total: 0,
  results: {},
  isPDFDownloading: false,
  form: {},
  careGuideNoteFormIsVisible: false,
  careGuideNoteformId: null,
  isSubmitting: false,
});

export default function careGuideNotes(
  state = initialState,
  action
) {
  Logger.log('debug', `[state.careGuideNotes.reducers] careGuideNotes(%j, %j)`, state, action);

  switch (action.type) {

    case TYPES.LIST_REQUEST:
      return state.mergeDeep({
        isLoading: true
      });

    case TYPES.LIST_SUCCESS:
      return state
        .mergeDeep({
          isLoading: false,
          pages: {
            [action.careGuideId]: {
              [action.order]: {
                [action.limit]: {
                  [action.page]: null,
                },
              },
            },
          },
          total: action.total,
          lastUpdated: action.receivedAt,
        })
        .setIn(
          ["pages", action.careGuideId, action.order, action.limit, action.page],
          List(action.result)
        );

    case TYPES.LIST_FAILURE:
      return state.mergeDeep({
        isLoading: false
      });

    case TYPES.PDF_DOWNLOAD_REQUEST:
      return state.mergeDeep({
        isPDFDownloading: true
      });

    case TYPES.PDF_DOWNLOAD_SUCCESS:
      return state.mergeDeep({
        isPDFDownloading: false,
      });

    case TYPES.PDF_DOWNLOAD_FAILURE:
      return state.mergeDeep({
        isPDFDownloading: false,
      });

    case TYPES.CREATE_REQUEST:
      return state.mergeDeep({
        form: {
          isSubmitting: true,
          success: null,
          errors: null,
        },
      });

    case TYPES.CREATE_SUCCESS:
      return state.mergeDeep({
        form: {
          isSubmitting: false,
          success: true,
          errors: null,
        },
        lastUpdated: action.receivedAt,
      });

    case TYPES.CREATE_FAILURE:
      return state
        .mergeDeep({
          form: {
            isSubmitting: false,
            success: false,
            errors: null,
          },
        })
        .setIn(["form", "errors"], action.error);

    case TYPES.UPDATE_REQUEST:
      return state.mergeDeep({
        form: {
          isSubmitting: true,
          success: null,
          errors: null,
        },
      });

    case TYPES.UPDATE_SUCCESS:
      return state.mergeDeep({
        form: {
          isSubmitting: false,
          success: true,
          errors: null,
        },
        lastUpdated: action.receivedAt,
      });

    case TYPES.UPDATE_FAILURE:
      return state
        .mergeDeep({
          form: {
            isSubmitting: false,
            success: false,
            errors: null,
          },
        })
        .setIn(["form", "errors"], action.error);

    case TYPES.PATCH_REQUEST:
      return state.mergeDeep({
        form: {
          isSubmitting: true,
          success: null,
          errors: null,
        },
      });

    case TYPES.PATCH_SUCCESS:
      return state.mergeDeep({
        form: {
          isSubmitting: false,
          success: true,
          errors: null,
        },
        lastUpdated: action.receivedAt,
      });

    case TYPES.PATCH_FAILURE:
      return state
        .mergeDeep({
          form: {
            isSubmitting: false,
            success: false,
            errors: null,
          },
        })
        .setIn(["form", "errors"], action.error);

    case TYPES.DELETE_REQUEST:
      return state.mergeDeep({
        isLoading: true
      });

    case TYPES.DELETE_SUCCESS:
      return state.mergeDeep({
        isLoading: false,
        lastUpdated: action.receivedAt,
        total: state.get('total') - 1,
      });

    case TYPES.DELETE_FAILURE:
      return state.mergeDeep({
        isLoading: false
      });

    case TYPES.FORM_DESTROY:
      return state
        .mergeDeep({
          form: {},
          careGuideNoteformId: null,
        })
        .set("form", action.form);

    case TYPES.SHOW_FORM:
      return state.mergeDeep({
        careGuideNoteFormIsVisible: true,
        careGuideNoteformId: action.id,
      });

    case TYPES.HIDE_FORM:
      return state.mergeDeep({
        careGuideNoteFormIsVisible: false,
      });


    case SESSION_DESTROY:
      return initialState;

    default:
      return state;
  }
}

Logger.log('silly', `state.careGuideNotes.reducers loaded.`);
