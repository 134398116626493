import {connect} from 'react-redux';
import {denormalize} from 'normalizr';

import {schema} from '../../../../../state/schema';
import SubscriptionSettings from '../components/SubscriptionSettings';

const mapStateToProps = (state) => {

  const activeSubscriptionId = state.payments.get('activeSubscriptionId');

  // get subscription from state
  let subscription = null;
  subscription = denormalize(
    state.entities.getIn(['subscriptions', activeSubscriptionId]),
    schema.subscription,
    state.entities.toJS()
  );

  return {
    isLoading: state.payments.get('isLoadingSubscription'),
    subscription: subscription,
  }
}

const SubscriptionSettingsContainer = connect(
  mapStateToProps
)(SubscriptionSettings);

export default SubscriptionSettingsContainer;
