import {connect} from 'react-redux';
import {List} from 'immutable';

import {
  loadUserAccount,
  updateUserAccountAlerts,
  userAccountAlertsFormDestroy
} from '../../../../../state/modules/userAccount/actions';
import {sendMessage} from '../../../../../state/actions';
import UserAccountAlertsForm from '../components/UserAccountAlertsForm';

const inputs = List([
  'alert_summary',
  'alert_summary_at',
  'alerts_email',
  'alerts_sms',
  'reminders_email',
  'reminders_sms',
]);

const mapStateToProps = (state) => {

  const data = {}, errors = {};
  for (const val of inputs.values()) {
    data[val] = state.userAccount.getIn(['data', val], ['alerts_email', 'alerts_sms', 'reminders_email', 'reminders_sms'].includes(val) ? {} : null);
    if (state.userAccount.getIn(['alertsForm', 'errors', val])) {
      errors[val] = state.userAccount.getIn(['alertsForm', 'errors', val]);
    }
  }

  data['alert_summary_at'] = data['alert_summary_at']
    ? data['alert_summary_at']
    : '18:00:00';
  
  return {
    isLoading: state.userAccount.get('isLoading'),
    isSubmitting: state.userAccount.get('isSubmittingAlerts'),
    success: state.userAccount.getIn(['alertsForm', 'success']),
    data: data,
    errors: errors
  }
}

const mapDispatchToProps = dispatch => {
  return {
    load: (cb) => {
      dispatch(loadUserAccount(cb));
    },
    submit: (data, cb) => {
      dispatch(updateUserAccountAlerts(data, cb));
    },
    formDestroy: (formState) => {
      dispatch(userAccountAlertsFormDestroy(formState));
    },
    sendMessage: (level, title, body, expires) => {
      dispatch(sendMessage(level, title, body, expires));
    }
  }
}

const UserAccountAlertsFormContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(UserAccountAlertsForm);

export default UserAccountAlertsFormContainer
