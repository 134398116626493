import {connect} from 'react-redux';
import {denormalize} from 'normalizr';

import InvitationsList from '../components/InvitationsList';
import {schema} from '../../../../../state/schema';
import {
  loadInvitations,
  respondCareGuideInvitation,
} from '../../../../../state/modules/careGuideInvitations/actions';
import {loadCareGuides} from '../../../../../state/modules/careGuides/actions';

const mapStateToProps = (state, ownProps) => {

  const page = ownProps.page;
  const limit = ownProps.limit;
  const order = ownProps.order;
  const result = state.careGuideInvitations.getIn(['pages', order, limit, page]);

  return {
    list: result 
      ? result.map(x => {
          return {
            key: x,
            ...denormalize(
              state.entities.getIn(['careGuideInvitations', x]),
              schema.careGuideInvitation,
              state.entities.toJS()
            )
          };
        }).toArray()
      : [],
    total: state.careGuideInvitations.get('total', 0),
    isLoading: state.careGuideInvitations.get('isLoading')
  }
}

const mapDispatchToProps = dispatch => {
  return {
    load: (page, limit, order, filter, cb) => {
      dispatch(loadInvitations(page, limit, order, filter, cb));
    },
    respond: (id, data, cb) => {
      dispatch(respondCareGuideInvitation(id, data, cb));
    },
    loadCareGuides: (page, limit, order, filter, partnershipId, cb) => {
      dispatch(loadCareGuides(page, limit, order, filter, partnershipId, cb));
    },
  }
}

const InvitationsListContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(InvitationsList);

export default InvitationsListContainer;
