import {normalize} from 'normalizr';

import api from '../../api';
import {schema} from '../../schema';
import {addEntities} from '../../actions';
import Logger from '../../../lib/Logger';

export const TYPES = {
  LIST_REQUEST: 'JOURNAL_TAGS/LIST_REQUEST',
  LIST_SUCCESS: 'JOURNAL_TAGS/LIST_SUCCESS',
  LIST_FAILURE: 'JOURNAL_TAGS/LIST_FAILURE',
};

export function journalTagListRequest(page, limit, order, filter) {
  Logger.log('debug', `[state.journalTags.actions] journalTagListRequest($${page}, ${limit}, ${order}, %j)`, filter);
  return {
    type: TYPES.LIST_REQUEST,
    page: page,
    limit: limit,
    order: order,
    filter: filter,
  }
}

export function journalTagListSuccess(data) {
  Logger.log('debug', `[state.journalTags.actions] journalTagListSuccess(%j)`, data);
  return {
    type: TYPES.LIST_SUCCESS,
    page: data.page,
    limit: data.limit,
    order: data.order,
    result: data.result,
    total: data.total,
    receivedAt: Date.now()
  }
}

export function journalTagListFailure(error) {
  Logger.log('debug', `[state.journalTags.actions] journalTagListFailure(%j)`, error);
  return {
    type: TYPES.LIST_FAILURE,
    error: error
  }
}


// API THUNK ACTION CREATORS

export function loadJournalTags(page=1, limit=10, order=null, filter=null, cb=function(){}) {
  Logger.log('debug', `[state.journalTags.actions] loadJournalTags(${page}, ${limit}, ${order}, %j, ###)`, filter);

  return async function(dispatch) {
    dispatch(journalTagListRequest(page, limit, order, filter));

    // call API
    const response = await api.getJournalTags(page, limit, order, filter);
    let success = false;

    // get journal tags list success
    if (200 === response.get('status')) {

      Logger.log('info', `Get API journal tags list success. Page: ${page}, Limit: ${limit}, Order: ${order}.`);

      const normalizedEntities = normalize(response.getIn(['data', 'journal_tags']), [schema.journalTag]);
      const data = {
        page: response.getIn(['data', 'page']),
        limit: response.getIn(['data', 'limit']),
        order: order,
        total: response.getIn(['data', 'total']),
        result: normalizedEntities.result
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(journalTagListSuccess(data));
      success = true;

    } else if (1 === page && 204 === response.get('status')) {

      Logger.log('info', `Get API journal tags list success [empty]. Page: ${page}, Limit: ${limit}, Order: ${order}.`);
      const data = {
        page: page,
        limit: limit,
        order: order,
        total: 0,
        result: []
      };
      dispatch(journalTagListSuccess(data));
      success = true;
      
    // get journal tags list failure
    } else {
      Logger.log('info', `Get API journal tags list failure. Page: ${page}, Limit: ${limit}, Order: ${order}.`);
      dispatch(journalTagListFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  }
}

Logger.log('silly', `state.journalTags.actions loaded.`);
