import React from 'react';
import { Translation } from 'react-i18next';

import { Tabs } from 'antd';
import { faStar, faUser, faInbox } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const ResourcesScreenTabs = ({ onChangeTab, tab, extraContent }) => {
  return (
    <Translation>
      {(t) => (
        <Tabs
          tab={t('resource_screen_tabs_title')}
          destroyInactiveTabPane={true}
          tabBarExtraContent={extraContent}
          size={'small'}
          onChange={onChangeTab}
          activeKey={tab}
        >
          <Tabs.TabPane
            centered={true}
            destroyInactiveTabPane={true}
            tab={
              <div className="icon-btn">
                <FontAwesomeIcon icon={faInbox} />
                {t('resource_screen_tabs_option_1')}
              </div>
            }
            key="all_resources"
          ></Tabs.TabPane>
          <Tabs.TabPane
            destroyInactiveTabPane={true}
            tab={
              <div className="icon-btn">
                <FontAwesomeIcon icon={faStar} />
                {t('resource_screen_tabs_option_2')}
              </div>
            }
            key="favorite_resources"
          ></Tabs.TabPane>
          <Tabs.TabPane
            tab={
              <div className="icon-btn">
                <FontAwesomeIcon icon={faUser} />
                {t('resource_screen_tabs_option_3')}
              </div>
            }
            key="personal_resources"
          ></Tabs.TabPane>
        </Tabs>
      )}
    </Translation>
  );
};

export default ResourcesScreenTabs;
