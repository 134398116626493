import { List, Map } from 'immutable';

import { TYPES } from './actions';
import { SESSION_DESTROY } from '../../actions';
import Logger from '../../../lib/Logger';

const initialState = Map({
  areEventsLoading: false,
  areParticipantEventsLoading: false,
  isResponding: false,
  events: {},
  participantEvents: {},
  eventForm: {},
  eventFormIsVisible: false,
  eventFormEventId: null,
  eventFormStartDate: null,
  eventDetailIsVisible: false,
  eventDetailIsView: true,
  eventDetailEventId: null,
  members: [],
  totalParticipantsEvent: 0,
});

export default function calendar(state = initialState, action) {
  Logger.log(
    'debug',
    `[state.calendar.reducers] calendar(%j, %j)`,
    state,
    action
  );

  switch (action.type) {
    case TYPES.LIST_EVENTS_REQUEST:
      return state.mergeDeep({
        areEventsLoading: true,
      });

    case TYPES.LIST_EVENTS_SUCCESS:
      // concat start and end dates to act as key
      const period = action.start + '.' + action.end;

      return state
        .mergeDeep({
          areEventsLoading: false,
          events: {
            [action.careGuideId]: {
              total: action.total,
              [period]: {
                [action.order]: {
                  [action.limit]: {
                    [action.page]: null,
                  },
                },
              },
            },
          },
          lastUpdated: action.receivedAt,
        })
        .setIn(
          [
            'events',
            action.careGuideId,
            period,
            action.order,
            action.limit,
            action.page,
          ],
          List(action.result)
        );

    case TYPES.LIST_EVENTS_FAILURE:
      return state.mergeDeep({
        areEventsLoading: false,
      });

    case TYPES.CREATE_EVENT_REQUEST:
      return state.mergeDeep({
        eventForm: {
          isSubmitting: true,
          success: null,
          errors: null,
        },
      });

    case TYPES.CREATE_EVENT_SUCCESS:
      return state.mergeDeep({
        eventForm: {
          isSubmitting: false,
          success: true,
          errors: null,
        },
        lastUpdated: action.receivedAt,
      });

    case TYPES.CREATE_EVENT_FAILURE:
      return state
        .mergeDeep({
          eventForm: {
            isSubmitting: false,
            success: false,
            errors: null,
          },
        })
        .setIn(['eventForm', 'errors'], action.error);

    case TYPES.CREATE_EVENT_PARTNER_REQUEST:
      return state.mergeDeep({
        eventForm: {
          isSubmitting: true,
          success: null,
          errors: null,
        },
      });

    case TYPES.CREATE_EVENT_PARTNER_SUCCESS:
      return state.mergeDeep({
        eventForm: {
          isSubmitting: false,
          success: true,
          errors: null,
        },
        lastUpdated: action.receivedAt,
      });

    case TYPES.CREATE_EVENT_PARTNER_FAILURE:
      return state
        .mergeDeep({
          eventForm: {
            isSubmitting: false,
            success: false,
            errors: null,
          },
        })
        .setIn(['eventForm', 'errors'], action.error);

    case TYPES.UPDATE_EVENT_REQUEST:
      return state.mergeDeep({
        eventForm: {
          isSubmitting: true,
          success: null,
          errors: null,
        },
      });

    case TYPES.UPDATE_EVENT_SUCCESS:
      return state.mergeDeep({
        eventForm: {
          isSubmitting: false,
          success: true,
          errors: null,
        },
        lastUpdated: action.receivedAt,
      });

    case TYPES.UPDATE_EVENT_FAILURE:
      return state
        .mergeDeep({
          eventForm: {
            isSubmitting: false,
            success: false,
            errors: null,
          },
        })
        .setIn(['eventForm', 'errors'], action.error);

    case TYPES.UPDATE_EVENT_PARTNER_REQUEST:
      return state.mergeDeep({
        eventForm: {
          isSubmitting: true,
          success: null,
          errors: null,
        },
      });

    case TYPES.UPDATE_EVENT_PARTNER_SUCCESS:
      return state.mergeDeep({
        eventForm: {
          isSubmitting: false,
          success: true,
          errors: null,
        },
        lastUpdated: action.receivedAt,
      });

    case TYPES.UPDATE_EVENT_PARTNER_FAILURE:
      return state
        .mergeDeep({
          eventForm: {
            isSubmitting: false,
            success: false,
            errors: null,
          },
        })
        .setIn(['eventForm', 'errors'], action.error);

    case TYPES.PATCH_EVENT_REQUEST:
      return state.mergeDeep({
        eventForm: {
          isSubmitting: true,
          success: null,
          errors: null,
        },
      });

    case TYPES.PATCH_EVENT_SUCCESS:
      return state.mergeDeep({
        eventForm: {
          isSubmitting: false,
          success: true,
          errors: null,
        },
        lastUpdated: action.receivedAt,
      });

    case TYPES.PATCH_EVENT_FAILURE:
      return state
        .mergeDeep({
          eventForm: {
            isSubmitting: false,
            success: false,
            errors: null,
          },
        })
        .setIn(['eventForm', 'errors'], action.error);

    case TYPES.PATCH_EVENT_PARTNER_REQUEST:
      return state.mergeDeep({
        eventForm: {
          isSubmitting: true,
          success: null,
          errors: null,
        },
      });

    case TYPES.PATCH_EVENT_PARTNER_SUCCESS:
      return state.mergeDeep({
        eventForm: {
          isSubmitting: false,
          success: true,
          errors: null,
        },
        lastUpdated: action.receivedAt,
      });

    case TYPES.PATCH_EVENT_PARTNER_FAILURE:
      return state
        .mergeDeep({
          eventForm: {
            isSubmitting: false,
            success: false,
            errors: null,
          },
        })
        .setIn(['eventForm', 'errors'], action.error);

    case TYPES.DELETE_EVENT_REQUEST:
      return state.mergeDeep({
        areEventsLoading: false,
        lastUpdated: action.receivedAt,
      });

    case TYPES.DELETE_EVENT_SUCCESS:
      return state.mergeDeep({
        areEventsLoading: false,
      });

    case TYPES.DELETE_EVENT_FAILURE:
      return state
        .mergeDeep({
          areEventsLoading: false,
          eventForm: {
            isSubmitting: false,
            success: false,
            errors: null,
          },
        })
        .setIn(['eventForm', 'errors'], action.error);

    case TYPES.DELETE_EVENT_PARTNER_REQUEST:
      return state.mergeDeep({
        areEventsLoading: false,
        lastUpdated: action.receivedAt,
      });

    case TYPES.DELETE_EVENT_PARTNER_SUCCESS:
      return state.mergeDeep({
        areEventsLoading: false,
      });

    case TYPES.DELETE_EVENT_PARTNER_FAILURE:
      return state
        .mergeDeep({
          areEventsLoading: false,
          eventForm: {
            isSubmitting: false,
            success: false,
            errors: null,
          },
        })
        .setIn(['eventForm', 'errors'], action.error);

    case TYPES.LIST_PARTICIPANT_EVENTS_REQUEST:
      return state.mergeDeep({
        areParticipantEventsLoading: true,
      });

    case TYPES.LIST_PARTICIPANT_EVENTS_SUCCESS:
      // concat start and end dates to act as key
      const period2 = action.start + '.' + action.end;

      return state
        .mergeDeep({
          areParticipantEventsLoading: false,
          participantEvents: {
            [action.careGuideId]: {
              total: action.total,
              [period2]: {
                [action.order]: {
                  [action.limit]: {
                    [action.page]: null,
                  },
                },
              },
            },
          },
          lastUpdated: action.receivedAt,
        })
        .setIn(
          [
            'participantEvents',
            action.careGuideId,
            period2,
            action.order,
            action.limit,
            action.page,
          ],
          List(action.result)
        );

    case TYPES.LIST_PARTICIPANT_EVENTS_FAILURE:
      return state.mergeDeep({
        areParticipantEventsLoading: false,
      });

    case TYPES.RESPOND_PARTICIPANT_EVENT_REQUEST:
      return state.mergeDeep({
        isResponding: true,
      });

    case TYPES.RESPOND_PARTICIPANT_EVENT_SUCCESS:
      return state.mergeDeep({
        isResponding: false,
      });

    case TYPES.RESPOND_PARTICIPANT_EVENT_FAILURE:
      return state.mergeDeep({
        isResponding: false,
      });

    case TYPES.CREATE_EVENT_EXCEPTION_REQUEST:
      return state.mergeDeep({
        eventForm: {
          isSubmitting: true,
          success: null,
        },
      });

    case TYPES.CREATE_EVENT_EXCEPTION_SUCCESS:
      return state.mergeDeep({
        eventForm: {
          isSubmitting: false,
          success: true,
        },
        lastUpdated: action.receivedAt,
      });

    case TYPES.CREATE_EVENT_EXCEPTION_FAILURE:
      return state.mergeDeep({
        eventForm: {
          isSubmitting: false,
          success: false,
        },
      });

    case TYPES.LIST_CARE_GUIDES_EVENT_PARTICIPANTS_REQUEST:
      return state.mergeDeep({
        areParticipantEventsLoading: true,
      });

    case TYPES.LIST_CARE_GUIDES_EVENT_PARTICIPANTS_SUCCESS:
      return state
        .mergeDeep({
          areParticipantEventsLoading: false,
          pages: {
            [action.partnershipId]: {
              [action.order]: {
                [action.limit]: {
                  [action.page]: null,
                },
              },
            },
          },
          totalParticipantsEvent: action.total,
          lastUpdated: action.receivedAt,
        })
        .setIn(
          [
            'pages',
            action.partnershipId,
            action.order,
            action.limit,
            action.page,
          ],
          List(action.result)
        );

    case TYPES.LIST_CARE_GUIDES_EVENT_PARTICIPANTS_FAILURE:
      return state.mergeDeep({
        areParticipantEventsLoading: false,
      });

    case TYPES.CREATE_PARTNERSHIP_EVENT_REQUEST:
      return state.mergeDeep({
        eventForm: {
          isSubmitting: true,
          success: null,
          errors: null,
        },
      });

    case TYPES.CREATE_PARTNERSHIP_EVENT_SUCCESS:
      return state.mergeDeep({
        eventForm: {
          isSubmitting: false,
          success: true,
          errors: null,
        },
        lastUpdated: action.receivedAt,
      });

    case TYPES.CREATE_PARTNERSHIP_EVENT_FAILURE:
      return state
        .mergeDeep({
          eventForm: {
            isSubmitting: false,
            success: false,
            errors: null,
          },
        })
        .setIn(['eventForm', 'errors'], action.error);

    case TYPES.EVENT_FORM_DESTROY:
      return state
        .mergeDeep({
          eventForm: {},
        })
        .set('eventForm', action.form);

    case TYPES.SHOW_EVENT_FORM:
      return state.mergeDeep({
        eventFormIsVisible: true,
        eventFormEventId: action.eventId,
        eventFormStartDate: action.startDate,
      });

    case TYPES.HIDE_EVENT_FORM:
      return state.mergeDeep({
        eventFormIsVisible: false,
        eventFormEventId: null,
        eventFormStartDate: null,
      });

    case TYPES.SHOW_EVENT_DETAIL:
      return state.mergeDeep({
        eventDetailIsVisible: true,
        eventDetailIsView: action.view,
        eventDetailEventId: action.eventId,
      });

    case TYPES.HIDE_EVENT_DETAIL:
      return state.mergeDeep({
        eventDetailIsVisible: false,
        eventDetailIsView: false,
        eventDetailEventId: null,
      });

    case SESSION_DESTROY:
      return initialState;

    default:
      return state;
  }
}

Logger.log('silly', `state.calendar.reducers loaded.`);
