import React, { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import { Translation, getI18n } from "react-i18next";
import { Affix, Card, Col, Form, Row, Spin, Input } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

import message from "../../../elements/lib/MessageWrapper";
import FormMetadata from "../../../elements/components/FormMetadata";
import { pathTo } from "../../../Routes";
import Logger from "../../../../../lib/Logger";
import SubscriptionStatusInput from './SubscriptionStatusInput';
import TagInput from '../../adminPartnerClassesAdmin/containers/TagInputContainer';

const CareGuideAdminForm = ({
  id,
  data,
  errors,
  load,
  destroyForm,
  isLoading,
  isSubmitting,
  created_id,
  activeId,
  result,
  ...props
}) => {
  const [classes, setClasses] = useState([]);
  const [subscriptionStatus, setSubscriptionStatus] = useState(-1);
  const [redirectTo, setRedirectTo] = useState(null);
  const [form] = Form.useForm();
  // form column settings
  const layout = {
    main: {
      labelCol: { span: 5 },
      wrapperCol: { span: 19 },
    },
  };

  // load record data from API
  useEffect(() => {
    if (id) {
      load(activeId, id);
    }
  }, [activeId, id, load]);

  // update input values when new data is available
  const dataString = JSON.stringify(data);
  useEffect(() => {
    if (id && !isSubmitting) {
      const dataObj = JSON.parse(dataString);
      form.setFieldsValue({
        ...dataObj,
        ...result,
      });
      setSubscriptionStatus(dataObj['subscription_status']);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form, dataString, isSubmitting, id]);

  // handle errors reported by API
  useEffect(() => {
    let firstFieldName = "";
    for (const field in errors) {
      form.setFields([{ name: field, errors: errors[field] }]);
      if (firstFieldName === "") {
        firstFieldName = field;
      }
    }
    form.scrollToField(firstFieldName);
  }, [form, errors]);
  // redirect add form to edit form on successful create action
  useEffect(() => {
    if (created_id) {
      setRedirectTo(pathTo("CareGuideAdminEditScreen", { id_partnership: activeId, id: created_id }));
    }
    return () => {
      destroyForm();
    };
  }, [created_id, activeId, setRedirectTo, destroyForm]);

  // submit data handler
  const submitData = async (values) => {
    Logger.log("debug", `CareGuideAdminForm.submitData(###)`);

    // API POST/PUT payload
    let payload = {};
    let care_partner_tags = [];
    for (const input of Object.keys(data)) {
      if (input in values) {
        payload[input] = values[input];
      }
    }

    for (const input of classes) {
      if (values[input] && values[input] !== '') {
        care_partner_tags.push(values[input]);
      }
    }
    delete payload['care_partner_tags']
    payload['care_partner_tags_list'] = care_partner_tags;


    if (id) {
      // update
      props.update(activeId, id, payload, (success) => {
        if (success) {
          message.success(getI18n().t("feedback_form_success"));
        } else {
          message.error(getI18n().t("feedback_form_error"));
        }
      });
    }
  };

  // form submit handler
  const handleFinish = async (values) => {
    Logger.log("debug", `CareGuideTagForm.handleFinish(###)`);
    if (!props.isSubmitting) {
      await submitData(values);
    }
  };

  // form error handler
  const handleFinishFailed = ({ values, errorFields, outOfDate }) => {
    Logger.log("debug", `CareGuideAdminForm.handleFinishFailed(###)`);
    message.error(getI18n().t("feedback_form_error"));
    if (errorFields && errorFields.length > 0) {
      form.scrollToField(errorFields[0].name);
    }
  };

  if (redirectTo) {
    return <Redirect to={redirectTo} />;
  }

  return (
    <Translation>
      {(t) => (
        <>
          <div className="care-guide-admin-form">
            <Form
              name="care_guide_admin_form"
              form={form}
              initialValues={data}
              onFinish={handleFinish}
              onFinishFailed={handleFinishFailed}
              validateTrigger="onSubmit"
              {...layout.main}
            >
              <Row gutter={16}>
                <Col xs={24} lg={18}>
                  <Card
                    title={
                      id
                        ? t("care_guide_admin_edit_title")
                        : t("care_guide_admin_add_title")
                    }
                    extra={
                      isLoading ? (
                        <Spin
                          indicator={
                            <LoadingOutlined style={{ fontSize: 20 }} spin />
                          }
                        />
                      ) : null
                    }
                  >
                    
                    <SubscriptionStatusInput
                      name="subscription_status"
                      label={t('care_guide_admin_accounts_input_subscription_status')}
                      rules={[
                        { required: true, message: t('feedback_validation_required') },
                      ]}
                      disabled={isLoading || isSubmitting}
                      optionPending={subscriptionStatus}

                      />

                    <div className="form-group">
                      <TagInput
                        partnershipId={activeId}
                        disabled={isLoading || isSubmitting}
                        setClasses={setClasses}
                      />
                    </div>
                    <Form.Item
                      style={{ display: 'none' }}
                      name="care_partner_tags"
                    >
                      <Input type="hidden" />
                    </Form.Item>
                    <Row>
                      <Col offset={layout.main.labelCol.span}>
                        {form.getFieldError('care_partner_tags') &&
                          <h2 className="error-label">
                            {form.getFieldError('care_partner_tags')}
                          </h2>}
                      </Col>
                    </Row>
                  </Card>
                </Col>

                <Col xs={24} lg={6}>
                  <Affix offsetTop={10}>
                    <Card title={t("form_metadata_header")}>
                      <FormMetadata
                        id={id}
                        activeDelete={false}
                        partnershipId={activeId}
                        isSubmitting={isSubmitting}
                        showStatus={false}
                        delete={null}
                        deleteRedirectTo="CareGuidesAdminScreen"
                        createdAt={props.createdAt}
                        updatedAt={props.updatedAt}
                      />
                    </Card>
                  </Affix>
                </Col>
              </Row>
            </Form>
          </div>
        </>
      )}
    </Translation>
  );
};

export default CareGuideAdminForm;

Logger.log("silly", `CareGuideAdminForm loaded.`);