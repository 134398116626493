import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { Translation } from "react-i18next";

import CareGuideWrapper from "../../../layouts/components/CareGuideWrapper";
import DocumentHead from "../../../elements/components/DocumentHead";
import Loading from "../../../elements/components/Loading";
import { pathTo } from "../../../Routes";
import scrollToTop from "../../../elements/lib/scrollToTop";
import Logger from "../../../../../lib/Logger";

class DashboardScreen extends Component {
  render() {
    const { careGuideCount, userProfilesPartneshipsCount } = this.props;

    if(userProfilesPartneshipsCount === 1 && careGuideCount === 0){
      return (
        <Redirect to={pathTo("HomeScreen")} />
      );
    }

    if(userProfilesPartneshipsCount === 0 && careGuideCount > 0){
      return(
        <Redirect to={pathTo("HomeScreen")} />
      )
    }

    if(userProfilesPartneshipsCount > 0 || careGuideCount > 0){
      return(
        <Redirect to={pathTo("HomeScreen")} />
      )
    }

    
    return (
      <Translation>
        {(t) => (
          <CareGuideWrapper>
            <DocumentHead title={t("route_dashboard")} />
            
            {careGuideCount === 0 ? (
              <p>{t("dashboard_no_care_guides")}</p>
            ) : (
              <Loading />
            )}
          </CareGuideWrapper>
        )}
      </Translation>
    );
  }


  /* LIFECYCLE EVENTS */

  componentDidMount() {
    scrollToTop();
  }
}

export default DashboardScreen;

Logger.log("silly", `DashboardScreen loaded.`);
