import { connect } from 'react-redux';
import { denormalize } from 'normalizr';

import { schema } from '../../../../../state/schema';
import DashboardDetailScreen from '../components/DashboardDetailScreen';

const mapStateToProps = (state, ownProps) => {
  const page = parseInt(
    ownProps.match.params.page ? ownProps.match.params.page : 1
  );

  const activeId = state.careGuides.get('activeId') ?? ownProps.match.params.id;

  const careGuide = activeId
    ? denormalize(
        state.entities.getIn(['careGuides', activeId]),
        schema.careGuide,
        state.entities.toJS()
      )
    : null;

  return {
    accessCareGuide: state.careGuides.get('accessCareGuide'),
    areJournalsLoading: state.journalPosts.get('isLoading'),
    page: page,
    careGuideId: activeId,
    isCareGuideLoading: careGuide === undefined ? true : false,
    isCareGuideActive: careGuide?.account?.is_subscription_valid,
  };
};

const DashboardDetailScreenContainer = connect(mapStateToProps)(
  DashboardDetailScreen
);

export default DashboardDetailScreenContainer;
