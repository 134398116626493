import {connect} from 'react-redux';
import {denormalize} from 'normalizr';

import {schema} from '../../../../../state/schema';
import {loadRegistrationCodes, deleteRegistrationCode} from '../../../../../state/modules/registrationCodes/actions';
import {sendMessage} from '../../../../../state/actions';
import RegistrationCodesList from '../components/RegistrationCodesList';
import storage from "../../../../../lib/Storage";

const mapStateToProps = (state, ownProps) => {

  const page = ownProps.page;
  const limit = ownProps.limit;
  const order = ownProps.order;
  const result = state.registrationCodes.getIn(['pages', order, limit, page]);

  return {
    list: result 
      ? result.map(x => {
          return {
            key: x,
            ...denormalize(
              state.entities.getIn(['registrationCodes', x]),
              schema.registrationCode,
              state.entities.toJS()
            )
          };
        }).toArray().map(x => {
          // flatten some nested objects to make table easier to work with
          return {
            plan_id: x.plan ? x.plan.id : '',
            plan_name: x.plan ? x.plan.name : '',
            partnership_id: x.partnership ? x.partnership.id : '',
            ...x
            
          };
        })
      : [],
    isLoading: state.registrationCodes.get('isLoading'),
    total: state.registrationCodes.get('total'),
    partnershipActiveId: storage.get('local', 'partnershipId'),
  }
}

const mapDispatchToProps = dispatch => {
  return {
    load: (parntership_id, page, limit, order, filter, cb) => {
      dispatch(loadRegistrationCodes(parntership_id, page, limit, order, filter, cb));
    },
    remove: (id, cb) => {
      dispatch(deleteRegistrationCode(id, cb));
    },
    sendMessage: (level, title, body, expires) => {
      dispatch(sendMessage(level, title, body, expires));
    },
  }
}

const RegistrationCodesListContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(RegistrationCodesList);

export default RegistrationCodesListContainer;
