import { normalize } from 'normalizr';

import api from '../../api';
import { schema } from '../../schema';
import { addEntities } from '../../actions';
import Logger from '../../../lib/Logger';

// ACTION TYPES

export const ADMIN_PARTNER_CLASS_ADMIN_LIST_REQUEST =
  'ADMIN_PARTNER_CLASS_ADMIN_LIST_REQUEST';
export const ADMIN_PARTNER_CLASS_ADMIN_LIST_SUCCESS =
  'ADMIN_PARTNER_CLASS_ADMIN_LIST_SUCCESS';
export const ADMIN_PARTNER_CLASS_ADMIN_LIST_FAILURE =
  'ADMIN_PARTNER_CLASS_ADMIN_LIST_FAILURE';
export const ADMIN_PARTNER_CLASS_ADMIN_PARTNERSHIP_LIST_REQUEST =
  'ADMIN_PARTNER_CLASS_ADMIN_PARTNERSHIP_LIST_REQUEST';
export const ADMIN_PARTNER_CLASS_ADMIN_PARTNERSHIP_LIST_SUCCESS =
  'ADMIN_PARTNER_CLASS_ADMIN_PARTNERSHIP_LIST_SUCCESS';
export const ADMIN_PARTNER_CLASS_ADMIN_PARTNERSHIP_LIST_FAILURE =
  'ADMIN_PARTNER_CLASS_ADMIN_PARTNERSHIP_LIST_FAILURE';

// ACTION CREATORS

export function adminPartnerClassAdminListRequest(
  partnershipId,
  page,
  limit,
  order,
  filter,
) {
  Logger.log(
    'debug',
    `[adminPartnerClassesAdmin.actions] adminPartnerClassAdminListRequest(${partnershipId}, ${page}, ${limit}, ${order}, %j)`
  );
  return {
    type: ADMIN_PARTNER_CLASS_ADMIN_LIST_REQUEST,
    partnershipId: partnershipId,
    page: page,
    limit: limit,
    order: order,
    filter: filter,
  };
}

export function adminPartnerClassAdminListSuccess(partnershipId, data) {
  Logger.log(
    'debug',
    `[adminPartnerClassesAdmin.actions] adminPartnerClassAdminListSuccess(${partnershipId}, %j)`,
    data
  );
  return {
    type: ADMIN_PARTNER_CLASS_ADMIN_LIST_SUCCESS,
    partnershipId: partnershipId,
    page: data.page,
    limit: data.limit,
    order: data.order,
    result: data.result,
    total: data.total,
    receivedAt: Date.now(),
  };
}

export function adminPartnerClassAdminListFailure(partnershipId, error) {
  Logger.log(
    'debug',
    `[adminPartnerClassesAdmin.actions] adminPartnerClassAdminListFailure(${partnershipId}, %j)`,
    error
  );
  return {
    type: ADMIN_PARTNER_CLASS_ADMIN_LIST_FAILURE,
    partnershipId: partnershipId,
    error: error,
  };
}

export function adminPartnerClassAdminPartnershipListRequest(partnershipId) {
  Logger.log(
    'debug',
    `[adminPartnerClassesAdmin.actions] adminPartnerClassAdminPartnershipListRequest(${partnershipId})`
  );
  return {
    type: ADMIN_PARTNER_CLASS_ADMIN_PARTNERSHIP_LIST_REQUEST,
    partnershipId: partnershipId,
  };
}

export function adminPartnerClassAdminPartnershipListSuccess(
  partnershipId,
  data
) {
  Logger.log(
    'debug',
    `[adminPartnerClassesAdmin.actions] adminPartnerClassAdminPartnershipListSuccess(${partnershipId}, %j)`,
    data
  );
  return {
    type: ADMIN_PARTNER_CLASS_ADMIN_PARTNERSHIP_LIST_SUCCESS,
    partnershipId: partnershipId,
    result: data.result,
    total: data.total,
    receivedAt: Date.now(),
  };
}

export function adminPartnerClassAdminPartnershipListFailure(
  partnershipId,
  error
) {
  Logger.log(
    'debug',
    `[adminPartnerClassesAdmin.actions] adminPartnerClassAdminPartnershipListFailure(${partnershipId}, %j)`,
    error
  );
  return {
    type: ADMIN_PARTNER_CLASS_ADMIN_PARTNERSHIP_LIST_FAILURE,
    partnershipId: partnershipId,
    error: error,
  };
}

// API THUNK ACTION CREATORS

export function loadAdminPartnerClasses(
  partnershipId = null,
  page = 1,
  limit = 10,
  order = null,
  filter = null,
  cb = function () {}
) {
  Logger.log(
    'debug',
    `[adminPartnerClassesAdmin.actions] loadAdminPartnerClasses(${partnershipId}, ${page}, ${limit}, ${order})`
  );

  return async function (dispatch) {
    dispatch(
      adminPartnerClassAdminListRequest(partnershipId, page, limit, order, filter)
    );

    // call API
    const response = await api.getAdminPartnerClasses(
      partnershipId,
      page,
      limit,
      order,
      filter
    );
    let success = false;
    let result = [];

    // get admin partner classes list success
    if (200 === response.get('status')) {
      Logger.log(
        'info',
        `Get API admin partner classes list success. Partnership ID: ${partnershipId}, Page: ${page}, Limit: ${limit}, Order: ${order}.`
      );

      const normalizedEntities = normalize(
        response.getIn(['data', 'admin_partner_classes']),
        [schema.adminPartnerClassAdmin]
      );
      
      const data = {
        page: response.getIn(['data', 'page']),
        limit: response.getIn(['data', 'limit']),
        order: order,
        total: response.getIn(['data', 'total']),
        result: normalizedEntities.result,
      };

      result = normalizedEntities.result;
      dispatch(addEntities(normalizedEntities));
      dispatch(adminPartnerClassAdminListSuccess(partnershipId, data));
      success = true;
    } else if (1 === page && 204 === response.get('status')) {
      Logger.log(
        'info',
        `Get API admin partner classes success [empty]. Partnership ID: ${partnershipId}, Page: ${page}, Limit: ${limit}, Order: ${order}.`
      );
      const data = {
        page: page,
        limit: limit,
        order: order,
        total: 0,
        result: [],
      };
      dispatch(adminPartnerClassAdminListSuccess(partnershipId, data));
      success = true;

      // get admin partner classes list failure
    } else {
      Logger.log(
        'info',
        `Get API admin partner classes list failure. Page: ${page}, Limit: ${limit}, Order: ${order}.`
      );
      dispatch(
        adminPartnerClassAdminListFailure(
          partnershipId,
          response.getIn(['data', 'error'])
        )
      );
    }

    // callback function
    cb(success, result);
  };
}

export function loadAdminPartnerClassPartnershipAdmin(
  partnershipId = null,
  cb = function () {}
) {
  Logger.log(
    'debug',
    `[adminPartnerClassesAdmin.actions] loadAdminPartnerClassPartnershipAdmin(${partnershipId}`
  );

  return async function (dispatch) {
    dispatch(adminPartnerClassAdminPartnershipListRequest(partnershipId));

    // call API
    const response = await api.getAdminPartnerClassesAdmin(partnershipId);
    let success = false;

    // get admin partner class partnership list success
    if (200 === response.get('status')) {
      Logger.log(
        'info',
        `Get API admin partner class partnership list success. ID: ${partnershipId}`
      );

      const normalizedEntities = normalize(
        response.getIn(['data', 'admin_partner_classes_admin']),
        [schema.adminPartnerClassAdmin]
      );

      const data = {
        total: response.getIn(['data', 'total']),
        result: normalizedEntities.result,
      };
      dispatch(addEntities(normalizedEntities));
      dispatch(
        adminPartnerClassAdminPartnershipListSuccess(partnershipId, data)
      );
      success = true;
    } else if (204 === response.get('status')) {
      Logger.log(
        'info',
        `Get API admin partner class partnership success [empty]. ID: ${partnershipId}.`
      );
      const data = {
        total: 0,
        result: [],
      };
      dispatch(
        adminPartnerClassAdminPartnershipListSuccess(partnershipId, data)
      );
      success = true;

      // get admin partner class partnership list failure
    } else {
      Logger.log(
        'info',
        `Get API admin partner class partnership list failure. ID: ${partnershipId}.`
      );
      dispatch(
        adminPartnerClassAdminPartnershipListFailure(
          partnershipId,
          response.getIn(['data', 'error'])
        )
      );
    }

    // callback function
    cb(success);
  };
}

Logger.log('silly', `adminPartnerClassesAdmin.actions loaded.`);
