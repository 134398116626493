import {connect} from 'react-redux';

import EmailConfirmationScreen from '../components/EmailConfirmationScreen';
import QueryString from '../../../../../lib/QueryString';

const mapStateToProps = (state, ownProps) => {
  const queryParams = QueryString.parse(ownProps.location.search);
  return {
    isSubmitting: state.userAccount.get('isEmailConfirmationSubmitting'),
    token: 'token' in queryParams ? queryParams['token'] : null,
  }
}

const EmailConfirmationScreenContainer = connect(
  mapStateToProps
)(EmailConfirmationScreen);

export default EmailConfirmationScreenContainer;
