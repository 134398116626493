import React, {useState, useEffect} from 'react';
import {withRouter} from 'react-router-dom';
import {Translation} from 'react-i18next';
import {Avatar, Button, Dropdown, Form, Input, Menu} from 'antd';
import {DownOutlined, UserOutlined} from '@ant-design/icons';

import imageURL from '../../../elements/lib/imageURL';

const CareGuidePicker = ({list, load, defaultId, defaultLabel, defaultImage, onChange, disabled=false, ...props}) => {

  const [currentId, setCurrentId] = useState(defaultId);
  const [currentLabel, setCurrentLabel] = useState(defaultLabel);
  const [currentImage, setCurrentImage] = useState(defaultImage);

  useEffect(() => {
    onChange(currentId);
  }, [currentId, onChange]);

  useEffect(() => {
    setCurrentId(defaultId);
    onChange(defaultId);
  }, [defaultId, setCurrentId, onChange]);

  useEffect(() => {
    setCurrentLabel(defaultLabel);
  }, [defaultLabel, setCurrentLabel]);

  useEffect(() => {
    setCurrentImage(defaultImage);
  }, [defaultImage, setCurrentImage]);

  const clickHandler = e => {
    const id = e.item.props.value;
    const selectedCareGuide = list.filter(x => x.id === id)[0];
    setCurrentId(selectedCareGuide.id);
    setCurrentLabel(selectedCareGuide.first_name + ' ' + selectedCareGuide.last_name);
    setCurrentImage(selectedCareGuide.image);
  }

  const menu = (
    <Menu onClick={clickHandler}>
      {list.map(x => <Menu.Item key={x.id} value={x.id}>{x.first_name + ' ' + x.last_name}</Menu.Item>)}
    </Menu>
  );

  const imageSize = 24;
  const careGuideImageUrl = currentImage && currentImage.file_name && currentImage.file_extension && currentImage.file_directory
    ? imageURL(currentImage.file_directory, currentImage.file_name, currentImage.file_extension, imageSize, imageSize, 'crop')
    : null;

  return (
    <Translation>{(t) => 
      <>

        {props.total > 0
          ? <div className="care-guide-picker">
              <Avatar size={imageSize} src={careGuideImageUrl} icon={<UserOutlined />} />
              {list.length > 1
                ? <Dropdown overlay={menu} disabled={disabled}>
                    <Button>
                      {currentLabel} <DownOutlined />
                    </Button>
                  </Dropdown>
                : <Button>
                    {currentLabel}
                  </Button>}
            </div>
          : null}

          <Form.Item
            name={props.name}
            label={props.label}
            rules={props.rules}
            style={{display: 'none'}}
          >
            <Input type="hidden" />
          </Form.Item>

      </>
    }</Translation>
  );
}

export default withRouter(CareGuidePicker);
