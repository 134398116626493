import React, { useEffect, useState } from 'react';
import { Translation } from 'react-i18next';
import { Form, Select } from 'antd';
import { List } from 'immutable';
import { schema } from '../../../../../state/schema';
import store from '../../../../../state/store';
import { denormalize } from 'normalizr';
import useDebounce from '../../../elements/components/useDebounce';

const { Option } = Select;

const MemberEventFormInput = ({
  careGuidesIds,
  name,
  label,
  placeholder,
  isLoading,
  disabled,
  load,
  profileId,
  total,
  onChange = null,
  multiple = false,
  rules = [],
  partnershipId,
  loadParticipants,
  initialParticipants,
  form,
  ...props
}) => {
  const [query, setQuery] = useState('');
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [lastPage, setLastPage] = useState(1);
  const [filter, setFilter] = useState({});
  const [result, setResult] = useState(List([]));
  const [firstDebouncedQuery, setFirstDebouncedQuery] = useState(true);
  const [debouncedTotal, setDebouncedTotal] = useState(0);
  const [useDebouncedTotal, setUseDebouncedTotal] = useState(false);

  const limit = 10;
  const order = 'updated_at.desc';
  const state = store.getState();
  const debouncedQuery = useDebounce(query, 1000);

  const cleanDebounced = () => {
    setFilter({});
    setPage(lastPage);
    setFirstDebouncedQuery(true);
    setUseDebouncedTotal(false);
    setDebouncedTotal(0);
  };

  useEffect(() => {
    if (debouncedQuery.trim() === '') {
      cleanDebounced();
      return;
    } else {
      if (firstDebouncedQuery) {
        setLastPage(page);
      }
      setUseDebouncedTotal(true);
      setFirstDebouncedQuery(false);
      setPage(1);
      setFilter({ name: debouncedQuery });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedQuery]);

  // load sections on mount
  useEffect(() => {
    if (partnershipId && !!careGuidesIds.length) {
      loadParticipants(
        partnershipId,
        page,
        limit,
        order,
        filter,
        {},
        (success, result) => {
          if (success) {
            setResult(List(result));
          }
        }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [partnershipId, page, limit, filter, loadParticipants, careGuidesIds]);

  const onScroll = async (event) => {
    const target = event.target;
    let totalCurrent = data.length;

    if (useDebouncedTotal) {
      totalCurrent = debouncedTotal;
    }

    if (
      !isLoading &&
      target.scrollTop + target.offsetHeight === target.scrollHeight &&
      total > totalCurrent
    ) {
      setPage(page + 1);
    }
  };

  useEffect(() => {
    const options = result
      ? result
          .map((x) => {
            return {
              key: x,
              ...denormalize(
                state.entities.getIn(['careGuidesMembersPartnerships', x]),
                schema.careGuidesMembersPartnership,
                state.entities.toJS()
              ),
            };
          })
          .toArray()
      : [];

    if (useDebouncedTotal) {
      setDebouncedTotal((prevState) => prevState + options.length);
    }

    setData((prevState) => {
      const filteredOptions = options.filter(
        (newOption) =>
          !prevState.some((x) => x?.profile?.id === newOption?.profile?.id)
      );

      return prevState.concat(filteredOptions);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [result]);

  useEffect(() => {
    setData([]);
    setPage(1);
    if (!!initialParticipants.length) {
      const options = initialParticipants
        ? initialParticipants.map((x) => {
            return {
              key: x,
              ...denormalize(
                state.entities.getIn(['careGuidesMembersPartnerships', x]),
                schema.careGuidesMembersPartnership,
                state.entities.toJS()
              ),
            };
          })
        : [];
      if (useDebouncedTotal) {
        setDebouncedTotal((prevState) => prevState + options.length);
      }
      setData((prevState) => {
        const filteredOptions = options.filter(
          (newOption) =>
            !prevState.some((x) => x?.profile.id === newOption?.profile.id)
        );
        return prevState.concat(filteredOptions);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialParticipants]);

  return (
    <Translation>
      {(t) => (
        <div className="select-form">
          <Form.Item name={name} label={label} rules={rules}>
            <Select
              showSearch
              showArrow={true}
              mode={multiple ? 'multiple' : null}
              onPopupScroll={onScroll}
              placeholder={placeholder}
              optionFilterProp="children"
              disabled={disabled}
              loading={isLoading}
              onChange={onChange}
              maxTagCount={6}
              onSearch={(value) => setQuery(value)}
              onBlur={() => setQuery('')}
            >
              {data.map((x) => (
                <Select.Option key={x?.profile?.id} value={x?.profile?.id}>
                  {x?.profile?.first_name} {x?.profile?.last_name}
                </Select.Option>
              ))}
              {isLoading && <Option>{t('form_input_loading')}</Option>}
            </Select>
          </Form.Item>
        </div>
      )}
    </Translation>
  );
};

export default MemberEventFormInput;
