import React, {useEffect} from 'react';
import {Translation} from 'react-i18next';
import {Form, Select} from 'antd';

const PartnershipInput = ({name, label, options, isLoading, disabled, load, onChange, rules=null, style=null, loadOnMount=true, allowNone=false, ...props}) => {

  // load sections on mount
  useEffect(() => {
    if (loadOnMount) {
      load(1, 100);
    }
  }, [load, loadOnMount]);

  return (
    <Translation>{(t) => 
      <div className="form-group">
        <Form.Item
          name={name}
          label={label}
          style={style}
          rules={rules}
        >
          <Select
            disabled={disabled || isLoading}
            loading={isLoading}
            onChange={onChange}
          >
            {allowNone
              ? <Select.Option key={0} value=''>{t('form_input_options_none')}</Select.Option>
              : null}
            {options.map(x => <Select.Option key={x.value} value={x.value}>{x.label}</Select.Option>)}
          </Select>
        </Form.Item>
      </div>
    }</Translation>
  );
}

export default PartnershipInput;
