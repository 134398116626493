import React from 'react';
import {Translation} from 'react-i18next';
import {Form, DatePicker, Select} from 'antd';

const { RangePicker } = DatePicker;

const DateFilter = ({selectName, dateName, label, onSelect=null, onChange=null, showDate=false, ...props}) => {

  const options = [
    {
      value: 1,
      label: 'journal_posts_date_range_all',
    },
    {
      value: 2,
      label: 'journal_posts_date_range_7_days',
    },
    {
      value: 3,
      label: 'journal_posts_date_range_30_days',
    },
    {
      value: 4,
      label: 'journal_posts_date_range_90_days',
    },
    {
      value: 5,
      label: 'journal_posts_date_range_12_months',
    },
    {
      value: 6,
      label: 'journal_posts_date_range_custom',
    },
  ];

  return (
    <Translation>{(t) => 
      <div className="form-group">

        <Form.Item
          name={selectName}
          label={label}
        >
          <Select
            disabled={props.isLoading || props.isSubmitting}
            onChange={onSelect}
          >
            {options.map(x => <Select.Option key={x.value} value={x.value}>{t(x.label)}</Select.Option>)}
          </Select>
        </Form.Item>

        <Form.Item
          name={dateName}
          style={{display: showDate ? 'block' : 'none'}}
        >
          <RangePicker
            disabled={props.isLoading || props.isSubmitting}
            onChange={onChange}
            format="MM/DD/YYYY"
            style={{width: '100%'}}
          />
        </Form.Item>
      </div>
    }</Translation>
  );
}

export default DateFilter;
