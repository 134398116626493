// import {normalize} from 'normalizr';

import api from '../../api';
// import {schema} from '../../schema';
// import {addEntities} from '../../actions';
import Logger from '../../../lib/Logger';

export const TYPES = {
  LIST_REQUEST: 'JOURNAL_EMOJIS/LIST_REQUEST',
  LIST_SUCCESS: 'JOURNAL_EMOJIS/LIST_SUCCESS',
  LIST_FAILURE: 'JOURNAL_EMOJIS/LIST_FAILURE',
  CREATE_REQUEST: 'JOURNAL_EMOJIS/CREATE_REQUEST',
  CREATE_SUCCESS: 'JOURNAL_EMOJIS/CREATE_SUCCESS',
  CREATE_FAILURE: 'JOURNAL_EMOJIS/CREATE_FAILURE',
  DELETE_REQUEST: 'JOURNAL_EMOJIS/DELETE_REQUEST',
  DELETE_SUCCESS: 'JOURNAL_EMOJIS/DELETE_SUCCESS',
  DELETE_FAILURE: 'JOURNAL_EMOJIS/DELETE_FAILURE',
  FORM_DESTROY: 'JOURNAL_EMOJIS/FORM_DESTROY',
};

export function journalEmojiListRequest(careGuideId, journalPostId, page, limit, order, filter) {
  Logger.log('debug', `[state.journalEmojis.actions] journalEmojiListRequest(${careGuideId}, ${journalPostId}, ${page}, ${limit}, ${order}, %j)`, filter);
  return {
    type: TYPES.LIST_REQUEST,
    careGuideId: careGuideId,
    journalPostId: journalPostId,
    page: page,
    limit: limit,
    order: order,
    filter: filter,
  }
}

export function journalEmojiListSuccess(careGuideId, journalPostId, data) {
  Logger.log('debug', `[state.journalEmojis.actions] journalEmojiListSuccess(${careGuideId}, ${journalPostId}, %j)`, data);
  return {
    type: TYPES.LIST_SUCCESS,
    careGuideId: careGuideId,
    journalPostId: journalPostId,
    page: data.page,
    limit: data.limit,
    order: data.order,
    result: data.result,
    total: data.total,
    receivedAt: Date.now()
  }
}

export function journalEmojiListFailure(careGuideId, journalPostId, error) {
  Logger.log('debug', `[state.journalEmojis.actions] journalEmojiListFailure(${careGuideId}, ${journalPostId}, %j)`, error);
  return {
    type: TYPES.LIST_FAILURE,
    careGuideId: careGuideId,
    journalPostId: journalPostId,
    error: error
  }
}

export function journalEmojiCreateRequest(careGuideId, journalPostId, data) {
  Logger.log('debug', `[state.journalEmojis.actions] journalEmojiCreateRequest(${careGuideId}, ${journalPostId}, %j)`, data);
  return {
    type: TYPES.CREATE_REQUEST,
    careGuideId: careGuideId,
    journalPostId: journalPostId,
  }
}

export function journalEmojiCreateSuccess(careGuideId, journalPostId, data) {
  Logger.log('debug', `[state.journalEmojis.actions] journalEmojiCreateSuccess(${careGuideId}, ${journalPostId}, %j)`, data);
  return {
    type: TYPES.CREATE_SUCCESS,
    careGuideId: careGuideId,
    journalPostId: journalPostId,
    id: data.id,
    receivedAt: Date.now()
  }
}

export function journalEmojiCreateFailure(careGuideId, journalPostId, error) {
  Logger.log('debug', `[state.journalEmojis.actions] journalEmojiCreateFailure(${careGuideId}, ${journalPostId}, %j)`, error);
  return {
    type: TYPES.CREATE_FAILURE,
    careGuideId: careGuideId,
    journalPostId: journalPostId,
    error: error
  }
}

export function journalEmojiDeleteRequest(careGuideId, journalPostId, id) {
  Logger.log('debug', `[state.journalEmojis.actions] journalEmojiDeleteRequest(${careGuideId}, ${journalPostId}, ${id})`);
  return {
    type: TYPES.DELETE_REQUEST,
    careGuideId: careGuideId,
    journalPostId: journalPostId,
    id: id
  }
}

export function journalEmojiDeleteSuccess(careGuideId, journalPostId, id) {
  Logger.log('debug', `[state.journalEmojis.actions] journalEmojiDeleteSuccess(${careGuideId}, ${journalPostId}, ${id})`);
  return {
    type: TYPES.DELETE_SUCCESS,
    careGuideId: careGuideId,
    journalPostId: journalPostId,
    id: id,
  }
}

export function journalEmojiDeleteFailure(careGuideId, journalPostId, id, error) {
  Logger.log('debug', `[state.journalEmojis.actions] journalEmojiDeleteFailure(${careGuideId}, ${journalPostId}, ${id}, %j)`, error);
  return {
    type: TYPES.DELETE_FAILURE,
    careGuideId: careGuideId,
    journalPostId: journalPostId,
    id: id,
    error: error
  }
}

export function journalEmojiFormDestroy(formState=null) {
  Logger.log('debug', `[state.journalEmojis.actions] journalEmojiFormDestroy(%j)`, formState);
  return {
    type: TYPES.FORM_DESTROY,
    form: formState
  }
}


// API THUNK ACTION CREATORS

export function loadJournalEmojis(careGuideId, journalPostId, page=1, limit=10, order='count.desc', filter=null, cb=function(){}) {
  Logger.log('debug', `[state.journalEmojis.actions] loadJournalEmojis(${careGuideId}, ${journalPostId}, ${page}, ${limit}, ${order}, %j, ###)`, filter);

  return async function(dispatch) {
    dispatch(journalEmojiListRequest(careGuideId, journalPostId, page, limit, order, filter));

    // call API
    const response = await api.getCareGuideJournalPostEmojis(careGuideId, journalPostId, page, limit, order, filter);
    let success = false;

    // get journal emojis list success
    if (200 === response.get('status')) {

      Logger.log('info', `Get API journal emojis list success. Care Guide ID: ${careGuideId}, Post ID: ${journalPostId}, Page: ${page}, Limit: ${limit}, Order: ${order}.`);

      // const normalizedEntities = normalize(response.getIn(['data', 'JOURNAL_EMOJIS']), [schema.journalEmoji]);
      const data = {
        page: response.getIn(['data', 'page']),
        limit: response.getIn(['data', 'limit']),
        order: order,
        total: response.getIn(['data', 'total']),
        result: response.getIn(['data', 'journal_emojis'])
      };

      // dispatch(addEntities(normalizedEntities));
      dispatch(journalEmojiListSuccess(careGuideId, journalPostId, data));
      success = true;

    } else if (1 === page && 204 === response.get('status')) {

      Logger.log('info', `Get API journal emojis list success [empty]. Care Guide ID: ${careGuideId}, Post ID: ${journalPostId}, Page: ${page}, Limit: ${limit}, Order: ${order}.`);
      const data = {
        page: page,
        limit: limit,
        order: order,
        total: 0,
        result: []
      };
      dispatch(journalEmojiListSuccess(careGuideId, journalPostId, data));
      success = true;
      
    // get journal emojis list failure
    } else {
      Logger.log('info', `Get API journal emojis list failure. Care Guide ID: ${careGuideId}, Post ID: ${journalPostId}, Page: ${page}, Limit: ${limit}, Order: ${order}.`);
      dispatch(journalEmojiListFailure(careGuideId, journalPostId, response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  }
}

export function createJournalEmoji(careGuideId, journalPostId, data, cb=function(){}) {
  Logger.log('debug', `[state.journalEmojis.actions] createJournalEmoji(${careGuideId}, ${journalPostId}, %j, ###)`, data);

  return async function(dispatch) {
    dispatch(journalEmojiCreateRequest(careGuideId, journalPostId, data));

    // call API
    const response = await api.postCareGuideJournalPostEmojis(careGuideId, journalPostId, data);
    let success = false;

    // post journal emoji success
    if (201 === response.get('status')) {

      Logger.log('info', `POST API journal emoji success. Care Guide ID: ${careGuideId}, Post ID: ${journalPostId}.`);

      // const normalizedEntities = normalize([response.getIn(['data', 'journal_emoji'])], [schema.journalEmoji]);
      const data = {
        id: response.getIn(['data', 'journal_emoji', 'id']),
      };

      // dispatch(addEntities(normalizedEntities));
      dispatch(journalEmojiCreateSuccess(careGuideId, journalPostId, data));
      success = true;
      
    // post journal emoji failure
    } else {
      Logger.log('info', `POST API journal emoji failure. Care Guide ID: ${careGuideId}, Post ID: ${journalPostId}.`);
      dispatch(journalEmojiCreateFailure(careGuideId, journalPostId, response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  }
}

export function deleteJournalEmoji(careGuideId, journalPostId, id, cb=function(){}) {
  Logger.log('debug', `[state.journalEmojis.actions] deleteJournalEmoji(${careGuideId}, ${journalPostId}, ${id}, ###)`);

  return async function(dispatch) {
    dispatch(journalEmojiDeleteRequest(careGuideId, journalPostId, id));

    // call API
    const response = await api.deleteCareGuideJournalPostEmoji(careGuideId, journalPostId, id);
    let success = false;

    // delete journal emoji success
    if (204 === response.get('status')) {

      Logger.log('info', `DELETE API journal emoji success. Care Guide ID: ${careGuideId}, Post ID: ${journalPostId}, ID: ${id}.`);

      // dispatch(removeEntity({entityType: 'journalEmoji', id: id}));
      dispatch(journalEmojiDeleteSuccess(careGuideId, journalPostId, id));
      success = true;
      
    // get journal emoji failure
    } else {
      Logger.log('info', `DELETE API journal emoji failure. Care Guide ID: ${careGuideId}, Post ID: ${journalPostId}, ID: ${id}.`);
      dispatch(journalEmojiDeleteFailure(careGuideId, journalPostId, id, response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  }
}

Logger.log('silly', `state.journalEmojis.actions loaded.`);
