import {Map} from 'immutable';

import {
  LIST_TIMEZONES_REQUEST,
  LIST_TIMEZONES_SUCCESS,
  LIST_TIMEZONES_FAILURE
} from './actions'
import {SESSION_DESTROY} from '../../actions';
import Logger from '../../../lib/Logger';

const initialState = Map({
  isLoading: false,
  data: {},
});

export default function timezones(
  state=initialState,
  action
) {
  Logger.log('debug', `[state.timezones.reducers] timezones(%j, %j)`, state, action);

  switch(action.type) {

    case LIST_TIMEZONES_REQUEST:
      return state.mergeDeep({
        isLoading: true
      });

    case LIST_TIMEZONES_SUCCESS:
      return state.mergeDeep({
        isLoading: false,
        data: null,
      }).setIn(['data'], action.result);

    case LIST_TIMEZONES_FAILURE:
      return state.mergeDeep({
        isLoading: false
      });
    
    case SESSION_DESTROY:
      return initialState;

    default:
      return state;
  }
}

Logger.log('silly', `state.timezones.reducers loaded.`);
