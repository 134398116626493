import { connect } from 'react-redux';
import { denormalize } from 'normalizr';
import { schema } from '../../../../../state/schema';

import LearningLayout from '../components/LearningLayout';
import {
  setActiveItemId,
  setActiveItem,
} from '../../../../../state/modules/learnings/actions';
import { MinusSquareOutlined } from '@ant-design/icons';

const mapStateToProps = (state, ownProps) => {
  const activeId = state.careGuides.get('activeId');
  const careGuide = activeId
    ? denormalize(
        state.entities.getIn(['careGuides', activeId]),
        schema.careGuide,
        state.entities.toJS()
      )
    : null;

  const page = 1;
  const limit = 25;
  const plan_name = careGuide ? careGuide?.account?.plan?.name : null;
  const results = state.learnings.getIn(['results', limit, page]);
  const activeItemId = state.learnings.get('activeItemId');

  let options = [];
  let list = [];
  list = results
    ? results.toArray().map((x) => {
        let data = {
          key: `${x.id}`,
          value: `${x.id}`,
          title: x.attributes && x.attributes?.Title ? x.attributes.Title : '',
          titleContent:
            x.attributes && x.attributes?.TitleContent
              ? x.attributes.TitleContent
              : null,
          titleMedia:
            x.attributes && x.attributes?.TitleMedia
              ? x.attributes.TitleMedia
              : null,
          content:
            x.attributes && x.attributes?.Content ? x.attributes.Content : null,
          videos:
            x.attributes && x.attributes?.Videos ? x.attributes.Videos : [],
          imageUrl:
            x.attributes && x.attributes?.ImageFile
              ? x.attributes.ImageFile.data?.attributes.url
              : null,
          imageHeight:
            x.attributes && x.attributes?.ImageFile
              ? x.attributes.ImageFile.data?.attributes.height
              : null,
          imageWidth:
            x.attributes && x.attributes?.ImageFile
              ? x.attributes.ImageFile.data?.attributes.width
              : null,
        };

        options.push(data);

        const children =
          x.attributes && x.attributes.subTopics && x.attributes.subTopics.data
            ? x.attributes.subTopics.data.map((y) => {
                let data = {
                  key: `${x.id}-${y.id}`,
                  value: `${x.id}-${y.id}`,
                  title:
                    y.attributes && y.attributes?.TitleSubTopic
                      ? y.attributes.TitleSubTopic
                      : '',
                  titleContent:
                    y.attributes && y.attributes?.TitleContent
                      ? y.attributes.TitleContent
                      : null,
                  titleMedia:
                    y.attributes && y.attributes?.TitleMedia
                      ? y.attributes.TitleMedia
                      : null,
                  content:
                    y.attributes && y.attributes?.Content
                      ? y.attributes.Content
                      : null,
                  videos:
                    y.attributes && y.attributes?.Videos
                      ? y.attributes.Videos
                      : [],
                  imageUrl:
                    y.attributes && y.attributes?.ImageFile
                      ? y.attributes.ImageFile.data?.attributes.url
                      : null,
                  imageHeight:
                    x.attributes && x.attributes?.ImageFile
                      ? x.attributes.ImageFile.data?.attributes.height
                      : null,
                  imageWidth:
                    x.attributes && x.attributes?.ImageFile
                      ? x.attributes.ImageFile.data?.attributes.width
                      : null,
                };
                options.push(data);

                return { switcherIcon: <MinusSquareOutlined />, ...data };
              })
            : [];

        return {
          children: children,
          ...data,
        };
      })
    : [];

  return {
    page: page,
    limit: limit,
    list: list,
    options: options,
    activeItemId: activeItemId,
    isLoading: state.learnings.get('isLoading'),
    careGuideId: activeId,
    plan_name: plan_name,
    isCareGuideLoading: careGuide === undefined ? true : false,
    isCareGuideActive:
      careGuide && careGuide.account && careGuide.account.is_subscription_valid,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setActiveId: (id) => {
      dispatch(setActiveItemId(id));
      dispatch(setActiveItem(null));
    },
  };
};

const LearningLayoutContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(LearningLayout);

export default LearningLayoutContainer;
