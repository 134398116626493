import React from 'react';
import notification from '../../../elements/lib/NotificationWrapper';
import Logger from '../../../../../lib/Logger';
import { withRouter } from 'react-router';
import { Translation, getI18n } from 'react-i18next';
import { Space, Button, Form, Radio } from 'antd';
import { Modal, Typography } from 'antd';

const { Text } = Typography;

const layout = {
  main: {
    labelCol: { span: 5 },
    wrapperCol: { span: 19 },
  },
};

const CareGuideDocumentModalExport = ({
  openModalExport,
  setOpenModalExport,
  allPageCareGuideDocumentIds,
  isDownloading,
  total,
  partnershipName,
  careGuideId,
  download,
  order,
  filter,
  history,
  ...props
}) => {
  const [form] = Form.useForm();

  const onClickOK = () => {
    form.submit();
  };

  // form submit handler
  const handleFinish = async (values) => {
    Logger.log('debug', `careGuideDocumentModalExportForm.handleFinish(###)`);
    if (!isDownloading) {
      await submitData(values);
    }
  };

  // form error handler
  const handleFinishFailed = () => {
    Logger.log(
      'debug',
      `careGuideDocumentModalExportForm.handleFinishFailed(###)`
    );
    notification.error(
      getI18n().t('care_guide_document_message_export_failure')
    );
  };

  const submitData = async (values) => {
    Logger.log('debug', `careGuideDocumentModalExportForm.submitData(###)`);

    const payload = {};
    let orderDownload = order;
    let filterDownload = filter;

    for (var key in values) {
      if (['option-export'].includes(key)) {
        if (values[key] === 1) {
          payload['care_guide_document_ids'] = allPageCareGuideDocumentIds;
        } else if (values[key] === 2) {
          orderDownload = {};
          filterDownload = {};
        }
      }
    }

    if (careGuideId) {
      download(
        partnershipName,
        careGuideId,
        payload,
        orderDownload,
        filterDownload,
        (success) => {
          if (success) {
            notification.success(
              getI18n().t('care_guide_document_message_export_success')
            );
          } else {
            notification.error(
              getI18n().t('care_guide_document_message_export_failure')
            );
          }
        }
      );
    } else {
      notification.error(
        getI18n().t('care_guide_document_message_export_failure')
      );
    }
  };

  return (
    <Translation>
      {(t) => (
        <>
          <Modal
            title={t('care_guide_document_export_title')}
            className="document-modal-export"
            centered
            visible={openModalExport}
            onCancel={() => setOpenModalExport(false)}
            footer={[
              <Button key="back" onClick={() => setOpenModalExport(false)}>
                {t('care_guide_document_export_cancel')}
              </Button>,
              <Button
                key="submit"
                type="primary"
                loading={isDownloading}
                disabled={allPageCareGuideDocumentIds.length < 1 && total < 1}
                onClick={onClickOK}
              >
                {t('care_guide_document_export_documents')}
              </Button>,
            ]}
            forceRender={true}
          >
            <Form
              name="validate_other"
              {...layout.main}
              initialValues={{
                'option-export': 1,
              }}
              form={form}
              onFinish={handleFinish}
              onFinishFailed={handleFinishFailed}
              validateTrigger="onSubmit"
              requiredMark={false}
              layout="vertical"
            >
              <div className="ant-col ant-col-24 ant-form-item-label">
                <label
                  className="ant-form-item-required export-care-guide-owners-options-title"
                  title="options-title"
                >
                  {t('care_guide_document_list_export')}
                </label>
                <Text type="secondary">(required)</Text>
              </div>

              <Form.Item name="option-export">
                <Radio.Group size={'large'}>
                  <Space direction="vertical">
                    <Radio
                      value={1}
                      disabled={allPageCareGuideDocumentIds.length < 1}
                    >
                      {t('care_guide_document_option_current')}
                    </Radio>
                    <Radio value={2} disabled={total < 1}>
                      {t('care_guide_document_export_option_all')}
                    </Radio>
                  </Space>
                </Radio.Group>
              </Form.Item>
              <div style={{ display: 'none' }}>
                <Button htmlType="submit"></Button>
              </div>
            </Form>
          </Modal>
        </>
      )}
    </Translation>
  );
};

export default withRouter(CareGuideDocumentModalExport);
