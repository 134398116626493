import { connect } from 'react-redux';

import { careGuideDocumentShowForm } from '../../../../../state/modules/careGuideDocuments/actions';

import CareGuideDocumentsScreen from '../components/CareGuideDocumentsScreen';

const mapStateToProps = (state, ownProps) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    showForm: (careGuideDocumentId) => {
      dispatch(careGuideDocumentShowForm(careGuideDocumentId));
    },
  };
};

const CareGuideDocumentsScreenContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(CareGuideDocumentsScreen);

export default CareGuideDocumentsScreenContainer;
