import {connect} from 'react-redux';
import JournalPartnerTagsAdminScreen from '../components/JournalPartnerTagsAdminScreen';
import storage from "../../../../../lib/Storage";

const mapStateToProps = state => {
  return {
    total: state.journalPartnerTagsAdmin.get('total'),
    isLoading: state.journalPartnerTagsAdmin.get('isLoading'),
    activeId: storage.get('local', 'partnershipId'),
    access_role_admin_in_app: storage.get('local', 'partnershipData')?.access_role_admin_in_app,

  }
}

const JournalPartnerTagsAdminScreenContainer = connect(
  mapStateToProps
)(JournalPartnerTagsAdminScreen);

export default JournalPartnerTagsAdminScreenContainer;