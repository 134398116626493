import { connect } from 'react-redux';
import { denormalize } from 'normalizr';

import ResourcesList from '../components/ResourcesList';
import { schema } from '../../../../../state/schema';
import {
  loadResources,
  deleteResource,
  resourceShowForm,
  patchResource,
  patchResourceFavorite,
  resourceShowPreviewDetail,
  createResourceInteraction,
} from '../../../../../state/modules/resources/actions';

import { downloadResourceCSVAdmin } from '../../../../../state/modules/resourcesAdmin/actions';
import storage from '../../../../../lib/Storage';
import { resourceNoteShowList } from '../../../../../state/modules/resourceNotes/actions';

const mapStateToProps = (state, ownProps) => {
  const profileId = state.session.get('profileId');
  const careGuideId = ownProps.careGuideId;

  const careGuide = careGuideId
    ? denormalize(
        state.entities.getIn(['careGuides', careGuideId]),
        schema.careGuide,
        state.entities.toJS()
      )
    : null;

  const page = ownProps.page;
  const limit = ownProps.limit;
  const order = ownProps.order;
  const result = state.resources.getIn([
    'results',
    careGuideId,
    'pages',
    order,
    limit,
    page,
  ]);

  return {
    list: result
      ? result
          .map((x) => {
            return {
              key: x,
              ...denormalize(
                state.entities.getIn(['resources', x]),
                schema.resource,
                state.entities.toJS()
              ),
            };
          })
          .toArray()
      : [],
    total: state.resources.getIn(['results', careGuideId, 'total']),
    isLoading: state.resources.get('isLoading'),
    profileId: profileId,
    partnershipId: storage.get('local', 'partnershipId'),
    partnershipName: storage.get('local', 'partnershipData')?.name,
    careGuide,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    load: (careGuideId, page, limit, order, filter, cb) => {
      dispatch(loadResources(careGuideId, page, limit, order, filter, cb));
    },
    remove: (careGuideId, id, cb) => {
      dispatch(deleteResource(careGuideId, id, cb));
    },
    showForm: (id) => {
      dispatch(resourceShowForm(id));
    },
    showList: (resourceId) => {
      dispatch(resourceNoteShowList(resourceId));
    },
    patchFavorite: (careGuideId, id, data, cb) => {
      dispatch(patchResourceFavorite(careGuideId, id, data, cb));
    },
    patch: (careGuideId, id, data, cb) => {
      dispatch(patchResource(careGuideId, id, data, cb));
    },
    showDetail: (careGuideId, resourceId) => {
      dispatch(resourceShowPreviewDetail(careGuideId, resourceId));
    },
    createInteraction: (careGuideId, resourceId, data, cb) => {
      dispatch(createResourceInteraction(careGuideId, resourceId, data, cb));
    },
    download: (partnershipId, partnershipName, resourceId, cb) => {
      dispatch(
        downloadResourceCSVAdmin(partnershipId, partnershipName, resourceId, cb)
      );
    },
  };
};

const ResourcesListContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ResourcesList);

export default ResourcesListContainer;
