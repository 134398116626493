import React, {useState, useRef} from 'react';
import {Translation} from 'react-i18next';
import {Form, Input, Popover, Typography, Space} from 'antd';
import {CheckCircleFilled, CloseCircleOutlined} from '@ant-design/icons';


const reAlphaNumeric = new RegExp('^\\w+$');
const reNotNumber = new RegExp('(?!^\\d+$)^.+$');

const incompleteTextType = 'default';
const incompleteIcon = <CloseCircleOutlined style={{ color: '#00000040' }} />;

const completeTextType = 'secondary';
const completeIcon = <CheckCircleFilled style={{ color: '#52c41a' }} />;

const UserNameInput = ({name, hasError=false, label=null, rules=null, disabled=false, autoFocus=false, useTooltip=true, tooltipPlacement='right', useSuccess=true, ...props}) => {

  const [isVisible, setIsVisible] = useState(false);

  const [isLengthComplete, setIsLengthComplete] = useState(false);
  const [isAlphaNumericComplete, setIsAlphaNumericComplete] = useState(false);
  const [isNotNumberComplete, setIsNotNumberComplete] = useState(false);

  const inputRef = useRef(null);

  const onChange = e => {
    const val = e.target.value;
    
    // check length
    setIsLengthComplete(val.length >= 2 && val.length <= 40);

    // check is alphanumeric
    setIsAlphaNumericComplete(reAlphaNumeric.test(val));

    // check is not a number
    setIsNotNumberComplete(reNotNumber.test(val));
  }

  return (
    <Translation>{(t) =>
      <div className="form-group">
        <Popover
          placement={tooltipPlacement}
          trigger="focus"
          title={null}
          visible={useTooltip && isVisible && !(isLengthComplete && isAlphaNumericComplete && isNotNumberComplete)}
          content={
            <>
              <Space direction="vertical">

                <Typography.Text
                  type={isLengthComplete ? completeTextType : incompleteTextType}
                >
                  {isLengthComplete ? completeIcon : incompleteIcon}{' '}
                  {t('input_requirment_length', {min: 2, max: 40})}
                </Typography.Text>

                <Typography.Text
                  type={isAlphaNumericComplete ? completeTextType : incompleteTextType}
                >
                  {isAlphaNumericComplete ? completeIcon : incompleteIcon}{' '}
                  {t('input_requirment_alpanumeric')}
                </Typography.Text>

                <Typography.Text
                  type={isNotNumberComplete ? completeTextType : incompleteTextType}
                >
                  {isNotNumberComplete ? completeIcon : incompleteIcon}{' '}
                  {t('input_requirment_not_number')}
                </Typography.Text>

              </Space>
            </>
          }
        >
          <Form.Item
            name={name}
            label={label ? label : t('register_input__username')}
            rules={rules
              ? rules
              : [
                  {required: true, message: t('feedback_validation_required')},
                  {min: 2, max: 40, message: t('feedback_validation_length', {min: 2, max: 40})},
                  {pattern: /^\w+$/, message: t('feedback_validation_alphanumeric')},
                  {pattern: /(?!^\d+$)^.+$/, message: t('feedback_validation_not_number')}
                ]}
            // hasFeedback={useSuccess && isLengthComplete && isAlphaNumericComplete && isNotNumberComplete && !hasError}
            hasFeedback={true}
            validateStatus={hasError ? "error" : (useSuccess && isLengthComplete && isAlphaNumericComplete && isNotNumberComplete ? "success" : null)}
          >
            <Input
              disabled={disabled}
              autoFocus={autoFocus}
              onChange={onChange}
              ref={inputRef}
              onFocus={() => setIsVisible(true)}
              onBlur={() => setIsVisible(false)}
            />
          </Form.Item>
        </Popover>
      </div>
    }</Translation>
  )
}

export default UserNameInput;
