import React, { useState } from 'react';
import { getI18n } from 'react-i18next';

import { Button, Cascader } from 'antd';
import { EllipsisOutlined } from '@ant-design/icons';
import { FiEdit } from 'react-icons/fi';
import { withRouter } from 'react-router';
import hasPermission, {
  VIEW_PARTNER,
  VIEW_OWNER,
} from '../../../elements/lib/hasPermission';
import ConfirmationModal from '../../../elements/components/ConfirmationModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBan,
  faThumbtack,
  faCheck,
  faFileArrowDown,
} from '@fortawesome/free-solid-svg-icons';

import { faTrashCan } from '@fortawesome/free-regular-svg-icons';

const confirmTexts = {
  title: getI18n().t('resource_card_action_delete_title'),
  description: null,
  leaveText: getI18n().t('resource_card_action_delete_leave'),
  saveText: getI18n().t('resource_card_action_delete_save'),
};

const ResourceCardActions = ({
  item,
  careGuideId,
  partnershipId,
  handlePinned,
  handleDisabled,
  handleRemove,
  handleExport,
  showForm,
  profileId,
}) => {
  const { is_pinned, id, partnership, profile, status } = item;

  const confirmTextsDisabled = {
    title:
      status === 1
        ? getI18n().t('resource_card_action_disabled_title')
        : getI18n().t('resource_card_action_enabled_title'),
    description: null,
    leaveText:
      status === 1
        ? getI18n().t('resource_card_action_disable_leave')
        : getI18n().t('resource_card_action_enable_leave'),
    saveText: getI18n().t('resource_card_action_disabled_save'),
  };

  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [confirmationModalDisabledOpen, setConfirmationModalDisabledOpen] =
    useState(false);

  const handleConfirmLeave = () => {
    setConfirmationModalOpen(false);
  };

  const handleConfirmDelete = () => {
    handleRemove(careGuideId, item);
    setConfirmationModalOpen(false);
  };

  const handleConfirmLeaveDisabled = () => {
    setConfirmationModalDisabledOpen(false);
  };

  const handleConfirmDisabled = () => {
    handleDisabled(careGuideId, item, status === 1 ? 2 : 1);
    setConfirmationModalDisabledOpen(false);
  };

  const menuItems = [
    {
      label: (
        <span className="resource-table-menu-item">
          <FiEdit />
          <span className="text-align">
            {getI18n().t('resource_card_action_edit')}
          </span>
        </span>
      ),
      value: 1,
      disabled: status !== 1,
      access:
        (hasPermission(VIEW_PARTNER) && partnership?.id) ||
        profile?.id === profileId ||
        hasPermission(VIEW_OWNER),
    },
    {
      label: (
        <span className={'resource-table-menu-item'}>
          {is_pinned ? (
            <FontAwesomeIcon
              icon={faThumbtack}
              size="2x"
              style={{ opacity: 0.5 }}
            />
          ) : (
            <FontAwesomeIcon icon={faThumbtack} size="2x" />
          )}
          <span className="text-align">
            {is_pinned
              ? getI18n().t('resource_card_action_un_pin')
              : getI18n().t('resource_card_action_pinned')}
          </span>
        </span>
      ),
      disabled: status !== 1,
      value: 2,
      access: hasPermission(VIEW_PARTNER) && partnership?.id,
    },
    {
      label: (
        <span className="resource-table-menu-item" style={{ color: '#F44336' }}>
          <FontAwesomeIcon icon={faTrashCan} size="2x" />
          <span className="text-align">
            {getI18n().t('resource_card_action_delete')}
          </span>
        </span>
      ),
      value: 3,
      access:
        (hasPermission(VIEW_PARTNER) && partnership?.id) ||
        profile?.id === profileId ||
        hasPermission(VIEW_OWNER),
    },
    {
      label: (
        <span className="resource-table-menu-item">
          {status === 1 ? (
            <FontAwesomeIcon icon={faBan} size="2x" />
          ) : (
            <FontAwesomeIcon icon={faCheck} size="2x" />
          )}
          <span className="text-align">
            {status === 1
              ? getI18n().t('resource_card_action_disabled')
              : getI18n().t('resource_card_action_enabled')}
          </span>
        </span>
      ),
      value: 4,
      access: hasPermission(VIEW_PARTNER) && partnership?.id,
    },
    {
      label: (
        <span className="resource-table-menu-item">
          <FontAwesomeIcon icon={faFileArrowDown} size="2x" />
          <span className="text-align">
            {getI18n().t('resource_card_action_export_all')}
          </span>
        </span>
      ),
      value: 5,
      access: hasPermission(VIEW_PARTNER) && partnership?.id,
    },
  ];

  const onChange = (option) => {
    const value = option[0];
    if (value === 1) {
      showForm(id);
    } else if (value === 2) {
      handlePinned(careGuideId, item, is_pinned);
    } else if (value === 3) {
      setConfirmationModalOpen(true);
    } else if (value === 4) {
      setConfirmationModalDisabledOpen(true);
    } else if (value === 5) {
      handleExport(partnershipId, item);
    }
  };

  return (
    <span onClick={(e) => e.stopPropagation()} className="actions">
      <Cascader
        options={menuItems.filter((option) => option.access)}
        onChange={onChange}
        dropdownMenuColumnStyle={{ height: '32px' }}
        defaultValue={[]}
        value={[]}
        style={{ height: '26px' }}
      >
        <Button
          style={{ verticalAlign: '2px' }}
          icon={<EllipsisOutlined />}
          type="text"
          onClick={(e) => e.stopPropagation()}
        ></Button>
      </Cascader>
      <ConfirmationModal
        texts={confirmTexts}
        openConfirmationModal={confirmationModalOpen}
        onLeaveConfirmation={handleConfirmDelete}
        onSaveConfirmation={handleConfirmLeave}
        onCancel={handleConfirmLeave}
        setConfirmationModalOpen={setConfirmationModalOpen}
      />
      <ConfirmationModal
        texts={confirmTextsDisabled}
        openConfirmationModal={confirmationModalDisabledOpen}
        onLeaveConfirmation={handleConfirmDisabled}
        onSaveConfirmation={handleConfirmLeaveDisabled}
        onCancel={handleConfirmLeaveDisabled}
        setConfirmationModalOpen={setConfirmationModalDisabledOpen}
      />
    </span>
  );
};

export default withRouter(ResourceCardActions);
