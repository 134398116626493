import {connect} from 'react-redux';
import {List} from 'immutable';
import {denormalize} from 'normalizr';

import {schema} from '../../../../../state/schema';
import {
  patchJournalPost,
  loadJournalPosts,
  journalPostFormDestroy,
  journalPostHideForm
} from '../../../../../state/modules/journalPosts/actions';
import {
  getImageUploadURL,
  uploadImage,
  createMediaImage,
  deleteMediaImage,
} from '../../../../../state/modules/media/actions';
import JournalPostModalForm from '../components/JournalPostModalForm';

const inputs = List([
  'rating',
  'body',
  'tags',
  'media'
]);

const mapStateToProps = (state, ownProps) => {

  const careGuide = ownProps.careGuideId
    ? denormalize(
        state.entities.getIn(['careGuides', ownProps.careGuideId]),
        schema.careGuide,
        state.entities.toJS()
      )
    : null;

  const errors = {};
  for (const val of inputs.values()) {
    if (state.journalPosts.getIn(['form', 'errors', val])) {
      errors[val] = state.journalPosts.getIn(['form', 'errors', val]);
    }
  }

  const postId = state.journalPosts.get('formId');
  const post = postId
    ? denormalize(
        state.entities.getIn(['journalPosts', postId]),
        schema.journalPost,
        state.entities.toJS()
      )
    : null;
  const data = {
    rating: post && post.rating ? post.rating.toString() : '',
    body: post ? post.body : '',
    tags: post ? post.tags.map(x => `${x.id}.tag`).concat(post.partner_tags.map(x => `${x.id}.partner_tag`)) : [],
    partner_tags: post ? post.partner_tags : [],
    media: post ? post.media : [],
  };

  return {
    careGuide: careGuide,
    visible: state.journalPosts.get('formIsVisible'),
    isSubmitting: state.journalPosts.getIn(['form', 'isSubmitting']),
    success: state.journalPosts.getIn(['form', 'success']),
    postId: postId,
    data: data,
    errors: errors,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    patch: (careGuideId, id, data, cb) => {
      dispatch(patchJournalPost(careGuideId, id, data, cb));
    },
    load: (careGuideId, page, limit, order, filter, cb) => {
      dispatch(loadJournalPosts(careGuideId, page, limit, order, filter, cb));
    },
    getUploadUrl: (extension, directory, cb) => {
      dispatch(getImageUploadURL(extension, directory, cb));
    },
    upload: (path, file, cb) => {
      dispatch(uploadImage(path, file, cb));
    },
    createImage: (careGuideId, data, cb) => {
      dispatch(createMediaImage(careGuideId, data, cb));
    },
    deleteImage: (careGuideId, id, cb) => {
      dispatch(deleteMediaImage(careGuideId, id, cb));
    },
    formDestroy: (formState) => {
      dispatch(journalPostFormDestroy(formState));
    },
    hideForm: () => {
      dispatch(journalPostHideForm());
    },
  }
}

const JournalPostModalFormContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(JournalPostModalForm);

export default JournalPostModalFormContainer;
