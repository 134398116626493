import {connect} from 'react-redux';
import {denormalize} from 'normalizr';

import {schema} from '../../../../../state/schema';
import {createCustomer, createSubscription} from '../../../../../state/modules/payments/actions';
import {loadAccount} from '../../../../../state/modules/accounts/actions';
import PaymentRegistrationFlow from '../components/PaymentRegistrationFlow';

const mapStateToProps = (state, ownProps) => {

  // get URL query parameters
  const accountId = 'accountId' in ownProps ? ownProps.accountId : null;
  const registrationId = 'registrationId' in ownProps ? ownProps.registrationId : null;

  // get account from state
  let account = null;
  if (accountId) {
    account = denormalize(
      state.entities.getIn(['accounts', accountId]),
      schema.account,
      state.entities.toJS()
    );
  }

  return {
    intent: state.payments.get('intent'),
    clientSecret: state.payments.get('clientSecret'),
    isSubmittingCustomer: state.payments.get('isSubmittingCustomer'),
    isSubmittingSubscription: state.payments.get('isSubmittingSubscription'),
    accountId: accountId,
    registrationId: registrationId,
    account: account,
    planId: account && account.plan ? account.plan.id : null,
    subscriptionCycle: account ? account.subscription_cycle : null,
    trialPeriod: account && account.plan && account.subscription_cycle
      ? (account.subscription_cycle === 'M' ? account.plan.month_trial_period : account.plan.year_trial_period)
      : null,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    createCustomer: (cb) => {
      dispatch(createCustomer(cb));
    },
    createSubscription: (data, cb) => {
      dispatch(createSubscription(data, cb));
    },
    loadAccount: (id, cb) => {
      dispatch(loadAccount(id, cb));
    },
  }
}

const PaymentRegistrationFlowContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(PaymentRegistrationFlow);

export default PaymentRegistrationFlowContainer;
