import React from 'react';
import {Translation} from 'react-i18next';

const CareGuideDisabled = props => {
  return (
    <Translation>{(t) => 
      <>
        {t('care_guide_disabled')}
      </>
    }</Translation>
  );
}

export default CareGuideDisabled;
