import React, { useState, useCallback, useRef, useEffect } from 'react';
import { getRouteFromPath } from '../../../Routes';
import {
  hasPermissionAdmin,
  pathRedirect,
} from '../../../elements/lib/hasPermissionAdmin';
import { pathTo } from '../../../Routes';
import { Redirect } from 'react-router-dom';
import { Translation } from 'react-i18next';
import DocumentHead from '../../../elements/components/DocumentHead';
import Logger from '../../../../../lib/Logger';
import QueryString from '../../../../../lib/QueryString';
import ResourcePreview from '../containers/ResourcePreviewContainer';
import ResourcesAdminList from '../containers/ResourcesAdminListContainer';
import ResourceNotesModalAdmin from '../../resourceNotes/containers/ResourceNotesModalAdminContainer';
import scrollToTop from '../../../elements/lib/scrollToTop';

const ResourcesAdminScreen = (props) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const prevProps = useRef();

  const [redirect, setRedirect] = useState(null);

  const limit = parseInt(
    props.match.params.limit ? props.match.params.limit : 12
  );

  const page = parseInt(props.match.params.page ? props.match.params.page : 1);

  const { order, search, ...filter } = QueryString.parse(props.location.search);

  const updateSelectedRowKeys = useCallback(
    (newKeys, insert, except) => {
      setSelectedRowKeys((prevSelectedRowKeys) => {
        if (except) {
          return newKeys;
        } else {
          if (insert) {
            return [...prevSelectedRowKeys, newKeys];
          } else {
            const indice = prevSelectedRowKeys.indexOf(newKeys);
            if (indice !== -1) {
              const newSelectedRowKeys = [...prevSelectedRowKeys];
              newSelectedRowKeys.splice(indice, 1);
              return newSelectedRowKeys;
            }
          }
        }
        return prevSelectedRowKeys;
      });
    },
    [setSelectedRowKeys]
  );

  useEffect(() => {
    scrollToTop();
  }, []);

  useEffect(() => {
    prevProps.current = props;
  });

  useEffect(() => {
    Logger.log('silly', `ResourcesAdminScreen.componentDidUpdate()`);
    const route = getRouteFromPath(props.location.pathname);
    const screen = hasPermissionAdmin();

    if (
      prevProps.current &&
      props.partnershipId !== prevProps.current.partnershipId
    ) {
      if (route.screen !== pathRedirect(screen)[0]) {
        setRedirect(pathRedirect(screen)[0]);
      }
    }
  }, [props.location.pathname, props.partnershipId]);

  if (redirect) {
    return <Redirect to={pathTo(redirect)} />;
  }

  return (
    <Translation>
      {(t) => (
        <>
          <DocumentHead title={t('resources_route_list')} />

          <ResourcesAdminList
            key={page}
            component="ResourcesAdminScreen"
            page={page}
            limit={limit}
            view={props.view}
            order={order || 'status.asc'}
            filter={
              search && search !== '' ? { search, ...filter } : { ...filter }
            }
            search={search}
            selectedRowKeys={selectedRowKeys}
            updateSelectedRowKeys={updateSelectedRowKeys}
          />

          <ResourcePreview />
          <ResourceNotesModalAdmin
            partnershipId={props.partnershipId}
            partnershipName={props.partnershipName}
          />
        </>
      )}
    </Translation>
  );
};

export default ResourcesAdminScreen;

Logger.log('silly', `ResourcesAdminScreen loaded.`);
