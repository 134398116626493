import React, { useEffect, useState } from 'react';
import { withRouter } from "react-router";
import { Translation } from 'react-i18next';
import Logger from '../../../../../lib/Logger';
import LoadingIcon from '../../../elements/components/LoadingIcon';
import { Card, Typography, Select, Tree, Empty, TreeSelect } from 'antd';
import { CaretDownOutlined } from '@ant-design/icons';

function LearningTree({ page, limit, total = 0, load, isLoading, list, setActive, activeItemId, plan_name, options, window_width, ...props }) {

  const [selectKey, setSelectKey] = useState([]);
  const [expandKey, setExpandKey] = useState([]);
  const [value, setValue] = useState('');

  useEffect(() => {
    load(plan_name, page, limit);
  }, [plan_name, page, limit, load]);

  const { Title } = Typography;
  const onSelect = (selectedKeys, info) => {

    if (value !== info.node.key) {
      if (selectedKeys.length > 0) {
        setSelectKey(selectedKeys)
        setValue(info.node.key)
        setActive(info.node.key)
      } else {
        setSelectKey(selectedKeys)
        setValue('')
        setActive(null)
      }
    }
  };

  const onExpand = (expandedKeys, info) => {
    setExpandKey(expandedKeys);
  }

  const onChange = (val) => {
    if (val) {
      if (val !== value) {
        const value = val.split("-");
        setValue(val);
        if (value.length === 2) {
          setExpandKey([`${value[0]}`]);
          setSelectKey([`${val}`]);
          setActive(val);
        } else {
          setExpandKey([`${val}`]);
          setSelectKey([`${val}`]);
          setActive(val);
        }
      }
    }
  };

  useEffect(() => {

    if (typeof (activeItemId) === 'string') {
      const value = activeItemId.split("-");
      setValue(activeItemId);
      if (value.length === 2) {
        setExpandKey([`${value[0]}`]);
        setSelectKey([`${activeItemId}`]);
        setActive(activeItemId);
      } else {
        setExpandKey([`${activeItemId}`]);
        setSelectKey([`${activeItemId}`]);
        setActive(activeItemId);
      }

    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeItemId]);

  return (
    <Translation>{(t) =>

      <>
        {window_width > 992 ? <Card title={<Title level={3}>{t('learning_nav_title')}</Title>}
          extra={isLoading ? <LoadingIcon size={16} /> : null}
          className="learning-list-card" style={{ borderRadius: "20px" }}>
          {list.length > 0 ?
            <>
              <Select
                mode={null}
                showSearch
                optionFilterProp="children"
                disabled={isLoading}
                loading={isLoading}
                showArrow={true}
                value={value}
                className='learning-select-card'
                onChange={onChange}
              >

                {options.map(x => <Select.Option key={x.key} value={x.key}>{x.title}</Select.Option>)}

              </Select>

              <Tree
                showLine
                blockNode
                className='learning-tree'
                switcherIcon={<CaretDownOutlined />}
                expandedKeys={expandKey}
                selectedKeys={selectKey}
                onExpand={onExpand}
                onSelect={onSelect}
                treeData={list}
              />
            </>
            : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          }

        </Card> :


          <div className='learning-tree-select-card'>

            <TreeSelect
              showSearch
              style={{
                width: '100%',
              }}
              className='learning-tree-select'
              switcherIcon={<CaretDownOutlined />}
              treeLine={true}
              value={value}
              dropdownStyle={{
                maxHeight: 400,
                overflow: 'auto',
              }}
              placeholder="Please select"
              allowClear
              onChange={onChange}
              treeData={list}
            />
          </div>
        }
      </>

    }</Translation>
  )
}

export default withRouter(LearningTree);

Logger.log('silly', `LearningTree loaded.`);
