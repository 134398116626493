import {connect} from 'react-redux';
import {denormalize} from 'normalizr';
import moment from 'moment';

import JournalPostsList from '../components/JournalPostsList';
import {schema} from '../../../../../state/schema';
import {loadJournalPosts} from '../../../../../state/modules/journalPosts/actions';

const mapStateToProps = (state, ownProps) => {

  const careGuideId = ownProps.careGuideId;
  const page = ownProps.page;
  const limit = ownProps.limit;
  const order = ownProps.order;
  const result = state.journalPosts.getIn(['results', careGuideId, 'pages', order, limit, page]);

  return {
    list: result 
      ? result.map(x => {
          return {
            key: x,
            ...denormalize(
              state.entities.getIn(['journalPosts', x]),
              schema.journalPost,
              state.entities.toJS()
            )
          };
        }).toArray().map(x => {

          // flatten some nested objects to make table easier to work with
          return {
            profile_name: x.profile ? x.profile.first_name + ' ' + x.profile.last_name : '',
            ...x
          };
        })
      : [],
    total: state.journalPosts.getIn(['results', careGuideId, 'total']),
    isLoading: state.journalPosts.get('isLoading'),
    profileId: state.session.get('profileId'),
  }
}

const mapDispatchToProps = dispatch => {
  return {
    load: (careGuideId, page, limit, order, filter, cb) => {
      dispatch(loadJournalPosts(careGuideId, page, limit, order, {tz: moment().utcOffset()/60, ...filter}, cb));
    },
  }
}

const JournalPostsListContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(JournalPostsList);

export default JournalPostsListContainer;
