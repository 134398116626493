import {normalize} from 'normalizr';

import api from '../../api';
import {schema} from '../../schema';
import {addEntities} from '../../actions';
import Logger from '../../../lib/Logger';

export const RELATIONSHIP_LIST_REQUEST = 'RELATIONSHIP_LIST_REQUEST';
export const RELATIONSHIP_LIST_SUCCESS = 'RELATIONSHIP_LIST_SUCCESS';
export const RELATIONSHIP_LIST_FAILURE = 'RELATIONSHIP_LIST_FAILURE';

export function relationshipListRequest(page, limit) {
  Logger.log('debug', `[state.relationships.actions] relationshipListRequest(${page}, ${limit})`);
  return {
    type: RELATIONSHIP_LIST_REQUEST,
    page: page,
    limit: limit,
  }
}

export function relationshipListSuccess(data) {
  Logger.log('debug', `[state.relationships.actions] relationshipListSuccess(%j)`, data);
  return {
    type: RELATIONSHIP_LIST_SUCCESS,
    page: data.page,
    limit: data.limit,
    order: data.order,
    result: data.result,
    total: data.total,
    receivedAt: Date.now()
  }
}

export function relationshipListFailure(error) {
  Logger.log('debug', `[state.relationships.actions] relationshipListFailure(%j)`, error);
  return {
    type: RELATIONSHIP_LIST_FAILURE,
    error: error
  }
}


// API THUNK ACTION CREATORS

export function loadRelationships(page=1, limit=50, order=null, filter=null, cb=function(){}) {
  Logger.log('debug', `[state.relationships.actions] loadRelationships(${page}, ${limit}, ${order}, %j, ###)`, filter);

  return async function(dispatch) {
    dispatch(relationshipListRequest(page, limit, order, filter));

    // call API
    const response = await api.getRelationships(page, limit, order, filter);
    let success = false;

    // get relationships list success
    if (200 === response.get('status')) {

      Logger.log('info', `Get API relationships list success. Page: ${page}, Limit: ${limit}, Order: ${order}.`);

      const normalizedEntities = normalize(response.getIn(['data', 'relationships']), [schema.relationship]);
      const data = {
        page: response.getIn(['data', 'page']),
        limit: response.getIn(['data', 'limit']),
        order: order,
        total: response.getIn(['data', 'total']),
        result: normalizedEntities.result
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(relationshipListSuccess(data));
      success = true;

    } else if (1 === page && 204 === response.get('status')) {

      Logger.log('info', `Get API relationships list success [empty]. Page: ${page}, Limit: ${limit}, Order: ${order}.`);
      const data = {
        page: page,
        limit: limit,
        order: order,
        total: 0,
        result: []
      };
      dispatch(relationshipListSuccess(data));
      success = true;
      
    // get relationships list failure
    } else {
      Logger.log('info', `Get API relationships list failure. Page: ${page}, Limit: ${limit}, Order: ${order}.`);
      dispatch(relationshipListFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  }
}

Logger.log('silly', `state.relationships.actions loaded.`);
