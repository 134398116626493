import React, {useEffect} from 'react';
import {Translation, getI18n} from 'react-i18next';
import {Button, List} from 'antd';
import {DeleteOutlined} from '@ant-design/icons';

import {pathTo} from '../../../Routes';
import message from '../../../elements/lib/MessageWrapper';
import Config from '../../../../../Config';
import Logger from '../../../../../lib/Logger';


const RegisterStep3List = ({careGuideId, component, page, limit, order, total, load, ...props}) => {

  useEffect(() => {
    load(careGuideId, page, limit, order);
  }, [careGuideId, page, limit, order, load]);

  const onClickDelete = (id) => {
    props.remove(careGuideId, id, (success) => {
      if (success) {
        message.success(getI18n().t('register_step3_invite_delete_feedback_success'));
        load(careGuideId, page, limit, order);
      } else {
        message.error(getI18n().t('register_step3_invite_delete_feedback_failure'));
      }
    });
  }

  const onClickDone = () => {
    props.history.push(pathTo(Config.get('DEFAULT_LOGIN_REDIRECT')));
  }

  return (
    <Translation>{(t) => 
      <>
        {props.list && props.list.length > 0
          ? <div className="registration-step3-helper-list">

              <div className="list-actions">
                <Button type="primary" onClick={onClickDone}>{t('action_done')}</Button>
              </div>

              <h3>{t('register_step3_list_title')}</h3>

              <List
                size="small"
                itemLayout="horizontal"
                dataSource={props.list}
                renderItem={item => (
                  <List.Item
                    key={item.id}
                    actions={[
                      <Button type="text" icon={<DeleteOutlined />} onClick={() => onClickDelete(item.id)} />
                    ]}
                  >

                    <List.Item.Meta
                      title={item.invitation ? item.invitation.email : null}
                      description={item.role ? t('care_guide_helpers_role_' + item.role) : null}
                    />

                  </List.Item>
                )}
              />

            </div>
          : null}
      </>
    }</Translation>
  )  
}

export default RegisterStep3List;

Logger.log('silly', `RegisterStep3List loaded.`);
