import { connect } from 'react-redux';

import ResourceAdminAddScreen from '../components/ResourceAdminAddScreen';

const mapStateToProps = (state) => {
  return {
    isLoading: state.resourcesAdmin.get('isLoading'),
    success: state.resourcesAdmin.getIn(['form', 'success']),
  };
};

const ResourceAdminAddScreenContainer = connect(mapStateToProps)(
  ResourceAdminAddScreen
);

export default ResourceAdminAddScreenContainer;
