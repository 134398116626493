import { Map } from "immutable";

import {
  UI_CHANGE_MENU_COLLAPSED,
  UI_SET_ROUTING_PREVENT_TRANSITION,
  UI_CHANGE_MENU_ADMIN_COLLAPSED,
} from "./actions";
import Logger from "../../lib/Logger";

export default function ui(
  state = Map({
    isMenuCollapsed: false,
    isRoutingTransitionPrevented: false,
    isMenuAdminCollapsed: false,
  }),
  action
) {
  Logger.log("debug", `[state.ui.reducers] ui(%j, %j)`, state, action);

  switch (action.type) {
    case UI_CHANGE_MENU_COLLAPSED:
      return state.mergeDeep({
        isMenuCollapsed: !state.get("isMenuCollapsed"),
      });

    case UI_SET_ROUTING_PREVENT_TRANSITION:
      return state.mergeDeep({
        isRoutingTransitionPrevented: action.prevent,
      });

    case UI_CHANGE_MENU_ADMIN_COLLAPSED:
      return state.mergeDeep({
        isMenuAdminCollapsed: !state.get("isMenuAdminCollapsed"),
      });
    default:
      return state;
  }
}

Logger.log("silly", `state.ui.reducers loaded.`);
