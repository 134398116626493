import React, {useEffect} from 'react';
import {Translation, getI18n} from 'react-i18next';
import {Button, Checkbox, Form, Input, Modal, Popconfirm, Tooltip} from 'antd';
import {CheckCircleOutlined, CloseCircleOutlined, DeleteOutlined, QuestionCircleOutlined} from '@ant-design/icons';

import RoleInput from './RoleInput';
import TagInput from '../../careGuideHelperTags/containers/TagInputContainer';
import message from '../../../elements/lib/MessageWrapper';
import Logger from '../../../../../lib/Logger';


const CareGuideHelperForm = ({careGuideId, helperId, page, limit, order, errors, isLoading, isSubmitting, data, ...props}) => {

  const [form] = Form.useForm();

  // handle errors reported by API
  useEffect(() => {
    let firstFieldName = '';
    for (const field in errors) {
      form.setFields([{name: field, errors: errors[field]}]);
      if (firstFieldName === '') {
        firstFieldName = field;
      }
    }
    form.scrollToField(firstFieldName);
  }, [form, errors]);

  useEffect(() => {
    form.setFieldsValue(data);
  }, [helperId, form]); // eslint-disable-line react-hooks/exhaustive-deps

  // submit data handler
  const submitData = async (values) => {
    Logger.log('debug', `CareGuideHelperForm.submitData(###)`);

    const payload = {};

    // transform values
    for (var key in values) {

      // transform public_status
      if (['public_status'].includes(key)) {
        payload[key] = values[key] ? 1 : 2;
      }

      else {
        payload[key] = values[key];
      }
    }

    if (helperId) { // update
      props.update(careGuideId, helperId, payload, (success) => {
        if (success) {
          message.success(getI18n().t('feedback_form_success'));
          clearForm();
          props.load(careGuideId, page, limit, order);
        } else {
          message.error(getI18n().t('feedback_form_error'));
        }
      });
    } else { // create
      // props.create(careGuideId, payload, (success) => {
      //   if (success) {
      //     message.success(getI18n().t('feedback_form_success'));
      //     clearForm();
      //     props.load(careGuideId, page, limit, order);
      //   } else {
      //     message.error(getI18n().t('feedback_form_error'));
      //   }
      // });
    }
  }

  const clearForm = () => {
    props.hideForm();
    props.formDestroy();
    form.setFieldsValue({role: null, public_status: null, note: ''});
    for (const key of Object.keys(data)) {
      form.setFields([{name: key, errors: []}]);
    }
  }

  // form submit handler
  const handleFinish = async (values) => {
    Logger.log('debug', `CareGuideHelperForm.handleFinish(###)`);
    if (!props.isSubmitting) {
      await submitData(values);
    }
  }

  // form error handler
  const handleFinishFailed = ({values, errorFields, outOfDate}) => {
    Logger.log('debug', `CareGuideHelperForm.handleFinishFailed(###)`);
    message.error(getI18n().t('feedback_form_error'));
    if (errorFields && errorFields.length > 0) {
      form.scrollToField(errorFields[0].name);
    }
  }

  // remove error message when input value changes
  const handleValuesChange = (changedValues, allValues) => {
    for (const key of Object.keys(changedValues)) {
      form.setFields([{name: key, errors: []}]);
    }
  }

  const onClickOK = () => {
    form.submit();
  }

  const onClickDelete = () => {
    props.delete(careGuideId, helperId, (success) => {
      if (success) {
        message.success(getI18n().t('feedback_delete_success'));
        clearForm();
        props.load(careGuideId, page, limit, order);
      } else {
        message.error(getI18n().t('feedback_delete_error'));
      }
    });
  }

  return (
    <Translation>{(t) => 
      <>
        <Modal
          title={props.profileName ? props.profileName : t('care_guide_helper_form_title_create')}
          centered
          visible={props.visible}
          onOk={onClickOK}
          onCancel={clearForm}
          footer={[
            <Button key="close" icon={<CloseCircleOutlined />} onClick={clearForm}>
              {t('action_close')}
            </Button>,
            helperId
              ? <Popconfirm
                  key="delete-confirm"
                  placement="top"
                  title={t('care_guide_helper_form_delete_confirm_body')}
                  onConfirm={onClickDelete}
                  okText={t('confirm_yes')}
                  cancelText={t('confirm_cancel')}
                >
                  <Button key="delete" danger icon={<DeleteOutlined />}>
                    {t('action_delete')}
                  </Button>
                </Popconfirm>
              : null,
            <Button key="submit" type="primary" icon={<CheckCircleOutlined />} loading={isSubmitting} onClick={onClickOK}>
              {t('care_guide_helper_form_button_submit')}
            </Button>,
          ]}
          width={500}
          forceRender={true}
        >
          <div className="care-guide-helper-form">
            <Form
              name="care_guide_helper_form"
              form={form}
              initialValues={data}
              onFinish={handleFinish}
              onFinishFailed={handleFinishFailed}
              onValuesChange={handleValuesChange}
              validateTrigger="onSubmit"
              requiredMark={false}
              layout="vertical"
            >

              <RoleInput
                name="role"
                label={
                  <span>
                    {t('care_guide_helpers_role')}&nbsp;
                    <Tooltip title={t('care_guide_helpers_tooltip_role')}>
                      <QuestionCircleOutlined />
                    </Tooltip>
                  </span>
                }
                disabled={isLoading || isSubmitting}
              />

              <div className="form-group">
                <Form.Item
                  name="note"
                  label={t('care_guide_helper_form_input_note')}
                  rules={[
                    {type: 'string', min: 0, max: 250, message: t('feedback_validation_char_range', {min: 0, max: 250})},
                  ]}
                >
                  <Input.TextArea autoFocus disabled={isLoading || isSubmitting} />
                </Form.Item>
              </div>

              <TagInput
                name="tags"
                label={t('care_guide_helper_form_input_tags')}
              />

              <div className="form-group">
                <Form.Item
                  name="public_status"
                  label={null}
                  valuePropName="checked"
                  extra={t('care_guide_helpers_hint_public_status_1')}
                >
                  <Checkbox
                    disabled={isLoading || isSubmitting}
                  >{t('care_guide_helpers_public_status_1')}</Checkbox>
                </Form.Item>
              </div>

            </Form>
          </div>
        </Modal>
      </>
    }</Translation>
  );
}

export default CareGuideHelperForm;

Logger.log('silly', `CareGuideHelperForm loaded.`);
