import {normalize} from 'normalizr';

import api from '../../api';
import {schema} from '../../schema';
import {addEntities, removeEntity} from '../../actions';
import Logger from '../../../lib/Logger';

export const TYPES = {
  LIST_INBOX_REQUEST: 'MESSAGES/LIST_INBOX_REQUEST',
  LIST_INBOX_SUCCESS: 'MESSAGES/LIST_INBOX_SUCCESS',
  LIST_INBOX_FAILURE: 'MESSAGES/LIST_INBOX_FAILURE',
  LIST_DRAFTS_REQUEST: 'MESSAGES/LIST_DRAFTS_REQUEST',
  LIST_DRAFTS_SUCCESS: 'MESSAGES/LIST_DRAFTS_SUCCESS',
  LIST_DRAFTS_FAILURE: 'MESSAGES/LIST_DRAFTS_FAILURE',
  LIST_SENT_REQUEST: 'MESSAGES/LIST_SENT_REQUEST',
  LIST_SENT_SUCCESS: 'MESSAGES/LIST_SENT_SUCCESS',
  LIST_SENT_FAILURE: 'MESSAGES/LIST_SENT_FAILURE',
  LIST_ARCHIVED_REQUEST: 'MESSAGES/LIST_ARCHIVED_REQUEST',
  LIST_ARCHIVED_SUCCESS: 'MESSAGES/LIST_ARCHIVED_SUCCESS',
  LIST_ARCHIVED_FAILURE: 'MESSAGES/LIST_ARCHIVED_FAILURE',
  LIST_DELETED_REQUEST: 'MESSAGES/LIST_DELETED_REQUEST',
  LIST_DELETED_SUCCESS: 'MESSAGES/LIST_DELETED_SUCCESS',
  LIST_DELETED_FAILURE: 'MESSAGES/LIST_DELETED_FAILURE',
  SEND_REQUEST: 'MESSAGES/SEND_REQUEST',
  SEND_SUCCESS: 'MESSAGES/SEND_SUCCESS',
  SEND_FAILURE: 'MESSAGES/SEND_FAILURE',
  PATCH_REQUEST: 'MESSAGES/PATCH_REQUEST',
  PATCH_SUCCESS: 'MESSAGES/PATCH_SUCCESS',
  PATCH_FAILURE: 'MESSAGES/PATCH_FAILURE',
  PATCH_RECIPIENT_REQUEST: 'MESSAGES/PATCH_RECIPIENT_REQUEST',
  PATCH_RECIPIENT_SUCCESS: 'MESSAGES/PATCH_RECIPIENT_SUCCESS',
  PATCH_RECIPIENT_FAILURE: 'MESSAGES/PATCH_RECIPIENT_FAILURE',
  FORM_DESTROY: 'MESSAGES/FORM_DESTROY',
  SHOW_COMPOSE_FORM: 'MESSAGES/SHOW_COMPOSE_FORM',
  HIDE_COMPOSE_FORM: 'MESSAGES/HIDE_COMPOSE_FORM',
  SAVE_DRAFT_REQUEST: 'MESSAGES/SAVE_DRAFT_REQUEST',
  SAVE_DRAFT_SUCCESS: 'MESSAGES/SAVE_DRAFT_SUCCESS',
  SAVE_DRAFT_FAILURE: 'MESSAGES/SAVE_DRAFT_FAILURE',
  DELETE_REQUEST: 'MESSAGES/DELETE_REQUEST',
  DELETE_SUCCESS: 'MESSAGES/DELETE_SUCCESS',
  DELETE_FAILURE: 'MESSAGES/DELETE_FAILURE',
  COUNTS_REQUEST: 'MESSAGES/COUNTS_REQUEST',
  COUNTS_SUCCESS: 'MESSAGES/COUNTS_SUCCESS',
  COUNTS_FAILURE: 'MESSAGES/COUNTS_FAILURE',
  COMPOSE_CHAT:'MESSAGES/COMPOSE_CHAT',
};

export function messageListInboxRequest(page, limit, order, filter) {
  Logger.log('debug', `[state.messages.actions] messageListInboxRequest(${page}, ${limit}, ${order}, %j)`, filter);
  return {
    type: TYPES.LIST_INBOX_REQUEST,
    page: page,
    limit: limit,
    order: order,
    filter: filter,
  }
}

export function messageListInboxSuccess(data) {
  Logger.log('debug', `[state.messages.actions] messageListInboxSuccess(%j)`, data);
  return {
    type: TYPES.LIST_INBOX_SUCCESS,
    page: data.page,
    limit: data.limit,
    order: data.order,
    result: data.result,
    total: data.total,
    receivedAt: Date.now()
  }
}

export function messageListInboxFailure(error) {
  Logger.log('debug', `[state.messages.actions] messageListInboxFailure(%j)`, error);
  return {
    type: TYPES.LIST_INBOX_FAILURE,
    error: error
  }
}

export function messageListDraftsRequest(page, limit, order, filter) {
  Logger.log('debug', `[state.messages.actions] messageListDraftsRequest(${page}, ${limit}, ${order}, %j)`, filter);
  return {
    type: TYPES.LIST_DRAFTS_REQUEST,
    page: page,
    limit: limit,
    order: order,
    filter: filter,
  }
}

export function messageListDraftsSuccess(data) {
  Logger.log('debug', `[state.messages.actions] messageListDraftsSuccess(%j)`, data);
  return {
    type: TYPES.LIST_DRAFTS_SUCCESS,
    page: data.page,
    limit: data.limit,
    order: data.order,
    result: data.result,
    total: data.total,
    receivedAt: Date.now()
  }
}

export function messageListDraftsFailure(error) {
  Logger.log('debug', `[state.messages.actions] messageListDraftsFailure(%j)`, error);
  return {
    type: TYPES.LIST_DRAFTS_FAILURE,
    error: error
  }
}

export function messageListSentRequest(page, limit, order, filter) {
  Logger.log('debug', `[state.messages.actions] messageListSentRequest(${page}, ${limit}, ${order}, %j)`, filter);
  return {
    type: TYPES.LIST_SENT_REQUEST,
    page: page,
    limit: limit,
    order: order,
    filter: filter,
  }
}

export function messageListSentSuccess(data) {
  Logger.log('debug', `[state.messages.actions] messageListSentSuccess(%j)`, data);
  return {
    type: TYPES.LIST_SENT_SUCCESS,
    page: data.page,
    limit: data.limit,
    order: data.order,
    result: data.result,
    total: data.total,
    receivedAt: Date.now()
  }
}

export function messageListSentFailure(error) {
  Logger.log('debug', `[state.messages.actions] messageListSentFailure(%j)`, error);
  return {
    type: TYPES.LIST_SENT_FAILURE,
    error: error
  }
}

export function messageListArchivedRequest(page, limit, order, filter) {
  Logger.log('debug', `[state.messages.actions] messageListArchivedRequest(${page}, ${limit}, ${order}, %j)`, filter);
  return {
    type: TYPES.LIST_ARCHIVED_REQUEST,
    page: page,
    limit: limit,
    order: order,
    filter: filter,
  }
}

export function messageListArchivedSuccess(data) {
  Logger.log('debug', `[state.messages.actions] messageListArchivedSuccess(%j)`, data);
  return {
    type: TYPES.LIST_ARCHIVED_SUCCESS,
    page: data.page,
    limit: data.limit,
    order: data.order,
    result: data.result,
    total: data.total,
    receivedAt: Date.now()
  }
}

export function messageListArchivedFailure(error) {
  Logger.log('debug', `[state.messages.actions] messageListArchivedFailure(%j)`, error);
  return {
    type: TYPES.LIST_ARCHIVED_FAILURE,
    error: error
  }
}

export function messageListDeletedRequest(page, limit, order, filter) {
  Logger.log('debug', `[state.messages.actions] messageListDeletedRequest(${page}, ${limit}, ${order}, %j)`, filter);
  return {
    type: TYPES.LIST_DELETED_REQUEST,
    page: page,
    limit: limit,
    order: order,
    filter: filter,
  }
}

export function messageListDeletedSuccess(data) {
  Logger.log('debug', `[state.messages.actions] messageListDeletedSuccess(%j)`, data);
  return {
    type: TYPES.LIST_DELETED_SUCCESS,
    page: data.page,
    limit: data.limit,
    order: data.order,
    result: data.result,
    total: data.total,
    receivedAt: Date.now()
  }
}

export function messageListDeletedFailure(error) {
  Logger.log('debug', `[state.messages.actions] messageListDeletedFailure(%j)`, error);
  return {
    type: TYPES.LIST_DELETED_FAILURE,
    error: error
  }
}

export function messageSendRequest(messageId, data) {
  Logger.log('debug', `[state.messages.actions] messageSendRequest(${messageId}, %j)`, data);
  return {
    type: TYPES.SEND_REQUEST,
    messageId: messageId,
  }
}

export function messageSendSuccess(messageId, data) {
  Logger.log('debug', `[state.messages.actions] messageSendSuccess(${messageId}, %j)`, data);
  return {
    type: TYPES.SEND_SUCCESS,
    messageId: messageId,
    id: data.id,
    receivedAt: Date.now()
  }
}

export function messageSendFailure(error) {
  Logger.log('debug', `[state.messages.actions] messageSendFailure(%j)`, error);
  return {
    type: TYPES.SEND_FAILURE,
    error: error
  }
}

export function messagePatchRequest(messageId, data) {
  Logger.log('debug', `[state.messages.actions] messagePatchRequest(${messageId}, %j)`, data);
  return {
    type: TYPES.PATCH_REQUEST,
    messageId: messageId,
  }
}

export function messagePatchSuccess(messageId, data) {
  Logger.log('debug', `[state.messages.actions] messagePatchSuccess(${messageId}, %j)`, data);
  return {
    type: TYPES.PATCH_SUCCESS,
    messageId: messageId,
    receivedAt: Date.now()
  }
}

export function messagePatchFailure(messageId, error) {
  Logger.log('debug', `[state.messages.actions] messagePatchFailure(${messageId}, %j)`, error);
  return {
    type: TYPES.PATCH_FAILURE,
    messageId: messageId,
    error: error
  }
}

export function messageRecipientPatchRequest(messageId, id, data) {
  Logger.log('debug', `[state.messages.actions] messageRecipientPatchRequest(${messageId}, ${id}, %j)`, data);
  return {
    type: TYPES.PATCH_RECIPIENT_REQUEST,
    messageId: messageId,
    id: id,
  }
}

export function messageRecipientPatchSuccess(messageId, id, data) {
  Logger.log('debug', `[state.messages.actions] messageRecipientPatchSuccess(${messageId}, ${id}, %j)`, data);
  return {
    type: TYPES.PATCH_RECIPIENT_SUCCESS,
    messageId: messageId,
    id: id,
    receivedAt: Date.now()
  }
}

export function messageRecipientPatchFailure(messageId, id, error) {
  Logger.log('debug', `[state.messages.actions] messageRecipientPatchFailure(${messageId}, ${id}, %j)`, error);
  return {
    type: TYPES.PATCH_RECIPIENT_FAILURE,
    messageId: messageId,
    id: id,
    error: error
  }
}

export function messageFormDestroy(formState=null) {
  Logger.log('debug', `[state.messages.actions] messageFormDestroy(%j)`, formState);
  return {
    type: TYPES.FORM_DESTROY,
    form: formState
  }
}

export function messageShowComposeForm(threadId=null, message=null, recipientIds=[]) {
  Logger.log('debug', `[state.messages.actions] messageShowComposeForm()`);
  return {
    type: TYPES.SHOW_COMPOSE_FORM,
    threadId: threadId,
    message: message,
    recipientIds: recipientIds,
  }
}

export function messageComposeChat(threadId=null, message=null, recipientIds=[]) {
  Logger.log('debug', `[state.messages.actions] messageChat()`);
  return {
    type: TYPES.COMPOSE_CHAT,
    threadId: threadId,
    message: message,
    recipientIds: recipientIds,
  }
}

export function messageHideComposeForm() {
  Logger.log('debug', `[state.messages.actions] messageHideComposeForm()`);
  return {
    type: TYPES.HIDE_COMPOSE_FORM,
  }
}

export function messageSaveDraftRequest(messageId, data) {
  Logger.log('debug', `[state.messages.actions] messageSaveDraftRequest(${messageId}, %j)`, data);
  return {
    type: TYPES.SAVE_DRAFT_REQUEST,
    messageId: messageId,
  }
}

export function messageSaveDraftSuccess(messageId, data) {
  Logger.log('debug', `[state.messages.actions] messageSaveDraftSuccess(${messageId}, %j)`, data);
  return {
    type: TYPES.SAVE_DRAFT_SUCCESS,
    messageId: messageId,
    receivedAt: Date.now()
  }
}

export function messageSaveDraftFailure(messageId, error) {
  Logger.log('debug', `[state.messages.actions] messageSaveDraftFailure(${messageId}, %j)`, error);
  return {
    type: TYPES.SAVE_DRAFT_FAILURE,
    messageId: messageId,
    error: error
  }
}

export function messageDeleteRequest(messageId) {
  Logger.log('debug', `[state.messages.actions] messageDeleteRequest(${messageId})`);
  return {
    type: TYPES.DELETE_REQUEST,
    messageId: messageId
  }
}

export function messageDeleteSuccess(messageId) {
  Logger.log('debug', `[state.messages.actions] messageDeleteSuccess(${messageId})`);
  return {
    type: TYPES.DELETE_SUCCESS,
    messageId: messageId,
  }
}

export function messageDeleteFailure(error) {
  Logger.log('debug', `[state.messages.actions] messageDeleteFailure(%j)`, error);
  return {
    type: TYPES.DELETE_FAILURE,
    error: error
  }
}

export function messageCountsRequest() {
  Logger.log('debug', `[state.messages.actions] messageCountsRequest()`);
  return {
    type: TYPES.COUNTS_FAILURE,
  }
}

export function messageCountsSuccess(data) {
  Logger.log('debug', `[state.messages.actions] messageCountsSuccess(%j)`, data);
  return {
    type: TYPES.COUNTS_SUCCESS,
    unread: data.unread,
    receivedAt: Date.now()
  }
}

export function messageCountsFailure(error) {
  Logger.log('debug', `[state.messages.actions] messageCountsFailure(%j)`, error);
  return {
    type: TYPES.COUNTS_FAILURE,
    error: error
  }
}


// API THUNK ACTION CREATORS

export function loadMessagesInbox(page=1, limit=10, order=null, filter=null, cb=function(){}) {
  Logger.log('debug', `[state.messages.actions] loadMessagesInbox(${page}, ${limit}, ${order}, %j, ###)`, filter);

  return async function(dispatch) {
    dispatch(messageListInboxRequest(page, limit, order, filter));

    // call API
    const response = await api.getMessagesInbox(page, limit, order, filter);
    let success = false;

    // get messages inbox list success
    if (200 === response.get('status')) {

      Logger.log('info', `Get API messages inbox list success. Page: ${page}, Limit: ${limit}, Order: ${order}.`);

      const normalizedEntities = normalize(response.getIn(['data', 'message_threads']), [schema.messageThread]);
      const data = {
        page: response.getIn(['data', 'page']),
        limit: response.getIn(['data', 'limit']),
        order: order,
        total: response.getIn(['data', 'total']),
        result: normalizedEntities.result
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(messageListInboxSuccess(data));
      success = true;

    } else if (1 === page && 204 === response.get('status')) {

      Logger.log('info', `Get API messages inbox list success [empty]. Page: ${page}, Limit: ${limit}, Order: ${order}.`);
      const data = {
        page: page,
        limit: limit,
        order: order,
        total: 0,
        result: []
      };
      dispatch(messageListInboxSuccess(data));
      success = true;
      
    // get messages inbox list failure
    } else {
      Logger.log('info', `Get API messages inbox list failure. Page: ${page}, Limit: ${limit}, Order: ${order}.`);
      dispatch(messageListInboxFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  }
}

export function loadMessagesDrafts(page=1, limit=10, order=null, filter=null, cb=function(){}) {
  Logger.log('debug', `[state.messages.actions] loadMessagesDrafts(${page}, ${limit}, ${order}, %j, ###)`, filter);

  return async function(dispatch) {
    dispatch(messageListDraftsRequest(page, limit, order, filter));

    // call API
    const response = await api.getMessagesDrafts(page, limit, order, filter);
    let success = false;

    // get messages drafts list success
    if (200 === response.get('status')) {

      Logger.log('info', `Get API messages drafts list success. Page: ${page}, Limit: ${limit}, Order: ${order}.`);

      const normalizedEntities = normalize(response.getIn(['data', 'messages']), [schema.message]);
      const data = {
        page: response.getIn(['data', 'page']),
        limit: response.getIn(['data', 'limit']),
        order: order,
        total: response.getIn(['data', 'total']),
        result: normalizedEntities.result
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(messageListDraftsSuccess(data));
      success = true;

    } else if (1 === page && 204 === response.get('status')) {

      Logger.log('info', `Get API messages drafts list success [empty]. Page: ${page}, Limit: ${limit}, Order: ${order}.`);
      const data = {
        page: page,
        limit: limit,
        order: order,
        total: 0,
        result: []
      };
      dispatch(messageListDraftsSuccess(data));
      success = true;
      
    // get messages drafts list failure
    } else {
      Logger.log('info', `Get API messages drafts list failure. Page: ${page}, Limit: ${limit}, Order: ${order}.`);
      dispatch(messageListDraftsFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  }
}

export function loadMessagesSent(page=1, limit=10, order=null, filter=null, cb=function(){}) {
  Logger.log('debug', `[state.messages.actions] loadMessagesSent(${page}, ${limit}, ${order}, %j, ###)`, filter);

  return async function(dispatch) {
    dispatch(messageListSentRequest(page, limit, order, filter));

    // call API
    const response = await api.getMessagesSent(page, limit, order, filter);
    let success = false;

    // get messages sent list success
    if (200 === response.get('status')) {

      Logger.log('info', `Get API messages sent list success. Page: ${page}, Limit: ${limit}, Order: ${order}.`);

      const normalizedEntities = normalize(response.getIn(['data', 'message_threads']), [schema.messageThread]);
      const data = {
        page: response.getIn(['data', 'page']),
        limit: response.getIn(['data', 'limit']),
        order: order,
        total: response.getIn(['data', 'total']),
        result: normalizedEntities.result
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(messageListSentSuccess(data));
      success = true;

    } else if (1 === page && 204 === response.get('status')) {

      Logger.log('info', `Get API messages sent list success [empty]. Page: ${page}, Limit: ${limit}, Order: ${order}.`);
      const data = {
        page: page,
        limit: limit,
        order: order,
        total: 0,
        result: []
      };
      dispatch(messageListSentSuccess(data));
      success = true;
      
    // get messages sent list failure
    } else {
      Logger.log('info', `Get API messages sent list failure. Page: ${page}, Limit: ${limit}, Order: ${order}.`);
      dispatch(messageListSentFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  }
}

export function loadMessagesArchived(page=1, limit=10, order=null, filter=null, cb=function(){}) {
  Logger.log('debug', `[state.messages.actions] loadMessagesArchived(${page}, ${limit}, ${order}, %j, ###)`, filter);

  return async function(dispatch) {
    dispatch(messageListArchivedRequest(page, limit, order, filter));

    // call API
    const response = await api.getMessagesArchived(page, limit, order, filter);
    let success = false;

    // get messages archived list success
    if (200 === response.get('status')) {

      Logger.log('info', `Get API messages archived list success. Page: ${page}, Limit: ${limit}, Order: ${order}.`);

      const normalizedEntities = normalize(response.getIn(['data', 'message_threads']), [schema.messageThread]);
      const data = {
        page: response.getIn(['data', 'page']),
        limit: response.getIn(['data', 'limit']),
        order: order,
        total: response.getIn(['data', 'total']),
        result: normalizedEntities.result
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(messageListArchivedSuccess(data));
      success = true;

    } else if (1 === page && 204 === response.get('status')) {

      Logger.log('info', `Get API messages archived list success [empty]. Page: ${page}, Limit: ${limit}, Order: ${order}.`);
      const data = {
        page: page,
        limit: limit,
        order: order,
        total: 0,
        result: []
      };
      dispatch(messageListArchivedSuccess(data));
      success = true;
      
    // get messages archived list failure
    } else {
      Logger.log('info', `Get API messages archived list failure. Page: ${page}, Limit: ${limit}, Order: ${order}.`);
      dispatch(messageListArchivedFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  }
}

export function loadMessagesDeleted(page=1, limit=10, order=null, filter=null, cb=function(){}) {
  Logger.log('debug', `[state.messages.actions] loadMessagesDeleted(${page}, ${limit}, ${order}, %j, ###)`, filter);

  return async function(dispatch) {
    dispatch(messageListDeletedRequest(page, limit, order, filter));

    // call API
    const response = await api.getMessagesDeleted(page, limit, order, filter);
    let success = false;

    // get messages deleted list success
    if (200 === response.get('status')) {

      Logger.log('info', `Get API messages deleted list success. Page: ${page}, Limit: ${limit}, Order: ${order}.`);

      const normalizedEntities = normalize(response.getIn(['data', 'message_threads']), [schema.messageThread]);
      const data = {
        page: response.getIn(['data', 'page']),
        limit: response.getIn(['data', 'limit']),
        order: order,
        total: response.getIn(['data', 'total']),
        result: normalizedEntities.result
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(messageListDeletedSuccess(data));
      success = true;

    } else if (1 === page && 204 === response.get('status')) {

      Logger.log('info', `Get API messages deleted list success [empty]. Page: ${page}, Limit: ${limit}, Order: ${order}.`);
      const data = {
        page: page,
        limit: limit,
        order: order,
        total: 0,
        result: []
      };
      dispatch(messageListDeletedSuccess(data));
      success = true;
      
    // get messages deleted list failure
    } else {
      Logger.log('info', `Get API messages deleted list failure. Page: ${page}, Limit: ${limit}, Order: ${order}.`);
      dispatch(messageListDeletedFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  }
}

export function sendMessage(messageId=null, data=null, cb=function(){}) {
  Logger.log('debug', `[state.messages.actions] sendMessage(${messageId}, %j, ###)`, data);

  return async function(dispatch) {
    dispatch(messageSendRequest(messageId, data));
    let response = null
    let success = false;

    // send a new message
    if (!messageId) {

      // call API
      response = await api.postMessages(data);
      if (201 === response.get('status')) {
        success = true;
      }

    // send from an existing message (a draft)
    } else {

      // call API
      response = await api.putMessage(messageId, data);
      if (200 === response.get('status')) {
        success = true;
      }
    }

    // send message success
    if (success) {

      Logger.log('info', `Send API message success. Message ID: ${messageId}.`);

      const normalizedEntities = normalize([response.getIn(['data', 'message'])], [schema.message]);
      const data = {
        id: response.getIn(['data', 'message', 'id']),
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(messageSendSuccess(messageId, data));

    // send message failure
    } else {
      Logger.log('info', `Send API message failure. Message ID: ${messageId}.`);
      dispatch(messageSendFailure(messageId, response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  }
}

export function patchMessage(messageId, data, cb=function(){}) {
  Logger.log('debug', `[state.messages.actions] patchMessage(${messageId}, %j, ###)`, data);

  return async function(dispatch) {
    dispatch(messagePatchRequest(messageId, data));

    // call API
    const response = await api.patchMessage(messageId, data);
    let success = false;

    // patch message  success
    if (200 === response.get('status')) {

      Logger.log('info', `PATCH API message  success. Message ID: ${messageId}.`);

      const normalizedEntities = normalize([response.getIn(['data', 'message'])], [schema.message]);
      const data = {
        id: response.getIn(['data', 'message', 'id']),
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(messagePatchSuccess(messageId, data));
      success = true;
      
    // patch message failure
    } else {
      Logger.log('info', `PATCH API message failure. Message ID: ${messageId}.`);
      dispatch(messagePatchFailure(messageId, response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  }
}

export function patchMessageRecipient(messageId, id, data, cb=function(){}) {
  Logger.log('debug', `[state.messages.actions] patchMessageRecipient(${messageId}, ${id}, %j, ###)`, data);

  return async function(dispatch) {
    dispatch(messageRecipientPatchRequest(messageId, id, data));

    // call API
    const response = await api.patchMessageRecipient(messageId, id, data);
    let success = false;

    // patch message recipient success
    if (200 === response.get('status')) {

      Logger.log('info', `PATCH API message recipient success. Message ID: ${messageId}, ID: ${id}.`);

      const normalizedEntities = normalize([response.getIn(['data', 'message_recipient'])], [schema.messageRecipient]);
      const data = {
        id: response.getIn(['data', 'message_recipient', 'id']),
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(messageRecipientPatchSuccess(messageId, id, data));
      success = true;
      
    // patch message recipient failure
    } else {
      Logger.log('info', `PATCH API message recipient failure. Message ID: ${messageId}, ID: ${id}.`);
      dispatch(messageRecipientPatchFailure(messageId, id, response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  }
}

export function saveMessageDraft(messageId=null, data=null, cb=function(){}) {
  Logger.log('debug', `[state.messages.actions] saveMessageDraft(${messageId}, %j, ###)`, data);

  return async function(dispatch) {
    dispatch(messageSaveDraftRequest(messageId, data));
    let response = null;
    let success = false;

    // save draft of new message
    if (!messageId) {

      // call API
      response = await api.postMessages(data);
      if (201 === response.get('status')) {
        success = true;
      }

    // save draft of existing message
    } else {

      // call API
      response = await api.putMessage(messageId, data);
      if (200 === response.get('status')) {
        success = true;
      }
    }

    // save message draft success
    if (success) {

      Logger.log('info', `Save API message draft success. Message ID: ${messageId}.`);

      const normalizedEntities = normalize([response.getIn(['data', 'message'])], [schema.message]);
      const data = {
        id: response.getIn(['data', 'message', 'id']),
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(messageSaveDraftSuccess(messageId, data));

    // save message draft failure
    } else {
      Logger.log('info', `Save API message draft failure. Message ID: ${messageId}.`);
      dispatch(messageSaveDraftFailure(messageId, response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  }
}

export function deleteMessage(messageId, cb=function(){}) {
  Logger.log('debug', `[state.messages.actions] deleteMessage(${messageId}, ###)`);

  return async function(dispatch) {
    dispatch(messageDeleteRequest(messageId));

    // call API
    const response = await api.deleteMessage(messageId);
    let success = false;

    // delete message success
    if (204 === response.get('status')) {

      Logger.log('info', `DELETE API message success. ID: ${messageId}.`);

      dispatch(removeEntity({entityType: 'messages', id: messageId}));
      dispatch(messageDeleteSuccess(messageId));
      success = true;
      
    // get message failure
    } else {
      Logger.log('info', `DELETE API message failure. ID: ${messageId}.`);
      dispatch(messageDeleteFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  }
}

export function loadMessagesCounts(cb=function(){}) {
  Logger.log('debug', `[state.messages.actions] loadMessagesCounts(###)`);

  return async function(dispatch) {
    dispatch(messageCountsRequest());

    // call API
    const response = await api.getMessagesCounts();
    let success = false;

    // get message counts success
    if (200 === response.get('status')) {

      Logger.log('info', `Get API message counts success.`);

      const data = {
        unread: response.getIn(['data', 'unread']),
      };

      dispatch(messageCountsSuccess(data));
      success = true;

    // get message counts failure
    } else {
      Logger.log('info', `Get API message counts failure.`);
      dispatch(messageCountsFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  }
}

Logger.log('silly', `state.messages.actions loaded.`);
