import { connect } from 'react-redux';
import { denormalize } from 'normalizr';

import { schema } from '../../../../../state/schema';
import { loadAdminPartnerClassPartnershipRegister } from '../../../../../state/modules/adminPartnerClasses/actions';
import TagInput from '../components/TagInput';

const mapStateToProps = (state, ownProps) => {

  const partnershipId = ownProps.partnershipId;
  const result = state.adminPartnerClasses.getIn(['adminPartnerClasses', partnershipId]);
  let nameClasses = [];
  const adminPartnerClasses = result
    ? result.map(x => {
      return {
        key: x,
        ...denormalize(
          state.entities.getIn(['adminPartnerClasses', x]),
          schema.adminPartnerClass,
          state.entities.toJS()
        )
      };
    }).toArray().map(x => {
      nameClasses.push(x.name);
      return {
        ...x
      };
    })
    : [];

  return {
    options: adminPartnerClasses,
    isLoading: state.adminPartnerClasses.get('isLoading'),
    nameClasses: nameClasses,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    load: (partnershipId, cb) => {
      dispatch(loadAdminPartnerClassPartnershipRegister(partnershipId, cb));
    },
  }
}

const TagInputContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(TagInput);

export default TagInputContainer;
