import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { Translation } from 'react-i18next';
import { Col, PageHeader, Row } from 'antd';

import DocumentHead from '../../../elements/components/DocumentHead';
import PaymentRegistrationFlow from '../containers/PaymentRegistrationFlowContainer';
import scrollToTop from '../../../elements/lib/scrollToTop';
import QueryString from '../../../../../lib/QueryString';
import { pathTo } from '../../../Routes';
import Logger from '../../../../../lib/Logger';
import imageLogo from '../../../assets/images/logo-300x300.png';

import '../styles/Payments.less';

class PaymentRegistrationScreen extends Component {
  render() {
    // get URL query parameters
    const params = QueryString.parse(this.props.location.search);
    const accountId = 'aid' in params ? params.aid : null;
    const registrationId = 'rid' in params ? params.rid : null;

    if (!accountId || !registrationId) {
      return <Redirect to={pathTo('DashboardScreen')} />;
    }

    return (
      <Translation>
        {(t) => (
          <div className="screen screen-public screen-register">
            <DocumentHead title={t('register_route_step3')} />

            <PageHeader
              onBack={() =>
                this.props.history.push(pathTo('RegisterStep1Screen'))
              }
              title={t('register_screen_step3_page_title')}
              subTitle={t('register_screen_form3_header')}
            />

            <div className="logo">
              <img
                src={imageLogo}
                height="42"
                alt={t('app_name')}
                className=""
              />
            </div>

            <div className="container-form-register">
              <Row type="flex" justify="space-around" align="middle">
                <Col xs={22} sm={20} md={16} lg={14} xl={12}>
                  <h1>{t('register_screen_step3_title')}</h1>
                  <h2>{t('register_screen_step3_subtitle')}</h2>

                  <PaymentRegistrationFlow
                    accountId={accountId}
                    registrationId={registrationId}
                  />
                </Col>
              </Row>
            </div>
          </div>
        )}
      </Translation>
    );
  }

  /* LIFECYCLE EVENTS */

  componentDidMount() {
    scrollToTop();
  }
}

export default PaymentRegistrationScreen;

Logger.log('silly', `PaymentRegistrationScreen loaded.`);
