import {connect} from 'react-redux';

import {updateCustomer} from '../../../../../state/modules/payments/actions';
import CheckoutForm from '../components/CheckoutForm';

const mapStateToProps = (state, ownProps) => {
  return {}
}

const mapDispatchToProps = dispatch => {
  return {
    updateCustomer: (data, cb) => {
      dispatch(updateCustomer(data, cb));
    },
  }
}

const CheckoutFormContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(CheckoutForm);

export default CheckoutFormContainer;
