import React, { useState } from "react";
import { Translation } from "react-i18next";
import {
  Button,
  Checkbox,
  Col,
  Collapse,
  Drawer,
  Input,
  Row,
  Space,
  Tag,
} from "antd";

import { BiFilterAlt } from "react-icons/bi";
import { IoClose } from "react-icons/io5";
import Title from "antd/lib/typography/Title";

const DEFAULT_FILTERS = {
  class_tags: {},
  account_subscription_status: [],
};

const FILTER_CATEGORIES = {
  SUBSCRIPTION: "account_subscription_status",
};

const MembersListFilters = ({
  filters,
  onSearch,
  appliedFilters,
  applyFilters,
  clearFilters,
}) => {
  const filterIds = filters.map((filter) => filter.id);
  const [showFilters, setShowFilters] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState(appliedFilters);

  const allCareTags = filters.reduce((acc, filter) => {
    acc.push(...filter.care_guide_partner_tag);
    return acc;
  }, []);

  function getArrayFilterElements(filterType) {
    return filterType.map((filter) => {
      return allCareTags.find((tag) => filter === tag.value);
    });
  }

  function getObjectFilterElements(filterType) {
    return getfilterTypeElements(Object.entries(filterType));
  }

  const currentFilters = Object.values(appliedFilters).reduce(
    (acc, filterType) => {
      const filterElements = Array.isArray(filterType)
        ? getArrayFilterElements(filterType)
        : getObjectFilterElements(filterType);
      return [...acc, ...filterElements];
    },
    []
  );

  function getfilterTypeElements(data) {
    return data.reduce((acc, [key, value]) => {
      if (value.length) {
        const filter = allCareTags.filter(
          (tag) => value.includes(tag.value) && tag.category === key
        );
        return [...acc, ...filter];
      }
      return acc;
    }, []);
  }

  const onFilterSelect = (value, filter) => {
    setSelectedFilters((prevSelectedFilters) => {
      const isSubscriptionFilter =
        filter.filterKey === FILTER_CATEGORIES.SUBSCRIPTION;

      if (value.length === 0) {
        return {
          ...prevSelectedFilters,
          [filter.filterKey]: isSubscriptionFilter ? [] : {},
        };
      }
      return {
        ...prevSelectedFilters,
        [filter.filterKey]: isSubscriptionFilter
          ? value
          : {
              ...prevSelectedFilters[filter.filterKey],
              [filter.name]: value,
            },
      };
    });
  };

  const removeTag = (tag) => () => {
    if (currentFilters.length === 1) {
      clearFilters();
      setSelectedFilters(DEFAULT_FILTERS);
      return;
    }

    const isSubscriptionFilter =
      tag.filterKey === FILTER_CATEGORIES.SUBSCRIPTION;

    const categorytags = isSubscriptionFilter
      ? selectedFilters[tag.filterKey]
      : selectedFilters[tag.filterKey][tag.category];
    const newTags = categorytags.filter((t) => t !== tag.value);
    let newSelectedFilters = { ...selectedFilters };

    if (!!newTags.length) {
      newSelectedFilters[tag.filterKey] = isSubscriptionFilter
        ? newTags
        : {
            ...selectedFilters[tag.filterKey],
            [tag.category]: newTags,
          };
    } else {
      newSelectedFilters[tag.filterKey] = isSubscriptionFilter
        ? []
        : {
            ...selectedFilters[tag.filterKey],
            [tag.category]: [],
          };
    }
    saveFilters(newSelectedFilters);
    setSelectedFilters(newSelectedFilters);
  };

  const getCheckboxvalue = (filterKey, name) => {
    if (filterKey === FILTER_CATEGORIES.SUBSCRIPTION)
      return selectedFilters[filterKey];

    return selectedFilters[filterKey][name];
  };

  const saveFilters = (filters) => {
    const formattedFilters = Object.entries(filters).reduce(
      (acc, [key, value]) => {
        
        if (Object.values(value).length > 0) {

            Object.keys(value).forEach(x => {
              if(value[x].length < 1){
                delete value[x];
              }
            });
            acc[key] = value;
        }
        return acc;
      },
      {}
    );

    applyFilters(formattedFilters);
    showFilters && setShowFilters(false);
  };

  const deleteFilters = () => {
    setSelectedFilters(DEFAULT_FILTERS);
    clearFilters();
  };

  return (
    <Translation>
      {(t) => (
        <>
          <div className="members-list-search-wrapper">
            <Input.Search
              enterButton
              onSearch={onSearch}
              placeholder={t("members_list_search")}
              size="large"
              allowClear
            />
            <Button
              size="large"
              className="members-list-filters-button"
              onClick={() => setShowFilters(true)}
            >
              <BiFilterAlt />
              <span style={{ color: "black" }}>
                {t("members_list_add_filters")}
              </span>
            </Button>
            {currentFilters.length > 0 && (
              <Button
                size="large"
                onClick={deleteFilters}
                className="members-list-filters-button clear-filters"
              >
                <IoClose />
                <span>{t("members_list_clear_filters")}</span>
              </Button>
            )}
          </div>
          {currentFilters.length > 0 && (
            <Col className="filters-applied-layout">
              <Row>
                <Title level={5}>{t("members_list_filters_applied")}</Title>
              </Row>
              <Row>
                <Space size={12}>
                  {currentFilters.map((filter) => (
                    <Tag
                      key={filter.id}
                      color={filter.color_hex}
                      className="action-filter-tag"
                    >
                      {filter.tagText}
                      <Button type="link" onClick={removeTag(filter)}>
                        <IoClose />
                      </Button>
                    </Tag>
                  ))}
                </Space>
              </Row>
            </Col>
          )}
          <Drawer
            className="members-list-filters-drawer"
            title={t("members_list_members_filters")}
            destroyOnClose
            placement="right"
            width={548}
            visible={showFilters}
            onClose={() => setShowFilters(false)}
            footer={
              <Row wrap={false}>
                <Button
                  type="primary"
                  style={{ width: "100%" }}
                  onClick={() => saveFilters(selectedFilters)}
                >
                  {t("members_list_apply_filters")}
                </Button>
                <Button
                  style={{ width: "100%" }}
                  onClick={() => setShowFilters(false)}
                >
                  {t("members_list_cancel")}
                </Button>
              </Row>
            }
          >
            <Collapse ghost activeKey={filterIds} expandIconPosition="right">
              {filters.map((filter) => (
                <Collapse.Panel
                  key={filter.id}
                  header={filter.name}
                  className="filter-category"
                >
                  <Checkbox.Group
                    name={filter.name}
                    onChange={(value) => onFilterSelect(value, filter)}
                    options={filter.care_guide_partner_tag}
                    defaultValue={appliedFilters[filter.name]}
                    value={getCheckboxvalue(filter.filterKey, filter.name)}
                  />
                </Collapse.Panel>
              ))}
            </Collapse>
          </Drawer>
        </>
      )}
    </Translation>
  );
};

export default MembersListFilters;
