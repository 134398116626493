import {connect} from 'react-redux';

import JournalPartnerTagAdminEditScreen from '../components/JournalPartnerTagAdminEditScreen';

const mapStateToProps = state => {
  return {
    isLoading: state.journalPartnerTagsAdmin.get('isLoading'),
    success: state.journalPartnerTagsAdmin.getIn(['form', 'success']),
  }
}

const JournalPartnerTagAdminEditScreenContainer = connect(
  mapStateToProps
)(JournalPartnerTagAdminEditScreen);

export default JournalPartnerTagAdminEditScreenContainer;