import {connect} from 'react-redux';
import {denormalize} from 'normalizr';

import {schema} from '../../../../../state/schema';
import {loadCareGuideMembers} from '../../../../../state/modules/careGuideHelpers/actions';
import MemberInput from '../components/MemberInput';

const mapStateToProps = (state, {includeSelf=true, ...ownProps}) => {

  const careGuideId = ownProps.careGuideId;
  const page = ownProps.page || 1;
  const limit = ownProps.limit || 50;
  const order = ownProps.order || 'created_at.desc';

  const profileId = state.session.get('profileId');
  const result = state.careGuideHelpers.getIn(['members', careGuideId, 'pages', order, limit, page]);
  let members = result
    ? result.map(x => {
        return {
          key: x,
          ...denormalize(
            state.entities.getIn(['careGuideMembers', x]),
            schema.careGuideMember,
            state.entities.toJS()
          )
        };
      }).toArray()
    : [];

  if (!includeSelf) {
    members = members.filter(x => !x.profile || x.profile.id !== profileId);
  }

  return {
    options: members.map(x => {
      return {label: x.profile.first_name + ' ' + x.profile.last_name, value: x.profile.id, role: x.role, profileId: x.profile.id};
    }),
    isLoading: state.journalTags.get('areMembersLoading'),
    profileId: profileId
  }
}

const mapDispatchToProps = dispatch => {
  return {
    load: (careGuideId, page, limit, order, filter, cb) => {
      dispatch(loadCareGuideMembers(careGuideId, page, limit, order, filter, cb));
    },
  }
}

const MemberInputContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(MemberInput);

export default MemberInputContainer;
