import React from 'react';
import {Translation, getI18n} from 'react-i18next';
import {Form, Input} from 'antd';

import CareGuideContentEditor from './CareGuideContentEditor';
// import FieldTypeTableEditor from './FieldTypeTableEditor2';
// import FieldTypeTableEditor from './FieldTypeTableEditor';
import FieldTypeTableEditor from './FieldTypeTableEditor3';
import FieldTypeADLTableEditor from './FieldTypeADLTableEditor';
import FieldTypeMedTableEditor from './FieldTypeMedTableEditor';


const SegmentContentInput = ({content, index, remove, form, defaultContentType, inputRef=null, ...props}) => {

  const contentType = defaultContentType;

  // input column settings
  const layout = {
    main: {
      wrapperCol: {span: 24},
    },
  }

  // prep content input based on type
  var contentInput = null;
  var contentEditor = null;
  var contentStyle = null;
  switch(contentType) {
    case 1:
      contentInput = <Input placeholder="Content" />;
      break;
    case 2:
      contentInput = <Input.TextArea placeholder="Content" rows={4} />;
      break;
    case 3:
      contentStyle = {display: 'none'};
      contentInput = <Input type="hidden" />;
      contentEditor = 
        <CareGuideContentEditor
          onChange={(contents, delta, source, editor) => {
            form.setFields([{
              name: [`contents`, content.name, `content`],
              value: editor.getHTML()
            }]);
          }}
          defaultValue={form.getFieldValue([`contents`, content.name, `content`])}
          placeholder={getI18n().t('care_guides_form_placeholder_wysiwyg')}
          editorRef={inputRef}
        />;
      break;
    case 4:
      contentStyle = null;
      contentInput =
        <FieldTypeTableEditor
          form={form}
          content={content}
          contentsPath={[`contents`, content.name]}
        />;
      break;
    case 5:
      contentInput =
        <FieldTypeADLTableEditor
          form={form}
          content={content}
          contentsPath={[`contents`, content.name]}
        />;
      break;
    case 6:
      contentInput =
        <FieldTypeMedTableEditor
          form={form}
          content={content}
          contentsPath={[`contents`, content.name]}
          submitCallbackRef={props.submitCallbackRef}
        />;
      break;
    default:
  }

  return (
    <Translation>{(t) => 
      <>
        <Form.Item
          {...content}
          name={[content.name, 'content']}
          fieldKey={[content.fieldKey, 'content']}
          style={contentStyle}
          {...layout.main}
        >
          {contentInput}
        </Form.Item>
        {contentEditor}
      </>
    }</Translation>
  )
}

export default SegmentContentInput;
