import { connect } from 'react-redux';
import { withRouter } from "react-router";

import LearningTree from '../components/LearningTree';
import {
  loadTopics,
  setActiveItemId,
  setActiveItem
} from '../../../../../state/modules/learnings/actions';

const mapStateToProps = (state, ownProps) => {

  const page = ownProps.page;
  const limit = ownProps.limit;
  const plan_name = ownProps.plan_name;
  const list = ownProps.list;
  const options = ownProps.options;  
  const activeItemId = state.learnings.get('activeItemId');

  return {
    activeItemId: activeItemId,
    page: page,
    limit: limit,
    list: list,
    options: options,
    total: state.learnings.get('total'),
    isLoading: state.learnings.get('isLoading'),
    plan_name: plan_name,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    load: (plan_name, page, limit, cb) => {
      dispatch(loadTopics(plan_name, page, limit, cb));
    },
    setActive: (id) => {
      dispatch(setActiveItemId(id));
      dispatch(setActiveItem(null));
    },
  }
}

const LearningTreeContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(LearningTree);

export default withRouter(LearningTreeContainer);
