import React, {useEffect} from 'react';
import {Translation, getI18n} from 'react-i18next';
import {Button, Form, Input, Modal, Popconfirm, Tooltip} from 'antd';
import {
  CloseCircleOutlined,
  CheckCircleOutlined,
  DeleteOutlined,
  QuestionCircleOutlined,
  RedoOutlined
} from '@ant-design/icons';

import {pathTo} from '../../../Routes';
import RoleInput from '../../careGuideHelpers/components/RoleInput';
import TagInput from '../../careGuideHelperTags/containers/TagInputContainer';
import message from '../../../elements/lib/MessageWrapper';
import Logger from '../../../../../lib/Logger';


const CareGuideInvitationForm = ({careGuideId, helperId, invitationId, page, limit, order, data, errors, load, destroyForm, isLoading, isSubmitting, isResending, ...props}) => {

  const [form] = Form.useForm();

  // handle errors reported by API
  useEffect(() => {
    let firstFieldName = '';
    for (const field in errors) {
      form.setFields([{name: field, errors: errors[field]}]);
      if (firstFieldName === '') {
        firstFieldName = field;
      }
    }
    form.scrollToField(firstFieldName);
  }, [form, errors]);

  useEffect(() => {
    form.setFieldsValue(data);
  }, [helperId, form]); // eslint-disable-line react-hooks/exhaustive-deps

  // focus on first input when showing form
  useEffect(() => {
    const firstInputField = form.getFieldInstance('email');
    if (props.visible && firstInputField) {
      // using timeout because there seems to be a timing issue conflicting with focus, maybe the modal
      setTimeout(() => firstInputField.focus(), 250);
    }
  }, [form, props.visible]);

  // submit data handler
  const submitData = async (values) => {
    Logger.log('debug', `CareGuideInvitationForm.submitData(###)`);

    const payload = {};

    // transform values
    for (var key in values) {

      payload[key] = values[key];

      // set empty string to null
      if (['email'].includes(key) && payload[key] === '') {
        payload[key] = null;
      }
    }

    // create URL templates for invitation
    payload['url_template_register'] = window.location.href.split('/').slice(0, 3).join('/')
      + pathTo('RegisterStep1Screen') + '?invite_code={invite_code}&invite_id={invite_id}';
    payload['url_template_accept'] = window.location.href.split('/').slice(0, 3).join('/')
      + pathTo('CareGuideInvitationAccept', {invitationId: '000'}).replace(/000/, '{invite_id}') + '?invite_code={invite_code}';

    if (invitationId) { // update
      props.patch(careGuideId, invitationId, payload, (success) => {
        if (success) {
          message.success(getI18n().t('feedback_form_success'));
          clearForm();
          if (page && limit) {
            load(careGuideId, page, limit, order);
          }
        } else {
          message.error(getI18n().t('feedback_form_error'));
        }
      });
    } else { // create
      props.create(careGuideId, payload, (success) => {
        if (success) {
          message.success(getI18n().t('feedback_form_success'));
          clearForm();
          if (page && limit) {
            load(careGuideId, page, limit, order);
          }
        } else {
          message.error(getI18n().t('feedback_form_error'));
        }
      });
    }
  }

  const clearForm = () => {
    props.hideForm();
    props.formDestroy();
    form.setFieldsValue({email: '', role: 2});
    for (const key of Object.keys(data)) {
      form.setFields([{name: key, errors: []}]);
    }
  }

  // form submit handler
  const handleFinish = async (values) => {
    Logger.log('debug', `CareGuideInvitationForm.handleFinish(###)`);
    if (!props.isSubmitting) {
      await submitData(values);
    }
  }

  // form error handler
  const handleFinishFailed = ({values, errorFields, outOfDate}) => {
    Logger.log('debug', `CareGuideInvitationForm.handleFinishFailed(###)`);
    message.error(getI18n().t('feedback_form_error'));
    if (errorFields && errorFields.length > 0) {
      form.scrollToField(errorFields[0].name);
    }
  }

  // remove error message when input value changes
  const handleValuesChange = (changedValues, allValues) => {
    for (const key of Object.keys(changedValues)) {
      form.setFields([{name: key, errors: []}]);
    }
  }

  const onClickOK = () => {
    form.submit();
  }

  const onClickDelete = () => {
    props.delete(careGuideId, invitationId, (success) => {
      if (success) {
        message.success(getI18n().t('feedback_delete_success'));
        clearForm();
        load(careGuideId, page, limit, order);
      } else {
        message.error(getI18n().t('feedback_delete_error'));
      }
    });
  }

  const onClickResend = () => {

    const payload = {};

    // create URL templates for invitation
    payload['url_template_register'] = window.location.href.split('/').slice(0, 3).join('/')
      + pathTo('RegisterStep1Screen') + '?invite_code={invite_code}&invite_id={invite_id}';
    payload['url_template_accept'] = window.location.href.split('/').slice(0, 3).join('/')
      + pathTo('CareGuideInvitationAccept', {invitationId: '000'}).replace(/000/, '{invite_id}') + '?invite_code={invite_code}';

    props.resend(careGuideId, invitationId, payload, (success) => {
      if (success) {
        message.success(getI18n().t('care_guide_invitations_resend_success'));
        clearForm();
        load(careGuideId, page, limit, order);
      } else {
        message.error(getI18n().t('care_guide_invitations_resend_error'));
      }
    });
  }

  return (
    <Translation>{(t) => 
      <>
        <Modal
          title={props.email ? props.email : t('care_guide_invitations_form_create_title')}
          centered
          visible={props.visible}
          onOk={onClickOK}
          onCancel={clearForm}
          footer={[
            <Button key="close" icon={<CloseCircleOutlined />} onClick={clearForm}>
              {t('action_close')}
            </Button>,
            helperId
              ? <Button key="resend" icon={<RedoOutlined />} onClick={onClickResend} loading={isResending}>
                  {t('care_guide_invitations_button_resend')}
                </Button>
              : null,
            helperId
              ? <Popconfirm
                  key="delete-confirm"
                  placement="top"
                  title={t('care_guide_invitations_delete_confirm_body')}
                  onConfirm={onClickDelete}
                  okText={t('confirm_yes')}
                  cancelText={t('confirm_cancel')}
                >
                  <Button key="delete" danger icon={<DeleteOutlined />}>
                    {t('action_delete')}
                  </Button>
                </Popconfirm>
              : null,
            <Button
              key="submit"
              type="primary"
              icon={<CheckCircleOutlined />}
              loading={isSubmitting}
              onClick={onClickOK}
            >
              {helperId ? t('care_guide_invitations_form_button_update') : t('care_guide_invitations_form_button_submit')}
            </Button>,
          ]}
          width={500}
          forceRender={true}
        >
          <div className="care-guide-invitation-form">
            <Form
              name="care_guide_invitations_form"
              form={form}
              initialValues={data}
              onFinish={handleFinish}
              onFinishFailed={handleFinishFailed}
              onValuesChange={handleValuesChange}
              validateTrigger="onSubmit"
              requiredMark={false}
              layout="vertical"
            >

              {!helperId
                ? <div className="form-group">
                    <Form.Item
                      name="email"
                      label={t('care_guide_invitations_form_input_email')}
                      rules={[
                        {required: true, message: t('feedback_validation_required')},
                        {type: 'email', message: t('feedback_validation_email')}
                      ]}
                    >
                      <Input disabled={isLoading || isSubmitting} />
                    </Form.Item>
                  </div>
                : null}

              <RoleInput
                name="role"
                label={
                  <span>
                    {t('care_guide_invitations_role')}&nbsp;
                    <Tooltip title={t('care_guide_invitations_tooltip_role')}>
                      <QuestionCircleOutlined />
                    </Tooltip>
                  </span>
                }
                disabled={isLoading || isSubmitting}
              />

              <TagInput
                name="tags"
                label={t('care_guide_invitations_form_input_tags')}
              />

              <div style={{display: 'none'}}>
                <Button htmlType="submit"></Button>
              </div>

            </Form>
          </div>
        </Modal>
      </>
    }</Translation>
  );
}

export default CareGuideInvitationForm;

Logger.log('silly', `CareGuideInvitationForm loaded.`);
