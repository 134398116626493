import { pathTo } from '../../../Routes';
import { Redirect } from 'react-router-dom';
import { Translation } from 'react-i18next';
import Logger from '../../../../../lib/Logger';
import React, { useState, useEffect } from 'react';
import scrollToTop from '../../../elements/lib/scrollToTop';
import { Button, Col, Row } from 'antd';
import { LeftOutlined } from '@ant-design/icons';
import Title from 'antd/lib/typography/Title';
import '../styles/chat.less';
import { chatIcon } from '../../../elements/components/Icons';

const ChatScreen = (props) => {
  const [redirect, setRedirect] = useState(null);

  useEffect(() => {
    scrollToTop();
  }, []);

  useEffect(() => {
    if (props.accessTabs === null || !props.accessTabs?.messages) {
      setRedirect('HomeScreen');
    }
  }, [props.accessTabs]);

  if (redirect) {
    return <Redirect to={pathTo(redirect)} />;
  }

  return (
    <Translation>
      {(t) => (
        <>
          <Row align="start">
            <Button
              type="link"
              onClick={() => setRedirect('HomeScreen')}
              className="back-btn"
            >
              <LeftOutlined /> {t('chat_back_home')}
            </Button>
          </Row>
          <Col md={16} lg={12} xl={10} xxl={6} className="chat-content">
            <Row justify="center">{chatIcon()}</Row>
            <Title level={3}>{t('chat_title')}</Title>
            <p dangerouslySetInnerHTML={{ __html: t('chat_description') }} />
            <a
              className="ant-btn ant-btn-primary"
              href="https://application.textline.com/auth/sign_in?utm_cta=Sign%20In"
              target="_blank"
              rel="noopener noreferrer"
            >
              {t('chat_redirect')}
            </a>
          </Col>
        </>
      )}
    </Translation>
  );
};

export default ChatScreen;

Logger.log('silly', `ChatScreen loaded.`);
