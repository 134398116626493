import React, { Component } from 'react';
import { Translation } from 'react-i18next';
import { Col, Layout, Row, Card, Spin } from 'antd';
import { Redirect } from 'react-router-dom';

import scrollToTop from '../../../elements/lib/scrollToTop';
import Logger from '../../../../../lib/Logger';
import UserProfilesChooserHomeListContainer from '../../userProfilesPartnerships/containers/UserProfilesPartnershipsChooserHomeListContainer';
import CareGuideChooserHomeList from '../../careGuides/containers/CareGuideChooserHomeListContainer';
import Config from '../../../../../Config';
import QueryString from '../../../../../lib/QueryString';
import { pathTo } from '../../../Routes';
import { LoadingOutlined } from '@ant-design/icons';
import {
  MixpanelTracker,
  mixpanelEventsEnum,
} from '../../../../../lib/Mixpanel';

class HomeScreen extends Component {
  static defaultProps = {
    limit: Config.get('DEFAULT_LIST_LENGTH', 10),
  };

  state = {
    isPartner: false,
    areCareGuides: false,
    isFirstLoad: true,
  };

  render() {
    const page = parseInt(
      this.props.match.params.page ? this.props.match.params.page : 1
    );

    const { order, orderUserProfilePartnership, ...filter } = QueryString.parse(
      this.props.location.search
    );

    const filterLength = Object.keys(filter).length;
    if (
      this.props.userProfilesPartneshipsCount > 0 &&
      this.state.isPartner === false
    ) {
      this.setState({
        isPartner: true,
      });
    }

    if (this.props.careGuideCount > 1 && this.state.areCareGuides === false) {
      this.setState({
        areCareGuides: true,
      });
    }

    if (
      this.state.areCareGuides === false &&
      this.props.activeId &&
      filterLength === 0 &&
      this.state.isPartner === false &&
      this.props.partnershipActiveId === null
    ) {
      const careGuide = this.props.list[0];

      if (careGuide.partnership) {
        MixpanelTracker.mixpanelTrack(
          mixpanelEventsEnum.PAGES.ACCESS_CARE_GUIDE,
          {
            careGuideName: `${careGuide.first_name} ${careGuide.last_name}`,
            partnershipName: careGuide.partnership.name,
            careGuideId: careGuide.id,
            partnershipId: careGuide.partnership.id,
          }
        );
        MixpanelTracker.mixpanelTrack(mixpanelEventsEnum.PAGES.ACCESS_PAGE, {
          careGuideName: `${careGuide.first_name} ${careGuide.last_name}`,
          partnershipName: careGuide.partnership.name,
          careGuideId: careGuide.id,
          partnershipId: careGuide.partnership.id,
          page: 'DashboardDetailScreen',
        });
      } else {
        MixpanelTracker.mixpanelTrack(
          mixpanelEventsEnum.PAGES.ACCESS_CARE_GUIDE,
          {
            careGuideName: `${careGuide.first_name} ${careGuide.last_name}`,
            careGuideId: careGuide.id,
          }
        );
        MixpanelTracker.mixpanelTrack(mixpanelEventsEnum.PAGES.ACCESS_PAGE, {
          careGuideName: `${careGuide.first_name} ${careGuide.last_name}`,
          careGuideId: careGuide.id,
          page: 'DashboardDetailScreen',
        });
      }

      return (
        <Redirect
          to={pathTo('DashboardDetailScreen', { id: this.props.activeId })}
        />
      );
    }

    return (
      <Translation>
        {(t) => (
          <Layout>
            <Row>
              <Col xs={12} sm={18} md={22} lg={24} xl={24}>
                {(this.props.isLoading || this.props.isLoadingPartnership) && (
                  <Row type="flex" justify="center" align="center">
                    <Col>
                      <Spin
                        indicator={
                          <LoadingOutlined style={{ fontSize: 60 }} spin />
                        }
                      />
                    </Col>
                  </Row>
                )}

                {this.state.isPartner === true && (
                  <Card
                    title={t('partnerships_title')}
                    style={
                      this.props.isLoadingPartnership === false
                        ? {
                            border: '2px solid #32CD32',
                          }
                        : { display: 'none' }
                    }
                  >
                    <UserProfilesChooserHomeListContainer
                      component="HomeScreen"
                      page={page}
                      pageUserProfilePartnership={
                        this.props.pageUserProfilePartnership
                      }
                      limit={this.props.limit}
                      orderUserProfilePartnership={
                        orderUserProfilePartnership || 'updated_at.asc'
                      }
                      filter={filter}
                    />
                  </Card>
                )}
                {this.state.isPartner === false && (
                  <Card
                    title={t('care_guides_chooser_title')}
                    style={
                      this.props.isLoading === false
                        ? {
                            border: '2px solid #3232CD',
                          }
                        : { display: 'none' }
                    }
                  >
                    <CareGuideChooserHomeList
                      component="HomeScreen"
                      key={page}
                      pageUserProfilePartnership={
                        this.props.pageUserProfilePartnership
                      }
                      page={page}
                      limit={this.props.limit}
                      order={order || 'started_at.asc'}
                      filter={filter}
                    />
                  </Card>
                )}
              </Col>
            </Row>
          </Layout>
        )}
      </Translation>
    );
  }

  /* LIFECYCLE EVENTS */

  componentDidMount() {
    if (this.props.partnershipActiveId) {
      MixpanelTracker.mixpanelTrack(
        mixpanelEventsEnum.PAGES.ACCESS_PAGE_ADMINISTRATOR,
        {
          partnershipName: this.props.partnershipName,
          partnershipId: this.props.partnershipActiveId,
          page: 'HomeScreen',
        }
      );
    } else {
      MixpanelTracker.mixpanelTrack(mixpanelEventsEnum.PAGES.ACCESS_PAGE, {
        page: 'HomeScreen',
      });
    }

    this.props.setActive(null);
    this.props.setAccess({ access_role_admin_in_care_guide: null });
    scrollToTop();
  }

  componentDidUpdate() {
    if (
      this.props.userProfilesPartneshipsCount === 0 &&
      this.props.careGuideCount === 1
    ) {
      if (this.props.list.length === 1) {
        const activeId = this.props.list[0].id;
        this.props.setActive(activeId);
        this.props.setAccess({
          access_role_admin_in_care_guide: this.props.list[0].partnership
            ? this.props.list[0].partnership.access_role_admin_in_care_guide
            : null,
        });
      }
    }
  }
}

export default HomeScreen;

Logger.log('silly', `HomeScreen loaded.`);
