import {connect} from 'react-redux';
import {List} from 'immutable';
import PartnershipAccountAccessForm from '../components/PartnershipAccountAccessForm';

import {
  updatePartnershipAccountAccess,
  partnershipAccountAccessFormDestroy
} from '../../../../../state/modules/partnershipAccount/actions';
import storage from "../../../../../lib/Storage";

const inputs = List([
  'access_role_admin_in_app',
]);

const mapStateToProps = (state) => {

  const data = {}, errors = {};
  for (const val of inputs.values()) {
    data[val] = state.partnershipAccount.getIn(['data', val], ['access_role_admin_in_app'].includes(val) ? {} : null);
    if (state.partnershipAccount.getIn(['accessForm', 'errors', val])) {
      errors[val] = state.partnershipAccount.getIn(['accessForm', 'errors', val]);
    }
  }

  return {
    activeId: storage.get('local', 'partnershipId'),
    isLoading: state.partnershipAccount.get('isLoading'),
    isSubmitting: state.partnershipAccount.get('isSubmittingAccess'),
    success: state.partnershipAccount.getIn(['accessForm', 'success']),
    data: data,
    errors: errors
  }
}

const mapDispatchToProps = dispatch => {
  return {
    submit: (partnership_id, data, cb) => {
      dispatch(updatePartnershipAccountAccess(partnership_id, data, cb));
    },
    formDestroy: (formState) => {
      dispatch(partnershipAccountAccessFormDestroy(formState));
    },
  }
}

const PartnershipAccountAccessFormContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(PartnershipAccountAccessForm);

export default PartnershipAccountAccessFormContainer
