import React, { useEffect, useState } from 'react';
import {
  Col,
  Row,
  Typography,
  DatePicker,
  Collapse,
  Pagination,
  Button,
} from 'antd';
import { Translation } from 'react-i18next';

import {
  FileOutlined,
  CaretUpOutlined,
  CaretDownOutlined,
  DeleteOutlined,
} from '@ant-design/icons';
import Format from '../../../../../lib/Format';
import { pathTo } from '../../../Routes';
import QueryString from '../../../../../lib/QueryString';
import { withRouter } from 'react-router';
import moment from 'moment';
import { Empty } from 'antd';

const { Title, Text } = Typography;
const { RangePicker } = DatePicker;
const { Panel } = Collapse;

const DailyLogsList = ({
  careGuide,
  list,
  load,
  careGuideId,
  partnershipId,
  component,
  history,
  page,
  limit,
  filter,
  order,
  total,
  setConfirmationModalOpen,
  setDailyLogId,
  generatePDF,
  isGeneratingPDF,
  ...props
}) => {
  const [activeKey, setActiveKey] = useState([]);

  let params = QueryString.parse(history.location.search);
  const defaultStart = 'start' in params ? moment(params['start']) : null;
  const defaultEnd = 'end' in params ? moment(params['end']) : null;

  const onChangeDateHandler = (dates) => {
    params['start'] = dates && dates[0] ? dates[0].format('YYYY-MM-DD') : '';
    if (params['start'] === '') {
      delete params['start'];
    }
    params['end'] = dates && dates[1] ? dates[1].format('YYYY-MM-DD') : '';
    if (params['end'] === '') {
      delete params['end'];
    }
    const path = pathTo(component, { id: careGuideId });
    history.push(QueryString.append(path, params));
  };

  const onChangeCollapse = (key) => {
    setActiveKey(key);
  };

  const isActiveKey = (key) => activeKey.includes(key);

  const onChange = (newPage, pageSize) => {
    setActiveKey([]);
    const path = pathTo(component, {
      id: careGuideId,
      page: newPage,
      limit: pageSize,
    });
    history.push(QueryString.append(path, { order, ...filter }));
  };

  const onClickExportHandler = (partnershipId, careGuideId, id) => {
    generatePDF(partnershipId, careGuideId, id);
  };

  const filterString = JSON.stringify(filter);

  useEffect(() => {
    load(
      partnershipId,
      careGuideId,
      page,
      limit,
      order,
      JSON.parse(filterString)
    );
  }, [careGuideId, partnershipId, page, limit, filterString, order, load]);

  const genExtra = (id) => (
    <DeleteOutlined
      style={{
        fontSize: '16px',
        color: 'red',
      }}
      onClick={(event) => {
        event.stopPropagation();
        setDailyLogId(id);
        setConfirmationModalOpen(true);
      }}
    />
  );

  const transformationText = (text) => {
    return Array.isArray(text.split(/(?=[A-Z])/))
      ? text.split(/(?=[A-Z])/).join(' ') + ': '
      : text.split(/(?=[A-Z])/)[0] + ': ';
  };

  return (
    <Translation>
      {(t) => (
        <>
          <Col
            span={24}
            flex="auto"
            md={{ span: 12 }}
            className="section-daily-log"
          >
            <Row className="info-daily-log">
              <Col flex="auto" className={`info-row`}>
                <Title level={3}>{t('care_guide_owners_cognito')}</Title>
                <RangePicker
                  style={{ width: '100%' }}
                  size="large"
                  showTime
                  format="MM/DD/YYYY"
                  onChange={onChangeDateHandler}
                  value={[defaultStart, defaultEnd]}
                />
                <br />
                <br />

                {total > 0 ? (
                  <>
                    <Collapse
                      style={{ backgroundColor: 'white' }}
                      activeKey={activeKey}
                      onChange={onChangeCollapse}
                    >
                      {list
                        ? list.map((x) => (
                            <>
                              {x?.answers ? (
                                <Panel
                                  header={
                                    <>
                                      <Text
                                        type={
                                          !isActiveKey(x.key) && 'secondary'
                                        }
                                        style={{
                                          fontSize: '16px',
                                          color:
                                            isActiveKey(x.key) && '#4279BD',
                                        }}
                                      >
                                        {Format.dateTable(x.created_at)}
                                      </Text>
                                      {isActiveKey(x.key) ? (
                                        <div
                                          style={{
                                            marginLeft: '12px',
                                            textAlign: 'center',
                                          }}
                                        >
                                          <CaretDownOutlined />
                                        </div>
                                      ) : (
                                        <div
                                          style={{
                                            marginLeft: '12px',
                                            textAlign: 'center',
                                          }}
                                        >
                                          <CaretUpOutlined />
                                        </div>
                                      )}
                                    </>
                                  }
                                  key={x.key}
                                  extra={genExtra(x.id)}
                                  showArrow={false}
                                >
                                  <Row>
                                    <Col flex="auto">
                                      <Text strong>
                                        {t(
                                          'care_guide_owners_cognito_field_name'
                                        ) + ': '}
                                      </Text>
                                      <Text type="secondary">
                                        {x?.answers['Name']}
                                      </Text>
                                    </Col>
                                  </Row>
                                  <>
                                    {Object.entries(x?.answers)
                                      .filter(
                                        ([key]) =>
                                          key !== 'Name' && key !== 'Comments'
                                      )
                                      .map(([key, value], index, array) => {
                                        if (index % 2 === 0) {
                                          const nextEntry = array[index + 1];
                                          return (
                                            <Row
                                              gutter={24}
                                              style={{ marginTop: '20px' }}
                                            >
                                              <Col span={11}>
                                                <Text strong>
                                                  {transformationText(key)}
                                                </Text>
                                                <Text type="secondary">
                                                  {Array.isArray(value)
                                                    ? value.join(', ')
                                                    : value}
                                                </Text>
                                              </Col>
                                              {nextEntry && (
                                                <Col span={11}>
                                                  <Text strong>
                                                    {transformationText(
                                                      nextEntry[0]
                                                    )}
                                                  </Text>
                                                  <Text type="secondary">
                                                    {Array.isArray(nextEntry[1])
                                                      ? nextEntry[1].join(', ')
                                                      : nextEntry[1]}
                                                  </Text>
                                                </Col>
                                              )}
                                            </Row>
                                          );
                                        }
                                        return null;
                                      })}
                                  </>
                                  <Row style={{ marginTop: '20px' }}>
                                    <Col flex="auto">
                                      <Text strong>
                                        {t(
                                          'care_guide_owners_cognito_field_comments'
                                        ) + ': '}
                                      </Text>
                                      <Text type="secondary">
                                        {x?.answers['Comments']}
                                      </Text>
                                    </Col>
                                  </Row>
                                  <Row
                                    style={{ marginTop: '20px' }}
                                    justify="end"
                                  >
                                    <Col>
                                      <Button
                                        style={{ borderRadius: '4px' }}
                                        type="primary"
                                        icon={<FileOutlined />}
                                        disabled={isGeneratingPDF}
                                        onClick={() =>
                                          onClickExportHandler(
                                            partnershipId,
                                            careGuideId,
                                            x.id
                                          )
                                        }
                                      >
                                        {t('Download PDF')}
                                      </Button>
                                    </Col>
                                  </Row>
                                </Panel>
                              ) : null}
                            </>
                          ))
                        : null}
                    </Collapse>
                    <br />
                    <div style={{ textAlign: 'center' }}>
                      {total > 10 && (
                        <Pagination
                          defaultCurrent={5}
                          style={{ textAlign: 'center' }}
                          current={page}
                          pageSize={limit}
                          showSizeChanger={total > 10}
                          position={['bottomCenter']}
                          onChange={onChange}
                          total={total}
                          pageSizeOptions={['10', '20']}
                        />
                      )}
                    </div>
                  </>
                ) : (
                  <div
                    style={{
                      height: '826px',
                      backgroundColor: 'white',
                      alignItems: 'center',
                      justifyContent: 'center',
                      display: 'flex',
                    }}
                  >
                    <Empty
                      description={
                        <Text>
                          {t(
                            'care_guide_owners_cognito_empty_answers_description'
                          )}
                        </Text>
                      }
                    />
                  </div>
                )}
              </Col>
            </Row>
          </Col>
        </>
      )}
    </Translation>
  );
};

export default withRouter(DailyLogsList);
