import React, {useEffect, useState, useRef} from 'react';
import {Translation} from 'react-i18next';
import {Button, Typography} from 'antd';
import {Link} from 'react-router-dom';
import {loadStripe} from '@stripe/stripe-js';

import {pathTo} from '../../../Routes';
import Loading from '../../../elements/components/Loading';
import Config from '../../../../../Config';
import Logger from '../../../../../lib/Logger';

import imageCircleLogo from "../../../assets/images/circle-logo-380x376.png";


const stripePromise = Config.get('STRIPE_API_KEY') ? loadStripe(Config.get('STRIPE_API_KEY')) : null;

const statuses = {
  succeeded: 1,
  processing: 2,
  canceled: 3,
  requires_payment_method: 4,
  requires_confirmation: 5,
  requires_action: 6,
  requires_capture: 7,
  error: 8,
};

const PaymentStatus = ({paymentIntent, paymentIntentClientSecret, setupIntent, setupIntentClientSecret, ...props}) => {

  const [isLoading, setIsLoading] = useState(true);
  const [status, setStatus] = useState(null);
  const [retry, setRetry] = useState(null);
  const stripeRef = useRef(null);

  useEffect(() => {
    const getStripe = async () => {
      stripeRef.current = await stripePromise;
      setRetry(0);
    };
    getStripe();
  }, []);

  // retrieve setup intent
  useEffect(() => {
    if (stripeRef.current && setupIntentClientSecret) {
      stripeRef.current.retrieveSetupIntent(setupIntentClientSecret).then(result => {
        setIsLoading(false);
        setStatus(result.setupIntent && result.setupIntent.status in statuses ? statuses[result.setupIntent.status] : statuses.error);

        // if still processing, retry after a bit
        if (result.setupIntent && result.setupIntent.status === 'processing') {
          setTimeout(() => setRetry(retry + 1), (2 ** (retry + 2)) * 1000);
        }
      });
    }
  }, [setupIntentClientSecret, retry]);

  // retrieve payment intent
  useEffect(() => {
    if (stripeRef.current && paymentIntentClientSecret) {
      stripeRef.current.retrievePaymentIntent(paymentIntentClientSecret).then(result => {
        setIsLoading(false);
        setStatus(result.paymentIntent && result.paymentIntent.status in statuses ? statuses[result.paymentIntent.status] : statuses.error);
        
        // if still processing, retry after a bit
        if (result.paymentIntent && result.paymentIntent.status === 'processing') {
          setTimeout(() => setRetry(retry + 1), (2 ** (retry + 2)) * 1000);
        }
      });
    }
  }, [paymentIntentClientSecret, retry]);


  const {Text} = Typography;

  return (
    <Translation>{(t=>
      <>

        {isLoading
          ? <Loading />
          : null}

        {status === statuses.succeeded
          ? <>
              {/* <div>
                <Text type="success">{t('payments_status_success')}</Text>
              </div>
              <div>
                <Link to={pathTo(Config.get('DEFAULT_REGISTRATION_REDIRECT'))}>{t('payments_action_link_dashboard')}</Link>
              </div> */}
              <div className="welcome-box">
                <div className="circle-logo">
                  <img src={imageCircleLogo} alt={t('app_name')} />
                </div>
                <h2>{t('payments_status_welcome_header')}</h2>
                <div className="welcome-box-copy" dangerouslySetInnerHTML={{__html: t('payments_status_welcome_copy', {interpolation: {escapeValue: false}})}} />
                <Button type="primary">
                  <Link to={pathTo(Config.get('DEFAULT_REGISTRATION_REDIRECT'))}>{t('payments_action_link_dashboard')}</Link>
                </Button>
              </div>
            </>
          : null}

        {status === statuses.processing
          ? <>
              <div>
                <h1>{t('payments_screen_status_title')}</h1>
                <Text type="warning">{t('payments_status_processing')}</Text>
              </div>
            </>
          : null}

        {[statuses.canceled, statuses.requires_payment_method, statuses.requires_confirmation,
          statuses.requires_action, statuses.requires_capture, statuses.error].includes(status)
          ? <>
              <div>
                <h1>{t('payments_screen_status_title')}</h1>
                <Text type="danger">{t('payments_status_error')}</Text>
              </div>
            </>
          : null}
        
      </>
    )}</Translation>
  );
};

export default PaymentStatus;

Logger.log('silly', `PaymentStatus loaded.`);
