import {connect} from 'react-redux';
import {List} from 'immutable';
import {denormalize} from 'normalizr';

import {schema} from '../../../../../state/schema';
import {
  loadCareGuideHelpers,
  updateCareGuideHelper,
  deleteCareGuideHelper,
  careGuideHelperFormDestroy,
  careGuideHelperHideForm
} from '../../../../../state/modules/careGuideHelpers/actions';
import CareGuideHelperForm from '../components/CareGuideHelperForm';

const inputs = List([
  'role',
  'public_status',
  'note',
  'tags',
]);

const mapStateToProps = (state, ownProps) => {

  const errors = {};
  for (const val of inputs.values()) {
    if (state.careGuideHelpers.getIn(['form', 'errors', val])) {
      errors[val] = state.careGuideHelpers.getIn(['form', 'errors', val]);
    }
  }

  const helperId = state.careGuideHelpers.get('formHelperId');
  const helper = helperId
    ? denormalize(
        state.entities.getIn(['careGuideHelpers', helperId]),
        schema.careGuideHelper,
        state.entities.toJS()
      )
    : null;
  const data = {
    role: helper ? helper.role : null,
    public_status: helper ? (helper.public_status === 1 ? true : false) : null,
    note: helper ? helper.note : null,
    tags: helper ? helper.tags : [],
  };

  return {
    visible: state.careGuideHelpers.get('formIsVisible'),
    isLoading: state.careGuideHelpers.get('isLoading'),
    isSubmitting: state.careGuideHelpers.getIn(['form', 'isSubmitting']),
    success: state.careGuideHelpers.getIn(['form', 'success']),
    profileName: helper && helper.profile ? helper.profile.first_name + ' ' + helper.profile.last_name : null,
    helperId: helperId,
    data: data,
    errors: errors,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    load: (careGuideId, page, limit, order, filter, cb) => {
      dispatch(loadCareGuideHelpers(careGuideId, page, limit, order, filter, cb));
    },
    update: (careGuideId, id, data, cb) => {
      dispatch(updateCareGuideHelper(careGuideId, id, data, cb));
    },
    delete: (careGuideId, id, cb) => {
      dispatch(deleteCareGuideHelper(careGuideId, id, cb));
    },
    formDestroy: (formState) => {
      dispatch(careGuideHelperFormDestroy(formState));
    },
    hideForm: () => {
      dispatch(careGuideHelperHideForm());
    },
  }
}

const CareGuideHelperFormContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(CareGuideHelperForm);

export default CareGuideHelperFormContainer;
