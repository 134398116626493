import {getI18n} from 'react-i18next';

import Config from '../../../../Config';
import Logger from '../../../../lib/Logger';
import Events from '../../../../lib/EventEmitter';

import UserProfilesPartnershipsAdminScreen from './containers/UserProfilesPartnershipsAdminScreenContainer';


const register = () => {
  Logger.log('debug', `userProfilesPartnershipsAdmin.register()`);

  if (Config.getIn(['MODULE_TOGGLES', 'userProfilesPartnershipsAdmin', 'routes'])) {
    Events.dispatch('ADD_ADMIN_ROUTES', 
      {
        'UserProfilesPartnershipsAdminScreen': ['PrivateRoute', '/admin/userProfilespartnership/:page(\\d+)?', true, UserProfilesPartnershipsAdminScreen, getI18n().t('user_profiles_partnerships_route_list')],
      }
    );
  }
}

export default register;

Logger.log('silly', `partnerships.register() loaded.`);
