import {getI18n} from 'react-i18next';

import Config from '../../../../Config';
import Logger from '../../../../lib/Logger';
import Events from '../../../../lib/EventEmitter';

import JournalPartnerTagsAdminScreen from './containers/JournalPartnerTagsAdminScreenContainer';
import JournalPartnerTagAdminAddScreen from './containers/JournalPartnerTagAdminAddScreenContainer';
import JournalPartnerTagAdminEditScreen from './containers/JournalPartnerTagAdminEditScreenContainer';

const register = () => {
  Logger.log('debug', `journalPartnerTagsAdmin.register()`);

  if (Config.getIn(['MODULE_TOGGLES', 'journalPartnerTagsAdmin', 'routes'])) {
    Events.dispatch('ADD_ADMIN_ROUTES', 
      {
        'JournalPartnerTagAdminAddScreen': ['PrivateRoute', '/admin/journal-partner-tags/partnership/:id_partnership([\\w\\-]+)/add', true, JournalPartnerTagAdminAddScreen, getI18n().t('journal_partner_tag_route_add')],
        'JournalPartnerTagAdminEditScreen': ['PrivateRoute', '/admin/journal-partner-tags/partnership/:id_partnership([\\w\\-]+)/edit/:id([\\w\\-]+)', true, JournalPartnerTagAdminEditScreen, getI18n().t('journal_partner_tag_route_edit')],
        'JournalPartnerTagsAdminScreen': ['PrivateRoute', '/admin/journal-partner-tags/:page(\\d+)?', true, JournalPartnerTagsAdminScreen, getI18n().t('journal_partner_tags_route_list')],
      }
    );
  }
}

export default register;

Logger.log('silly', `journalPartnerTagsAdmin.register() loaded.`);
