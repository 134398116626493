import {connect} from 'react-redux';

import CareGuidesAdminScreen from '../components/CareGuidesAdminScreen';
import storage from "../../../../../lib/Storage";

const mapStateToProps = state => {
  return {
    total: state.careGuides.get('total'),
    isLoading: state.careGuides.get('isLoading'),
    partnershipActiveId: storage.get('local', 'partnershipId'),
  }
}

const CareGuidesAdminScreenContainer = connect(
  mapStateToProps
)(CareGuidesAdminScreen);

export default CareGuidesAdminScreenContainer;
