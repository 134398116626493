import { connect } from 'react-redux';
import { List } from 'immutable';
import moment from 'moment';
import { denormalize } from 'normalizr';

import { schema } from '../../../../../state/schema';
import {
  loadCalendarEventAdmin,
  updateCalendarEventAdmin,
  createCalendarEventAdmin,
  deleteCalendarEventAdmin,
  calendarEventAdminFormDestroy,
  loadCalendarEventParticipants,
  calendarEventParticipantSetPage,
  deleteCalendarEventAdminParticipant,
  calendarEventAdminShowEventParticipantForm,
} from '../../../../../state/modules/calendarEventsAdmin/actions';
import CalendarEventAdminForm from '../components/CalendarEventAdminForm';
import Config from '../../../../../Config';
import { loadCareGuidesMembersPartnership } from '../../../../../state/modules/careGuidesMembersPartnership/actions';
import storage from '../../../../../lib/Storage';

const inputs = List([
  'care_guide',
  'care_guides',
  'title',
  'description',
  'start_at',
  'end_at',
  'start_at_date',
  'start_at_time',
  'end_at_date',
  'end_at_time',
  'is_all_day',
  'participants',
  'recurring',
  'rrule',
  'recurring_end_at',
  'status',
  'url_event_partner',
  'url_label',
]);

const mapStateToProps = (state, ownProps) => {
  const careGuidesMembersPartnership = state.entities.get(
    'careGuidesMembersPartnership',
    {}
  );

  const care_guides_members_partnership = careGuidesMembersPartnership
    ? Object.keys(careGuidesMembersPartnership).map((id) => {
        return {
          label:
            careGuidesMembersPartnership[id].profile.first_name +
            ' ' +
            careGuidesMembersPartnership[id].profile.last_name,
          value: careGuidesMembersPartnership[id].profile.id,
        };
      })
    : [];

  const data = {},
    errors = {};
  for (const val of inputs.values()) {
    data[val] = state.entities.getIn(
      ['calendarEventsAdmin', ownProps.id, val],
      ''
    );

    if (['care_guide', 'parent_event', 'partnership'].includes(val)) {
      errors[val] = state.calendarEventsAdmin.getIn([
        'form',
        'errors',
        val + '_id',
      ]);
    } else {
      errors[val] = state.calendarEventsAdmin.getIn(['form', 'errors', val]);
    }
  }

  // change select number values to strings to work with ant design select inputs
  Object.keys(data).forEach((x) => {
    if (['status'].includes(x)) {
      data[x] = data[x].toString();
    }
  });

  data['start_at_date'] = data['start_at']
    ? moment(data['start_at'], Config.get('API_DATETIME_FORMAT'))
    : null;

  data['start_at_time'] =
    data && data['start_at']
      ? moment(data['start_at'], Config.get('API_DATETIME_FORMAT')).format(
          'h:mm A'
        )
      : '8:00 AM';

  data['end_at_date'] =
    data && data['end_at']
      ? moment(data['end_at'], Config.get('API_DATETIME_FORMAT')).format(
          'MM/DD/YYYY'
        )
      : data['start_at_date']
      ? moment(data['start_at']).format('MM/DD/YYYY')
      : null;

  data['end_at_time'] =
    data && data['end_at']
      ? moment(data['end_at'], Config.get('API_DATETIME_FORMAT')).format(
          'h:mm A'
        )
      : '9:00 AM';

  data['recurring_end_at'] = data['recurring_end_at']
    ? moment(data['recurring_end_at'], Config.get('API_DATETIME_FORMAT'))
    : null;

  const care_guide = data['care_guide'] ?? null;

  if (Array.isArray(data['care_guides'])) {
    if (data['care_guides'].length < 1) {
      data['care_guides'] = care_guide ? [care_guide] : [];
    }
  } else {
    data['care_guides'] = care_guide ? [care_guide] : [];
  }

  // hydrate participants for dynamic inputs
  if ('participants' in data && Array.isArray(data['participants'])) {
    data['participants'] = data['participants'].map((x) => {
      return {
        key: x,
        ...denormalize(
          state.entities.getIn(['calendarEventParticipants', x]),
          schema.calendarEventParticipant,
          state.entities.toJS()
        ),
      };
    });
  }
  const total = state.calendarEventsAdmin.getIn([
    'calendarEventParticipants',
    ownProps.id,
    'total',
  ]);
  const pageParticipants = state.calendarEventsAdmin.get('pageParticipants');
  const areParticipantEventsLoading = state.calendarEventsAdmin.get(
    'areParticipantEventsLoading'
  );

  const result = state.calendarEventsAdmin.getIn([
    'calendarEventParticipants',
    ownProps.id,
    pageParticipants,
    10,
  ]);
  let list = [];

  list = result
    ? result
        .map((x) => {
          return {
            key: x,
            ...denormalize(
              state.entities.getIn(['calendarEventParticipants', x]),
              schema.calendarEventParticipant,
              state.entities.toJS()
            ),
          };
        })
        .toArray()
    : [];

    return {
    pageParticipants: pageParticipants,
    list: list,
    total: total,
    areParticipantEventsLoading: areParticipantEventsLoading,
    isLoading: state.calendarEventsAdmin.get('isLoading'),
    isSubmitting: state.calendarEventsAdmin.getIn(['form', 'isSubmitting']),
    success: state.calendarEventsAdmin.getIn(['form', 'success']),
    created_id: state.calendarEventsAdmin.getIn(['form', 'created_id']),
    data: data,
    errors: errors,
    createdAt: state.entities.getIn(
      ['calendarEventsAdmin', ownProps.id, 'created_at'],
      null
    ),
    updatedAt: state.entities.getIn(
      ['calendarEventsAdmin', ownProps.id, 'updated_at'],
      null
    ),
    activeId: storage.get('local', 'partnershipId'),
    care_guides_members_partnership: care_guides_members_partnership,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    load: (partnership_id, id, cb) => {
      dispatch(loadCalendarEventAdmin(partnership_id, id, cb));
    },
    update: (id, data, cb) => {
      dispatch(updateCalendarEventAdmin(id, data, cb));
    },
    create: (data, cb) => {
      dispatch(createCalendarEventAdmin(data, cb));
    },
    delete: (partnershipId, id, cb) => {
      dispatch(deleteCalendarEventAdmin(partnershipId, id, cb));
    },
    deleteParticipant: (calendarEventParticipantId, cb) => {
      dispatch(
        deleteCalendarEventAdminParticipant(calendarEventParticipantId, cb)
      );
    },
    destroyForm: (formState) => {
      dispatch(calendarEventAdminFormDestroy(formState));
    },
    loadMembersPartnership: (partnershipId, cb) => {
      dispatch(loadCareGuidesMembersPartnership(partnershipId, cb));
    },
    loadParticipants: (calendarEventId, page, limit, cb) => {
      dispatch(loadCalendarEventParticipants(calendarEventId, page, limit, cb));
    },
    setPage: (page) => {
      dispatch(calendarEventParticipantSetPage(page));
    },
    showParticipantForm: (participantId) => {
      dispatch(calendarEventAdminShowEventParticipantForm(participantId));
    },
  };
};

const CalendarEventAdminFormContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(CalendarEventAdminForm);

export default CalendarEventAdminFormContainer;
