import React, { useState, useEffect } from 'react';
import i18next from 'i18next';
import { Translation, getI18n } from 'react-i18next';
import { Button, Checkbox, Form, Table } from 'antd';
import { CheckCircleOutlined } from '@ant-design/icons';

import Logger from '../../../../../lib/Logger';
import message from '../../../elements/lib/MessageWrapper';
import storage from '../../../../../lib/Storage';

const PartnershipAccountAccessCareGuideForm = ({
  data,
  activeId,
  ...props
}) => {
  const [accessToken, setAccessToken] = useState('access-token');
  const [valuesAccess, setValuesAccess] = useState(
    data.access_role_admin_in_care_guide
  );

  const [form] = Form.useForm();

  useEffect(() => {
    setValuesAccess({ ...data.access_role_admin_in_care_guide });
  }, [data]);

  const accessOptions = [
    'member_profile',
    'add_resource',
    'schedule_event',
    'journal_post',
    'care_guide',
    'calendar',
    'care_team',
    'resources',
  ];

  const accessColumns = [
    {
      title: getI18n().t(
        'partnership_account_access_admin_care_guide_form_table_access_title'
      ),
      dataIndex: 'access_type',
    },
    {
      title: getI18n().t(
        'partnership_account_access_admin_care_guide_form_input_access_admin'
      ),
      dataIndex: 'access_role_admin_in_care_guide',
    },
  ];
  const accessData = accessOptions.map((x, i) => {
    return {
      key: i,
      access_type: getI18n().t('partnership_accounts_access_care_guide_' + x),
      access_role_admin_in_care_guide: (
        <Checkbox
          name="access_role_admin_in_care_guide"
          checked={valuesAccess[x]}
          onChange={(e) => onChangeAccess(x)}
        ></Checkbox>
      ),
    };
  });

  const onChangeAccess = (val) => {
    if (valuesAccess[val]) {
      setValuesAccess({ ...valuesAccess, [val]: false });
    } else {
      setValuesAccess({ ...valuesAccess, [val]: true });
    }
  };

  useEffect(() => {
    setAccessToken('access-' + Object.keys(valuesAccess).join(','));
  }, [valuesAccess]);

  // handle errors reported by API
  useEffect(() => {
    let firstFieldName = '';
    for (const field in props.errors) {
      form.setFields([{ name: field, errors: props.errors[field] }]);
      if (firstFieldName === '') {
        firstFieldName = field;
      }
    }
    form.scrollToField(firstFieldName);
  }, [form, props.errors]);

  // submit data handler
  const submitData = async (values) => {
    Logger.log(
      'debug',
      `PartnershipAccountAccessCareGuideForm.submitData(###)`
    );
    // API POST/PUT payload
    const payload = {
      access_role_admin_in_care_guide: valuesAccess,
    };
    // update
    props.submit(activeId, payload, (success) => {
      if (success) {
        storage.set('local', 'partnershipData', {
          access_role_admin_in_care_guide: valuesAccess,
          access_role_admin_in_app: storage.get('local', 'partnershipData')
            ?.access_role_admin_in_app,
          access_tabs_in_app: storage.get('local', 'partnershipData')
            ?.access_tabs_in_app,
          role: storage.get('local', 'partnershipData')?.role,
          name: storage.get('local', 'partnershipData')?.name,
        });

        message.success(
          i18next.t(
            'partnership_account_access_admin_care_guide_form_message_success'
          )
        );
      } else {
        message.error(
          i18next.t(
            'partnership_account_access_admin_care_guide_form_message_failure'
          )
        );
      }
    });
  };

  // form submit handler
  const handleFinish = async (values) => {
    Logger.log(
      'debug',
      `PartnershipAccountAccessCareGuideForm.handleFinish(###)`
    );
    if (!props.isSubmitting) {
      await submitData(values);
    }
  };

  // form error handler
  const handleFinishFailed = ({ values, errorFields, outOfDate }) => {
    Logger.log(
      'debug',
      `PartnershipAccountAccessCareGuideForm.handleFinishFailed(###)`
    );
    message.error(getI18n().t('feedback_form_error'));
    if (errorFields && errorFields.length > 0) {
      form.scrollToField(errorFields[0].name);
    }
  };

  return (
    <Translation>
      {(t) => (
        <div className="partnership-account-access-care-guide-form">
          <Form
            name="partnership_access_care_guide_account_form"
            onFinish={handleFinish}
            onFinishFailed={handleFinishFailed}
            form={form}
          >
            <Table
              key={accessToken}
              columns={accessColumns}
              dataSource={accessData}
              size="small"
              pagination={false}
            />

            <div className="form-actions">
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  icon={<CheckCircleOutlined />}
                  loading={props.isSubmitting}
                >
                  {t(
                    'partnership_account_access_admin_care_guide_form_button_submit'
                  )}
                </Button>
              </Form.Item>
            </div>
          </Form>
        </div>
      )}
    </Translation>
  );
};

export default PartnershipAccountAccessCareGuideForm;

Logger.log('silly', `PartnershipAccountAccessCareGuideForm loaded.`);
