import {connect} from 'react-redux';
import {List} from 'immutable';

import {
  registerStep2,
  registerStep2FormDestroy,
  registerCreateAccount,
  loadTermsOfServiceCurrent,
  registerCreateAccountClient
} from '../../../../../state/modules/register/actions';
import RegisterStep2Form from '../components/RegisterStep2Form';

const inputs = List([
  'care_guide_first_name',
  'care_guide_last_name',
  'care_guide_postal_code',
  'care_guide_relationship_id',
  'registrations_care_partner_tags',
  'tos_id',
]);

const mapStateToProps = (state) => {

  const data = {}, errors = {};
  for (const val of inputs.values()) {
    data[val] = '';
    if (state.register.getIn(['step2Form', 'errors', val])) {
      errors[val] = state.register.getIn(['step2Form', 'errors', val]);
    }
  }

  let currentTosId = '';
  if (state.entities.get('tos')) {
    currentTosId = state.entities.get('tos')[Object.keys(state.entities.get('tos'))[0]].id;
  }
  data['tos_id'] = false;
  
  return {
    isSubmitting: state.register.get('isStep2Submitting') || state.register.get('isCreateAccountSubmitting'),
    success: state.register.getIn(['step2Form', 'success']),
    data: data,
    errors: errors,
    tos_id: currentTosId
  }
}

const mapDispatchToProps = dispatch => {
  return {
    submit: (data, cb) => {
      dispatch(registerStep2(data, cb));
    },
    createAccount: (data, cb) => {
      dispatch(registerCreateAccount(data, cb));
    },
    createAccountClient: (data, cb) => {
      dispatch(registerCreateAccountClient(data, cb));
    },
    formDestroy: (formState) => {
      dispatch(registerStep2FormDestroy(formState));
    },
    loadTermsOfServiceCurrent: () => {
      dispatch(loadTermsOfServiceCurrent());
    },
  }
}

const RegisterStep2FormContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(RegisterStep2Form);

export default RegisterStep2FormContainer;
