import { Map, List } from "immutable";

import {
  CARE_GUIDE_PARTNER_TAG_ADMIN_LIST_REQUEST,
  CARE_GUIDE_PARTNER_TAG_ADMIN_LIST_SUCCESS,
  CARE_GUIDE_PARTNER_TAG_ADMIN_LIST_FAILURE,
  CARE_GUIDE_PARTNER_TAG_ADMIN_READ_REQUEST,
  CARE_GUIDE_PARTNER_TAG_ADMIN_READ_SUCCESS,
  CARE_GUIDE_PARTNER_TAG_ADMIN_READ_FAILURE,
  CARE_GUIDE_PARTNER_TAG_ADMIN_UPDATE_REQUEST,
  CARE_GUIDE_PARTNER_TAG_ADMIN_UPDATE_SUCCESS,
  CARE_GUIDE_PARTNER_TAG_ADMIN_UPDATE_FAILURE,
  CARE_GUIDE_PARTNER_TAG_ADMIN_CREATE_REQUEST,
  CARE_GUIDE_PARTNER_TAG_ADMIN_CREATE_SUCCESS,
  CARE_GUIDE_PARTNER_TAG_ADMIN_CREATE_FAILURE,
  CARE_GUIDE_PARTNER_TAG_ADMIN_DELETE_REQUEST,
  CARE_GUIDE_PARTNER_TAG_ADMIN_DELETE_SUCCESS,
  CARE_GUIDE_PARTNER_TAG_ADMIN_DELETE_FAILURE,
  CARE_GUIDE_PARTNER_TAG_PARTNERSHIP_LIST_REQUEST,
  CARE_GUIDE_PARTNER_TAG_PARTNERSHIP_LIST_FAILURE,
  CARE_GUIDE_PARTNER_TAG_PARTNERSHIP_LIST_SUCCESS,
  CARE_GUIDE_PARTNER_TAG_ADMIN_FORM_DESTROY,
} from "./actions";
import Logger from "../../../lib/Logger";

export default function careGuidePartnerTagsAdmin(
  state = Map({
    isLoading: false,
    areCareGuidePartnerTagsAdminLoading: false,
    areCareGuidePartnerTagsLoading: false,
    pages: {},
  }),
  action
) {
  Logger.log(
    "debug",
    `[careGuidePartnerTagsAdmin.reducers] careGuidePartnerTagsAdmin(%j, %j)`,
    state,
    action
  );

  switch (action.type) {
    case CARE_GUIDE_PARTNER_TAG_ADMIN_LIST_REQUEST:
      return state.mergeDeep({
        isLoading: true,
      });

    case CARE_GUIDE_PARTNER_TAG_ADMIN_LIST_SUCCESS:
      return state
        .mergeDeep({
          isLoading: false,
          pages: {
            [action.order]: {
              [action.limit]: {
                [action.page]: null,
              },
            },
          },
          total: action.total,
          lastUpdated: action.receivedAt,
        })
        .setIn(
          ["pages", action.order, action.limit, action.page],
          List(action.result)
        );

    case CARE_GUIDE_PARTNER_TAG_ADMIN_LIST_FAILURE:
      return state.mergeDeep({
        isLoading: false,
      });

    case CARE_GUIDE_PARTNER_TAG_ADMIN_READ_REQUEST:
      return state.mergeDeep({
        isLoading: true,
      });

    case CARE_GUIDE_PARTNER_TAG_ADMIN_READ_SUCCESS:
      return state.mergeDeep({
        isLoading: false,
        lastUpdated: action.receivedAt,
      });

    case CARE_GUIDE_PARTNER_TAG_ADMIN_READ_FAILURE:
      return state.mergeDeep({
        isLoading: false,
      });

    case CARE_GUIDE_PARTNER_TAG_ADMIN_UPDATE_REQUEST:
      return state.mergeDeep({
        isLoading: true,
        form: {
          isSubmitting: true,
          success: null,
          errors: null,
        },
      });

    case CARE_GUIDE_PARTNER_TAG_ADMIN_UPDATE_SUCCESS:
      return state.mergeDeep({
        isLoading: false,
        form: {
          isSubmitting: false,
          success: true,
          errors: null,
        },
        lastUpdated: action.receivedAt,
      });

    case CARE_GUIDE_PARTNER_TAG_ADMIN_UPDATE_FAILURE:
      return state
        .mergeDeep({
          isLoading: false,
          form: {
            isSubmitting: false,
            success: false,
            errors: null,
          },
        })
        .setIn(["form", "errors"], action.error);

    case CARE_GUIDE_PARTNER_TAG_ADMIN_CREATE_REQUEST:
      return state.mergeDeep({
        isLoading: true,
        form: {
          isSubmitting: true,
          success: null,
          errors: null,
        },
      });

    case CARE_GUIDE_PARTNER_TAG_ADMIN_CREATE_SUCCESS:
      return state.mergeDeep({
        isLoading: false,
        form: {
          isSubmitting: false,
          success: true,
          created_id: action.id,
          errors: null,
        },
        lastUpdated: action.receivedAt,
      });

    case CARE_GUIDE_PARTNER_TAG_ADMIN_CREATE_FAILURE:
      return state
        .mergeDeep({
          isLoading: false,
          form: {
            isSubmitting: false,
            success: false,
            errors: null,
          },
        })
        .setIn(["form", "errors"], action.error);

    case CARE_GUIDE_PARTNER_TAG_ADMIN_DELETE_REQUEST:
      return state.mergeDeep({
        isLoading: true,
      });

    case CARE_GUIDE_PARTNER_TAG_ADMIN_DELETE_SUCCESS:
      return state.mergeDeep({
        isLoading: false,
        lastUpdated: action.receivedAt,
      });

    case CARE_GUIDE_PARTNER_TAG_ADMIN_DELETE_FAILURE:
      return state.mergeDeep({
        isLoading: false,
      });

    case CARE_GUIDE_PARTNER_TAG_PARTNERSHIP_LIST_REQUEST:
      return state.mergeDeep({
        areCareGuidePartnerTagsLoading: true,
      });

    case CARE_GUIDE_PARTNER_TAG_PARTNERSHIP_LIST_SUCCESS:
      return state
        .mergeDeep({
          areCareGuidePartnerTagsLoading: false,
          careGuidePartnerTagsAdmin: {
            [action.partnershipId]: {
              total: action.total,
            },
          },
          lastUpdated: action.receivedAt,
        })
        .setIn(
          [
            "careGuidePartnerTagsAdmin",
            action.partnershipId,
          ],
          List(action.result)
        );

    case CARE_GUIDE_PARTNER_TAG_PARTNERSHIP_LIST_FAILURE:
      return state.mergeDeep({
        areCareGuidePartnerTagsLoading: false,
      });


    case CARE_GUIDE_PARTNER_TAG_ADMIN_FORM_DESTROY:
      return state
        .mergeDeep({
          form: null,
        })
        .set("form", action.form);

    default:
      return state;
  }
}

Logger.log("silly", `careGuidePartnerTagsAdmin.reducers loaded.`);
