import React, { useEffect, useState } from 'react';
import { Translation } from 'react-i18next';
import Logger from '../../../../../lib/Logger';
import { Row, Typography, Space, Radio, Col } from 'antd';
import { membersIcon } from '../../../elements/components/Icons';
import AdminTagInput from '../../adminPartnerClassesAdmin/containers/TagInputContainer';
import CareGuidesPartnership from '../../careGuideHelpers/containers/CareGuidesPartnershipContainer';

const { Title, Text } = Typography;

const ResourceAdminFormStep2 = ({
  optionMembers,
  onHandleChangeOptionMembers,
  partnershipActiveId,
  isLoading,
  isSubmitting,
  setClasses,
  setCareGuideIds,
  totalMembers,
  totalMembersFiltered,
  form,
  careGuidepartnerTags,
  careGuideIds,
  idsFiltered,
}) => {
  const [membersInTags, setMembersInTags] = useState(0);

  const onChange = (value) => {
    setCareGuideIds(value);
    return value;
  };

  useEffect(() => {
    setMembersInTags(
      idsFiltered.filter((id) => careGuideIds.includes(id)).toArray().length
    );
  }, [careGuideIds, idsFiltered]);

  return (
    <Translation>
      {(t) => (
        <>
          <Row align="top" justify="space-between" flex={'auto'}>
            <Col
              xs={{ span: 0, order: 1 }}
              sm={{ span: 0, order: 1 }}
              md={{ span: 2, order: 1 }}
          ></Col>

            <Col
              xs={{ span: 24, order: 2 }}
              sm={{ span: 10, order: 2 }}
              md={{ span: 6, order: 2 }}
            >
              <Space direction="vertical">
                <Title level={5}>
                  {t('resource_title_assign_resource_to')}
                </Title>
                <Radio.Group
                  size={'large'}
                  onChange={onHandleChangeOptionMembers}
                  disabled={isLoading || isSubmitting}
                  defaultValue={optionMembers}
                >
                  <Space direction="vertical">
                    <Radio value={1}>
                      {t('resource_option_select_members_1')}
                    </Radio>
                    <Radio value={2}>
                      {t('resource_option_select_members_2')}
                    </Radio>
                  </Space>
                </Radio.Group>
              </Space>
            </Col>
            <Col
              xs={{ span: 24, order: 3 }}
              sm={{ span: 10, order: 3 }}
              md={{ span: 6, order: 2 }}
            >
              {optionMembers === 2 && (
                <>
                  <Title level={5}>{t('resource_title_assign_to')}</Title>
                  <div className="form-group">
                    <AdminTagInput
                      mode={'tags'}
                      partnershipId={partnershipActiveId}
                      disabled={isLoading || isSubmitting}
                      setClasses={setClasses}
                    />
                  </div>
                  <CareGuidesPartnership
                    multiple={true}
                    setCareGuideIds={setCareGuideIds}
                    name="care_guides"
                    label={t('resource_care_guides')}
                    partnershipId={partnershipActiveId}
                    handleChange={onChange}
                    form={form}
                    isSubmitting={isSubmitting}
                    careGuidepartnerTags={careGuidepartnerTags}
                  />
                </>
              )}

              <Space direction="horizontal" style={{ marginBottom: '40px' }}>
                <Title level={5} style={{ display: 'inline' }}>
                  {t('resource_title_total_members')}
                </Title>
                <Text style={{ fontSize: '16px' }}>
                  {t('resource_subtitle_total_members', {
                    totalSelect:
                      optionMembers === 1
                        ? totalMembers
                        : careGuideIds.length +
                          totalMembersFiltered -
                          membersInTags,
                    total: totalMembers,
                  })}
                </Text>
              </Space>
              {optionMembers === 1 && (
                <>
                  <Row style={{ marginBottom: '40px' }} justify="center">
                    {membersIcon()}
                  </Row>
                  <Text italic>{t('resource_description_members')}</Text>
                  <div style={{ marginTop: '200px' }}></div>{' '}
                </>
              )}
            </Col>
            <Col
              xs={{ span: 0, order: 4 }}
              sm={{ span: 0, order: 4 }}
              md={{ span: 2, order: 4 }}
            ></Col>
          </Row>
        </>
      )}
    </Translation>
  );
};

export default ResourceAdminFormStep2;

Logger.log('silly', `ResourceAdminFormStep2 loaded.`);
