import React, { useEffect, useState, useRef } from 'react';
import { Translation, getI18n } from 'react-i18next';
import { Button, Form, Modal } from 'antd';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';

import BodyInput from './BodyInput';
import message from '../../../elements/lib/MessageWrapper';
import Logger from '../../../../../lib/Logger';
import {
  MixpanelTracker,
  mixpanelEventsEnum,
} from '../../../../../lib/Mixpanel';

const JournalReplyModalForm = ({
  careGuideId,
  careGuide,
  postId,
  replyId,
  errors,
  isSubmitting,
  data,
  ...props
}) => {
  const [resetBody, setResetBody] = useState(null);
  const [form] = Form.useForm();

  const editorRef = useRef();

  // handle errors reported by API
  useEffect(() => {
    let firstFieldName = '';
    for (const field in errors) {
      form.setFields([{ name: field, errors: errors[field] }]);
      if (firstFieldName === '') {
        firstFieldName = field;
      }
    }
    form.scrollToField(firstFieldName);
  }, [form, errors]);

  // focus on body input when showing form
  useEffect(() => {
    if (props.visible) {
      if (editorRef.current) {
        setTimeout(() => editorRef.current.focus(), 250);
      }
    }
  }, [props.visible, editorRef]);

  useEffect(() => {
    form.setFieldsValue(data);
  }, [replyId, form]); // eslint-disable-line react-hooks/exhaustive-deps

  // submit data handler
  const submitData = async (values) => {
    Logger.log('debug', `JournalReplyModalForm.submitData(###)`);

    const payload = {};

    // transform values
    for (var key in values) {
      if (values[key]) {
        payload[key] = values[key];
      }
    }

    props.patch(careGuideId, postId, replyId, payload, (success) => {
      if (success) {
        message.success(getI18n().t('feedback_form_success'));
        clearForm();

        if (careGuide?.partnership) {
          MixpanelTracker.mixpanelTrack(
            mixpanelEventsEnum.JOURNAL.EDIT_REPLY_POST,
            {
              careGuideName: `${careGuide.first_name} ${careGuide.last_name}`,
              partnershipName: careGuide.partnership.name,
              careGuideId: careGuide.id,
              partnershipId: careGuide.partnership.id,
              journalPostId: postId,
              journalPostReplyId: replyId,
            }
          );
        } else {
          MixpanelTracker.mixpanelTrack(
            mixpanelEventsEnum.JOURNAL.EDIT_REPLY_POST,
            {
              careGuideName: `${careGuide.first_name} ${careGuide.last_name}`,
              careGuideId: careGuide.id,
              journalPostId: postId,
              journalPostReplyId: replyId,
            }
          );
        }
      } else {
        message.error(getI18n().t('feedback_form_error'));
      }
    });
  };

  const clearForm = () => {
    props.hideForm();
    props.formDestroy();
    form.setFieldsValue({ body: '' });
    setResetBody(Math.random());
    for (const key of Object.keys(data)) {
      form.setFields([{ name: key, errors: [] }]);
    }
  };

  // form submit handler
  const handleFinish = async (values) => {
    Logger.log('debug', `JournalReplyModalForm.handleFinish(###)`);
    if (!props.isSubmitting) {
      await submitData(values);
    }
  };

  // form error handler
  const handleFinishFailed = ({ values, errorFields, outOfDate }) => {
    Logger.log('debug', `JournalReplyModalForm.handleFinishFailed(###)`);
    message.error(getI18n().t('feedback_form_error'));
    if (errorFields && errorFields.length > 0) {
      form.scrollToField(errorFields[0].name);
    }
  };

  // remove error message when input value changes
  const handleValuesChange = (changedValues, allValues) => {
    for (const key of Object.keys(changedValues)) {
      form.setFields([{ name: key, errors: [] }]);
    }
  };

  const onClickOK = () => {
    form.submit();
  };

  return (
    <Translation>
      {(t) => (
        <>
          <Modal
            title={t('journal_replies_form_title_edit')}
            centered
            visible={props.visible}
            onOk={onClickOK}
            onCancel={clearForm}
            footer={[
              <Button
                key="close"
                icon={<CloseCircleOutlined />}
                onClick={clearForm}
              >
                {t('action_close')}
              </Button>,
              <Button
                key="submit"
                type="primary"
                icon={<CheckCircleOutlined />}
                loading={isSubmitting}
                onClick={onClickOK}
              >
                {t('journal_replies_form_button_update')}
              </Button>,
            ]}
            width={600}
            forceRender={true}
          >
            <div className="journal-reply-form">
              <Form
                name="journal_reply_form"
                form={form}
                initialValues={data}
                onFinish={handleFinish}
                onFinishFailed={handleFinishFailed}
                onValuesChange={handleValuesChange}
                validateTrigger="onSubmit"
                requiredMark={true}
                colon={false}
                layout="inline"
                labelWrap={true}
              >
                <BodyInput
                  name="body"
                  label={false}
                  form={form}
                  defaultValue={data['body']}
                  rules={[
                    {
                      required: true,
                      message: t('feedback_validation_required'),
                    },
                    {
                      type: 'string',
                      min: 1,
                      max: 4000,
                      message: t('feedback_validation_char_range', {
                        min: 1,
                        max: 4000,
                      }),
                    },
                  ]}
                  disabled={isSubmitting}
                  reset={resetBody}
                  editorRef={editorRef}
                />

                <div style={{ display: 'none' }}>
                  <Button htmlType="submit"></Button>
                </div>
              </Form>
            </div>
          </Modal>
        </>
      )}
    </Translation>
  );
};

export default JournalReplyModalForm;

Logger.log('silly', `JournalReplyModalForm loaded.`);
