import React, { useState } from "react";
import QueryString from '../../../../../lib/QueryString';
import moment from 'moment';
import CareGuideNoteModalExport from "../containers/CareGuideNoteModalExportContainer";
import { withRouter } from "react-router";
import { Typography } from "antd";
import { Translation } from 'react-i18next';
import { PlusOutlined } from '@ant-design/icons';
import { pathTo } from '../../../Routes';
import { Col, Button, Row, Input, DatePicker } from "antd";
import { BsFileEarmarkArrowDown } from "react-icons/bs";

const { RangePicker } = DatePicker;
const { Title } = Typography;

const CareGuideNoteListHeader = ({ component, showForm, careGuideId, history, order, filter }) => {

    const [openModalExport, setOpenModalExport] = useState(false);
    let params = QueryString.parse(history.location.search);
    const defaultStart = 'start' in params ? moment(params['start']) : null;
    const defaultEnd = 'end' in params ? moment(params['end']) : null;

    const onChangeDateHandler = (dates) => {
        params['start'] = dates && dates[0] ? dates[0].format('YYYY-MM-DD') : '';
        if (params['start'] === '') {
            delete params['start'];
        }
        params['end'] = dates && dates[1] ? dates[1].format('YYYY-MM-DD') : '';
        if (params['end'] === '') {
            delete params['end'];
        }
        const path = pathTo(component, { id: careGuideId });
        history.push(QueryString.append(path, params));
    }

    const onSearch = (value) => {
        params['search'] = value;
        if (params['search'] === '') {
            delete params['search'];
        }
        const path = pathTo(component, { id: careGuideId });
        history.push(QueryString.append(path, params));
    }

    return (
        <Translation>{(t) =>
            <>
                <Row justify="space-between" align="top">
                    <Col>
                        <Title
                            level={2}
                            className="note-title">
                            {t("care_guide_note_list_header_title")}
                        </Title>
                    </Col>
                    <Col>

                        <Input.Search
                            enterButton
                            size="large"
                            placeholder={t("care_guide_note_list_header_search")}
                            allowClear
                            onSearch={onSearch}
                        />
                    </Col>
                    <Col>
                        <RangePicker
                            size="large"
                            showTime
                            format="MM/DD/YYYY"
                            onChange={onChangeDateHandler}
                            value={[defaultStart, defaultEnd]}
                        />
                    </Col>

                    <Col>
                        <Button
                            icon={<BsFileEarmarkArrowDown />}
                            className="export-btn"
                            size="large"
                            onClick={() => setOpenModalExport(true)}
                        >
                            {t('care_guide_note_list_export')}
                        </Button>
                    </Col>
                    <Col>
                        <Button
                            type="primary"
                            size="large"
                            icon={<PlusOutlined />}
                            onClick={() => showForm()}
                        >
                            {t("care_guide_note_list_new_note")}</Button>
                    </Col>
                </Row>
                <CareGuideNoteModalExport
                    order={order}
                    filter={filter}
                    careGuideId={careGuideId}
                    openModalExport={openModalExport}
                    setOpenModalExport={setOpenModalExport} />
            </>
        }</Translation>

    );
};

export default withRouter(CareGuideNoteListHeader);