import { connect } from 'react-redux';

import MainMenuAdmin from '../components/MainMenuAdmin';
import storage from '../../../../lib/Storage';

const mapStateToProps = (state, ownProps) => {
  return {
    activeId: storage.get('local', 'partnershipId'),
    accessAdmin: storage.get('local', 'partnershipData')
      ?.access_role_admin_in_app,
    role: storage.get('local', 'partnershipData')?.role,
    isMenuAdminCollapsed: state.ui.get('isMenuAdminCollapsed'),
    partnershipName: storage.get('local', 'partnershipData')?.name ?? null,
  };
};

const MainMenuAdminContainer = connect(mapStateToProps)(MainMenuAdmin);

export default MainMenuAdminContainer;
