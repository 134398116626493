import { connect } from "react-redux";
import AdminLayout from "../components/AdminLayout";
import { uiChangeMenuAdminCollapsed } from "../../../../state/ui/actions";

import {
  loadUserProfilesPartnerships,
} from "../../../../state/modules/userProfilesPartnerships/actions";
import storage from "../../../../lib/Storage";

const mapStateToProps = (state, ownProps) => {
  const page = 1;
  const limit = 10;
  const orderUserProfilePartnership = "updated_at.asc";

  let defaultPartnershipId = storage.get('local', 'partnershipId');

  return {
    isLoading: state.messages.get("isLoading"),
    isMenuAdminCollapsed: state.ui.get("isMenuAdminCollapsed"),
    updatePassword: state.session.get("updatePassword"),
    defaultPartnershipId: defaultPartnershipId,
    page: page,
    limit: limit,
    orderUserProfilePartnership: orderUserProfilePartnership,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    uiChangeMenuAdminCollapsed: () => {
      dispatch(uiChangeMenuAdminCollapsed());
    },
    loadUserProfilesPartnership: (page, limit, order, filter, cb) => {
      dispatch(loadUserProfilesPartnerships(page, limit, order, filter, cb));
    }
  };
};

const AdminLayoutContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminLayout);

export default AdminLayoutContainer;
