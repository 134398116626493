import api from '../../api';
import Logger from '../../../lib/Logger';

export const TYPES = {
  LIST_TOPIC_REQUEST: 'LEARNING/LIST_TOPIC_REQUEST',
  LIST_TOPIC_SUCCESS: 'LEARNING/LIST_TOPIC_SUCCESS',
  LIST_TOPIC_FAILURE: 'LEARNING/LIST_TOPIC_FAILURE',
  SET_ACTIVE_ITEM_ID: 'LEARNING/SET_ACTIVE_ITEM_ID',
  SET_ACTIVE_ITEM: 'LEARNING/SET_ACTIVE_ITEM',
  SUB_TOPIC_READ_REQUEST: 'LEARNING/SUB_TOPIC_READ_REQUEST',
  SUB_TOPIC_READ_SUCCESS: 'LEARNING/SUB_TOPIC_READ_SUCCESS',
  SUB_TOPIC_READ_FAILURE: 'LEARNING/SUB_TOPIC_READ_FAILURE',
};

export function setActiveItemId(id) {
  Logger.log('debug', `[state.subTopic.actions] setActiveItemId(${id})`);
  return {
    type: TYPES.SET_ACTIVE_ITEM_ID,
    id: id,
  }
}

export function setActiveItem(activeItem) {
  Logger.log('debug', `[state.subTopic.actions] setActiveItem(${activeItem})`);
  return {
    type: TYPES.SET_ACTIVE_ITEM,
    activeItem: activeItem,
  }
}


export function subTopicReadRequest(id) {
  Logger.log('debug', `[resourcesAdmin.actions] subTopicReadRequest(${id})`);
  return {
    type: TYPES.SUB_TOPIC_READ_REQUEST,
    id: id
  }
}

export function subTopicReadSuccess(data) {
  Logger.log('debug', `[resourcesAdmin.actions] subTopicReadSuccess(%j)`, data);
  return {
    type: TYPES.SUB_TOPIC_READ_SUCCESS,
    id: data.id,
    result: data.result,
    receivedAt: Date.now()
  }
}

export function subTopicReadFailure(error) {
  Logger.log('debug', `[resourcesAdmin.actions] subTopicReadFailure(%j)`, error);
  return {
    type: TYPES.SUB_TOPIC_READ_FAILURE,
    error: error
  }
}


export function learningListTopicsRequest(page, limit) {
  Logger.log('debug', `[state.learnings.actions] learningListTopicsRequest(${page}, ${limit}, %j)`);
  return {
    type: TYPES.LIST_TOPIC_REQUEST,
    page: page,
    limit: limit,
  }
}


export function learningListTopicsSuccess(data) {
  Logger.log('debug', `[state.learnings.actions] learningListTopicsSuccess(%j)`, data);
  return {
    type: TYPES.LIST_TOPIC_SUCCESS,
    page: data.page,
    limit: data.limit,
    result: data.result,
    page_count: data.page_count,
    total: data.total,
    receivedAt: Date.now()
  }
}

export function learningListTopicsFailure(error) {
  Logger.log('debug', `[state.learnings.actions] learningListTopicsFailure(%j)`, error);
  return {
    type: TYPES.LIST_TOPIC_FAILURE,
    error: error
  }
}


// API THUNK ACTION CREATORS

export function loadTopics(plan_name, page, limit, cb = function () { }) {
  Logger.log('debug', `[state.learnings.actions] loadTopics(${page}, ${limit}, %j, ###)`);
  return async function (dispatch) {
    dispatch(learningListTopicsRequest(page, limit));

    // call API
    const response = await api.getTopics(plan_name, page, limit);
    let success = false;

    // get topics list success
    if (200 === response.get('status') && response.getIn(['data', 'data']).length !== 0) {

      Logger.log('info', `Get API topics list success. Page: ${page}, Limit: ${limit}`);
      const meta = response.getIn(['data', 'meta'])
      const data = {
        page: meta.pagination.page,
        limit: meta.pagination.pageSize,
        page_count: meta.pagination.pageCount,
        total: meta.pagination.page,
        result: response.getIn(['data', 'data'])
      };

      dispatch(learningListTopicsSuccess(data));
      success = true;

    } else if (1 === page && 200 === response.get('status') && response.getIn(['data', 'data']).length === 0) {

      Logger.log('info', `Get API topics list success [empty]. Page: ${page}, Limit: ${limit}`);

      const data = {
        page: page,
        limit: limit,
        page_count: 0,
        result: []
      };
      dispatch(setActiveItemId(null))
      dispatch(setActiveItem(null))
      dispatch(learningListTopicsSuccess(data));
      success = true;

      // get topics list failure
    } else {
      const data = {
        page: page,
        limit: limit,
        page_count: 0,
        result: []
      };
      dispatch(learningListTopicsSuccess(data));
      dispatch(setActiveItemId(null))
      dispatch(setActiveItem(null))
      Logger.log('info', `Get API topics list failure. Page: ${page}, Limit: ${limit}`);
      dispatch(learningListTopicsFailure(response.getIn(['data', 'error'])));
    }
    // callback function
    cb(success);
  }
}

