import { normalize } from 'normalizr';

import api from '../../api';
import { schema } from '../../schema';
import { addEntities } from '../../actions';
import Logger from '../../../lib/Logger';

export const TYPES = {
  LIST_REQUEST: 'PLANS/LIST_REQUEST',
  LIST_SUCCESS: 'PLANS/LIST_SUCCESS',
  LIST_FAILURE: 'PLANS/LIST_FAILURE',
  ADMIN_LIST_REQUEST: 'PLANS/ADMIN_LIST_REQUEST',
  ADMIN_LIST_SUCCESS: 'PLANS/ADMIN_LIST_SUCCESS',
  ADMIN_LIST_FAILURE: 'PLANS/ADMIN_LIST_FAILURE',
};

export function planListRequest(page, limit, order) {
  Logger.log(
    'debug',
    `[state.plans.actions] planListRequest(${page}, ${limit}, ${order})`
  );
  return {
    type: TYPES.LIST_REQUEST,
    page: page,
    limit: limit,
    order: order,
  };
}

export function planListSuccess(data) {
  Logger.log('debug', `[state.plans.actions] planListSuccess(%j)`, data);
  return {
    type: TYPES.LIST_SUCCESS,
    page: data.page,
    limit: data.limit,
    order: data.order,
    result: data.result,
    total: data.total,
    receivedAt: Date.now(),
  };
}

export function planListFailure(error) {
  Logger.log('debug', `[state.plans.actions] planListFailure(%j)`, error);
  return {
    type: TYPES.LIST_FAILURE,
    error: error,
  };
}

export function planAdminListRequest(page, limit, order) {
  Logger.log(
    'debug',
    `[state.plans.actions] planAdminListRequest(${page}, ${limit}, ${order})`
  );
  return {
    type: TYPES.ADMIN_LIST_REQUEST,
    page: page,
    limit: limit,
    order: order,
  };
}

export function planAdminListSuccess(data) {
  Logger.log('debug', `[state.plans.actions] planAdminListSuccess(%j)`, data);
  return {
    type: TYPES.ADMIN_LIST_SUCCESS,
    page: data.page,
    limit: data.limit,
    order: data.order,
    result: data.result,
    total: data.total,
    receivedAt: Date.now(),
  };
}

export function planAdminListFailure(error) {
  Logger.log('debug', `[state.plans.actions] planAdminListFailure(%j)`, error);
  return {
    type: TYPES.ADMIN_LIST_FAILURE,
    error: error,
  };
}

// API THUNK ACTION CREATORS

export function loadPlansAdmin(
  page = 1,
  limit = 10,
  order = null,
  cb = function () {}
) {
  Logger.log(
    'debug',
    `[plans.actions] loadPlansAdmin(${page}, ${limit}, ${order})`
  );

  return async function (dispatch) {
    dispatch(planAdminListRequest(page, limit, order));

    // call API
    const response = await api.getPlansAdmin(page, limit, order);
    let success = false;
    let result = [];

    // get plans admin list success
    if (200 === response.get('status')) {
      Logger.log(
        'info',
        `Get API plans admin list success. Page: ${page}, Limit: ${limit}, Order: ${order}.`
      );

      const normalizedEntities = normalize(
        response.getIn(['data', 'plans_admin']),
        [schema.planAdmin]
      );
      const data = {
        page: response.getIn(['data', 'page']),
        limit: response.getIn(['data', 'limit']),
        order: order,
        total: response.getIn(['data', 'total']),
        result: normalizedEntities.result,
      };
      
      result = normalizedEntities.result;
      dispatch(addEntities(normalizedEntities));
      dispatch(planAdminListSuccess(data));
      success = true;
    } else if (1 === page && 204 === response.get('status')) {
      Logger.log(
        'info',
        `Get API plans admin success [empty]. Page: ${page}, Limit: ${limit}, Order: ${order}.`
      );
      const data = {
        page: page,
        limit: limit,
        order: order,
        total: 0,
        result: [],
      };
      dispatch(planAdminListSuccess(data));
      success = true;

      // get plans admin list failure
    } else {
      Logger.log(
        'info',
        `Get API plans admin list failure. Page: ${page}, Limit: ${limit}, Order: ${order}.`
      );
      dispatch(planAdminListFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success, result);
  };
}

export function loadPlans(
  page = 1,
  limit = 10,
  order = null,
  cb = function () {}
) {
  Logger.log(
    'debug',
    `[state.plans.actions] loadPlans(${page}, ${limit}, ${order}, ###)`
  );

  return async function (dispatch) {
    dispatch(planListRequest(page, limit, order));

    // call API
    const response = await api.getPlans(page, limit, order);
    let success = false;

    // get plans admin list success
    if (200 === response.get('status')) {
      Logger.log(
        'info',
        `Get API plans admin list success. Page: ${page}, Limit: ${limit}, Order: ${order}.`
      );

      const normalizedEntities = normalize(response.getIn(['data', 'plans']), [
        schema.plan,
      ]);
      const data = {
        page: response.getIn(['data', 'page']),
        limit: response.getIn(['data', 'limit']),
        order: order,
        total: response.getIn(['data', 'total']),
        result: normalizedEntities.result,
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(planListSuccess(data));
      success = true;

      // get plans admin list failure
    } else {
      Logger.log(
        'info',
        `Get API plans admin list failure. Page: ${page}, Limit: ${limit}, Order: ${order}.`
      );
      dispatch(planListFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  };
}

Logger.log('silly', `state.resources.actions loaded.`);
