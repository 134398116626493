import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { Translation } from 'react-i18next';
import { Avatar, Button, Dropdown, Menu } from 'antd';
import { DownOutlined, UserOutlined } from '@ant-design/icons';
import { pathTo, hasRoute, getRouteFromPath } from '../../../Routes';
import imageURL from '../../../elements/lib/imageURL';
import {
  MixpanelTracker,
  mixpanelEventsEnum,
} from '../../../../../lib/Mixpanel';

const CareGuidesSelector = ({
  list,
  load,
  setActive,
  setAccess,
  defaultId,
  defaultLabel,
  defaultImage,
  disabled = false,
  ...props
}) => {
  const [currentId, setCurrentId] = useState(defaultId);
  const [currentLabel, setCurrentLabel] = useState(defaultLabel);
  const [currentImage, setCurrentImage] = useState(defaultImage);
  // const {width} = useWindowDimensions();

  useEffect(() => {
    setCurrentId(defaultId);
  }, [defaultId, setCurrentId]);

  useEffect(() => {
    setCurrentLabel(defaultLabel);
  }, [defaultLabel, setCurrentLabel]);

  useEffect(() => {
    setCurrentImage(defaultImage);
  }, [defaultImage, setCurrentImage]);

  const clickHandler = (e) => {
    const id = e.item.props.value;
    const selectedCareGuide = list.filter((x) => x.id === id)[0];
    setCurrentId(selectedCareGuide.id);
    if (selectedCareGuide.partnership) {
      setAccess({
        access_role_admin_in_care_guide:
          selectedCareGuide.partnership.access_role_admin_in_care_guide,
      });
    }

    setCurrentLabel(
      selectedCareGuide.first_name + ' ' + selectedCareGuide.last_name
    );
    setActive(id);
    const currentRoute = getRouteFromPath(props.location.pathname);
    if (
      currentRoute &&
      props.careGuideScreens.includes(currentRoute.screen) &&
      currentRoute.params.id !== id
    ) {
      if (hasRoute(currentRoute.screen, { id: id })) {
        if (selectedCareGuide.partnership) {
          MixpanelTracker.mixpanelTrack(
            mixpanelEventsEnum.PAGES.ACCESS_CARE_GUIDE,
            {
              careGuideName: `${selectedCareGuide.first_name} ${selectedCareGuide.last_name}`,
              partnershipName: selectedCareGuide.partnership.name,
              careGuideId: selectedCareGuide.id,
              partnershipId: selectedCareGuide.partnership.id,
              page: currentRoute.screen,
            }
          );
          MixpanelTracker.mixpanelTrack(mixpanelEventsEnum.PAGES.ACCESS_PAGE, {
            careGuideName: `${selectedCareGuide.first_name} ${selectedCareGuide.last_name}`,
            partnershipName: selectedCareGuide.partnership.name,
            careGuideId: selectedCareGuide.id,
            partnershipId: selectedCareGuide.partnership.id,
            page: currentRoute.screen,
          });
        } else {
          MixpanelTracker.mixpanelTrack(
            mixpanelEventsEnum.PAGES.ACCESS_CARE_GUIDE,
            {
              careGuideName: `${selectedCareGuide.first_name} ${selectedCareGuide.last_name}`,
              careGuideId: selectedCareGuide.id,
              page: currentRoute.screen,
            }
          );
          MixpanelTracker.mixpanelTrack(mixpanelEventsEnum.PAGES.ACCESS_PAGE, {
            careGuideName: `${selectedCareGuide.first_name} ${selectedCareGuide.last_name}`,
            careGuideId: selectedCareGuide.id,
            page: currentRoute.screen,
          });
        }

        props.history.push(pathTo(currentRoute.screen, { id: id }));
      }
    }
  };

  const menu = (
    <Menu onClick={clickHandler}>
      {list.map((x) => (
        <Menu.Item key={x.id} value={x.id}>
          {x.first_name + ' ' + x.last_name}
        </Menu.Item>
      ))}
    </Menu>
  );

  const imageSize = 32;
  const careGuideImageUrl =
    currentImage &&
    currentImage.file_name &&
    currentImage.file_extension &&
    currentImage.file_directory
      ? imageURL(
          currentImage.file_directory,
          currentImage.file_name,
          currentImage.file_extension,
          imageSize,
          imageSize,
          'crop'
        )
      : null;

  return (
    <Translation>
      {(t) => (
        <>
          {props.total > 0 ? (
            <div className="care-guide-selector">
              {currentId ? (
                <Link to={pathTo('CareGuideDetailScreen', { id: currentId })}>
                  <Avatar
                    size={imageSize}
                    src={careGuideImageUrl}
                    icon={<UserOutlined />}
                  />
                </Link>
              ) : (
                <Avatar
                  size={imageSize}
                  src={careGuideImageUrl}
                  icon={<UserOutlined />}
                />
              )}
              {list.length > 1 ? (
                <Dropdown
                  overlay={menu}
                  disabled={disabled}
                  trigger={['click']}
                >
                  <Button onClick={null}>
                    {currentLabel} <DownOutlined />
                  </Button>
                </Dropdown>
              ) : (
                <Button onClick={null}>{currentLabel}</Button>
              )}
            </div>
          ) : null}
        </>
      )}
    </Translation>
  );
};

export default withRouter(CareGuidesSelector);
