import {normalize} from 'normalizr';

import api from '../../api';
import {schema} from '../../schema';
import {addEntities} from '../../actions';
import Logger from '../../../lib/Logger';

// ACTION TYPES


export const TYPES = {
  USER_PROFILES_PARTNERSHIP_ADMIN_LIST_REQUEST: 'USER_PROFILES_PARTNERSHIP_ADMIN_LIST_REQUEST',
  USER_PROFILES_PARTNERSHIP_ADMIN_LIST_SUCCESS: 'USER_PROFILES_PARTNERSHIP_ADMIN_LIST_SUCCESS',
  USER_PROFILES_PARTNERSHIP_ADMIN_LIST_FAILURE: 'USER_PROFILES_PARTNERSHIP_ADMIN_LIST_FAILURE',
};


// ACTION CREATORS

export function userProfilesPartnershipAdminListRequest(page, limit, order, filter) {
  Logger.log('debug', `[userProfilesPartnershipsAdmin.actions] userProfilesPartnershipAdminListRequest(${page}, ${limit}, ${order}, %j)`, filter);
  return {
    type: TYPES.USER_PROFILES_PARTNERSHIP_ADMIN_LIST_REQUEST,
    page: page,
    limit: limit,
    order: order,
    filter: filter
  }
}

export function userProfilesPartnershipAdminListSuccess(data) {
  Logger.log('debug', `[userProfilesPartnershipsAdmin.actions] userProfilesPartnershipAdminListSuccess(%j)`, data);
  return {
    type: TYPES.USER_PROFILES_PARTNERSHIP_ADMIN_LIST_SUCCESS,
    page: data.page,
    limit: data.limit,
    order: data.order,
    result: data.result,
    total: data.total,
    receivedAt: Date.now()
  }
}

export function userProfilesPartnershipAdminListFailure(error) {
  Logger.log('debug', `[userProfilesPartnershipsAdmin.actions] userProfilesPartnershipAdminListFailure(%j)`, error);
  return {
    type: TYPES.USER_PROFILES_PARTNERSHIP_ADMIN_LIST_FAILURE,
    error: error
  }
}


// API THUNK ACTION CREATORS

export function loadUserProfilesPartnershipsAdmin(parntership_id = null, page=1, limit=10, order=null, filter=null, cb=function(){}) {
  Logger.log('debug', `[userProfilesPartnershipAdmin.actions] loadUserProfilesPartnershipsAdmin(${page}, ${limit}, ${order}, %j, ###)`, filter);

  return async function(dispatch) {
    dispatch(userProfilesPartnershipAdminListRequest(page, limit, order, filter));

    // call API
    const response = await api.getUserProfilesPartnershipsAdmin(parntership_id, page, limit, order, filter);
    let success = false;

    // get user profiles partnerships admin list success
    if (200 === response.get('status')) {

      Logger.log('info', `Get API user profiles partnerships admin list success. Page: ${page}, Limit: ${limit}, Order: ${order}.`);

      const normalizedEntities = normalize(response.getIn(['data', 'user_profiles_partnerships_admin']), [schema.userProfilePartnershipAdmin]);
      const data = {
        page: response.getIn(['data', 'page']),
        limit: response.getIn(['data', 'limit']),
        order: order,
        total: response.getIn(['data', 'total']),
        result: normalizedEntities.result
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(userProfilesPartnershipAdminListSuccess(data));
      success = true;

    } else if (1 === page && 204 === response.get('status')) {

      Logger.log('info', `Get API user profiles partnerships admin success [empty]. Page: ${page}, Limit: ${limit}, Order: ${order}.`);
      const data = {
        page: page,
        limit: limit,
        order: order,
        total: 0,
        result: []
      };
      dispatch(userProfilesPartnershipAdminListSuccess(data));
      success = true;
      
    // get user profiles partnerships admin list failure
    } else {
      Logger.log('info', `Get API user profiles partnerships admin list failure. Page: ${page}, Limit: ${limit}, Order: ${order}.`);
      dispatch(userProfilesPartnershipAdminListFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  }
}


Logger.log('silly', `userProfilesPartnershipsAdmin.actions loaded.`);
