import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { Translation } from 'react-i18next';
import { Avatar, Button, Dropdown, Menu } from 'antd';
import { DownOutlined, ShareAltOutlined } from '@ant-design/icons';
import {
  MixpanelTracker,
  mixpanelEventsEnum,
} from '../../../../../lib/Mixpanel';
import { pathTo, hasRoute, getRouteFromPath } from '../../../Routes';
import storage from '../../../../../lib/Storage';

const PartnershipsSelector = ({
  list,
  load,
  limit,
  setActive,
  defaultId,
  defaultLabel,
  defaultImage,
  disabled = false,
  setActiveCareGuide,
  setAccessCareGuide,
  pageUserProfilePartnership,
  orderUserProfilePartnership,
  ...props
}) => {
  const [currentId, setCurrentId] = useState(defaultId);
  const [currentLabel, setCurrentLabel] = useState(defaultLabel);
  // const {width} = useWindowDimensions();

  useEffect(() => {
    setCurrentId(defaultId);
  }, [defaultId, setCurrentId]);

  useEffect(() => {
    setCurrentLabel(defaultLabel);
  }, [defaultLabel, setCurrentLabel]);

  const clickHandler = (e) => {
    const id = e.item.props.value;
    const selectedPartnership = list.filter((x) => x.id === id)[0];

    setCurrentId(selectedPartnership.partnership_id);
    setCurrentLabel(selectedPartnership.partnership.name);
    setActive(selectedPartnership.id);

    storage.set(
      'local',
      'userProfilePartnershipPage',
      pageUserProfilePartnership
    );
    storage.set('local', 'partnershipId', selectedPartnership.partnership_id);
    storage.set('local', 'partnershipData', {
      access_role_admin_in_care_guide:
        selectedPartnership.partnership.access_role_admin_in_care_guide,
      access_role_admin_in_app:
        selectedPartnership.partnership.access_role_admin_in_app,
      access_tabs_in_app: selectedPartnership.partnership.access_tabs_in_app,
      role: selectedPartnership.role,
      name: selectedPartnership.partnership.name,
    });

    setActiveCareGuide(null);
    setAccessCareGuide({ access_role_admin_in_care_guide: null });
    const currentRoute = getRouteFromPath(props.location.pathname);

    if (
      currentRoute &&
      props.partnershipAdminScreens.includes(currentRoute.screen) &&
      currentRoute.params.id !== id
    ) {
      if (hasRoute(currentRoute.screen, { id: id })) {
        MixpanelTracker.mixpanelTrack(
          mixpanelEventsEnum.PAGES.ACCESS_PAGE_ADMINISTRATOR,
          {
            partnershipName: selectedPartnership.partnership.name,
            partnershipId: selectedPartnership.partnership.id,
            page: currentRoute.screen,
          }
        );
        props.history.push(pathTo(currentRoute.screen, { id: id }));
      }
    }
  };

  const menu = (
    <Menu onClick={clickHandler}>
      {list.map((x) => (
        <Menu.Item key={x.id} value={x.id}>
          {x.partnership.name}
        </Menu.Item>
      ))}
    </Menu>
  );

  const imageSize = 32;

  useEffect(() => {
    load(pageUserProfilePartnership, limit, orderUserProfilePartnership);
  }, [pageUserProfilePartnership, limit, orderUserProfilePartnership, load]);

  return (
    <Translation>
      {(t) => (
        <>
          {props.total > 0 ? (
            <div className="care-guide-selector">
              {currentId && (
                <Avatar size={imageSize} icon={<ShareAltOutlined />} />
              )}
              {list.length > 1 ? (
                <Dropdown
                  overlay={menu}
                  disabled={disabled}
                  trigger={['click']}
                >
                  <Button onClick={null}>
                    {currentLabel}
                    <DownOutlined />
                  </Button>
                </Dropdown>
              ) : (
                <Button onClick={null}>{currentLabel}</Button>
              )}
            </div>
          ) : null}
        </>
      )}
    </Translation>
  );
};

export default withRouter(PartnershipsSelector);
