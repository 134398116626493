import api from '../../api';
import Logger from '../../../lib/Logger';
import {addEntities, sessionCreateSuccess} from '../../actions';
import Auth from '../../../lib/Auth';

export const REGISTER_STEP1_REQUEST = 'REGISTER_STEP1_REQUEST';
export const REGISTER_STEP1_SUCCESS = 'REGISTER_STEP1_SUCCESS';
export const REGISTER_STEP1_FAILURE = 'REGISTER_STEP1_FAILURE';
export const REGISTER_STEP1_FORM_DESTROY = 'REGISTER_STEP1_FORM_DESTROY';
export const REGISTER_STEP2_REQUEST = 'REGISTER_STEP2_REQUEST';
export const REGISTER_STEP2_SUCCESS = 'REGISTER_STEP2_SUCCESS';
export const REGISTER_STEP2_FAILURE = 'REGISTER_STEP2_FAILURE';
export const REGISTER_STEP2_FORM_DESTROY = 'REGISTER_STEP2_FORM_DESTROY';
export const REGISTER_CREATE_ACCOUNT_REQUEST = 'REGISTER_CREATE_ACCOUNT_REQUEST';
export const REGISTER_CREATE_ACCOUNT_SUCCESS = 'REGISTER_CREATE_ACCOUNT_SUCCESS';
export const REGISTER_CREATE_ACCOUNT_FAILURE = 'REGISTER_CREATE_ACCOUNT_FAILURE';
export const TERMS_OF_SERVICE_CURRENT_REQUEST = 'TERMS_OF_SERVICE_CURRENT_REQUEST';
export const TERMS_OF_SERVICE_CURRENT_SUCCESS = 'TERMS_OF_SERVICE_CURRENT_SUCCESS';
export const TERMS_OF_SERVICE_CURRENT_FAILURE = 'TERMS_OF_SERVICE_CURRENT_FAILURE';
export const REGISTER_CREATE_ACCOUNT_OWNER_REQUEST = 'REGISTER_CREATE_ACCOUNT_OWNER_REQUEST';
export const REGISTER_CREATE_ACCOUNT_OWNER_SUCCESS = 'REGISTER_CREATE_ACCOUNT_OWNER_SUCCESS';
export const REGISTER_CREATE_ACCOUNT_OWNER_FAILURE = 'REGISTER_CREATE_ACCOUNT_OWNER_FAILURE';
export const REGISTER_CREATE_ACCOUNT_ADMIN_REQUEST = 'REGISTER_CREATE_ACCOUNT_ADMIN_REQUEST';
export const REGISTER_CREATE_ACCOUNT_ADMIN_SUCCESS = 'REGISTER_CREATE_ACCOUNT_ADMIN_SUCCESS';
export const REGISTER_CREATE_ACCOUNT_ADMIN_FAILURE = 'REGISTER_CREATE_ACCOUNT_ADMIN_FAILURE';
export const REGISTER_CREATE_ACCOUNT_CLIENT_REQUEST = 'REGISTER_CREATE_ACCOUNT_CLIENT_REQUEST';
export const REGISTER_CREATE_ACCOUNT_CLIENT_SUCCESS = 'REGISTER_CREATE_ACCOUNT_CLIENT_SUCCESS';
export const REGISTER_CREATE_ACCOUNT_CLIENT_FAILURE = 'REGISTER_CREATE_ACCOUNT_CLIENT_FAILURE';

export function registerStep1Request(data) {
  Logger.log('debug', `[state.register.actions] registerStep1Request(###)`, data);
  return {
    type: REGISTER_STEP1_REQUEST,
  }
}

export function registerStep1Success(data) {
  Logger.log('debug', `[state.register.actions] registerStep1Success(###)`, data);
  return {
    type: REGISTER_STEP1_SUCCESS,
    id: data.id,
    receivedAt: Date.now()
  }
}

export function registerStep1Failure(error) {
  Logger.log('debug', `[state.register.actions] registerStep1Failure(###)`, error);
  return {
    type: REGISTER_STEP1_FAILURE,
    error: error
  }
}

export function registerStep1FormDestroy(formState=null) {
  Logger.log('debug', `[state.register.actions] registerStep1FormDestroy(###)`, formState);
  return {
    type: REGISTER_STEP1_FORM_DESTROY,
    form: formState
  }
}

export function registerStep2Request(data) {
  Logger.log('debug', `[state.register.actions] registerStep2Request(###)`, data);
  return {
    type: REGISTER_STEP2_REQUEST,
  }
}

export function registerStep2Success(data) {
  Logger.log('debug', `[state.register.actions] registerStep2Success(###)`, data);
  return {
    type: REGISTER_STEP2_SUCCESS,
    id: data.id,
    receivedAt: Date.now()
  }
}

export function registerStep2Failure(error) {
  Logger.log('debug', `[state.register.actions] registerStep2Failure(###)`, error);
  return {
    type: REGISTER_STEP2_FAILURE,
    error: error
  }
}

export function registerStep2FormDestroy(formState=null) {
  Logger.log('debug', `[state.register.actions] registerStep2FormDestroy(###)`, formState);
  return {
    type: REGISTER_STEP2_FORM_DESTROY,
    form: formState
  }
}



export function registerCreateAccountRequest(data) {
  Logger.log('debug', `[state.register.actions] registerCreateAccountRequest(###)`, data);
  return {
    type: REGISTER_CREATE_ACCOUNT_REQUEST,
  }
}

export function registerCreateAccountSuccess(data) {
  Logger.log('debug', `[state.register.actions] registerCreateAccountSuccess(###)`, data);
  return {
    type: REGISTER_CREATE_ACCOUNT_SUCCESS,
    id: data.id,
    account_id: data.account_id,
    care_guide_id: data.care_guide_id,
    planId: data.plan_id,
    subscriptionCycle: data.subscription_cycle,
    receivedAt: Date.now()
  }
}

export function registerCreateAccountFailure(error) {
  Logger.log('debug', `[state.register.actions] registerCreateAccountFailure(###)`, error);
  return {
    type: REGISTER_CREATE_ACCOUNT_FAILURE,
    error: error
  }
}

export function registerCreateAccountOwnerRequest(data) {
  Logger.log('debug', `[state.register.actions] registerCreateAccountOwnerRequest(###)`, data);
  return {
    type: REGISTER_CREATE_ACCOUNT_OWNER_REQUEST,
  }
}

export function registerCreateAccountOwnerSuccess(data) {
  Logger.log('debug', `[state.register.actions] registerCreateAccountOwnerSuccess(###)`, data);
  return {
    type: REGISTER_CREATE_ACCOUNT_OWNER_SUCCESS,
    id: data.id,
    account_id: data.account_id,
    care_guide_id: data.care_guide_id,
    planId: data.plan_id,
    subscriptionCycle: data.subscription_cycle,
    receivedAt: Date.now()
  }
}

export function registerCreateAccountOwnerFailure(error) {
  Logger.log('debug', `[state.register.actions] registerCreateAccountOwnerFailure(###)`, error);
  return {
    type: REGISTER_CREATE_ACCOUNT_OWNER_FAILURE,
    error: error
  }
}

export function registerCreateAccountAdminRequest(data) {
  Logger.log('debug', `[state.register.actions] registerCreateAccountAdminRequest(###)`, data);
  return {
    type: REGISTER_CREATE_ACCOUNT_ADMIN_REQUEST,
  }
}

export function registerCreateAccountAdminSuccess(data) {
  Logger.log('debug', `[state.register.actions] registerCreateAccountAdminSuccess(###)`, data);
  return {
    type: REGISTER_CREATE_ACCOUNT_ADMIN_SUCCESS,
    id: data.id,
    subscriptionCycle: data.subscription_cycle,
    receivedAt: Date.now()
  }
}

export function registerCreateAccountAdminFailure(error) {
  Logger.log('debug', `[state.register.actions] registerCreateAccountAdminFailure(###)`, error);
  return {
    type: REGISTER_CREATE_ACCOUNT_ADMIN_FAILURE,
    error: error
  }
}

export function registerCreateAccountClientRequest(data) {
  Logger.log('debug', `[state.register.actions] registerCreateAccountClientRequest(###)`, data);
  return {
    type: REGISTER_CREATE_ACCOUNT_CLIENT_REQUEST,
  }
}

export function registerCreateAccountClientSuccess(data) {
  Logger.log('debug', `[state.register.actions] registerCreateAccountClientSuccess(###)`, data);
  return {
    type: REGISTER_CREATE_ACCOUNT_CLIENT_SUCCESS,
    id: data.id,
    account_id: data.account_id,
    care_guide_id: data.care_guide_id,
    planId: data.plan_id,
    subscriptionCycle: data.subscription_cycle,
    receivedAt: Date.now()
  }
}

export function registerCreateAccountClientFailure(error) {
  Logger.log('debug', `[state.register.actions] registerCreateAccountClientFailure(###)`, error);
  return {
    type: REGISTER_CREATE_ACCOUNT_CLIENT_FAILURE,
    error: error
  }
}


export function termsOfServiceCurrentRequest(page, limit) {
  Logger.log('debug', `[state.register.actions] termsOfServiceCurrentRequest(${page}, ${limit})`);
  return {
    type: TERMS_OF_SERVICE_CURRENT_REQUEST
  }
}

export function termsOfServiceCurrentSuccess(data) {
  Logger.log('debug', `[state.register.actions] termsOfServiceCurrentSuccess(###)`, data);
  return {
    type: TERMS_OF_SERVICE_CURRENT_SUCCESS,
    id: data.id,
    text: data.text,
    version: data.version,
    publish_date: data.publish_date,
    receivedAt: Date.now()
  }
}

export function termsOfServiceCurrentFailure(error) {
  Logger.log('debug', `[state.register.actions] termsOfServiceCurrentFailure(###)`, error);
  return {
    type: TERMS_OF_SERVICE_CURRENT_FAILURE,
    error: error
  }
}

// API THUNK ACTION CREATORS

export function registerStep1(data, cb=function(){}) {
  Logger.log('debug', `[state.register.actions] registerStep1(###, ###)`);

  return async function(dispatch) {
    dispatch(registerStep1Request(data));

    // call API
    const response = await api.postRegisterStep1(data);
    let success = false;
    let registrationId = null;
    let registrationCode = null;
    let partnershipId = null;
    // post register step 1 success
    if (201 === response.get('status')) {

      Logger.log('info', `POST API register step 1 success.`);
      success = true;

      const registerSuccessData = {
        id: response.getIn(['data', 'registration_1', 'id']),
        code: response.getIn(['data', 'registration_1', 'registration_code']),
      };

      dispatch(registerStep1Success(registerSuccessData));
      registrationId = registerSuccessData.id;
      registrationCode = registerSuccessData.code?.type_user;
      partnershipId = registerSuccessData.code?.partnership?.id;

    // get register step 1 failure
    } else {
      Logger.log('info', `POST API register step 1 failure.`);
      dispatch(registerStep1Failure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success, registrationId, registrationCode, partnershipId);
  }
}

export function registerStep2(data, cb=function(){}) {
  Logger.log('debug', `[state.register.actions] registerStep2(###, ###)`);

  return async function(dispatch) {
    dispatch(registerStep2Request(data));

    // call API
    const response = await api.postRegisterStep2(data);
    let success = false;
    let registrationId = null;
    let registrationCode = null;

    // post register step 2 success
    if (201 === response.get('status')) {

      Logger.log('info', `POST API register step 2 success.`);
      success = true;

      const registerSuccessData = {
        id: response.getIn(['data', 'registration_2', 'id']),
        code: response.getIn(['data', 'registration_2', 'registration_code']),

      };

      dispatch(registerStep2Success(registerSuccessData));
      registrationId = registerSuccessData.id;
      registrationCode = registerSuccessData.code?.type_user;
    // get register step 2 failure
    } else {
      Logger.log('info', `POST API register step 2 failure.`);
      dispatch(registerStep2Failure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success, registrationId, registrationCode);
  }
}

export function registerCreateAccount(data, cb=function(){}) {
  Logger.log('debug', `[state.register.actions] registerCreateAccount(###, ###)`);

  return async function(dispatch) {
    dispatch(registerCreateAccountRequest(data));

    // call API
    const response = await api.postRegisterCreateAccount(data);
    let success = false;
    let accountId = null;
    let careGuideId = null;
    let consentURL = null;
    let userID = null;
    let price = null;

    // post register create account success
    if (201 === response.get('status')) {

      Logger.log('info', `POST API register create account success.`);
      success = true;

      const registerSuccessData = {
        id: response.getIn(['data', 'registration_create_account', 'id']),
        care_guide_id: response.getIn(['data', 'registration_create_account', 'care_guide_id']),
        account_id: response.getIn(['data', 'registration_create_account', 'account_id']),
        user_id: response.getIn(['data', 'registration_create_account', 'user_id']),
        profile_id: response.getIn(['data', 'registration_create_account', 'profile_id']),
        username: response.getIn(['data', 'registration_create_account', 'username']),
        token: response.getIn(['data', 'registration_create_account', 'token']),
        expiration: response.getIn(['data', 'registration_create_account', 'expiration']),
        plan_id: response.getIn(['data', 'registration_create_account', 'plan', 'id']),
        consent_url: response.getIn(['data', 'registration_create_account', 'plan', 'consent_url']),
        subscription_cycle: response.getIn(['data', 'registration_create_account', 'subscription_cycle']),
      };

      dispatch(registerCreateAccountSuccess(registerSuccessData));
      careGuideId = registerSuccessData.care_guide_id;
      accountId = registerSuccessData.account_id;
      consentURL = registerSuccessData.consent_url;
      userID = registerSuccessData.user_id;
      price = registerSuccessData.subscription_cycle === 'M'
        ? response.getIn(['data', 'registration_create_account', 'plan', 'price_month'])
        : response.getIn(['data', 'registration_create_account', 'plan', 'price_year']);
      const tokenClaims = Auth.parseJwt(registerSuccessData.token);

      // login user
      dispatch(sessionCreateSuccess({
        authToken: registerSuccessData.token,
        authIssuedAt: 'iat' in tokenClaims ? tokenClaims.iat : null,
        authExpires: 'exp' in tokenClaims ? tokenClaims.exp : null,
        userId: registerSuccessData.user_id,
        username: registerSuccessData.username,
        profileId: registerSuccessData.profile_id,
        remember: true,
        remember_username: false,
      }));
      
    // get register create account failure
    } else {
      Logger.log('info', `POST API register create account failure.`);
      dispatch(registerCreateAccountFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success, accountId, careGuideId, userID, consentURL, price);
  }
}

export function registerCreateAccountOwner(data, cb=function(){}) {
  Logger.log('debug', `[state.register.actions] registerCreateAccountOwner(###, ###)`);

  return async function(dispatch) {
    dispatch(registerCreateAccountOwnerRequest(data));

    // call API
    const response = await api.postRegisterCreateAccountOwner(data);
    let success = false;
    let accountId = null;

    // post register create account owner success
    if (201 === response.get('status')) {

      Logger.log('info', `POST API register create account owner success.`);
      success = true;

      const registerSuccessData = {
        id: response.getIn(['data', 'registration_create_account', 'id']),
        care_guide_id: response.getIn(['data', 'registration_create_account', 'care_guide_id']),
        account_id: response.getIn(['data', 'registration_create_account', 'account_id']),
        user_id: response.getIn(['data', 'registration_create_account', 'user_id']),
        profile_id: response.getIn(['data', 'registration_create_account', 'profile_id']),
        username: response.getIn(['data', 'registration_create_account', 'username']),
        token: response.getIn(['data', 'registration_create_account', 'token']),
        expiration: response.getIn(['data', 'registration_create_account', 'expiration']),
        plan_id: response.getIn(['data', 'registration_create_account', 'plan', 'id']),
        consent_url: response.getIn(['data', 'registration_create_account', 'plan', 'consent_url']),
        subscription_cycle: response.getIn(['data', 'registration_create_account', 'subscription_cycle']),
      };

      dispatch(registerCreateAccountOwnerSuccess(registerSuccessData));
      accountId = registerSuccessData.account_id;
      const tokenClaims = Auth.parseJwt(registerSuccessData.token);

      // login user
      dispatch(sessionCreateSuccess({
        authToken: registerSuccessData.token,
        authIssuedAt: 'iat' in tokenClaims ? tokenClaims.iat : null,
        authExpires: 'exp' in tokenClaims ? tokenClaims.exp : null,
        userId: registerSuccessData.user_id,
        username: registerSuccessData.username,
        profileId: registerSuccessData.profile_id,
        remember: true,
        remember_username: false,
      }));
      
    // get register create account owner failure
    } else {
      Logger.log('info', `POST API register create account owner failure.`);
      dispatch(registerCreateAccountOwnerFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success, accountId);
  }
}

export function registerCreateAccountAdmin(data, cb=function(){}) {
  Logger.log('debug', `[state.register.actions] registerCreateAccountAdmin(###, ###)`);

  return async function(dispatch) {
    dispatch(registerCreateAccountAdminRequest(data));

    // call API
    const response = await api.postRegisterCreateAccountAdmin(data);
    let success = false;
    let accountId = null;

    // post register create account admin success
    if (201 === response.get('status')) {

      Logger.log('info', `POST API register create account admin success.`);
      success = true;

      const registerSuccessData = {
        id: response.getIn(['data', 'registration_create_account', 'id']),
        care_guide_id: response.getIn(['data', 'registration_create_account', 'care_guide_id']),
        account_id: response.getIn(['data', 'registration_create_account', 'account_id']),
        user_id: response.getIn(['data', 'registration_create_account', 'user_id']),
        profile_id: response.getIn(['data', 'registration_create_account', 'profile_id']),
        username: response.getIn(['data', 'registration_create_account', 'username']),
        token: response.getIn(['data', 'registration_create_account', 'token']),
        expiration: response.getIn(['data', 'registration_create_account', 'expiration']),
        plan_id: response.getIn(['data', 'registration_create_account', 'plan', 'id']),
        consent_url: response.getIn(['data', 'registration_create_account', 'plan', 'consent_url']),
        subscription_cycle: response.getIn(['data', 'registration_create_account', 'subscription_cycle']),
      };

      dispatch(registerCreateAccountAdminSuccess(registerSuccessData));
      accountId = registerSuccessData.account_id;
      const tokenClaims = Auth.parseJwt(registerSuccessData.token);

      // login user
      dispatch(sessionCreateSuccess({
        authToken: registerSuccessData.token,
        authIssuedAt: 'iat' in tokenClaims ? tokenClaims.iat : null,
        authExpires: 'exp' in tokenClaims ? tokenClaims.exp : null,
        userId: registerSuccessData.user_id,
        username: registerSuccessData.username,
        profileId: registerSuccessData.profile_id,
        remember: true,
        remember_username: false,
      }));
      
    // get register create account admin failure
    } else {
      Logger.log('info', `POST API register create account admin failure.`);
      dispatch(registerCreateAccountAdminFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success, accountId);
  }
}

export function registerCreateAccountClient(data, cb=function(){}) {
  Logger.log('debug', `[state.register.actions] registerCreateAccountClient(###, ###)`);

  return async function(dispatch) {
    dispatch(registerCreateAccountClientRequest(data));

    // call API
    const response = await api.postRegisterCreateAccountClient(data);
    let success = false;
    let accountId = null;

    // post register create account client success
    if (201 === response.get('status')) {

      Logger.log('info', `POST API register create account client success.`);
      success = true;

      const registerSuccessData = {
        id: response.getIn(['data', 'registration_create_account', 'id']),
        care_guide_id: response.getIn(['data', 'registration_create_account', 'care_guide_id']),
        account_id: response.getIn(['data', 'registration_create_account', 'account_id']),
        user_id: response.getIn(['data', 'registration_create_account', 'user_id']),
        profile_id: response.getIn(['data', 'registration_create_account', 'profile_id']),
        username: response.getIn(['data', 'registration_create_account', 'username']),
        token: response.getIn(['data', 'registration_create_account', 'token']),
        expiration: response.getIn(['data', 'registration_create_account', 'expiration']),
        plan_id: response.getIn(['data', 'registration_create_account', 'plan', 'id']),
        consent_url: response.getIn(['data', 'registration_create_account', 'plan', 'consent_url']),
        subscription_cycle: response.getIn(['data', 'registration_create_account', 'subscription_cycle']),
      };

      dispatch(registerCreateAccountClientSuccess(registerSuccessData));
      accountId = registerSuccessData.account_id;
      const tokenClaims = Auth.parseJwt(registerSuccessData.token);

      // login user
      dispatch(sessionCreateSuccess({
        authToken: registerSuccessData.token,
        authIssuedAt: 'iat' in tokenClaims ? tokenClaims.iat : null,
        authExpires: 'exp' in tokenClaims ? tokenClaims.exp : null,
        userId: registerSuccessData.user_id,
        username: registerSuccessData.username,
        profileId: registerSuccessData.profile_id,
        remember: true,
        remember_username: false,
      }));
      
    // get register create account client failure
    } else {
      Logger.log('info', `POST API register create account client failure.`);
      dispatch(registerCreateAccountClientFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success, accountId);
  }
}

export function loadTermsOfServiceCurrent(cb=function(){}) {
  Logger.log('debug', `[state.register.actions] loadTermsOfServiceCurrent(###)`);

  return async function(dispatch) {
    dispatch(termsOfServiceCurrentRequest());

    // call API
    const response = await api.getTermsOfServiceCurrent();

    // get ToS list success
    if (200 === response.get('status')) {

      Logger.log('info', `GET API current terms of service success.`);

      const tosSuccessData = {
        id: response.getIn(['data', 'terms_of_service', 'id']),
        text: response.getIn(['data', 'terms_of_service', 'text']),
        version: response.getIn(['data', 'terms_of_service', 'version']),
        publish_date: response.getIn(['data', 'terms_of_service', 'publish_date'])
      };

      // normalize data for single instance
      const tosId = response.getIn(['data', 'terms_of_service', 'id']);
      const normalizedToS = {
        entities: {
          tos: {
            [tosId]: tosSuccessData
          }
        }
      };

      dispatch(addEntities(normalizedToS));
      dispatch(termsOfServiceCurrentSuccess(tosSuccessData));
      
    // get ToS list failure
    } else {
      Logger.log('info', `GET API current terms of service failure.`);
      dispatch(termsOfServiceCurrentFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb();
  }
}

Logger.log('silly', `state.register.actions loaded.`);
