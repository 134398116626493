import {Map} from 'immutable';

import {REGISTER_CREATE_ACCOUNT_SUCCESS} from '../register/actions';
import {TYPES} from './actions';
import {SESSION_DESTROY} from '../../actions';
import Logger from '../../../lib/Logger';

const initialState = Map({
  isSubmittingCustomer: false,
  isSubmittingSubscription: false,
  isLoadingSubscription: false,
  isDeletingSubscription: false,
  activeSubscriptionId: null,
});

export default function payments(
  state=initialState,
  action
) {
  Logger.log('debug', `[state.payments.reducers] payments(%j, %j)`, state, action);

  switch(action.type) {

    case TYPES.CREATE_CUSTOMER_REQUEST:
      return state.mergeDeep({
        isSubmittingCustomer: true,
      });

    case TYPES.CREATE_CUSTOMER_SUCCESS:
      return state.mergeDeep({
        isSubmittingCustomer: false,
        lastUpdated: action.receivedAt
      });

    case TYPES.CREATE_CUSTOMER_FAILURE:
      return state.mergeDeep({
        isSubmittingCustomer: false,
      });

    case TYPES.UPDATE_CUSTOMER_REQUEST:
      return state.mergeDeep({
        isSubmittingCustomer: true,
      });

    case TYPES.UPDATE_CUSTOMER_SUCCESS:
      return state.mergeDeep({
        isSubmittingCustomer: false,
        lastUpdated: action.receivedAt
      });

    case TYPES.UPDATE_CUSTOMER_FAILURE:
      return state.mergeDeep({
        isSubmittingCustomer: false,
      });
    
    case TYPES.CREATE_SUBSCRIPTION_REQUEST:
      return state.mergeDeep({
        isSubmittingSubscription: true,
      });

    case TYPES.CREATE_SUBSCRIPTION_SUCCESS:
      return state.mergeDeep({
        isSubmittingSubscription: false,
        intent: action.intent,
        clientSecret: action.clientSecret,
        planId: action.planId,
        subscriptionCycle: action.subscriptionCycle,
        lastUpdated: action.receivedAt
      });

    case TYPES.CREATE_SUBSCRIPTION_FAILURE:
      return state.mergeDeep({
        isSubmittingSubscription: false,
      });

    case REGISTER_CREATE_ACCOUNT_SUCCESS:
      return state.mergeDeep({
        planId: action.planId,
        subscriptionCycle: action.subscriptionCycle,
      });

    case TYPES.READ_SUBSCRIPTION_REQUEST:
      return state.mergeDeep({
        isLoadingSubscription: true
      });

    case TYPES.READ_SUBSCRIPTION_SUCCESS:
      return state.mergeDeep({
        isLoadingSubscription: false,
        activeSubscriptionId: action.id,
        lastUpdated: action.receivedAt
      });

    case TYPES.READ_SUBSCRIPTION_FAILURE:
      return state.mergeDeep({
        isLoadingSubscription: false
      });

    case TYPES.DELETE_SUBSCRIPTION_REQUEST:
      return state.mergeDeep({
        isDeletingSubscription: true
      });

    case TYPES.DELETE_SUBSCRIPTION_SUCCESS:
      return state.mergeDeep({
        isDeletingSubscription: false,
        activeSubscriptionId: action.newId,
      });

    case TYPES.DELETE_SUBSCRIPTION_FAILURE:
      return state.mergeDeep({
        isDeletingSubscription: false
      });

    case SESSION_DESTROY:
      return initialState;

    default:
      return state;
  }
}

Logger.log('silly', `state.payments.reducers loaded.`);
