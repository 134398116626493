import React, { useState } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { Translation, getI18n } from 'react-i18next';
import { Button, Checkbox, Form, Input } from 'antd';
import { LoginOutlined } from '@ant-design/icons';

import Logger from '../../../../../lib/Logger';
import {
  MixpanelTracker,
  mixpanelEventsEnum,
} from '../../../../../lib/Mixpanel';
import message from '../../../elements/lib/MessageWrapper';
import { pathTo, hasRoute } from '../../../Routes';

const LoginForm = (props) => {
  const [redirectTo2FA, setRedirectTo2FA] = useState(false);
  const [form] = Form.useForm();

  // submit credentials handler
  const submitCredentials = async (payload) => {
    Logger.log('debug', `LoginForm.submitCredentials()`);

    MixpanelTracker.mixpanelTrack(mixpanelEventsEnum.LOGIN.STARTED_LOGIN);

    // create session
    props.createSession(payload, (success, requires2FA, accountLocked) => {
      if (!success) {
        if (requires2FA) {
          setRedirectTo2FA(true);
        } else {
          message.error(
            accountLocked
              ? getI18n().t('session_login_form_message_account_locked')
              : getI18n().t('session_login_form_message_failure')
          );
        }
      }
    });
  };

  // remove error message when input value changes
  const handleValuesChange = (changedValues, allValues) => {
    for (const key of Object.keys(changedValues)) {
      form.setFields([{ name: key, errors: [] }]);
    }
  };

  // form submit handler
  const handleFinish = async (values) => {
    Logger.log('debug', `LoginForm.handleFinish(###)`);
    if (!props.isSubmitting) {
      await submitCredentials(values);
    }
  };

  // form error handler
  const handleFinishFailed = ({ values, errorFields, outOfDate }) => {
    Logger.log('debug', `LoginForm.handleFinishFailed(###)`);
    message.error(getI18n().t('session_login_form_message_failure'));
  };

  if (redirectTo2FA === true) {
    return <Redirect to={pathTo('TwoFactorAuthScreen')} />;
  }

  return (
    <Translation>
      {(t) => (
        <div>
          <Form
            hideRequiredMark
            layout="vertical"
            name="login_form"
            form={form}
            onFinish={handleFinish}
            onFinishFailed={handleFinishFailed}
            onValuesChange={handleValuesChange}
            validateTrigger="onSubmit"
            initialValues={{
              username:
                props.session_username &&
                typeof props.session_username === 'string'
                  ? props.session_username
                  : '',
              remember: true,
              remember_username: Boolean(props.session_username),
            }}
          >
            <Form.Item
              name="username"
              label={t('session_login_form_input_username')}
              rules={[
                { required: true, message: t('feedback_validation_required') },
              ]}
            >
              <Input autoFocus />
            </Form.Item>

            <Form.Item
              name="password"
              label={t('session_login_form_input_password')}
              rules={[
                { required: true, message: t('feedback_validation_required') },
              ]}
            >
              <Input.Password />
            </Form.Item>

            <Form.Item className="remember-username-row">
              <Form.Item
                name="remember_username"
                valuePropName="checked"
                noStyle
              >
                <Checkbox>
                  {t('session_login_form_input_remember_username')}
                </Checkbox>
              </Form.Item>
              <Link
                to={pathTo('PasswordResetRequestScreen')}
                className="link-forgot-password"
              >
                {t('session_login_form_button_forgot_password')}
              </Link>
            </Form.Item>

            <Form.Item className="remember-row">
              <Form.Item name="remember" valuePropName="checked" noStyle>
                <Checkbox>{t('session_login_form_input_remember')}</Checkbox>
              </Form.Item>
            </Form.Item>

            <div className="form-actions">
              <Form.Item>
                <Button
                  type="primary"
                  className="login-button btn-large-round"
                  htmlType="submit"
                  size="large"
                  icon={<LoginOutlined />}
                  loading={props.isSubmitting}
                  block={true}
                >
                  {t('session_login_form_button_submit')}
                </Button>
              </Form.Item>

              {hasRoute('RegisterStep1Screen') ? (
                <div className="register-link">
                  <div>
                    {t('session_login_form_text_register')}{' '}
                    <Link
                      to={pathTo('RegisterStep1Screen')}
                      className="link-register"
                    >
                      {t('session_login_form_link_register')}
                    </Link>
                  </div>
                </div>
              ) : null}
            </div>
          </Form>
        </div>
      )}
    </Translation>
  );
};

export default LoginForm;

Logger.log('silly', `LoginForm loaded.`);
