import React from 'react';
import { Translation } from 'react-i18next';
import { Form, Select, Tag } from 'antd';


const CareGuideColorInput = ({ name, setColor, color, label, disabled = false, ...props }) => {


  const handleChange = (value) => {
    setColor(value);
  };

  const options = [
    {
      value: "magenta",
      label: "magenta",
    },
    {
      value: "red",
      label: "red",
    },
    {
      value: "volcano",
      label: "volcano",
    },
    {
      value: "orange",
      label: "orange",
    },
    {
      value: "gold",
      label: "gold",
    },
    {
      value: "lime",
      label: "lime",
    },
    {
      value: "green",
      label: "green",
    },
    {
      value: "cyan",
      label: "cyan",
    },
    {
      value: "blue",
      label: "blue",
    },
    {
      value: "geekblue",
      label: "geekblue",
    },
    {
      value: "purple",
      label: "purple",
    },
  ];

  return (
    <Translation>{(t) =>
      <div className="form-group">
        <Form.Item
          name={name || 'color_hex'}
          label={label}
        >
          <Select
            onChange={handleChange}
            disabled={disabled}
            defaultValue={"magenta"}
          >
            {options.map(x => <Select.Option key={x.value} value={x.value}>{t(x.label)}</Select.Option>)}
          </Select>
        </Form.Item>
        <Form.Item
          label={t('color_example')}
        >
          <Tag color={color}>{color}</Tag>
        </Form.Item>
      </div>

    }</Translation>
  );
}

export default CareGuideColorInput;
