import {connect} from 'react-redux';
import {List} from 'immutable';

import {
  registerStep1,
  registerStep1FormDestroy,
  registerCreateAccount,
  registerCreateAccountAdmin,
  registerCreateAccountOwner
} from '../../../../../state/modules/register/actions';
import {createSession} from '../../../../../state/actions';
import RegisterStep1Form from '../components/RegisterStep1Form';

const inputs = List([
  'username',
  'password',
  'password2',
  'email',
  'first_name',
  'last_name',
  'telephone',
  'registration_code',
  'invite_code',
  'invite_id',
  'agree_reminders_updates',
]);

const mapStateToProps = (state, ownProps) => {

  const data = {}, errors = {};
  for (const val of inputs.values()) {
    data[val] = '';
    if (state.register.getIn(['step1Form', 'errors', val])) {
      errors[val] = state.register.getIn(['step1Form', 'errors', val]);
    }
  }

  if ('registrationCode' in ownProps) {
    data['registration_code'] = ownProps.registrationCode;
  }
  if ('inviteCode' in ownProps) {
    data['invite_code'] = ownProps.inviteCode;
  }
  if ('inviteId' in ownProps) {
    data['invite_id'] = ownProps.inviteId;
  }

  let currentTosId = '';
  if (state.entities.get('tos')) {
    currentTosId = state.entities.get('tos')[Object.keys(state.entities.get('tos'))[0]].id;
  }
  data['tos_id'] = false;
  
  return {
    isSubmitting: state.register.get('isStep1Submitting') || state.register.get('isCreateAccountSubmitting'),
    success: state.register.getIn(['step1Form', 'success']),
    data: data,
    errors: errors,
    tos_id: currentTosId,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    submit: (data, cb) => {
      dispatch(registerStep1(data, cb));
    },
    createAccount: (data, cb) => {
      dispatch(registerCreateAccount(data, cb));
    },
    createAccountOwner: (data, cb) => {
      dispatch(registerCreateAccountOwner(data, cb));
    },
    createAccountAdmin: (data, cb) => {
      dispatch(registerCreateAccountAdmin(data, cb));
    },
    formDestroy: (formState) => {
      dispatch(registerStep1FormDestroy(formState));
    },
    createSession: (data, cb) => {
      dispatch(createSession(data, cb));
    }
  }
}

const RegisterStep1FormContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(RegisterStep1Form);

export default RegisterStep1FormContainer;
