import {connect} from 'react-redux';
import {List} from 'immutable';
import {denormalize} from 'normalizr';

import {schema} from '../../../../../state/schema';
import {
  loadCalendarEventParticipants,
  deleteCalendarEventAdminParticipant,
  calendarEventAdminShowEventParticipantFormDestroy,
  calendarEventAdminHideEventParticipantForm,
  updateCalendarEventParticipant,
  createCalendarEventParticipant,
} from '../../../../../state/modules/calendarEventsAdmin/actions'


import EventParticipantFormModal from '../components/EventParticipantFormModal';

const inputs = List([
  'public_status',
  'user_profile_id'
]);


const mapStateToProps = (state, ownProps) => {

  const errors = {};
  for (const val of inputs.values()) {
    if (state.calendarEventsAdmin.getIn(['eventForm', 'errors', val])) {
      errors[val] = state.calendarEventsAdmin.getIn(['eventForm', 'errors', val]);
    }
  }

  const participantId = state.calendarEventsAdmin.get('eventParticipantFormParticipantId');

  const participant = participantId
    ? denormalize(
        state.entities.getIn(['calendarEventParticipants', participantId]),
        schema.calendarEventParticipant,
        state.entities.toJS()
      )
    : null;

  const data = {
    public_status: participant ? participant.public_status : '',
    user_profile_id: participant ? participant.profile ? participant.profile.id : null : null
  };

  return {
    pageParticipants: state.calendarEventsAdmin.get('pageParticipants'),
    visible: state.calendarEventsAdmin.get('eventParticipantFormIsVisible'),
    isSubmitting: state.calendarEventsAdmin.getIn(['eventForm', 'isSubmitting']),
    success: state.calendarEventsAdmin.getIn(['eventForm', 'success']),
    participantId: participantId,
    participant: participant,
    data: data,
    errors: errors,
    inputs,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    create: (eventId, data, cb) => {
      dispatch(createCalendarEventParticipant(eventId, data, cb));
    },
    update: (id, data, cb) => {
      dispatch(updateCalendarEventParticipant(id, data, cb));
    },
    delete: (calendarEventParticipantId, cb) => {
      dispatch(deleteCalendarEventAdminParticipant(calendarEventParticipantId, cb));
    },
    loadParticipants: (calendarEventId, page, limit, cb) =>{
      dispatch(loadCalendarEventParticipants(calendarEventId, page, limit, cb))
    },
    formDestroy: (formState) => {
      dispatch(calendarEventAdminShowEventParticipantFormDestroy(formState));
    },
    hideForm: () => {
      dispatch(calendarEventAdminHideEventParticipantForm());
    },
  }
}

const EventParticipantFormModalContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(EventParticipantFormModal);

export default EventParticipantFormModalContainer;
