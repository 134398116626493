import React, {useState} from "react";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.bubble.css';

export default function CareGuideContentEditor({onChange, defaultValue='', placeholder=null, editorRef=null, ...props}) {
  const [value, setValue] = useState(defaultValue);

  return (
    <ReactQuill
      theme="bubble"
      value={value}
      onChange={(content, delta, source, editor) => {
        setValue(content);
        onChange(content, delta, source, editor);
      }}
      placeholder={placeholder}
      ref={editorRef}
    />
  );
}
