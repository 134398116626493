import {normalize} from 'normalizr';

import api from '../../api';
import {schema} from '../../schema';
import {addEntities} from '../../actions';
import Logger from '../../../lib/Logger';

export const TYPES = {
  LIST_REGIONS_REQUEST: 'LOCATIONS/LIST_REGIONS_REQUEST',
  LIST_REGIONS_SUCCESS: 'LOCATIONS/LIST_REGIONS_SUCCESS',
  LIST_REGIONS_FAILURE: 'LOCATIONS/LIST_REGIONS_FAILURE',
  LIST_SUBREGIONS_REQUEST: 'LOCATIONS/LIST_SUBREGIONS_REQUEST',
  LIST_SUBREGIONS_SUCCESS: 'LOCATIONS/LIST_SUBREGIONS_SUCCESS',
  LIST_SUBREGIONS_FAILURE: 'LOCATIONS/LIST_SUBREGIONS_FAILURE',
  LIST_CITIES_REQUEST: 'LOCATIONS/LIST_CITIES_REQUEST',
  LIST_CITIES_SUCCESS: 'LOCATIONS/LIST_CITIES_SUCCESS',
  LIST_CITIES_FAILURE: 'LOCATIONS/LIST_CITIES_FAILURE',
  LIST_POSTAL_CODES_REQUEST: 'LOCATIONS/LIST_POSTAL_CODES_REQUEST',
  LIST_POSTAL_CODES_SUCCESS: 'LOCATIONS/LIST_POSTAL_CODES_SUCCESS',
  LIST_POSTAL_CODES_FAILURE: 'LOCATIONS/LIST_POSTAL_CODES_FAILURE',
};

export function regionListRequest(countryCode, page, limit, order) {
  Logger.log('debug', `[state.locations.actions] regionListRequest(${countryCode}, ${page}, ${limit}, ${order})`);
  return {
    type: TYPES.LIST_REGIONS_REQUEST,
    countryCode: countryCode,
    page: page,
    limit: limit,
    order: order,
  }
}

export function regionListSuccess(data) {
  Logger.log('debug', `[state.locations.actions] regionListSuccess(%j)`, data);
  return {
    type: TYPES.LIST_REGIONS_SUCCESS,
    page: data.page,
    limit: data.limit,
    order: data.order,
    result: data.result,
    total: data.total,
    receivedAt: Date.now()
  }
}

export function regionListFailure(error) {
  Logger.log('debug', `[state.locations.actions] regionListFailure(%j)`, error);
  return {
    type: TYPES.LIST_REGIONS_FAILURE,
    error: error
  }
}

export function subregionListRequest(countryCode, regionCode, page, limit, order) {
  Logger.log('debug', `[state.locations.actions] subregionListRequest(${countryCode}, ${regionCode}, ${page}, ${limit}, ${order})`);
  return {
    type: TYPES.LIST_SUBREGIONS_REQUEST,
    countryCode: countryCode,
    regionCode: regionCode,
    page: page,
    limit: limit,
    order: order,
  }
}

export function subregionListSuccess(data) {
  Logger.log('debug', `[state.locations.actions] subregionListSuccess(%j)`, data);
  return {
    type: TYPES.LIST_SUBREGIONS_SUCCESS,
    page: data.page,
    limit: data.limit,
    order: data.order,
    result: data.result,
    total: data.total,
    receivedAt: Date.now()
  }
}

export function subregionListFailure(error) {
  Logger.log('debug', `[state.locations.actions] subregionListFailure(%j)`, error);
  return {
    type: TYPES.LIST_SUBREGIONS_FAILURE,
    error: error
  }
}

export function cityListRequest(countryCode, regionCode, page, limit, order) {
  Logger.log('debug', `[state.locations.actions] cityListRequest(${countryCode}, ${regionCode}, ${page}, ${limit}, ${order})`);
  return {
    type: TYPES.LIST_CITIES_REQUEST,
    countryCode: countryCode,
    regionCode: regionCode,
    page: page,
    limit: limit,
    order: order,
  }
}

export function cityListSuccess(data) {
  Logger.log('debug', `[state.locations.actions] cityListSuccess(%j)`, data);
  return {
    type: TYPES.LIST_CITIES_SUCCESS,
    page: data.page,
    limit: data.limit,
    order: data.order,
    result: data.result,
    total: data.total,
    receivedAt: Date.now()
  }
}

export function cityListFailure(error) {
  Logger.log('debug', `[state.locations.actions] cityListFailure(%j)`, error);
  return {
    type: TYPES.LIST_CITIES_FAILURE,
    error: error
  }
}

export function postalCodeListRequest(countryCode, regionCode, parent, parentID, page, limit, order) {
  Logger.log('debug', `[state.locations.actions] postalCodeListRequest(${countryCode}, ${regionCode}, ${parent}, ${parentID}, ${page}, ${limit}, ${order})`);
  return {
    type: TYPES.LIST_POSTAL_CODES_REQUEST,
    countryCode: countryCode,
    regionCode: regionCode,
    parent: parent,
    parentID: parentID,
    page: page,
    limit: limit,
    order: order,
  }
}

export function postalCodeListSuccess(data) {
  Logger.log('debug', `[state.locations.actions] postalCodeListSuccess(%j)`, data);
  return {
    type: TYPES.LIST_POSTAL_CODES_SUCCESS,
    countryCode: data.countryCode,
    page: data.page,
    limit: data.limit,
    order: data.order,
    result: data.result,
    total: data.total,
    receivedAt: Date.now()
  }
}

export function postalCodeListFailure(error) {
  Logger.log('debug', `[state.locations.actions] postalCodeListFailure(%j)`, error);
  return {
    type: TYPES.LIST_POSTAL_CODES_FAILURE,
    error: error
  }
}


// API THUNK ACTION CREATORS

export function loadRegions(countryCode, page=1, limit=10, order=null, cb=function(){}) {
  Logger.log('debug', `[state.locations.actions] loadRegions(${countryCode}, ${page}, ${limit}, ${order}, ###)`);

  return async function(dispatch) {
    dispatch(regionListRequest(countryCode, page, limit, order));

    // call API
    const response = await api.getRegions(countryCode, page, limit, order);
    let success = false;

    // get regions list success
    if (200 === response.get('status')) {

      Logger.log('info', `Get API regions list success. Country: ${countryCode}, Page: ${page}, Limit: ${limit}, Order: ${order}.`);

      const normalizedEntities = normalize(response.getIn(['data', 'regions']), [schema.region]);
      const data = {
        countryCode: countryCode,
        page: response.getIn(['data', 'page']),
        limit: response.getIn(['data', 'limit']),
        order: order,
        total: response.getIn(['data', 'total']),
        result: normalizedEntities.result
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(regionListSuccess(data));
      success = true;
      
    // get regions list failure
    } else {
      Logger.log('info', `Get API regions list failure. Country: ${countryCode}, Page: ${page}, Limit: ${limit}, Order: ${order}.`);
      dispatch(regionListFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  }
}

export function loadSubregions(countryCode, regionCode, page=1, limit=10, order=null, cb=function(){}) {
  Logger.log('debug', `[state.locations.actions] loadSubregions(${countryCode}, ${regionCode}, ${page}, ${limit}, ${order}, ###)`);

  return async function(dispatch) {
    dispatch(subregionListRequest(countryCode, regionCode, page, limit, order));

    // call API
    const response = await api.getSubregions(countryCode, regionCode, page, limit, order);
    let success = false;

    // get subregions list success
    if (200 === response.get('status')) {

      Logger.log('info', `Get API subregions list success. Country: ${countryCode}, Region: ${regionCode}, Page: ${page}, Limit: ${limit}, Order: ${order}.`);

      const normalizedEntities = normalize(response.getIn(['data', 'subregions']), [schema.subregion]);
      const data = {
        page: response.getIn(['data', 'page']),
        limit: response.getIn(['data', 'limit']),
        order: order,
        total: response.getIn(['data', 'total']),
        result: normalizedEntities.result
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(subregionListSuccess(data));
      success = true;
      
    // get subregions list failure
    } else {
      Logger.log('info', `Get API subregions list failure. Country: ${countryCode}, Page: ${page}, Limit: ${limit}, Order: ${order}.`);
      dispatch(subregionListFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  }
}

export function loadCities(countryCode, regionCode, page=1, limit=10, order=null, cb=function(){}) {
  Logger.log('debug', `[state.locations.actions] loadCities(${countryCode}, ${regionCode}, ${page}, ${limit}, ${order}, ###)`);

  return async function(dispatch) {
    dispatch(cityListRequest(countryCode, regionCode, page, limit, order));

    // call API
    const response = await api.getCities(countryCode, regionCode, page, limit, order);
    let success = false;

    // get cities list success
    if (200 === response.get('status')) {

      Logger.log('info', `Get API cities list success. Country: ${countryCode}, Region: ${regionCode}, Page: ${page}, Limit: ${limit}, Order: ${order}.`);

      const normalizedEntities = normalize(response.getIn(['data', 'cities']), [schema.city]);
      const data = {
        page: response.getIn(['data', 'page']),
        limit: response.getIn(['data', 'limit']),
        order: order,
        total: response.getIn(['data', 'total']),
        result: normalizedEntities.result
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(cityListSuccess(data));
      success = true;
      
    // get cities list failure
    } else {
      Logger.log('info', `Get API cities list failure. Country: ${countryCode}, Page: ${page}, Limit: ${limit}, Order: ${order}.`);
      dispatch(cityListFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  }
}

export function loadPostalCodes(countryCode, regionCode, parent, parentID, page=1, limit=10, order=null, cb=function(){}) {
  Logger.log('debug', `[state.locations.actions] loadPostalCodes(${countryCode}, ${regionCode}, ${parent}, ${parentID}, ${page}, ${limit}, ${order}, ###)`);

  return async function(dispatch) {
    dispatch(postalCodeListRequest(countryCode, regionCode, parent, parentID, page, limit, order));

    // call API
    const response = await api.getPostalCodes(countryCode, regionCode, parent, parentID, page, limit, order);
    let success = false;

    // get postal codes list success
    if (200 === response.get('status')) {

      Logger.log('info', `Get API postal codes list success. Country: ${countryCode}, Region: ${regionCode}, Parent: ${parent}/${parentID}, Page: ${page}, Limit: ${limit}, Order: ${order}.`);

      const normalizedEntities = normalize(response.getIn(['data', 'postal_codes']), [schema.postalCode]);
      const data = {
        page: response.getIn(['data', 'page']),
        limit: response.getIn(['data', 'limit']),
        order: order,
        total: response.getIn(['data', 'total']),
        result: normalizedEntities.result
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(postalCodeListSuccess(data));
      success = true;
      
    // get postal codes list failure
    } else {
      Logger.log('info', `Get API postal codes list failure. Country: ${countryCode}, Region: ${regionCode}, Parent: ${parent}/${parentID}, Page: ${page}, Limit: ${limit}, Order: ${order}.`);
      dispatch(postalCodeListFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  }
}

Logger.log('silly', `state.locations.actions loaded.`);
