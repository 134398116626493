import React, { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import { Translation, getI18n } from "react-i18next";
import { Affix, Card, Col, Form, Input, Row, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

import message from "../../../elements/lib/MessageWrapper";
import FormMetadata from "../../../elements/components/FormMetadata";
import { pathTo } from "../../../Routes";
import Logger from "../../../../../lib/Logger";

const JournalPartnerTagAdminForm = ({
  id,
  data,
  errors,
  load,
  destroyForm,
  isLoading,
  isSubmitting,
  created_id,
  activeId,
  ...props
}) => {
  const [redirectTo, setRedirectTo] = useState(null);
  const [form] = Form.useForm();

  // form column settings
  const layout = {
    main: {
      labelCol: { span: 5 },
      wrapperCol: { span: 19 },
    },
  };

  // load record data from API
  useEffect(() => {
    if (id) {
      load(activeId, id);
    }
  }, [activeId, id, load]);

  // update input values when new data is available
  const dataString = JSON.stringify(data);
  useEffect(() => {
    if (id && !isSubmitting) {
      const dataObj = JSON.parse(dataString);
      form.setFieldsValue(dataObj);
    }
  }, [form, dataString, isSubmitting, id]);

  // handle errors reported by API
  useEffect(() => {
    let firstFieldName = "";
    for (const field in errors) {
      form.setFields([{ name: field, errors: errors[field] }]);
      if (firstFieldName === "") {
        firstFieldName = field;
      }
    }
    form.scrollToField(firstFieldName);
  }, [form, errors]);

  // redirect add form to edit form on successful create action
  useEffect(() => {
    if (created_id) {
      setRedirectTo(pathTo("JournalPartnerTagAdminEditScreen", {id_partnership: activeId, id: created_id }));
    }
    return () => {
      destroyForm();
    };
  }, [created_id,  activeId,  setRedirectTo, destroyForm]);

  // submit data handler
  const submitData = async (values) => {
    Logger.log("debug", `JournalPartnerTagAdminForm.submitData(###)`);

    // API POST/PUT payload
    let payload = {};
    for (const input of Object.keys(data)) {
      if (input in values) {
        payload[input] = values[input];
      }
    }
    payload["partnership_id"] = activeId;

    if (id) {
      // update
      props.update(id, payload, (success) => {
        if (success) {
          message.success(getI18n().t("feedback_form_success"));
        } else {
          message.error(getI18n().t("feedback_form_error"));
        }
      });
    } else {
      // create
      props.create(payload, (success) => {
        if (success) {
          message.success(getI18n().t("feedback_form_success"));
        } else {
          message.error(getI18n().t("feedback_form_error"));
        }
      });
    }
  };

  // form submit handler
  const handleFinish = async (values) => {
    Logger.log("debug", `JournalPartnerTagForm.handleFinish(###)`);
    if (!props.isSubmitting) {
      await submitData(values);
    }
  };

  // form error handler
  const handleFinishFailed = ({ values, errorFields, outOfDate }) => {
    Logger.log("debug", `JournalPartnerTagAdminForm.handleFinishFailed(###)`);
    message.error(getI18n().t("feedback_form_error"));
    if (errorFields && errorFields.length > 0) {
      form.scrollToField(errorFields[0].name);
    }
  };

  if (redirectTo) {
    return <Redirect to={redirectTo} />;
  }

  return (
    <Translation>
      {(t) => (
        <>
          <div className="journal-tag-form">
            <Form
              name="journal_tag_form"
              form={form}
              initialValues={data}
              onFinish={handleFinish}
              onFinishFailed={handleFinishFailed}
              validateTrigger="onSubmit"
              {...layout.main}
            >
              <Row gutter={16}>
                <Col xs={24} lg={18}>
                  <Card
                    title={
                      id
                        ? t("journal_partner_tag_edit_title")
                        : t("journal_partner_tag_add_title")
                    }
                    extra={
                      isLoading ? (
                        <Spin
                          indicator={
                            <LoadingOutlined style={{ fontSize: 20 }} spin />
                          }
                        />
                      ) : null
                    }
                  >
                    <div className="form-group">
                      <Form.Item
                        name="label"
                        label={t("journal_partner_tag_label")}
                        rules={[
                          {
                            required: true,
                            message: t("feedback_validation_required"),
                          },
                          {
                            type: "string",
                            min: 1,
                            max: 60,
                            message: t("feedback_validation_char_range", {
                              min: 1,
                              max: 60,
                            }),
                          },
                        ]}
                      >
                        <Input disabled={isLoading || isSubmitting} />
                      </Form.Item>
                    </div>
                  </Card>
                </Col>

                <Col xs={24} lg={6}>
                  <Affix offsetTop={10}>
                    <Card title={t("form_metadata_header")}>
                      <FormMetadata
                        id={id}
                        activeDelete={true}
                        partnershipId={activeId}
                        isSubmitting={isSubmitting}
                        delete={props.delete.bind(this)}
                        deleteRedirectTo="JournalPartnerTagsAdminScreen"
                        createdAt={props.createdAt}
                        updatedAt={props.updatedAt}
                      />
                    </Card>
                  </Affix>
                </Col>
              </Row>
            </Form>
          </div>
        </>
      )}
    </Translation>
  );
};

export default JournalPartnerTagAdminForm;

Logger.log("silly", `JournalPartnerTagAdminForm loaded.`);