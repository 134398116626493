import React, {useEffect} from 'react';
import {Translation} from 'react-i18next';
import {Form, Select} from 'antd';
import hasPermission, { VIEW_PARTNER_MEMBERS } from '../../../elements/lib/hasPermission';

const MemberInput = ({careGuideId, name, label, placeholder, options, isLoading, isSubmitting, load, profileId, onChange=null, multiple=false, rules=[], ...props}) => {

  // load members on mount
  useEffect(() => {
    if (careGuideId) {
      load(careGuideId, 1, 50, 'created_at.desc');
    }
  }, [load, careGuideId]);

  return (

    <Translation>{(t) => 
      <div className='select-form'>
        <Form.Item
          name={name}
          label={label}
          rules={rules}
        >
          <Select          
            mode={multiple ? "multiple" : null}
            showSearch
            placeholder={placeholder}
            optionFilterProp="children"
            disabled={isLoading || isSubmitting}
            loading={isLoading}
            onChange={onChange}
            showArrow={true}
          >

          {options.map(x => hasPermission(VIEW_PARTNER_MEMBERS) ? <Select.Option key={x.value} value={x.value}>{x.label}</Select.Option>
            : (x?.role !== 4 && x?.role !== 5) ? <Select.Option key={x.value} value={x.value}>{x.label}</Select.Option> : null)} 

          </Select>
        </Form.Item>
      </div>
    }</Translation>
  );
}

export default MemberInput;
