import {connect} from 'react-redux';

import PaymentRegistrationScreen from '../components/PaymentRegistrationScreen';

const mapStateToProps = (state) => {
  return {
    isSubmittingCustomer: state.payments.get('isSubmittingCustomer'),
    isSubmittingSubscription: state.payments.get('isSubmittingSubscription'),
  }
}

const PaymentRegistrationScreenContainer = connect(
  mapStateToProps
)(PaymentRegistrationScreen);

export default PaymentRegistrationScreenContainer;
