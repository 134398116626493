import React from 'react';
import { getI18n } from 'react-i18next';

import { RiPushpin2Fill } from 'react-icons/ri';

import CareGuideDocumentsActionColumn from './CareGuideDocumentsActionColumn';
import { Button } from 'antd';
import Format from '../../../../../lib/Format';


const getDefaultSortOrder = (order, value) => {
  const sortOrderMap = {
    [`${value}.asc`]: 'ascend',
    [`${value}.desc`]: 'descend',
  };

  return sortOrderMap[order] || null;
};

const getSortingMethod = (sorter, dataIndex) => {
  if (!sorter) return null;
  if (sorter === true) {
    return (a, b) => a[dataIndex].localeCompare(b[dataIndex]);
  }
  return sorter;
};

const getColumn = (
  titleKey,
  dataIndex,
  key,
  sorter,
  width,
  ellipsis = false,
  render = null
) => {
  return {
    title: getI18n().t(titleKey),
    dataIndex,
    key,
    defaultSortOrder: getDefaultSortOrder(sorter, key),
    sorter: getSortingMethod(sorter, dataIndex),
    width,
    ellipsis,
    render: (text, row) => (render ? render(text, row) : text),
  };
};

const getActionColumn = (
  showForm,
  openDeleteConfirmationModal,
  handlePinned
) => {
  return {
    title: getI18n().t('table_header_action'),
    key: 'actions',
    width: 78,
    fixed: 'right',
    render: (row) => (
      <CareGuideDocumentsActionColumn
        row={row}
        showForm={showForm}
        openDeleteConfirmationModal={openDeleteConfirmationModal}
        handlePinned={handlePinned}
      />
    ),
  };
};

const getLeftColumns = (order, handlePinned) => [
  getColumn(
    'table_header_partnership_name',
    'user_file_name',
    'user_file_name',
    order,
    326,
    false,
    (text, record) => (
      <span style={{ fontWeight: '600' }}>
        {record.is_pinned && (
          <Button
            type="link"
            icon={
              <RiPushpin2Fill style={{ color: '#4279BD', fontSize: '16px' }} />
            }
            onClick={(event) => {
              handlePinned(record.id, record.is_pinned);
              event.stopPropagation();
            }}
          />
        )}
        {text}
      </span>
    )
  ),
  getColumn(
    'table_header_description',
    'description',
    'description',
    null,
    476,
    true
  ),
  getColumn(
    'table_header_creation',
    'created_at',
    'created_at',
    order,
    150,
    false,
    (text) => <span>{Format.dateTable(text)}</span>
  ),
  getColumn(
    'table_header_updated',
    'updated_at',
    'updated_at',
    order,
    150,
    false,
    (text) => <span>{Format.dateTable(text)}</span>
  ),
];

export const getTableColumns = (
  order,
  showForm,
  openDeleteConfirmationModal,
  handlePinned
) => {
  const leftColumns = getLeftColumns(order, handlePinned);
  const actionColumn = getActionColumn(
    showForm,
    openDeleteConfirmationModal,
    handlePinned
  );
  const newColumns = [...leftColumns, actionColumn];
  return newColumns;
};
