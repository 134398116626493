import { Col, Row, Space, Button, Form } from 'antd';
import { FaRegEdit } from 'react-icons/fa';
import { FiTrash } from 'react-icons/fi';
import { MdOutlineSave } from 'react-icons/md';
import { Translation, getI18n } from 'react-i18next';
import { withRouter } from 'react-router';
import ConfirmationModal from '../../../elements/components/ConfirmationModal';
import Logger from '../../../../../lib/Logger';
import MemberProfileCard from './MemberProfileCard';
import MemberProfileTags from '../containers/MemberProfileTagsContainer';
import notification from '../../../elements/lib/NotificationWrapper';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import Title from 'antd/lib/typography/Title';

const MemberProfile = ({
  data,
  errors,
  load,
  careGuideId,
  partnershipId,
  isSubmitting,
  setRedirectConfirmation,
  ...props
}) => {
  const modalTexts = {
    title: getI18n().t('member_profile_modal_title'),
    description: getI18n().t('member_profile_modal_description'),
    leaveText: getI18n().t('member_profile_modal_discard'),
    saveText: getI18n().t('member_profile_modal_save'),
  };

  const [isEditionMode, setIsEditionMode] = useState(false);
  const [hasChanges, setHasChanges] = useState(false);
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const tagsNames = useRef([]);
  const [form] = Form.useForm();

  const updateTagsNames = useCallback((classes) => {
    tagsNames.current = classes;
  }, []);

  const onFieldsChange = () => {
    const currentTags = {
      subscription_status: data.subscription_status,
      ...tagsNames.current.reduce((acc, item) => {
        acc[item] =
          data.care_partner_tags?.find(
            (x) => x.admin_partner_class.name === item
          )?.id || '';
        return acc;
      }, {}),
    };

    const formFields = form.getFieldsValue();
    const hasChanges =
      JSON.stringify(formFields) !== JSON.stringify(currentTags);

    setHasChanges(hasChanges);
    setRedirectConfirmation(hasChanges);
  };

  // form submit handler
  const handleFinish = async (values) => {
    Logger.log('debug', `CareGuideOwnerTagForm.handleFinish(###)`);
    if (!isSubmitting) {
      await submitData(values);
      setHasChanges(false);
    }
  };

  // form error handler
  const handleFinishFailed = ({ values, errorFields, outOfDate }) => {
    Logger.log('debug', `CareGuideAdminForm.handleFinishFailed(###)`);
    notification.error(getI18n().t('feedback_form_error'));
    if (errorFields && errorFields.length > 0) {
      form.scrollToField(errorFields[0].name);
    }
  };

  const submitForm = () => {
    form.submit();
    setConfirmationModalOpen(false);
  };

  const getTagsValues = (tagsData) => {
    const values = tagsData.reduce((acc, item) => {
      acc[item] =
        data.care_partner_tags?.find((x) => x.admin_partner_class.name === item)
          ?.id || '';
      return acc;
    }, {});
    return values;
  };

  const undoChanges = () => {
    form.setFieldsValue(getTagsValues(tagsNames.current));
    setHasChanges(false);
    setConfirmationModalOpen(false);
  };

  // set errors on form fields
  useEffect(() => {
    let firstFieldName = '';
    for (const field in errors) {
      form.setFields([{ name: field, errors: errors[field] }]);
      if (firstFieldName === '') {
        firstFieldName = field;
      }
    }
    form.scrollToField(firstFieldName);
  }, [form, errors]);

  // update input values when new data is available
  const dataString = JSON.stringify(data);
  useEffect(() => {
    if (careGuideId && !isSubmitting) {
      const dataObj = JSON.parse(dataString);
      const tagsValues = getTagsValues(tagsNames.current);
      form.setFieldsValue({
        ...dataObj,
        ...tagsValues,
      });
    }
    onFieldsChange();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form, dataString, isSubmitting, careGuideId]);

  // submit data handler
  const submitData = async (values) => {
    Logger.log('debug', `CareGuideAdminForm.submitData(###)`);

    // API POST/PUT payload
    let payload = {};
    let care_partner_tags = [];
    for (const input of Object.keys(data)) {
      if (input in values) {
        payload[input] = values[input];
      }
    }
    for (const input of tagsNames.current) {
      if (values[input] && values[input] !== '') {
        care_partner_tags.push(values[input]);
      }
    }
    delete payload['care_partner_tags'];
    payload['care_partner_tags_list'] = care_partner_tags;

    if (careGuideId) {
      // update
      props.update(partnershipId, careGuideId, payload, (success) => {
        if (success) {
          notification.success(
            getI18n().t('member_profile_modal_changes_saved')
          );
        } else {
          notification.error(getI18n().t('member_profile_modal_error'));
        }
        setHasChanges(false);
        setIsEditionMode(false);
      });
    }
  };

  // load record data from API
  useEffect(() => {
    if (partnershipId && careGuideId) {
      load(partnershipId, careGuideId);
    }
  }, [careGuideId, partnershipId, load]);

  // submit form when submitForm is true
  useEffect(() => {
    if (props.submitForm) {
      form.submit();
    }
  }, [props.submitForm, form]);

  return (
    <Translation>
      {(t) => (
        <Row>
          <Col span={24} flex={1} className="member-info-column">
            <Form
              name="member_profile_profile_form"
              className="member-profile-form"
              form={form}
              initialValues={data}
              onFinish={handleFinish}
              validateTrigger="onSubmit"
              onFieldsChange={onFieldsChange}
              onFinishFailed={handleFinishFailed}
            >
              <MemberProfileCard
                data={form.getFieldValue(['owner_profile'])}
                tagText={t('member_profile_role_caregiver')}
                isEditionMode={isEditionMode}
              >
                <Space
                  className={`${
                    isEditionMode ? 'edition-mode edition-bottom' : ''
                  } info-row`}
                >
                  <Row
                    align="top"
                    justify="space-between"
                    className="member-data-row"
                    flex={'auto'}
                  >
                    <Title level={5}>{t('member_profile_tags')}</Title>
                    <Button
                      onClick={() => setIsEditionMode(!isEditionMode)}
                      type="primary"
                      className={`edit-button ${isEditionMode ? 'active' : ''}`}
                      ghost={!isEditionMode}
                    >
                      <FaRegEdit />
                      {t('member_profile_edit')}
                    </Button>
                  </Row>
                  <MemberProfileTags
                    updateTagsNames={updateTagsNames}
                    isEditionMode={isEditionMode}
                    partnershipId={partnershipId}
                  />
                  {isEditionMode && (
                    <Row className="action-btn-row">
                      <Button
                        onClick={() => setConfirmationModalOpen(true)}
                        htmlType="button"
                        className="action-btn cancel-outlined-btn"
                        disabled={!hasChanges}
                        type="danger"
                      >
                        <FiTrash />
                        {t('member_profile_cancel')}
                      </Button>
                      <Button
                        htmlType="submit"
                        className="action-btn save-outlined-btn"
                        disabled={!hasChanges}
                        type="primary"
                      >
                        <MdOutlineSave />
                        {t('member_profile_save')}
                      </Button>
                    </Row>
                  )}
                </Space>
              </MemberProfileCard>
            </Form>
          </Col>
          <Col
            span={24}
            flex="auto"
            xl={{ span: 10 }}
            className="member-info-column "
          >
            <MemberProfileCard
              smallVariant={true}
              data={form.getFieldValue(['careguide_profile'])}
              tagText={t('member_profile_role_caremember')}
            />
          </Col>
          <ConfirmationModal
            texts={modalTexts}
            openConfirmationModal={confirmationModalOpen}
            onLeaveConfirmation={undoChanges}
            onSaveConfirmation={submitForm}
            onCancel={() => setConfirmationModalOpen(false)}
            setConfirmationModalOpen={setConfirmationModalOpen}
          />
        </Row>
      )}
    </Translation>
  );
};

export default withRouter(MemberProfile);
