import {getI18n} from 'react-i18next';

import Config from '../../../../Config';
import Logger from '../../../../lib/Logger';
import Events from '../../../../lib/EventEmitter';

import CalendarScreen from './containers/CalendarScreenContainer';

const register = () => {
  Logger.log('debug', `calendar.register()`);

  if (Config.getIn(['MODULE_TOGGLES', 'calendar', 'routes'])) {
    Events.dispatch('ADD_MAIN_ROUTES', 

      // screen name (key): [route type (element), path (prop), exact (prop), component (prop), route name]
      {
        'CalendarScreen': ['PrivateRoute', '/care-guide/:id([\\w\\-]+)/calendar', true, CalendarScreen, getI18n().t('calendar_route_main')],
      }
    );
  }
}

export default register;

Logger.log('silly', `calendar.register() loaded.`);
