import { connect } from 'react-redux';
import { denormalize } from 'normalizr';
import { getI18n } from 'react-i18next';

import { schema } from '../../../../../state/schema';
import {
  loadCalendarEventsAdmin,
  deleteCalendarEventAdmin,
  calendarEventAdminShowEventDetail,
} from '../../../../../state/modules/calendarEventsAdmin/actions';
import CalendarEventsAdminList from '../components/CalendarEventsAdminList';
import storage from '../../../../../lib/Storage';

const get_care_guide_name = (x) => {
  if (x?.care_guide) {
    if (x?.care_guide?.first_name && x?.care_guide?.last_name) {
      return `${x?.care_guide?.first_name} ${x?.care_guide?.last_name}`;
    }
  } else if (x?.amount_assigned_care_guides > 1) {
      return getI18n().t('calendar_events_is_multiple_care_guide');
  } else if (x.partnership?.name) {
    return x.partnership.name;
  }
  return getI18n().t('calendar_events_universal');
};

const mapStateToProps = (state, ownProps) => {
  const page = ownProps.page;
  const limit = ownProps.limit;
  const order = ownProps.order;
  const result = state.calendarEventsAdmin.getIn(['pages', order, limit, page]);

  return {
    list: result
      ? result
          .map((x) => {
            return {
              key: x,
              ...denormalize(
                state.entities.getIn(['calendarEventsAdmin', x]),
                schema.calendarEventAdmin,
                state.entities.toJS()
              ),
            };
          })
          .toArray()
          .map((x) => {
            // flatten some nested objects to make table easier to work with
            return {
              care_guide_name: get_care_guide_name(x),
              profile_id: x.profile ? x.profile.id : '',
              parent_event_id: x.parent_event ? x.parent_event.id : '',
              profile_username:
                x.profile && x.profile.user ? x.profile.user.username : '',
              ...x,
            };
          })
      : [],
    isLoading: state.calendarEventsAdmin.get('isLoading'),
    total: state.calendarEventsAdmin.get('total'),
    activeId: storage.get('local', 'partnershipId'),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    load: (parntership_id, page, limit, order, filter, cb) => {
      dispatch(
        loadCalendarEventsAdmin(parntership_id, page, limit, order, filter, cb)
      );
    },
    remove: (partnershipId, id, cb) => {
      dispatch(deleteCalendarEventAdmin(partnershipId, id, cb));
    },
    showDetail: (eventId) => {
      dispatch(calendarEventAdminShowEventDetail(eventId));
    },
  };
};

const CalendarEventsAdminListContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(CalendarEventsAdminList);

export default CalendarEventsAdminListContainer;
