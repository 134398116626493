import {Map, List} from 'immutable';

import {
  TYPES
} from './actions'
import Logger from '../../../lib/Logger';

export default function careGuidesAdmin(
  state=Map({
    isLoading: false,
    pages: {},
  }),
  action
) {
  Logger.log('debug', `[careGuidesAdmin.reducers] careGuidesAdmin(%j, %j)`, state, action);

  switch(action.type) {

    case TYPES.CARE_GUIDE_ADMIN_LIST_REQUEST:
      return state.mergeDeep({
        isLoading: true
      });

    case TYPES.CARE_GUIDE_ADMIN_LIST_SUCCESS:
      return state.mergeDeep({
        isLoading: false,
        pages: {
          [action.order]: {
            [action.limit]: {
              [action.page]: null
            }
          }
        },
        total: action.total,
        lastUpdated: action.receivedAt
      }).setIn(['pages', action.order, action.limit, action.page], List(action.result));

    case TYPES.CARE_GUIDE_ADMIN_LIST_FAILURE:
      return state.mergeDeep({
        isLoading: false
      });

    case TYPES.CARE_GUIDE_ADMIN_READ_REQUEST:
      return state.mergeDeep({
        isLoading: true,
      });

    case TYPES.CARE_GUIDE_ADMIN_READ_SUCCESS:
      return state.mergeDeep({
        isLoading: false,
        lastUpdated: action.receivedAt,
      });

    case TYPES.CARE_GUIDE_ADMIN_READ_FAILURE:
      return state.mergeDeep({
        isLoading: false,
      });

    case TYPES.CARE_GUIDE_ADMIN_UPDATE_REQUEST:
      return state.mergeDeep({
        isLoading: true,
        form: {
          isSubmitting: true,
          success: null,
          errors: null,
        },
      });

    case TYPES.CARE_GUIDE_ADMIN_UPDATE_SUCCESS:
      return state.mergeDeep({
        isLoading: false,
        form: {
          isSubmitting: false,
          success: true,
          errors: null,
        },
        lastUpdated: action.receivedAt,
      });

    case TYPES.CARE_GUIDE_ADMIN_UPDATE_FAILURE:
      return state
        .mergeDeep({
          isLoading: false,
          form: {
            isSubmitting: false,
            success: false,
            errors: null,
          },
        })
        .setIn(["form", "errors"], action.error);

    case TYPES.CARE_GUIDE_ADMIN_FORM_DESTROY:
      return state
        .mergeDeep({
          form: null,
        })
        .set("form", action.form);

    default:
      return state;
  }
}

Logger.log('silly', `careGuidesAdmin.reducers loaded.`);
