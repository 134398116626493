import { List, Map } from 'immutable';

import { TYPES } from './actions';
import { SESSION_DESTROY } from '../../actions';
import Logger from '../../../lib/Logger';

const initialState = Map({
  isLoading: false,
  activeItem: null,
  activeItemId: null,
});

export default function learnings(
  state = initialState,
  action
) {
  Logger.log('debug', `[state.learnings.reducers] learnings(%j, %j)`, state, action);

  switch (action.type) {

    case TYPES.SET_ACTIVE_ITEM_ID:
      return state.mergeDeep({
        activeItemId: action.id,
      });

    case TYPES.SET_ACTIVE_ITEM:
      return state.mergeDeep({
        activeItem: action.activeItem,
      });

    case TYPES.SUB_TOPIC_READ_REQUEST:
      return state.mergeDeep({
        isLoading: true
      });

    case TYPES.SUB_TOPIC_READ_SUCCESS:
      return state.mergeDeep({
        isLoading: false,
        lastUpdated: action.receivedAt,
        subTopic: action.result,
      });

    case TYPES.SUB_TOPIC_READ_FAILURE:
      return state.mergeDeep({
        isLoading: false
      });

    case TYPES.LIST_TOPIC_REQUEST:
      return state.mergeDeep({
        isLoading: true
      });

    case TYPES.LIST_TOPIC_SUCCESS:
      return state.mergeDeep({
        isLoading: false,
        results: {
          total: action.total,
          page_count: action.page_count,
          pages: {
              [action.limit]: {
                [action.page]: null
              }
            
          }
        },
        lastUpdated: action.receivedAt
      }).setIn(['results', action.limit, action.page], List(action.result));

    case TYPES.LIST_TOPIC_FAILURE:
      return state.mergeDeep({
        isLoading: false
      });

    case SESSION_DESTROY:
      return initialState;

    default:
      return state;
  }
}

Logger.log('silly', `state.learnings.reducers loaded.`);
