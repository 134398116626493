import CareGuideNoteListCard from '../components/CareGuideNoteListCard';
import { patchCareGuideNote, deleteCareGuideNote, loadCareGuideNotes, careGuideNoteShowForm } from '../../../../../state/modules/careGuideNotes/actions';
import { connect } from 'react-redux';

const mapStateToProps = (state, ownProps) => {
  const profileId = state.session.get('profileId');

  return {
    profileId,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    remove: (careGuideId, id, cb) => {
      dispatch(deleteCareGuideNote(careGuideId, id, cb));
    },
    patch: (careGuideId, id, data, cb) => {
      dispatch(patchCareGuideNote(careGuideId, id, data, cb));
    },
    load: (careGuideId, page, limit, order, filter, cb) => {
      dispatch(loadCareGuideNotes(careGuideId, page, limit, order, filter, cb));
    },
    showForm: (careGuideNoteId) => {
      dispatch(careGuideNoteShowForm(careGuideNoteId));
    },
  }
}

const CareGuideNoteListCardContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(CareGuideNoteListCard);

export default CareGuideNoteListCardContainer;
