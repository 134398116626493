import React, { useState } from 'react';
import { withRouter } from 'react-router';
import { Translation } from 'react-i18next';
import { Button, Space } from 'antd';

import { pathTo } from '../../Routes';
import { RiDashboard3Line } from 'react-icons/ri';
import { Redirect } from 'react-router-dom';
import storage from '../../../../lib/Storage';
import { MixpanelTracker, mixpanelEventsEnum } from '../../../../lib/Mixpanel';

const CareGuideChooserListActions = ({
  item,
  profileId,
  setActive,
  partnershipsCount,
  listPartnerships,
  setActiveUserProfilePartnership,
  pageUserProfilePartnership,
  setActiveAccess,
  page,
  setActivePage,
  partnershipActiveId,
  ...props
}) => {
  const [redirectTo, setRedirectTo] = useState(null);

  const onClick = (event, item, path, page) => {
    event.preventDefault();
    setActive(item.id);
    if (item.partnership) {
      setActiveAccess({
        access_role_admin_in_care_guide:
          item.partnership.access_role_admin_in_care_guide,
      });
      MixpanelTracker.mixpanelTrack(
        mixpanelEventsEnum.PAGES.ACCESS_CARE_GUIDE,
        {
          careGuideName: `${item.care_guide_first_name} ${item.care_guide_last_name}`,
          partnershipName: item.partnership.name,
          careGuideId: item.id,
          partnershipId: item.partnership.id,
        }
      );
      MixpanelTracker.mixpanelTrack(mixpanelEventsEnum.PAGES.ACCESS_PAGE, {
        careGuideName: `${item.care_guide_first_name} ${item.care_guide_last_name}`,
        partnershipName: item.partnership.name,
        careGuideId: item.id,
        partnershipId: item.partnership.id,
        page: path,
      });
    } else {
      MixpanelTracker.mixpanelTrack(
        mixpanelEventsEnum.PAGES.ACCESS_CARE_GUIDE,
        {
          careGuideName: `${item.care_guide_first_name}  ${item.care_guide_last_name}`,
          careGuideId: item.id,
        }
      );
      MixpanelTracker.mixpanelTrack(mixpanelEventsEnum.PAGES.ACCESS_PAGE, {
        careGuideName: `${item.care_guide_first_name}  ${item.care_guide_last_name}`,
        careGuideId: item.id,
        page: path,
      });
    }

    setActivePage(page);
    setRedirectTo(pathTo(path, { id: item.id }));

    if (partnershipsCount > 0 && item.partnership !== null) {
      const partnershipAssociate = listPartnerships.find(
        (x) => x.partnership_id === item.partnership?.id
      );
      setActiveUserProfilePartnership(partnershipAssociate.id);
      storage.set('local', 'userProfilePartnershipId', partnershipAssociate.id);
      storage.set(
        'local',
        'partnershipId',
        partnershipAssociate.partnership_id
      );
      storage.set('local', 'partnershipData', {
        access_role_admin_in_care_guide:
          partnershipAssociate.partnership.access_role_admin_in_care_guide,
        access_role_admin_in_app:
          partnershipAssociate.partnership.access_role_admin_in_app,
        access_tabs_in_app: partnershipAssociate.partnership.access_tabs_in_app,
        role: partnershipAssociate.role,
        name: partnershipAssociate.name,
      });
    }
  };

  if (redirectTo) {
    return <Redirect to={redirectTo} />;
  }

  const profile = item.helpers.find(
    (x) =>
      x.profile && x.profile?.id === profileId && (x.role === 4 || x.role === 5)
  );

  return (
    <Translation>
      {(t) => (
        <>
          <Space>
            {!profile && (
              <Button
                title={t('title_button_user_dashboard')}
                className="react-icon"
                icon={<RiDashboard3Line />}
                onClick={(e) => onClick(e, item, 'DashboardDetailScreen', page)}
              ></Button>
            )}
          </Space>
        </>
      )}
    </Translation>
  );
};

export default withRouter(CareGuideChooserListActions);
