// import React from 'react';
import {getI18n} from 'react-i18next';

import Config from '../../../../Config';
import Logger from '../../../../lib/Logger';
import Events from '../../../../lib/EventEmitter';

import CareGuideOwnersScreen from './containers/CareGuideOwnersScreenContainer';
import MemberActions from './containers/MemberActionsContainer';

const register = () => {
  Logger.log('debug', `members.register()`);

  if (Config.getIn(['MODULE_TOGGLES', 'careGuideOwners', 'routes'])) {
    Events.dispatch('ADD_MAIN_ROUTES', 

      // screen name (key): [route type (element), path (prop), exact (prop), component (prop)]
      {
        'CareGuideOwnersScreen': ['PrivateRoute', "/members-list/:page(\\d+)?/:limit(\\d+)?", true, CareGuideOwnersScreen, getI18n().t('route_memberslist_selector')],
        'CareGuideMembersActions': ['PrivateRoute', "/members-list/actions/care_guide/:id([\\w\\-]+)?/:page(\\d+)?/:limit(\\d+)?", true, MemberActions, getI18n().t('route_memberslist_selector')],
      }
    );
  }
}

export default register;

Logger.log('silly', `careGuideOwners.register() loaded.`);
