import React from 'react';
import {Translation} from 'react-i18next';
import {Card, List, Spin, Typography} from 'antd';
import {LoadingOutlined} from '@ant-design/icons';

import Format from '../../../../../lib/Format';
import Logger from '../../../../../lib/Logger';


const PlanCurrent = ({subscription, isLoading, ...props}) => {

  const {Text} = Typography;

  const plan = subscription ? subscription.plan : null
  const loadingIcon = <Spin style={{height: 18}} indicator={<LoadingOutlined style={{ fontSize: 18 }} spin />} />;

  return (
    <Translation>{(t=>
      <>
        <Card
          title={t('plans_current_title')}
          size="default"
          extra={isLoading ? loadingIcon : null}
        >

          {plan
            ? <>
                <List size="small">

                  <List.Item>
                    <List.Item.Meta
                      title={t('plans_current_plan_name')}
                    />
                    <Text>{plan.name}</Text>
                  </List.Item>

                  <List.Item>
                    <List.Item.Meta
                      title={t('plans_current_member_limit')}
                    />
                    <Text>{plan.helper_limit}</Text>
                  </List.Item>

                  <List.Item>
                    <List.Item.Meta
                      title={t('plans_current_billing')}
                    />
                    <Text>
                      {subscription.subscription_cycle === 'Y'
                        ? (plan.price_year ? t('plans_current_billing_yearly', {price: Format.currency(plan.price_year, true)}) : Format.currency(plan.price_year, true))
                        : (plan.price_month ? t('plans_current_billing_monthly', {price: Format.currency(plan.price_month, true)}) : Format.currency(plan.price_month, true))}
                      </Text>
                  </List.Item>

                </List>
              </>
            : null}

        </Card>
      </>
    )}</Translation>
  );
};

export default PlanCurrent;

Logger.log('silly', `PlanCurrent loaded.`);
