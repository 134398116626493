import { connect } from 'react-redux';
import { denormalize } from 'normalizr';

import { schema } from '../../../../../state/schema';
import {
  resourceNoteHideListAdmin,
  downloadResourceNotesCSV,
} from '../../../../../state/modules/resourceNotes/actions';
import ResourceNotesModalAdmin from '../components/ResourceNotesModalAdmin';

const mapStateToProps = (state, ownProps) => {
  const resourceId = state.resourceNotes.get('listAdminResourceId');
  const resource = resourceId
    ? denormalize(
        state.entities.getIn(['resourcesAdmin', resourceId]),
        schema.resourceAdmin,
        state.entities.toJS()
      )
    : null;

  return {
    partnershipId: ownProps.partnershipId,
    partnershipName: ownProps.partnershipName,
    resource: resource,
    resourceId: resourceId,
    visible: state.resourceNotes.get('listAdminIsVisible'),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    hideList: () => {
      dispatch(resourceNoteHideListAdmin());
    },
    download: (partnershipId, resourceId, order, filter, cb) => {
      dispatch(
        downloadResourceNotesCSV(partnershipId, resourceId, order, filter, cb)
      );
    },
  };
};

const ResourceNotesModalAdminContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ResourceNotesModalAdmin);

export default ResourceNotesModalAdminContainer;
