import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router';
import { Translation, getI18n } from 'react-i18next';
import { Button, Input, Col, Row, Typography, Space } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { pathTo } from '../../../Routes';
import useDebounce from '../../../elements/components/useDebounce';
import QueryString from '../../../../../lib/QueryString';
import { FaRegTrashAlt } from 'react-icons/fa';
import ConfirmationModal from '../../../elements/components/ConfirmationModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTableList, faBorderAll } from '@fortawesome/free-solid-svg-icons';

const { Title, Text } = Typography;

const confirmTexts = {
  title: getI18n().t('resource_list_table_deletes_leave_title'),
  description: null,
  leaveText: getI18n().t('resource_list_table_deletes_leave_leave'),
  saveText: getI18n().t('resource_list_table_deletes_leave_save'),
};

const ResourceAdminListHeader = ({
  total,
  page,
  limit,
  showList,
  partnershipActiveId,
  history,
  selectInvertAll,
  selectedRowKeys,
  confirmDeletes,
  view,
  ...props
}) => {
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [query, setQuery] = useState('');
  const debouncedQuery = useDebounce(query, 1000);
  let params = QueryString.parse(history.location.search);

  const defaultSearch = 'search' in params ? params['search'] : null;
  const [search, setSearch] = useState(defaultSearch);

  const onSearch = (e) => {
    if (e.target.value === '') {
      let path = props.location.pathname;
      delete params['search'];
      history.push(QueryString.append(path, params));
    }

    setQuery(e.target.value);
    setSearch(e.target.value);
  };

  useEffect(() => {
    setSearch(defaultSearch ?? '');
  }, [defaultSearch]);

  useEffect(() => {
    if (debouncedQuery.trim() === '') {
      return;
    } else {
      if (debouncedQuery === search) {
        delete params['search'];
        params['search'] = debouncedQuery;

        const path = pathTo('ResourcesAdminScreen', {
          page: 1,
        });
        history.push(QueryString.append(path, params));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedQuery]);

  const handleConfirmLeave = () => {
    setConfirmationModalOpen(false);
  };

  const handleConfirmDelete = () => {
    setConfirmationModalOpen(false);
    confirmDeletes();
  };

  const onChangeView = (view) => {
    const path = props.location.pathname;
    const params = QueryString.parse(props.location.search);
    params.view = view;
    history.push(QueryString.append(path, params));
  };

  const amountResources = (page, limit) => {
    if (page > 1) {
      return limit * page - limit;
    }
    return 0;
  };

  return (
    <Translation>
      {(t) => (
        <>
          <div className="resource-list-table-header">
            <Row
              justify="space-between"
              align="top"
              className="resource-list-table-header-row"
            >
              <Col>
                <Title level={2} className="resource-title">
                  {t('resource_list_table_title')}
                </Title>
              </Col>
            </Row>

            <Row
              justify="space-between"
              align="top"
              className="resource-list-table-header-row"
            >
              <Col>
                <Row align="top" gutter={16}>
                  <Col>
                    <Input
                      value={search}
                      size="large"
                      placeholder={t('resource_list_table_search')}
                      allowClear
                      onChange={(e) => onSearch(e)}
                    />
                  </Col>
                  <Col>
                    <Button
                      type="primary"
                      size="large"
                      icon={<PlusOutlined />}
                      onClick={(e) =>
                        history.push(
                          pathTo('ResourceAdminAddScreen', {
                            id_partnership: partnershipActiveId,
                          })
                        )
                      }
                    >
                      {t('resource_form_button_add_resouce')}
                    </Button>
                  </Col>
                </Row>
              </Col>
              <Col>
                <Text>
                  {t('resource_list_table_show_selected_total', {
                    showList: showList + amountResources(page, limit),
                    total: total,
                  })}
                </Text>
                <Space style={{ marginLeft: '10px' }}>
                  <Button
                    type="text"
                    onClick={() => onChangeView('table')}
                    className={`${
                      view === 'table'
                        ? 'btn-view-active'
                        : 'btn-view-desactive'
                    }`}
                  >
                    <FontAwesomeIcon icon={faTableList} />
                  </Button>
                  <Button
                    onClick={() => onChangeView('grid')}
                    type="text"
                    className={`${
                      view === 'grid' ? 'btn-view-active' : 'btn-view-desactive'
                    }`}
                  >
                    <FontAwesomeIcon icon={faBorderAll} />
                  </Button>
                </Space>
              </Col>
            </Row>
            {selectedRowKeys.length > 0 && (
              <Row
                align="middle"
                gutter={16}
                className="resource-list-table-header-row"
              >
                <Col>
                  <Text>
                    {t('resource_list_table_show_selected', {
                      selected: selectedRowKeys.length,
                    })}
                  </Text>
                </Col>
                <Col>
                  <Button
                    danger
                    size="large"
                    onClick={() => setConfirmationModalOpen(true)}
                  >
                    <span className="anticon">
                      <FaRegTrashAlt />
                    </span>
                    {t('resource_list_table_delete_resources')}
                  </Button>
                </Col>
              </Row>
            )}
          </div>
          <ConfirmationModal
            texts={confirmTexts}
            openConfirmationModal={confirmationModalOpen}
            onLeaveConfirmation={handleConfirmDelete}
            onSaveConfirmation={handleConfirmLeave}
            onCancel={handleConfirmLeave}
            setConfirmationModalOpen={setConfirmationModalOpen}
          />
        </>
      )}
    </Translation>
  );
};

export default withRouter(ResourceAdminListHeader);
