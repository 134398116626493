import React, { useState } from 'react';
import { withRouter } from 'react-router';
import { Translation } from 'react-i18next';
import { Button, Card, Collapse, Form, Row, Space, Col } from 'antd';
import { CloseCircleOutlined, FilePdfOutlined } from '@ant-design/icons';
import moment from 'moment';

import DateFilter from './DateFilter';
import RatingFilter from './RatingFilter';
import KeywordFilter from './KeywordFilter';
import TagInput from '../../journalTags/containers/TagInputContainer';
import MemberInput from '../../careGuideHelpers/containers/MemberInputContainer';
import QueryString from '../../../../../lib/QueryString';
import Config from '../../../../../Config';
import {
  MixpanelTracker,
  mixpanelEventsEnum,
} from '../../../../../lib/Mixpanel';

const WithCollapse = ({
  children,
  isCollapsible = false,
  isClearButtonVisible = false,
  title = null,
  ...props
}) => {
  const showClearButtonText = true; //width > 415;
  const showDownloadButtonText = true; //width > 360;
  return isCollapsible ? (
    <Collapse>
      <Collapse.Panel
        header={title}
        extra={
          <Space direction="vertical">
            <PDFButton
              text={showDownloadButtonText ? props.buttonText : ''}
              isGeneratingPDF={props.isGeneratingPDF}
              onClickExportHandler={props.onClickExportHandler}
            />
            {isClearButtonVisible ? (
              <ClearButton
                text={showClearButtonText ? props.clearButtonText : ''}
                onClick={props.onClickClearHandler}
              />
            ) : null}
          </Space>
        }
        key="1"
      >
        {children}
      </Collapse.Panel>
    </Collapse>
  ) : (
    children
  );
};

const ClearButton = (props) => (
  <Button size="small" icon={<CloseCircleOutlined />} onClick={props.onClick}>
    {props.text}
  </Button>
);

const PDFButton = (props) => (
  <Button
    size="small"
    icon={<FilePdfOutlined />}
    loading={props.isGeneratingPDF}
    disabled={props.isGeneratingPDF}
    onClick={props.onClickExportHandler}
  >
    {props.text}
  </Button>
);

const JournalPostsFilter = ({
  careGuideId,
  careGuide,
  statusPartnership,
  history,
  title = false,
  isCollapsible = false,
  ...props
}) => {
  const [form] = Form.useForm();

  let params = QueryString.parse(history.location.search);
  const [isClearButtonVisible, setIsClearButtonVisible] = useState(
    Object.keys(params).length === 0 ||
      (Object.keys(params).length === 1 && 'tz' in params)
      ? false
      : true
  );
  params['tz'] = moment().utcOffset() / 60;
  const defaultTags = 'tags' in params ? params['tags'].split(',') : [];
  const defaultMembers =
    'members' in params ? params['members'].split(',') : [];
  const defaultRatings =
    'ratings' in params
      ? params['ratings'].split(',').map((x) => parseInt(x))
      : [];
  const defaultKeywords = 'keywords' in params ? params['keywords'] : '';
  const defaultRange = 'range' in params ? parseInt(params['range']) : 1;
  const defaultStart = 'start' in params ? moment(params['start']) : null;
  const defaultEnd = 'end' in params ? moment(params['end']) : null;

  const [isDateVisible, setDateVisible] = useState(
    defaultRange === 6 ? true : false
  );
  const [resetRatings, setResetRatings] = useState(null);

  // const {width} = useWindowDimensions();
  const showClearButtonText = true; // width > 1200;
  const showDownloadButtonText = true; //width > 1050;

  const isClearButtonVisibleHandler = () => {
    if (
      (!('tags' in params) || params['tags'] === []) &&
      (!('members' in params) || params['members'] === []) &&
      (!('ratings' in params) || params['ratings'] === []) &&
      (!('range' in params) || params['range'] === 1) &&
      (!('keywords' in params) || params['keywords'] === '')
    ) {
      setIsClearButtonVisible(false);
    } else {
      setIsClearButtonVisible(true);
    }
  };

  const onSelectDateHandler = (value) => {
    params['range'] = value;
    let dateValues;

    switch (value) {
      // all
      case 1:
        dateValues = [null, null];
        setDateVisible(false);
        break;

      // last 7 days
      case 2:
        dateValues = [moment().subtract(6, 'days'), moment()];
        setDateVisible(false);
        break;

      // last 30 days
      case 3:
        dateValues = [moment().subtract(29, 'days'), moment()];
        setDateVisible(false);
        break;

      // last 90 days
      case 4:
        dateValues = [moment().subtract(89, 'days'), moment()];
        setDateVisible(false);
        break;

      // last 12 months
      case 5:
        dateValues = [moment().subtract(12, 'months'), moment()];
        setDateVisible(false);
        break;

      // custom
      case 6:
        setDateVisible(true);
        break;

      default:
    }

    form.setFieldsValue({ date: dateValues });
    onChangeDateHandler(dateValues);
    isClearButtonVisibleHandler();

    if (params['range'] === 1) {
      delete params['range'];
    }
    history.push(QueryString.append(history.location.pathname, params));
  };

  const onChangeDateHandler = (dates, dateStrings) => {
    params['start'] = dates && dates[0] ? dates[0].format('YYYY-MM-DD') : '';
    if (params['start'] === '') {
      delete params['start'];
    }
    params['end'] = dates && dates[1] ? dates[1].format('YYYY-MM-DD') : '';
    if (params['end'] === '') {
      delete params['end'];
    }
    history.push(QueryString.append(history.location.pathname, params));
  };

  const onChangeTagHandler = (value, option) => {
    params['tags'] = value.join(',');
    if (params['tags'] === '') {
      delete params['tags'];
    }
    isClearButtonVisibleHandler();
    history.push(QueryString.append(history.location.pathname, params));
  };

  const onChangeMemberHandler = (value, option) => {
    params['members'] = value.join(',');
    if (params['members'] === '') {
      delete params['members'];
    }
    isClearButtonVisibleHandler();
    history.push(QueryString.append(history.location.pathname, params));
  };

  const onChangeRatingsHandler = (value) => {
    params['ratings'] = value.join(',');
    if (params['ratings'] === '') {
      delete params['ratings'];
    }
    isClearButtonVisibleHandler();
    history.push(QueryString.append(history.location.pathname, params));
  };

  const onChangeKeywordsHandler = (value) => {
    console.log('onChangeKeywordsHandler');
    params['keywords'] = value;
    if (params['keywords'] === '') {
      delete params['keywords'];
    }
    isClearButtonVisibleHandler();
    history.push(QueryString.append(history.location.pathname, params));
  };

  const onClickExportHandler = (event) => {
    props.generatePDF(
      careGuideId,
      1,
      200,
      'published_at.desc',
      params,
      (success) => {
        if (success) {
          if (careGuide?.partnership) {
            MixpanelTracker.mixpanelTrack(
              mixpanelEventsEnum.JOURNAL.EXPORT_PDF,
              {
                careGuideName: `${careGuide.first_name} ${careGuide.last_name}`,
                partnershipName: careGuide.partnership.name,
                careGuideId: careGuide.id,
                partnershipId: careGuide.partnership.id,
              }
            );
          } else {
            MixpanelTracker.mixpanelTrack(
              mixpanelEventsEnum.JOURNAL.EXPORT_PDF,
              {
                careGuideName: `${careGuide.first_name} ${careGuide.last_name}`,
                careGuideId: careGuide.id,
              }
            );
          }
        }
      }
    );
    event.stopPropagation();
  };

  const onClickClearHandler = (event) => {
    form.setFieldsValue({
      tags: [],
      members: [],
      range: 1,
      date: [null, null],
      keywords: '',
    });
    setResetRatings(Math.random());
    setDateVisible(false);
    setIsClearButtonVisible(false);
    params = {};
    history.push(QueryString.append(history.location.pathname, params));
    event.stopPropagation();
  };

  return (
    <Translation>
      {(t) => (
        <WithCollapse
          isCollapsible={isCollapsible}
          title={title}
          buttonText={t('journal_posts_filter_button_download_pdf')}
          clearButtonText={t('journal_posts_filter_button_clear')}
          isGeneratingPDF={props.isGeneratingPDF}
          onClickExportHandler={onClickExportHandler.bind(this)}
          onClickClearHandler={onClickClearHandler.bind(this)}
          isClearButtonVisible={isClearButtonVisible}
        >
          <Card
            title={isCollapsible ? null : title}
            size="small"
            bordered={false}
            extra={
              isCollapsible ? null : (
                <Space direction="vertical">
                  <PDFButton
                    text={
                      showDownloadButtonText
                        ? t('journal_posts_filter_button_download_pdf')
                        : ''
                    }
                    isGeneratingPDF={props.isGeneratingPDF}
                    onClickExportHandler={onClickExportHandler.bind(this)}
                  />
                  {isClearButtonVisible ? (
                    <ClearButton
                      text={
                        showClearButtonText
                          ? t('journal_posts_filter_button_clear')
                          : ''
                      }
                      onClick={onClickClearHandler.bind(this)}
                    />
                  ) : null}
                </Space>
              )
            }
          >
            <Form
              layout="vertical"
              className="journal-posts-filter-form"
              form={form}
              initialValues={{
                tags: defaultTags,
                members: defaultMembers,
                range: defaultRange,
                date: [defaultStart, defaultEnd],
                keywords: defaultKeywords,
              }}
            >
              <Row gutter={16}>
                <Col xs={24} sm={12} lg={24}>
                  <DateFilter
                    selectName="range"
                    dateName="date"
                    label={t('journal_posts_filter_input_date')}
                    onSelect={onSelectDateHandler}
                    onChange={onChangeDateHandler}
                    showDate={isDateVisible}
                  />
                </Col>

                <Col xs={24} sm={12} lg={24}>
                  <TagInput
                    name="tags"
                    label={t('journal_posts_filter_input_tags')}
                    placeholder={t('journal_posts_filter_placeholder_tags')}
                    careGuideId={careGuideId}
                    onChange={onChangeTagHandler}
                  />
                </Col>
                <Col xs={24} sm={12} lg={24}>
                  <RatingFilter
                    label={t('journal_posts_filter_input_ratings')}
                    onChange={onChangeRatingsHandler}
                    defaultValue={defaultRatings}
                    reset={resetRatings}
                  />
                </Col>

                <Col xs={24} sm={12} lg={24}>
                  <MemberInput
                    name="members"
                    label={t('journal_posts_filter_input_members')}
                    placeholder={t('journal_posts_filter_placeholder_members')}
                    multiple={true}
                    careGuideId={careGuideId}
                    onChange={onChangeMemberHandler}
                  />
                </Col>

                {Config.getIn([
                  'MODULE_TOGGLES',
                  'journalPosts',
                  'keywords',
                ]) ? (
                  <Col xs={24} sm={12} lg={24}>
                    <KeywordFilter
                      name="keywords"
                      label={t('journal_posts_filter_input_keywords')}
                      placeholder={t(
                        'journal_posts_filter_placeholder_keywords'
                      )}
                      careGuideId={careGuideId}
                      onSearch={onChangeKeywordsHandler}
                    />
                  </Col>
                ) : null}
              </Row>
            </Form>
          </Card>
        </WithCollapse>
      )}
    </Translation>
  );
};

export default withRouter(JournalPostsFilter);
