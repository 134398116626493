import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { Translation } from 'react-i18next';
import { Button, List, Modal, Popover, Space, Tabs, Tooltip } from 'antd';

import { care, frustrated, like, love, laugh, sad } from './Emoji';
import UserAvatar from '../../../elements/components/UserAvatar';
import hasPermission, {
  CREATE_JOURNAL_EMOJI,
} from '../../../elements/lib/hasPermission';
import Logger from '../../../../../lib/Logger';
import {
  MixpanelTracker,
  mixpanelEventsEnum,
} from '../../../../../lib/Mixpanel';

const JournalEmojisList = ({
  careGuideId,
  careGuide,
  journalPostId,
  component,
  load,
  create,
  remove,
  total,
  page = 1,
  limit = 50,
  order = 'count.desc',
  ...props
}) => {
  const [isAddReactionVisible, setAddReactionVisible] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const trackerJournalEmoji = (event, params) => {
    if (careGuide?.partnership) {
      MixpanelTracker.mixpanelTrack(event, {
        careGuideName: `${careGuide.first_name} ${careGuide.last_name}`,
        partnershipName: careGuide.partnership.name,
        careGuideId: careGuide.id,
        partnershipId: careGuide.partnership.id,
        journalPostId: journalPostId,
        ...params,
      });
    } else {
      MixpanelTracker.mixpanelTrack(event, {
        careGuideName: `${careGuide.first_name} ${careGuide.last_name}`,
        careGuideId: careGuide.id,
        journalPostId: journalPostId,
        ...params,
      });
    }
  };

  useEffect(() => {
    load(careGuideId, journalPostId, page, limit, order);
  }, [careGuideId, journalPostId, page, limit, order, load]);

  const toggleAddReactionHandler = () =>
    setAddReactionVisible(!isAddReactionVisible);

  const createReaction = (code) => {
    create(careGuideId, journalPostId, code, (success) => {
      if (success) {
        load(careGuideId, journalPostId, page, limit, order);
        trackerJournalEmoji(
          mixpanelEventsEnum.JOURNAL.CREATE_REACTION_EMOJI_POST,
          { reaction: code }
        );
      }
    });
  };

  const removeReaction = (id) => {
    remove(careGuideId, journalPostId, id, (success) => {
      if (success) {
        load(careGuideId, journalPostId, page, limit, order);
        trackerJournalEmoji(
          mixpanelEventsEnum.JOURNAL.DELETE_REACTION_EMOJI_POST
        );
      }
    });
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const emojiOptions = [
    { label: 'journal_emojis_label_like', code: '👍', svg: like },
    { label: 'journal_emojis_label_love', code: '❤️', svg: love },
    { label: 'journal_emojis_label_care', code: '🥰', svg: care },
    { label: 'journal_emojis_label_laugh', code: '😁', svg: laugh },
    { label: 'journal_emojis_label_frustrated', code: '😡', svg: frustrated },
    { label: 'journal_emojis_label_sad', code: '😢', svg: sad },
  ];

  // prep reaction button values
  let buttonEmoji = 0;
  let buttonIsActive = false;
  let reactionId = null;
  if (props.list) {
    const selectedEmojis = props.list.filter((x) => x.id);
    if (selectedEmojis.length > 0) {
      const selectedButtonEmoji = emojiOptions.indexOf(
        emojiOptions.filter((x) => x.code === selectedEmojis[0].code)[0]
      );
      if (selectedButtonEmoji > -1) {
        buttonEmoji = selectedButtonEmoji;
      }
      buttonIsActive = true;
      reactionId = selectedEmojis[0].id;
    }
  }

  const addReactionContent = (t) => (
    <div className="journal-emoji-tooltip">
      <Space>
        {emojiOptions.map((x, i) => (
          <Tooltip key={i} placement="top" title={t(x.label)}>
            <Button
              size="small"
              shape="circle"
              type={buttonIsActive && i === buttonEmoji ? 'primary' : 'default'}
              ghost={buttonIsActive && i === buttonEmoji}
              onClick={
                buttonIsActive && i === buttonEmoji
                  ? () => {
                      toggleAddReactionHandler();
                      removeReaction(reactionId);
                    }
                  : () => {
                      toggleAddReactionHandler();
                      createReaction(x.code);
                    }
              }
            >
              {x.svg ? x.svg(16) : x.code}
            </Button>
          </Tooltip>
        ))}
      </Space>
    </div>
  );

  return (
    <Translation>
      {(t) => (
        <>
          <div className="journal-emojis">
            {hasPermission(CREATE_JOURNAL_EMOJI) ? (
              <Popover
                title={t('journal_emojis_add_popover_title')}
                content={addReactionContent(t)}
                visible={isAddReactionVisible}
                placement="topLeft"
                trigger="click"
                onVisibleChange={toggleAddReactionHandler}
              >
                <Button
                  key="add-emoji"
                  className="add-emoji"
                  type={buttonIsActive ? 'primary' : 'default'}
                  size="small"
                  ghost={buttonIsActive}
                  onClick={toggleAddReactionHandler}
                >
                  <span className={buttonIsActive ? '' : 'inactive'}>
                    {t(emojiOptions[buttonEmoji].label)}{' '}
                    {emojiOptions[buttonEmoji].svg ? (
                      <span className="journal-emoji-svg">
                        {emojiOptions[buttonEmoji].svg(13)}
                      </span>
                    ) : (
                      emojiOptions[buttonEmoji].code
                    )}
                  </span>
                </Button>
              </Popover>
            ) : null}
            {props.list && props.list.length > 0 ? (
              <Link onClick={showModal} to="#">
                <Space wrap={true} size={4}>
                  {emojiOptions.map((x, i) =>
                    x.code in props.map && props.map[x.code].count > 0 ? (
                      <Tooltip
                        key={i}
                        placement="bottom"
                        title={
                          x.code in props.map && props.map[x.code].profiles ? (
                            <div>
                              <div className="center">
                                <b>{t(x.label)}</b>
                              </div>
                              <div>
                                {props.map[x.code].profiles
                                  .map((p) => `${p.first_name} ${p.last_name}`)
                                  .join(', ')}
                              </div>
                            </div>
                          ) : null
                        }
                      >
                        <div className="journal-emoji">
                          {x.svg ? (
                            <span className="journal-emoji-svg">
                              {x.svg(18)}
                            </span>
                          ) : (
                            x.code
                          )}
                        </div>
                      </Tooltip>
                    ) : null
                  )}
                  <span className="emoji-count">
                    {
                      props.list.reduce((prev, cur) => ({
                        count: prev.count + cur.count,
                      })).count
                    }
                  </span>
                </Space>
              </Link>
            ) : null}
          </div>

          {props.list && props.list.length > 0 ? (
            <Modal
              title={null}
              visible={isModalVisible}
              onOk={handleOk}
              onCancel={handleCancel}
              width={750}
              footer={[
                <Button key="ok" type="primary" onClick={handleOk}>
                  {t('action_close')}
                </Button>,
              ]}
              wrapClassName="journal-reactions-modal"
            >
              <Tabs>
                {emojiOptions.map((x, i) =>
                  x.code in props.map && props.map[x.code].count > 0 ? (
                    <Tabs.TabPane
                      tab={
                        <span>
                          {t(x.label)}{' '}
                          {x.svg ? (
                            <span className="journal-emoji-svg">
                              {x.svg(18)}
                            </span>
                          ) : (
                            x.code
                          )}{' '}
                          {props.map[x.code].count}
                        </span>
                      }
                      key={i}
                    >
                      <List
                        itemLayout="horizontal"
                        dataSource={props.map[x.code].profiles}
                        renderItem={(item) => (
                          <List.Item>
                            <List.Item.Meta
                              avatar={
                                <UserAvatar
                                  profile={item.profile}
                                  showTooltp={false}
                                  size={32}
                                />
                              }
                              title={`${item.first_name} ${item.last_name}`}
                            />
                          </List.Item>
                        )}
                      />
                    </Tabs.TabPane>
                  ) : null
                )}
              </Tabs>
            </Modal>
          ) : null}
        </>
      )}
    </Translation>
  );
};

export default withRouter(JournalEmojisList);

Logger.log('silly', `JournalEmojisList loaded.`);
