import { connect } from 'react-redux';

import MemberEventFormInput from '../components/MemberEventFormInput';
import { loadCareGuidesMembersPartnership } from '../../../../../state/modules/calendar/actions';

const mapStateToProps = (state, ownProps) => {
  const profileId = state.session.get('profileId');
  return {
    total: state.calendar.get('totalParticipantsEvent'),
    isLoading: state.calendar.get('areParticipantEventsLoading'),
    profileId: profileId,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadParticipants: (partnershipId, page, limit, order, filter, data, cb) => {
      dispatch(loadCareGuidesMembersPartnership(partnershipId, page, limit, order, filter, data, cb));
    },
  };
};

const MemberEventFormInputContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(MemberEventFormInput);

export default MemberEventFormInputContainer;
