import {connect} from 'react-redux';
import {denormalize} from 'normalizr';
import {List} from 'immutable';

import {schema} from '../../../../../state/schema';
import {
  createJournalPost,
  loadJournalPosts,
  journalPostFormDestroy
} from '../../../../../state/modules/journalPosts/actions';
import {
  getImageUploadURL,
  uploadImage,
  createMediaImage,
  deleteMediaImage,
} from '../../../../../state/modules/media/actions';
import JournalPostForm from '../components/JournalPostForm';

const inputs = List([
  'rating',
  'body',
  'tags',
  'media'
]);

const mapStateToProps = (state, ownProps) => {

  const careGuide = ownProps.careGuideId
    ? denormalize(
        state.entities.getIn(['careGuides', ownProps.careGuideId]),
        schema.careGuide,
        state.entities.toJS()
      )
    : null;

  const errors = {};
  for (const val of inputs.values()) {
    if (state.journalPosts.getIn(['form', 'errors', val])) {
      errors[val] = state.journalPosts.getIn(['form', 'errors', val]);
    }
  }
  
  return {
    careGuide: careGuide,
    isSubmitting: state.journalPosts.getIn(['form', 'isSubmitting']),
    success: state.journalPosts.getIn(['form', 'success']),
    errors: errors
  }
}

const mapDispatchToProps = dispatch => {
  return {
    create: (careGuideId, data, cb) => {
      dispatch(createJournalPost(careGuideId, data, cb));
    },
    load: (careGuideId, page, limit, order, filter, cb) => {
      dispatch(loadJournalPosts(careGuideId, page, limit, order, filter, cb));
    },
    getUploadUrl: (extension, directory, cb) => {
      dispatch(getImageUploadURL(extension, directory, cb));
    },
    upload: (path, file, cb) => {
      dispatch(uploadImage(path, file, cb));
    },
    createImage: (careGuideId, data, cb) => {
      dispatch(createMediaImage(careGuideId, data, cb));
    },
    deleteImage: (careGuideId, id, cb) => {
      dispatch(deleteMediaImage(careGuideId, id, cb));
    },
    formDestroy: (formState) => {
      dispatch(journalPostFormDestroy(formState));
    },
  }
}

const JournalPostFormContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(JournalPostForm);

export default JournalPostFormContainer;
