import { connect } from 'react-redux';
import { List } from 'immutable';
import moment from 'moment-timezone';

import MemberEventForm from '../components/MemberEventForm';
import storage from '../../../../../lib/Storage';
import {
  calendarEventFormDestroy,
  calendarHideEventForm,
  createCalendarEventPartnership,
} from '../../../../../state/modules/calendar/actions';

const inputs = List([
  'title',
  'url_event_partner',
  'url_label',
  'description',
  'start_at_date',
  'start_at_time',
  'end_at_date',
  'end_at_time',
  'is_all_day',
  'participants',
  'recurring',
  'recurring_end_at',
  'parent_event_id',
]);

const mapStateToProps = (state, ownProps) => {
  const errors = {};
  for (const val of inputs.values()) {
    if (state.calendar.getIn(['eventForm', 'errors', val])) {
      errors[val] = state.calendar.getIn(['eventForm', 'errors', val]);
    }
  }

  const profileId = state.session.get('profileId');

  const tz = state.userAccount.getIn(
    ['data', 'timezone'],
    'America/Los_Angeles'
  );
  const startDateObj = moment();

  const data = {
    title: '',
    url_event_partner: '',
    url_label: '',
    is_all_day: false,
    start_at_date: startDateObj,
    start_at_time: '8:00 AM',
    end_at_date: startDateObj.format('MM/DD/YYYY'),
    end_at_time: '9:00 AM',
    description: '',
    participants: [],
    recurring: 1,
    recurring_end_at: null,
    parent_event_id: '',
  };

  return {
    visible: state.calendar.get('eventFormIsVisible'),
    isSubmitting: state.calendar.getIn(['eventForm', 'isSubmitting']),
    success: state.calendar.getIn(['eventForm', 'success']),
    timezone: tz,
    profileId: profileId,
    data: data,
    errors: errors,
    partnershipId: storage.get('local', 'partnershipId'),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    create: (careGuideId, data, cb) => {
      dispatch(createCalendarEventPartnership(careGuideId, data, cb));
    },
    formDestroy: (formState) => {
      dispatch(calendarEventFormDestroy(formState));
    },
    hideForm: () => {
      dispatch(calendarHideEventForm());
    },
  };
};

const MemberEventFormContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(MemberEventForm);

export default MemberEventFormContainer;
