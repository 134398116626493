import React, { useState, useEffect } from 'react';
import { Translation, getI18n } from 'react-i18next';
import { Button, Form } from 'antd';
import { MessageOutlined } from '@ant-design/icons';

import BodyInput from './BodyInput';
import UserAvatar from '../../../elements/components/UserAvatar';
import message from '../../../elements/lib/MessageWrapper';
import Logger from '../../../../../lib/Logger';
import useWindowDimensions from '../../../../../lib/WindowDimensions';
import {
  MixpanelTracker,
  mixpanelEventsEnum,
} from '../../../../../lib/Mixpanel';

const JournalReplyForm = ({
  careGuideId,
  careGuide,
  journalPostId,
  page,
  limit,
  order,
  errors,
  load,
  destroyForm,
  isLoading,
  isSubmitting,
  ...props
}) => {
  const [resetBody, setResetBody] = useState(null);
  const [form] = Form.useForm();

  const { width } = useWindowDimensions();
  const isLarge = width > 992;

  // handle errors reported by API
  useEffect(() => {
    let firstFieldName = '';
    for (const field in errors) {
      form.setFields([{ name: field, errors: errors[field] }]);
      if (firstFieldName === '') {
        firstFieldName = field;
      }
    }
    form.scrollToField(firstFieldName);
  }, [form, errors]);

  // submit data handler
  const submitData = async (values) => {
    Logger.log('debug', `JournalReplyForm.submitData(###)`);

    const payload = {};

    // transform values
    for (var key in values) {
      payload[key] = values[key];
    }

    props.create(careGuideId, journalPostId, payload, (success) => {
      if (success) {
        message.success(getI18n().t('feedback_form_success'));
        for (let i = 1; i <= page; i++) {
          load(careGuideId, journalPostId, i, limit, order);
        }
        form.setFieldsValue({ body: '', rating: '' });
        setResetBody(Math.random());

        if (careGuide?.partnership) {
          MixpanelTracker.mixpanelTrack(
            mixpanelEventsEnum.JOURNAL.CREATE_REPLY_POST,
            {
              careGuideName: `${careGuide.first_name} ${careGuide.last_name}`,
              partnershipName: careGuide.partnership.name,
              careGuideId: careGuide.id,
              partnershipId: careGuide.partnership.id,
              journalPostId: journalPostId,
            }
          );
        } else {
          MixpanelTracker.mixpanelTrack(
            mixpanelEventsEnum.JOURNAL.CREATE_REPLY_POST,
            {
              careGuideName: `${careGuide.first_name} ${careGuide.last_name}`,
              careGuideId: careGuide.id,
              journalPostId: journalPostId,
            }
          );
        }
      } else {
        message.error(getI18n().t('feedback_form_error'));
      }
    });
  };

  // form submit handler
  const handleFinish = async (values) => {
    Logger.log('debug', `JournalReplyForm.handleFinish(###)`);
    if (!props.isSubmitting) {
      await submitData(values);
    }
  };

  // form error handler
  const handleFinishFailed = ({ values, errorFields, outOfDate }) => {
    Logger.log('debug', `JournalReplyForm.handleFinishFailed(###)`);
    message.error(getI18n().t('feedback_form_error'));
    if (errorFields && errorFields.length > 0) {
      form.scrollToField(errorFields[0].name);
    }
  };

  // remove error message when input value changes
  const handleValuesChange = (changedValues, allValues) => {
    for (const key of Object.keys(changedValues)) {
      form.setFields([{ name: key, errors: [] }]);
    }
  };

  return (
    <Translation>
      {(t) => (
        <>
          <div className="journal-reply-form">
            <UserAvatar
              profile={props.userProfile}
              showTooltp={false}
              size={isLarge ? 32 : 24}
            />
            <Form
              name="journal_reply_form"
              form={form}
              onFinish={handleFinish}
              onFinishFailed={handleFinishFailed}
              onValuesChange={handleValuesChange}
              validateTrigger="onSubmit"
              requiredMark={false}
              layout="vertical"
            >
              <BodyInput
                name="body"
                form={form}
                rules={[
                  {
                    required: true,
                    message: t('feedback_validation_required'),
                  },
                  {
                    type: 'string',
                    min: 1,
                    max: 4000,
                    message: t('feedback_validation_char_range', {
                      min: 1,
                      max: 4000,
                    }),
                  },
                ]}
                disabled={isLoading || isSubmitting}
                reset={resetBody}
              />

              <Button
                type="primary"
                size="small"
                icon={<MessageOutlined />}
                htmlType="submit"
                loading={isSubmitting}
              >
                {t('journal_replies_form_button_submit')}
              </Button>
            </Form>
          </div>
        </>
      )}
    </Translation>
  );
};

export default JournalReplyForm;

Logger.log('silly', `JournalReplyForm loaded.`);
