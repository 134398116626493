import React, {Component} from 'react';
import {Translation} from 'react-i18next';
import {Col, Row} from 'antd';

import DocumentHead from '../../../elements/components/DocumentHead';
import PlanCurrent from '../../plans/containers/PlanCurrentContainer';
import SubscriptionSettings from '../containers/SubscriptionSettingsContainer';
import scrollToTop from '../../../elements/lib/scrollToTop';
import Logger from '../../../../../lib/Logger';

import '../styles/Payments.less';

class SubscriptionScreen extends Component {
  render() {
    return (
      <Translation>{(t) => 
        <div>

          <DocumentHead title={t('payments_route_subscription')} />

            <Row gutter={24}>

              <Col xl={12} lg={24} md={24} sm={24} xs={24}>
                <PlanCurrent />
              </Col>

              <Col xl={12} lg={24} md={24} sm={24} xs={24}>
                <SubscriptionSettings />
              </Col>

            </Row>

        </div>
      }</Translation>
    )
  }

  /* LIFECYCLE EVENTS */

  componentDidMount() {
    scrollToTop();
    this.props.loadSubscription();
  }
}

export default SubscriptionScreen;

Logger.log('silly', `SubscriptionScreen loaded.`);
