import React, { Suspense, useEffect, useState } from 'react';
import { Translation } from 'react-i18next';
import DrawerMenuAdmin from '../../menus/containers/DrawerMenuAdminContainer';
import { Alert, Button, Card, Col, Drawer, Layout, Row, Space } from 'antd';
import {
  CaretRightOutlined,
} from '@ant-design/icons';
import { arrowLeft } from '../../elements/components/Icons';

import { AdminRoutes } from '../../Routes';
import MainMenuAdmin from '../../menus/containers/MainMenuAdminContainer';
import Loading from '../../elements/components/Loading';
import Breadcrumbs from '../../elements/components/Breadcrumbs';
import UpdatePasswordForm from '../../modules/userAccount/containers/UpdatePasswordFormContainer';
import useWindowDimensions from '../../../../lib/WindowDimensions';
import Config from '../../../../Config';
import PartnershipWrapper from './PartnershipWrapper';
import '../../modules/admin/styles/admin.less';
import { pathTo } from '../../Routes';
import { Redirect } from 'react-router-dom';

const { Header, Content, Sider } = Layout;

const AdminLayout = (props) => {
  const [drawerVisible, setDrawerVisible] = useState(false);

  const { width } = useWindowDimensions();
  const collapseSidebarWidth = Config.get('UI_COLLAPSE_SIDEBAR_WIDTH');

  const loading = () => <Loading />;

  const toggle = () => {
    props.uiChangeMenuAdminCollapsed();
  };

  const toggleDrawer = () => {
    setDrawerVisible(!drawerVisible);
  };

  const goBack = () => {
    window.history.back();
  }

  useEffect(() => {
    props.loadUserProfilesPartnership(
      props.page,
      props.limit,
      props.orderUserProfilePartnership,
      null,
      (success) => {}
    );
  }, [props]);

  return (
    <Translation>
      {(t) => (
        <>
          <div>
            {props.defaultPartnershipId ? (
              <PartnershipWrapper>
                <div className="default-layout">
                  <Layout>
                    <Drawer
                      closable={false}
                      placement="right"
                      onClose={toggleDrawer}
                      visible={drawerVisible}
                      width={220}
                    >
                      <DrawerMenuAdmin toggleDrawer={toggleDrawer.bind(this)} />
                    </Drawer>

                    <Sider
                    onCollapse={toggle}
                      collapsible
                      collapsed={
                        props.isMenuAdminCollapsed ||
                        width <= collapseSidebarWidth
                      }
                    >
                      <div className="logo" />
                      <MainMenuAdmin currentPath={props.location.pathname} />
                    </Sider>

                    <Layout>
                      <Header>
                        <div className="main-nav-controls">
                          {width > collapseSidebarWidth ? (
                            <Space>
                              <Button
                                className={'icon-button'}
                                type="text"
                                icon={arrowLeft(10, 17)}
                                onClick={goBack}
                              />
                              <Breadcrumbs pathname={props.location.pathname} />
                            </Space>
                          ) : null}
                        </div>
                        <div className="mobile-nav-controls">
                          <div style={{ float: 'right' }}>
                            <Button onClick={toggleDrawer}>
                              <CaretRightOutlined className="trigger-drawer" />
                            </Button>
                          </div>
                          <h1>{t('app_name')}</h1>
                        </div>
                      </Header>

                      <Content>
                        <div id="content-main">
                          <Suspense fallback={loading()}>
                            {props.updatePassword ? (
                              <>
                                <Row
                                  type="flex"
                                  justify="space-around"
                                  align="middle"
                                >
                                  <Col xs={24} md={18} lg={14} xl={12}>
                                    <Alert
                                      message={t('password_warning_expired')}
                                      type="warning"
                                      style={{ marginBottom: 20 }}
                                    />
                                    <Card title={t('password_form_title')}>
                                      <UpdatePasswordForm />
                                    </Card>
                                  </Col>
                                </Row>
                              </>
                            ) : (
                              <AdminRoutes />
                            )}
                          </Suspense>
                        </div>
                      </Content>
                    </Layout>
                  </Layout>
                </div>
              </PartnershipWrapper>
            ) : (
              <Redirect to={pathTo('HomeScreen')} />
            )}
          </div>
        </>
      )}
    </Translation>
  );
};

export default AdminLayout;
