import { Table } from "antd";
import React, { useEffect } from "react";
import { withRouter } from "react-router";
import Logger from "../../../../../lib/Logger";
import { Translation, getI18n } from "react-i18next";
import CareGuideChooserListActions from "../../../elements/components/CareGuideChooserListActions";


import QueryString from "../../../../../lib/QueryString";
import { pathTo } from "../../../Routes";
import { getColumnSearchProps } from '../../../elements/components/TableColumnFilters';

const CareGuideChooserHomeList = ({
  component,
  pageUserProfilePartnership,
  page,
  limit,
  order,
  filter,
  total,
  load,
  history,
  profileId,
  setActive,
  partnershipsCount,
  listPartnerships,
  setActiveUserProfilePartnership,
  setActivePage,
  setActiveAccess,
  partnershipActiveId,
  showForm,
  ...props
}) => {

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
  };

  const handleReset = (clearFilters, dataIndex) => {
    clearFilters();
    delete filter[dataIndex];
    history.push(QueryString.append(props.location.pathname, { order, ...filter }));
  };

  const columns = [
    {
      title: getI18n().t("owner_first_name"),
      dataIndex: "owner_profile_first_name",
      key: "owner_profile_first_name",
      ...getColumnSearchProps('owner_profile_first_name', handleSearch, handleReset, getI18n().t('owner_first_name'), filter),
    },
    {
      title: getI18n().t("owner_last_name"),
      dataIndex: "owner_profile_last_name",
      key: "owner_profile_last_name",
      ...getColumnSearchProps('owner_profile_last_name', handleSearch, handleReset, getI18n().t('owner_last_name'), filter),
    },
    {
      title: getI18n().t("care_guide_first_name"),
      dataIndex: "care_guide_first_name",
      key: "care_guide_first_name",
      ...getColumnSearchProps('care_guide_first_name', handleSearch, handleReset, getI18n().t('care_guide_first_name'), filter),
    },
    {
      title: getI18n().t("care_guide_last_name"),
      dataIndex: "care_guide_last_name",
      key: "care_guide_last_name",
      ...getColumnSearchProps('care_guide_last_name', handleSearch, handleReset, getI18n().t('care_guide_last_name'), filter),
    },
    {
      title: getI18n().t("owner_email"),
      dataIndex: "owner_profile_email",
      key: "owner_profile_email",
      ...getColumnSearchProps('owner_profile_email', handleSearch, handleReset, getI18n().t('owner_email'), filter),
    },
    {
      title: getI18n().t("care_guide_partner_tags_title"),
      key: "care_guide_partner_tags",
      ...getColumnSearchProps('care_guide_partner_tags', handleSearch, handleReset, getI18n().t('care_guide_partner_tags'), filter),
    },
    {
      title: getI18n().t("table_header_tool_tips"),
      key: "actions",
      render: (item) => (
        <CareGuideChooserListActions
          item={item}
          profileId={profileId}
          setActive={setActive}
          partnershipsCount={partnershipsCount}
          listPartnerships={listPartnerships}
          setActiveUserProfilePartnership={setActiveUserProfilePartnership}
          setActivePage={setActivePage}
          setActiveAccess={setActiveAccess}
          page={page}
          limit={limit}
          order={order}
          pageUserProfilePartnership={pageUserProfilePartnership}
          partnershipActiveId={partnershipActiveId}
        />
      ),
    },
  ];

  const pagination = {
    current: page,
    pageSize: limit,
    total: total,
    showSizeChanger: false,
    position: ['bottomCenter'],
  };

  const handleTableChange = (pagination, filters, sorter) => {

    let path = props.location.pathname;
    const params = {};

    // handle pagination
    if ('current' in pagination && pagination['current']) {
      path = pathTo(component, { page: pagination['current'] });
    }

    // handle sorting
    if ('field' in sorter && 'order' in sorter) {
      if (sorter['field'] && sorter['order']) {
        const order = sorter['field'] + '.' + (sorter['order'] === 'ascend' ? 'asc' : 'desc');
        params['order'] = order;
      }
    }

    // handle filters
    if (filters) {
      for (const key in filters) {
        if (filters[key]) {
          params[key] = filters[key].join(',');
        }
      }
    }

    history.push(QueryString.append(path, params));
  }

  const filterString = JSON.stringify(filter);

  useEffect(() => {
    load(page, limit, order, JSON.parse(filterString), partnershipActiveId);
  }, [page, limit, order, filterString, partnershipActiveId, load]);


  return (
    <Translation>
      {(t) => (
        <Table
          columns={columns}
          dataSource={props.list}
          loading={props.isLoading}
          pagination={page === 1 && total < limit ? false : pagination}
          scroll={{ x: true }}
          onChange={handleTableChange}
        />
      )}
    </Translation>
  );
};

export default withRouter(CareGuideChooserHomeList);

Logger.log("silly", `CareGuideChooserHomeList loaded.`);
