import {connect} from 'react-redux';
import {List} from 'immutable';
import {denormalize} from 'normalizr';

import {schema} from '../../../../../state/schema';
import {
  loadCareGuide,
  updateCareGuideSegment,
  deleteCareGuideSegment,
  generateCareGuideSegmentPDF,
} from '../../../../../state/modules/careGuides/actions';
import {uiSetRoutingPreventTransition} from '../../../../../state/ui/actions';
import CareGuideSegment from '../components/CareGuideSegment';

const inputs = List([
  'name',
  'order',
  'contents',
]);

const mapStateToProps = (state, ownProps) => {

  // const careGuideId = ownProps.careGuideId;
  const segmentId = ownProps.segmentId;

  const data = {}, errors = {};
  for (const val of inputs.values()) {
    data[val] = state.entities.getIn(['careGuideSegments', segmentId, val], '');
    errors[val] = state.careGuides.getIn(['segmentForm', 'errors', val]);
  }

  const segment = segmentId
    ? denormalize(
        state.entities.getIn(['careGuideSegments', segmentId]),
        schema.careGuideSegment,
        state.entities.toJS()
      )
    : null;

  // hydrate contents for dynamic inputs
  if ('contents' in data && Array.isArray(data['contents'])) {
    data['contents'] = data['contents'].map(x => {
      return {
        key: x,
        ...denormalize(
          state.entities.getIn(['CareGuideSegmentContents', x]),
          schema.CareGuideSegmentContent,
          state.entities.toJS()
        )
      };
    });
  }

  return {
    isLoading: state.careGuides.get('isLoading'),
    isDeleting: state.careGuides.get('isDeleting'),
    isSubmitting: state.careGuides.getIn(['segmentForm', 'isSubmitting']),
    success: state.careGuides.getIn(['segmentForm', 'success']),
    isGeneratingPDF: state.careGuides.get('isGeneratingSegmentPDF'),
    segment: segment,
    data: data,
    errors: errors,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    load: (id, cb) => {
      dispatch(loadCareGuide(id, cb));
    },
    update: (careGuideId, segmentId, data, cb) => {
      dispatch(updateCareGuideSegment(careGuideId, segmentId, data, cb));
    },
    remove: (careGuideId, segmentId, cb) => {
      dispatch(deleteCareGuideSegment(careGuideId, segmentId, cb));
    },
    generatePDF: (careGuideId, segmentId, cb) => {
      dispatch(generateCareGuideSegmentPDF(careGuideId, segmentId, cb));
    },
    preventRoutingTransition: (prevent) => {
      dispatch(uiSetRoutingPreventTransition(prevent));
    },
  }
}

const CareGuideSegmentContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(CareGuideSegment);

export default CareGuideSegmentContainer;
