import {connect} from 'react-redux';
import {List} from 'immutable';

import {loadCareGuide, createCareGuideSegment} from '../../../../../state/modules/careGuides/actions';
import {uiSetRoutingPreventTransition} from '../../../../../state/ui/actions';
import CareGuideSegmentCreate from '../components/CareGuideSegmentCreate';

const inputs = List([
  'name',
  'contents',
  'order',
]);

const mapStateToProps = (state, ownProps) => {

  // const careGuideId = ownProps.careGuideId;

  const data = {}, errors = {};
  for (const val of inputs.values()) {
    data[val] = '';
    errors[val] = state.careGuides.getIn(['segmentForm', 'errors', val]);
  }
  data['order'] = ownProps.maxOrder;

  return {
    isLoading: state.careGuides.get('isLoading'),
    isSubmitting: state.careGuides.getIn(['segmentForm', 'isSubmitting']),
    success: state.careGuides.getIn(['segmentForm', 'success']),
    data: data,
    errors: errors,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    load: (id, cb) => {
      dispatch(loadCareGuide(id, cb));
    },
    create: (careGuideId, data, cb) => {
      dispatch(createCareGuideSegment(careGuideId, data, cb));
    },
    preventRoutingTransition: (prevent) => {
      dispatch(uiSetRoutingPreventTransition(prevent));
    },
  }
}

const CareGuideSegmentCreateContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(CareGuideSegmentCreate);

export default CareGuideSegmentCreateContainer;
