import {normalize} from 'normalizr';

import api from '../../api';
import {schema} from '../../schema';
import {addEntities} from '../../actions';
import Logger from '../../../lib/Logger';

// ACTION TYPES


export const TYPES = {
  
 CARE_GUIDE_ADMIN_LIST_REQUEST: 'CARE_GUIDE_ADMIN_LIST_REQUEST',
 CARE_GUIDE_ADMIN_LIST_SUCCESS: 'CARE_GUIDE_ADMIN_LIST_SUCCESS',
 CARE_GUIDE_ADMIN_LIST_FAILURE: 'CARE_GUIDE_ADMIN_LIST_FAILURE',
 CARE_GUIDE_ADMIN_FORM_DESTROY: 'CARE_GUIDE_ADMIN_FORM_DESTROY',
 CARE_GUIDE_ADMIN_READ_REQUEST: 'CARE_GUIDE_ADMIN_READ_REQUEST',
 CARE_GUIDE_ADMIN_READ_SUCCESS: 'CARE_GUIDE_ADMIN_READ_SUCCESS',
 CARE_GUIDE_ADMIN_READ_FAILURE: 'CARE_GUIDE_ADMIN_READ_FAILURE',
 CARE_GUIDE_ADMIN_UPDATE_REQUEST: 'CARE_GUIDE_ADMIN_UPDATE_REQUEST',
 CARE_GUIDE_ADMIN_UPDATE_SUCCESS: 'CARE_GUIDE_ADMIN_UPDATE_SUCCESS',
 CARE_GUIDE_ADMIN_UPDATE_FAILURE: 'CARE_GUIDE_ADMIN_UPDATE_FAILURE',
}

// ACTION CREATORS

export function careGuideAdminListRequest(page, limit, order, filter) {
  Logger.log('debug', `[careGuidesAdmin.actions] careGuideAdminListRequest(${page}, ${limit}, ${order}, %j)`, filter);
  return {
    type: TYPES.CARE_GUIDE_ADMIN_LIST_REQUEST,
    page: page,
    limit: limit,
    order: order,
    filter: filter
  }
}

export function careGuideAdminListSuccess(data) {
  Logger.log('debug', `[careGuidesAdmin.actions] careGuideAdminListSuccess(%j)`, data);
  return {
    type:  TYPES.CARE_GUIDE_ADMIN_LIST_SUCCESS,
    page: data.page,
    limit: data.limit,
    order: data.order,
    result: data.result,
    total: data.total,
    receivedAt: Date.now()
  }
}

export function careGuideAdminListFailure(error) {
  Logger.log('debug', `[careGuidesAdmin.actions] careGuideAdminListFailure(%j)`, error);
  return {
    type:  TYPES.CARE_GUIDE_ADMIN_LIST_FAILURE,
    error: error
  }
}

export function careGuideReadAdminRequest(id) {
  Logger.log('debug', `[careGuidesAdmin.actions] careGuideReadAdminRequest(${id})`);
  return {
    type: TYPES.CARE_GUIDE_ADMIN_READ_REQUEST,
    id: id
  }
}

export function careGuideReadAdminSuccess(data) {
  Logger.log('debug', `[careGuidesAdmin.actions] careGuideReadAdminSuccess(%j)`, data);
  return {
    type: TYPES.CARE_GUIDE_ADMIN_READ_SUCCESS,
    id: data.id,
    receivedAt: Date.now()
  }
}

export function careGuideReadAdminFailure(error) {
  Logger.log('debug', `[careGuidesAdmin.actions] careGuideReadAdminFailure(%j)`, error);
  return {
    type: TYPES.CARE_GUIDE_ADMIN_READ_FAILURE,
    error: error
  }
}



export function careGuideAdminUpdateRequest(id, data) {
  Logger.log('debug', `[careGuidesAdmin.actions] careGuideAdminUpdateRequest(${id}, %j)`, data);
  return {
    type: TYPES.CARE_GUIDE_ADMIN_UPDATE_REQUEST,
  }
}

export function careGuideAdminUpdateSuccess(data) {
  Logger.log('debug', `[careGuidesAdmin.actions] careGuideAdminUpdateSuccess(%j)`, data);
  return {
    type: TYPES.CARE_GUIDE_ADMIN_UPDATE_SUCCESS,
    id: data.id,
    receivedAt: Date.now()
  }
}

export function careGuideAdminUpdateFailure(error) {
  Logger.log('debug', `[careGuidesAdmin.actions] careGuideAdminUpdateFailure(%j)`, error);
  return {
    type: TYPES.CARE_GUIDE_ADMIN_UPDATE_FAILURE,
    error: error
  }
}

export function careGuideAdminFormDestroy(formState=null) {
  Logger.log('debug', `[careGuidesAdmin.actions] careGuideAdminFormDestroy(%j)`, formState);
  return {
    type: TYPES.CARE_GUIDE_ADMIN_FORM_DESTROY,
    form: formState
  }
}


// API THUNK ACTION CREATORS

export function loadCareGuidesAdmin(parntershipId = null, page=1, limit=10, order=null, filter=null, cb=function(){}) {
  Logger.log('debug', `[careGuidesAdmin.actions] loadCareGuidesAdmin(${page}, ${limit}, ${order}, %j, ###)`, filter);

  return async function(dispatch) {
    dispatch(careGuideAdminListRequest(page, limit, order, filter));

    // call API
    const response = await api.getCareGuides(page, limit, order, filter, parntershipId);
    let success = false;

    // get care guides admin list success
    if (200 === response.get('status')) {

      Logger.log('info', `Get API care guides list admin success. Page: ${page}, Limit: ${limit}, Order: ${order}.`);

      const normalizedEntities = normalize(response.getIn(['data', 'care_guides']), [schema.careGuideAdmin]);

      const data = {
        page: response.getIn(['data', 'page']),
        limit: response.getIn(['data', 'limit']),
        order: order,
        total: response.getIn(['data', 'total']),
        result: normalizedEntities.result
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(careGuideAdminListSuccess(data));
      success = true;

    } else if (1 === page && 204 === response.get('status')) {

      Logger.log('info', `Get API care guides admin success [empty]. Page: ${page}, Limit: ${limit}, Order: ${order}.`);
      const data = {
        page: page,
        limit: limit,
        order: order,
        total: 0,
        result: []
      };
      dispatch(careGuideAdminListSuccess(data));
      success = true;
      
    // get care guides list failure
    } else {
      Logger.log('info', `Get API care guides list failure. Page: ${page}, Limit: ${limit}, Order: ${order}.`);
      dispatch(careGuideAdminListFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  }
}

export function loadCareGuideAdmin(partnership_id, id, cb=function(){}) {
  Logger.log('debug', `[careGuidesAdmin.actions] loadCareGuideAdmin(${id}, ###)`);

  return async function(dispatch) {
    dispatch(careGuideReadAdminRequest(id));

    // call API
    const response = await api.getCareGuideAdmin(partnership_id, id);
    let success = false;

    // get care guide admin success
    if (200 === response.get('status')) {

      Logger.log('info', `Get API care guide admin success. ID: ${id}.`);

      const normalizedEntities = normalize([response.getIn(['data', 'care_guide_admin'])], [schema.careGuideAdmin]);
      const data = {
        id: response.getIn(['data', 'care_guide_admin', 'id']),
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(careGuideReadAdminSuccess(data));
      success = true;

    // get care guide admin failure
    } else {
      Logger.log('info', `Get API care guide admin failure. ID: ${id}.`);
      dispatch(careGuideReadAdminFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  }
}

export function updateCareGuideAdmin(partnership_id, id, data, cb=function(){}) {
  Logger.log('debug', `[careGuidesAdmin.actions] updateCareGuideAdmin(${id}, %j, ###)`, data);

  return async function(dispatch) {
    dispatch(careGuideAdminUpdateRequest(id, data));

    // call API
    const response = await api.putCareGuideAdmin(partnership_id, id, data);
    let success = false;

    // put care guide admin success
    if (200 === response.get('status')) {

      Logger.log('info', `PUT API care guide admin success. User: ${id}.`);

      const normalizedEntities = normalize([response.getIn(['data', 'care_guide_admin'])], [schema.careGuideAdmin]);
      const data = {
        id: response.getIn(['data', 'care_guide_admin', 'id']),
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(careGuideAdminUpdateSuccess(data));
      success = true;

    // get care guide admin failure
    } else {
      Logger.log('info', `PUT API care guide admin failure. ID: ${id}.`);
      dispatch(careGuideAdminUpdateFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  }
}



Logger.log('silly', `careGuidesAdmin.actions loaded.`);
