import { connect } from 'react-redux';

import ResourceAdminEditScreen from '../components/ResourceAdminEditScreen';

const mapStateToProps = (state) => {
  return {
    isLoading: state.resourcesAdmin.get('isLoading'),
    success: state.resourcesAdmin.getIn(['form', 'success']),
  };
};

const ResourceAdminEditScreenContainer = connect(mapStateToProps)(
  ResourceAdminEditScreen
);

export default ResourceAdminEditScreenContainer;
