import React, { useEffect } from 'react';
import { withRouter } from 'react-router';
import { Translation } from 'react-i18next';
import { List } from 'antd';

import JournalPostDetail from '../containers/JournalPostDetailContainer';
import QueryString from '../../../../../lib/QueryString';
import { pathTo } from '../../../Routes';
import Logger from '../../../../../lib/Logger';

const JournalPostsList = ({
  careGuideId,
  component,
  page,
  limit,
  order,
  tags,
  partner_tags,
  members,
  ratings,
  keywords,
  start,
  end,
  exclude,
  total,
  load,
  history,
  ...props
}) => {
  useEffect(() => {
    const filter =
      tags ||
      partner_tags ||
      members ||
      ratings ||
      keywords ||
      start ||
      end ||
      exclude
        ? {
            tags: tags,
            partner_tags: partner_tags,
            members: members,
            ratings: ratings,
            keywords: keywords,
            start: start,
            end: end,
            exclude: exclude,
          }
        : null;
    load(careGuideId, page, limit, order, filter);
  }, [
    careGuideId,
    page,
    limit,
    order,
    load,
    tags,
    partner_tags,
    members,
    ratings,
    keywords,
    start,
    end,
    exclude,
  ]);

  const pagination = careGuideId
    ? {
        onChange: (newPage) => {
          const queryParams = {};
          if (tags) queryParams.tags = tags;
          if (partner_tags) queryParams.partner_tags = partner_tags;
          if (members) queryParams.members = members;
          if (ratings) queryParams.ratings = ratings;
          if (keywords) queryParams.keywords = keywords;
          if (start) queryParams.start = start;
          if (end) queryParams.end = end;
          const path = pathTo(
            component,
            { id: careGuideId, page: newPage },
            queryParams
          );
          history.push(QueryString.append(path));
        },
        current: page,
        pageSize: limit,
        total: total,
      }
    : null;

  return (
    <Translation>
      {(t) => (
        <List
          className="journal-posts-list"
          itemLayout="vertical"
          dataSource={props.list}
          pagination={page === 1 && total <= limit ? false : pagination}
          renderItem={(item) => (
            <List.Item key={item.id}>
              <JournalPostDetail
                careGuideId={careGuideId}
                journal={item}
                page={page}
                limit={limit}
                order={order}
              />
            </List.Item>
          )}
        />
      )}
    </Translation>
  );
};

export default withRouter(JournalPostsList);

Logger.log('silly', `JournalPostsList loaded.`);
