import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { Translation } from 'react-i18next';
import { Col, PageHeader, Row } from 'antd';

import PaymentStatus from './PaymentStatus';
import DocumentHead from '../../../elements/components/DocumentHead';
import scrollToTop from '../../../elements/lib/scrollToTop';
import QueryString from '../../../../../lib/QueryString';
import { pathTo } from '../../../Routes';
import Logger from '../../../../../lib/Logger';
import imageLogo from '../../../assets/images/logo-300x300.png';

import '../styles/Payments.less';

class PaymentStatusScreen extends Component {
  render() {
    // get URL query parameters
    const params = QueryString.parse(this.props.location.search);

    const paymentIntent =
      'payment_intent' in params ? params.payment_intent : null;
    const paymentIntentClientSecret =
      'payment_intent_client_secret' in params
        ? params.payment_intent_client_secret
        : null;

    const setupIntent = 'setup_intent' in params ? params.setup_intent : null;
    const setupIntentClientSecret =
      'setup_intent_client_secret' in params
        ? params.setup_intent_client_secret
        : null;

    if (
      !(paymentIntent && paymentIntentClientSecret) &&
      !(setupIntent && setupIntentClientSecret)
    ) {
      return <Redirect to={pathTo('DashboardScreen')} />;
    }

    return (
      <Translation>
        {(t) => (
          <div className="screen screen-public screen-register">
            <DocumentHead title={t('payments_route_status')} />

            <PageHeader
              title={t('register_screen_step3_page_title')}
              subTitle={t('payments_screen_status_title')}
            />

            <div className="logo">
              <img
                src={imageLogo}
                height="42"
                alt={t('app_name')}
                className=""
              />
            </div>

            <div className="container-form-register">
              <Row type="flex" justify="space-around" align="middle">
                <Col xs={22} sm={20} md={16} lg={14} xl={12}>
                  <PaymentStatus
                    paymentIntent={paymentIntent}
                    paymentIntentClientSecret={paymentIntentClientSecret}
                    setupIntent={setupIntent}
                    setupIntentClientSecret={setupIntentClientSecret}
                  />
                </Col>
              </Row>
            </div>
          </div>
        )}
      </Translation>
    );
  }

  /* LIFECYCLE EVENTS */

  componentDidMount() {
    scrollToTop();
  }
}

export default PaymentStatusScreen;

Logger.log('silly', `PaymentStatusScreen loaded.`);
