import React, {useState} from 'react';
import {Translation} from 'react-i18next';
import {Form, Input, Popover, Typography, Space} from 'antd';
import {CheckCircleFilled, CloseCircleOutlined} from '@ant-design/icons';


const incompleteTextType = 'default';
const incompleteIcon = <CloseCircleOutlined style={{ color: '#00000040' }} />;

const completeTextType = 'secondary';
const completeIcon = <CheckCircleFilled style={{ color: '#52c41a' }} />;

const RegistrationCodeInput = ({name, form, hasError=false, label=null, rules=null, disabled=false, autoFocus=false, useTooltip=true, tooltipPlacement='right', useSuccess=true, reference=null, ...props}) => {

  const [isVisible, setIsVisible] = useState(false);

  const [isLengthComplete, setIsLengthComplete] = useState(form.getFieldValue(name) && form.getFieldValue(name).length >= 5 && form.getFieldValue(name).length <= 20);

  const onChange = e => {
    const val = e.target.value;
    
    // check length
    setIsLengthComplete(val.length >= 5 && val.length <= 20);
  }

  return (
    <Translation>{(t) =>
      <div className="form-group">
        <Popover
          placement={tooltipPlacement}
          trigger="focus"
          title={null}
          visible={useTooltip && isVisible && !isLengthComplete}
          content={
            <>
              <Space direction="vertical">

                <Typography.Text
                  type={isLengthComplete ? completeTextType : incompleteTextType}
                >
                  {isLengthComplete ? completeIcon : incompleteIcon}{' '}
                  {t('input_requirment_length', {min: 5, max: 20})}
                </Typography.Text>

              </Space>
            </>
          }
        >
          <Form.Item
            name={name}
            label={label}
            rules={rules
              ? rules
              : [
                  {min: 5, max: 20, message: t('feedback_validation_length', {min: 5, max: 20})},
                ]}
            // hasFeedback={useSuccess && isLengthComplete && !hasError}
            hasFeedback={true}
            validateStatus={hasError ? "error" : (useSuccess && isLengthComplete ? "success" : null)}
          >
            <Input
              disabled={disabled}
              autoFocus={autoFocus}
              onChange={onChange}
              ref={reference}
              onFocus={() => setIsVisible(true)}
              onBlur={() => setIsVisible(false)}
            />
          </Form.Item>
        </Popover>
      </div>
    }</Translation>
  )
}

export default RegistrationCodeInput;
