import {connect} from 'react-redux';

import PageHeader from '../components/PageHeader';

const mapStateToProps = (state) => {
  return {
    careGuideCount: state.careGuides.get('total'),
  }
}

const PageHeaderContainer = connect(
  mapStateToProps
)(PageHeader);

export default PageHeaderContainer;
