import { connect } from 'react-redux';
import { List } from 'immutable';
import {
  loadResources,
  loadResource,
  createResource,
  updateResource,
  deleteResource,
  patchResource,
  resourceFormDestroy,
  resourceHideForm,
} from '../../../../../state/modules/resources/actions';
import {
  getImageUploadURL,
  uploadImage,
  createMediaImage,
  createMediaImageCareGuidePartnership,
  deleteMediaImageCareGuidePartnership,
  deleteMediaImage,
} from '../../../../../state/modules/media/actions';
import { loadTotalMembers } from '../.../../../../../../state/modules/careGuides/actions';

import ResourceForm from '../components/ResourceForm';

const inputs = List([
  'name',
  'description',
  'telephone',
  'website',
  'email',
  'tags',
  'service_tags',
  'logo',
  'is_pinned',
  'is_favorite',
  'care_guide',
  'care_guides',
  'resources_care_partner_tags',
]);

const mapStateToProps = (state, ownProps) => {
  const data = {},
    errors = {};
  for (const val of inputs.values()) {
    data[val] = state.entities.getIn(
      ['resources', state.resources.get('formId'), val],
      ''
    );
    if (state.resources.getIn(['form', 'errors', val])) {
      errors[val] = state.resources.getIn(['form', 'errors', val]);
    }
  }

  const resourceId = state.resources.get('formId');

  if (resourceId) {
    const care_guide = data['care_guide'] ?? null;

    if (Array.isArray(data['care_guides'])) {
      if (data['care_guides'].length < 1) {
        data['care_guides'] = care_guide ? [care_guide] : [];
      }
    } else {
      data['care_guides'] = care_guide ? [care_guide] : [];
    }
  } else {
    data['care_guides'] = [ownProps.careGuideId];
  }

  if (data['resources_care_partner_tags'] === '') {
    data['resources_care_partner_tags'] = [];
  }

  if (data['tags'] === '') {
    data['tags'] = [];
  }

  if (data['service_tags'] === '') {
    data['service_tags'] = [];
  }

  return {
    visible: state.resources.get('formIsVisible'),
    isSubmitting: state.resources.getIn(['form', 'isSubmitting']),
    success: state.resources.getIn(['form', 'success']),
    totalMembers: state.careGuides.get('totalMembers'),
    totalMembersFiltered: state.careGuides.get('totalMembersFiltered'),
    idsFiltered: state.careGuides.get('idsFiltered'),
    data: data,
    errors: errors,
    resourceId: resourceId,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    create: (careGuideId, data, cb) => {
      dispatch(createResource(careGuideId, data, cb));
    },
    load: (careGuideId, page, limit, order, filter, cb) => {
      dispatch(loadResources(careGuideId, page, limit, order, filter, cb));
    },
    loadResource: (careGuideId, id, cb) => {
      dispatch(loadResource(careGuideId, id, cb));
    },
    loadTotalMembers: (partnershipId, filter, cb) => {
      dispatch(loadTotalMembers(partnershipId, filter, cb));
    },
    update: (careGuideId, id, data, cb) => {
      dispatch(updateResource(careGuideId, id, data, cb));
    },
    delete: (careGuideId, id, cb) => {
      dispatch(deleteResource(careGuideId, id, cb));
    },
    formDestroy: (formState) => {
      dispatch(resourceFormDestroy(formState));
    },
    hideForm: () => {
      dispatch(resourceHideForm());
    },
    getUploadUrl: (extension, directory, cb) => {
      dispatch(getImageUploadURL(extension, directory, cb));
    },
    upload: (path, file, cb) => {
      dispatch(uploadImage(path, file, cb));
    },
    createImage: (careGuideId, data, cb) => {
      dispatch(createMediaImage(careGuideId, data, cb));
    },
    createImagePartnership: (careGuideId, partnershipId, data, cb) => {
      dispatch(
        createMediaImageCareGuidePartnership(
          careGuideId,
          partnershipId,
          data,
          cb
        )
      );
    },
    deleteImage: (careGuideId, id, cb) => {
      dispatch(deleteMediaImage(careGuideId, id, cb));
    },
    deleteImagePartnership: (careGuideId, partnershipId, id, cb) => {
      dispatch(
        deleteMediaImageCareGuidePartnership(careGuideId, partnershipId, id, cb)
      );
    },
    patch: (careGuideId, id, data, cb) => {
      dispatch(patchResource(careGuideId, id, data, cb));
    },
  };
};

const ResourceFormContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ResourceForm);

export default ResourceFormContainer;
