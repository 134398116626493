import {connect} from 'react-redux';

import CareGuidePartnerTagAdminAddScreen from '../components/CareGuidePartnerTagAdminAddScreen';

const mapStateToProps = state => {
  return {
    isLoading: state.careGuidePartnerTagsAdmin.get('isLoading'),
    success: state.careGuidePartnerTagsAdmin.getIn(['form', 'success']),
  }
}

const CareGuidePartnerTagAdminAddScreenContainer = connect(
  mapStateToProps
)(CareGuidePartnerTagAdminAddScreen);

export default CareGuidePartnerTagAdminAddScreenContainer;