import {normalize} from 'normalizr';

import api from '../../api';
import {schema} from '../../schema';
import {addEntities, removeEntity} from '../../actions';
import Logger from '../../../lib/Logger';

// ACTION TYPES

export const CALENDAR_EVENT_ADMIN_LIST_REQUEST = 'CALENDAR_EVENT_ADMIN_LIST_REQUEST';
export const CALENDAR_EVENT_ADMIN_LIST_SUCCESS = 'CALENDAR_EVENT_ADMIN_LIST_SUCCESS';
export const CALENDAR_EVENT_ADMIN_LIST_FAILURE = 'CALENDAR_EVENT_ADMIN_LIST_FAILURE';
export const CALENDAR_EVENT_ADMIN_READ_REQUEST = 'CALENDAR_EVENT_ADMIN_READ_REQUEST';
export const CALENDAR_EVENT_ADMIN_READ_SUCCESS = 'CALENDAR_EVENT_ADMIN_READ_SUCCESS';
export const CALENDAR_EVENT_ADMIN_READ_FAILURE = 'CALENDAR_EVENT_ADMIN_READ_FAILURE';
export const CALENDAR_EVENT_ADMIN_UPDATE_REQUEST = 'CALENDAR_EVENT_ADMIN_UPDATE_REQUEST';
export const CALENDAR_EVENT_ADMIN_UPDATE_SUCCESS = 'CALENDAR_EVENT_ADMIN_UPDATE_SUCCESS';
export const CALENDAR_EVENT_ADMIN_UPDATE_FAILURE = 'CALENDAR_EVENT_ADMIN_UPDATE_FAILURE';
export const CALENDAR_EVENT_ADMIN_CREATE_REQUEST = 'CALENDAR_EVENT_ADMIN_CREATE_REQUEST';
export const CALENDAR_EVENT_ADMIN_CREATE_SUCCESS = 'CALENDAR_EVENT_ADMIN_CREATE_SUCCESS';
export const CALENDAR_EVENT_ADMIN_CREATE_FAILURE = 'CALENDAR_EVENT_ADMIN_CREATE_FAILURE';
export const CALENDAR_EVENT_ADMIN_DELETE_REQUEST = 'CALENDAR_EVENT_ADMIN_DELETE_REQUEST';
export const CALENDAR_EVENT_ADMIN_DELETE_SUCCESS = 'CALENDAR_EVENT_ADMIN_DELETE_SUCCESS';
export const CALENDAR_EVENT_ADMIN_DELETE_FAILURE = 'CALENDAR_EVENT_ADMIN_DELETE_FAILURE';
export const CALENDAR_EVENT_ADMIN_FORM_DESTROY = 'CALENDAR_EVENT_ADMIN_FORM_DESTROY';
export const CALENDAR_EVENT_ADMIN_SHOW_EVENT_DETAIL = 'CALENDAR_EVENT_ADMIN_SHOW_EVENT_DETAIL';
export const CALENDAR_EVENT_ADMIN_HIDE_EVENT_DETAIL = 'CALENDAR_EVENT_ADMIN_HIDE_EVENT_DETAIL';
export const CALENDAR_EVENT_ADMIN_LIST_PARTICIPANTS_REQUEST = 'CALENDAR_EVENT_ADMIN_LIST_PARTICIPANTS_REQUEST';
export const CALENDAR_EVENT_ADMIN_LIST_PARTICIPANTS_SUCCESS = 'CALENDAR_EVENT_ADMIN_LIST_PARTICIPANTS_SUCCESS';
export const CALENDAR_EVENT_ADMIN_LIST_PARTICIPANTS_FAILURE = 'CALENDAR_EVENT_ADMIN_LIST_PARTICIPANTS_FAILURE';
export const CALENDAR_EVENT_ADMIN_DELETE_PARTICIPANT_REQUEST = 'CALENDAR_EVENT_ADMIN_DELETE_PARTICIPANT_REQUEST';
export const CALENDAR_EVENT_ADMIN_DELETE_PARTICIPANT_SUCCESS = 'CALENDAR_EVENT_ADMIN_DELETE_PARTICIPANT_SUCCESS';
export const CALENDAR_EVENT_ADMIN_DELETE_PARTICIPANT_FAILURE = 'CALENDAR_EVENT_ADMIN_DELETE_PARTICIPANT_FAILURE';
export const CALENDAR_EVENT_ADMIN_SHOW_EVENT_PARTICIPANT_FORM = 'CALENDAR_EVENT_ADMIN_SHOW_EVENT_PARTICIPANT_FORM';
export const CALENDAR_EVENT_ADMIN_HIDE_EVENT_PARTICIPANT_FORM = 'CALENDAR_EVENT_ADMIN_HIDE_EVENT_PARTICIPANT_FORM';
export const CALENDAR_EVENT_ADMIN_EVENT_PARTICIPANT_FORM_DESTROY = 'CALENDAR_EVENT_ADMIN_EVENT_PARTICIPANT_FORM_DESTROY';
export const CALENDAR_EVENT_ADMIN_UPDATE_PARTICIPANT_REQUEST = 'CALENDAR_EVENT_ADMIN_UPDATE_PARTICIPANT_REQUEST';
export const CALENDAR_EVENT_ADMIN_UPDATE_PARTICIPANT_SUCCESS = 'CALENDAR_EVENT_ADMIN_UPDATE_PARTICIPANT_SUCCESS';
export const CALENDAR_EVENT_ADMIN_UPDATE_PARTICIPANT_FAILURE = 'CALENDAR_EVENT_ADMIN_UPDATE_PARTICIPANT_FAILURE';
export const CALENDAR_EVENT_ADMIN_CREATE_PARTICIPANT_REQUEST = 'CALENDAR_EVENT_ADMIN_CREATE_PARTICIPANT_REQUEST';
export const CALENDAR_EVENT_ADMIN_CREATE_PARTICIPANT_SUCCESS = 'CALENDAR_EVENT_ADMIN_CREATE_PARTICIPANT_SUCCESS';
export const CALENDAR_EVENT_ADMIN_CREATE_PARTICIPANT_FAILURE = 'CALENDAR_EVENT_ADMIN_CREATE_PARTICIPANT_FAILURE';

export const CALENDAR_EVENT_PARTICIPANT_SET_PAGE = 'CALENDAR_EVENT_PARTICIPANT_SET_PAGE';



// ACTION CREATORS

export function calendarEventAdminListRequest(page, limit, order, filter) {
  Logger.log('debug', `[calendarEventsAdmin.actions] calendarEventAdminListRequest(${page}, ${limit}, ${order}, %j)`, filter);
  return {
    type: CALENDAR_EVENT_ADMIN_LIST_REQUEST,
    page: page,
    limit: limit,
    order: order,
    filter: filter
  }
}

export function calendarEventAdminListSuccess(data) {
  Logger.log('debug', `[calendarEventsAdmin.actions] calendarEventAdminListSuccess(%j)`, data);
  return {
    type: CALENDAR_EVENT_ADMIN_LIST_SUCCESS,
    page: data.page,
    limit: data.limit,
    order: data.order,
    result: data.result,
    total: data.total,
    receivedAt: Date.now()
  }
}

export function calendarEventAdminListFailure(error) {
  Logger.log('debug', `[calendarEventsAdmin.actions] calendarEventAdminListFailure(%j)`, error);
  return {
    type: CALENDAR_EVENT_ADMIN_LIST_FAILURE,
    error: error
  }
}

export function calendarEventAdminReadRequest(id) {
  Logger.log('debug', `[calendarEventsAdmin.actions] calendarEventAdminReadRequest(${id})`);
  return {
    type: CALENDAR_EVENT_ADMIN_READ_REQUEST,
    id: id
  }
}

export function calendarEventAdminReadSuccess(data) {
  Logger.log('debug', `[calendarEventsAdmin.actions] calendarEventAdminReadSuccess(%j)`, data);
  return {
    type: CALENDAR_EVENT_ADMIN_READ_SUCCESS,
    id: data.id,
    receivedAt: Date.now()
  }
}

export function calendarEventAdminReadFailure(error) {
  Logger.log('debug', `[calendarEventsAdmin.actions] calendarEventAdminReadFailure(%j)`, error);
  return {
    type: CALENDAR_EVENT_ADMIN_READ_FAILURE,
    error: error
  }
}

export function calendarEventAdminUpdateRequest(id, data) {
  Logger.log('debug', `[calendarEventsAdmin.actions] calendarEventAdminUpdateRequest(${id}, %j)`, data);
  return {
    type: CALENDAR_EVENT_ADMIN_UPDATE_REQUEST,
  }
}

export function calendarEventAdminUpdateSuccess(data) {
  Logger.log('debug', `[calendarEventsAdmin.actions] calendarEventAdminUpdateSuccess(%j)`, data);
  return {
    type: CALENDAR_EVENT_ADMIN_UPDATE_SUCCESS,
    id: data.id,
    receivedAt: Date.now()
  }
}

export function calendarEventAdminUpdateFailure(error) {
  Logger.log('debug', `[calendarEventsAdmin.actions] calendarEventAdminUpdateFailure(%j)`, error);
  return {
    type: CALENDAR_EVENT_ADMIN_UPDATE_FAILURE,
    error: error
  }
}

export function calendarEventAdminCreateRequest(data) {
  Logger.log('debug', `[calendarEventsAdmin.actions] calendarEventAdminCreateRequest(%j)`, data);
  return {
    type: CALENDAR_EVENT_ADMIN_CREATE_REQUEST,
  }
}

export function calendarEventAdminCreateSuccess(data) {
  Logger.log('debug', `[calendarEventsAdmin.actions] calendarEventAdminCreateSuccess(%j)`, data);
  return {
    type: CALENDAR_EVENT_ADMIN_CREATE_SUCCESS,
    id: data.id,
    receivedAt: Date.now()
  }
}

export function calendarEventAdminCreateFailure(error) {
  Logger.log('debug', `[calendarEventsAdmin.actions] calendarEventAdminCreateFailure(%j)`, error);
  return {
    type: CALENDAR_EVENT_ADMIN_CREATE_FAILURE,
    error: error
  }
}

export function calendarEventAdminDeleteRequest(id) {
  Logger.log('debug', `[calendarEventsAdmin.actions] calendarEventAdminDeleteRequest(${id})`);
  return {
    type: CALENDAR_EVENT_ADMIN_DELETE_REQUEST,
    id: id
  }
}

export function calendarEventAdminDeleteSuccess(id) {
  Logger.log('debug', `[calendarEventsAdmin.actions] calendarEventAdminDeleteSuccess(${id})`);
  return {
    type: CALENDAR_EVENT_ADMIN_DELETE_SUCCESS,
    id: id,
  }
}

export function calendarEventAdminDeleteFailure(error) {
  Logger.log('debug', `[calendarEventsAdmin.actions] calendarEventAdminDeleteFailure(%j)`, error);
  return {
    type: CALENDAR_EVENT_ADMIN_DELETE_FAILURE,
    error: error
  }
}

export function calendarEventAdminFormDestroy(formState=null) {
  Logger.log('debug', `[calendarEventsAdmin.actions] calendarEventAdminFormDestroy(%j)`, formState);
  return {
    type: CALENDAR_EVENT_ADMIN_FORM_DESTROY,
    form: formState
  }
}

export function calendarEventAdminShowEventDetail(eventId=null) {
  Logger.log('debug', `[state.calendarEventsAdmin.actions] calendarEventAdminShowEventDetail()`);
  return {
    type: CALENDAR_EVENT_ADMIN_SHOW_EVENT_DETAIL,
    eventId: eventId,
  }
}

export function calendarEventAdminHideEventDetail() {
  Logger.log('debug', `[state.calendarEventsAdmin.actions] calendarEventAdminHideEventDetail()`);
  return {
    type: CALENDAR_EVENT_ADMIN_HIDE_EVENT_DETAIL,
  }
}

export function calendarEventListParticipantsRequest(calendarEventId, page, limit) {
  Logger.log('debug', `[state.calendar.actions] calendarEventListParticipantsRequest(${calendarEventId}, ${page}, ${limit}`);
  return {
    type: CALENDAR_EVENT_ADMIN_LIST_PARTICIPANTS_REQUEST,
    calendarEventId: calendarEventId,
    page: page,
    limit: limit,
  }
}

export function calendarEventListParticipantsSuccess(calendarEventId, data) {
  Logger.log('debug', `[state.calendar.actions] calendarEventListParticipantsSuccess(${calendarEventId}, %j)`, data);
  return {
    type: CALENDAR_EVENT_ADMIN_LIST_PARTICIPANTS_SUCCESS,
    calendarEventId: calendarEventId,
    page: data.page,
    limit: data.limit,
    result: data.result,
    total: data.total,
    receivedAt: Date.now()
  }
}

export function calendarEventListParticipantsFailure(calendarEventId, error) {
  Logger.log('debug', `[state.calendar.actions] calendarEventListParticipantsFailure(${calendarEventId}, %j)`, error);
  return {
    type: CALENDAR_EVENT_ADMIN_LIST_PARTICIPANTS_FAILURE,
    calendarEventId: calendarEventId,
    error: error
  }
}

export function calendarEventParticipantCreateRequest(eventId, data) {
  Logger.log('debug', `[state.calendarEventsAdminactions] calendarEventParticipantCreateRequest(${eventId}, %j)`, data);
  return {
    type: CALENDAR_EVENT_ADMIN_CREATE_PARTICIPANT_REQUEST,
    eventId: eventId,
  }
}

export function calendarEventParticipantCreateSuccess(eventId, data) {
  Logger.log('debug', `[state.calendarEventsAdminactions] calendarEventParticipantCreateSuccess(${eventId}, %j)`, data);
  return {
    type: CALENDAR_EVENT_ADMIN_CREATE_PARTICIPANT_SUCCESS,
    eventId: eventId,
    id: data.id,
    receivedAt: Date.now()
  }
}

export function calendarEventParticipantCreateFailure(eventId, error) {
  Logger.log('debug', `[state.calendarEventsAdminactions] calendarEventParticipantCreateFailure(${eventId}, %j)`, error);
  return {
    type: CALENDAR_EVENT_ADMIN_CREATE_PARTICIPANT_FAILURE,
    eventId: eventId,
    error: error
  }
}


export function calendarEventParticipantUpdateRequest(id, data) {
  Logger.log('debug', `[state.calendarEventsAdmin.actions] calendarEventParticipantUpdateRequest(${id}, %j)`, data);
  return {
    type: CALENDAR_EVENT_ADMIN_UPDATE_PARTICIPANT_REQUEST,
    id: id,
  }
}

export function calendarEventParticipantUpdateSuccess(data) {
  Logger.log('debug', `[state.calendarEventsAdmin.actions] calendarEventParticipantUpdateSuccess(%j)`, data);
  return {
    type: CALENDAR_EVENT_ADMIN_UPDATE_PARTICIPANT_SUCCESS,
    id: data.id,
    receivedAt: Date.now()
  }
}

export function calendarEventParticipantUpdateFailure(error) {
  Logger.log('debug', `[state.calendarEventsAdmin.actions] calendarEventParticipantUpdateFailure(%j)`, error);
  return {
    type: CALENDAR_EVENT_ADMIN_UPDATE_PARTICIPANT_FAILURE,
    error: error
  }
}

export function calendarEventAdminDeleteParticipantRequest(id) {
  Logger.log('debug', `[calendarEventsAdmin.actions] calendarEventAdminDeleteParticipantRequest(${id})`);
  return {
    type: CALENDAR_EVENT_ADMIN_DELETE_PARTICIPANT_REQUEST,
    id: id
  }
}

export function calendarEventAdminDeleteParticipantSuccess(id) {
  Logger.log('debug', `[calendarEventsAdmin.actions] calendarEventAdminDeleteParticipantSuccess(${id})`);
  return {
    type: CALENDAR_EVENT_ADMIN_DELETE_PARTICIPANT_SUCCESS,
    id: id,
  }
}

export function calendarEventAdminDeleteParticipantFailure(error) {
  Logger.log('debug', `[calendarEventsAdmin.actions] calendarEventAdminDeleteParticipantFailure(%j)`, error);
  return {
    type: CALENDAR_EVENT_ADMIN_DELETE_PARTICIPANT_FAILURE,
    error: error
  }
}

export function  calendarEventAdminShowEventParticipantFormDestroy(formState=null) {
  Logger.log('debug', `[state.calendar.actions] calendarEventAdminShowEventParticipantFormDestroy(%j)`, formState);
  return {
    type: CALENDAR_EVENT_ADMIN_EVENT_PARTICIPANT_FORM_DESTROY,
    form: formState
  }
}

export function calendarEventAdminShowEventParticipantForm(participantId=null) {
  Logger.log('debug', `[state.calendarEventsAdmin.actions] calendarEventAdminShowEventParticipantForm()`);
  return {
    type: CALENDAR_EVENT_ADMIN_SHOW_EVENT_PARTICIPANT_FORM,
    participantId: participantId,
  }
}

export function calendarEventAdminHideEventParticipantForm() {
  Logger.log('debug', `[state.calendarEventsAdmin.actions] calendarEventAdminHideEventForm()`);
  return {
    type: CALENDAR_EVENT_ADMIN_HIDE_EVENT_PARTICIPANT_FORM,
  }
}


export function calendarEventParticipantSetPage(page) {
  Logger.log('info', `[state.calendar.actions] calendarEventParticipantSetPage(${page})`);

  return {
    type: CALENDAR_EVENT_PARTICIPANT_SET_PAGE,
    page: page,
  }
}


// API THUNK ACTION CREATORS

export function loadCalendarEventsAdmin(parntership_id = null, page=1, limit=10, order=null, filter=null, cb=function(){}) {
  Logger.log('debug', `[calendarEventsAdmin.actions] loadCalendarEventsAdmin(${page}, ${limit}, ${order}, %j, ###)`, filter);

  return async function(dispatch) {
    dispatch(calendarEventAdminListRequest(page, limit, order, filter));

    // call API
    const response = await api.getCalendarEventsAdmin(parntership_id, page, limit, order, filter);
    let success = false;

    // get calendar events admin list success
    if (200 === response.get('status')) {

      Logger.log('info', `Get API calendar events list success. Page: ${page}, Limit: ${limit}, Order: ${order}.`);

      const normalizedEntities = normalize(response.getIn(['data', 'calendar_events']), [schema.calendarEventAdmin]);
      const data = {
        page: response.getIn(['data', 'page']),
        limit: response.getIn(['data', 'limit']),
        order: order,
        total: response.getIn(['data', 'total']),
        result: normalizedEntities.result
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(calendarEventAdminListSuccess(data));
      success = true;

    } else if (1 === page && 204 === response.get('status')) {

      Logger.log('info', `Get API calendar events admin success [empty]. Page: ${page}, Limit: ${limit}, Order: ${order}.`);
      const data = {
        page: page,
        limit: limit,
        order: order,
        total: 0,
        result: []
      };
      dispatch(calendarEventAdminListSuccess(data));
      success = true;
      
    // get calendar Events Admin list failure
    } else {
      Logger.log('info', `Get API calendar events list failure. Page: ${page}, Limit: ${limit}, Order: ${order}.`);
      dispatch(calendarEventAdminListFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  }
}

export function loadCalendarEventAdmin(partnership_id, id, cb=function(){}) {
  Logger.log('debug', `[calendarEventsAdmin.actions] loadCalendarEvent(${id}, ###)`);

  return async function(dispatch) {
    dispatch(calendarEventAdminReadRequest(id));

    // call API
    const response = await api.getCalendarEventAdmin(partnership_id, id);
    let success = false;

    // get calendar event success
    if (200 === response.get('status')) {

      Logger.log('info', `Get API calendar event success. ID: ${id}.`);

      const normalizedEntities = normalize([response.getIn(['data', 'calendar_event'])], [schema.calendarEventAdmin]);
      const data = {
        id: response.getIn(['data', 'calendar_event', 'id']),
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(calendarEventAdminReadSuccess(data));
      success = true;
      
    // get calendar event failure
    } else {
      Logger.log('info', `Get API calendar event failure. ID: ${id}.`);
      dispatch(calendarEventAdminReadFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  }
}

export function updateCalendarEventAdmin(id, data, cb=function(){}) {
  Logger.log('debug', `[calendarEventsAdmin.actions] updateCalendarEvent(${id}, %j, ###)`, data);

  return async function(dispatch) {
    dispatch(calendarEventAdminUpdateRequest(id, data));

    // call API
    const response = await api.putCalendarEventAdmin(id, data);
    let success = false;

    // put calendar event success
    if (200 === response.get('status')) {

      Logger.log('info', `PUT API calendar event success. User: ${id}.`);

      const normalizedEntities = normalize([response.getIn(['data', 'calendar_event'])], [schema.calendarEventAdmin]);
      const data = {
        id: response.getIn(['data', 'calendar_event', 'id']),
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(calendarEventAdminUpdateSuccess(data));
      success = true;
      
    // get calendar event failure
    } else {
      Logger.log('info', `PUT API calendar event failure. ID: ${id}.`);
      dispatch(calendarEventAdminUpdateFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  }
}

export function createCalendarEventAdmin(data, cb=function(){}) {
  Logger.log('debug', `[calendarEventsAdmin.actions] createCalendarEvent(%j, ###)`, data);

  return async function(dispatch) {
    dispatch(calendarEventAdminCreateRequest(data));

    // call API
    const response = await api.postCalendarEventsAdmin(data);
    let success = false;

    // post calendar events success
    if (201 === response.get('status')) {

      Logger.log('info', `POST API calendar events success. Post: ${response.getIn(['data', 'calendar_event', 'id'])}.`);

      const normalizedEntities = normalize([response.getIn(['data', 'calendar_event'])], [schema.calendarEventAdmin]);
      const data = {
        id: response.getIn(['data', 'calendar_event', 'id']),
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(calendarEventAdminCreateSuccess(data));
      success = true;
      
    // get calendar events failure
    } else {
      Logger.log('info', `POST API calendar events failure.`);
      dispatch(calendarEventAdminCreateFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  }
}

export function deleteCalendarEventAdmin(partnershipId, id, cb=function(){}) {
  Logger.log('debug', `[calendarEventsAdmin.actions] deleteCalendarEvent(${partnershipId}, ${id}, ###)`);

  return async function(dispatch) {
    dispatch(calendarEventAdminDeleteRequest(id));

    // call API
    const response = await api.deleteCalendarEventAdmin(partnershipId, id);
    let success = false;

    // delete calendar event success
    if (204 === response.get('status')) {

      Logger.log('info', `DELETE API calendar event success. ID: ${id}.`);

      dispatch(removeEntity({entityType: 'calendarEventsAdmin', id: id}));
      dispatch(calendarEventAdminDeleteSuccess(id));
      success = true;
      
    // get calendar event failure
    } else {
      Logger.log('info', `DELETE API calendar event failure. ID: ${id}.`);
      dispatch(calendarEventAdminDeleteFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  }
}

export function loadCalendarEventParticipants(calendarEventId, page=1, limit=10, cb=function(){}) {
  Logger.log('debug', `[state.calendarEventsAdmin.actions] loadCalendarEventParticipants(${calendarEventId}, ${page}, ${limit}, ###)`,);

  return async function(dispatch) {
    dispatch(calendarEventListParticipantsRequest(calendarEventId, page, limit));

    // call API
    const response = await api.getCalendarEventParticipants(calendarEventId, page, limit);
    let success = false;
    let total = 0;

    // get calendar participant events list success
    if (200 === response.get('status')) {

      Logger.log('info', `Get API calendar participant events list success. Care Guide ID: ${calendarEventId}, Page: ${page}, Limit: ${limit}.`);

      const normalizedEntities = normalize(response.getIn(['data', 'calendar_event_participants']), [schema.calendarEventParticipant]);

      const data = {
        page: response.getIn(['data', 'page']),
        limit: response.getIn(['data', 'limit']),
        total: response.getIn(['data', 'total']),
        result: normalizedEntities.result
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(calendarEventListParticipantsSuccess(calendarEventId, data));

      success = true;
      total = response.getIn(['data', 'total']);

    } else if (1 === page && 204 === response.get('status')) {

      Logger.log('info', `Get API calendar participant events list success [empty]. Care Guide ID: ${calendarEventId}, Page: ${page}, Limit: ${limit}.`);
      const data = {
        page: page,
        limit: limit,
        total: 0,
        result: []
      };
      dispatch(calendarEventListParticipantsSuccess(calendarEventId, data));
      success = true;
      
    // get calendar participant events list failure
    } else {
      Logger.log('info', `Get API calendar participant events list failure. Care Guide ID: ${calendarEventId}, Page: ${page}, Limit: ${limit}.`);
      dispatch(calendarEventListParticipantsFailure(calendarEventId, response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success, total);
  }
}

export function createCalendarEventParticipant(eventId, data, cb=function(){}) {
  Logger.log('debug', `[state.calendarEventsAdmin.actions] createCalendarEventParticipant(${eventId}, %j, ###)`, data);

  return async function(dispatch) {
    dispatch(calendarEventParticipantCreateRequest(eventId, data));

    // call API
    const response = await api.postCalendarEventParticipant(eventId, data);
    let success = false;

    // post calendar event participant success
    if (201 === response.get('status')) {

      Logger.log('info', `POST API calendar event participant success. Care Guide ID: ${eventId}.`);

      const normalizedEntities = normalize([response.getIn(['data', 'calendar_event_participants'])], [schema.calendarEventParticipant]);
      const data = {
        id: response.getIn(['data', 'calendar_event_participants', 'id']),
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(calendarEventParticipantCreateSuccess(eventId, data));
      success = true;
      
    // post calendar event participant failure
    } else {
      Logger.log('info', `POST API calendar event participant failure. Care Guide ID: ${eventId}.`);
      dispatch(calendarEventParticipantCreateFailure(eventId, response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  }
}


export function updateCalendarEventParticipant(id, data, cb=function(){}) {
  Logger.log('debug', `[state.calendarEventsAdmin.actions] updateCalendarEventParticipant(${id}, %j, ###)`, data);

  return async function(dispatch) {
    dispatch(calendarEventParticipantUpdateRequest(id, data));

    // call API
    const response = await api.putCalendarEventAdminParticipant(id, data);
    let success = false;

    // PUT calendar event participant success
    if (200 === response.get('status')) {

      Logger.log('info', `PUT API calendar event participant success. Participant: ${id}.`);

      const normalizedEntities = normalize([response.getIn(['data', 'calendar_event_participant'])], [schema.calendarEventParticipant]);
      const data = {
        id: response.getIn(['data', 'calendar_event_participant', 'id']),
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(calendarEventParticipantUpdateSuccess(id, data));
      success = true;
      
    // PUT calendar event participant failure
    } else {
      Logger.log('info', `PUT API calendar event failure. ID: ${id}.`);
      dispatch(calendarEventParticipantUpdateFailure( response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  }
}

export function deleteCalendarEventAdminParticipant(id, cb=function(){}) {
  Logger.log('debug', `[calendarEventsAdmin.actions] deleteCalendarEventAdminParticipant(${id})`);

  return async function(dispatch) {
    dispatch(calendarEventAdminDeleteParticipantRequest(id));

    // call API
    const response = await api.deleteCalendarEventAdminParticipant(id);
    let success = false;

    // delete calendar event participant success
    if (204 === response.get('status')) {

      Logger.log('info', `DELETE API calendar event participant success. ID: ${id}.`);

      dispatch(calendarEventAdminDeleteParticipantSuccess(id));
      success = true;
      
    // get calendar event failure
    } else {
      Logger.log('info', `DELETE API calendar event participant failure. ID: ${id}.`);
      dispatch(calendarEventAdminDeleteParticipantFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  }
}


Logger.log('silly', `calendarEventsAdmin.actions loaded.`);
