import {List, Map} from 'immutable';

import {TYPES} from './actions';
import {SESSION_DESTROY} from '../../actions';
import Logger from '../../../lib/Logger';

const initialState = Map({
  isLoading: false,
  journalPosts: {},
  calendarEvents: {},
  messages: {},
  resources: {},
  careGuideHelpers: {},
  careGuides: {},
});

export default function search(
  state=initialState,
  action
) {
  Logger.log('debug', `[state.search.reducers] search(%j, %j)`, state, action);

  switch(action.type) {

    case TYPES.LIST_REQUEST:
      return state.mergeDeep({
        isLoading: true
      });

    case TYPES.LIST_SUCCESS:
      return state.mergeDeep({
        isLoading: false,
        journalPosts: {
          [action.careGuideId]: {
            total: action.journalPostsTotal,
            pages: {
              [action.limit]: {
                [action.page]: null
              }
            }
          }
        },
        calendarEvents: {
          [action.careGuideId]: {
            total: action.calendarEventsTotal,
            pages: {
              [action.limit]: {
                [action.page]: null
              }
            }
          }
        },
        messages: {
          [action.careGuideId]: {
            total: action.messagesTotal,
            pages: {
              [action.limit]: {
                [action.page]: null
              }
            }
          }
        },
        resources: {
          [action.careGuideId]: {
            total: action.resourcesTotal,
            pages: {
              [action.limit]: {
                [action.page]: null
              }
            }
          }
        },
        careGuideHelpers: {
          [action.careGuideId]: {
            total: action.careGuideHelpersTotal,
            pages: {
              [action.limit]: {
                [action.page]: null
              }
            }
          }
        },
        careGuides: {
          [action.careGuideId]: {
            total: action.careGuidesTotal,
            pages: {
              [action.limit]: {
                [action.page]: null
              }
            }
          }
        },
        lastUpdated: action.receivedAt
      }).setIn(['journalPosts', action.careGuideId, 'pages', action.limit, action.page], List(action.journalPosts))
        .setIn(['calendarEvents', action.careGuideId, 'pages', action.limit, action.page], List(action.calendarEvents))
        .setIn(['messages', action.careGuideId, 'pages', action.limit, action.page], List(action.messages))
        .setIn(['resources', action.careGuideId, 'pages', action.limit, action.page], List(action.resources))
        .setIn(['careGuideHelpers', action.careGuideId, 'pages', action.limit, action.page], List(action.careGuideHelpers))
        .setIn(['careGuides', action.careGuideId, 'pages', action.limit, action.page], List(action.careGuides));

    case TYPES.LIST_FAILURE:
      return state.mergeDeep({
        isLoading: false
      });

    case SESSION_DESTROY:
      return initialState;

    default:
      return state;
  }
}

Logger.log('silly', `state.search.reducers loaded.`);
