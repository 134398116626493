import { Map, List } from "immutable";

import {
  CARE_GUIDES_MEMBERS_PARTNERSHIP_LIST_REQUEST,
  CARE_GUIDES_MEMBERS_PARTNERSHIP_LIST_SUCCESS,
  CARE_GUIDES_MEMBERS_PARTNERSHIP_LIST_FAILURE,
} from "./actions";
import Logger from "../../../lib/Logger";

export default function careGuidesMembersPartnership(
  state = Map({
    isLoading: false,
    areCareGuidesMembersLoading: false,
  }),
  action
) {
  Logger.log(
    "debug",
    `[careGuidesMembersPartnership.reducers] careGuidesMembersPartnership(%j, %j)`,
    state,
    action
  );

  switch (action.type) {
    case CARE_GUIDES_MEMBERS_PARTNERSHIP_LIST_REQUEST:
      return state.mergeDeep({
        areCareGuidesMembersLoading: true,
      });

    case CARE_GUIDES_MEMBERS_PARTNERSHIP_LIST_SUCCESS:
      return state
        .mergeDeep({
          areCareGuidesMembersLoading: false,
          lastUpdated: action.receivedAt,
          total: action.total,
        }).setIn(['care_guides_members'], List(action.result));

    case CARE_GUIDES_MEMBERS_PARTNERSHIP_LIST_FAILURE:
      return state.mergeDeep({
        areCareGuidesMembersLoading: false,
      });

    default:
      return state;
  }
}

Logger.log("silly", `careGuidesMembersPartnership.reducers loaded.`);
