import React, {useState, useEffect, useRef} from 'react';
import {Translation, getI18n} from 'react-i18next';
import {Button, Checkbox, ConfigProvider, Form, Input, Space, Table} from 'antd';
import {MinusOutlined, PlusOutlined} from '@ant-design/icons';

import TableColumnTypeInput from './TableColumnTypeInput';

const getContentInputField = (contentName, type, row, i, layout) => {
  return [
    // text
    <Form.Item
      {...row}
      name={[contentName, 'rows', row.name, 'content', i]}
      fieldKey={[contentName, 'rows', row.fieldKey, 'content', i]}
      {...layout.main}
    >
      <Input placeholder="Content..." />
    </Form.Item>,

    // textarea
    <Form.Item
      {...row}
      name={[contentName, 'rows', row.name, 'content', i]}
      fieldKey={[contentName, 'rows', row.fieldKey, 'content', i]}
      {...layout.main}
    >
      <Input.TextArea rows={3} placeholder="Content..." />
    </Form.Item>,

    // check mark
    <Form.Item
      {...row}
      name={[contentName, 'rows', row.name, 'content', i]}
      fieldKey={[contentName, 'rows', row.fieldKey, 'content', i]}
      valuePropName="checked"
      {...layout.main}
    >
      <Checkbox />
    </Form.Item>,

    // text w/check mark
    <Form.Item
      {...row}
      name={[contentName, 'rows', row.name, 'content', i]}
      fieldKey={[contentName, 'rows', row.fieldKey, 'content', i]}
      {...layout.main}
    >
      <Input placeholder="Content..." />
    </Form.Item>,

    // cross mark
    <Form.Item
      {...row}
      name={[contentName, 'rows', row.name, 'content', i]}
      fieldKey={[contentName, 'rows', row.fieldKey, 'content', i]}
      valuePropName="checked"
      {...layout.main}
    >
      <Checkbox />
    </Form.Item>,

    // text w/cross
    <Form.Item
      {...row}
      name={[contentName, 'rows', row.name, 'content', i]}
      fieldKey={[contentName, 'rows', row.fieldKey, 'content', i]}
      {...layout.main}
    >
      <Input placeholder="Content..." />
    </Form.Item>,

    // phone number
    <Form.Item
      {...row}
      name={[contentName, 'rows', row.name, 'content', i]}
      fieldKey={[contentName, 'rows', row.fieldKey, 'content', i]}
      {...layout.main}
    >
      <Input placeholder="Phone Number..." />
    </Form.Item>,

    // URL
    <Form.Item
      {...row}
      name={[contentName, 'rows', row.name, 'content', i]}
      fieldKey={[contentName, 'rows', row.fieldKey, 'content', i]}
      {...layout.main}
    >
      <Input placeholder="URL..." />
    </Form.Item>,

  ][type];
}

const FieldTypeTableEditor = ({form, content, contentsPath, ...props}) => {

  const columns = form.getFieldValue([...contentsPath, `columns`]) || [undefined];
  const rows = form.getFieldValue([...contentsPath, `rows`]);
  const columnTypes = columns ? columns.filter(x => x).map(x => x.type).join() : null;

  const rowsRef = useRef(null);
  // const addColRef = useRef(null);
  const removeColRef = useRef(null);
  const removeRowRef = useRef(null);
  const moveRowRef = useRef(null);

  // input column settings
  const layout = {
    main: {
      wrapperCol: {span: 24},
    },
  }

  const createColumn = (name, fieldKey, dataKey) => {
    return {
      title:
        <>
          <Space>
            <Space direction="vertical" size={0}>
              <Form.Item
                name={[content.name, 'columns', dataKey, 'label']}
                fieldKey={[content.name, 'columns', fieldKey, 'label']}
                rules={[
                  {required: true, message: getI18n().t('feedback_validation_required')},
                ]}
                className="table-column-label-input"
                {...layout.main}
              >
                <Input placeholder="Header" />
              </Form.Item>
              <TableColumnTypeInput
                name={[content.name, 'columns', dataKey, 'type']}
                fieldKey={[content.name, 'columns', fieldKey, 'type']}
                className="table-column-type-input"
                {...layout.main}
              />
            </Space>
            <Button
              danger
              shape="circle"
              size="small"
              type="dashed"
              icon={<MinusOutlined />}
              onClick={() => removeColumn({name: name, fieldKey: fieldKey})}
            />
          </Space>
        </>,
      dataIndex: dataKey,
      key: dataKey
    }
  }

  const removeColumn = (column) => {

    // remove column's input fields from rows
    var rowsCopy = [];
    for (const row of rowsRef.current) {
      let newRow = {};
      for (const prop in row) {
        const propInt = parseInt(prop);
        if (Number.isInteger(propInt)) {
          if (propInt < column.fieldKey) {
            newRow[prop] = row[prop];
          } else {
            const namePath = row[prop].props.name;
            const namePath2 = row[prop].props.name.slice();
            namePath2.splice(-1, 1, propInt + 1);
            form.setFields([{name: ["contents", ...namePath], value: form.getFieldValue(["contents", ...namePath2])}]);
            const newPropInt = propInt - 1;
            newRow[(newPropInt).toString()] = row[prop];
          }
        } else {
          newRow[prop] = row[prop];
        }
      }
      rowsCopy.push(newRow);
    }
    setDataSource(rowsCopy);

    // remove column
    if (removeColRef.current) {
      removeColRef.current(column.name);
    }
  }

  const addColumn = (columns, add) => {

    console.log("addColumn");

    // copy existing columns
    var columnsCopy = columns.map((column, i) => {
      return createColumn(content.name, {name: i, fieldKey: i}, i);
    });

    // add new column
    let newLength = columns.length;
    columnsCopy.push(createColumn(content.name, {name: newLength, fieldKey: newLength}, newLength));
    columnsCopy.push({
      title:
        <>
          {/* <Button
            type="dashed"
            onClick={addColRef.current}
          >
            <PlusOutlined />
          </Button> */}
        </>,
      dataIndex: 'delete',
      key: 'delete'
    });

    add();
  }

  const createRow = (contentName, row, place) => {
    var rowData = {
      key: place
    };
    const columnTypeArray = columnTypes.split(',');
    for (let y = 0; y < columnTypeArray.length; y++) {
      rowData[y] = getContentInputField(contentName, parseInt(columnTypeArray.filter((type, it) => it === y) - 1), row, y, layout);
    }
    rowData['delete'] = 
      <Button
        danger
        shape="circle"
        size="small"
        type="dashed"
        className="del-row-btn"
        icon={<MinusOutlined />}
        onClick={() => removeRow(row)}
      />;
    return rowData;
  }

  const removeRow = (row) => {

    // copy existing rows
    var rowsCopy = rowsRef.current.filter(x => {
      return x.key !== row.fieldKey
    }).map((row, i) => {
      return createRow(content.name, {name: i, fieldKey: i}, i);
    });

    setDataSource(rowsCopy);
    if (removeRowRef.current) {
      removeRowRef.current(row.name);
    }
  }

  const addRow = (rows, add) => {

    // copy existing rows
    var rowsCopy = rows.map((row, i) => {
      return createRow(content.name, {name: i, fieldKey: i}, i);
    });

    // add new row
    rowsCopy.push(createRow(content.name, {name: rows.length, fieldKey: rows.length}, rows.length));

    add();
    setDataSource(rowsCopy);
  }

  useEffect(() => {
    var rowsCopy = rowsRef.current
      ? rowsRef.current.map((row, i) => {
        return createRow(content.name, {name: i, fieldKey: i}, i);
      })
      : [];
    if (rowsCopy.length > 0) {
      setDataSource(rowsCopy);
    }

  // eslint-disable-next-line
  }, [columnTypes]);

  const columnsSource = columns
    ? columns.map((column, i) => {
        return createColumn(i, i, i);
      }).concat([{
        title: 
        <>
          {/* <Button
            className="add-col-btn"
            type="dashed"
            onClick={addColRef.current}
          >
            <PlusOutlined />
          </Button> */}
        </>,
        dataIndex: 'delete',
        key: 'delete'
      }])
    : [];

  const [dataSource, setDataSource] = useState(rows
    ? rows.map((row, i) => {
        return createRow(content.name, {name: i, fieldKey: i}, i);
      })
    : []);

  useEffect(() => {
    rowsRef.current = dataSource;
  }, [dataSource]);

  return (
    <Translation>{(t) => 
      <>

        <div className="table-editor">

          <ConfigProvider renderEmpty={() => null}>
            <Table
              size="small"
              columns={columnsSource}
              dataSource={dataSource}
              pagination={false}
            />
          </ConfigProvider>

          <Space>
            <div className="field-type-table-column-list">
              <Form.List name={[content.name, 'columns']}>

                {(columns, {add, remove, move}) => {
                  if (!removeColRef.current) {
                    removeColRef.current = remove;
                  }
                  // addColRef.current = () => addColumn(columns, add);
                  // return null;
                  return(
                    <>
                      <Form.Item>
                        <Button
                          type="dashed"
                          onClick={() => {
                            addColumn(columns, add);
                          }}
                          block
                        >
                          <PlusOutlined /> Add column
                        </Button>
                      </Form.Item>
                    </>
                  );
                }}

              </Form.List>
            </div>

            <div className="field-type-table-row-list">
              <Form.List name={[content.name, 'rows']}>

                {(rows, {add, remove, move}) => {
                  if (!removeRowRef.current) {
                    removeRowRef.current = remove;
                  }
                  if (!moveRowRef.current) {
                    moveRowRef.current = move;
                  }
                  return(
                    <>
                      <Form.Item>
                        <Button
                          type="dashed"
                          onClick={() => {
                            addRow(rows, add);
                          }}
                          block
                        >
                          <PlusOutlined /> Add row
                        </Button>
                      </Form.Item>
                    </>
                  );
                }}

              </Form.List>
            </div>
          </Space>

        </div>

      </>
    }</Translation>
  )
}

export default FieldTypeTableEditor;
