import {normalize} from 'normalizr';

import api from '../../api';
import {schema} from '../../schema';
// import {addEntities, removeEntity} from '../../actions';
import {addEntities} from '../../actions';
import Logger from '../../../lib/Logger';

export const TYPES = {
  LIST_REQUEST: 'JOURNAL_POSTS/LIST_REQUEST',
  LIST_SUCCESS: 'JOURNAL_POSTS/LIST_SUCCESS',
  LIST_FAILURE: 'JOURNAL_POSTS/LIST_FAILURE',
  READ_REQUEST: 'JOURNAL_POSTS/READ_REQUEST',
  READ_SUCCESS: 'JOURNAL_POSTS/READ_SUCCESS',
  READ_FAILURE: 'JOURNAL_POSTS/READ_FAILURE',
  CREATE_REQUEST: 'JOURNAL_POSTS/CREATE_REQUEST',
  CREATE_SUCCESS: 'JOURNAL_POSTS/CREATE_SUCCESS',
  CREATE_FAILURE: 'JOURNAL_POSTS/CREATE_FAILURE',
  PATCH_REQUEST: 'JOURNAL_POSTS/PATCH_REQUEST',
  PATCH_SUCCESS: 'JOURNAL_POSTS/PATCH_SUCCESS',
  PATCH_FAILURE: 'JOURNAL_POSTS/PATCH_FAILURE',
  // DELETE_REQUEST: 'JOURNAL_POSTS/DELETE_REQUEST',
  // DELETE_SUCCESS: 'JOURNAL_POSTS/DELETE_SUCCESS',
  // DELETE_FAILURE: 'JOURNAL_POSTS/DELETE_FAILURE',
  PDF_REQUEST: 'JOURNAL_POSTS/PDF_REQUEST',
  PDF_SUCCESS: 'JOURNAL_POSTS/PDF_SUCCESS',
  PDF_FAILURE: 'JOURNAL_POSTS/PDF_FAILURE',
  FORM_DESTROY: 'JOURNAL_POSTS/FORM_DESTROY',
  SHOW_FORM: 'JOURNAL_POSTS/SHOW_FORM',
  HIDE_FORM: 'JOURNAL_POSTS/HIDE_FORM',
};

export function journalPostListRequest(careGuideId, page, limit, order, filter) {
  Logger.log('debug', `[state.journalPosts.actions] journalPostListRequest(${careGuideId}, ${page}, ${limit}, ${order}, %j)`, filter);
  return {
    type: TYPES.LIST_REQUEST,
    careGuideId: careGuideId,
    page: page,
    limit: limit,
    order: order,
    filter: filter,
  }
}

export function journalPostListSuccess(careGuideId, data) {
  Logger.log('debug', `[state.journalPosts.actions] journalPostListSuccess(${careGuideId}, %j)`, data);
  return {
    type: TYPES.LIST_SUCCESS,
    careGuideId: careGuideId,
    page: data.page,
    limit: data.limit,
    order: data.order,
    result: data.result,
    total: data.total,
    receivedAt: Date.now()
  }
}

export function journalPostListFailure(careGuideId, error) {
  Logger.log('debug', `[state.journalPosts.actions] journalPostListFailure(${careGuideId}, %j)`, error);
  return {
    type: TYPES.LIST_FAILURE,
    careGuideId: careGuideId,
    error: error
  }
}

export function journalPostReadRequest(careGuideId, id) {
  Logger.log('debug', `[state.journalPosts.actions] journalPostReadRequest(${careGuideId}, ${id})`);
  return {
    type: TYPES.READ_REQUEST,
    careGuideId: careGuideId,
    id: id
  }
}

export function journalPostReadSuccess(careGuideId, id, data) {
  Logger.log('debug', `[state.journalPosts.actions] journalPostReadSuccess(${careGuideId}, ${id}, %j)`, data);
  return {
    type: TYPES.READ_SUCCESS,
    careGuideId: careGuideId,
    id: data.id,
    receivedAt: Date.now()
  }
}

export function journalPostReadFailure(careGuideId, id, error) {
  Logger.log('debug', `[state.journalPosts.actions] journalPostReadFailure(${careGuideId}, ${id}, %j)`, error);
  return {
    type: TYPES.READ_FAILURE,
    careGuideId: careGuideId,
    id: id,
    error: error
  }
}

export function journalPostCreateRequest(careGuideId, data) {
  Logger.log('debug', `[state.journalPosts.actions] journalPostCreateRequest(${careGuideId}, %j)`, data);
  return {
    type: TYPES.CREATE_REQUEST,
    careGuideId: careGuideId,
  }
}

export function journalPostCreateSuccess(careGuideId, data) {
  Logger.log('debug', `[state.journalPosts.actions] journalPostCreateSuccess(${careGuideId}, %j)`, data);
  return {
    type: TYPES.CREATE_SUCCESS,
    careGuideId: careGuideId,
    id: data.id,
    receivedAt: Date.now()
  }
}

export function journalPostCreateFailure(careGuideId, error) {
  Logger.log('debug', `[state.journalPosts.actions] journalPostCreateFailure(${careGuideId}, %j)`, error);
  return {
    type: TYPES.CREATE_FAILURE,
    careGuideId: careGuideId,
    error: error
  }
}

export function journalPostPatchRequest(careGuideId, id, data) {
  Logger.log('debug', `[state.journalPosts.actions] journalPostPatchRequest(${careGuideId}, ${id}, %j)`, data);
  return {
    type: TYPES.PATCH_REQUEST,
    careGuideId: careGuideId,
    id: id,
  }
}

export function journalPostPatchSuccess(careGuideId, id, data) {
  Logger.log('debug', `[state.journalPosts.actions] journalPostPatchSuccess(${careGuideId}, ${id}, %j)`, data);
  return {
    type: TYPES.PATCH_SUCCESS,
    careGuideId: careGuideId,
    id: id,
    receivedAt: Date.now()
  }
}

export function journalPostPatchFailure(careGuideId, id, error) {
  Logger.log('debug', `[state.journalPosts.actions] journalPostPatchFailure(${careGuideId}, ${id}, %j)`, error);
  return {
    type: TYPES.PATCH_FAILURE,
    careGuideId: careGuideId,
    id: id,
    error: error
  }
}

// export function journalPostDeleteRequest(careGuideId, id) {
//   Logger.log('debug', `[state.journalPosts.actions] journalPostDeleteRequest(${careGuideId}, ${id})`);
//   return {
//     type: TYPES.DELETE_REQUEST,
//     careGuideId: careGuideId,
//     id: id
//   }
// }

// export function journalPostDeleteSuccess(careGuideId, id) {
//   Logger.log('debug', `[state.journalPosts.actions] journalPostDeleteSuccess(${careGuideId}, ${id})`);
//   return {
//     type: TYPES.DELETE_SUCCESS,
//     careGuideId: careGuideId,
//     id: id,
//   }
// }

// export function journalPostDeleteFailure(careGuideId, error) {
//   Logger.log('debug', `[state.journalPosts.actions] journalPostDeleteFailure(${careGuideId}, %j)`, error);
//   return {
//     type: TYPES.DELETE_FAILURE,
//     careGuideId: careGuideId,
//     error: error
//   }
// }

export function journalPostPDFRequest(careGuideId, page, limit, order, filter) {
  Logger.log('debug', `[state.journalPosts.actions] journalPostPDFRequest(${careGuideId}, ${page}, ${limit}, ${order}, %j)`, filter);
  return {
    type: TYPES.PDF_REQUEST,
    careGuideId: careGuideId,
    page: page,
    limit: limit,
    order: order,
    filter: filter,
  }
}

export function journalPostPDFSuccess(careGuideId) {
  Logger.log('debug', `[state.journalPosts.actions] journalPostPDFSuccess(${careGuideId})`);
  return {
    type: TYPES.PDF_SUCCESS,
    careGuideId: careGuideId,
    receivedAt: Date.now()
  }
}

export function journalPostPDFFailure(careGuideId, error) {
  Logger.log('debug', `[state.journalPosts.actions] journalPostPDFFailure(${careGuideId}, %j)`, error);
  return {
    type: TYPES.PDF_FAILURE,
    careGuideId: careGuideId,
    error: error
  }
}

export function journalPostFormDestroy(formState=null) {
  Logger.log('debug', `[state.journalPosts.actions] journalPostFormDestroy(%j)`, formState);
  return {
    type: TYPES.FORM_DESTROY,
    form: formState
  }
}

export function journalPostShowForm(id=null) {
  Logger.log('debug', `[state.journalPosts.actions] journalPostShowForm()`);
  return {
    type: TYPES.SHOW_FORM,
    id: id,
  }
}

export function journalPostHideForm() {
  Logger.log('debug', `[state.journalPosts.actions] journalPostHideForm()`);
  return {
    type: TYPES.HIDE_FORM,
  }
}


// API THUNK ACTION CREATORS

export function loadJournalPosts(careGuideId, page=1, limit=10, order=null, filter=null, cb=function(){}) {
  Logger.log('debug', `[state.journalPosts.actions] loadJournalPosts(${careGuideId}, ${page}, ${limit}, ${order}, %j, ###)`, filter);

  return async function(dispatch) {
    dispatch(journalPostListRequest(careGuideId, page, limit, order, filter));

    // call API
    const response = await api.getCareGuideJournalPosts(careGuideId, page, limit, order, filter);
    let success = false;

    // get journal posts list success
    if (200 === response.get('status')) {

      Logger.log('info', `Get API journal posts list success. Care Guide ID: ${careGuideId}, Page: ${page}, Limit: ${limit}, Order: ${order}.`);

      const normalizedEntities = normalize(response.getIn(['data', 'journal_posts']), [schema.journalPost]);
      const data = {
        page: response.getIn(['data', 'page']),
        limit: response.getIn(['data', 'limit']),
        order: order,
        total: response.getIn(['data', 'total']),
        result: normalizedEntities.result
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(journalPostListSuccess(careGuideId, data));
      success = true;

    } else if (1 === page && 204 === response.get('status')) {

      Logger.log('info', `Get API journal posts list success [empty]. Care Guide ID: ${careGuideId}, Page: ${page}, Limit: ${limit}, Order: ${order}.`);
      const data = {
        page: page,
        limit: limit,
        order: order,
        total: 0,
        result: []
      };
      dispatch(journalPostListSuccess(careGuideId, data));
      success = true;
      
    // get journal posts list failure
    } else {
      Logger.log('info', `Get API journal posts list failure. Care Guide ID: ${careGuideId}, Page: ${page}, Limit: ${limit}, Order: ${order}.`);
      dispatch(journalPostListFailure(careGuideId, response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  }
}

export function loadJournalPost(careGuideId, id, cb=function(){}) {
  Logger.log('debug', `[state.journalPosts.actions] loadJournalPost(${careGuideId}, ${id}, ###)`);

  return async function(dispatch) {
    dispatch(journalPostReadRequest(careGuideId, id));

    // call API
    const response = await api.getCareGuideJournalPost(careGuideId, id);
    let success = false;

    // get journal post success
    if (200 === response.get('status')) {

      Logger.log('info', `Get API journal post success. Care Guide ID: ${careGuideId}, ID: ${id}.`);

      const normalizedEntities = normalize([response.getIn(['data', 'journal_post'])], [schema.journalPost]);
      const data = {
        id: response.getIn(['data', 'journal_post', 'id']),
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(journalPostReadSuccess(careGuideId, id, data));
      success = true;
      
    // get journal post failure
    } else {
      Logger.log('info', `Get API journal post failure. Care Guide ID: ${careGuideId}, ID: ${id}.`);
      dispatch(journalPostReadFailure(careGuideId, id, response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  }
}

export function createJournalPost(careGuideId, data, cb=function(){}) {
  Logger.log('debug', `[state.journalPosts.actions] createJournalPost(${careGuideId}, %j, ###)`, data);

  return async function(dispatch) {
    dispatch(journalPostCreateRequest(careGuideId, data));

    // call API
    const response = await api.postCareGuideJournalPosts(careGuideId, data);
    let success = false;

    // post journal post success
    if (201 === response.get('status')) {

      Logger.log('info', `POST API journal post success. Care Guide ID: ${careGuideId}.`);

      const normalizedEntities = normalize([response.getIn(['data', 'journal_post'])], [schema.journalPost]);
      const data = {
        id: response.getIn(['data', 'journal_post', 'id']),
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(journalPostCreateSuccess(careGuideId, data));
      success = true;
      
    // post journal post failure
    } else {
      Logger.log('info', `POST API journal post failure. Care Guide ID: ${careGuideId}.`);
      dispatch(journalPostCreateFailure(careGuideId, response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  }
}

export function patchJournalPost(careGuideId, id, data, cb=function(){}) {
  Logger.log('debug', `[state.journalPosts.actions] patchJournalPost(${careGuideId}, ${id}, %j, ###)`, data);

  return async function(dispatch) {
    dispatch(journalPostPatchRequest(careGuideId, id, data));

    // call API
    const response = await api.patchCareGuideJournalPost(careGuideId, id, data);
    let success = false;

    // patch journal post success
    if (200 === response.get('status')) {

      Logger.log('info', `PATCH API journal post success. Care Guide ID: ${careGuideId}, ID: ${id}.`);

      const normalizedEntities = normalize([response.getIn(['data', 'journal_post'])], [schema.journalPost]);
      const data = {
        id: response.getIn(['data', 'journal_post', 'id']),
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(journalPostPatchSuccess(careGuideId, id, data));
      success = true;
      
    // patch journal post failure
    } else {
      Logger.log('info', `PATCH API journal post failure. Care Guide ID: ${careGuideId}, ID: ${id}.`);
      dispatch(journalPostPatchFailure(careGuideId, id, response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  }
}

// export function deleteJournalPost(careGuideId, id, cb=function(){}) {
//   Logger.log('debug', `[state.journalPosts.actions] deleteJournalPost(${id}, ###)`);

//   return async function(dispatch) {
//     dispatch(journalPostDeleteRequest(careGuideId, id));

//     // call API
//     const response = await api.deleteJournalPost(careGuideId, id);
//     let success = false;

//     // delete journal post success
//     if (204 === response.get('status')) {

//       Logger.log('info', `DELETE API journal post success. Care Guide ID: ${careGuideId}, ID: ${id}.`);

//       dispatch(removeEntity({entityType: 'journal_posts', id: id}));
//       dispatch(journalPostDeleteSuccess(careGuideId, id));
//       success = true;
      
//     // get journal post failure
//     } else {
//       Logger.log('info', `DELETE API journal post failure. Care Guide ID: ${careGuideId}, ID: ${id}.`);
//       dispatch(journalPostDeleteFailure(careGuideId, response.getIn(['data', 'error'])));
//     }

//     // callback function
//     cb(success);
//   }
// }

export function generateJournalPostsPDF(careGuideId, page=1, limit=100, order=null, filter=null, cb=function(){}) {
  Logger.log('debug', `[state.journalPosts.actions] generateJournalPostsPDF(${careGuideId}, ${page}, ${limit}, ${order}, %j, ###)`, filter);

  return async function(dispatch) {
    dispatch(journalPostPDFRequest(careGuideId, page, limit, order, filter));

    // call API
    const response = await api.getCareGuideJournalPostsPDF(careGuideId, page, limit, order, filter);
    let success = false;

    // get journal posts PDF success
    if (200 === response.get('status')) {

      Logger.log('info', `Get API journal posts PDF success. Care Guide ID: ${careGuideId}, Page: ${page}, Limit: ${limit}, Order: ${order}.`);

      // trigger browser download
      const url = window.URL.createObjectURL(new Blob([response.get('data')], {type: 'application/pdf'}));
      const link = document.createElement('a');
      link.href = url;
      const contentDisposition = response.get('headers')['content-disposition'];
      link.setAttribute(
        'download',
        contentDisposition
          ? contentDisposition.substring(contentDisposition.indexOf('"') + 1, contentDisposition.lastIndexOf('"'))
          : 'report.pdf'
        );
      document.body.appendChild(link);
      link.click();

      dispatch(journalPostPDFSuccess(careGuideId));
      success = true;

    // get journal posts PDF failure
    } else {
      Logger.log('info', `Get API journal posts PDF failure. Care Guide ID: ${careGuideId}, Page: ${page}, Limit: ${limit}, Order: ${order}.`);
      dispatch(journalPostPDFFailure(careGuideId, response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  }
}

Logger.log('silly', `state.journalPosts.actions loaded.`);
