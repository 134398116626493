import { normalize } from "normalizr";

import api from "../../api";
import { schema } from "../../schema";
import { addEntities } from "../../actions";
import Logger from "../../../lib/Logger";

// ACTION TYPES

export const CARE_GUIDES_MEMBERS_PARTNERSHIP_LIST_REQUEST =
  "CARE_GUIDES_MEMBERS_PARTNERSHIP_LIST_REQUEST";
export const CARE_GUIDES_MEMBERS_PARTNERSHIP_LIST_SUCCESS =
  "CARE_GUIDES_MEMBERS_PARTNERSHIP_LIST_SUCCESS";
export const CARE_GUIDES_MEMBERS_PARTNERSHIP_LIST_FAILURE =
  "CARE_GUIDES_MEMBERS_PARTNERSHIP_LIST_FAILURE";

export function careGuidesMembersPartnershipListRequest(partnershipId) {
  Logger.log(
    "debug",
    `[careGuidesMembersPartnership.actions] careGuidesMembersPartnershipListRequest(${partnershipId} %j)`
  );
  return {
    type: CARE_GUIDES_MEMBERS_PARTNERSHIP_LIST_REQUEST,
    partnershipId: partnershipId,
  };
}

export function careGuidesMembersPartnershipListSuccess(partnershipId, data) {
  Logger.log(
    "debug",
    `[careGuidesMembersPartnership.actions] careGuidesMembersPartnershipListSuccess(${partnershipId}, %j)`,
    data
  );
  return {
    type: CARE_GUIDES_MEMBERS_PARTNERSHIP_LIST_SUCCESS,
    partnershipId: partnershipId,
    total: data.total,
    receivedAt: Date.now(),
  };
}

export function careGuidesMembersPartnershipListFailure(partnershipId, error) {
  Logger.log(
    "debug",
    `[careGuidesMembersPartnership.actions] careGuidesMembersPartnershipListFailure(${partnershipId}, %j)`,
    error
  );
  return {
    type: CARE_GUIDES_MEMBERS_PARTNERSHIP_LIST_FAILURE,
    partnershipId: partnershipId,
    error: error,
  };
}

// API THUNK ACTION CREATORS

export function loadCareGuidesMembersPartnership(partnershipId, cb = function () {}) {
  Logger.log(
    "debug",
    `[careGuidesMembersPartnership.actions] loadCareGuidesMembersPartnership(${partnershipId} %j, ###)`
  );

  return async function (dispatch) {
    dispatch(careGuidesMembersPartnershipListRequest(partnershipId));

    // call API
    const response = await api.getCareGuidesMembersPartnership(partnershipId);
    let success = false;

    // get care guides members partnership list success
    if (200 === response.get("status")) {
      Logger.log(
        "info",
        `Get API care guides partnership list success. ID: ${partnershipId}.`
      );

      const normalizedEntities = normalize(
        response.getIn(["data", "care_guides_members_partnership"]),
        [schema.careGuideMemberPartnership]
      );
      const data = {
        result: normalizedEntities.result,
        total: response.getIn(['data', 'total']),
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(careGuidesMembersPartnershipListSuccess(partnershipId, data));
      success = true;
    } else if (204 === response.get("status")) {
      Logger.log(
        "info",
        `Get API care guides members partnership success [empty]. ID: ${partnershipId}.`
      );
      const data = {
        total: 0,
        result: [],
      };
      dispatch(careGuidesMembersPartnershipListSuccess(partnershipId, data));
      success = true;

      // get care guide members partnership list failure
    } else {
      Logger.log(
        "info",
        `Get API care guides members partnership list failure. ID: ${partnershipId}.`
      );
      dispatch(
        careGuidesMembersPartnershipListFailure(
          partnershipId,
          response.getIn(["data", "error"])
        )
      );
    }

    // callback function
    cb(success);
  };
}

Logger.log("silly", `careGuidesMembersPartnership.actions loaded.`);
