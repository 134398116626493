import {normalize} from 'normalizr';

import api from '../../api';
import {schema} from '../../schema';
import {addEntities, removeEntity} from '../../actions';
import Logger from '../../../lib/Logger';

// ACTION TYPES

export const JOURNAL_PARTNER_TAG_ADMIN_LIST_REQUEST = 'JOURNAL_PARTNER_TAG_ADMIN_LIST_REQUEST';
export const JOURNAL_PARTNER_TAG_ADMIN_LIST_SUCCESS = 'JOURNAL_PARTNER_TAG_ADMIN_LIST_SUCCESS';
export const JOURNAL_PARTNER_TAG_ADMIN_LIST_FAILURE = 'JOURNAL_PARTNER_TAG_ADMIN_LIST_FAILURE';
export const JOURNAL_PARTNER_TAG_ADMIN_READ_REQUEST = 'JOURNAL_PARTNER_TAG_ADMIN_READ_REQUEST';
export const JOURNAL_PARTNER_TAG_ADMIN_READ_SUCCESS = 'JOURNAL_PARTNER_TAG_ADMIN_READ_SUCCESS';
export const JOURNAL_PARTNER_TAG_ADMIN_READ_FAILURE = 'JOURNAL_PARTNER_TAG_ADMIN_READ_FAILURE';
export const JOURNAL_PARTNER_TAG_ADMIN_UPDATE_REQUEST = 'JOURNAL_PARTNER_TAG_ADMIN_UPDATE_REQUEST';
export const JOURNAL_PARTNER_TAG_ADMIN_UPDATE_SUCCESS = 'JOURNAL_PARTNER_TAG_ADMIN_UPDATE_SUCCESS';
export const JOURNAL_PARTNER_TAG_ADMIN_UPDATE_FAILURE = 'JOURNAL_PARTNER_TAG_ADMIN_UPDATE_FAILURE';
export const JOURNAL_PARTNER_TAG_ADMIN_CREATE_REQUEST = 'JOURNAL_PARTNER_TAG_ADMIN_CREATE_REQUEST';
export const JOURNAL_PARTNER_TAG_ADMIN_CREATE_SUCCESS = 'JOURNAL_PARTNER_TAG_ADMIN_CREATE_SUCCESS';
export const JOURNAL_PARTNER_TAG_ADMIN_CREATE_FAILURE = 'JOURNAL_PARTNER_TAG_ADMIN_CREATE_FAILURE';
export const JOURNAL_PARTNER_TAG_ADMIN_DELETE_REQUEST = 'JOURNAL_PARTNER_TAG_ADMIN_DELETE_REQUEST';
export const JOURNAL_PARTNER_TAG_ADMIN_DELETE_SUCCESS = 'JOURNAL_PARTNER_TAG_ADMIN_DELETE_SUCCESS';
export const JOURNAL_PARTNER_TAG_ADMIN_DELETE_FAILURE = 'JOURNAL_PARTNER_TAG_ADMIN_DELETE_FAILURE';
export const JOURNAL_PARTNER_TAG_ADMIN_FORM_DESTROY = 'JOURNAL_PARTNER_TAG_ADMIN_FORM_DESTROY';

// ACTION CREATORS

export function journalPartnerTagAdminListRequest(page, limit, order, filter) {
  Logger.log('debug', `[journalPartnerTagsAdmin.actions] journalPartnerTagAdminListRequest(${page}, ${limit}, ${order}, %j)`, filter);
  return {
    type: JOURNAL_PARTNER_TAG_ADMIN_LIST_REQUEST,
    page: page,
    limit: limit,
    order: order,
    filter: filter
  }
}

export function journalPartnerTagAdminListSuccess(data) {
  Logger.log('debug', `[journalPartnerTagsAdmin.actions] journalPartnerTagAdminListSuccess(%j)`, data);
  return {
    type: JOURNAL_PARTNER_TAG_ADMIN_LIST_SUCCESS,
    page: data.page,
    limit: data.limit,
    order: data.order,
    result: data.result,
    total: data.total,
    receivedAt: Date.now()
  }
}

export function journalPartnerTagAdminListFailure(error) {
  Logger.log('debug', `[journalPartnerTagsAdmin.actions] journalPartnerTagAdminListFailure(%j)`, error);
  return {
    type: JOURNAL_PARTNER_TAG_ADMIN_LIST_FAILURE,
    error: error
  }
}


export function journalPartnerTagReadAdminRequest(id) {
  Logger.log('debug', `[journalPartnerTagsAdmin.actions] journalPartnerTagReadAdminRequest(${id})`);
  return {
    type: JOURNAL_PARTNER_TAG_ADMIN_READ_REQUEST,
    id: id
  }
}

export function journalPartnerTagReadAdminSuccess(data) {
  Logger.log('debug', `[journalPartnerTagsAdmin.actions] journalPartnerTagReadAdminSuccess(%j)`, data);
  return {
    type: JOURNAL_PARTNER_TAG_ADMIN_READ_SUCCESS,
    id: data.id,
    receivedAt: Date.now()
  }
}

export function journalPartnerTagReadAdminFailure(error) {
  Logger.log('debug', `[journalPartnerTagsAdmin.actions] journalPartnerTagReadAdminFailure(%j)`, error);
  return {
    type: JOURNAL_PARTNER_TAG_ADMIN_READ_FAILURE,
    error: error
  }
}

export function journalPartnerTagAdminUpdateRequest(id, data) {
  Logger.log('debug', `[journalPartnerTagsAdmin.actions] journalPartnerTagAdminUpdateRequest(${id}, %j)`, data);
  return {
    type: JOURNAL_PARTNER_TAG_ADMIN_UPDATE_REQUEST,
  }
}

export function journalPartnerTagAdminUpdateSuccess(data) {
  Logger.log('debug', `[journalPartnerTagsAdmin.actions] journalPartnerTagAdminUpdateSuccess(%j)`, data);
  return {
    type: JOURNAL_PARTNER_TAG_ADMIN_UPDATE_SUCCESS,
    id: data.id,
    receivedAt: Date.now()
  }
}

export function journalPartnerTagAdminUpdateFailure(error) {
  Logger.log('debug', `[journalPartnerTagsAdmin.actions] journalPartnerTagAdminUpdateFailure(%j)`, error);
  return {
    type: JOURNAL_PARTNER_TAG_ADMIN_UPDATE_FAILURE,
    error: error
  }
}

export function journalPartnerTagAdminCreateRequest(data) {
  Logger.log('debug', `[journalPartnerTagsAdmin.actions] journalPartnerTagAdminCreateRequest(%j)`, data);
  return {
    type: JOURNAL_PARTNER_TAG_ADMIN_CREATE_REQUEST,
  }
}

export function journalPartnerTagAdminCreateSuccess(data) {
  Logger.log('debug', `[journalPartnerTagsAdmin.actions] journalPartnerTagAdminCreateSuccess(%j)`, data);
  return {
    type: JOURNAL_PARTNER_TAG_ADMIN_CREATE_SUCCESS,
    id: data.id,
    receivedAt: Date.now()
  }
}

export function journalPartnerTagAdminCreateFailure(error) {
  Logger.log('debug', `[journalPartnerTagsAdmin.actions] journalPartnerTagAdminCreateFailure(%j)`, error);
  return {
    type: JOURNAL_PARTNER_TAG_ADMIN_CREATE_FAILURE,
    error: error
  }
}

export function journalPartnerTagAdminDeleteRequest(id) {
  Logger.log('debug', `[journalPartnerTagsAdmin.actions] journalPartnerTagAdminDeleteRequest(${id})`);
  return {
    type: JOURNAL_PARTNER_TAG_ADMIN_DELETE_REQUEST,
    id: id
  }
}

export function journalPartnerTagAdminDeleteSuccess(id) {
  Logger.log('debug', `[journalPartnerTagsAdmin.actions] journalPartnerTagAdminDeleteSuccess(${id})`);
  return {
    type: JOURNAL_PARTNER_TAG_ADMIN_DELETE_SUCCESS,
    id: id,
  }
}

export function journalPartnerTagAdminDeleteFailure(error) {
  Logger.log('debug', `[journalPartnerTagsAdmin.actions] journalPartnerTagAdminDeleteFailure(%j)`, error);
  return {
    type: JOURNAL_PARTNER_TAG_ADMIN_DELETE_FAILURE,
    error: error
  }
}

export function journalPartnerTagAdminFormDestroy(formState=null) {
  Logger.log('debug', `[journalPartnerTagsAdmin.actions] journalPartnerTagAdminFormDestroy(%j)`, formState);
  return {
    type: JOURNAL_PARTNER_TAG_ADMIN_FORM_DESTROY,
    form: formState
  }
}


// API THUNK ACTION CREATORS

export function loadJournalPartnerTagsAdmin(parntership_id = null, page=1, limit=10, order=null, filter=null, cb=function(){}) {
  Logger.log('debug', `[journalPartnerTagsAdmin.actions] loadJournalPartnerTagsAdmin(${page}, ${limit}, ${order}, %j, ###)`, filter);

  return async function(dispatch) {
    dispatch(journalPartnerTagAdminListRequest(page, limit, order, filter));

    // call API
    const response = await api.getJournalPartnerTagsAdmin(parntership_id, page, limit, order, filter);
    let success = false;

    // get journal partner tags admin list success
    if (200 === response.get('status')) {

      Logger.log('info', `Get API journal partner tags admin list success. Page: ${page}, Limit: ${limit}, Order: ${order}.`);

      const normalizedEntities = normalize(response.getIn(['data', 'journal_partner_tags']), [schema.journalPartnerTagAdmin]);
      const data = {
        page: response.getIn(['data', 'page']),
        limit: response.getIn(['data', 'limit']),
        order: order,
        total: response.getIn(['data', 'total']),
        result: normalizedEntities.result
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(journalPartnerTagAdminListSuccess(data));
      success = true;

    } else if (1 === page && 204 === response.get('status')) {

      Logger.log('info', `Get API journal partner tags admin success [empty]. Page: ${page}, Limit: ${limit}, Order: ${order}.`);
      const data = {
        page: page,
        limit: limit,
        order: order,
        total: 0,
        result: []
      };
      dispatch(journalPartnerTagAdminListSuccess(data));
      success = true;

    // get journal partner tags admin list failure
    } else {
      Logger.log('info', `Get API journal partner tags admin list failure. Page: ${page}, Limit: ${limit}, Order: ${order}.`);
      dispatch(journalPartnerTagAdminListFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  }
}

export function loadJournalPartnerTagAdmin(partnership_id, id, cb=function(){}) {
  Logger.log('debug', `[journalPartnerTagsAdmin.actions] loadJournalPartnerTagAdmin(${id}, ###)`);

  return async function(dispatch) {
    dispatch(journalPartnerTagReadAdminRequest(id));

    // call API
    const response = await api.getJournalPartnerTagAdmin(partnership_id, id);
    let success = false;

    // get journal partner tag admin success
    if (200 === response.get('status')) {

      Logger.log('info', `Get API journal partner tag admin success. ID: ${id}.`);

      const normalizedEntities = normalize([response.getIn(['data', 'journal_partner_tag'])], [schema.journalPartnerTagAdmin]);
      const data = {
        id: response.getIn(['data', 'journal_partner_tag', 'id']),
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(journalPartnerTagReadAdminSuccess(data));
      success = true;

    // get journal partner tag admin failure
    } else {
      Logger.log('info', `Get API journal partner tag admin failure. ID: ${id}.`);
      dispatch(journalPartnerTagReadAdminFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  }
}

export function updateJournalPartnerTagAdmin(id, data, cb=function(){}) {
  Logger.log('debug', `[journalPartnerTagsAdmin.actions] updateJournalPartnerTagAdmin(${id}, %j, ###)`, data);

  return async function(dispatch) {
    dispatch(journalPartnerTagAdminUpdateRequest(id, data));

    // call API
    const response = await api.putJournalPartnerTagAdmin(id, data);
    let success = false;

    // put journal partner tag admin success
    if (200 === response.get('status')) {

      Logger.log('info', `PUT API journal partner tag admin success. User: ${id}.`);

      const normalizedEntities = normalize([response.getIn(['data', 'journal_partner_tag'])], [schema.journalPartnerTagAdmin]);
      const data = {
        id: response.getIn(['data', 'journal_partner_tag', 'id']),
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(journalPartnerTagAdminUpdateSuccess(data));
      success = true;

    // get journal partner tag admin failure
    } else {
      Logger.log('info', `PUT API journal partner tag admin failure. ID: ${id}.`);
      dispatch(journalPartnerTagAdminUpdateFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  }
}

export function createJournalPartnerTagAdmin(data, cb=function(){}) {
  Logger.log('debug', `[journalPartnerTagsAdmin.actions] createJournalPartnerTagAdmin(%j, ###)`, data);

  return async function(dispatch) {
    dispatch(journalPartnerTagAdminCreateRequest(data));

    // call API
    const response = await api.postJournalPartnerTagsAdmin(data);
    let success = false;

    // post journal partenr tag admin success
    if (201 === response.get('status')) {

      Logger.log('info', `POST API journal partner tags admin success. Post: ${response.getIn(['data', 'journal_partner_tag', 'id'])}.`);

      const normalizedEntities = normalize([response.getIn(['data', 'journal_partner_tag'])], [schema.journalPartnerTagAdmin]);
      const data = {
        id: response.getIn(['data', 'journal_partner_tag', 'id']),
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(journalPartnerTagAdminCreateSuccess(data));
      success = true;

    // get journal partner tags admin failure
    } else {
      Logger.log('info', `POST API journal partner tags admin failure.`);
      dispatch(journalPartnerTagAdminCreateFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  }
}

export function deleteJournalPartnerTagAdmin(partnership_id, id, cb=function(){}) {
  Logger.log('debug', `[journalPartnerTagsAdmin.actions] deleteJournalPartnerTagAdmin(${id}, ###)`);

  return async function(dispatch) {
    dispatch(journalPartnerTagAdminDeleteRequest(id));

    // call API
    const response = await api.deleteJournalPartnerTagAdmin(partnership_id, id);
    let success = false;

    // delete journal partner tag admin success
    if (204 === response.get('status')) {

      Logger.log('info', `DELETE API journal partner tag admin success. ID: ${id}.`);

      dispatch(removeEntity({entityType: 'journalPartnerTagsAdmin', id: id}));
      dispatch(journalPartnerTagAdminDeleteSuccess(id));
      success = true;

    // get journal partner tag admin failure
    } else {
      Logger.log('info', `DELETE API journal partner tag admin failure. ID: ${id}.`);
      dispatch(journalPartnerTagAdminDeleteFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  }
}

Logger.log('silly', `journalPartnerTagsAdmin.actions loaded.`);
