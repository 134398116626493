import React, { useEffect } from 'react';
import { Translation } from 'react-i18next';
import { Form, Select } from 'antd';

const TagInput = ({ partnershipId, options, disabled, load, setClasses, nameClasses, ...props }) => {

  // load care guide partner tags on mount
  useEffect(() => {
    if (partnershipId) {
      load(partnershipId);
    }
  }, [load, partnershipId]);

  useEffect(() => {
    setClasses(nameClasses);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nameClasses]);

  return (
    <Translation>{(t) => (
      options.map(x =>
        <div className="form-group" key={x?.id}>
          <Form.Item
            name={x?.name}
            label={x?.name}
            initialValue={''}
          >
            <Select
              disabled={disabled}
            >
              <Select.Option key={0} value={''}>{t('form_input_options_none')}</Select.Option>
              {x?.care_guide_partner_tag?.map(y =>
                <Select.Option
                  key={y.id}
                  value={y.id}
                >
                  {y.label}
                </Select.Option>)}
            </Select>
          </Form.Item>
        </div>))
    }</Translation>
  );
}

export default TagInput;