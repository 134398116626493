import { List, Map } from "immutable";

import { TYPES } from "./actions";
import { SESSION_DESTROY } from "../../actions";
import Logger from "../../../lib/Logger";

const initialState = Map({
  isLoading: false,
  isLoadingAdmin: false,
  totalAdmin: 0,
  total: 0,
  results: {},
  resultsAdmin: {},
});

export default function plans(state = initialState, action) {
  Logger.log("debug", `[state.plans.reducers] plans(%j, %j)`, state, action);

  switch (action.type) {
    case TYPES.LIST_REQUEST:
      return state.mergeDeep({
        isLoading: true,
      });

    case TYPES.LIST_SUCCESS:
      return state
        .mergeDeep({
          isLoading: false,
          total: action.total,
          results: {
            pages: {
              [action.order]: {
                [action.limit]: {
                  [action.page]: null,
                },
              },
            },
          },
          lastUpdated: action.receivedAt,
        })
        .setIn(
          ["results", "pages", action.order, action.limit, action.page],
          List(action.result)
        );

    case TYPES.LIST_FAILURE:
      return state.mergeDeep({
        isLoading: false,
      });

    case TYPES.ADMIN_LIST_REQUEST:
      return state.mergeDeep({
        isLoadingAdmin: true,
      });

    case TYPES.ADMIN_LIST_SUCCESS:
      return state
        .mergeDeep({
          isLoadingAdmin: false,
          totalAdmin: action.total,
          resultsAdmin: {
            pages: {
              [action.order]: {
                [action.limit]: {
                  [action.page]: null,
                },
              },
            },
          },
          lastUpdated: action.receivedAt,
        })
        .setIn(
          ["resultsAdmin", "pages", action.order, action.limit, action.page],
          List(action.result)
        );

    case TYPES.ADMIN_LIST_FAILURE:
      return state.mergeDeep({
        isLoadingAdmin: false,
      });

    case SESSION_DESTROY:
      return initialState;

    default:
      return state;
  }
}

Logger.log("silly", `state.plans.reducers loaded.`);
