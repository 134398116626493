import React from "react";
import {Translation} from 'react-i18next';
import {Form, Radio} from 'antd';

import {happy, smiling, neutral, sad, sadTear} from './Emoji';
import useWindowDimensions from '../../../../../lib/WindowDimensions';

export default function RatingInput({name, label, rules, disabled, ...props}) {

  const {width} = useWindowDimensions();
  const isLarge = width > 768;

  const optionStyle = {fontSize: isLarge ? '40px' : '25px'};
  const size = isLarge ? 30 : 20;

  return (
    <Translation>{(t) => 
      <div className="form-group rating-input">
        <Form.Item
          name={name}
          label={label}
          rules={rules}
          {...props}
        >
          <Radio.Group
            optionType="button"
            size={isLarge ? "large" : "middle"}
            buttonStyle="solid"
            disabled={disabled}
          >
            <Radio.Button value="1"><span role="img" aria-label="angry face" style={optionStyle}>{sadTear(size)}</span></Radio.Button>
            <Radio.Button value="2"><span role="img" aria-label="slightly frowning face" style={optionStyle}>{sad(size)}</span></Radio.Button>
            <Radio.Button value="3"><span role="img" aria-label="neutral face" style={optionStyle}>{neutral(size)}</span></Radio.Button>
            <Radio.Button value="4"><span role="img" aria-label="slightly smiling face" style={optionStyle}>{smiling(size)}</span></Radio.Button>
            <Radio.Button value="5"><span role="img" aria-label="grinning face" style={optionStyle}>{happy(size)}</span></Radio.Button>
          </Radio.Group>
        </Form.Item>
      </div>
    }</Translation>
  );
}
