import React from 'react';
import { Translation } from 'react-i18next';
import { Form, Select } from 'antd';


const SubscriptionStatusInput = ({ name, label, optionPending, fieldKey = null, rules = null, disabled = false, ...props }) => {

  return (
    <Translation>{(t) =>
      <div className="form-group">
        <Form.Item
          name={name || 'subscription_status'}
          fieldKey={fieldKey}
          label={label || t('care_guide_admin_accounts_input_subscription_status')}
          rules={rules}
        >
          <Select disabled={disabled}>
            <Select.Option key={1} value={1}>{t("care_guide_admin_accounts_input_subscription_status_active")}</Select.Option>
            {optionPending === 1 ? 
            <Select.Option key={2} value={3}>{t("care_guide_admin_accounts_input_subscription_status_pending")}</Select.Option> :
            <Select.Option key={2} value={optionPending}>{t("care_guide_admin_accounts_input_subscription_status_pending")}</Select.Option>
          }
          </Select>
        </Form.Item>
      </div>
    }</Translation>
  );
}

export default SubscriptionStatusInput;
