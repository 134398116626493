import React, { useState, useEffect } from 'react';
import { Translation, getI18n } from 'react-i18next';
import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  Modal,
  Row,
} from 'antd';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import moment from 'moment-timezone';

import TimeInput from '../../calendar/components/TimeInput';
import RecurringInput from '../../calendar/components/RecurringInput';
import DescriptionInput from '../../calendar/components/DescriptionInput';
import MemberEventFormInput from '../containers/MemberEventFormInputContainer';
import message from '../../../elements/lib/MessageWrapper';
import Format from '../../../../../lib/Format';
import Config from '../../../../../Config';
import Logger from '../../../../../lib/Logger';

const MemberEventForm = ({
  partnershipId,
  careGuidesIds,
  start,
  end,
  page,
  limit,
  order,
  errors,
  isLoading,
  isSubmitting,
  initialParticipants,
  data,
  ...props
}) => {
  const [resetBody, setResetBody] = useState(null);
  const [isAllDay, setIsAllDay] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [isRecurring, setIsRecurring] = useState(false);
  const [form] = Form.useForm();

  const apiDatetimeFormat = Config.get('API_DATETIME_FORMAT');

  // handle errors reported by API
  useEffect(() => {
    let firstFieldName = '';
    for (const field in errors) {
      form.setFields([{ name: field, errors: errors[field] }]);
      if (firstFieldName === '') {
        firstFieldName = field;
      }
    }
    form.scrollToField(firstFieldName);
  }, [form, errors]);

  // select default participants
  useEffect(() => {
    if (!!initialParticipants.length) {
      form.setFieldsValue({ participants: initialParticipants });
    }
  }, [form, initialParticipants, data]);

  // focus on first input when showing form
  useEffect(() => {
    const firstInputField = form.getFieldInstance('title');
    if (props.visible && firstInputField) {
      // using timeout because there seems to be a timing issue conflicting with focus, maybe the modal
      setTimeout(() => firstInputField.focus(), 250);
    }
  }, [form, props.visible]);

  // submit data handler
  const submitData = async (values) => {
    Logger.log('debug', `EventForm.submitData(###)`);

    // const useEventId = eventIdOverride ? null : eventId;
    const payload = transformValues(values);

    // create
    props.create(partnershipId, payload, (success) => {
      if (success) {
        message.success(getI18n().t('feedback_form_success'));
        clearForm();
      } else {
        message.error(getI18n().t('feedback_form_error'));
      }
    });
  };

  const transformValues = (values) => {
    const payload = {};

    // transform date values
    if (values['is_all_day']) {
      payload['start_at'] = Format.date(
        moment
          .tz(values['start_at_date'], props.timezone)
          .startOf('day')
          .format(),
        apiDatetimeFormat
      );
      payload['recurring_end_at'] = values['recurring_end_at']
        ? Format.date(
            moment
              .tz(values['recurring_end_at'], props.timezone)
              .startOf('day')
              .format(),
            apiDatetimeFormat
          )
        : null;
    } else {
      // prep moment date objects so we can manipulate them
      const endDateObj = values['end_at_date']
        ? moment(values['end_at_date'], 'MM/DD/YYYY')
        : null;
      const endTimeObj = values['end_at_time']
        ? moment(values['end_at_time'], ['h:mm A'])
        : null;
      const startTimeObj = moment(values['start_at_time'], ['h:mm A']);

      // if end time is less than start time then increment end date by one day
      if (endTimeObj && endTimeObj < startTimeObj) {
        endDateObj.add(1, 'day');
      }

      const startDate2 = moment.tz(
        values['start_at_date'].format('YYYY-MM-DD') +
          ' ' +
          startTimeObj.format('HH:mm'),
        props.timezone
      );
      const endDate =
        endDateObj && endTimeObj
          ? moment.tz(
              endDateObj.format('YYYY-MM-DD') +
                ' ' +
                endTimeObj.format('HH:mm'),
              props.timezone
            )
          : null;
      const recurringEndDate = values['recurring_end_at']
        ? moment.tz(
            values['recurring_end_at'].format('YYYY-MM-DD') +
              ' ' +
              endTimeObj.format('HH:mm'),
            props.timezone
          )
        : null;
      payload['start_at'] = Format.date(startDate2.format(), apiDatetimeFormat);
      payload['end_at'] = endDate
        ? Format.date(endDate.format(), apiDatetimeFormat)
        : null;
      payload['recurring_end_at'] = recurringEndDate
        ? Format.date(recurringEndDate.format(), apiDatetimeFormat)
        : null;
    }

    // transform values
    for (var key in values) {
      // transform boolean values
      if (['is_all_day'].includes(key)) {
        payload[key] = values[key] ? true : false;
      }

      // // transform date values
      else if (
        [
          'start_at_date',
          'start_at_time',
          'end_at_date',
          'end_at_time',
          'recurring_end_at',
        ].includes(key)
      ) {
        // do nothing
      }

      // handle recipients
      else if (key === 'participants' && Array.isArray(values[key])) {
        payload[key] = values[key].map((x, i) => {
          return { user_profile_id: x };
        });
      }

      // handle optional
      else if (['parent_event_id', 'end_at'].includes(key)) {
        if (values[key] !== null && values[key] !== '') {
          payload[key] = values[key];
        }
      } else if (['url_event_partner', 'url_label'].includes(key)) {
        if (values[key]) {
          if (values[key].length > 0) {
            payload[key] = values[key];
          }
        }
      } else {
        payload[key] = values[key];
      }
    }

    if (partnershipId) {
      payload['care_guide_ids'] = careGuidesIds;
    }

    return payload;
  };

  const clearForm = () => {
    props.hideForm();
    props.formDestroy();
    form.setFieldsValue({
      title: '',
      url_event_partner: '',
      url_label: '',
      is_all_day: false,
      start_at_date: null,
      start_at_time: '8:00 AM',
      end_at_date: null,
      end_at_time: '9:00 AM',
      recurring: 1,
      recurring_end_at: null,
      parent_event_id: null,
      description: '',
      participants: [props.profileId],
    });
    for (const key of Object.keys(data)) {
      form.setFields([{ name: key, errors: [] }]);
    }
    setResetBody(Math.random());
    setIsAllDay(false);
    // setEventIdOverride(false);
    onRecurringChangeHandler(1);
  };

  // form submit handler
  const handleFinish = async (values) => {
    Logger.log('debug', `EventForm.handleFinish(###)`);
    if (!props.isSubmitting) {
      await submitData(values);
    }
  };

  // form error handler
  const handleFinishFailed = ({ values, errorFields, outOfDate }) => {
    Logger.log('debug', `EventForm.handleFinishFailed(###)`);
    message.error(getI18n().t('feedback_form_error'));
    if (errorFields && errorFields.length > 0) {
      form.scrollToField(errorFields[0].name);
    }
  };

  // remove error message when input value changes
  const handleValuesChange = (changedValues, allValues) => {
    for (const key of Object.keys(changedValues)) {
      form.setFields([{ name: key, errors: [] }]);
    }
  };

  const onChangeStartDate = (date, dateString) => {
    setStartDate(date);
    form.setFieldsValue({ end_at_date: Format.date(date, 'MM/DD/YYYY') });
    const lastDate = form.getFieldValue('recurring_end_at');
    const recurring = form.getFieldValue('recurring');
    if ([2, 3].includes(recurring) && (!lastDate || lastDate < date)) {
      form.setFieldsValue({ recurring_end_at: date });
    }
  };

  const disableLastDateHandler = (current) => {
    return startDate && current && current < startDate.startOf('day');
  };

  const onStartTimeChangeHandler = (value, option) => {
    // get end time value (as string)
    const endTimeValue = form.getFieldValue('end_at_time');

    // get date values (as moment.js objects)
    const startDate2 = form.getFieldValue('start_at_date');
    const endDate = form.getFieldValue('end_at_date');

    // turn time values into moment.js objects
    const endTime = endTimeValue ? moment(endTimeValue, ['h:mm A']) : null;
    const startTime = moment(value, ['h:mm A']);

    // if `end_at_time` has no value, set time to `start_at_time` (value) + 1 hour
    if (!endTimeValue) {
      form.setFieldsValue({
        end_at_time: startTime.add(1, 'hour').format('h:mm A'),
      });

      // if `start_at_date` and `end_at_date` are set to same day and `start_at_time` is later than `end_at_time`,
      // set time to `start_at_time` (value) + 1 hour
      // } else if (startDate2 && endDate && startDate2.format("YYYY-MM-DD") === endDate.format("YYYY-MM-DD") && startTime && endTime && startTime > endTime) {
    } else if (
      startDate2 &&
      endDate &&
      startDate2.format('MM/DD/YYYY') === endDate &&
      startTime &&
      endTime &&
      startTime >= endTime
    ) {
      form.setFieldsValue({
        end_at_time: startTime.add(1, 'hour').format('h:mm A'),
      });
    }
  };

  const onEndTimeChangeHandler = (value, option) => {
    // get start time value (as string)
    const startTimeValue = form.getFieldValue('start_at_time');

    // get date values (as moment.js objects)
    const startDate2 = form.getFieldValue('start_at_date');
    const endDate = form.getFieldValue('end_at_date');

    // turn time values into moment.js objects
    const startTime = startTimeValue
      ? moment(startTimeValue, ['h:mm A'])
      : null;
    const endTime = moment(value, ['h:mm A']);

    // if `start_at_date` and `end_at_date` are set to same day and `start_at_time` is later than `end_at_time`,
    // set time to `start_at_time` (value) + 1 hour
    // if (startDate2 && endDate && startDate2.format("YYYY-MM-DD") === endDate.format("YYYY-MM-DD") && startTime && endTime && startTime > endTime) {
    if (
      startDate2 &&
      endDate &&
      startDate2.format('MM/DD/YYYY') === endDate &&
      startTime &&
      endTime &&
      startTime > endTime
    ) {
      form.setFieldsValue({
        end_at_time: startTime.add(1, 'hour').format('h:mm A'),
      });
    }
  };

  const onRecurringChangeHandler = (val) => {
    setIsRecurring([2, 3, 4, 5].includes(val));
    const lastDate = form.getFieldValue('recurring_end_at');
    const recurring = form.getFieldValue('recurring');
    if ([2, 3, 4, 5].includes(recurring)) {
      if (!lastDate || lastDate < startDate) {
        form.setFieldsValue({ recurring_end_at: startDate });
      }
    } else {
      form.setFieldsValue({ recurring_end_at: null });
    }
  };

  const onClickOK = () => {
    form.submit();
  };

  return (
    <Translation>
      {(t) => (
        <Modal
          title={t('calendar_event_form_title_create')}
          centered
          visible={props.visible}
          onCancel={clearForm}
          destroyOnClose={true}
          footer={[
            <Button
              key="close"
              icon={<CloseCircleOutlined />}
              onClick={clearForm}
            >
              {t('action_close')}
            </Button>,
            <Button
              key="submit"
              type="primary"
              icon={<CheckCircleOutlined />}
              loading={isSubmitting}
              onClick={onClickOK}
            >
              {t('calendar_event_form_button_submit')}
            </Button>,
          ]}
          width={500}
          bodyStyle={{ paddingBottom: 0 }}
          forceRender={true}
        >
          <div className="calendar-event-form">
            <Form
              name="calendar_event_form"
              form={form}
              initialValues={data}
              onFinish={handleFinish}
              onFinishFailed={handleFinishFailed}
              onValuesChange={handleValuesChange}
              validateTrigger="onSubmit"
              requiredMark={false}
              layout="vertical"
            >
              <Row>
                <Col span={24}>
                  <div className="form-group">
                    <Form.Item
                      name="title"
                      label={null}
                      rules={[
                        {
                          required: true,
                          message: t('feedback_validation_required'),
                        },
                        {
                          type: 'string',
                          min: 0,
                          max: 250,
                          message: t('feedback_validation_length', {
                            min: 0,
                            max: 250,
                          }),
                        },
                      ]}
                    >
                      <Input
                        autoFocus
                        placeholder={t('calendar_event_form_placeholder_title')}
                        disabled={isLoading || isSubmitting}
                      />
                    </Form.Item>
                  </div>
                </Col>
              </Row>

              <Row>
                <Col span={24}>
                  <div className="form-group">
                    <Form.Item
                      name="is_all_day"
                      label={null}
                      valuePropName="checked"
                      style={{ marginBottom: 14 }}
                    >
                      <Checkbox
                        onChange={(evt) => setIsAllDay(evt.target.checked)}
                        disabled={isLoading || isSubmitting}
                      >
                        {t('calendar_event_form_input_is_all_day')}
                      </Checkbox>
                    </Form.Item>
                  </div>
                </Col>
              </Row>

              <Row gutter={16}>
                <Col span={8}>
                  <div className="form-group">
                    <Form.Item
                      name="start_at_date"
                      label={
                        isAllDay
                          ? t('calendar_event_form_input_date')
                          : t('calendar_event_form_input_start_at_date')
                      }
                      rules={[
                        {
                          required: true,
                          message: t('feedback_validation_required'),
                        },
                      ]}
                    >
                      <DatePicker
                        format="MM/DD/YYYY"
                        onChange={onChangeStartDate}
                        disabled={isLoading || isSubmitting}
                      />
                    </Form.Item>
                  </div>
                </Col>

                <Col span={8}>
                  {!isAllDay ? (
                    <TimeInput
                      name="start_at_time"
                      label={t('calendar_event_form_input_start_at_time')}
                      rules={[
                        {
                          required: true,
                          message: t('feedback_validation_required'),
                        },
                      ]}
                      disabled={isLoading || isSubmitting}
                      onChange={onStartTimeChangeHandler}
                    />
                  ) : null}
                </Col>

                {!isAllDay ? (
                  <Col span={8}>
                    <TimeInput
                      name="end_at_time"
                      label={t('calendar_event_form_input_end_at_time')}
                      rules={[
                        {
                          required: true,
                          message: t('feedback_validation_required'),
                        },
                      ]}
                      disabled={!startDate || isLoading || isSubmitting}
                      onChange={onEndTimeChangeHandler}
                    />
                  </Col>
                ) : null}

                <Form.Item name="end_at_date">
                  <Input type="hidden" />
                </Form.Item>
              </Row>
              <Row gutter={16}>
                <Col span={12}>
                  <div className="form-group">
                    <RecurringInput
                      name="recurring"
                      label={t('calendar_event_form_input_recurring')}
                      onChange={onRecurringChangeHandler}
                    />
                  </div>
                </Col>

                <Col span={12}>
                  <div className="form-group">
                    <Form.Item
                      name="recurring_end_at"
                      label={t(
                        'calendar_event_form_input_recurring_end_at_date'
                      )}
                      rules={[
                        {
                          required: isRecurring,
                          message: t('feedback_validation_required'),
                        },
                      ]}
                    >
                      <DatePicker
                        placeholder={
                          isRecurring
                            ? t(
                                'calendar_event_form_placeholder_recurring_end_at_date'
                              )
                            : null
                        }
                        format="MM/DD/YYYY"
                        allowClear={true}
                        disabledDate={disableLastDateHandler}
                        disabled={!isRecurring || isLoading || isSubmitting}
                      />
                    </Form.Item>
                  </div>
                </Col>
              </Row>

              <DescriptionInput
                name="description"
                label={null}
                placeholder={t('calendar_event_form_placeholder_description')}
                form={form}
                defaultValue={data['description']}
                disabled={isLoading || isSubmitting}
                reset={resetBody}
              />

                <>
                  <Row>
                    <Col span={24}>
                      <div className="form-group">
                        <Form.Item name="url_event_partner" label={null}>
                          <Input
                            placeholder={t(
                              'calendar_event_form_placeholder_url_event_partner'
                            )}
                            disabled={isLoading || isSubmitting}
                          />
                        </Form.Item>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <div className="form-group">
                        <Form.Item
                          name="url_label"
                          label={null}
                          rules={[
                            {
                              type: 'string',
                              min: 1,
                              max: 20,
                              message: t('feedback_validation_length', {
                                min: 1,
                                max: 20,
                              }),
                            },
                          ]}
                        >
                          <Input
                            placeholder={t(
                              'calendar_event_form_placeholder_url_label'
                            )}
                            disabled={isLoading || isSubmitting}
                          />
                        </Form.Item>
                      </div>
                    </Col>
                  </Row>
                </>

              <MemberEventFormInput
                name="participants"
                label={t('calendar_event_form_input_participants')}
                multiple={true}
                form={form}
                partnershipId={partnershipId}
                careGuidesIds={careGuidesIds}
                disabled={isLoading || isSubmitting}
                initialParticipants={initialParticipants}
              />

              <div style={{ display: 'none' }}>
                <Form.Item name="parent_event_id" label={null} rules={[]}>
                  <Input type="hidden" />
                </Form.Item>
              </div>

              <div style={{ display: 'none' }}>
                <Button htmlType="submit"></Button>
              </div>
            </Form>
          </div>
        </Modal>
      )}
    </Translation>
  );
};

export default MemberEventForm;

Logger.log('silly', `EventForm loaded.`);
