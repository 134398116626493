import React, { useState, useEffect } from 'react';
import { Translation, getI18n } from 'react-i18next';
import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  Modal,
  Popconfirm,
  Radio,
  Row,
  Space,
} from 'antd';
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  DeleteOutlined,
} from '@ant-design/icons';
import moment from 'moment-timezone';
import DescriptionInput from './DescriptionInput';
import TimeInput from './TimeInput';
import RecurringInput from './RecurringInput';
import MemberInput from '../../careGuideHelpers/containers/MemberInputContainer';
import message from '../../../elements/lib/MessageWrapper';
import { pathTo } from '../../../Routes';
import Format from '../../../../../lib/Format';
import Config from '../../../../../Config';
import Logger from '../../../../../lib/Logger';
import hasPermission, {
  VIEW_PARTNER,
} from '../../../elements/lib/hasPermission';
import {
  MixpanelTracker,
  mixpanelEventsEnum,
} from '../../../../../lib/Mixpanel';

const RecurringChangeConfirmModal = (props) => {
  return (
    <Translation>
      {(t) => (
        <Modal
          title={t('calendar_event_form_submit_recurring_confirm_title')}
          visible={props.isVisible}
          onOk={props.onOK}
          onCancel={props.onCancel}
          width={350}
        >
          <>
            <p>{props.text}</p>
            <Radio.Group onChange={props.onChange} value={props.value}>
              <Space direction="vertical">
                <Radio value={1}>
                  {t('calendar_event_form_submit_recurring_this')}
                </Radio>
                <Radio value={2}>
                  {t('calendar_event_form_submit_recurring_later')}
                </Radio>
                <Radio value={3}>
                  {t('calendar_event_form_submit_recurring_all')}
                </Radio>
              </Space>
            </Radio.Group>
          </>
        </Modal>
      )}
    </Translation>
  );
};

const EventForm = ({
  end,
  page,
  data,
  start,
  order,
  limit,
  errors,
  eventId,
  isLoading,
  careGuide,
  careGuideId,
  isSubmitting,
  clickedOnDate,
  partnershipActiveId,
  ...props
}) => {
  const [resetBody, setResetBody] = useState(null);
  const [isAllDay, setIsAllDay] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [isDeleteConfirmVisible, setDeleteConfirmVisible] = useState(false);
  const [isSaveConfirmVisible, setSaveConfirmVisible] = useState(false);
  const [eventIdOverride, setEventIdOverride] = useState(false);
  const [isRecurring, setIsRecurring] = useState(false);
  const [recurringChangeOption, setRecurringChangeOption] = useState(1);
  const [form] = Form.useForm();

  const apiDatetimeFormat = Config.get('API_DATETIME_FORMAT');

  const trackerCalendarEvent = (event, params = {}) => {
    if (careGuide?.partnership) {
      MixpanelTracker.mixpanelTrack(event, {
        careGuideName: `${careGuide.first_name} ${careGuide.last_name}`,
        partnershipName: careGuide.partnership.name,
        careGuideId: careGuide.id,
        partnershipId: careGuide.partnership.id,
        ...params,
      });
    } else {
      MixpanelTracker.mixpanelTrack(event, {
        careGuideName: `${careGuide.first_name} ${careGuide.last_name}`,
        careGuideId: careGuide.id,
        ...params,
      });
    }
  };

  // handle errors reported by API
  useEffect(() => {
    let firstFieldName = '';
    for (const field in errors) {
      form.setFields([{ name: field, errors: errors[field] }]);
      if (firstFieldName === '') {
        firstFieldName = field;
      }
    }
    form.scrollToField(firstFieldName);
  }, [form, errors]);

  // focus on first input when showing form
  useEffect(() => {
    const firstInputField = form.getFieldInstance('title');
    if (props.visible && firstInputField) {
      // using timeout because there seems to be a timing issue conflicting with focus, maybe the modal
      setTimeout(() => firstInputField.focus(), 250);
    }
  }, [form, props.visible]);

  // keep `is_all_day` synced between form data and local state
  useEffect(() => {
    setIsAllDay(data.is_all_day);
  }, [eventId, setIsAllDay, data]);

  // keep `start_at_date` synced between form data and local state
  useEffect(() => {
    setStartDate(data.start_at_date);
  }, [eventId, setStartDate, data]);

  // populate form with event data
  useEffect(() => {
    form.setFieldsValue(data);
    onRecurringChangeHandler(data.recurring);
  }, [eventId, form]); // eslint-disable-line react-hooks/exhaustive-deps

  // populate form with start/end dates if clicking directly on date to start new
  useEffect(() => {
    form.setFieldsValue({
      start_at_date: data.start_at_date,
      end_at_date: data.end_at_date,
    });
  }, [clickedOnDate, form]); // eslint-disable-line react-hooks/exhaustive-deps

  // submit data handler
  const submitData = async (values) => {
    Logger.log('debug', `EventForm.submitData(###)`);

    const useEventId = eventIdOverride ? null : eventId;
    const payload = transformValues(values);

    // create URL templates for event
    payload['url_template_calendar_event'] =
      window.location.href.split('/').slice(0, 3).join('/') +
      pathTo('CalendarScreen', { id: careGuideId }) +
      '?start={start}&event_id={event_id}';
    if (useEventId) {
      const realEventId = useEventId.replace(/\+.*$/, '');

      if (hasPermission(VIEW_PARTNER)) {
        // recurring events append `+i` (an incremental value) to the ID, let's strip that off
        props.updatePartner(
          partnershipActiveId,
          realEventId,
          payload,
          (success) => {
            if (success) {
              message.success(getI18n().t('feedback_form_success'));
              clearForm();
              trackerCalendarEvent(mixpanelEventsEnum.CALENDAR.EDIT_EVENT);
              props.load(careGuideId, start, end, page, limit, order);
            } else {
              message.error(getI18n().t('feedback_form_error'));
              setEventIdOverride(false);
            }
          }
        );
      } else {
        // recurring events append `+i` (an incremental value) to the ID, let's strip that off
        props.update(careGuideId, realEventId, payload, (success) => {
          if (success) {
            message.success(getI18n().t('feedback_form_success'));
            clearForm();
            trackerCalendarEvent(mixpanelEventsEnum.CALENDAR.EDIT_EVENT);
            props.load(careGuideId, start, end, page, limit, order);
          } else {
            message.error(getI18n().t('feedback_form_error'));
            setEventIdOverride(false);
          }
        });
      }
      // update
    } else {
      if (eventIdOverride && hasPermission(VIEW_PARTNER)) {
        const realEventId = eventId.replace(/\+.*$/, '');
        // create
        props.duplicatePartner(
          realEventId,
          partnershipActiveId,
          payload,
          (success) => {
            if (success) {
              message.success(getI18n().t('feedback_form_success'));
              clearForm();
              message.error(getI18n().t('feedback_form_error'));
              setEventIdOverride(false);
              trackerCalendarEvent(mixpanelEventsEnum.CALENDAR.CREATE_EVENT);
            }
          }
        );
      } else {
        // create
        props.create(careGuideId, payload, (success) => {
          if (success) {
            message.success(getI18n().t('feedback_form_success'));
            clearForm();
            trackerCalendarEvent(mixpanelEventsEnum.CALENDAR.CREATE_EVENT);
            props.load(careGuideId, start, end, page, limit, order);
          } else {
            message.error(getI18n().t('feedback_form_error'));
            setEventIdOverride(false);
          }
        });
      }
    }
  };

  const transformValues = (values) => {
    const payload = {};

    // transform date values
    if (values['is_all_day']) {
      payload['start_at'] = Format.date(
        moment
          .tz(values['start_at_date'], props.timezone)
          .startOf('day')
          .format(),
        apiDatetimeFormat
      );
      payload['recurring_end_at'] = values['recurring_end_at']
        ? Format.date(
            moment
              .tz(values['recurring_end_at'], props.timezone)
              .startOf('day')
              .format(),
            apiDatetimeFormat
          )
        : null;
    } else {
      // prep moment date objects so we can manipulate them
      const endDateObj = values['end_at_date']
        ? moment(values['end_at_date'], 'MM/DD/YYYY')
        : null;
      const endTimeObj = values['end_at_time']
        ? moment(values['end_at_time'], ['h:mm A'])
        : null;
      const startTimeObj = moment(values['start_at_time'], ['h:mm A']);

      // if end time is less than start time then increment end date by one day
      if (endTimeObj && endTimeObj < startTimeObj) {
        endDateObj.add(1, 'day');
      }

      const startDate2 = moment.tz(
        values['start_at_date'].format('YYYY-MM-DD') +
          ' ' +
          startTimeObj.format('HH:mm'),
        props.timezone
      );
      const endDate =
        endDateObj && endTimeObj
          ? moment.tz(
              endDateObj.format('YYYY-MM-DD') +
                ' ' +
                endTimeObj.format('HH:mm'),
              props.timezone
            )
          : null;
      const recurringEndDate = values['recurring_end_at']
        ? moment.tz(
            values['recurring_end_at'].format('YYYY-MM-DD') +
              ' ' +
              endTimeObj.format('HH:mm'),
            props.timezone
          )
        : null;
      payload['start_at'] = Format.date(startDate2.format(), apiDatetimeFormat);
      payload['end_at'] = endDate
        ? Format.date(endDate.format(), apiDatetimeFormat)
        : null;
      payload['recurring_end_at'] = recurringEndDate
        ? Format.date(recurringEndDate.format(), apiDatetimeFormat)
        : null;
    }

    // transform values
    for (var key in values) {
      // transform boolean values
      if (['is_all_day'].includes(key)) {
        payload[key] = values[key] ? true : false;
      }

      // // transform date values
      else if (
        [
          'start_at_date',
          'start_at_time',
          'end_at_date',
          'end_at_time',
          'recurring_end_at',
        ].includes(key)
      ) {
        // do nothing
      }

      // handle recipients
      else if (key === 'participants' && Array.isArray(values[key])) {
        payload[key] = values[key].map((x, i) => {
          return { user_profile_id: x };
        });
      }

      // handle optional
      else if (['parent_event_id', 'end_at'].includes(key)) {
        if (values[key] !== null && values[key] !== '') {
          payload[key] = values[key];
        }
      } else if (['url_event_partner', 'url_label'].includes(key)) {
        if (values[key]) {
          if (values[key].length > 0) {
            payload[key] = values[key];
          }
        }
      } else {
        payload[key] = values[key];
      }
    }

    if (partnershipActiveId) {
      payload['partnership_id'] = partnershipActiveId;
    }

    return payload;
  };

  const clearForm = () => {
    props.hideForm();
    props.formDestroy();
    form.setFieldsValue({
      title: '',
      url_event_partner: '',
      url_label: '',
      is_all_day: false,
      start_at_date: null,
      start_at_time: '8:00 AM',
      end_at_date: null,
      end_at_time: '9:00 AM',
      recurring: 1,
      recurring_end_at: null,
      parent_event_id: null,
      description: '',
      participants:
        props.profileId !== careGuide?.owner?.profile?.id &&
        careGuide?.owner?.profile?.id
          ? [props.profileId, careGuide?.owner?.profile?.id]
          : [props.profileId],
    });
    for (const key of Object.keys(data)) {
      form.setFields([{ name: key, errors: [] }]);
    }
    setResetBody(Math.random());
    setIsAllDay(false);
    setEventIdOverride(false);
    onRecurringChangeHandler(1);
  };

  // form submit handler
  const handleFinish = async (values) => {
    Logger.log('debug', `EventForm.handleFinish(###)`);
    if (!props.isSubmitting) {
      await submitData(values);
    }
  };

  // form error handler
  const handleFinishFailed = ({ values, errorFields, outOfDate }) => {
    Logger.log('debug', `EventForm.handleFinishFailed(###)`);
    message.error(getI18n().t('feedback_form_error'));
    if (errorFields && errorFields.length > 0) {
      form.scrollToField(errorFields[0].name);
    }
  };

  // remove error message when input value changes
  const handleValuesChange = (changedValues, allValues) => {
    for (const key of Object.keys(changedValues)) {
      form.setFields([{ name: key, errors: [] }]);
    }
  };

  const onChangeStartDate = (date, dateString) => {
    setStartDate(date);
    form.setFieldsValue({ end_at_date: Format.date(date, 'MM/DD/YYYY') });
  };

  const disableLastDateHandler = (current) => {
    return startDate && current && current < startDate.startOf('day');
  };

  const onStartTimeChangeHandler = (value, option) => {
    // get end time value (as string)
    const endTimeValue = form.getFieldValue('end_at_time');

    // get date values (as moment.js objects)
    const startDate2 = form.getFieldValue('start_at_date');
    const endDate = form.getFieldValue('end_at_date');

    // turn time values into moment.js objects
    const endTime = endTimeValue ? moment(endTimeValue, ['h:mm A']) : null;
    const startTime = moment(value, ['h:mm A']);

    // if `end_at_time` has no value, set time to `start_at_time` (value) + 1 hour
    if (!endTimeValue) {
      form.setFieldsValue({
        end_at_time: startTime.add(1, 'hour').format('h:mm A'),
      });

      // if `start_at_date` and `end_at_date` are set to same day and `start_at_time` is later than `end_at_time`,
      // set time to `start_at_time` (value) + 1 hour
      // } else if (startDate2 && endDate && startDate2.format("YYYY-MM-DD") === endDate.format("YYYY-MM-DD") && startTime && endTime && startTime > endTime) {
    } else if (
      startDate2 &&
      endDate &&
      startDate2.format('MM/DD/YYYY') === endDate &&
      startTime &&
      endTime &&
      startTime >= endTime
    ) {
      form.setFieldsValue({
        end_at_time: startTime.add(1, 'hour').format('h:mm A'),
      });
    }
  };

  const onEndTimeChangeHandler = (value, option) => {
    // get start time value (as string)
    const startTimeValue = form.getFieldValue('start_at_time');

    // get date values (as moment.js objects)
    const startDate2 = form.getFieldValue('start_at_date');
    const endDate = form.getFieldValue('end_at_date');

    // turn time values into moment.js objects
    const startTime = startTimeValue
      ? moment(startTimeValue, ['h:mm A'])
      : null;
    const endTime = moment(value, ['h:mm A']);

    // if `start_at_date` and `end_at_date` are set to same day and `start_at_time` is later than `end_at_time`,
    // set time to `start_at_time` (value) + 1 hour
    // if (startDate2 && endDate && startDate2.format("YYYY-MM-DD") === endDate.format("YYYY-MM-DD") && startTime && endTime && startTime > endTime) {
    if (
      startDate2 &&
      endDate &&
      startDate2.format('MM/DD/YYYY') === endDate &&
      startTime &&
      endTime &&
      startTime > endTime
    ) {
      form.setFieldsValue({
        end_at_time: startTime.add(1, 'hour').format('h:mm A'),
      });
    }
  };

  const onRecurringChangeHandler = (val) => {
    setIsRecurring([2, 3, 4, 5].includes(val));
    form.setFieldsValue({ recurring_end_at: null });
  };

  const onClickOK = () => {
    setSaveConfirmVisible(false);
    form.submit();
  };

  const onClickDelete = () => {
    // recurring events append `+i` (an incremental value) to the ID, let's strip that off
    const realEventId = eventId.replace(/\+.*$/, '');

    props.delete(careGuideId, realEventId, (success) => {
      if (success) {
        message.success(getI18n().t('feedback_delete_success'));
        clearForm();
        trackerCalendarEvent(mixpanelEventsEnum.CALENDAR.DELETE_EVENT);
        props.load(careGuideId, start, end, page, limit, order);
        setDeleteConfirmVisible(false);
      } else {
        message.error(getI18n().t('feedback_delete_error'));
      }
    });
  };

  // delete an entire series of recurring events
  const onClickDeleteAll = () => {
    setDeleteConfirmVisible(false);
    setRecurringChangeOption(1);
    if (eventId) {
      const realEventId = eventId.replace(/\+.*$/, '');
      const deleteEventId = data.parent_event_id
        ? data.parent_event_id
        : realEventId;

      if (hasPermission(VIEW_PARTNER)) {
        props.deletePartner(partnershipActiveId, deleteEventId, (success) => {
          if (success) {
            message.success(getI18n().t('feedback_delete_success'));
            clearForm();
            trackerCalendarEvent(mixpanelEventsEnum.CALENDAR.DELETE_EVENT);
            props.load(careGuideId, start, end, page, limit, order);
          } else {
            message.error(getI18n().t('feedback_delete_error'));
          }
        });
      } else {
        props.delete(careGuideId, deleteEventId, (success) => {
          if (success) {
            message.success(getI18n().t('feedback_delete_success'));
            clearForm();
            trackerCalendarEvent(mixpanelEventsEnum.CALENDAR.DELETE_EVENT);
            props.load(careGuideId, start, end, page, limit, order);
          } else {
            message.error(getI18n().t('feedback_delete_error'));
          }
        });
      }
    }
  };

  const onClickDeleteOccurrence = () => {
    setDeleteConfirmVisible(false);
    setRecurringChangeOption(1);

    // recurring events append `+i` (an incremental value) to the ID, let's strip that off
    const realEventId = eventId.replace(/\+.*$/, '');
    const payload = {
      exception_at: Format.date(props.event.start_at, apiDatetimeFormat),
    };

    props.createException(careGuideId, realEventId, payload, (success) => {
      if (success) {
        message.success(getI18n().t('feedback_delete_success'));
        clearForm();
        props.load(careGuideId, start, end, page, limit, order);
      } else {
        message.error(getI18n().t('feedback_delete_error'));
      }
    });
  };

  // deletes a sequence of recurring events
  const onClickDeleteLaterOccurrences = () => {
    setDeleteConfirmVisible(false);
    setRecurringChangeOption(1);

    // request is only valid for existing recurring events
    if (eventId) {
      // recurring events append `+i` (an incremental value) to the ID, let's strip that off
      const realEventId = eventId.replace(/\+.*$/, '');

      // if changing first instance a simple update is fine
      if (props.isFirstInstace === true) {
        form.submit();
      } else {
        // if changing an instance that is not first must create a new sequence

        let patchPayload = {};

        // first update previous sequence to end (unchanged) before the instance being updated
        switch (data.recurring) {
          case 2:
            patchPayload = {
              recurring_end_at: Format.date(
                data.start_at_date.clone().startOf('day'),
                apiDatetimeFormat
              ),
            };
            break;
          case 3:
            patchPayload = {
              recurring_end_at: Format.date(
                data.start_at_date.clone().subtract(6, 'days').startOf('day'),
                apiDatetimeFormat
              ),
            };
            break;
          case 4:
            patchPayload = {
              recurring_end_at: Format.date(
                data.start_at_date.clone().subtract(1, 'months').startOf('day'),
                apiDatetimeFormat
              ),
            };
            break;
          case 5:
            patchPayload = {
              recurring_end_at: Format.date(
                data.start_at_date.clone().subtract(1, 'months').startOf('day'),
                apiDatetimeFormat
              ),
            };
            break;
          case 1:
          default:
          // unsupported recurring
        }

        if (hasPermission(VIEW_PARTNER)) {
          // create
          props.patchPartner(
            realEventId,
            partnershipActiveId,
            patchPayload,
            (success) => {
              if (success) {
                message.success(getI18n().t('feedback_delete_success'));
                clearForm();
                trackerCalendarEvent(mixpanelEventsEnum.CALENDAR.EDIT_EVENT);
                props.load(careGuideId, start, end, page, limit, order);
              } else {
                message.error(getI18n().t('feedback_form_error'));
              }
            }
          );
        } else {
          props.patch(careGuideId, realEventId, patchPayload, (success) => {
            if (success) {
              message.success(getI18n().t('feedback_delete_success'));
              clearForm();
              trackerCalendarEvent(mixpanelEventsEnum.CALENDAR.EDIT_EVENT);
              props.load(careGuideId, start, end, page, limit, order);
            } else {
              message.error(getI18n().t('feedback_delete_error'));
            }
          });
        }
      }
    }
  };

  // updates a sequence of recurring events
  const onClickSaveLaterOccurrences = () => {
    setSaveConfirmVisible(false);
    setRecurringChangeOption(1);

    // request is only valid for existing recurring events
    if (eventId) {
      // recurring events append `+i` (an incremental value) to the ID, let's strip that off
      const realEventId = eventId.replace(/\+.*$/, '');

      // if changing first instance a simple update is fine
      if (props.isFirstInstace === true) {
        form.submit();
      } else {
        // if changing an instance that is not first must create a new sequence

        let patchPayload = {};

        // first update previous sequence to end (unchanged) before the instance being updated
        switch (data.recurring) {
          case 2:
            patchPayload = {
              recurring_end_at: Format.date(
                data.start_at_date.clone().subtract(1, 'days').endOf('day'),
                apiDatetimeFormat
              ),
            };
            break;
          case 3:
            patchPayload = {
              recurring_end_at: Format.date(
                data.start_at_date.clone().subtract(7, 'days').endOf('day'),
                apiDatetimeFormat
              ),
            };
            break;
          case 4:
            patchPayload = {
              recurring_end_at: Format.date(
                data.start_at_date.clone().subtract(1, 'months').endOf('day'),
                apiDatetimeFormat
              ),
            };
            break;
          case 5:
            patchPayload = {
              recurring_end_at: Format.date(
                data.start_at_date.clone().subtract(1, 'months').endOf('day'),
                apiDatetimeFormat
              ),
            };
            break;
          case 1:
          default:
          // unsupported recurring
        }

        if (hasPermission(VIEW_PARTNER)) {
          // create
          props.patchPartner(
            realEventId,
            partnershipActiveId,
            patchPayload,
            (success) => {
              if (success) {
                trackerCalendarEvent(mixpanelEventsEnum.CALENDAR.EDIT_EVENT);

                setEventIdOverride(true);
                form.setFieldsValue({
                  parent_event_id: realEventId,
                });
                form.submit();
              } else {
                message.error(getI18n().t('feedback_form_error'));
              }
            }
          );
        } else {
          props.patch(careGuideId, realEventId, patchPayload, (success) => {
            // then create a new sequence starting with this instance
            if (success) {
              trackerCalendarEvent(mixpanelEventsEnum.CALENDAR.EDIT_EVENT);
              setEventIdOverride(true);
              form.setFieldsValue({
                parent_event_id: realEventId,
              });
              form.submit();
            } else {
              message.error(getI18n().t('feedback_form_error'));
            }
          });
        }
      }
    }
  };

  const onClickSaveThisOccurrence = () => {
    setSaveConfirmVisible(false);

    // if user changed recurring end date and selected to update only this instance, we assume the intent is to update the entire series
    if (
      data.recurring_end_at !== form.getFieldValue('recurring_end_at') &&
      data.recurring === form.getFieldValue('recurring')
    ) {
      onClickSaveAllOccurrences();
    }

    // this path will create a new exception
    else {
      // recurring events append `+i` (an incremental value) to the ID, let's strip that off
      const realEventId = eventId.replace(/\+.*$/, '');

      // create new exception
      const payload = {
        exception_at: Format.date(props.event.start_at, apiDatetimeFormat),
      };
      props.createException(careGuideId, realEventId, payload, (success) => {
        if (success) {
          onRecurringChangeHandler(1);
          setEventIdOverride(true);

          // if user did not change recurring value and selected to update only this instance, the intent is to just change this one date
          // otherwise we assume the intent is to create a new recurring series
          if (data.recurring === form.getFieldValue('recurring')) {
            form.setFieldsValue({
              recurring: 1,
              recurring_end_at: null,
              parent_event_id: realEventId,
            });
          }

          form.submit();
        } else {
          message.error(getI18n().t('feedback_form_error'));
          setEventIdOverride(false);
        }
      });
    }
  };

  const onClickSaveAllOccurrences = () => {
    setSaveConfirmVisible(false);
    setRecurringChangeOption(1);

    // request is only valid for existing recurring events
    if (eventId && props.event && props.event.recurring_event) {
      // recurring events append `+i` (an incremental value) to the ID, let's strip that off
      const realEventId = eventId.replace(/\+.*$/, '');

      // construct payload, use times from form but dates from recurring event
      const values = form.getFieldsValue();
      const { start_at, end_at, ...patchPayload } = transformValues(values);

      const startAt = moment(start_at, apiDatetimeFormat);
      patchPayload.start_at = Format.date(
        moment(props.event.recurring_event.start_at, apiDatetimeFormat)
          .hour(startAt.hour())
          .minute(startAt.minute()),
        apiDatetimeFormat
      );

      const endAt = end_at ? moment(end_at, apiDatetimeFormat) : null;
      patchPayload.end_at = endAt
        ? Format.date(
            moment(props.event.recurring_event.end_at, apiDatetimeFormat)
              .hour(endAt.hour())
              .minute(endAt.minute()),
            apiDatetimeFormat
          )
        : null;

      if (hasPermission(VIEW_PARTNER)) {
        // create
        props.patchPartner(
          realEventId,
          partnershipActiveId,
          patchPayload,
          (success) => {
            if (success) {
              message.success(getI18n().t('feedback_form_success'));
              clearForm();
              trackerCalendarEvent(mixpanelEventsEnum.CALENDAR.EDIT_EVENT);

              props.load(careGuideId, start, end, page, limit, order);
            } else {
              message.error(getI18n().t('feedback_form_error'));
            }
          }
        );
      } else {
        props.patch(careGuideId, realEventId, patchPayload, (success) => {
          // then create a new sequence starting with this instance
          if (success) {
            message.success(getI18n().t('feedback_form_success'));
            clearForm();
            trackerCalendarEvent(mixpanelEventsEnum.CALENDAR.EDIT_EVENT);
            props.load(careGuideId, start, end, page, limit, order);
          } else {
            message.error(getI18n().t('feedback_form_error'));
          }
        });
      }
    }
  };

  const changeRecurringOption = (e) => {
    setRecurringChangeOption(e.target.value);
  };

  const onRecurringOptionClickOK = () => {
    setSaveConfirmVisible(false);
    switch (recurringChangeOption) {
      case 1:
        onClickSaveThisOccurrence();
        break;
      case 2:
        onClickSaveLaterOccurrences();
        break;
      case 3:
        onClickSaveAllOccurrences();
        break;
      default:
      // do nothing
    }
  };

  const onRecurringOptionClickCancel = () => {
    setSaveConfirmVisible(false);
  };

  const onRecurringOptionDeleteClickOK = () => {
    setDeleteConfirmVisible(false);
    switch (recurringChangeOption) {
      case 1:
        onClickDeleteOccurrence();
        break;
      case 2:
        onClickDeleteLaterOccurrences();
        break;
      case 3:
        onClickDeleteAll();
        break;
      default:
      // do nothing
    }
  };

  const onRecurringOptionDeleteClickCancel = () => {
    setDeleteConfirmVisible(false);
  };

  return (
    <Translation>
      {(t) => (
        <>
          <Modal
            title={
              eventId
                ? t('calendar_event_form_title_edit')
                : t('calendar_event_form_title_create')
            }
            centered
            visible={props.visible}
            // onOk={onClickOK}
            onCancel={clearForm}
            footer={[
              <Button
                key="close"
                icon={<CloseCircleOutlined />}
                onClick={clearForm}
              >
                {t('action_close')}
              </Button>,
              eventId ? (
                [2, 3, 4, 5].includes(data['recurring']) ? (
                  <Button
                    key="delete"
                    danger
                    icon={<DeleteOutlined />}
                    onClick={() => setDeleteConfirmVisible(true)}
                  >
                    {t('action_delete')}
                  </Button>
                ) : (
                  <Popconfirm
                    key="delete-confirm"
                    placement="top"
                    title={t('calendar_event_form_delete_confirm_body')}
                    onConfirm={onClickDelete}
                    okText={t('confirm_yes')}
                    cancelText={t('confirm_cancel')}
                  >
                    <Button key="delete" danger icon={<DeleteOutlined />}>
                      {t('action_delete')}
                    </Button>
                  </Popconfirm>
                )
              ) : null,
              <Button
                key="submit"
                type="primary"
                icon={<CheckCircleOutlined />}
                loading={isSubmitting}
                onClick={
                  eventId && [2, 3, 4, 5].includes(data['recurring'])
                    ? () => {
                        if (
                          data.recurring_end_at !==
                            form.getFieldValue('recurring_end_at') &&
                          data.recurring === form.getFieldValue('recurring')
                        ) {
                          onClickSaveAllOccurrences();
                        } else {
                          setSaveConfirmVisible(true);
                        }
                      }
                    : onClickOK
                }
              >
                {t('calendar_event_form_button_submit')}
              </Button>,
            ]}
            width={500}
            bodyStyle={{ paddingBottom: 0 }}
            forceRender={true}
          >
            <div className="calendar-event-form">
              <Form
                name="calendar_event_form"
                form={form}
                initialValues={data}
                onFinish={handleFinish}
                onFinishFailed={handleFinishFailed}
                onValuesChange={handleValuesChange}
                validateTrigger="onSubmit"
                requiredMark={false}
                layout="vertical"
              >
                <Row>
                  <Col span={24}>
                    <div className="form-group">
                      <Form.Item
                        name="title"
                        label={null}
                        rules={[
                          {
                            required: true,
                            message: t('feedback_validation_required'),
                          },
                          {
                            type: 'string',
                            min: 0,
                            max: 250,
                            message: t('feedback_validation_length', {
                              min: 0,
                              max: 250,
                            }),
                          },
                        ]}
                      >
                        <Input
                          autoFocus
                          placeholder={t(
                            'calendar_event_form_placeholder_title'
                          )}
                          disabled={isLoading || isSubmitting}
                        />
                      </Form.Item>
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col span={24}>
                    <div className="form-group">
                      <Form.Item
                        name="is_all_day"
                        label={null}
                        valuePropName="checked"
                        style={{ marginBottom: 14 }}
                      >
                        <Checkbox
                          onChange={(evt) => setIsAllDay(evt.target.checked)}
                          disabled={isLoading || isSubmitting}
                        >
                          {t('calendar_event_form_input_is_all_day')}
                        </Checkbox>
                      </Form.Item>
                    </div>
                  </Col>
                </Row>

                <Row gutter={16}>
                  <Col span={8}>
                    <div className="form-group">
                      <Form.Item
                        name="start_at_date"
                        label={
                          isAllDay
                            ? t('calendar_event_form_input_date')
                            : t('calendar_event_form_input_start_at_date')
                        }
                        rules={[
                          {
                            required: true,
                            message: t('feedback_validation_required'),
                          },
                        ]}
                      >
                        <DatePicker
                          format="MM/DD/YYYY"
                          onChange={onChangeStartDate}
                          disabled={isLoading || isSubmitting}
                        />
                      </Form.Item>
                    </div>
                  </Col>

                  <Col span={8}>
                    {!isAllDay ? (
                      <TimeInput
                        name="start_at_time"
                        label={t('calendar_event_form_input_start_at_time')}
                        rules={[
                          {
                            required: true,
                            message: t('feedback_validation_required'),
                          },
                        ]}
                        disabled={isLoading || isSubmitting}
                        // onDropdownVisibleChange={onStartTimeDropdownVisibleHandler}
                        onChange={onStartTimeChangeHandler}
                      />
                    ) : null}
                  </Col>

                  {!isAllDay ? (
                    <Col span={8}>
                      <TimeInput
                        name="end_at_time"
                        label={t('calendar_event_form_input_end_at_time')}
                        rules={[
                          {
                            required: true,
                            message: t('feedback_validation_required'),
                          },
                        ]}
                        disabled={!startDate || isLoading || isSubmitting}
                        onChange={onEndTimeChangeHandler}
                      />
                    </Col>
                  ) : null}

                  <Form.Item name="end_at_date">
                    <Input type="hidden" />
                  </Form.Item>
                </Row>

                <Row gutter={16}>
                  <Col span={12}>
                    <div className="form-group">
                      <RecurringInput
                        name="recurring"
                        label={t('calendar_event_form_input_recurring')}
                        onChange={onRecurringChangeHandler}
                      />
                    </div>
                  </Col>

                  <Col span={12}>
                    <div className="form-group">
                      <Form.Item
                        name="recurring_end_at"
                        label={t(
                          'calendar_event_form_input_recurring_end_at_date'
                        )}
                      >
                        <DatePicker
                          placeholder={
                            isRecurring
                              ? t(
                                  'calendar_event_form_placeholder_recurring_end_at_date'
                                )
                              : null
                          }
                          format="MM/DD/YYYY"
                          allowClear={true}
                          disabledDate={disableLastDateHandler}
                          disabled={!isRecurring || isLoading || isSubmitting}
                        />
                      </Form.Item>
                    </div>
                  </Col>
                </Row>

                <DescriptionInput
                  name="description"
                  label={null}
                  placeholder={t('calendar_event_form_placeholder_description')}
                  form={form}
                  defaultValue={data['description']}
                  disabled={isLoading || isSubmitting}
                  reset={resetBody}
                />

                {hasPermission(VIEW_PARTNER) ? (
                  <>
                    <Row>
                      <Col span={24}>
                        <div className="form-group">
                          <Form.Item name="url_event_partner" label={null}>
                            <Input
                              placeholder={t(
                                'calendar_event_form_placeholder_url_event_partner'
                              )}
                              disabled={isLoading || isSubmitting}
                            />
                          </Form.Item>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={24}>
                        <div className="form-group">
                          <Form.Item
                            name="url_label"
                            label={null}
                            rules={[
                              {
                                type: 'string',
                                min: 1,
                                max: 20,
                                message: t('feedback_validation_length', {
                                  min: 1,
                                  max: 20,
                                }),
                              },
                            ]}
                          >
                            <Input
                              placeholder={t(
                                'calendar_event_form_placeholder_url_label'
                              )}
                              disabled={isLoading || isSubmitting}
                            />
                          </Form.Item>
                        </div>
                      </Col>
                    </Row>
                  </>
                ) : null}

                {(eventId === null ||
                  (data['care_guide_id'] && eventId !== null)) && (
                  <MemberInput
                    name="participants"
                    label={t('calendar_event_form_input_participants')}
                    multiple={true}
                    careGuideId={careGuideId}
                  />
                )}

                <div style={{ display: 'none' }}>
                  <Form.Item name="parent_event_id" label={null} rules={[]}>
                    <Input type="hidden" />
                  </Form.Item>
                </div>

                <div style={{ display: 'none' }}>
                  <Button htmlType="submit"></Button>
                </div>
              </Form>

              <RecurringChangeConfirmModal
                isVisible={isSaveConfirmVisible}
                onChange={changeRecurringOption.bind(this)}
                value={recurringChangeOption}
                onOK={onRecurringOptionClickOK}
                onCancel={onRecurringOptionClickCancel}
                text={t('calendar_event_form_submit_recurring_confirm_body')}
              />

              <RecurringChangeConfirmModal
                isVisible={isDeleteConfirmVisible}
                onChange={changeRecurringOption.bind(this)}
                value={recurringChangeOption}
                onOK={onRecurringOptionDeleteClickOK}
                onCancel={onRecurringOptionDeleteClickCancel}
                text={t('calendar_event_form_delete_recurring_confirm_body')}
              />
            </div>
          </Modal>
        </>
      )}
    </Translation>
  );
};

export default EventForm;

Logger.log('silly', `EventForm loaded.`);
