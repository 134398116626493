import React, {Component} from 'react';
import {Translation} from 'react-i18next';
import {Col, Row} from 'antd';

import DocumentHead from '../../../elements/components/DocumentHead';
import scrollToTop from '../../../elements/lib/scrollToTop';
import Logger from '../../../../../lib/Logger';

class ConsentScreen extends Component {

  render() {
    return (
      <Translation>{(t) => 
        <>

          <DocumentHead title={t('content_route_consent')} />

          <Row>
            <Col span={24}>

              <iframe src="https://www.cognitoforms.com/f/RnQXxvDs4Uyff6Y8NCacyg?id=23" frameBorder="0" scrolling="yes" seamless="seamless" height="4036" width="100%" title="Consent Form"></iframe>

            </Col>
          </Row>

        </>
      }</Translation>
    )
  }

  /* LIFECYCLE EVENTS */

  componentDidMount() {
    scrollToTop();

    const script = document.createElement("script");
    script.src = "https://www.cognitoforms.com/scripts/embed.js";
    script.async = true;
    document.body.appendChild(script);
  }
}

export default ConsentScreen;

Logger.log('silly', `ConsentScreen loaded.`);
