import {List, Map} from 'immutable';

import {TYPES} from './actions';
import {SESSION_DESTROY} from '../../actions';
import Logger from '../../../lib/Logger';

const initialState = Map({
  isLoading: false,
  isResending: false,
  isResponding: false,
  results: {},
  pages: {},
  form: {},
  formIsVisible: false,
  formHelperId: null,
  total: null,
});

export default function careGuideInvitations(
  state=initialState,
  action
) {
  Logger.log('debug', `[state.careGuideInvitations.reducers] careGuideInvitations(%j, %j)`, state, action);

  switch(action.type) {

    case TYPES.LIST_REQUEST:
      return state.mergeDeep({
        isLoading: true
      });

    case TYPES.LIST_SUCCESS:
      return state.mergeDeep({
        isLoading: false,
        results: {
          [action.careGuideId]: {
            total: action.total,
            pages: {
              [action.order]: {
                [action.limit]: {
                  [action.page]: null
                }
              }
            }
          }
        },
        lastUpdated: action.receivedAt
      }).setIn(['results', action.careGuideId, 'pages', action.order, action.limit, action.page], List(action.result));

    case TYPES.LIST_FAILURE:
      return state.mergeDeep({
        isLoading: false
      });

    case TYPES.CREATE_REQUEST:
      return state.mergeDeep({
        form: {
          isSubmitting: true,
          success: null,
          errors: null
        }
      });

    case TYPES.CREATE_SUCCESS:
      return state.mergeDeep({
        form: {
          isSubmitting: false,
          success: true,
          errors: null
        },
        lastUpdated: action.receivedAt
      });

    case TYPES.CREATE_FAILURE:
      return state.mergeDeep({
        form: {
          isSubmitting: false,
          success: false,
          errors: null
        }
      }).setIn(['form', 'errors'], action.error);
    
    case TYPES.PATCH_REQUEST:
      return state.mergeDeep({
        form: {
          isSubmitting: true,
          success: null,
          errors: null
        }
      });

    case TYPES.PATCH_SUCCESS:
      return state.mergeDeep({
        form: {
          isSubmitting: false,
          success: true,
          errors: null
        },
        lastUpdated: action.receivedAt
      });

    case TYPES.PATCH_FAILURE:
      return state.mergeDeep({
        form: {
          isSubmitting: false,
          success: false,
          errors: null
        }
      }).setIn(['form', 'errors'], action.error);

    case TYPES.DELETE_REQUEST:
      return state.mergeDeep({
        isLoading: true,
        lastUpdated: action.receivedAt
      });

    case TYPES.DELETE_SUCCESS:
      return state.mergeDeep({
        isLoading: false
      });

    case TYPES.DELETE_FAILURE:
      return state.mergeDeep({
        isLoading: false
      });

    case TYPES.RESEND_REQUEST:
      return state.mergeDeep({
        isResending: true
      });

    case TYPES.RESEND_SUCCESS:
      return state.mergeDeep({
        isResending: false
      });

    case TYPES.RESEND_FAILURE:
      return state.mergeDeep({
        isResending: false
      });

    case TYPES.RESPOND_REQUEST:
      return state.mergeDeep({
        isResponding: true
      });

    case TYPES.RESPOND_SUCCESS:
      return state.mergeDeep({
        isResponding: false
      });

    case TYPES.RESPOND_FAILURE:
      return state.mergeDeep({
        isResponding: false
      });

    case TYPES.USER_LIST_REQUEST:
      return state.mergeDeep({
        isLoading: true
      });

    case TYPES.USER_LIST_SUCCESS:
      return state.mergeDeep({
        isLoading: false,
        pages: {
          [action.order]: {
            [action.limit]: {
              [action.page]: null
            }
          }
        },
        total: action.total,
        lastUpdated: action.receivedAt
      }).setIn(['pages', action.order, action.limit, action.page], List(action.result));

    case TYPES.USER_LIST_FAILURE:
      return state.mergeDeep({
        isLoading: false
      });

    case TYPES.FORM_DESTROY:
      return state.mergeDeep({
        form: {}
      }).set('form', action.form);

    case TYPES.SHOW_FORM:
      return state.mergeDeep({
        formIsVisible: true,
        formHelperId: action.helperId,
      });

    case TYPES.HIDE_FORM:
      return state.mergeDeep({
        formIsVisible: false,
        formHelperId: null,
      });

    case SESSION_DESTROY:
      return initialState;

    default:
      return state;
  }
}

Logger.log('silly', `state.careGuideInvitations.reducers loaded.`);
