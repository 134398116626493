import React from 'react';
import { Translation } from 'react-i18next';
import { Button, Space } from 'antd';
import { LeftOutlined, PlusOutlined, RightOutlined } from '@ant-design/icons';

import hasPermission, { CREATE_CALENDAR_EVENT } from '../../../elements/lib/hasPermission';
import useWindowDimensions from '../../../../../lib/WindowDimensions';


const CalendarToolbar = props => {

  const { width } = useWindowDimensions();
  const isMedium = width > 992;

  if (isMedium) {

    return (
      <Translation>{(t) =>
        <div className="cv-rbc-toolbar">

          <div className="cv-rbc-btn-group">
            <Space size="middle">

              <div className="ant-btn-group">
                <Button
                  icon={<LeftOutlined />}
                  onClick={() => props.onNavigate('PREV')}
                />
                <Button
                  icon={<RightOutlined />}
                  onClick={() => props.onNavigate('NEXT')}
                />
              </div>

              <Button
                onClick={() => props.onNavigate('TODAY')}
              >
                {t('calendar_toolbar_btn_today')}
              </Button>

              {hasPermission(CREATE_CALENDAR_EVENT)
                ? <Button
                  icon={<PlusOutlined />}
                  onClick={() => props.showForm()}
                >
                  {t('calendar_toolbar_btn_event_create')}
                </Button>
                : null}

            </Space>
          </div>

          <div className="cv-rbc-toolbar-label">
            {props.label}
          </div>

          <div className="cv-rbc-btn-group">
            <div className="ant-btn-group">
              <Button
                type={props.view === 'month' ? 'primary' : 'default'}
                onClick={() => props.onView('month')}
              >
                {t('calendar_toolbar_btn_month')}
              </Button>
              <Button
                type={props.view === 'week' ? 'primary' : 'default'}
                onClick={() => props.onView('week')}
              >
                {t('calendar_toolbar_btn_week')}
              </Button>
              <Button
                type={props.view === 'day' ? 'primary' : 'default'}
                onClick={() => props.onView('day')}
              >
                {t('calendar_toolbar_btn_day')}
              </Button>
            </div>
          </div>

        </div>
      }</Translation>
    );

  } else {

    return (
      <Translation>{(t) =>
        <div className="cv-rbc-toolbar-mobile">

          <div className="cv-rbc-mobile-header">

            <Button
              icon={<PlusOutlined />}
              onClick={() => props.showForm()}
            />

            <div className="cv-rbc-toolbar-label">
              {props.label}
            </div>

          </div>

          <div className="cv-rbc-btn-group">

            <div className="ant-btn-group">
              <Button
                icon={<LeftOutlined />}
                onClick={() => props.onNavigate('PREV')}
              />
              <Button
                icon={<RightOutlined />}
                onClick={() => props.onNavigate('NEXT')}
              />
            </div>

            <div className="cv-rbc-btn-group">
              <div className="ant-btn-group">
                <Button
                  type={props.view === 'month' ? 'primary' : 'default'}
                  onClick={() => props.onView('month')}
                >
                  {t('calendar_toolbar_btn_month')}
                </Button>
                <Button
                  type={props.view === 'week' ? 'primary' : 'default'}
                  onClick={() => props.onView('week')}
                >
                  {t('calendar_toolbar_btn_week')}
                </Button>
                <Button
                  type={props.view === 'day' ? 'primary' : 'default'}
                  onClick={() => props.onView('day')}
                >
                  {t('calendar_toolbar_btn_day')}
                </Button>
              </div>
            </div>

          </div>

        </div>
      }</Translation>
    );

  }
}

export default CalendarToolbar;
