import {connect} from 'react-redux';
import {List} from 'immutable';
import moment from 'moment';

import {
  loadRegistrationCode,
  updateRegistrationCode,
  deleteRegistrationCode,
  registrationCodeFormDestroy
} from '../../../../../state/modules/registrationCodes/actions';
import RegistrationCodeForm from '../components/RegistrationCodeForm';
import Config from '../../../../../Config';
import storage from "../../../../../lib/Storage";

const inputs = List([
  'code',
  'code_alias',
  'plan',
  'status',
  'partnership',
  'type_user',
  'limit_to_use',
  'available_to_use'
]);

const mapStateToProps = (state, ownProps) => {

  const data = {}, errors = {};
  for (const val of inputs.values()) {
    data[val] = state.entities.getIn(['registrationCodes', ownProps.id, val], '');
    if (['plan', 'partnership'].includes(val)) {
      errors[val] = state.registrationCodes.getIn(['form', 'errors', val + '_id']);
    }
    else {
      errors[val] = state.registrationCodes.getIn(['form', 'errors', val]);
    }
  }

  // change select number values to strings to work with ant design select inputs
  Object.keys(data).forEach(x => {
    if (['status', 'partnership'].includes(x)) {
      data[x] = data[x] ? data[x].toString() : '';
    }
    if (['limit_to_use'].includes(x)) {
      data[x] = data[x] ? parseInt(data[x], 10) : 1;
    }
    if (['available_to_use'].includes(x)) {
      data[x] = data[x] ? parseInt(data[x], 10) : 0;
    }
    if (['type_user'].includes(x)) {
      data[x] = data[x] ? parseInt(data[x], 10) : 4;
    }
  });

  data['expires_at'] = data['expires_at']
    ? moment(data['expires_at'], Config.get('API_DATETIME_FORMAT'))
    // : moment();
    : null;

  return {
    isLoading: state.registrationCodes.get('isLoading'),
    isSubmitting: state.registrationCodes.getIn(['form', 'isSubmitting']),
    success: state.registrationCodes.getIn(['form', 'success']),
    created_id: state.registrationCodes.getIn(['form', 'created_id']),
    data: data,
    errors: errors,
    createdAt: state.entities.getIn(['registrationCodes', ownProps.id, 'created_at'], null),
    updatedAt: state.entities.getIn(['registrationCodes', ownProps.id, 'updated_at'], null),
    activeId: storage.get('local', 'partnershipId'),
  }
}

const mapDispatchToProps = dispatch => {
  return {
    load: (partnership_id, id, cb) => {
      dispatch(loadRegistrationCode(partnership_id, id, cb));
    },
    update: (id, data, cb) => {
      dispatch(updateRegistrationCode(id, data, cb));
    },
    delete: (partnership_id, id, cb) => {
      dispatch(deleteRegistrationCode(partnership_id, id, cb));
    },
    destroyForm: (formState) => {
      dispatch(registrationCodeFormDestroy(formState));
    },
  }
}

const RegistrationCodeFormContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(RegistrationCodeForm);

export default RegistrationCodeFormContainer;
