import React, {Component} from 'react';
import {Translation} from 'react-i18next';
import {Link} from 'react-router-dom';
import {Button, Card, Col, Row} from 'antd';
import moment from 'moment';

import CareGuideDisabled from '../../careGuides/components/CareGuideDisabled';
import CareGuideWrapper from '../../../layouts/components/CareGuideWrapper';
import DocumentHead from '../../../elements/components/DocumentHead';
import JournalPostForm from '../../journalPosts/containers/JournalPostFormContainer';
import JournalPostModalForm from '../../journalPosts/containers/JournalPostModalFormContainer';
import JournalReplyModalForm from '../../journalReplies/containers/JournalReplyModalFormContainer';
import JournalPostsList from '../../journalPosts/containers/JournalPostsListContainer';
import EventList from '../../calendar/containers/EventListContainer';
import CareGuideHelpersQuickList from '../../careGuideHelpers/containers/CareGuideHelpersQuickListContainer';
import InviteButton from '../../careGuideInvitations/containers/InviteButtonContainer';
import CareGuideInvitationsQuickList from '../../careGuideHelpers/containers/CareGuideInvitationsQuickListContainer';
import CareGuideInvitationForm from '../../careGuideInvitations/containers/CareGuideInvitationFormContainer';
import InvitationsList from '../../careGuideInvitations/containers/InvitationsListContainer';
import EventInvitationsList from '../../calendar/containers/EventInvitationsListContainer';
import Loading from '../../../elements/components/Loading';
import LoadingIcon from '../../../elements/components/LoadingIcon';
import scrollToTop from '../../../elements/lib/scrollToTop';
import hasPermission, { CREATE_JOURNAL_POST, CREATE_CARE_GUIDE_INVITATION, VIEW_ACCESS_DASHBOARD } from '../../../elements/lib/hasPermission';
import {pathTo} from '../../../Routes';
import Config from '../../../../../Config';
import Logger from '../../../../../lib/Logger';
import { Redirect } from "react-router-dom";
import { hasPermissionCareGuide, pathRedirectCareGuide } from '../../../elements/lib/hasPermissionCareGuide';


class DashboardDetailScreen extends Component {

  static defaultProps = {
    limit: Config.get('DEFAULT_LIST_LENGTH', 10),
    areJournalsLoading: true,
  }

  constructor(props) {
    super(props);
    this.state = {
      journalFormIsVisible: false,
    };
  }

  toggleJournalForm = () => {
    this.setState({
      journalFormIsVisible: !this.state.journalFormIsVisible
    });
  }

  render() {
    const {careGuideId, page, limit, areJournalsLoading, isCareGuideActive, isCareGuideLoading} = this.props;

    if (isCareGuideLoading) {
      return (
        <Translation>{(t) => 
          <CareGuideWrapper>
            <DocumentHead title={t('route_dashboard')} />
            <Loading />
          </CareGuideWrapper>
        }</Translation>
      );
    }

    if(isCareGuideActive === null){
      return (
        <Redirect to={pathTo("HomeScreen")} />
      );
    }

    if(!hasPermission(VIEW_ACCESS_DASHBOARD)){
      return (
      <Redirect to={pathTo(pathRedirectCareGuide(hasPermissionCareGuide()), {id: careGuideId})} />
      )}

    if (!isCareGuideActive) {
      return (
        <Translation>{(t) => 
          <CareGuideWrapper>

            <DocumentHead title={t('route_dashboard')} />

            <Row gutter={16}>

              <Col
                lg={{span: 16, order: 1}}
                md={{span: 24, order: 2}}
                sm={{span: 24, order: 2}}
                xs={{span: 24, order: 2}}
                className="dashboard-content"
              >
                <CareGuideDisabled careGuideId={careGuideId} />
              </Col>

              <Col
                lg={{span: 8, order: 2}}
                md={{span: 24, order: 1}}
                sm={{span: 24, order: 1}}
                xs={{span: 24, order: 1}}
                className="content-sidebar"
              >
                <InvitationsList
                  page={1}
                  limit={5}
                  order={'invited_at.desc'}
                />
              </Col>

            </Row>

          </CareGuideWrapper>
        }</Translation>
      );
    }

    return (
      <Translation>{(t) => 
        <CareGuideWrapper>

          <DocumentHead title={t('route_dashboard')} />

          <Row gutter={16}>

            <Col
              lg={{span: 16, order: 1}}
              md={{span: 24, order: 2}}
              sm={{span: 24, order: 2}}
              xs={{span: 24, order: 2}}
              className="dashboard-content"
            >

              <h2 className="section-header">{t('journal_posts_title')}</h2>

              {hasPermission(CREATE_JOURNAL_POST)
                ? <Card>
                    <JournalPostForm
                      careGuideId={careGuideId}
                      page={page}
                      limit={limit}
                      order={'published_at.desc'}
                    />
                  </Card>
                : null}

              <JournalPostsList
                key={page}
                component="DashboardDetailScreen"
                careGuideId={careGuideId}
                page={page}
                limit={limit}
                order={'published_at.desc'}
              />
                
            </Col>

            <Col
              lg={{span: 8, order: 2}}
              md={{span: 24, order: 1}}
              sm={{span: 24, order: 1}}
              xs={{span: 24, order: 1}}
              className="content-sidebar"
            >

              <InvitationsList
                page={1}
                limit={5}
                order={'invited_at.desc'}
              />

              <>
              <EventInvitationsList
                careGuideId={careGuideId}
                start={moment().format("YYYY-MM-DD")}
                page={1}
                limit={5}
                order={'start_at.asc'}
              />
 
              <EventList
                careGuideId={careGuideId}
                start={moment().format("YYYY-MM-DD")}
                end={moment().add(1, 'Y').format("YYYY-MM-DD")}
                page={1}
                limit={3}
                order={'start_at.asc'}
              />
              </>
            

              <CareGuideInvitationsQuickList
                careGuideId={careGuideId}
                page={1}
                limit={5}
                order={'joined_at.asc'}
              />

              <Card
                title={t('dashboard_care_guide_helper_list_title')}
                extra={areJournalsLoading ? <LoadingIcon size={16} /> : null}
                size="small"
                bordered={false}
              >
                <CareGuideHelpersQuickList
                  careGuideId={careGuideId}
                  page={1}
                  limit={5}
                  order={'joined_at.asc'}
                />
                <Button>
                  <Link to={pathTo('CareTeamScreen', {id: careGuideId})}>{t('dashboard_care_guide_helper_btn_view_helpers')}</Link>
                </Button>
                {hasPermission(CREATE_CARE_GUIDE_INVITATION)
                  ? <InviteButton
                      careGuideId={careGuideId}
                      text={t('dashboard_care_guide_helper_btn_invite')}
                      style={{float: 'right'}}
                    />
                  : null}
              </Card>

            </Col>

          </Row>

          <CareGuideInvitationForm
            careGuideId={careGuideId}
          />

          <JournalPostModalForm
            careGuideId={careGuideId}
            page={page}
            limit={limit}
            order={'published_at.desc'}
          />

          <JournalReplyModalForm
            careGuideId={careGuideId}
          />
          
        </CareGuideWrapper>
      }</Translation>
    )
  }

  /* LIFECYCLE EVENTS */

  componentDidMount() {
    scrollToTop();
  }
}

export default DashboardDetailScreen;

Logger.log('silly', `DashboardDetailScreen loaded.`);
