import {connect} from 'react-redux';

import JournalPartnerTagAdminAddScreen from '../components/JournalPartnerTagAdminAddScreen';

const mapStateToProps = state => {
  return {
    isLoading: state.journalPartnerTagsAdmin.get('isLoading'),
    success: state.journalPartnerTagsAdmin.getIn(['form', 'success']),
  }
}

const JournalPartnerTagAdminAddScreenContainer = connect(
  mapStateToProps
)(JournalPartnerTagAdminAddScreen);

export default JournalPartnerTagAdminAddScreenContainer;