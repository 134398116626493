import {connect} from 'react-redux';

import CareGuidePartnerTagAdminEditScreen from '../components/CareGuidePartnerTagAdminEditScreen';

const mapStateToProps = state => {
  return {
    isLoading: state.careGuidePartnerTagsAdmin.get('isLoading'),
    success: state.careGuidePartnerTagsAdmin.getIn(['form', 'success']),
  }
}

const CareGuidePartnerTagAdminEditScreenContainer = connect(
  mapStateToProps
)(CareGuidePartnerTagAdminEditScreen);

export default CareGuidePartnerTagAdminEditScreenContainer;