import React, { useState, useEffect, createRef } from 'react';
import { Redirect } from 'react-router-dom';
import { Translation, getI18n } from 'react-i18next';
import { Button, Checkbox, Form, InputNumber } from 'antd';
import { CheckCircleOutlined } from '@ant-design/icons';

import FirstNameInput from './FirstNameInput';
import LastNameInput from './LastNameInput';
import EmailAddressInput from './EmailAddressInput';
import TelephoneInput from './TelephoneInput';
import UserNameInput from './UserNameInput';
import InviteCodeInput from './InviteCodeInput';
import RegistrationCodeInput from './RegistrationCodeInput';
import PasswordInput from '../../userAccount/components/PasswordInput';
import PasswordConfirmInput from '../../userAccount/components/PasswordConfirmInput';
import { pathTo } from '../../../Routes';
import message from '../../../elements/lib/MessageWrapper';
import Config from '../../../../../Config';
import Logger from '../../../../../lib/Logger';
import useWindowDimensions from '../../../../../lib/WindowDimensions';

const RegisterStep1Form = (props) => {
  const [redirectTo, setRedirectTo] = useState(null);
  const [firstNameHasError, setFirstNameHasError] = useState(false);
  const [lastNameHasError, setLastNameHasError] = useState(false);
  const [emailHasError, setEmailHasError] = useState(false);
  const [telephoneHasError, setTelephoneHasError] = useState(false);
  const [usernameHasError, setUsernameHasError] = useState(false);
  const [passwordHasError, setPasswordHasError] = useState(false);
  const [password2HasError, setPassword2HasError] = useState(false);
  const [inviteCodeHasError, setInviteCodeHasError] = useState(false);
  const [registrationCodeHasError, setRegistrationCodeHasError] =
    useState(false);

  const [form] = Form.useForm();
  const inputRegistrationCodeRef = createRef();
  const { width } = useWindowDimensions();
  const tooltipPlacement = width <= 992 ? 'top' : 'right';

  // handle errors reported by API
  useEffect(() => {
    let firstFieldName = '';
    for (const field in props.errors) {
      form.setFields([{ name: field, errors: props.errors[field] }]);

      switch (field) {
        case 'first_name':
          setFirstNameHasError(true);
          break;
        case 'last_name':
          setLastNameHasError(true);
          break;
        case 'email':
          setEmailHasError(true);
          break;
        case 'telephone':
          setTelephoneHasError(true);
          break;
        case 'username':
          setUsernameHasError(true);
          break;
        case 'password':
          setPasswordHasError(true);
          break;
        case 'password2':
          setPassword2HasError(true);
          break;
        case 'invite_code':
          setInviteCodeHasError(true);
          break;
        case 'registration_code':
          setRegistrationCodeHasError(true);
          break;
        default:
      }

      if (firstFieldName === '') {
        firstFieldName = field;
      }
    }
    form.scrollToField(firstFieldName);
  }, [form, props.errors]);

  const submitData = (values) => {
    Logger.log('debug', `RegisterStep1Form.submit()`);

    // API POST/PUT payload
    let payload = {};
    for (const input of Object.keys(props.data)) {
      // remove any non digit characters from strings
      if (['telephone'].includes(input) && values[input] !== null) {
        payload[input] = values[input].replace(/\D/g, '');
      } else if (['agree_reminders_updates'].includes(input)) {
        payload[input] = values[input] ? true : false;
      }
      // registration_code is acting strange in chrome in prod...
      else if (['registration_code'].includes(input)) {
        if (values[input] !== null && values[input] !== '') {
          payload['registration_code_id'] = values[input];
        }
      } else if (values[input]) {
        payload[input] = values[input];
      }
    }

    /*     if (values["tos_id"]) {
      payload["tos_id"] = props.tos_id;
    }
     */
    payload['tos_id'] = props.tos_id;

    // add plan/subscription data to payload if available
    if ('planId' in props && props.planId !== null) {
      payload['plan_id'] = props.planId;
    }
    if ('subscriptionCycle' in props && props.subscriptionCycle !== null) {
      payload['subscription_cycle'] = props.subscriptionCycle;
    }

    // handle timezone
    payload['timezone'] = 'America/Los_Angeles'; // default
    if (
      window.hasOwnProperty('Intl') &&
      Intl.DateTimeFormat &&
      Intl.DateTimeFormat().resolvedOptions
    ) {
      payload['timezone'] = Intl.DateTimeFormat().resolvedOptions().timeZone;
    }

    // register
    props.submit(
      payload,
      (success, registrationId, registrationCode, partnershipId) => {
        if (success) {
          if (props.inviteCode) {
            props.createAccount(
              { id: registrationId },
              (createAccountSuccess, careGuideId) => {
                if (createAccountSuccess) {
                  message.success(
                    getI18n().t('register_form1_message_invite_success')
                  );
                  setRedirectTo(pathTo(Config.get('DEFAULT_LOGIN_REDIRECT')));
                } else {
                  message.error(getI18n().t('register_form1_message_failure'));
                }
              }
            );
          } else {
            if (registrationCode) {
              switch (registrationCode) {
                case 1:
                  props.createAccountOwner(
                    { id: registrationId },
                    (createAccountSuccess) => {
                      if (createAccountSuccess) {
                        message.success(
                          getI18n().t('register_owner_message_success')
                        );
                        setRedirectTo(
                          pathTo(Config.get('DEFAULT_REGISTRATION_REDIRECT'))
                        );
                      } else {
                        message.error(
                          getI18n().t('register_owner_message_failure')
                        );
                      }
                    }
                  );
                  break;
                case 2:
                  props.createAccountAdmin(
                    { id: registrationId },
                    (createAccountSuccess) => {
                      if (createAccountSuccess) {
                        message.success(
                          getI18n().t('register_admin_message_success')
                        );
                        setRedirectTo(
                          pathTo(Config.get('DEFAULT_REGISTRATION_REDIRECT'))
                        );
                      } else {
                        message.error(
                          getI18n().t('register_admin_message_failure')
                        );
                      }
                    }
                  );
                  break;
                case 3:
                  setRedirectTo(
                    pathTo('RegisterStep2Screen', null, {
                      rid: registrationId,
                      role: registrationCode,
                      pid: partnershipId,
                    })
                  );
                  break;
                case 4:
                  setRedirectTo(
                    pathTo('RegisterStep2Screen', null, { rid: registrationId })
                  );
                  break;
                default:
                  setRedirectTo(
                    pathTo('RegisterStep2Screen', null, { rid: registrationId })
                  );
                  break;
              }
            } else {
              setRedirectTo(
                pathTo('RegisterStep2Screen', null, { rid: registrationId })
              );
            }
          }
        } else {
          message.error(getI18n().t('register_form1_message_failure'));
        }
      }
    );
  };

  // form submit handler
  const handleFinish = async (values) => {
    Logger.log('debug', `RegisterStep1Form.handleFinish(###)`);
    if (!props.isSubmitting) {
      await submitData(values);
    }
  };

  // form error handler
  const handleFinishFailed = ({ values, errorFields, outOfDate }) => {
    Logger.log('debug', `RegisterStep1Form.RegisterStep1Form(###)`);
    message.error(getI18n().t('register_form1_message_failure'));
    if (errorFields && errorFields.length > 0) {
      form.scrollToField(errorFields[0].name);

      for (const field of errorFields) {
        switch (field.name[0]) {
          case 'first_name':
            setFirstNameHasError(true);
            break;
          case 'last_name':
            setLastNameHasError(true);
            break;
          case 'email':
            setEmailHasError(true);
            break;
          case 'telephone':
            setTelephoneHasError(true);
            break;
          case 'username':
            setUsernameHasError(true);
            break;
          case 'password':
            setPasswordHasError(true);
            break;
          case 'password2':
            setPassword2HasError(true);
            break;
          case 'invite_code':
            setInviteCodeHasError(true);
            break;
          case 'registration_code':
            setRegistrationCodeHasError(true);
            break;
          default:
        }
      }
    }
  };

  // remove error message when input value changes
  const handleValuesChange = (changedValues, allValues) => {
    for (const key of Object.keys(changedValues)) {
      form.setFields([{ name: key, errors: [] }]);
      switch (key) {
        case 'first_name':
          setFirstNameHasError(false);
          break;
        case 'last_name':
          setLastNameHasError(false);
          break;
        case 'email':
          setEmailHasError(false);
          break;
        case 'telephone':
          setTelephoneHasError(false);
          break;
        case 'username':
          setUsernameHasError(false);
          break;
        case 'password':
          setPasswordHasError(false);
          break;
        case 'password2':
          setPassword2HasError(false);
          break;
        case 'invite_code':
          setInviteCodeHasError(false);
          break;
        case 'registration_code':
          setRegistrationCodeHasError(false);
          break;
        default:
      }
    }
  };

  if (redirectTo) {
    return <Redirect to={redirectTo} />;
  }

  return (
    <Translation>
      {(t) => (
        <div className="register-form register-form-step1">
          <Form
            layout="vertical"
            name="register_step1_form"
            form={form}
            onFinish={handleFinish}
            onFinishFailed={handleFinishFailed}
            onValuesChange={handleValuesChange}
            validateTrigger="onSubmit"
            initialValues={props.data}
          >
            {props.inviteCode ? (
              <InviteCodeInput
                name="invite_code"
                label={t('register_form1_input_invite_code')}
                disabled={props.isSubmitting}
                useTooltip={false}
                tooltipPlacement={tooltipPlacement}
                hasError={inviteCodeHasError}
                form={form}
              />
            ) : (
              <>
                {Config.get('REQUIRE_REGISTRATION_CODE') ? (
                  <RegistrationCodeInput
                    name="registration_code"
                    label={t('register_form1_input_registration_code')}
                    disabled={props.isSubmitting}
                    useTooltip={false}
                    tooltipPlacement={tooltipPlacement}
                    hasError={registrationCodeHasError}
                    rules={[
                      {
                        required: true,
                        message: t('feedback_validation_required'),
                      },
                      {
                        min: 5,
                        max: 20,
                        message: t('feedback_validation_length', {
                          min: 5,
                          max: 20,
                        }),
                      },
                    ]}
                    form={form}
                    reference={inputRegistrationCodeRef}
                  />
                ) : (
                  <RegistrationCodeInput
                    rules={[
                      {
                        min: 5,
                        max: 20,
                        message: t('feedback_validation_length', {
                          min: 5,
                          max: 20,
                        }),
                      },
                      {
                        required: true,
                        message: t('feedback_validation_required'),
                      },
                    ]}
                    name="registration_code"
                    label={t('register_form1_panel_registration_code')}
                    disabled={props.isSubmitting}
                    useTooltip={false}
                    tooltipPlacement={tooltipPlacement}
                    hasError={registrationCodeHasError}
                    form={form}
                    reference={inputRegistrationCodeRef}
                  />
                )}
              </>
            )}

            {props.inviteId ? (
              <Form.Item name="invite_id" className="hidden-input">
                <InputNumber type="hidden" />
              </Form.Item>
            ) : null}

            {props.inviteCode ? (
              <div className="form-group">
                <Form.Item
                  name="tos_id"
                  valuePropName="checked"
                  className="tos-row"
                  rules={[
                    {
                      required: true,
                      transform: (value) => value || undefined,
                      type: 'boolean',
                      message: t('feedback_validation_tos'),
                    },
                  ]}
                >
                  <Checkbox disabled={props.isSubmitting}>
                    {t('register_form1_input_tos')}{' '}
                    <span className="required-mark">*</span>
                  </Checkbox>
                </Form.Item>
              </div>
            ) : null}

            <FirstNameInput
              name="first_name"
              label={t('register_form1_input_first_name')}
              disabled={props.isSubmitting}
              autoFocus
              useTooltip={false}
              tooltipPlacement={tooltipPlacement}
              hasError={firstNameHasError}
            />

            <LastNameInput
              name="last_name"
              label={t('register_form1_input_last_name')}
              disabled={props.isSubmitting}
              useTooltip={false}
              tooltipPlacement={tooltipPlacement}
              hasError={lastNameHasError}
            />

            <EmailAddressInput
              name="email"
              label={t('register_form1_input_email_address')}
              disabled={props.isSubmitting}
              useTooltip={false}
              tooltipPlacement={tooltipPlacement}
              hasError={emailHasError}
            />

            <TelephoneInput
              name="telephone"
              label={t('register_form1_input_telephone')}
              disabled={props.isSubmitting}
              useTooltip={false}
              tooltipPlacement={tooltipPlacement}
              hasError={telephoneHasError}
            />

            <UserNameInput
              name="username"
              label={t('register_form1_input_username')}
              disabled={props.isSubmitting}
              useTooltip={true}
              tooltipPlacement={tooltipPlacement}
              hasError={usernameHasError}
            />

            <PasswordInput
              name="password"
              label={t('register_form1_input_password1')}
              disabled={props.isSubmitting}
              useTooltip={true}
              tooltipPlacement={tooltipPlacement}
              hasError={passwordHasError}
            />

            <PasswordConfirmInput
              name="password2"
              label={t('register_form1_input_password2')}
              disabled={props.isSubmitting}
              hasError={password2HasError}
              form={form}
            />

            <div className="form-group">
              <Form.Item
                name="agree_reminders_updates"
                valuePropName="checked"
                className="tos-row"
                rules={[
                  {
                    required: true,
                    transform: (value) => value || undefined,
                    type: 'boolean',
                    message: t('feedback_validation_reminders_updates'),
                  },
                ]}
              >
                <Checkbox
                  disabled={props.isSubmitting}
                  style={{ textAlign: 'justify' }}
                >
                  {t('register_form1_reminders_updates')}
                  <span className="required-mark">*</span>
                </Checkbox>
              </Form.Item>
            </div>

            <div className="form-actions">
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="login-button btn-large-round"
                  icon={<CheckCircleOutlined />}
                  loading={props.isSubmitting}
                  size="large"
                  block
                >
                  {t('register_form1_button_submit')}
                </Button>
              </Form.Item>
            </div>
          </Form>
        </div>
      )}
    </Translation>
  );
};

export default RegisterStep1Form;
