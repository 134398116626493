import { connect } from 'react-redux';
import { denormalize } from 'normalizr';
import { List } from 'immutable';

import ResourceNotesListAdmin from '../components/ResourceNotesListAdmin';
import { schema } from '../../../../../state/schema';
import { loadResourceNotesAdmin } from '../../../../../state/modules/resourceNotes/actions';

const mapStateToProps = (state, ownProps) => {
  const partnershipId = ownProps.partnershipId;
  const resourceId = ownProps.resourceId;
  const page = ownProps.page;
  const limit = ownProps.limit;
  const order = ownProps.order;

  let result = List();
  for (let i = 1; i <= page; i++) {
    result = result.merge(
      state.resourceNotes.getIn([
        'results',
        partnershipId,
        resourceId,
        'pages',
        order,
        limit,
        i,
      ])
    );
  }

  const profileId = state.session.get('profileId');

  return {
    list: result
      ? result
          .map((x) => {
            return {
              key: x,
              ...denormalize(
                state.entities.getIn(['resourceNotes', x]),
                schema.resourceNote,
                state.entities.toJS()
              ),
            };
          })
          .toArray()
          .filter((x) => x.key) // for some reason there's an empty result, remove
          .filter((v, i, a) => a.findIndex((t) => t.key === v.key) === i) // dedupe - sometimes merging pagination results have multiples of same
          .map((x) => {
            // flatten some nested objects to make table easier to work with
            return {
              profile_name: x.profile
                ? x.profile.first_name + ' ' + x.profile.last_name
                : '',
              ...x,
            };
          })
      : [],
    total: state.resourceNotes.getIn(
      ['results', partnershipId, resourceId, 'total'],
      0
    ),
    isLoading: state.resourceNotes.get('isLoading'),
    profileId: profileId,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    load: (partnershipId, resourceId, page, limit, order, filter, cb) => {
      dispatch(
        loadResourceNotesAdmin(
          partnershipId,
          resourceId,
          page,
          limit,
          order,
          filter,
          cb
        )
      );
    },
    loadAll: (
      partnershipId,
      resourceId,
      page,
      limit,
      order,
      filter,
      cb = function () {}
    ) => {
      dispatch(
        loadResourceNotesAdmin(
          partnershipId,
          resourceId,
          page,
          limit,
          order,
          filter,
          (success, total) => {
            if (success) {
              // loop over all pages to get all replies
              const pageCount = Math.ceil(total / limit);
              for (var i = pageCount - 1; i >= 1; i--) {
                dispatch(
                  loadResourceNotesAdmin(
                    partnershipId,
                    resourceId,
                    i,
                    limit,
                    order,
                    filter
                  )
                );
              }
              cb();
            }
          }
        )
      );
    },
  };
};

const ResourceNotesListAdminContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ResourceNotesListAdmin);

export default ResourceNotesListAdminContainer;
