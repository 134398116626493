import api from '../../api';
import Logger from '../../../lib/Logger';
import { MixpanelTracker } from '../../../lib/Mixpanel';

export const USER_ACCOUNT_READ_REQUEST = 'USER_ACCOUNT_READ_REQUEST';
export const USER_ACCOUNT_READ_SUCCESS = 'USER_ACCOUNT_READ_SUCCESS';
export const USER_ACCOUNT_READ_FAILURE = 'USER_ACCOUNT_READ_FAILURE';
export const USER_ACCOUNT_UPDATE_REQUEST = 'USER_ACCOUNT_UPDATE_REQUEST';
export const USER_ACCOUNT_UPDATE_SUCCESS = 'USER_ACCOUNT_UPDATE_SUCCESS';
export const USER_ACCOUNT_UPDATE_FAILURE = 'USER_ACCOUNT_UPDATE_FAILURE';
export const USER_ACCOUNT_UPDATE_AVATAR_REQUEST =
  'USER_ACCOUNT_UPDATE_AVATAR_REQUEST';
export const USER_ACCOUNT_UPDATE_AVATAR_SUCCESS =
  'USER_ACCOUNT_UPDATE_AVATAR_SUCCESS';
export const USER_ACCOUNT_UPDATE_AVATAR_FAILURE =
  'USER_ACCOUNT_UPDATE_AVATAR_FAILURE';
export const USER_ACCOUNT_DELETE_AVATAR_REQUEST =
  'USER_ACCOUNT_DELETE_AVATAR_REQUEST';
export const USER_ACCOUNT_DELETE_AVATAR_SUCCESS =
  'USER_ACCOUNT_DELETE_AVATAR_SUCCESS';
export const USER_ACCOUNT_DELETE_AVATAR_FAILURE =
  'USER_ACCOUNT_DELETE_AVATAR_FAILURE';
export const USER_ACCOUNT_FORM_DESTROY = 'USER_ACCOUNT_FORM_DESTROY';
export const PASSWORD_UPDATE_REQUEST = 'PASSWORD_UPDATE_REQUEST';
export const PASSWORD_UPDATE_SUCCESS = 'PASSWORD_UPDATE_SUCCESS';
export const PASSWORD_UPDATE_FAILURE = 'PASSWORD_UPDATE_FAILURE';
export const PASSWORD_FORM_DESTROY = 'PASSWORD_FORM_DESTROY';
export const USER_ACCOUNT_ALERTS_UPDATE_REQUEST =
  'USER_ACCOUNT_ALERTS_UPDATE_REQUEST';
export const USER_ACCOUNT_ALERTS_UPDATE_SUCCESS =
  'USER_ACCOUNT_ALERTS_UPDATE_SUCCESS';
export const USER_ACCOUNT_ALERTS_UPDATE_FAILURE =
  'USER_ACCOUNT_ALERTS_UPDATE_FAILURE';
export const USER_ACCOUNT_ALERTS_FORM_DESTROY =
  'USER_ACCOUNT_ALERTS_FORM_DESTROY';
export const USER_ACCOUNT_2FA_UPDATE_REQUEST =
  'USER_ACCOUNT_2FA_UPDATE_REQUEST';
export const USER_ACCOUNT_2FA_UPDATE_SUCCESS =
  'USER_ACCOUNT_2FA_UPDATE_SUCCESS';
export const USER_ACCOUNT_2FA_UPDATE_FAILURE =
  'USER_ACCOUNT_2FA_UPDATE_FAILURE';
export const USER_ACCOUNT_2FA_FORM_DESTROY = 'USER_ACCOUNT_2FA_FORM_DESTROY';
export const EMAIL_CONFIRMATION_REQUEST = 'EMAIL_CONFIRMATION_REQUEST';
export const EMAIL_CONFIRMATION_SUCCESS = 'EMAIL_CONFIRMATION_SUCCESS';
export const EMAIL_CONFIRMATION_FAILURE = 'EMAIL_CONFIRMATION_FAILURE';

export function userAccountReadRequest() {
  Logger.log('debug', `[state.userAccount.actions] userAccountReadRequest()`);
  return {
    type: USER_ACCOUNT_READ_REQUEST,
  };
}

export function userAccountReadSuccess(data) {
  Logger.log(
    'debug',
    `[state.userAccount.actions] userAccountReadSuccess(###)`,
    data
  );
  return {
    type: USER_ACCOUNT_READ_SUCCESS,
    id: data.id,
    username: data.username,
    email: data.email,
    password_changed_at: data.password_changed_at,
    is_verified: data.is_verified,
    is_enrolled_otp: data.is_enrolled_otp,
    one_time_password: data.one_time_password,
    profile_id: data.profile_id,
    first_name: data.first_name,
    last_name: data.last_name,
    telephone: data.telephone,
    timezone: data.timezone,
    joined_at: data.joined_at,
    alert_summary: data.alert_summary,
    alert_summary_at: data.alert_summary_at,
    alerts_in_app: data.alerts_in_app,
    alerts_email: data.alerts_email,
    alerts_sms: data.alerts_sms,
    reminders_email: data.reminders_email,
    reminders_sms: data.reminders_sms,
    avatar: data.avatar,
    receivedAt: Date.now(),
  };
}

export function userAccountReadFailure(error) {
  Logger.log(
    'debug',
    `[state.userAccount.actions] userAccountReadFailure(###)`,
    error
  );
  return {
    type: USER_ACCOUNT_READ_FAILURE,
    error: error,
  };
}

export function userAccountUpdateRequest(data) {
  Logger.log(
    'debug',
    `[state.userAccount.actions] userAccountUpdateRequest(###)`,
    data
  );
  return {
    type: USER_ACCOUNT_UPDATE_REQUEST,
    username: data.username,
    email: data.email,
    first_name: data.first_name,
    last_name: data.last_name,
    telephone: data.telephone,
    timezone: data.timezone,
    is_enrolled_otp: data.is_enrolled_otp,
    one_time_password: data.one_time_password,
  };
}

export function userAccountUpdateSuccess(data) {
  Logger.log(
    'debug',
    `[state.userAccount.actions] userAccountUpdateSuccess(###)`,
    data
  );
  return {
    type: USER_ACCOUNT_UPDATE_SUCCESS,
    id: data.id,
    username: data.username,
    email: data.email,
    password_changed_at: data.password_changed_at,
    is_verified: data.is_verified,
    is_enrolled_otp: data.is_enrolled_otp,
    one_time_password: data.one_time_password,
    profile_id: data.profile_id,
    first_name: data.first_name,
    last_name: data.last_name,
    telephone: data.telephone,
    timezone: data.timezone,
    joined_at: data.joined_at,
    alert_summary: data.alert_summary,
    alert_summary_at: data.alert_summary_at,
    alerts_in_app: data.alerts_in_app,
    alerts_email: data.alerts_email,
    alerts_sms: data.alerts_sms,
    reminders_email: data.reminders_email,
    reminders_sms: data.reminders_sms,
    avatar: data.avatar,
    receivedAt: Date.now(),
  };
}

export function userAccountUpdateFailure(error) {
  Logger.log(
    'debug',
    `[state.userAccount.actions] userAccountUpdateFailure(###)`,
    error
  );
  return {
    type: USER_ACCOUNT_UPDATE_FAILURE,
    error: error,
  };
}

export function userAccountUpdateAvatarRequest(data) {
  Logger.log(
    'debug',
    `[state.userAccount.actions] userAccountUpdateAvatarRequest(###)`,
    data
  );
  return {
    type: USER_ACCOUNT_UPDATE_AVATAR_REQUEST,
  };
}

export function userAccountUpdateAvatarSuccess(data) {
  Logger.log(
    'debug',
    `[state.userAccount.actions] userAccountUpdateAvatarSuccess(###)`,
    data
  );
  return {
    type: USER_ACCOUNT_UPDATE_AVATAR_SUCCESS,
    id: data.id,
    username: data.username,
    email: data.email,
    password_changed_at: data.password_changed_at,
    is_verified: data.is_verified,
    is_enrolled_otp: data.is_enrolled_otp,
    one_time_password: data.one_time_password,
    profile_id: data.profile_id,
    first_name: data.first_name,
    last_name: data.last_name,
    telephone: data.telephone,
    timezone: data.timezone,
    joined_at: data.joined_at,
    alert_summary: data.alert_summary,
    alert_summary_at: data.alert_summary_at,
    alerts_in_app: data.alerts_in_app,
    alerts_email: data.alerts_email,
    alerts_sms: data.alerts_sms,
    reminders_email: data.reminders_email,
    reminders_sms: data.reminders_sms,
    avatar: data.avatar,
  };
}

export function userAccountUpdateAvatarFailure(error) {
  Logger.log(
    'debug',
    `[state.userAccount.actions] userAccountUpdateAvatarFailure(###)`,
    error
  );
  return {
    type: USER_ACCOUNT_UPDATE_AVATAR_FAILURE,
    error: error,
  };
}

export function userAccountDeleteAvatarRequest() {
  Logger.log(
    'debug',
    `[state.userAccount.actions] userAccountDeleteAvatarRequest(###)`
  );
  return {
    type: USER_ACCOUNT_DELETE_AVATAR_REQUEST,
  };
}

export function userAccountDeleteAvatarSuccess(data) {
  Logger.log(
    'debug',
    `[state.userAccount.actions] userAccountDeleteAvatarSuccess()`,
    data
  );
  return {
    type: USER_ACCOUNT_DELETE_AVATAR_SUCCESS,
    id: data.id,
    username: data.username,
    email: data.email,
    password_changed_at: data.password_changed_at,
    is_verified: data.is_verified,
    is_enrolled_otp: data.is_enrolled_otp,
    one_time_password: data.one_time_password,
    profile_id: data.profile_id,
    first_name: data.first_name,
    last_name: data.last_name,
    telephone: data.telephone,
    timezone: data.timezone,
    joined_at: data.joined_at,
    alert_summary: data.alert_summary,
    alert_summary_at: data.alert_summary_at,
    alerts_in_app: data.alerts_in_app,
    alerts_email: data.alerts_email,
    alerts_sms: data.alerts_sms,
    reminders_email: data.reminders_email,
    reminders_sms: data.reminders_sms,
    avatar: data.avatar,
  };
}

export function userAccountDeleteAvatarFailure(error) {
  Logger.log(
    'debug',
    `[state.userAccount.actions] userAccountDeleteAvatarFailure(%j)`,
    error
  );
  return {
    type: USER_ACCOUNT_DELETE_AVATAR_FAILURE,
    error: error,
  };
}

export function userAccountFormDestroy(formState = null) {
  Logger.log(
    'debug',
    `[state.userAccount.actions] userAccountFormDestroy(###)`,
    formState
  );
  return {
    type: USER_ACCOUNT_FORM_DESTROY,
    form: formState,
  };
}

export function passwordUpdateRequest() {
  Logger.log('debug', `[state.userAccount.actions] passwordUpdateRequest()`);
  return {
    type: PASSWORD_UPDATE_REQUEST,
  };
}

export function passwordUpdateSuccess() {
  Logger.log('debug', `[state.userAccount.actions] passwordUpdateSuccess()`);
  return {
    type: PASSWORD_UPDATE_SUCCESS,
  };
}

export function passwordUpdateFailure(error) {
  Logger.log(
    'debug',
    `[state.userAccount.actions] passwordUpdateFailure(%j)`,
    error
  );
  return {
    type: PASSWORD_UPDATE_FAILURE,
    error: error,
  };
}

export function passwordFormDestroy(formState = null) {
  Logger.log(
    'debug',
    `[state.userAccount.actions] passwordFormDestroy(%j)`,
    formState
  );
  return {
    type: PASSWORD_FORM_DESTROY,
    form: formState,
  };
}

export function userAccountAlertsUpdateRequest(data) {
  Logger.log(
    'debug',
    `[state.userAccount.actions] userAccountAlertsUpdateRequest(###)`,
    data
  );
  return {
    type: USER_ACCOUNT_ALERTS_UPDATE_REQUEST,
    alert_summary: data.alert_summary,
    alert_summary_at: data.alert_summary_at,
    alerts_in_app: data.alerts_in_app,
    alerts_email: data.alerts_email,
    alerts_sms: data.alerts_sms,
    reminders_email: data.reminders_email,
    reminders_sms: data.reminders_sms,
  };
}

export function userAccountAlertsUpdateSuccess(data) {
  Logger.log(
    'debug',
    `[state.userAccount.actions] userAccountAlertsUpdateSuccess(###)`,
    data
  );
  return {
    type: USER_ACCOUNT_ALERTS_UPDATE_SUCCESS,
    id: data.id,
    username: data.username,
    email: data.email,
    password_changed_at: data.password_changed_at,
    is_verified: data.is_verified,
    is_enrolled_otp: data.is_enrolled_otp,
    one_time_password: data.one_time_password,
    profile_id: data.profile_id,
    first_name: data.first_name,
    last_name: data.last_name,
    telephone: data.telephone,
    timezone: data.timezone,
    joined_at: data.joined_at,
    avatar: data.avatar,
    alert_summary: data.alert_summary,
    alert_summary_at: data.alert_summary_at,
    alerts_in_app: data.alerts_in_app,
    alerts_email: data.alerts_email,
    alerts_sms: data.alerts_sms,
    reminders_email: data.reminders_email,
    reminders_sms: data.reminders_sms,
    receivedAt: Date.now(),
  };
}

export function userAccountAlertsUpdateFailure(error) {
  Logger.log(
    'debug',
    `[state.userAccount.actions] userAccountAlertsUpdateFailure(###)`,
    error
  );
  return {
    type: USER_ACCOUNT_ALERTS_UPDATE_FAILURE,
    error: error,
  };
}

export function userAccountAlertsFormDestroy(formState = null) {
  Logger.log(
    'debug',
    `[state.userAccount.actions] userAccountAlertsFormDestroy(%j)`,
    formState
  );
  return {
    type: USER_ACCOUNT_ALERTS_FORM_DESTROY,
    form: formState,
  };
}

export function userAccount2FAUpdateRequest(data) {
  Logger.log(
    'debug',
    `[state.userAccount.actions] userAccount2FAUpdateRequest(%j)`,
    data
  );
  return {
    type: USER_ACCOUNT_2FA_UPDATE_REQUEST,
    is_enrolled_otp: data.is_enrolled_otp,
  };
}

export function userAccount2FAUpdateSuccess(data) {
  Logger.log(
    'debug',
    `[state.userAccount.actions] userAccount2FAUpdateSuccess(%j)`,
    data
  );
  return {
    type: USER_ACCOUNT_2FA_UPDATE_SUCCESS,
    is_enrolled_otp: data.is_enrolled_otp,
    otp_secret: data.otp_secret,
    provisioning_uri: data.provisioning_uri,
    receivedAt: Date.now(),
  };
}

export function userAccount2FAUpdateFailure(error) {
  Logger.log(
    'debug',
    `[state.userAccount.actions] userAccount2FAUpdateFailure(%j)`,
    error
  );
  return {
    type: USER_ACCOUNT_2FA_UPDATE_FAILURE,
    error: error,
  };
}

export function userAccount2FAFormDestroy(formState = null) {
  Logger.log(
    'debug',
    `[state.userAccount.actions] userAccount2FAFormDestroy(%j)`,
    formState
  );
  return {
    type: USER_ACCOUNT_2FA_FORM_DESTROY,
    form: formState,
  };
}

export function emailConfirmationRequest() {
  Logger.log('debug', `[state.userAccount.actions] emailConfirmationRequest()`);
  return {
    type: EMAIL_CONFIRMATION_REQUEST,
  };
}

export function emailConfirmationSuccess(data) {
  Logger.log('debug', `[state.userAccount.actions] emailConfirmationSuccess()`);
  return {
    type: EMAIL_CONFIRMATION_SUCCESS,
    id: data.id,
    username: data.username,
    email: data.email,
    password_changed_at: data.password_changed_at,
    is_verified: data.is_verified,
    is_enrolled_otp: data.is_enrolled_otp,
    one_time_password: data.one_time_password,
    profile_id: data.profile_id,
    first_name: data.first_name,
    last_name: data.last_name,
    telephone: data.telephone,
    timezone: data.timezone,
    joined_at: data.joined_at,
    alert_summary: data.alert_summary,
    alert_summary_at: data.alert_summary_at,
    alerts_in_app: data.alerts_in_app,
    alerts_email: data.alerts_email,
    alerts_sms: data.alerts_sms,
    reminders_email: data.reminders_email,
    reminders_sms: data.reminders_sms,
    avatar: data.avatar,
  };
}

export function emailConfirmationFailure(error) {
  Logger.log(
    'debug',
    `[state.userAccount.actions] emailConfirmationFailure(%j)`,
    error
  );
  return {
    type: EMAIL_CONFIRMATION_FAILURE,
    error: error,
  };
}

// API THUNK ACTION CREATORS

export function loadUserAccount(cb = function () {}) {
  Logger.log('debug', `[state.userAccount.actions] loadUserAccount(###)`);

  return async function (dispatch) {
    dispatch(userAccountReadRequest());

    // call API
    const response = await api.getUserAccount();
    let success = false;

    // get user account success
    if (200 === response.get('status')) {
      Logger.log('info', `GET API user account success.`);

      success = true;
      const data = {
        id: response.getIn(['data', 'user_account', 'id']),
        username: response.getIn(['data', 'user_account', 'username']),
        email: response.getIn(['data', 'user_account', 'email']),
        profile_id: response.getIn(['data', 'user_account', 'profile_id']),
        first_name: response.getIn(['data', 'user_account', 'first_name']),
        last_name: response.getIn(['data', 'user_account', 'last_name']),
        telephone: response.getIn(['data', 'user_account', 'telephone']),
        timezone: response.getIn(['data', 'user_account', 'timezone']),
        password_changed_at: response.getIn([
          'data',
          'user_account',
          'password_changed_at',
        ]),
        is_verified: response.getIn(['data', 'user_account', 'is_verified']),
        is_enrolled_otp: response.getIn([
          'data',
          'user_account',
          'is_enrolled_otp',
        ]),
        one_time_password: response.getIn([
          'data',
          'user_account',
          'one_time_password',
        ]),
        joined_at: response.getIn(['data', 'user_account', 'joined_at']),
        avatar: response.getIn(['data', 'user_account', 'avatar']),
        alert_summary: response.getIn([
          'data',
          'user_account',
          'alert_summary',
        ]),
        alert_summary_at: response.getIn([
          'data',
          'user_account',
          'alert_summary_at',
        ]),
        alerts_in_app: response.getIn([
          'data',
          'user_account',
          'alerts_in_app',
        ]),
        alerts_email: response.getIn(['data', 'user_account', 'alerts_email']),
        reminders_email: response.getIn([
          'data',
          'user_account',
          'reminders_email',
        ]),
        reminders_sms: response.getIn([
          'data',
          'user_account',
          'reminders_sms',
        ]),
        alerts_sms: response.getIn(['data', 'user_account', 'alerts_sms']),
      };

      dispatch(userAccountReadSuccess(data));

      const userFirstName = response.getIn([
        'data',
        'user_account',
        'first_name',
      ]);
      const userLastName = response.getIn([
        'data',
        'user_account',
        'last_name',
      ]);

      const mixpanelData = {
        name: `${userFirstName} ${userLastName}`,
        email: response.getIn(['data', 'user_account', 'email']),
      };

      MixpanelTracker.identify(response.getIn(['data', 'user_account', 'id']));
      MixpanelTracker.setUserInfo(mixpanelData);

      // get user account failure
    } else {
      Logger.log('info', `GET API user account failure.`);
      dispatch(userAccountReadFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  };
}

export function updateUserAccount(data, cb = function () {}) {
  Logger.log(
    'debug',
    `[state.userAccount.actions] updateUserAccount(%j, ###)`,
    data
  );

  return async function (dispatch) {
    dispatch(userAccountUpdateRequest(data));

    // call API
    const response = await api.putUserAccount(data);

    // put user account success
    if (200 === response.get('status')) {
      Logger.log('info', `PUT API user account success.`);

      const data = {
        id: response.getIn(['data', 'user_account', 'id']),
        username: response.getIn(['data', 'user_account', 'username']),
        email: response.getIn(['data', 'user_account', 'email']),
        profile_id: response.getIn(['data', 'user_account', 'profile_id']),
        first_name: response.getIn(['data', 'user_account', 'first_name']),
        last_name: response.getIn(['data', 'user_account', 'last_name']),
        telephone: response.getIn(['data', 'user_account', 'telephone']),
        timezone: response.getIn(['data', 'user_account', 'timezone']),
        password_changed_at: response.getIn([
          'data',
          'user_account',
          'password_changed_at',
        ]),
        is_verified: response.getIn(['data', 'user_account', 'is_verified']),
        is_enrolled_otp: response.getIn([
          'data',
          'user_account',
          'is_enrolled_otp',
        ]),
        one_time_password: response.getIn([
          'data',
          'user_account',
          'one_time_password',
        ]),
        joined_at: response.getIn(['data', 'user_account', 'joined_at']),
        avatar: response.getIn(['data', 'user_account', 'avatar']),
        alert_summary: response.getIn([
          'data',
          'user_account',
          'alert_summary',
        ]),
        alert_summary_at: response.getIn([
          'data',
          'user_account',
          'alert_summary_at',
        ]),
        alerts_in_app: response.getIn([
          'data',
          'user_account',
          'alerts_in_app',
        ]),
        alerts_email: response.getIn(['data', 'user_account', 'alerts_email']),
        alerts_sms: response.getIn(['data', 'user_account', 'alerts_sms']),
        reminders_email: response.getIn([
          'data',
          'user_account',
          'reminders_email',
        ]),
        reminders_sms: response.getIn([
          'data',
          'user_account',
          'reminders_sms',
        ]),
      };

      dispatch(userAccountUpdateSuccess(data));

      // get user account failure
    } else {
      Logger.log('info', `PUT API user account failure.`);
      dispatch(userAccountUpdateFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb();
  };
}

export function updateUserAccountAlerts(data, cb = function () {}) {
  Logger.log(
    'debug',
    `[state.userAccount.actions] updateUserAccountAlerts(%j, ###)`,
    data
  );

  return async function (dispatch) {
    dispatch(userAccountAlertsUpdateRequest(data));

    // call API
    const response = await api.putUserAccountAlerts(data);
    let success = false;

    // put user account alerts success
    if (200 === response.get('status')) {
      Logger.log('info', `PUT API user account alerts success.`);

      const data = {
        id: response.getIn(['data', 'user_account', 'id']),
        username: response.getIn(['data', 'user_account', 'username']),
        email: response.getIn(['data', 'user_account', 'email']),
        profile_id: response.getIn(['data', 'user_account', 'profile_id']),
        first_name: response.getIn(['data', 'user_account', 'first_name']),
        last_name: response.getIn(['data', 'user_account', 'last_name']),
        telephone: response.getIn(['data', 'user_account', 'telephone']),
        timezone: response.getIn(['data', 'user_account', 'timezone']),
        password_changed_at: response.getIn([
          'data',
          'user_account',
          'password_changed_at',
        ]),
        is_verified: response.getIn(['data', 'user_account', 'is_verified']),
        is_enrolled_otp: response.getIn([
          'data',
          'user_account',
          'is_enrolled_otp',
        ]),
        one_time_password: response.getIn([
          'data',
          'user_account',
          'one_time_password',
        ]),
        joined_at: response.getIn(['data', 'user_account', 'joined_at']),
        avatar: response.getIn(['data', 'user_account', 'avatar']),
        alert_summary: response.getIn([
          'data',
          'user_account',
          'alert_summary',
        ]),
        alert_summary_at: response.getIn([
          'data',
          'user_account',
          'alert_summary_at',
        ]),
        alerts_in_app: response.getIn([
          'data',
          'user_account',
          'alerts_in_app',
        ]),
        alerts_email: response.getIn(['data', 'user_account', 'alerts_email']),
        alerts_sms: response.getIn(['data', 'user_account', 'alerts_sms']),
        reminders_email: response.getIn([
          'data',
          'user_account',
          'reminders_email',
        ]),
        reminders_sms: response.getIn([
          'data',
          'user_account',
          'reminders_sms',
        ]),
      };

      dispatch(userAccountAlertsUpdateSuccess(data));
      success = true;

      // get user account alerts failure
    } else {
      Logger.log('info', `PUT API user account alerts failure.`);
      dispatch(
        userAccountAlertsUpdateFailure(response.getIn(['data', 'error']))
      );
    }

    // callback function
    cb(success);
  };
}

export function updateUserAccountAvatar(data, cb = function () {}) {
  Logger.log(
    'debug',
    `[state.userAccount.actions] updateUserAccountAvatar(%j, ###)`,
    data
  );

  return async function (dispatch) {
    dispatch(userAccountUpdateAvatarRequest(data));

    // call API
    const response = await api.postUserAccountAvatar(data);
    let success = false;

    // post user account avatar success
    if (201 === response.get('status')) {
      Logger.log('info', `POST API user account avatar success.`);

      const data = {
        id: response.getIn(['data', 'user_account', 'id']),
        username: response.getIn(['data', 'user_account', 'username']),
        email: response.getIn(['data', 'user_account', 'email']),
        profile_id: response.getIn(['data', 'user_account', 'profile_id']),
        first_name: response.getIn(['data', 'user_account', 'first_name']),
        last_name: response.getIn(['data', 'user_account', 'last_name']),
        telephone: response.getIn(['data', 'user_account', 'telephone']),
        timezone: response.getIn(['data', 'user_account', 'timezone']),
        password_changed_at: response.getIn([
          'data',
          'user_account',
          'password_changed_at',
        ]),
        is_verified: response.getIn(['data', 'user_account', 'is_verified']),
        is_enrolled_otp: response.getIn([
          'data',
          'user_account',
          'is_enrolled_otp',
        ]),
        one_time_password: response.getIn([
          'data',
          'user_account',
          'one_time_password',
        ]),
        joined_at: response.getIn(['data', 'user_account', 'joined_at']),
        avatar: response.getIn(['data', 'user_account', 'avatar']),
        alert_summary: response.getIn([
          'data',
          'user_account',
          'alert_summary',
        ]),
        alert_summary_at: response.getIn([
          'data',
          'user_account',
          'alert_summary_at',
        ]),
        alerts_in_app: response.getIn([
          'data',
          'user_account',
          'alerts_in_app',
        ]),
        alerts_email: response.getIn(['data', 'user_account', 'alerts_email']),
        alerts_sms: response.getIn(['data', 'user_account', 'alerts_sms']),
        reminders_email: response.getIn([
          'data',
          'user_account',
          'reminders_email',
        ]),
        reminders_sms: response.getIn([
          'data',
          'user_account',
          'reminders_sms',
        ]),
      };

      dispatch(userAccountUpdateAvatarSuccess(data));
      success = true;

      // post user account avatar failure
    } else {
      Logger.log('info', `POST API user account avatar failure.`);
      dispatch(
        userAccountUpdateAvatarFailure(response.getIn(['data', 'error']))
      );
    }

    // callback function
    cb(success);
  };
}

export function deleteUserAccountAvatar(cb = function () {}) {
  Logger.log(
    'debug',
    `[state.userAccount.actions] deleteUserAccountAvatar(###)`
  );

  return async function (dispatch) {
    dispatch(userAccountDeleteAvatarRequest());

    // call API
    const response = await api.deleteUserAccountAvatar();
    let success = false;

    // delete user account avatar success
    if (200 === response.get('status')) {
      Logger.log('info', `DELETE API user account avatar success.`);

      const data = {
        id: response.getIn(['data', 'user_account', 'id']),
        username: response.getIn(['data', 'user_account', 'username']),
        email: response.getIn(['data', 'user_account', 'email']),
        profile_id: response.getIn(['data', 'user_account', 'profile_id']),
        first_name: response.getIn(['data', 'user_account', 'first_name']),
        last_name: response.getIn(['data', 'user_account', 'last_name']),
        telephone: response.getIn(['data', 'user_account', 'telephone']),
        timezone: response.getIn(['data', 'user_account', 'timezone']),
        password_changed_at: response.getIn([
          'data',
          'user_account',
          'password_changed_at',
        ]),
        is_verified: response.getIn(['data', 'user_account', 'is_verified']),
        is_enrolled_otp: response.getIn([
          'data',
          'user_account',
          'is_enrolled_otp',
        ]),
        one_time_password: response.getIn([
          'data',
          'user_account',
          'one_time_password',
        ]),
        joined_at: response.getIn(['data', 'user_account', 'joined_at']),
        avatar: response.getIn(['data', 'user_account', 'avatar']),
        alert_summary: response.getIn([
          'data',
          'user_account',
          'alert_summary',
        ]),
        alert_summary_at: response.getIn([
          'data',
          'user_account',
          'alert_summary_at',
        ]),
        alerts_in_app: response.getIn([
          'data',
          'user_account',
          'alerts_in_app',
        ]),
        alerts_email: response.getIn(['data', 'user_account', 'alerts_email']),
        alerts_sms: response.getIn(['data', 'user_account', 'alerts_sms']),
        reminders_email: response.getIn([
          'data',
          'user_account',
          'reminders_email',
        ]),
        reminders_sms: response.getIn([
          'data',
          'user_account',
          'reminders_sms',
        ]),
      };

      dispatch(userAccountDeleteAvatarSuccess(data));
      success = true;

      // delete user account avatar failure
    } else {
      Logger.log('info', `DELETE API user account avatar failure.`);
      dispatch(
        userAccountDeleteAvatarFailure(response.getIn(['data', 'error']))
      );
    }

    // callback function
    cb(success);
  };
}

export function updatePassword(data, cb = function () {}) {
  Logger.log('debug', `[state.userAccount.actions] updatePassword(###, ###)`);

  return async function (dispatch) {
    dispatch(passwordUpdateRequest());

    // call API
    const response = await api.putUserAccountPassword(data);
    let success = false;

    // put password success
    if (200 === response.get('status')) {
      Logger.log('info', `PUT API password success.`);
      dispatch(passwordUpdateSuccess());
      success = true;

      // put password failure
    } else {
      Logger.log('info', `PUT API password failure.`);
      dispatch(passwordUpdateFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  };
}

export function updateUserAccount2FA(data, cb = function () {}) {
  Logger.log(
    'debug',
    `[state.userAccount.actions] updateUserAccount2FA(%j, ###)`,
    data
  );

  return async function (dispatch) {
    dispatch(userAccount2FAUpdateRequest(data));

    // call API
    const response = await api.putAccount2FA(data);
    let success = false;

    // put user account 2FA success
    if (200 === response.get('status')) {
      Logger.log('info', `PUT API user account 2FA success.`);

      const data = {
        is_enrolled_otp: response.getIn([
          'data',
          'user_account_2fa',
          'is_enrolled_otp',
        ]),
        otp_secret: response.getIn(['data', 'user_account_2fa', 'otp_secret']),
        provisioning_uri: response.getIn([
          'data',
          'user_account_2fa',
          'provisioning_uri',
        ]),
      };

      dispatch(userAccount2FAUpdateSuccess(data));
      success = true;

      // get user account 2FA failure
    } else {
      Logger.log('info', `PUT API user account 2FA failure.`);
      dispatch(userAccount2FAUpdateFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  };
}

export function confirmEmailAddress(data, cb = function () {}) {
  Logger.log(
    'debug',
    `[state.userAccount.actions] confirmEmailAddress(%j, ###)`,
    data
  );

  return async function (dispatch) {
    dispatch(emailConfirmationRequest(data));

    // call API
    const response = await api.postUserAccountEmailConfirmation(data);
    let success = false;

    // post user account email confirmation success
    if (201 === response.get('status')) {
      Logger.log('info', `POST API user account email confirmation success.`);

      const data = {
        id: response.getIn(['data', 'user_account', 'id']),
        username: response.getIn(['data', 'user_account', 'username']),
        email: response.getIn(['data', 'user_account', 'email']),
        profile_id: response.getIn(['data', 'user_account', 'profile_id']),
        first_name: response.getIn(['data', 'user_account', 'first_name']),
        last_name: response.getIn(['data', 'user_account', 'last_name']),
        telephone: response.getIn(['data', 'user_account', 'telephone']),
        timezone: response.getIn(['data', 'user_account', 'timezone']),
        password_changed_at: response.getIn([
          'data',
          'user_account',
          'password_changed_at',
        ]),
        is_verified: response.getIn(['data', 'user_account', 'is_verified']),
        is_enrolled_otp: response.getIn([
          'data',
          'user_account',
          'is_enrolled_otp',
        ]),
        one_time_password: response.getIn([
          'data',
          'user_account',
          'one_time_password',
        ]),
        joined_at: response.getIn(['data', 'user_account', 'joined_at']),
        avatar: response.getIn(['data', 'user_account', 'avatar']),
        alert_summary: response.getIn([
          'data',
          'user_account',
          'alert_summary',
        ]),
        alert_summary_at: response.getIn([
          'data',
          'user_account',
          'alert_summary_at',
        ]),
        alerts_in_app: response.getIn([
          'data',
          'user_account',
          'alerts_in_app',
        ]),
        alerts_email: response.getIn(['data', 'user_account', 'alerts_email']),
        alerts_sms: response.getIn(['data', 'user_account', 'alerts_sms']),
        reminders_email: response.getIn([
          'data',
          'user_account',
          'reminders_email',
        ]),
        reminders_sms: response.getIn([
          'data',
          'user_account',
          'reminders_sms',
        ]),
      };

      dispatch(emailConfirmationSuccess(data));
      success = true;

      // post user account email confirmation failure
    } else {
      Logger.log('info', `POST API user account email confirmation failure.`);
      dispatch(emailConfirmationFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  };
}

Logger.log('silly', `state.userAccount.actions loaded.`);
