import React, { useEffect, useState } from 'react';
import { Translation } from 'react-i18next';
import Logger from '../../../../../lib/Logger';
import AdminLayout from '../../../layouts/containers/AdminLayoutContainer';
import { getRouteFromPath } from '../../../Routes';
import { Redirect } from 'react-router-dom';
import { pathTo } from '../../../Routes';

const AdminScreen = (props) => {
  const [redirect, setRedirect] = useState(null);

  useEffect(() => {
    if (props.accessTabs === null || !props.accessTabs?.administrator) {
      setRedirect('HomeScreen');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const route = getRouteFromPath(props.location.pathname);
  if (!route || !route.route || route.route.length < 5) {
    return null;
  }

  if (redirect) {
    return <Redirect to={pathTo(redirect)} />;
  }

  return (
    <Translation>
      {(t) => (
        <>
          <AdminLayout location={props.location} />
        </>
      )}
    </Translation>
  );
};

export default AdminScreen;

Logger.log('silly', `AdminScreen loaded.`);
