import { Divider } from 'antd';
import { Translation } from 'react-i18next';
import { withRouter } from 'react-router';
import CareGuideDocumentFormModal from '../containers/CareGuideDocumentFormModalContainer';
import CareGuideDocumentPreviewModal from '../containers/CareGuideDocumentPreviewModalContainer';
import CareGuideDocumentsHeader from './CareGuideDocumentsHeader';
import CareGuideDocumentsTable from '../containers/CareGuideDocumentsTableContainer';
import Logger from '../../../../../lib/Logger';
import { pathTo } from '../../../Routes';
import QueryString from '../../../../../lib/QueryString';
import CareGuideDocumentsFilterTags from './CareGuideDocumentsFilterTags';

const component = 'CareGuideMembersActions';

const CareGuideDocumentsScreen = ({
  order,
  filter,
  limit,
  page,
  history,
  careGuideId,
  showForm,
}) => {
  let params = QueryString.parse(history.location.search);
  const dateStart = 'start' in params ? params['start'] : null;
  const dateEnd = 'end' in params ? params['end'] : null;
  const searchParam = 'search' in params ? params['search'] : null;

  const handleResetDate = () => {
    const path = pathTo(component, { id: careGuideId });
    delete params['start'];
    delete params['end'];
    history.push(QueryString.append(path, params));
  };

  const handleResetSearch = () => {
    const path = pathTo(component, { id: careGuideId });
    delete params['search'];
    history.push(QueryString.append(path, params));
  };

  return (
    <Translation>
      {(t) => (
        <section className="care-guide-files-layout">
          <CareGuideDocumentsHeader
            order={order}
            filter={filter}
            limit={limit}
            page={page}
            careGuideId={careGuideId}
            component={component}
            showForm={showForm}
          />
          <Divider />
          {Object.keys(filter).length > 0 && (
            <CareGuideDocumentsFilterTags
              dateStart={dateStart}
              dateEnd={dateEnd}
              handleResetDate={handleResetDate}
              searchParam={searchParam}
              handleSearchReset={handleResetSearch}
            />
          )}
          <CareGuideDocumentsTable
            showForm={showForm}
            page={page}
            limit={limit}
            order={order}
            filter={filter}
            careGuideId={careGuideId}
            component={component}
          />
          <CareGuideDocumentFormModal
            careGuideId={careGuideId}
            page={page}
            limit={limit}
            filter={filter}
            order={order}
          />
          <CareGuideDocumentPreviewModal />
        </section>
      )}
    </Translation>
  );
};

export default withRouter(CareGuideDocumentsScreen);

Logger.log('silly', `CareGuideFilesScreen loaded.`);
