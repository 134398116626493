import { connect } from 'react-redux';

import { loadPlansAdmin } from '../../../../../state/modules/plans/actions';
import PlanInputAdmin from '../components/PlanInputAdmin';

const mapStateToProps = (state) => {
  return {
    total: state.plans.get('totalAdmin'),
    isLoading: state.plans.get('isLoadingAdmin'),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    load: (page, limit, order, cb) => {
      dispatch(loadPlansAdmin(page, limit, order, cb));
    },
  };
};

const PlanInputAdminContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(PlanInputAdmin);

export default PlanInputAdminContainer;
