import { connect } from 'react-redux';
import { List } from 'immutable';
import { denormalize } from 'normalizr';
import { schema } from '../../../../../state/schema';
import {
  createResourceNote,
  loadResourceNotes,
  resourceNoteFormDestroy,
} from '../../../../../state/modules/resourceNotes/actions';
import ResourceNoteForm from '../components/ResourceNoteForm';
import storage from '../../../../../lib/Storage';

const inputs = List(['body']);

const mapStateToProps = (state, ownProps) => {
  const careGuideId = ownProps.careGuideId;
  const careGuide = careGuideId
    ? denormalize(
        state.entities.getIn(['careGuides', careGuideId]),
        schema.careGuide,
        state.entities.toJS()
      )
    : null;
  const resourceId = ownProps.resourceId;

  const resource = resourceId
    ? denormalize(
        state.entities.getIn(['resources', resourceId]),
        schema.resource,
        state.entities.toJS()
      )
    : null;

  const errors = {};
  for (const val of inputs.values()) {
    if (state.resourceNotes.getIn(['form', 'errors', val])) {
      errors[val] = state.resourceNotes.getIn(['form', 'errors', val]);
    }
  }

  return {
    resource,
    careGuide,
    partnershipId: storage.get('local', 'partnershipId'),
    partnershipName: storage.get('local', 'partnershipData')?.name,
    isSubmitting: state.resourceNotes.getIn(['form', 'isSubmitting']),
    success: state.resourceNotes.getIn(['form', 'success']),
    errors: errors,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    create: (careGuideId, resourceId, data, cb) => {
      dispatch(createResourceNote(careGuideId, resourceId, data, cb));
    },
    load: (careGuideId, resourceId, page, limit, order, filter, cb) => {
      dispatch(
        loadResourceNotes(
          careGuideId,
          resourceId,
          page,
          limit,
          order,
          filter,
          cb
        )
      );
    },
    formDestroy: (formState) => {
      dispatch(resourceNoteFormDestroy(formState));
    },
  };
};

const ResourceNoteFormContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ResourceNoteForm);

export default ResourceNoteFormContainer;
