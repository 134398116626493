import React, {Component} from 'react';
import {Translation} from 'react-i18next';
import {Col, Row} from 'antd';

import DocumentHead from '../../../elements/components/DocumentHead';
import EmailConfirmation from '../containers/EmailConfirmationContainer';
import Logger from '../../../../../lib/Logger';

class EmailConfirmationScreen extends Component {

  scrollToTop = () => {
    Logger.log('debug', `EmailConfirmationScreen.scrollToTop()`);
    if (typeof window !== 'undefined') {
      setTimeout(() => window.scrollTo(0, 0), 100);
    }
  }

  render() {
    return (
      <Translation>{(t) => 
        <div>

          <DocumentHead title={t('user_account_route_email_confirmation')} />

          <Row type="flex" justify="space-around" align="middle">
            <Col xs={24} md={18} lg={14} xl={12}>
              <EmailConfirmation token={this.props.token} />
            </Col>
          </Row>

        </div>
      }</Translation>
    )
  }

  /* LIFECYCLE EVENTS */

  componentDidMount() {
    Logger.log('silly', `EmailConfirmationScreen.componentDidMount()`);
    this.scrollToTop();
  }
}

export default EmailConfirmationScreen;

Logger.log('silly', `EmailConfirmationScreen loaded.`);
