import {getI18n} from 'react-i18next';

import Config from '../../../../Config';
import Logger from '../../../../lib/Logger';
import Events from '../../../../lib/EventEmitter';

import CalendarEventsAdminScreen from './containers/CalendarEventsAdminScreenContainer';
import CalendarEventAdminAddScreen from './containers/CalendarEventAdminAddScreenContainer';
import CalendarEventAdminEditScreen from './containers/CalendarEventAdminEditScreenContainer';

const register = () => {
  Logger.log('debug', `calendarEventsAdmin.register()`);

  if (Config.getIn(['MODULE_TOGGLES', 'calendarEventsAdmin', 'routes'])) {
    Events.dispatch('ADD_ADMIN_ROUTES', 
      {
        'CalendarEventAdminAddScreen': ['PrivateRoute', '/admin/calendar-events/partnership/:id_partnership([\\w\\-]+)/add', true, CalendarEventAdminAddScreen, getI18n().t('calendar_event_route_add')],
        'CalendarEventAdminEditScreen': ['PrivateRoute', '/admin/calendar-events/partnership/:id_partnership([\\w\\-]+)/edit/:id([\\w\\-]+)', true, CalendarEventAdminEditScreen, getI18n().t('calendar_event_route_edit')],
        'CalendarEventsAdminScreen': ['PrivateRoute', '/admin/calendar-events/:page(\\d+)?', true, CalendarEventsAdminScreen, getI18n().t('calendar_events_route_list')],
      }
    );
  }
}

export default register;

Logger.log('silly', `calendarEventsAdmin.register() loaded.`);
