import { denormalize } from 'normalizr';

import store from '../../../../state/store';
import { schema } from '../../../../state/schema';

export const CREATE_JOURNAL_POST = 'CREATE_JOURNAL_POST';
export const CREATE_JOURNAL_COMMENT = 'CREATE_JOURNAL_COMMENT';
export const CREATE_JOURNAL_EMOJI = 'CREATE_JOURNAL_EMOJI';
export const UPDATE_CARE_GUIDE_HELPER = 'UPDATE_CARE_GUIDE_HELPER';
export const CREATE_CARE_GUIDE_INVITATION = 'CREATE_CARE_GUIDE_INVITATION';
export const CREATE_RESOURCE = 'CREATE_RESOURCE';
export const UPDATE_RESOURCE = 'UPDATE_RESOURCE';
export const CREATE_CALENDAR_EVENT = 'CREATE_CALENDAR_EVENT';
export const UPDATE_CARE_GUIDE = 'UPDATE_CARE_GUIDE';
export const VIEW_PARTNER = 'VIEW_PARTNER';
export const VIEW_ACCESS_CARE_GUIDE = 'VIEW_ACCESS_CARE_GUIDE';
export const VIEW_ACCESS_DASHBOARD = 'VIEW_ACCESS_DASHBOARD';
export const VIEW_PARTNER_MEMBERS = 'VIEW_PARTNER_MEMBERS';
export const VIEW_SELECTOR_CARE_GUIDE = 'VIEW_SELECTOR_CARE_GUIDE';
export const VIEW_OWNER = 'VIEW_OWNER';

const ROLE_GUEST = 1;
const ROLE_MEMBER = 2;
const ROLE_ADMIN = 3;
const ROLE_PARTNER_OWNER = 4;
const ROLE_PARTNER_ADMIN = 5;

const hasPermission = (action, activeId = null, currentCareGuide = null) => {
  const state = store.getState();

  if (!activeId) {
    activeId = state.careGuides.get('activeId');
  }

  const profileId = state.session.get('profileId');

  if (!currentCareGuide) {
    currentCareGuide = denormalize(
      state.entities.getIn(['careGuides', activeId]),
      schema.careGuide,
      state.entities.toJS()
    );
  }

  const isOwner =
    currentCareGuide && currentCareGuide.owner && currentCareGuide.owner.profile
      ? currentCareGuide.owner.profile.id === profileId
      : false;

  if (isOwner) {
    switch (action) {
      case VIEW_PARTNER:
        return false;
      case VIEW_ACCESS_CARE_GUIDE:
        return false;
      case VIEW_PARTNER_MEMBERS:
        return false;
      case VIEW_ACCESS_DASHBOARD:
        return true;
      default:
        return true;
    }
  }

  const helper =
    currentCareGuide && currentCareGuide.helpers
      ? currentCareGuide.helpers.find(
          (x) => x.profile && x.profile.id === profileId
        )
      : null;

  const role = helper ? helper.role : null;

  if (role) {
    switch (role) {
      case ROLE_GUEST:
        switch (action) {
          case CREATE_JOURNAL_POST:
            return false;
          case CREATE_JOURNAL_COMMENT:
            return false;
          case CREATE_JOURNAL_EMOJI:
            return false;
          case UPDATE_CARE_GUIDE_HELPER:
            return false;
          case CREATE_CARE_GUIDE_INVITATION:
            return false;
          case CREATE_RESOURCE:
            return false;
          case UPDATE_RESOURCE:
            return false;
          case CREATE_CALENDAR_EVENT:
            return false;
          case UPDATE_CARE_GUIDE:
            return false;
          case VIEW_PARTNER:
            return false;
          case VIEW_ACCESS_CARE_GUIDE:
            return false;
          case VIEW_PARTNER_MEMBERS:
            return false;
          case VIEW_ACCESS_DASHBOARD:
            return true;
          case VIEW_SELECTOR_CARE_GUIDE:
            return true;
          default:
            return false;
        }

      case ROLE_MEMBER:
        switch (action) {
          case CREATE_JOURNAL_POST:
            return true;
          case CREATE_JOURNAL_COMMENT:
            return true;
          case CREATE_JOURNAL_EMOJI:
            return true;
          case UPDATE_CARE_GUIDE_HELPER:
            return false;
          case CREATE_CARE_GUIDE_INVITATION:
            return false;
          case CREATE_RESOURCE:
            return false;
          case UPDATE_RESOURCE:
            return false;
          case CREATE_CALENDAR_EVENT:
            return true;
          case UPDATE_CARE_GUIDE:
            return true;
          case VIEW_PARTNER:
            return false;
          case VIEW_ACCESS_CARE_GUIDE:
            return false;
          case VIEW_PARTNER_MEMBERS:
            return false;
          case VIEW_ACCESS_DASHBOARD:
            return true;
          case VIEW_SELECTOR_CARE_GUIDE:
            return true;
          default:
            return false;
        }

      case ROLE_ADMIN:
        switch (action) {
          case VIEW_ACCESS_DASHBOARD:
            return true;
          case VIEW_PARTNER:
            return false;
          case VIEW_ACCESS_CARE_GUIDE:
            return false;
          case VIEW_PARTNER_MEMBERS:
            return false;
          case VIEW_SELECTOR_CARE_GUIDE:
            return true;
          case VIEW_OWNER:
            return false;
          default:
            return true;
        }

      case ROLE_PARTNER_ADMIN:
        switch (action) {
          case VIEW_ACCESS_DASHBOARD:
            return false;
          case VIEW_SELECTOR_CARE_GUIDE:
            return false;
          case VIEW_OWNER:
            return false;
          default:
            return true;
        }

      case ROLE_PARTNER_OWNER:
        switch (action) {
          case VIEW_ACCESS_DASHBOARD:
            return false;
          case VIEW_SELECTOR_CARE_GUIDE:
            return false;
          case VIEW_OWNER:
            return false;
          default:
            return true;
        }

      default:
        return false;
    }
  }
  return false;
};

export default hasPermission;
