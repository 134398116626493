import React from 'react';
import { Translation } from 'react-i18next';
import { Col, Row, Button, Space } from 'antd';
import { pathTo } from '../../../Routes';
import { withRouter } from 'react-router';

const ResourceAdminFormFooter = ({
  id,
  component,
  setTargetPath,
  nextStep,
  step,
  prevStep,
  setConfirmationModalOpen,
  isChangeValue,
  history,
  submittable,
  isSubmitting,
  isImageUploadUrlLoading,
  isImageUploading,
}) => {
  const onHandleCancel = () => {
    if (isChangeValue) {
      setTargetPath(pathTo(component));
      setConfirmationModalOpen(true);
    } else {
      history.push(pathTo(component));
    }
  };

  return (
    <Translation>
      {(t) => (
        <>
          <Row justify="end" align="top" style={{ marginBottom: '40px' }}>
            <Col>
              {step === 0 && (
                <Space>
                  <Button
                    className="cancel-btn-resource"
                    onClick={onHandleCancel}
                  >
                    {t('resource_form_button_cancel')}
                  </Button>

                  <Button
                    type="primary"
                    onClick={nextStep}
                    disabled={!submittable}
                  >
                    {t('resource_form_button_next')}
                  </Button>
                </Space>
              )}

              {step === 1 && (
                <Space>
                  <Button className="previous-btn" onClick={prevStep}>
                    {t('resource_form_button_previous')}
                  </Button>
                  <Button
                    htmlType="submit"
                    type="primary"
                    loading={
                      isSubmitting ||
                      isImageUploadUrlLoading ||
                      isImageUploading
                    }
                  >
                    {id
                      ? t('resource_form_button_edit_resouce')
                      : t('resource_form_button_add_resouce')}
                  </Button>
                </Space>
              )}
            </Col>
          </Row>
        </>
      )}
    </Translation>
  );
};

export default withRouter(ResourceAdminFormFooter);
