import React, { Component } from 'react';
import { Translation } from 'react-i18next';
import { Card, Col, Row } from 'antd';
import { Calendar } from 'react-big-calendar';
import moment from 'moment';

import CareGuideDisabled from '../../careGuides/components/CareGuideDisabled';
import CareGuideWrapper from '../../../layouts/components/CareGuideWrapper';
import CalendarToolbar from '../containers/CalendarToolbarContainer';
import DocumentHead from '../../../elements/components/DocumentHead';
import EventForm from '../containers/EventFormContainer';
import EventModal from '../containers/EventModalContainer';
import Loading from '../../../elements/components/Loading';
import hasPermission, {
  CREATE_CALENDAR_EVENT,
  VIEW_ACCESS_CARE_GUIDE,
} from '../../../elements/lib/hasPermission';
import scrollToTop from '../../../elements/lib/scrollToTop';
import QueryString from '../../../../../lib/QueryString';
import Logger from '../../../../../lib/Logger';
import { Redirect } from 'react-router-dom';
import { pathTo } from '../../../Routes';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import '../styles/Calendar.less';
import {
  MixpanelTracker,
  mixpanelEventsEnum,
} from '../../../../../lib/Mixpanel';

class CalendarScreen extends Component {
  static defaultProps = {
    isLoading: true,
  };

  state = {
    redirect: null,
  };

  onNavigateHandler = (evt) => {
    const path = this.props.location.pathname;
    const params = QueryString.parse(this.props.location.search);
    params.start = moment(evt).format('YYYY-MM-DD');
    this.props.history.push(QueryString.append(path, params));
  };

  onViewHandler = (evt) => {
    const path = this.props.location.pathname;
    const params = QueryString.parse(this.props.location.search);
    params.view = evt;
    this.props.history.push(QueryString.append(path, params));
  };

  onSelectEventHandler = (evt) => {
    if (this.careGuide?.partnership) {
      MixpanelTracker.mixpanelTrack(mixpanelEventsEnum.CALENDAR.VIEW_EVENT, {
        careGuideName: `${this.props.careGuide.first_name} ${this.props.careGuide.last_name}`,
        partnershipName: this.props.careGuide.partnership.name,
        careGuideId: this.props.careGuide.id,
        partnershipId: this.props.careGuide.partnership.id,
        eventName: evt.clean_event,
        eventId: evt.id,
      });
    } else {
      MixpanelTracker.mixpanelTrack(mixpanelEventsEnum.CALENDAR.VIEW_EVENT, {
        careGuideName: `${this.props.careGuide.first_name} ${this.props.careGuide.last_name}`,
        careGuideId: this.props.careGuide.id,
        eventName: evt.clean_event,
        eventId: evt.id,
      });
    }

    this.props.showDetail(evt.id, false);
  };

  onSelectSlot = (slot) => {
    if (
      hasPermission(CREATE_CALENDAR_EVENT) &&
      slot.action === 'click' &&
      this.props.view === 'month'
    ) {
      this.props.showForm(null, slot.start);
    }
  };

  onShowMoreHandler = (events, date) => {
    const path = this.props.location.pathname;
    const params = QueryString.parse(this.props.location.search);
    params.start = moment(date).format('YYYY-MM-DD');
    params.view = 'day';
    this.props.history.push(QueryString.append(path, params));
  };

  render() {
    const eventStyleGetter = (event, start, end, isSelected) => {
      return {
        style: event.partnership
          ? { backgroundColor: event.partnership.color_hex }
          : {},
      };
    };

    if (this.props.isCareGuideLoading) {
      return (
        <Translation>
          {(t) => (
            <CareGuideWrapper>
              <DocumentHead title={t('calendar_route_main')} />
              <Loading />
            </CareGuideWrapper>
          )}
        </Translation>
      );
    }

    if (this.props.isCareGuideActive === null) {
      return <Redirect to={pathTo('HomeScreen')} />;
    }

    if (!this.props.isCareGuideActive) {
      return (
        <Translation>
          {(t) => (
            <CareGuideWrapper>
              <DocumentHead title={t('calendar_route_main')} />
              <CareGuideDisabled careGuideId={this.props.careGuideId} />
            </CareGuideWrapper>
          )}
        </Translation>
      );
    }

    const { redirect } = this.state;

    if (redirect) {
      return <Redirect to={pathTo(redirect)} />;
    }

    return (
      <Translation>
        {(t) => (
          <CareGuideWrapper>
            <DocumentHead title={t('calendar_route_main')} />

            <Row className="calendar-panel">
              <Col span={24}>
                <Card bordered={false} bodyStyle={{ padding: 0 }}>
                  <div className="calendar-view">
                    <Calendar
                      localizer={this.props.localizer}
                      events={this.props.events}
                      startAccessor="start"
                      endAccessor="end"
                      views={['month', 'week', 'day']}
                      date={moment(this.props.start).toDate()}
                      view={this.props.view}
                      onNavigate={this.onNavigateHandler}
                      onShowMore={this.onShowMoreHandler}
                      onView={this.onViewHandler}
                      onSelectEvent={this.onSelectEventHandler}
                      selectable={true}
                      onSelectSlot={this.onSelectSlot}
                      components={{
                        toolbar: CalendarToolbar,
                      }}
                      eventPropGetter={eventStyleGetter}
                    />
                  </div>
                </Card>
              </Col>
            </Row>

            <EventForm
              careGuideId={this.props.careGuideId}
              start={this.props.start}
              end={this.props.end}
              page={this.props.page}
              limit={this.props.limit}
              order={'start_at.asc'}
            />

            <EventModal />
          </CareGuideWrapper>
        )}
      </Translation>
    );
  }

  /* LIFECYCLE EVENTS */

  componentDidMount() {
    scrollToTop();

    if (this.props.isCareGuideActive || true) {
      // @todo: remove `true` from conditional once `isCareGuideActive` is more reliable
      this.props.load(this.props.careGuideId, this.props.start, this.props.end);
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.isCareGuideActive) {
      if (this.props.careGuide?.partnership !== prevProps.partnershipActiveId) {
        if (this.props.careGuide.partnership) {
          this.props.setActiveAccess({
            access_role_admin_in_care_guide:
              this.props.careGuide.partnership.access_role_admin_in_care_guide,
          });

          if (this.props.accessCareGuide) {
            if (hasPermission(VIEW_ACCESS_CARE_GUIDE)) {
              if (!this.props.accessCareGuide?.calendar) {
                this.setState({ redirect: 'HomeScreen' });
              }
            }
          }
        }
      }
      if (
        prevProps.careGuideId !== this.props.careGuideId ||
        prevProps.start !== this.props.start ||
        prevProps.end !== this.props.end
      ) {
        this.props.load(
          this.props.careGuideId,
          this.props.start,
          this.props.end
        );
      }
      if (this.props.eventId && prevProps.eventId !== this.props.eventId) {
        this.props.showDetail(this.props.eventId);
      }
    }
  }
}

export default CalendarScreen;

Logger.log('silly', `CalendarScreen loaded.`);
