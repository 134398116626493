import {connect} from 'react-redux';
import {denormalize} from 'normalizr';

import {schema} from '../../../../../state/schema';
import CareTeamScreen from '../components/CareTeamScreen';
import { careGuideSetActive, careGuideSetAccess } from '../../../../../state/modules/careGuides/actions';

const mapStateToProps = (state, ownProps) => {
  const page = parseInt(ownProps.match.params.page ? ownProps.match.params.page : 1);

  const activeId = state.careGuides.get("activeId") ?? ownProps.match.params.id;

  const careGuide = activeId
    ? denormalize(
      state.entities.getIn(['careGuides', activeId]),
      schema.careGuide,
      state.entities.toJS()
    )
    : null;

  return {
    isLoading: state.careGuideHelpers.get('isLoading'),
    page: page,
    careGuideId: activeId,
    careGuide: careGuide,
    isCareGuideLoading: careGuide === undefined ? true : false,
    isCareGuideActive: careGuide?.account?.is_subscription_valid,
    careGuideParnetshipStatus: careGuide?.partnership?.status,
    accessCareGuide: state.careGuides.get('accessCareGuide'),
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setActive: (id) => {
      dispatch(careGuideSetActive(id));
    },
    setActiveAccess: (data) => {
      dispatch(careGuideSetAccess(data));
    },
  }
}

const CareTeamScreenContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(CareTeamScreen);

export default CareTeamScreenContainer;
