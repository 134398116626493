import { connect } from 'react-redux';

import { loadResourceServiceTags } from '../../../../../state/modules/resourceServiceTags/actions';
import ServiceTagInput from '../components/ServiceTagInput';

const mapStateToProps = (state) => {
  const tags = state.entities.get('resourceServiceTags', {});
  return {
    options: Object.keys(tags).map((id) => {
      return { label: tags[id].label, value: id, color_hex: tags[id].color_hex };
    }),
    isLoading: state.resourceServiceTags.get('isLoading'),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    load: (page, limit, order, filter, cb) => {
      dispatch(loadResourceServiceTags(page, limit, order, filter, cb));
    },
  };
};

const ServiceTagInputContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ServiceTagInput);

export default ServiceTagInputContainer;
