import React, { useState, useEffect } from 'react';
import { Translation, getI18n } from 'react-i18next';
import message from '../../../elements/lib/MessageWrapper';
import Logger from '../../../../../lib/Logger';
import ResourceAdminFormHeader from './ResourceAdminFormHeader';
import ResourceAdminFormFooter from './ResourceAdminFormFooter';
import ResourceAdminFormStep1 from './ResourceAdminFormStep1';
import ResourceAdminFormStep2 from './ResourceAdminFormStep2';
import { Col, Row, Upload, Form, Space } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import ConfirmationModal from '../../../elements/components/ConfirmationModal';
import { pathTo } from '../../../Routes';
import notification from '../../../elements/lib/NotificationWrapper';
import imageURL from '../../../elements/lib/imageURL';
import {
  MixpanelTracker,
  mixpanelEventsEnum,
} from '../../../../../lib/Mixpanel';

const confirmTexts = {
  title: getI18n().t('resource_form_modal_leave_title'),
  description: getI18n().t('resource_form_modal_leave_description'),
  leaveText: getI18n().t('resource_form_modal_leave_leave'),
  saveText: getI18n().t('resource_form_modal_leave_save'),
};

const layout = {
  main: {
    labelCol: { span: 24 },
    wrapperCol: { span: 20 },
  },
};

// upload criteria
const fileTypes = {
  'image/png': 'png',
  'image/jpeg': 'jpg',
};

const fileSizeLimit = 1024 * 1024 * 6;
const reFileKey = /(?:(.*?)\/)?(.*?)\.(\w+)/;

const ResourceAdminForm = ({
  id,
  data,
  load,
  errors,
  history,
  isLoading,
  created_id,
  destroyForm,
  idsFiltered,
  totalMembers,
  isSubmitting,
  partnershipName,
  isImageUploading,
  loadTotalMembers,
  totalMembersFiltered,
  partnershipActiveId,
  isImageUploadUrlLoading,
  ...props
}) => {
  const [form] = Form.useForm();
  const [step, setStep] = useState(0);
  const [isBeforeUpload, setIsBeforeUpload] = useState(false);
  const [uploadedFile, setUploadedFile] = useState(null);
  const [classes, setClasses] = useState([]);
  const [isPinned, setIsPinned] = useState(data['is_pinned']);
  const [value, setValue] = useState(data['description']);
  const [isChangeValue, setIsChangeValue] = useState(false);
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [targetPath, setTargetPath] = useState(null);
  const [optionMembers, setOptionMembers] = useState(1);
  const [careGuideIds, setCareGuideIds] = useState(data['care_guides']);
  const [submittable, setSubmittable] = useState(false);
  const [mediaId, setMediaId] = useState(null);
  const [deleteId, setDeleteId] = useState(null);
  const [isDeleteMedia, setIsDeleteMedia] = useState(false);
  const [formData, setFormData] = useState({});
  const [careGuidepartnerTags, setCareGuidepartnerTags] = useState([]);

  // update input values when new data is available
  const dataString = JSON.stringify(data);

  const nextStep = async () => {
    const values = await form.validateFields();
    setFormData({ ...formData, ...values });
    setStep(step + 1);
  };

  const prevStep = () => {
    setStep(step - 1);
  };

  const onBeforeUpload = (file) => {
    const isSupportedFileType = Object.keys(fileTypes).includes(file.type);

    if (!isSupportedFileType) {
      form.setFields([
        {
          name: 'doc-file',
          errors: [
            <Space>
              <ExclamationCircleOutlined />
              {getI18n().t('resource_form_feedback_image_file_types')}
            </Space>,
          ],
        },
      ]);

      setUploadedFile(null);
      return Upload.LIST_IGNORE;
    }

    const isUnderFileSizeLimit = file.size < fileSizeLimit;
    if (!isUnderFileSizeLimit) {
      form.setFields([
        {
          name: 'doc-file',
          errors: [
            <Space>
              <ExclamationCircleOutlined />
              {getI18n().t('resource_form_feedback_image_file_size_limit')}
            </Space>,
          ],
        },
      ]);

      setUploadedFile(null);
      return Upload.LIST_IGNORE;
    }

    if (isSupportedFileType && isUnderFileSizeLimit) {
      setUploadedFile([file]);
      setIsBeforeUpload(true);
      setMediaId(null);
      return false;
    }
    return Upload.LIST_IGNORE;
  };

  const handleFileChange = (info) => {
    if (info.file.status === 'removed') {
      setUploadedFile(null);
      setIsBeforeUpload(false);
      if (mediaId) {
        setIsDeleteMedia(true);
        setDeleteId(mediaId);
        setMediaId(null);
      }
    } else {
      const file = {
        ...info.fileList[0],
        file: info.file,
        thumbUrl: URL.createObjectURL(info.fileList[0].originFileObj),
      };

      setUploadedFile([file]);
      form.setFieldsValue({
        'doc-file': file,
      });
    }
  };

  // form submit handler
  const handleFinish = async (values) => {
    Logger.log('debug', `ResourceAdminForm.handleFinish(###)`);
    if (!props.isSubmitting) {
      await submitData(values);
    }
  };

  // form error handler
  const handleFinishFailed = ({ values, errorFields, outOfDate }) => {
    Logger.log('debug', `ResourceAdminForm.handleFinishFailed(###)`);
    message.error(getI18n().t('feedback_form_error'));
    if (errorFields && errorFields.length > 0) {
      form.scrollToField(errorFields[0].name);
    }
  };

  const errorMessage = () => {
    message.error(getI18n().t('resources_form_feedback_image_upload_error'));
  };

  const submitDataImage = (
    file,
    values,
    payload,
    resources_care_partner_tags
  ) => {
    props.getUploadUrl(
      file.name.split('.').pop(),
      'logos',
      async (success, upload_url, file_id) => {
        if (success) {
          // upload image
          await props.upload(upload_url, file.file, async (uploadSuccess) => {
            const directory = 'logos/';
            file_id = file_id.replace(directory, '');
            if (uploadSuccess) {
              const matches = reFileKey.exec(file_id);
              const updateData = {
                file_directory: directory.slice(0, -1),
                file_name: matches[2],
                file_extension: matches[3],
              };

              // process image
              if (careGuideIds.length === 1) {
                await props.createImage(
                  careGuideIds[0],
                  partnershipActiveId,
                  updateData,
                  async (createSuccess, imageId) => {
                    if (createSuccess) {
                      setMediaId(imageId);
                      payload['logo_id'] = imageId;
                      processData(values, payload, resources_care_partner_tags);
                    } else {
                      errorMessage();
                    }
                  }
                );
              } else {
                // process image
                await props.createImagePartnership(
                  partnershipActiveId,
                  updateData,
                  async (createSuccess, imageId) => {
                    if (createSuccess) {
                      setMediaId(imageId);
                      payload['logo_id'] = imageId;
                      processData(values, payload, resources_care_partner_tags);
                    } else {
                      errorMessage();
                    }
                  }
                );
              }
            } else {
              errorMessage();
            }
          });
        } else {
          errorMessage();
        }
      }
    );
  };

  const processData = (values, payload, resources_care_partner_tags) => {
    let valuesForm = { ...formData, ...values };

    for (const input of Object.keys(data)) {
      // transform boolean values
      if (['is_universal', 'is_pinned'].includes(input)) {
        payload[input] = valuesForm[input] ? true : false;
      }
      // transform entity refs to IDs
      else if (['care_guides'].includes(input)) {
        payload['care_guide_ids'] = valuesForm[input];
      } else if (
        ['telephone', 'website', 'email', 'postal_code'].includes(input)
      ) {
        if (valuesForm[input]) {
          payload[input] = valuesForm[input];
        }
      } else if (['description'].includes(input)) {
        payload[input] = valuesForm[input] ? valuesForm[input] : '';
      } else if (['service_tags'].includes(input)) {
        payload['service_tag_ids'] = valuesForm[input];
      } else if (input in valuesForm) {
        payload[input] = valuesForm[input];
      }
    }
    payload['partnership_id'] = partnershipActiveId;
    payload['status'] = 1;
    for (const input of classes) {
      if (valuesForm[input]) {
        if (Array.isArray(valuesForm[input])) {
          const tagsFilters = valuesForm[input].filter((item) => item !== '');
          resources_care_partner_tags =
            resources_care_partner_tags.concat(tagsFilters);
        } else {
          if (valuesForm[input] !== '') {
            resources_care_partner_tags.push(valuesForm[input]);
          }
        }
      }
    }

    delete payload['logo'];
    delete payload['resources_care_partner_tags'];
    payload['resources_care_partner_tags_list'] = resources_care_partner_tags;

    if (id) {
      // update
      props.update(id, payload, async (success) => {
        if (success) {
          if (isDeleteMedia) {
            await props.deleteImage(partnershipActiveId, deleteId);
          }

          MixpanelTracker.mixpanelTrack(mixpanelEventsEnum.RESOURCE.EDIT, {
            partnershipName: partnershipName,
            partnershipId: partnershipActiveId,
          });

          notification.success(
            getI18n().t('resource_form_button_update_success')
          );
          setIsChangeValue(false);
          setTargetPath(pathTo('ResourcesAdminScreen'));
        } else {
          message.error(getI18n().t('feedback_form_error'));
        }
      });
    } else {
      // create
      props.create(payload, (success) => {
        if (success) {
          MixpanelTracker.mixpanelTrack(mixpanelEventsEnum.RESOURCE.CREATE, {
            partnershipName: partnershipName,
            partnershipId: partnershipActiveId,
          });

          notification.success(getI18n().t('resource_form_button_success'));
        } else {
          notification.error(getI18n().t('feedback_form_error'));
        }
      });
    }
  };

  // submit data handler
  const submitData = async (values) => {
    Logger.log('debug', `ResourceAdminForm.submitData(###)`);

    // API POST/PUT payload
    let payload = {};
    let resources_care_partner_tags = [];
    if (uploadedFile !== null) {
      if (mediaId) {
        payload['logo_id'] = mediaId;
        processData(values, payload, resources_care_partner_tags);
      } else {
        const file = uploadedFile[0];
        submitDataImage(file, values, payload, resources_care_partner_tags);
      }
    } else {
      payload['logo_id'] = null;
      processData(values, payload, resources_care_partner_tags);
    }
  };

  // remove error notification when input value changes
  const handleValuesChange = (changedValues, allValues) => {
    setIsChangeValue(true);
    const nameValues = Object.keys(changedValues);
    for (const key of nameValues) {
      form.setFields([{ name: key, errors: [] }]);
    }
    let resources_care_partner_tags = [];
    if (optionMembers === 2 && step === 1) {
      for (const input of classes) {
        if (form.getFieldsValue()[input]) {
          if (Array.isArray(form.getFieldsValue()[input])) {
            const tagsFilters = form
              .getFieldsValue()
              [input].filter((item) => item !== '');
            resources_care_partner_tags =
              resources_care_partner_tags.concat(tagsFilters);
          } else {
            if (form.getFieldsValue()[input] !== '') {
              resources_care_partner_tags.push(form.getFieldsValue()[input]);
            }
          }
        }
      }
      const filter = {
        care_guide_partner_tags: resources_care_partner_tags,
      };
      setCareGuidepartnerTags(resources_care_partner_tags);
      if (resources_care_partner_tags.length > 0) {
        loadTotalMembers(partnershipActiveId, filter);
      } else {
        loadTotalMembers(partnershipActiveId, {});
      }
    }
    if (nameValues.length === 1) {
      if (nameValues[0] === 'name') {
        form
          .validateFields(nameValues)
          .then(() => setSubmittable(true))
          .catch(() => setSubmittable(false));
      }
    }
  };

  const handleConfirmLeave = () => {
    setConfirmationModalOpen(false);
    setIsChangeValue(false);
  };

  const handleStayOnPage = () => {
    setConfirmationModalOpen(false);
    setTargetPath(null);
  };

  const onHandleChangeOptionMembers = (e) => {
    setOptionMembers(e.target.value);
    if (e.target.value === 1) {
      loadTotalMembers(partnershipActiveId, {});
    } else {
      if (careGuidepartnerTags.length > 0) {
        loadTotalMembers(partnershipActiveId, {
          care_guide_partner_tags: careGuidepartnerTags,
        });
      }
    }
  };

  // load record data from API
  useEffect(() => {
    if (id) {
      load(partnershipActiveId, id);
    }
  }, [partnershipActiveId, id, load]);

  useEffect(() => {
    if (id && !isSubmitting) {
      const dataObj = JSON.parse(dataString);
      setIsPinned(dataObj['is_pinned']);
      const transformedTags = data.resources_care_partner_tags.reduce(
        (acc, item) => {
          const className = item.admin_partner_class.name;
          const idValue = item.id;
          if (acc[className]) {
            acc[className].push(idValue);
          } else {
            acc[className] = [idValue];
          }

          return acc;
        },
        {}
      );
      form.setFieldsValue({
        ...dataObj,
        ...transformedTags,
      });

      setCareGuideIds(dataObj['care_guides']);
      if (dataObj['care_guides'].length > 0) {
        setOptionMembers(2);
      } else if (Object.keys(transformedTags).length > 0) {
        setOptionMembers(2);
      }

      if (dataObj['logo'] !== null) {
        setMediaId(dataObj['logo']['id']);
        form.setFieldsValue({
          'doc-file': 'preloaded',
        });

        const defaultFile = [
          {
            uid: dataObj['logo']['id'],
            name: dataObj['logo']['file_name'],
            url: imageURL(
              dataObj['logo']['file_directory'],
              dataObj['logo']['file_name'],
              dataObj['logo']['file_extension'],
              160,
              160,
              'ratio'
            ),
            thumbUrl: imageURL(
              dataObj['logo']['file_directory'],
              dataObj['logo']['file_name'],
              dataObj['logo']['file_extension'],
              160,
              160,
              'ratio'
            ),
            status: 'done',
          },
        ];
        setUploadedFile(defaultFile);
        setIsBeforeUpload(true);
      }

      setCareGuidepartnerTags(Object.values(transformedTags).flat());

      setSubmittable(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form, dataString, isSubmitting, id]);

  useEffect(() => {
    const unblock = history.block((location) => {
      if (isChangeValue) {
        setTargetPath(location.pathname);
        setConfirmationModalOpen(true);
        return false;
      }

      return true;
    });

    return () => unblock();
  }, [history, isChangeValue]);

  useEffect(() => {
    if (targetPath && !confirmationModalOpen) {
      history.push(targetPath);
    }
  }, [targetPath, confirmationModalOpen, history]);

  // redirect add form to edit form on successful create action
  useEffect(() => {
    if (created_id) {
      setIsChangeValue(false);
      setTargetPath(pathTo('ResourcesAdminScreen'));
    }
    return () => {
      destroyForm();
    };
  }, [created_id, partnershipActiveId, destroyForm]);

  useEffect(() => {
    if (careGuidepartnerTags.length > 0) {
      loadTotalMembers(partnershipActiveId, {
        care_guide_partner_tags: careGuidepartnerTags,
      });
    } else {
      loadTotalMembers(partnershipActiveId, {});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [careGuidepartnerTags]);

  return (
    <Translation>
      {(t) => (
        <>
          <ResourceAdminFormHeader id={id} step={step} />

          <Row justify="center" align="top">
            <Col
              span={24}
              flex={'auto'}
              className={'resource-form-content-panel'}
            >
              <Form
                name="resource_form"
                form={form}
                initialValues={{ ...data, 'option-members': optionMembers }}
                onFinish={handleFinish}
                onFinishFailed={handleFinishFailed}
                onValuesChange={handleValuesChange}
                validateTrigger="onSubmit"
                {...layout.main}
              >
                {step === 0 && (
                  <ResourceAdminFormStep1
                    form={form}
                    data={data}
                    handleFinish={handleFinish}
                    handleFinishFailed={handleFinishFailed}
                    isBeforeUpload={isBeforeUpload}
                    handleFileChange={handleFileChange}
                    uploadedFile={uploadedFile}
                    onBeforeUpload={onBeforeUpload}
                    isPinned={isPinned}
                    setIsPinned={setIsPinned}
                    isLoading={isLoading}
                    isSubmitting={isSubmitting}
                    value={value}
                    setValue={setValue}
                    isChangeValue={isChangeValue}
                    setIsChangeValue={setIsChangeValue}
                    handleValuesChange={handleValuesChange}
                  />
                )}

                {step === 1 && (
                  <ResourceAdminFormStep2
                    form={form}
                    optionMembers={optionMembers}
                    partnershipActiveId={partnershipActiveId}
                    isLoading={isLoading}
                    isSubmitting={isSubmitting}
                    totalMembers={totalMembers}
                    totalMembersFiltered={totalMembersFiltered}
                    classes={classes}
                    setClasses={setClasses}
                    setCareGuideIds={setCareGuideIds}
                    onHandleChangeOptionMembers={onHandleChangeOptionMembers}
                    careGuidepartnerTags={careGuidepartnerTags}
                    idsFiltered={idsFiltered}
                    careGuideIds={careGuideIds}
                  />
                )}

                <ResourceAdminFormFooter
                  id={id}
                  isSubmitting={isSubmitting}
                  isImageUploadUrlLoading={isImageUploadUrlLoading}
                  isImageUploading={isImageUploading}
                  submittable={submittable}
                  component={'ResourcesAdminScreen'}
                  isChangeValue={isChangeValue}
                  nextStep={nextStep}
                  prevStep={prevStep}
                  step={step}
                  setConfirmationModalOpen={setConfirmationModalOpen}
                  setTargetPath={setTargetPath}
                />
              </Form>
            </Col>
          </Row>

          <ConfirmationModal
            texts={confirmTexts}
            openConfirmationModal={confirmationModalOpen}
            onLeaveConfirmation={handleConfirmLeave}
            onSaveConfirmation={handleStayOnPage}
            onCancel={handleStayOnPage}
            setConfirmationModalOpen={setConfirmationModalOpen}
          />
        </>
      )}
    </Translation>
  );
};

export default ResourceAdminForm;

Logger.log('silly', `ResourceAdminForm loaded.`);
