import { connect } from 'react-redux';
import { denormalize } from 'normalizr';
import moment from 'moment';
import { momentLocalizer } from 'react-big-calendar';
import { getI18n } from 'react-i18next';

import CalendarScreen from '../components/CalendarScreen';
import { schema } from '../../../../../state/schema';
import {
  loadCalendarEvents,
  calendarShowEventForm,
  calendarShowEventDetail,
} from '../../../../../state/modules/calendar/actions';
import {
  careGuideSetActive,
  careGuideSetAccess,
  careGuideSetFirstLoad,
} from '../../../../../state/modules/careGuides/actions';
import QueryString from '../../../../../lib/QueryString';
import Format from '../../../../../lib/Format';
import storage from '../../../../../lib/Storage';

const localizer = momentLocalizer(moment);
const defaultLimit = 100;

const mapStateToProps = (state, ownProps) => {
  const queryParams = QueryString.parse(ownProps.location.search);

  const activeId = state.careGuides.get('activeId') ?? ownProps.match.params.id;

  const view = 'view' in queryParams ? queryParams['view'] : 'month';
  const start =
    'start' in queryParams
      ? moment(queryParams['start']).startOf(view).format('YYYY-MM-DD')
      : moment().startOf(view).format('YYYY-MM-DD');
  const end = moment(start).endOf(view).format('YYYY-MM-DD');
  const period = start + '.' + end;

  const profileId = state.session.get('profileId');

  const careGuide = activeId
    ? denormalize(
        state.entities.getIn(['careGuides', activeId]),
        schema.careGuide,
        state.entities.toJS()
      )
    : null;

  // showing event modal via query param?
  let eventId = null;
  if ('event_id' in queryParams) {
    if (state.entities.getIn(['calendarEvents', queryParams['event_id']])) {
      eventId = queryParams['event_id'];
    }
  }

  // merge results for all pages
  const result = [].concat.apply(
    [],
    Object.entries(
      state.calendar.getIn(
        ['events', activeId, period, 'start_at.asc', defaultLimit],
        {}
      )
    ).map(([k, v]) => v.toArray())
  );
  return {
    events: result
      ? result
          .map((x) => {
            return {
              key: x,
              ...denormalize(
                state.entities.getIn(['calendarEvents', x]),
                schema.calendarEvent,
                state.entities.toJS()
              ),
            };
          })
          .filter((x) => x.id)
          .map((x) => {
            // flatten some nested objects to make table easier to work with
            return {
              id: x.id,
              title:
                view === 'month'
                  ? x.is_all_day
                    ? getI18n().t('calendar_event_all_day') + ' ' + x.title
                    : // : <><span className="rbc-event-content-time">{Format.date(x.start_at, 'h:mm A')}</span> {x.title}</>)
                      Format.date(x.start_at, 'h:mm A') + ' ' + x.title
                  : x.title,
              clean_title: x.title,
              start: new Date(
                moment.parseZone(x.start_at).format('YYYY-MM-DDTHH:mm:ss')
              ),
              end: x.end_at
                ? new Date(
                    moment.parseZone(x.end_at).format('YYYY-MM-DDTHH:mm:ss')
                  )
                : new Date(
                    moment.parseZone(x.start_at).format('YYYY-MM-DDTHH:mm:ss')
                  ),
              allDay: x.is_all_day ? true : false,
              profileId: x.profile ? x.profile.id : null,
              partnership: x.partnership,
              // ...x
            };
          })
      : [],
    total: state.calendar.getIn(['events', activeId, 'total']),
    isLoading: state.calendar.get('isLoading'),
    careGuideId: activeId,
    profileId: profileId,
    eventId: eventId,
    careGuide: careGuide,
    start: start,
    end: end,
    view: view,
    localizer: localizer,
    isCareGuideLoading: careGuide === undefined ? true : false,
    isCareGuideActive:
      careGuide && careGuide.account && careGuide.account.is_subscription_valid,
    partnershipActiveId: storage.get('local', 'partnershipId'),
    accessCareGuide: state.careGuides.get('accessCareGuide'),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    load: (
      careGuideId,
      start,
      end,
      page = 1,
      limit = defaultLimit,
      order = 'start_at.asc',
      filter = null,
      cb = function () {}
    ) => {
      dispatch(
        loadCalendarEvents(
          careGuideId,
          start,
          end,
          page,
          limit,
          order,
          filter,
          (success, total) => {
            if (success) {
              dispatch(careGuideSetActive(careGuideId));
              // loop over all pages to get all events
              const pageCount = Math.ceil(total / limit);
              for (var i = 2; i <= pageCount; i++) {
                dispatch(
                  loadCalendarEvents(
                    careGuideId,
                    start,
                    end,
                    i,
                    limit,
                    order,
                    filter
                  )
                );
              }
              cb();
            } else {
              dispatch(careGuideSetFirstLoad(false));
            }
          }
        )
      );
    },
    showForm: (eventId, startDate) => {
      dispatch(calendarShowEventForm(eventId, startDate));
    },
    showDetail: (eventId, view) => {
      dispatch(calendarShowEventDetail(eventId, view));
    },
    setActive: (id) => {
      dispatch(careGuideSetActive(id));
    },
    setActiveAccess: (data) => {
      dispatch(careGuideSetAccess(data));
    },
  };
};

const CalendarScreenContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(CalendarScreen);

export default CalendarScreenContainer;
