import Logger from "./Logger";

const QueryString = {
  make: function (params) {
    if (!params) {
      return "";
    }

    const esc = encodeURIComponent;
    let isArrayValue = false;

    const query = Object.keys(params)
      .filter((x) => params[x] !== null)
      .map((k) => {
        if (typeof params[k] === "object" && !Array.isArray(params[k])) {
          // Nested array case
          const nestedValues = Object.entries(params[k])
            .map(([nestedKey, nestedValue]) => {
              const nestedArray =
                Array.isArray(nestedValue) &&
                nestedValue.map((v) => esc(v)).join(",");
              return `${esc(nestedKey)}:${nestedArray}`;
            })
            .join("||");
          return `${esc(k)}=${nestedValues}`;
        } else {
          return `${esc(k)}=${esc(params[k])}`;
        }
      })
      .join(isArrayValue ? "||" : "&");

    return query;
  },

  append: function (path, params) {
    const qs = QueryString.make(params);
    return qs ? (path.includes("?") ? path + qs : path + "?" + qs) : path;
  },

  parse: function (qs) {
    const params = {};
    const pairs = (qs[0] === "?" ? qs.substr(1) : qs).split("&");
    for (var i = 0; i < pairs.length; i++) {
      const pair = pairs[i].split("=");
      if (pair[0]) {
        params[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || "");
      }
    }
    return params;
  },
};

export default QueryString;

Logger.log("silly", `QueryString loaded.`);
