import {normalize} from 'normalizr';

import api from '../../api';
import {schema} from '../../schema';
import {addEntities, removeEntity} from '../../actions';
import Logger from '../../../lib/Logger';

export const TYPES = {
  LIST_REQUEST: 'CARE_GUIDE_INVITATIONS/LIST_REQUEST',
  LIST_SUCCESS: 'CARE_GUIDE_INVITATIONS/LIST_SUCCESS',
  LIST_FAILURE: 'CARE_GUIDE_INVITATIONS/LIST_FAILURE',
  CREATE_REQUEST: 'CARE_GUIDE_INVITATIONS/CREATE_REQUEST',
  CREATE_SUCCESS: 'CARE_GUIDE_INVITATIONS/CREATE_SUCCESS',
  CREATE_FAILURE: 'CARE_GUIDE_INVITATIONS/CREATE_FAILURE',
  PATCH_REQUEST: 'CARE_GUIDE_INVITATIONS/PATCH_REQUEST',
  PATCH_SUCCESS: 'CARE_GUIDE_INVITATIONS/PATCH_SUCCESS',
  PATCH_FAILURE: 'CARE_GUIDE_INVITATIONS/PATCH_FAILURE',
  DELETE_REQUEST: 'CARE_GUIDE_INVITATIONS/DELETE_REQUEST',
  DELETE_SUCCESS: 'CARE_GUIDE_INVITATIONS/DELETE_SUCCESS',
  DELETE_FAILURE: 'CARE_GUIDE_INVITATIONS/DELETE_FAILURE',
  RESEND_REQUEST: 'CARE_GUIDE_INVITATIONS/RESEND_REQUEST',
  RESEND_SUCCESS: 'CARE_GUIDE_INVITATIONS/RESEND_SUCCESS',
  RESEND_FAILURE: 'CARE_GUIDE_INVITATIONS/RESEND_FAILURE',
  RESPOND_REQUEST: 'CARE_GUIDE_INVITATIONS/RESPOND_REQUEST',
  RESPOND_SUCCESS: 'CARE_GUIDE_INVITATIONS/RESPOND_SUCCESS',
  RESPOND_FAILURE: 'CARE_GUIDE_INVITATIONS/RESPOND_FAILURE',
  USER_LIST_REQUEST: 'CARE_GUIDE_INVITATIONS/USER_LIST_REQUEST',
  USER_LIST_SUCCESS: 'CARE_GUIDE_INVITATIONS/USER_LIST_SUCCESS',
  USER_LIST_FAILURE: 'CARE_GUIDE_INVITATIONS/USER_LIST_FAILURE',
  FORM_DESTROY: 'CARE_GUIDE_INVITATIONS/FORM_DESTROY',
  SHOW_FORM: 'CARE_GUIDE_INVITATIONS/SHOW_FORM',
  HIDE_FORM: 'CARE_GUIDE_INVITATIONS/HIDE_FORM',
};

export function careGuideInvitationListRequest(careGuideId, page, limit, order, filter) {
  Logger.log('debug', `[state.careGuideInvitations.actions] careGuideInvitationListRequest(${careGuideId}, ${page}, ${limit}, ${order}, %j)`, filter);
  return {
    type: TYPES.LIST_REQUEST,
    careGuideId: careGuideId,
    page: page,
    limit: limit,
    order: order,
    filter: filter,
  }
}

export function careGuideInvitationListSuccess(careGuideId, data) {
  Logger.log('debug', `[state.careGuideInvitations.actions] careGuideInvitationListSuccess(${careGuideId}, %j)`, data);
  return {
    type: TYPES.LIST_SUCCESS,
    careGuideId: careGuideId,
    page: data.page,
    limit: data.limit,
    order: data.order,
    result: data.result,
    total: data.total,
    receivedAt: Date.now()
  }
}

export function careGuideInvitationListFailure(careGuideId, error) {
  Logger.log('debug', `[state.careGuideInvitations.actions] careGuideInvitationListFailure(${careGuideId}, %j)`, error);
  return {
    type: TYPES.LIST_FAILURE,
    careGuideId: careGuideId,
    error: error
  }
}

export function careGuideInvitationPostRequest(careGuideId, data) {
  Logger.log('debug', `[state.careGuideInvitations.actions] careGuideInvitationPostRequest(${careGuideId}, %j)`, data);
  return {
    type: TYPES.CREATE_REQUEST,
    careGuideId: careGuideId,
  }
}

export function careGuideInvitationPostSuccess(careGuideId, data) {
  Logger.log('debug', `[state.careGuideInvitations.actions] careGuideInvitationPostSuccess(${careGuideId}, %j)`, data);
  return {
    type: TYPES.CREATE_SUCCESS,
    careGuideId: careGuideId,
    id: data.id,
    receivedAt: Date.now()
  }
}

export function careGuideInvitationPostFailure(careGuideId, error) {
  Logger.log('debug', `[state.careGuideInvitations.actions] careGuideInvitationPostFailure(${careGuideId}, %j)`, error);
  return {
    type: TYPES.CREATE_FAILURE,
    careGuideId: careGuideId,
    error: error
  }
}

export function careGuideInvitationPatchRequest(careGuideId, id, data) {
  Logger.log('debug', `[state.careGuideInvitations.actions] careGuideInvitationPatchRequest(${careGuideId}, ${id}, %j)`, data);
  return {
    type: TYPES.PATCH_REQUEST,
    careGuideId: careGuideId,
    id: id,
  }
}

export function careGuideInvitationPatchSuccess(careGuideId, data) {
  Logger.log('debug', `[state.careGuideInvitations.actions] careGuideInvitationPatchSuccess(${careGuideId}, %j)`, data);
  return {
    type: TYPES.PATCH_SUCCESS,
    careGuideId: careGuideId,
    id: data.id,
    receivedAt: Date.now()
  }
}

export function careGuideInvitationPatchFailure(careGuideId, error) {
  Logger.log('debug', `[state.careGuideInvitations.actions] careGuideInvitationPatchFailure(${careGuideId}, %j)`, error);
  return {
    type: TYPES.PATCH_FAILURE,
    careGuideId: careGuideId,
    error: error
  }
}

export function careGuideInvitationDeleteRequest(careGuideId, id) {
  Logger.log('debug', `[state.careGuideInvitations.actions] careGuideInvitationDeleteRequest(${careGuideId}, ${id})`);
  return {
    type: TYPES.DELETE_REQUEST,
    careGuideId: careGuideId,
    id: id
  }
}

export function careGuideInvitationDeleteSuccess(careGuideId, id) {
  Logger.log('debug', `[state.careGuideInvitations.actions] careGuideInvitationDeleteSuccess(${careGuideId}, ${id})`);
  return {
    type: TYPES.DELETE_SUCCESS,
    careGuideId: careGuideId,
    id: id,
  }
}

export function careGuideInvitationDeleteFailure(careGuideId, error) {
  Logger.log('debug', `[state.careGuideInvitations.actions] careGuideInvitationDeleteFailure(${careGuideId}, %j)`, error);
  return {
    type: TYPES.DELETE_FAILURE,
    careGuideId: careGuideId,
    error: error
  }
}

export function careGuideInvitationResendRequest(careGuideId, id) {
  Logger.log('debug', `[state.careGuideInvitations.actions] careGuideInvitationResendRequest(${careGuideId}, ${id})`);
  return {
    type: TYPES.RESEND_REQUEST,
    careGuideId: careGuideId,
    id: id,
  }
}

export function careGuideInvitationResendSuccess(careGuideId, data) {
  Logger.log('debug', `[state.careGuideInvitations.actions] careGuideInvitationResendSuccess(${careGuideId}, %j)`, data);
  return {
    type: TYPES.RESEND_SUCCESS,
    careGuideId: careGuideId,
    id: data.id,
    receivedAt: Date.now()
  }
}

export function careGuideInvitationResendFailure(careGuideId, error) {
  Logger.log('debug', `[state.careGuideInvitations.actions] careGuideInvitationResendFailure(${careGuideId}, %j)`, error);
  return {
    type: TYPES.RESEND_FAILURE,
    careGuideId: careGuideId,
    error: error
  }
}

export function careGuideInvitationRespondRequest(id, data) {
  Logger.log('debug', `[state.careGuideInvitations.actions] careGuideInvitationRespondRequest(${id}, %j)`, data);
  return {
    type: TYPES.RESPOND_REQUEST,
    id: id,
  }
}

export function careGuideInvitationRespondSuccess(data) {
  Logger.log('debug', `[state.careGuideInvitations.actions] careGuideInvitationRespondSuccess(%j)`, data);
  return {
    type: TYPES.RESPOND_SUCCESS,
    id: data.id,
    receivedAt: Date.now()
  }
}

export function careGuideInvitationRespondFailure(error) {
  Logger.log('debug', `[state.careGuideInvitations.actions] careGuideInvitationRespondFailure(%j)`, error);
  return {
    type: TYPES.RESPOND_FAILURE,
    error: error
  }
}

export function userInvitationListRequest(page, limit, order, filter) {
  Logger.log('debug', `[state.careGuideInvitations.actions] userInvitationListRequest(${page}, ${limit}, ${order}, %j)`, filter);
  return {
    type: TYPES.USER_LIST_REQUEST,
    page: page,
    limit: limit,
    order: order,
    filter: filter,
  }
}

export function userInvitationListSuccess(data) {
  Logger.log('debug', `[state.careGuideInvitations.actions] userInvitationListSuccess(%j)`, data);
  return {
    type: TYPES.USER_LIST_SUCCESS,
    page: data.page,
    limit: data.limit,
    order: data.order,
    result: data.result,
    total: data.total,
    receivedAt: Date.now()
  }
}

export function userInvitationListFailure(error) {
  Logger.log('debug', `[state.careGuideInvitations.actions] userInvitationListFailure(%j)`, error);
  return {
    type: TYPES.USER_LIST_FAILURE,
    error: error
  }
}

export function careGuideInvitationFormDestroy(formState=null) {
  Logger.log('debug', `[state.careGuideInvitations.actions] careGuideInvitationFormDestroy(%j)`, formState);
  return {
    type: TYPES.FORM_DESTROY,
    form: formState
  }
}

export function careGuideInvitationShowForm(helperId=null) {
  Logger.log('debug', `[state.careGuideInvitations.actions] careGuideInvitationShowForm()`);
  return {
    type: TYPES.SHOW_FORM,
    helperId: helperId,
  }
}

export function careGuideInvitationHideForm() {
  Logger.log('debug', `[state.careGuideInvitations.actions] careGuideInvitationHideForm()`);
  return {
    type: TYPES.HIDE_FORM,
  }
}


// API THUNK ACTION CREATORS

export function loadCareGuideInvitations(careGuideId, page=1, limit=10, order=null, filter=null, cb=function(){}) {
  Logger.log('debug', `[state.careGuideInvitations.actions] loadCareGuideInvitations(${careGuideId}, ${page}, ${limit}, ${order}, %j, ###)`, filter);

  return async function(dispatch) {
    dispatch(careGuideInvitationListRequest(careGuideId, page, limit, order, filter));

    // call API
    const response = await api.getCareGuideInvitations(careGuideId, page, limit, order, filter);
    let success = false;

    // get care guide invitations list success
    if (200 === response.get('status')) {

      Logger.log('info', `Get API care guide invitations list success. Care Guide ID: ${careGuideId}, Page: ${page}, Limit: ${limit}, Order: ${order}.`);

      const normalizedEntities = normalize(response.getIn(['data', 'care_guide_invitations']), [schema.careGuideInvitation]);
      const data = {
        page: response.getIn(['data', 'page']),
        limit: response.getIn(['data', 'limit']),
        order: order,
        total: response.getIn(['data', 'total']),
        result: normalizedEntities.result
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(careGuideInvitationListSuccess(careGuideId, data));
      success = true;

    } else if (1 === page && 204 === response.get('status')) {

      Logger.log('info', `Get API care guide invitations list success [empty]. Care Guide ID: ${careGuideId}, Page: ${page}, Limit: ${limit}, Order: ${order}.`);
      const data = {
        page: page,
        limit: limit,
        order: order,
        total: 0,
        result: []
      };
      dispatch(careGuideInvitationListSuccess(careGuideId, data));
      success = true;
      
    // get care guide invitations list failure
    } else {
      Logger.log('info', `Get API care guide invitations list failure. Care Guide ID: ${careGuideId}, Page: ${page}, Limit: ${limit}, Order: ${order}.`);
      dispatch(careGuideInvitationListFailure(careGuideId, response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  }
}

export function creatCareGuideInvitation(careGuideId, data, cb=function(){}) {
  Logger.log('debug', `[state.careGuideInvitations.actions] creatCareGuideInvitation(${careGuideId}, %j, ###)`, data);

  return async function(dispatch) {
    dispatch(careGuideInvitationPostRequest(careGuideId, data));

    // call API
    const response = await api.postCareGuideInvitations(careGuideId, data);
    let success = false;

    // post care guide invitation success
    if (201 === response.get('status')) {

      Logger.log('info', `POST API care guide invitation success. Care Guide ID: ${careGuideId}.`);

      const normalizedEntities = normalize([response.getIn(['data', 'care_guide_invitation'])], [schema.careGuideInvitation]);
      const data = {
        id: response.getIn(['data', 'care_guide_invitation', 'id']),
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(careGuideInvitationPostSuccess(careGuideId, data));
      success = true;
      
    // post care guide invitation failure
    } else {
      Logger.log('info', `POST API care guide invitation failure. Care Guide ID: ${careGuideId}.`);
      dispatch(careGuideInvitationPostFailure(careGuideId, response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  }
}

export function patchCareGuideInvitation(careGuideId, id, data, cb=function(){}) {
  Logger.log('debug', `[state.careGuideInvitations.actions] patchCareGuideInvitation(${careGuideId}, ${id}, %j, ###)`, data);

  return async function(dispatch) {
    dispatch(careGuideInvitationPatchRequest(careGuideId, id, data));

    // call API
    const response = await api.patchCareGuideInvitation(careGuideId, id, data);
    let success = false;

    // patch care guide invitation success
    if (200 === response.get('status')) {

      Logger.log('info', `PATCH API care guide invitation success. Care Guide ID: ${careGuideId}, User: ${id}.`);

      const normalizedEntities = normalize([response.getIn(['data', 'care_guide_invitation'])], [schema.careGuideInvitation]);
      const data = {
        id: response.getIn(['data', 'care_guide_invitation', 'id']),
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(careGuideInvitationPatchSuccess(careGuideId, data));
      success = true;
      
    // patch care guide invitation failure
    } else {
      Logger.log('info', `PATCH API care guide invitation failure. Care Guide ID: ${careGuideId}, ID: ${id}.`);
      dispatch(careGuideInvitationPatchFailure(careGuideId, response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  }
}

export function deleteCareGuideInvitation(careGuideId, id, cb=function(){}) {
  Logger.log('debug', `[state.careGuideInvitations.actions] deleteCareGuideInvitation(${id}, ###)`);

  return async function(dispatch) {
    dispatch(careGuideInvitationDeleteRequest(careGuideId, id));

    // call API
    const response = await api.deleteCareGuideInvitation(careGuideId, id);
    let success = false;

    // delete care guide invitation success
    if (204 === response.get('status')) {

      Logger.log('info', `DELETE API care guide invitation success. Care Guide ID: ${careGuideId}, ID: ${id}.`);

      dispatch(removeEntity({entityType: 'care_guide_invitations', id: id}));
      dispatch(careGuideInvitationDeleteSuccess(careGuideId, id));
      success = true;
      
    // get care guide invitation failure
    } else {
      Logger.log('info', `DELETE API care guide invitation failure. Care Guide ID: ${careGuideId}, ID: ${id}.`);
      dispatch(careGuideInvitationDeleteFailure(careGuideId, response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  }
}

export function resendCareGuideInvitation(careGuideId, id, data={}, cb=function(){}) {
  Logger.log('debug', `[state.careGuideInvitations.actions] resendCareGuideInvitation(${careGuideId}, ${id}, ###)`);

  return async function(dispatch) {
    dispatch(careGuideInvitationResendRequest(careGuideId, id));

    // call API
    const response = await api.patchCareGuideInvitation(careGuideId, id, {resend: true, ...data});
    let success = false;

    // resend care guide invitation success
    if (200 === response.get('status')) {

      Logger.log('info', `resend API care guide invitation success. Care Guide ID: ${careGuideId}, User: ${id}.`);

      const normalizedEntities = normalize([response.getIn(['data', 'care_guide_invitation'])], [schema.careGuideInvitation]);
      const data = {
        id: response.getIn(['data', 'care_guide_invitation', 'id']),
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(careGuideInvitationResendSuccess(careGuideId, data));
      success = true;
      
    // resend care guide invitation failure
    } else {
      Logger.log('info', `resend API care guide invitation failure. Care Guide ID: ${careGuideId}, ID: ${id}.`);
      dispatch(careGuideInvitationResendFailure(careGuideId, response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  }
}

export function respondCareGuideInvitation(id, data, cb=function(){}) {
  Logger.log('debug', `[state.careGuideInvitations.actions] respondCareGuideInvitation(${id}, %j, ###)`, data);

  return async function(dispatch) {
    dispatch(careGuideInvitationRespondRequest(id, data));

    // call API
    const response = await api.putInvitationResponse(id, data);
    let success = false;

    // respond care guide invitation success
    if (200 === response.get('status')) {

      Logger.log('info', `respond API care guide invitation success. ID: ${id}.`);

      const normalizedEntities = normalize([response.getIn(['data', 'care_guide_invitation'])], [schema.careGuideInvitation]);
      const data = {
        id: response.getIn(['data', 'care_guide_invitation', 'id']),
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(careGuideInvitationRespondSuccess(data));
      success = true;
      
    // respond care guide invitation failure
    } else {
      Logger.log('info', `respond API care guide invitation failure. ID: ${id}.`);
      dispatch(careGuideInvitationRespondFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  }
}

export function loadInvitations(page=1, limit=10, order=null, filter=null, cb=function(){}) {
  Logger.log('debug', `[state.careGuideInvitations.actions] loadInvitations(${page}, ${limit}, ${order}, %j, ###)`, filter);

  return async function(dispatch) {
    dispatch(userInvitationListRequest(page, limit, order, filter));

    // call API
    const response = await api.getInvitations(page, limit, order, filter);
    let success = false;

    // get invitations list success
    if (200 === response.get('status')) {

      Logger.log('info', `Get API invitations list success. Page: ${page}, Limit: ${limit}, Order: ${order}.`);

      const normalizedEntities = normalize(response.getIn(['data', 'care_guide_invitations']), [schema.careGuideInvitation]);
      const data = {
        page: response.getIn(['data', 'page']),
        limit: response.getIn(['data', 'limit']),
        order: order,
        total: response.getIn(['data', 'total']),
        result: normalizedEntities.result
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(userInvitationListSuccess(data));
      success = true;

    } else if (1 === page && 204 === response.get('status')) {

      Logger.log('info', `Get API invitations list success [empty]. Page: ${page}, Limit: ${limit}, Order: ${order}.`);
      const data = {
        page: page,
        limit: limit,
        order: order,
        total: 0,
        result: []
      };
      dispatch(userInvitationListSuccess(data));
      success = true;
      
    // get invitations list failure
    } else {
      Logger.log('info', `Get API invitations list failure. Page: ${page}, Limit: ${limit}, Order: ${order}.`);
      dispatch(userInvitationListFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  }
}

Logger.log('silly', `state.careGuideInvitations.actions loaded.`);
