import {notification} from 'antd';
import Logger from '../../../../lib/Logger';

class NotificationWrapper {

  constructor() {
    Logger.log('debug', `MessageWrapper.constructor()`);
    this.events = {};
  }

  success(message = "", description=null, duration=4.5, onClose = null, className="custom-notification custom-notification-success", placement = "bottom") {
    Logger.log('debug', `MessageWrapper.open(${message}, ${duration}, ###})`);
    notification.open({message, description, duration, onClose, className, placement});
  }

  error(message = "", description=null, duration=4.5, onClose = null, className="custom-notification custom-notification-error", placement = "bottom") {
    Logger.log('debug', `MessageWrapper.open(${message}, ${duration}, ###})`);
    notification.open({message, description, duration, onClose, className, placement});
  }

  destroy() {
    Logger.log('debug', `MessageWrapper.destroy()`);
    notification.destroy();
  }  
}

const notificationWrapper = new NotificationWrapper();
export default notificationWrapper;

Logger.log('silly', `notificationWrapper loaded.`);
