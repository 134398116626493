import React, { useEffect } from 'react';
import { Translation } from 'react-i18next';
import { Form, Select, Tag } from 'antd';

const TagInput = ({ partnershipId, options, disabled, load, setClasses, nameClasses, mode=null, ...props }) => {

  // load care guide partner tags on mount
  useEffect(() => {
    if (partnershipId) {
      load(partnershipId);
    }
  }, [load, partnershipId]);

  useEffect(() => {
    setClasses(nameClasses);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nameClasses]);

  const careGuidePartnerTagMap = new Map(options.flatMap(x => x?.care_guide_partner_tag?.map(y => [y.id, y])));


  const tagRender = (props) => {
    const { value, label, closable, onClose  } = props;
    const tagColor = value ? careGuidePartnerTagMap.get(value)?.color_hex : 'blue'; 

    const onPreventMouseDown = (event) => {
      event.preventDefault();
      event.stopPropagation();
    };

    return (
      <Tag
        color={tagColor}
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
        style={{
          marginRight: 3,
        }}
      >
        {label}
      </Tag>
    );
  };


  return (
    <Translation>{(t) => (
      options.map(x =>
        <div className="form-group" key={x?.id}>
          <Form.Item
            name={x?.name}
            label={x?.name}
          >
            <Select
              tagRender={tagRender}
              disabled={disabled}
              mode={mode}
            >
              {x?.care_guide_partner_tag?.map(y =>
                <Select.Option
                  key={y.id}
                  value={y.id}
                >
                  {y.label}
                </Select.Option>)}
            </Select>
          </Form.Item>
        </div>))
    }</Translation>
  );
}

export default TagInput;