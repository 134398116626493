import React, { useState } from 'react';
import { Translation } from 'react-i18next';
import { Modal, Typography, Button } from 'antd';
import ResourceNotesListAdmin from '../containers/ResourceNotesListAdminContainer';
import Logger from '../../../../../lib/Logger';
import { faFileArrowDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  MixpanelTracker,
  mixpanelEventsEnum,
} from '../../../../../lib/Mixpanel';
const { Title } = Typography;

const ResourceNotesModalAdmin = ({
  partnershipId,
  partnershipName,
  resourceId,
  resource,
  download,
  ...props
}) => {
  const [page, setPage] = useState(1);
  const limit = 5;

  const onClickOK = () => {
    props.hideList();
    setPage(1);
  };

  const onClickCancel = () => {
    props.hideList();
    setPage(1);
  };

  const onClickExport = () => {
    MixpanelTracker.mixpanelTrack(
      mixpanelEventsEnum.RESOURCE.EXPORT_NOTES_CSV,
      {
        partnershipName: partnershipName,
        partnershipId: partnershipId,
        resourceId: resourceId,
        resourceName: resource.name,
      }
    );

    download(partnershipId, resourceId);
  };

  return (
    <Translation>
      {(t) => (
        <>
          <Modal
            title={
              <Title level={3} className="resource-note-modal-title">
                {t('resource_notes_list_title', {
                  name: resource ? resource.name : null,
                })}
              </Title>
            }
            className="resource-note-modal"
            centered
            visible={props.visible}
            onOk={onClickOK}
            onCancel={onClickCancel}
            width={600}
            forceRender={true}
            footer={[
              <Button size={'large'} type="primary" onClick={onClickExport}>
                <div className="icon-btn">
                  <FontAwesomeIcon icon={faFileArrowDown} />
                  {t('resource_notes_export')}
                </div>
              </Button>,
            ]}
          >
            <div className="resource-notes-list">
              <ResourceNotesListAdmin
                partnershipId={partnershipId}
                resourceId={resourceId}
                page={page}
                limit={limit}
                order={'published_at.desc'}
                setPage={setPage.bind(this)}
              />
            </div>
          </Modal>
        </>
      )}
    </Translation>
  );
};

export default ResourceNotesModalAdmin;

Logger.log('silly', `ResourceNotesModalAdmin loaded.`);
