import React, { useEffect } from 'react';
import { withRouter } from 'react-router';
import { Translation } from 'react-i18next';
import { List, Typography } from 'antd';

import QueryString from '../../../../../lib/QueryString';
import { pathTo } from '../../../Routes';
import Logger from '../../../../../lib/Logger';
import ResourceCard from './ResourceCard';
import ResourcesScreenTabs from './ResourcesScreenTabs';

const { Title } = Typography;
const ResourcesList = ({
  careGuideId,
  careGuide,
  partnershipId,
  partnershipName,
  component,
  page,
  limit,
  order,
  tab,
  search,
  serviceTags,
  total,
  load,
  history,
  onChangeTab,
  extraContent,
  width,
  patchFavorite,
  patch,
  remove,
  profileId,
  showDetail,
  showList,
  showForm,
  createInteraction,
  download,
  ...props
}) => {
  useEffect(() => {
    const filter = {};

    if (tab) filter[tab] = true;
    if (search !== '') filter.search = search;
    if (serviceTags) filter.service_tags = serviceTags;

    load(careGuideId, page, limit, order, filter);
  }, [careGuideId, page, limit, order, tab, search, serviceTags, load]);

  const pagination = careGuideId
    ? {
        onChange: (newPage, pageSize) => {
          const path = pathTo(component, {
            id: careGuideId,
            page: newPage,
            limit: pageSize,
          });
          history.push(QueryString.append(path));
        },
        current: page,
        pageSize: limit,
        total: total,
        showSizeChanger: false,
      }
    : null;

  return (
    <Translation>
      {(t) => (
        <>
          <ResourcesScreenTabs
            onChangeTab={onChangeTab}
            tab={tab}
            extraContent={
              !(width <= 576) ? extraContent(props.list.length, total) : {}
            }
          />
          {width <= 576 && (
            <Title
              level={5}
              style={{
                textAlign: 'center',
                color: '#7B7B7B',
                fontWeight: 'normal',
              }}
            >
              {t('resource_screen_tabs_title_show_resources', {
                showList: props.list.length,
                total: total,
              })}
            </Title>
          )}

          <List
            className="resources-list"
            dataSource={props.list}
            grid={{
              gutter: 18,
              xs: 1,
              sm: 2,
              md: 2,
              lg: 3,
              xl: 3,
              xxl: 3,
            }}
            pagination={page === 1 && total <= limit ? false : pagination}
            renderItem={(item) =>
              item?.id !== undefined && (
                <List.Item>
                  <ResourceCard
                    page={page}
                    limit={limit}
                    order={order}
                    load={load.bind(this)}
                    profileId={profileId}
                    careGuideId={careGuideId}
                    careGuide={careGuide}
                    partnershipId={partnershipId}
                    partnershipName={partnershipName}
                    item={item}
                    patchFavorite={patchFavorite.bind(this)}
                    patch={patch.bind(this)}
                    remove={remove.bind(this)}
                    showDetail={showDetail.bind(this)}
                    showList={showList.bind(this)}
                    showForm={showForm.bind(this)}
                    createInteraction={createInteraction.bind(this)}
                    search={search}
                    serviceTags={serviceTags}
                    download={download.bind(this)}
                  />
                </List.Item>
              )
            }
          />
        </>
      )}
    </Translation>
  );
};

export default withRouter(ResourcesList);

Logger.log('silly', `ResourcesList loaded.`);
