import React, {useState, useEffect} from "react";
import {Translation} from 'react-i18next';
import {Tag} from 'antd';

import {happy, smiling, neutral, sad, sadTear} from './Emoji';
import useWindowDimensions from '../../../../../lib/WindowDimensions';

const {CheckableTag} = Tag;

export default function RatingFilter({label, onChange, defaultValue=[], reset=null, ...props}) {

  const [selected, setSelected] = useState(defaultValue);

  const {width} = useWindowDimensions();
  const isLarge = width > 768;

  const optionStyle = {fontSize: isLarge ? '40px' : '25px'};
  const size = isLarge ? 30 : 20;

  const changeHandler = (val, checked) => {
    if (checked) {
      if (!selected.includes(val)) {
        setSelected([ val, ...selected])
      }
    } else {
      if (selected.includes(val)) {
        setSelected(selected.filter(x => x !== val))
      }
    }
  }

  useEffect(() => {
    if (reset) {
      setSelected([]);
    }
  }, [reset, setSelected]);

  useEffect(() => {
    onChange(selected);

    // eslint-disable-next-line
  }, [selected])

  return (
    <Translation>{(t) => 
      <div className="form-group ratings-input">
        <div className="ant-row ant-form-item ant-form-item-has-success">
          <div className="ant-col ant-form-item-label">
            <label title={label}>{label}</label>
          </div>
          <div className="ant-col ant-form-item-control">
            <div className="ant-form-item-control-input">
              <div className="ant-form-item-control-input-content">
                <CheckableTag
                  key={1}
                  checked={selected.includes(1)}
                  onChange={checked => changeHandler(1, checked)}
                >
                  <span role="img" aria-label="angry face" style={optionStyle}>{sadTear(size)}</span>
                </CheckableTag>
                <CheckableTag
                  key={2}
                  checked={selected.includes(2)}
                  onChange={checked => changeHandler(2, checked)}
                >
                  <span role="img" aria-label="slightly frowning face" style={optionStyle}>{sad(size)}</span>
                </CheckableTag>
                <CheckableTag
                  key={3}
                  checked={selected.includes(3)}
                  onChange={checked => changeHandler(3, checked)}
                >
                  <span role="img" aria-label="neutral face" style={optionStyle}>{neutral(size)}</span>
                </CheckableTag>
                <CheckableTag
                  key={4}
                  checked={selected.includes(4)}
                  onChange={checked => changeHandler(4, checked)}
                >
                  <span role="img" aria-label="slightly smiling face" style={optionStyle}>{smiling(size)}</span>
                </CheckableTag>
                <CheckableTag
                  key={5}
                  checked={selected.includes(5)}
                  onChange={checked => changeHandler(5, checked)}
                >
                  <span role="img" aria-label="grinning face" style={optionStyle}>{happy(size)}</span>
                </CheckableTag>
              </div>
            </div>
          </div>
        </div>
      </div>
    }</Translation>
  );
}
