import React, { Component } from 'react';
import { Translation } from 'react-i18next';
import { Col, PageHeader, Row } from 'antd';

import Logger from '../../../../../lib/Logger';
import PasswordResetForm from '../containers/PasswordResetFormContainer';
import { pathTo } from '../../../Routes';
import DocumentHead from '../../../elements/components/DocumentHead';
import QueryString from '../../../../../lib/QueryString';
import '../styles/PasswordReset.less';

class PasswordResetScreen extends Component {
  render() {
    const params = QueryString.parse(this.props.location.search);

    return (
      <Translation>
        {(t) => (
          <div className="screen screen-public screen-password-reset">
            <DocumentHead title={t('session_route_password_reset')} />

            <PageHeader
              onBack={() => this.props.history.push(pathTo('LoginScreen'))}
              title={t('session_password_reset_screen_title')}
              subTitle={t('session_password_reset_screen_form2_header')}
            />

            <div className="container-form-login">
              <Row type="flex" justify="space-around" align="middle">
                <Col xs={22} sm={18} md={16} lg={14} xl={8}>
                  <div class="outer-container">
                    <div class="inner-container">
                      <PasswordResetForm
                        code={'code' in params ? params.code : null}
                      />
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        )}
      </Translation>
    );
  }

  /* LIFECYCLE EVENTS */

  componentDidMount() {
    Logger.log('silly', `PasswordResetScreen.componentDidMount()`);
    if (typeof window !== 'undefined') {
      window.scrollTo(0, 0);
    }
  }

  componentDidUpdate() {
    Logger.log('silly', `PasswordResetScreen.componentDidUpdate()`);
  }

  componentWillUnmount() {
    Logger.log('silly', `PasswordResetScreen.componentWillUnmount()`);
  }
}

export default PasswordResetScreen;

Logger.log('silly', `PasswordResetScreen loaded.`);
