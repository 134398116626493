import React, {Component} from 'react';
import {Translation} from 'react-i18next';
import {Card, Col, Row, Space, Spin} from 'antd';
import {LoadingOutlined} from '@ant-design/icons';
import {pathTo} from '../../../Routes';
import {Redirect} from 'react-router-dom'
import {hasPermissionAdmin, pathRedirect} from '../../../elements/lib/hasPermissionAdmin';
import {getRouteFromPath} from '../../../Routes';

import CareGuidesAdminList from '../containers/CareGuidesAdminListContainer';
import DocumentHead from '../../../elements/components/DocumentHead';
import Config from '../../../../../Config';
import QueryString from '../../../../../lib/QueryString';
import Logger from '../../../../../lib/Logger';

class CareGuidesAdminScreen extends Component {

  static defaultProps = {
    limit: Config.get('DEFAULT_LIST_LENGTH', 10),
    isLoading: true,
  }

  scrollToTop = () => {
    Logger.log('debug', `CareGuidesAdminScreen.scrollToTop()`);
    if (typeof window !== 'undefined') {
      setTimeout(() => window.scrollTo(0, 0), 100);
    }
  }

  state = {
    redirect: null
  }


  render() {

    const loadingIcon = <Spin indicator={<LoadingOutlined style={{ fontSize: 20 }} spin />} />;
    const page = parseInt(this.props.match.params.page ? this.props.match.params.page : 1);
    const {order, ...filter} = QueryString.parse(this.props.location.search);

    const {redirect} = this.state;

    if (redirect) {
      return <Redirect to={pathTo(redirect)} />;
    }


    return (
      <Translation>{(t) => 
        <div>

          <DocumentHead title={t('care_guides_route_list')} />

          <Row>
            <Col span={24}>
              <Card
                title={
                  <Space size={10}>
                    {t('care_guides_list_title')}
                  </Space>
                }
                extra={this.props.isLoading ? loadingIcon : null}
              >
                <CareGuidesAdminList
                  key={page}
                  component="CareGuidesAdminScreen"
                  page={page}
                  limit={this.props.limit}
                  order={order || 'started_at.asc'}
                  filter={filter}
                />
              </Card>
            </Col>
          </Row>

        </div>
      }</Translation>
    )
  }

  /* LIFECYCLE EVENTS */

  componentDidMount() {
    this.scrollToTop();
  }

  componentDidUpdate(prevProps) {
    Logger.log('silly', `PartnershipAccountScreen.componentDidUpdate()`);
      const route = getRouteFromPath(this.props.location.pathname);   
      const screen =  hasPermissionAdmin(); 
      if(this.props.activeId !== prevProps.activeId && route.screen !== pathRedirect(screen)[0] ){
        this.setState({ redirect: pathRedirect(screen)[0]});
      } 
  }


}

export default CareGuidesAdminScreen;

Logger.log('silly', `CareGuidesAdminScreen loaded.`);
