import React, { useState, useEffect } from 'react';
import QueryString from '../../../../../lib/QueryString';
import CareGuideNoteListCard from "../containers/CareGuideNoteListCardContainer";
import { withRouter } from "react-router";
import { Translation } from "react-i18next";
import { pathTo } from '../../../Routes';
import { Pagination } from 'antd';

const CareGuideNoteListContent = ({ component, careGuideId, load, setPage, page, limit, order, filter, list, total, history, ...props }) => {

  const [selectId, setSelectId] = useState(null);

  const handleToggle = (id) => {
    if (id === selectId) {
      setSelectId(null);
    } else {
      setSelectId(id);
    }
  };

  const onChange = (newPage, pageSize) => {
    const path = pathTo(component, { id: careGuideId, page: newPage, limit: pageSize});
    history.push(QueryString.append(path, {order, ...filter}));
  }

  const getPageSizeOptions = (total) => {
    const options = ["10", "20"];
    if (total > 20) options.push("50");
    if (total > 50) options.push("100");
    return options;
  };

  const filterString = JSON.stringify(filter);
  useEffect(() => {
    load(careGuideId, page, limit, order, JSON.parse(filterString));
  }, [careGuideId, page, limit, order, filterString, load]);


  return (
    <Translation>{(t) =>
      <>
        {list.some((item) => item.is_pinned) && (
          <CareGuideNoteListCard
            order={order}
            limit={limit}
            filter={filter}
            page={page}
            careGuideId={careGuideId}
            handleToggle={handleToggle}
            selectId={selectId}
            title={t("care_guide_note_list_all_pin")}
            data={list.filter((item) => item.is_pinned)} />
        )}

        {list.some((item) => !item.is_pinned) && (
          <CareGuideNoteListCard
            order={order}
            limit={limit}
            filter={filter}
            page={page}
            careGuideId={careGuideId}
            handleToggle={handleToggle}
            selectId={selectId}
            title={t("care_guide_note_list_all_notes")}
            data={list.filter((item) => !item.is_pinned)} />
        )}
        {total > 10 && 
        <Pagination
          style={{textAlign: 'center'}}
          current={page}
          pageSize={limit}
          showSizeChanger={total > 10}
          position={["bottomCenter"]}
          pageSizeOptions={getPageSizeOptions(total)}
          onChange={onChange}
          total={total} />
        }
      </>
    }</Translation>
  );
};


// Ejemplo de u
export default withRouter(CareGuideNoteListContent);
