import React from 'react';
import {Translation, getI18n} from 'react-i18next';
import {Button, Form} from 'antd';
import {RightCircleOutlined} from '@ant-design/icons';

import PlanInput from '../containers/PlanInputContainer';
import message from '../../../elements/lib/MessageWrapper';
import Logger from '../../../../../lib/Logger';
import useWindowDimensions from '../../../../../lib/WindowDimensions';


const PlanForm = ({setIsChangingPlans=null, ...props}) => {

  const [form] = Form.useForm();
  const {width} = useWindowDimensions();

  const submitData = (values) => {
    Logger.log('debug', `PlanForm.submitData()`);

    const payload = {};
    if ('plan' in values && typeof values.plan === 'string') {
      const plan = values.plan.split(',');
      payload.plan_id = plan[0];
      payload.subscription_cycle = plan[1];
    }

    if (Object.keys(payload).length === 0) {
      message.error(getI18n().t('payemnts_plan_form_message_failure'));
    } else {
      props.update(props.accountId, payload, (success) => {
        if (success) {
          if (setIsChangingPlans) {
            setIsChangingPlans(false);
          }
        } else{
          message.error(getI18n().t('payemnts_plan_form_message_failure'));
        }
      });
    }
  }

  // form submit handler
  const handleFinish = async (values) => {
    Logger.log('debug', `PlanForm.handleFinish(###)`);
    if (!props.isSubmitting) {
      await submitData(values);
    }
  }

  // form error handler
  const handleFinishFailed = ({values, errorFields, outOfDate}) => {
    Logger.log('debug', `PlanForm.handleFinishFailed(###, ###, ###)`);
    message.error(getI18n().t('payemnts_plan_form_message_select_plan'));
    if (errorFields && errorFields.length > 0) {
      form.scrollToField(errorFields[0].name);
    }
  }

  // remove error message when input value changes
  const handleValuesChange = (changedValues, allValues) => {
    for (const key of Object.keys(changedValues)) {
      form.setFields([{name: key, errors: []}]);
    }
  }

  return (
    <Translation>{(t) =>
      <div className="payment-form payment-form-plan">

        <Form
          name="payment_plan_form"
          layout="vertical"
          form={form}
          initialValues={{plan: null}}
          onFinish={handleFinish}
          onFinishFailed={handleFinishFailed}
          onValuesChange={handleValuesChange}
          validateTrigger="onSubmit"
        >

          <PlanInput form={form} canChange={!props.isSubmitting && !props.clientSecret} />

          <div className="form-actions">
            <Form.Item style={{textAlign: 'center'}} hidden={props.clientSecret}>
              <Button
                type="primary"
                htmlType="submit"
                icon={<RightCircleOutlined />}
                loading={props.isSubmitting}
                block={width <= 576}
              >
                {t('payemnts_plan_form_button_submit')}
              </Button>
            </Form.Item>
          </div>

        </Form>
      </div>
    }</Translation>
  )
}

export default PlanForm;
