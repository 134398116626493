import React, {Component} from 'react';
import {Translation} from 'react-i18next';
import {Col, Row} from 'antd';

import InviteButton from '../../careGuideInvitations/containers/InviteButtonContainer';
import CareGuideDisabled from '../../careGuides/components/CareGuideDisabled';
import CareGuideWrapper from '../../../layouts/components/CareGuideWrapper';
import CareGuideHelpersList from '../containers/CareGuideHelpersListContainer';
import CareGuideHelperForm from '../containers/CareGuideHelperFormContainer';
import CareGuideInvitationForm from '../../careGuideInvitations/containers/CareGuideInvitationFormContainer';
import hasPermission, { CREATE_CARE_GUIDE_INVITATION, VIEW_ACCESS_CARE_GUIDE } from '../../../elements/lib/hasPermission';
import Loading from '../../../elements/components/Loading';
import DocumentHead from '../../../elements/components/DocumentHead';
import scrollToTop from '../../../elements/lib/scrollToTop';
import Config from '../../../../../Config';
import Logger from '../../../../../lib/Logger';
import { Redirect } from "react-router-dom";
import {pathTo} from '../../../Routes';

class CareTeamScreen extends Component {

  static defaultProps = {
    limit: Config.get('DEFAULT_LIST_LENGTH', 10),
    isLoading: true,
  }

  render() {
    const {careGuideId, page, limit, isCareGuideActive, isCareGuideLoading} = this.props;

    if (isCareGuideLoading) {
      return (
        <Translation>{(t) => 
          <CareGuideWrapper>
            <DocumentHead title={t('care_team_route_list')} />
            <Loading />
          </CareGuideWrapper>
        }</Translation>
      );
    }

    if(isCareGuideActive === null){
      return (
        <Redirect to={pathTo("HomeScreen")} />
      );
    }


    if (!isCareGuideActive) {
      return (
        <Translation>{(t) => 
          <CareGuideWrapper>
            <DocumentHead title={t('care_team_route_list')} />
            <CareGuideDisabled careGuideId={careGuideId} />
          </CareGuideWrapper>
        }</Translation>
      );
    }

    return (
      <Translation>{(t) => 
        <CareGuideWrapper>

          <DocumentHead title={t('care_team_route_list')} />

          {hasPermission(CREATE_CARE_GUIDE_INVITATION)
            ? <Row gutter={[16, 16]} className="page-actions">
                <Col span={24}>
                  <InviteButton careGuideId={careGuideId} showCount={true} />
                </Col>
              </Row>
            : null}

          <Row>
            <Col span={24}>

              <CareGuideHelpersList
                key={page}
                component="CareTeamScreen"
                careGuideId={careGuideId}
                page={page}
                limit={limit}
                order={'joined_at.asc'}
              />

            </Col>
          </Row>

          <CareGuideHelperForm
            careGuideId={careGuideId}
            page={page}
            limit={limit}
            order={'joined_at.asc'}
          />

          <CareGuideInvitationForm
            careGuideId={careGuideId}
            page={page}
            limit={limit}
            order={'joined_at.asc'}
          />

        </CareGuideWrapper>
      }</Translation>
    )
  }

  /* LIFECYCLE EVENTS */

  componentDidMount() {
    scrollToTop();
  }

  componentDidUpdate(prevProps) {
    if (this.props.isCareGuideActive) {
      if (this.props.careGuide?.partnership !== prevProps.partnershipActiveId) {
        if (this.props.careGuide.partnership) {
          this.props.setActiveAccess({ access_role_admin_in_care_guide: this.props.careGuide.partnership.access_role_admin_in_care_guide })

          if (this.props.accessCareGuide) {
            if (hasPermission(VIEW_ACCESS_CARE_GUIDE)) {
              if (!this.props.accessCareGuide?.care_team) {
                this.setState({ redirect: "HomeScreen" });
              }
            }
          }
        }
      }
    }
  }

}

export default CareTeamScreen;

Logger.log('silly', `CareTeamScreen loaded.`);
