import { Layout, Typography, Button, Row, Col } from 'antd';
import { pathTo } from '../../../Routes';
import { Redirect } from 'react-router-dom';
import { Translation } from 'react-i18next';
import CareGuideOwnersModalExport from '../containers/CareGuideOwnersModalExportContainer';
import Config from '../../../../../Config';
import Logger from '../../../../../lib/Logger';
import MembersListTableContainer from '../containers/MembersListTableContainer';
import DocumentHead from '../../../elements/components/DocumentHead';
import QueryString from '../../../../../lib/QueryString';
import React, { useState, useEffect, useCallback } from 'react';
import scrollToTop from '../../../elements/lib/scrollToTop';
import { BsFileEarmarkArrowDown } from 'react-icons/bs';

const { Title } = Typography;

const CareGuideOwnersScreen = (props) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [openModalExport, setOpenModalExport] = useState(false);

  const [redirect, setRedirect] = useState(null);

  const limit = parseInt(
    props.match.params.limit
      ? props.match.params.limit
      : Config.get('DEFAULT_LIST_LENGTH', 10)
  );

  const page = parseInt(props.match.params.page ? props.match.params.page : 1);

  const { order, ...filter } = QueryString.parse(props.location.search);

  const updateSelectedRowKeys = useCallback(
    (newKeys, insert, except) => {
      setSelectedRowKeys((prevSelectedRowKeys) => {
        if (except) {
          return newKeys;
        } else {
          if (insert) {
            return [...prevSelectedRowKeys, newKeys];
          } else {
            const indice = prevSelectedRowKeys.indexOf(newKeys);
            if (indice !== -1) {
              const newSelectedRowKeys = [...prevSelectedRowKeys];
              newSelectedRowKeys.splice(indice, 1);
              return newSelectedRowKeys;
            }
          }
        }
        return prevSelectedRowKeys;
      });
    },
    [setSelectedRowKeys]
  );

  const updateAllSelectedRowKeys = (newKeys, insert) => {
    setSelectedRowKeys((prevSelectedRowKeys) => {
      if (insert) {
        const newSelectedRowKeys = [...prevSelectedRowKeys];
        let newRows = [];
        newKeys.forEach((key) => {
          const indice = newSelectedRowKeys.indexOf(key);
          if (indice === -1) {
            newRows.push(key);
          }
        });
        return [...prevSelectedRowKeys, ...newRows];
      } else {
        // Eliminar elementos del array
        const newSelectedRowKeys = [...prevSelectedRowKeys];
        newKeys.forEach((key) => {
          const indice = newSelectedRowKeys.indexOf(key);
          if (indice !== -1) {
            newSelectedRowKeys.splice(indice, 1);
          }
        });
        return newSelectedRowKeys;
      }
    });
  };

  useEffect(() => {
    scrollToTop();
  }, []);

  useEffect(() => {
    if (props.accessTabs === null || !props.accessTabs?.members) {
      setRedirect('HomeScreen');
    }
  }, [props.accessTabs]);

  if (redirect) {
    return <Redirect to={pathTo(redirect)} />;
  }

  return (
    <Translation>
      {(t) => (
        <>
          <DocumentHead title={t('members_list_title')} />

          <Layout className="members-list-layout">
            <Row justify="space-between" align="top">
              <Col>
                <Title className="section-title">
                  {t('members_list_title')}
                </Title>
              </Col>
              <Col>
                <Button
                  icon={<BsFileEarmarkArrowDown />}
                  className="export-btn"
                  size="large"
                  onClick={() => setOpenModalExport(true)}
                >
                  {t('care_guide_owners_list_export')}
                </Button>
              </Col>
            </Row>
            <MembersListTableContainer
              selectedRowKeys={selectedRowKeys}
              updateSelectedRowKeys={updateSelectedRowKeys}
              updateAllSelectedRowKeys={updateAllSelectedRowKeys}
              component="CareGuideOwnersScreen"
              limit={limit}
              page={page}
              order={order || 'started_at.asc'}
              filter={filter}
            />
          </Layout>
          <CareGuideOwnersModalExport
            selectedRowKeys={selectedRowKeys}
            filter={filter}
            order={order || 'started_at.asc'}
            openModalExport={openModalExport}
            setOpenModalExport={setOpenModalExport}
          />
        </>
      )}
    </Translation>
  );
};

export default CareGuideOwnersScreen;

Logger.log('silly', `CareGuideOwnersScreen loaded.`);
