import {getI18n} from 'react-i18next';

import Config from '../../../../Config';
import Logger from '../../../../lib/Logger';
import Events from '../../../../lib/EventEmitter';

import CareTeamScreen from './containers/CareTeamScreenContainer';

const register = () => {
  Logger.log('debug', `careGuideHelpers.register()`);

  if (Config.getIn(['MODULE_TOGGLES', 'careGuideHelpers', 'routes'])) {
    Events.dispatch('ADD_MAIN_ROUTES',

      // screen name (key): [route type (element), path (prop), exact (prop), component (prop), route name]
      {
        'CareTeamScreen': ['PrivateRoute', '/care-guide/:id([\\w\\-]+)/care-team/:page(\\d+)?', true, CareTeamScreen, getI18n().t('care_team_route_list')],
      }
    );
  }
}

export default register;

Logger.log('silly', `careGuideHelpers.register() loaded.`);
