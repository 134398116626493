import CareGuideNoteList from '../components/CareGuideNoteList';
import {careGuideNoteShowForm } from '../../../../../state/modules/careGuideNotes/actions';
import { connect } from 'react-redux';

const mapStateToProps = (state, ownProps) => {

  return {
  }
}

const mapDispatchToProps = dispatch => {
  return {
    showForm: (careGuideNoteId) => {
      dispatch(careGuideNoteShowForm(careGuideNoteId));
    },
  }
}

const CareGuideNoteListContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(CareGuideNoteList);

export default CareGuideNoteListContainer;
