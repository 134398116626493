import Logger from "../../lib/Logger";

export const UI_CHANGE_MENU_COLLAPSED = "UI_CHANGE_MENU_COLLAPSED";
export const UI_SET_ROUTING_PREVENT_TRANSITION =
  "UI_SET_ROUTING_PREVENT_TRANSITION";
export const UI_CHANGE_MENU_ADMIN_COLLAPSED = "UI_CHANGE_MENU_ADMIN_COLLAPSED";

export function uiChangeMenuCollapsed() {
  Logger.log("debug", `[state.ui.actions] uiChangeMenuCollapsed()`);
  return {
    type: UI_CHANGE_MENU_COLLAPSED,
  };
}

export function uiSetRoutingPreventTransition(prevent) {
  Logger.log("debug", `[state.ui.actions] uiSetRoutingPreventTransition()`);
  return {
    type: UI_SET_ROUTING_PREVENT_TRANSITION,
    prevent: prevent,
  };
}

export function uiChangeMenuAdminCollapsed() {
  Logger.log("debug", `[state.ui.actions] uiChangeMenuAdminCollapsed()`);
  return {
    type: UI_CHANGE_MENU_ADMIN_COLLAPSED,
  };
}

Logger.log("silly", `state.ui.actions loaded.`);
