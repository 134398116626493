import React, { useState } from 'react';
import { Translation } from 'react-i18next';
import ReactQuill from 'react-quill';
import Logger from '../../../../../lib/Logger';
import {
  Col,
  Row,
  Typography,
  Upload,
  Button,
  Form,
  Input,
  Space,
  Checkbox,
} from 'antd';
import DescriptionToolbar from './DescriptionToolbar';
import { UploadOutlined } from '@ant-design/icons';
import ServiceTagInput from '../../resourceServiceTags/containers/ServiceTagInputContainer';

const { Title, Text } = Typography;

const modules = {
  toolbar: '#toolbar-resource',
};

const MemberResourceFormStep1 = ({
  form,
  data,
  isBeforeUpload,
  uploadIcon,
  handleFileChange,
  uploadedFile,
  onBeforeUpload,
  isPinned,
  isLoading,
  isSubmitting,
  value,
  setValue,
  setIsChangeValue,
  onChangePinned,
}) => {
  const [isDescriptionChange, setDescriptionChange] = useState(false);

  return (
    <Translation>
      {(t) => (
        <>
          <Title level={5}>{t('resources_basic_info')}</Title>
          <Row>
            <Col xs={{ span: 24, order: 1 }} sm={{ span: 12, order: 1 }}>
              <div className="form-group">
                <Form.Item
                  name="logo"
                  label={
                    <Space
                      direction="vertical"
                      style={{ marginBottom: '20px' }}
                    >
                      <Text>{t('resource_upload_button_title')}</Text>
                      <Text type="secondary">
                        {t('resource_upload_button_subtitle')}
                      </Text>
                    </Space>
                  }
                >
                  <Upload
                    className={isBeforeUpload ? 'resource-upload-file' : ''}
                    iconRender={uploadIcon}
                    onChange={(info) => handleFileChange(info)}
                    fileList={uploadedFile}
                    listType="picture"
                    beforeUpload={onBeforeUpload}
                    multiple={false}
                  >
                    {!isBeforeUpload && (
                      <Button icon={<UploadOutlined />}>
                        {t('resource_upload_button')}
                      </Button>
                    )}
                  </Upload>
                </Form.Item>
              </div>
            </Col>
            <Col xs={{ span: 24, order: 2 }} sm={{ span: 12, order: 2 }}>
              <div className="form-group">
                <Form.Item
                  name="is_pinned"
                  valuePropName="checked"
                  wrapperCol={{ offset: 1 }}
                  labelCol={{ offset: 1 }}
                >
                  <Checkbox
                    value={isPinned}
                    onChange={onChangePinned}
                    disabled={isLoading || isSubmitting}
                  >
                    {t('resources_is_pinned')}
                  </Checkbox>
                </Form.Item>
              </div>
            </Col>
          </Row>

          <Row>
            <Col xs={{ span: 24, order: 1 }} sm={{ span: 12, order: 1 }}>
              <div className="form-group">
                <Form.Item
                  name="name"
                  label={t('resources_name')}
                  rules={[
                    {
                      required: true,
                      message: t('feedback_validation_required'),
                    },
                    {
                      type: 'string',
                      min: 2,
                      max: 120,
                      message: t('feedback_validation_char_range', {
                        min: 2,
                        max: 120,
                      }),
                    },
                  ]}
                >
                  <Input disabled={isLoading || isSubmitting} />
                </Form.Item>
              </div>
            </Col>

            <Col xs={{ span: 24, order: 2 }} sm={{ span: 12, order: 2 }}>
              <div className="form-group">
                <Form.Item
                  name="telephone"
                  label={t('resources_telephone')}
                  wrapperCol={{ offset: 1 }}
                  labelCol={{ offset: 1 }}
                  rules={[
                    {
                      type: 'string',
                      min: 10,
                      max: 15,
                      message: t('feedback_validation_char_range', {
                        min: 10,
                        max: 15,
                      }),
                    },
                  ]}
                >
                  <Input disabled={isLoading || isSubmitting} />
                </Form.Item>
              </div>
            </Col>
          </Row>

          <Row>
            <Col xs={{ span: 24, order: 1 }} sm={{ span: 12, order: 1 }}>
              <div className="form-group">
                <Form.Item name="website" label={t('resources_website')}>
                  <Input disabled={isLoading || isSubmitting} />
                </Form.Item>
              </div>
            </Col>

            <Col xs={{ span: 24, order: 2 }} sm={{ span: 12, order: 2 }}>
              <div className="form-group">
                <Form.Item
                  wrapperCol={{ offset: 1 }}
                  labelCol={{ offset: 1 }}
                  name="email"
                  label={t('resources_email')}
                  rules={[
                    {
                      type: 'email',
                      message: t('feedback_validation_email'),
                    },
                  ]}
                >
                  <Input disabled={isLoading || isSubmitting} />
                </Form.Item>
              </div>
            </Col>
          </Row>

          <ServiceTagInput
            name="service_tags"
            label={t('resources_service_tags')}
            disabled={isLoading || isSubmitting}
            wrapperCol={{ span: 24 }}
            placeholder={t('resources_service_tags')}
          />

          <Form.Item name={'description'} style={{ display: 'none' }}>
            <Input type="hidden" />
          </Form.Item>

          <Form.Item
            label={t('resource_form_input_description')}
            wrapperCol={{ span: 24 }}
          >
            <DescriptionToolbar isChangeValue={isDescriptionChange} />
            <ReactQuill
              className="resource-form-admin-input-content"
              value={value}
              modules={modules}
              onChange={(content, delta, source, editor) => {
                setValue(content);
                form.setFields([
                  {
                    name: ['description'],
                    value: editor.getHTML(),
                  },
                ]);
                if (
                  content !== '<p><br></p>' &&
                  content !== data['description']
                ) {
                  setIsChangeValue(true);
                  setDescriptionChange(true);
                }
              }}
            />
          </Form.Item>
        </>
      )}
    </Translation>
  );
};

export default MemberResourceFormStep1;

Logger.log('silly', `MemberResourceFormStep1 loaded.`);
