import {List, Map} from 'immutable';

import {TYPES} from './actions';
import {SESSION_DESTROY} from '../../actions';
import Logger from '../../../lib/Logger';

const initialState = Map({
  isLoading: false,
  results: {},
  form: {},
  formIsVisible: false,
  formPostId: null,
  formReplyId: null,
});

export default function journalReplies(
  state=initialState,
  action
) {
  Logger.log('debug', `[state.journalReplies.reducers] journalReplies(%j, %j)`, state, action);

  switch(action.type) {

    case TYPES.LIST_REQUEST:
      return state.mergeDeep({
        isLoading: true
      });

    case TYPES.LIST_SUCCESS:
      return state.mergeDeep({
        isLoading: false,
        results: {
          [action.careGuideId]: {
            [action.journalPostId]: {
              total: action.total,
              pages: {
                [action.order]: {
                  [action.limit]: {
                    [action.page]: null
                  }
                }
              }
            }
          }
        },
        lastUpdated: action.receivedAt
      }).setIn(['results', action.careGuideId, action.journalPostId, 'pages', action.order, action.limit, action.page], List(action.result));

    case TYPES.LIST_FAILURE:
      return state.mergeDeep({
        isLoading: false
      });

    case TYPES.READ_REQUEST:
      return state.mergeDeep({
        isLoading: true
      });

    case TYPES.READ_SUCCESS:
      return state.mergeDeep({
        isLoading: false,
        lastUpdated: action.receivedAt
      });

    case TYPES.READ_FAILURE:
      return state.mergeDeep({
        isLoading: false
      });

    case TYPES.CREATE_REQUEST:
      return state.mergeDeep({
        form: {
          isSubmitting: true,
          success: null,
          errors: null
        }
      });

    case TYPES.CREATE_SUCCESS:
      return state.mergeDeep({
        form: {
          isSubmitting: false,
          success: true,
          errors: null
        },
        lastUpdated: action.receivedAt
      });

    case TYPES.CREATE_FAILURE:
      return state.mergeDeep({
        form: {
          isSubmitting: false,
          success: false,
          errors: null
        }
      }).setIn(['form', 'errors'], action.error);

    case TYPES.PATCH_REQUEST:
      return state.mergeDeep({
        form: {
          isSubmittingEdit: true,
          success: null,
          errors: null
        }
      });

    case TYPES.PATCH_SUCCESS:
      return state.mergeDeep({
        form: {
          isSubmittingEdit: false,
          success: true,
          errors: null
        },
        lastUpdated: action.receivedAt
      });

    case TYPES.PATCH_FAILURE:
      return state.mergeDeep({
        form: {
          isSubmittingEdit: false,
          success: false,
          errors: null
        }
      }).setIn(['form', 'errors'], action.error);

    case TYPES.FORM_DESTROY:
      return state.mergeDeep({
        form: {}
      }).set('form', action.form);

    case TYPES.SHOW_FORM:
      return state.mergeDeep({
        formIsVisible: true,
        formPostId: action.postId,
        formReplyId: action.replyId,
      });

    case TYPES.HIDE_FORM:
      return state.mergeDeep({
        formIsVisible: false,
        formPostId: null,
        formReplyId: null,
      });

    case SESSION_DESTROY:
      return initialState;

    default:
      return state;
  }
}

Logger.log('silly', `state.journalReplies.reducers loaded.`);
