import React from "react";
import { Translation } from "react-i18next";
import { Tag } from "antd";

const BooleanTag = ({ value, ...props }) => {
  return (
    <Translation>
      {(t) => (
        <Tag color={value ? "green" : "red"}>
          {value ? t("boolean_true") : t("boolean_false")}
        </Tag>
      )}
    </Translation>
  );
};

const RoleTag = ({ role, ...props }) => {
  const labels = {
    1: "registration_code_role_owner",
    2: "registration_code_role_admin",
    3: "registration_code_role_client",
    4: "registration_code_role_default",
  };

  const colors = {
    1: "success",
    2: "processing",
    3: "warning",
    4: "default",
  };
  return (
    <Translation>
      {(t) => <Tag color={colors[role]}>{t(labels[role])}</Tag>}
    </Translation>
  );
};

const StatusTag = ({ status, ...props }) => {
  const labels = {
    1: "status_enabled",
    2: "status_disabled",
    3: "status_archived",
    4: "status_deleted",
    5: "status_pending",
  };

  const colors = {
    1: "success",
    2: "default",
    3: "warning",
    4: "error",
    5: "processing",
  };

  return (
    <Translation>
      {(t) => <Tag color={colors[status]}>{t(labels[status])}</Tag>}
    </Translation>
  );
};

const ColorTag = ({color, ...props}) => {

  return (
    <Translation>{(t) =>
      <Tag color={color}>{color}</Tag>
    }</Translation>
  )
}

export { BooleanTag, StatusTag, RoleTag, ColorTag };
