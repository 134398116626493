import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { Translation } from 'react-i18next';
import { Col, PageHeader, Row } from 'antd';

import Logger from '../../../../../lib/Logger';
import RegisterStep2Form from '../containers/RegisterStep2FormContainer';
import TermsOfServiceModal from '../containers/TermsOfServiceModalContainer';
import DocumentHead from '../../../elements/components/DocumentHead';
import QueryString from '../../../../../lib/QueryString';
import { pathTo } from '../../../Routes';
import imageLogo from '../../../assets/images/logo-300x300.png';
import '../styles/Register.less';

class RegisterStep2Screen extends Component {
  render() {
    const params = QueryString.parse(this.props.location.search);
    const registrationId = 'rid' in params ? params.rid : null;
    const role = 'code' in params ? params.role : null;
    const partnershipId = 'pid' in params ? params.pid : null;

    if (!registrationId) {
      return <Redirect to={pathTo('RegisterStep1Screen')} />;
    }

    return (
      <Translation>
        {(t) => (
          <div className="screen screen-public screen-register">
            <DocumentHead title={t('register_route_step2')} />

            <PageHeader
              onBack={() =>
                this.props.history.push(pathTo('RegisterStep1Screen'))
              }
              title={t('register_screen_step2_page_title')}
              subTitle={t('register_screen_form2_header')}
            />

            <div className="logo">
              <img
                src={imageLogo}
                height="42"
                alt={t('app_name')}
                className=""
              />
            </div>

            <div className="container-form-register">
              <Row type="flex" justify="space-around" align="middle">
                <Col xs={22} sm={18} md={16} lg={14} xl={8}>
                  <div class="outer-container">
                    <div class="inner-container">
                      <h1 style={{ fontWeight: 'bold' }}>
                        {t('register_screen_step2_title')}
                      </h1>
                      <h2>{t('register_screen_step2_subtitle')}</h2>

                      <RegisterStep2Form
                        registrationId={registrationId}
                        role={role}
                        partnershipId={partnershipId}
                      />
                      <TermsOfServiceModal />
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        )}
      </Translation>
    );
  }

  /* LIFECYCLE EVENTS */

  componentDidMount() {
    Logger.log('silly', `RegisterStep2Screen.componentDidMount()`);
    if (typeof window !== 'undefined') {
      window.scrollTo(0, 0);
    }
  }

  componentDidUpdate() {
    Logger.log('silly', `RegisterStep2Screen.componentDidUpdate()`);
  }

  componentWillUnmount() {
    Logger.log('silly', `RegisterStep2Screen.componentWillUnmount()`);
  }
}

export default RegisterStep2Screen;

Logger.log('silly', `RegisterStep2Screen loaded.`);
