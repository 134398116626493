import React, {useEffect} from 'react';
import {Translation} from 'react-i18next';
import {Form, Select} from 'antd';
import {CrownOutlined} from '@ant-design/icons';

const TagInput = ({name, label, options, options_partner, list, list_partner, load, loadPartnerTags, activeId, onChange=null, rules=null, placeholder=null, ...props}) => {

  // load tags on mount
  useEffect(() => {
    load(1, 500);
    loadPartnerTags(activeId, 1, 500);
  }, [load, loadPartnerTags, activeId]);
  return (
    <Translation>{(t) => 
      <div className="form-group">
        <Form.Item
          name={name}
          label={label ? label : t('journal_posts_form_input_tags')}
          rules={rules}
        >
          <Select
            showSearch
            mode="multiple"
            disabled={props.isLoading || props.isSubmitting || props.isLoadingPartnerTags}
            loading={props.isLoading}
            onChange={onChange}
            showArrow={true}
            placeholder={placeholder}
          >
            {list.map(x => <Select.Option key={`${x.id}.tag`} value={`${x.id}.tag`}>{x.label}</Select.Option>)}
            {list_partner.map(x => <Select.Option key={`${x.id}.partner_tag`} value={`${x.id}.partner_tag`}>{x.label} <CrownOutlined/></Select.Option>)}
          </Select>
        </Form.Item>
      </div>
    }</Translation>
  );
}

export default TagInput;
