import React from 'react';
import {Translation} from 'react-i18next';

import CareGuidesSelector from '../../modules/careGuides/containers/CareGuidesSelectorContainer';
import Logger from '../../../../lib/Logger';
import {getRouteFromPath} from '../../Routes';


const PageHeader = ({pathname, ...props}) => {
  const route = getRouteFromPath(pathname);
  if (!route || ! route.route || route.route.length < 5) {
    return null;
  }
  return (
    <Translation>{(t) => 
      <>
        {props.careGuideCount && props.careGuideCount >= 1
          ? <h1 className="page-header">
              {route.route[4]} {t('layout_header_for')} <CareGuidesSelector currentPath={pathname} />
            </h1>
          : null}
      </>
    }</Translation>
  )
}

export default PageHeader;

Logger.log('silly', `PageHeader loaded.`);
