import React from 'react';
import { getI18n } from 'react-i18next';

import { Button, Cascader } from 'antd';
import { EllipsisOutlined } from '@ant-design/icons';
import { RiPushpin2Fill } from 'react-icons/ri';
import { FiEdit } from 'react-icons/fi';
import { AiOutlineDelete } from 'react-icons/ai';
import { withRouter } from 'react-router';

const CareGuideDocumentsActionColumn = ({
  row,
  showForm,
  openDeleteConfirmationModal,
  handlePinned,
}) => {
  const { is_pinned, id } = row;
  const menuItems = [
    {
      label: (
        <span className="member-document-table-menu-item">
          <FiEdit /> {getI18n().t('care_guide_documents_edit')}
        </span>
      ),
      value: 1,
    },
    {
      label: (
        <span className="member-document-table-menu-item">
          <RiPushpin2Fill style={{ paddingTop: '2px' }} />
          {getI18n().t(
            is_pinned
              ? 'care_guide_documents_unpin'
              : 'care_guide_documents_pin'
          )}
        </span>
      ),
      value: 2,
    },
    {
      label: (
        <span
          className="member-document-table-menu-item"
          style={{ color: '#F44336' }}
        >
          <AiOutlineDelete /> {getI18n().t('care_guide_documents_delete')}
        </span>
      ),
      value: 3,
    },
  ];

  const onChange = (item) => {
    const value = item[0];

    if (value === 1) {
      showForm(id);
    }
    if (value === 2) {
      handlePinned(id, is_pinned);
    }
    if (value === 3) {
      const path = `${row.file_directory}/${row.file_name}.${row.file_extension}`;
      openDeleteConfirmationModal(path, row);
    }
  };

  return (
    <span>
      <Cascader
        options={menuItems}
        onChange={onChange}
        dropdownMenuColumnStyle={{ height: '32px' }}
        defaultValue={[]}
        value={[]}
      >
        <Button type="text" onClick={(e) => e.stopPropagation()}>
          <EllipsisOutlined />
        </Button>
      </Cascader>
    </span>
  );
};

export default withRouter(CareGuideDocumentsActionColumn);
