import React from 'react';
import { withRouter } from 'react-router';
import { Translation } from 'react-i18next';
import { Col, Row, Steps } from 'antd';

const ResourceFormHeader = ({ id, step }) => {
  return (
    <Translation>
      {(t) => (
        <>
          <Row justify="center" align="top" style={{ marginBottom: '30px' }}>
            <Col>
              <Steps size="small" current={step}>
                <Steps.Step
                  style={{ width: '500px' }}
                  title={
                    id
                      ? t('resource_form_title_step1_edit')
                      : t('resource_form_title_step1_create')
                  }
                />
                <Steps.Step title={t('resource_form_title_step2')} />
              </Steps>
            </Col>
          </Row>
        </>
      )}
    </Translation>
  );
};

export default withRouter(ResourceFormHeader);
