import React, { useEffect } from 'react';
import { Translation } from 'react-i18next';

import ResourceAdminForm from '../containers/ResourceAdminFormContainer';
import DocumentHead from '../../../elements/components/DocumentHead';
import Logger from '../../../../../lib/Logger';

const ResourceAdminAddScreen = ({history, ...props}) => {
  
  const scrollToTop = () => {
    Logger.log('debug', `ResourceAdminAddScreen.scrollToTop()`);
    if (typeof window !== 'undefined') {
      setTimeout(() => window.scrollTo(0, 0), 100);
    }
  };

  useEffect(() => {
    scrollToTop();
  }, []);


  return (
    <Translation>
      {(t) => (
        <div>
          <DocumentHead title={t('resources_route_add')} />
          <ResourceAdminForm history={history}/>
        </div>
      )}
    </Translation>
  );
};

export default ResourceAdminAddScreen;

Logger.log('silly', `ResourceAdminAddScreen loaded.`);
