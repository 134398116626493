import { normalize } from 'normalizr';

import api from '../../api';
import { schema } from '../../schema';
import { addEntities, removeEntity } from '../../actions';
import Logger from '../../../lib/Logger';
import Format from '../../../lib/Format';

export const TYPES = {
  LIST_REQUEST: 'RESOURCE_NOTES/LIST_REQUEST',
  LIST_SUCCESS: 'RESOURCE_NOTES/LIST_SUCCESS',
  LIST_FAILURE: 'RESOURCE_NOTES/LIST_FAILURE',
  LIST_REQUEST_ADMIN: 'RESOURCE_NOTES/LIST_REQUEST_ADMIN',
  LIST_SUCCESS_ADMIN: 'RESOURCE_NOTES/LIST_SUCCESS_ADMIN',
  LIST_FAILURE_ADMIN: 'RESOURCE_NOTES/LIST_FAILURE_ADMIN',
  READ_REQUEST: 'RESOURCE_NOTES/READ_REQUEST',
  READ_SUCCESS: 'RESOURCE_NOTES/READ_SUCCESS',
  READ_FAILURE: 'RESOURCE_NOTES/READ_FAILURE',
  CREATE_REQUEST: 'RESOURCE_NOTES/CREATE_REQUEST',
  CREATE_SUCCESS: 'RESOURCE_NOTES/CREATE_SUCCESS',
  CREATE_FAILURE: 'RESOURCE_NOTES/CREATE_FAILURE',
  PATCH_REQUEST: 'RESOURCE_NOTES/PATCH_REQUEST',
  PATCH_SUCCESS: 'RESOURCE_NOTES/PATCH_SUCCESS',
  PATCH_FAILURE: 'RESOURCE_NOTES/PATCH_FAILURE',
  DELETE_REQUEST: 'RESOURCE_NOTES/DELETE_REQUEST',
  DELETE_SUCCESS: 'RESOURCE_NOTES/DELETE_SUCCESS',
  DELETE_FAILURE: 'RESOURCE_NOTES/DELETE_FAILURE',
  FORM_DESTROY: 'RESOURCE_NOTES/FORM_DESTROY',
  SHOW_LIST: 'RESOURCE_NOTES/SHOW_LIST',
  HIDE_LIST: 'RESOURCE_NOTES/HIDE_LIST',
  SHOW_LIST_ADMIN: 'RESOURCE_NOTES/SHOW_LIST_ADMIN',
  HIDE_LIST_ADMIN: 'RESOURCE_NOTES/HIDE_LIST_ADMIN',
  CSV_DOWNLOAD_REQUEST: 'RESOURCE_NOTES/CSV_DOWNLOAD_REQUEST',
  CSV_DOWNLOAD_SUCCESS: 'RESOURCE_NOTES/CSV_DOWNLOAD_SUCCESS',
  CSV_DOWNLOAD_FAILURE: 'RESOURCE_NOTES/CSV_DOWNLOAD_FAILURE',
};

export function resourceNoteListRequest(
  careGuideId,
  resourceId,
  page,
  limit,
  order,
  filter
) {
  Logger.log(
    'debug',
    `[state.resourceNotes.actions] resourceNoteListRequest(${careGuideId}, ${resourceId}, ${page}, ${limit}, ${order}, %j)`,
    filter
  );
  return {
    type: TYPES.LIST_REQUEST,
    careGuideId: careGuideId,
    resourceId: resourceId,
    page: page,
    limit: limit,
    order: order,
    filter: filter,
  };
}

export function resourceNoteListSuccess(careGuideId, resourceId, data) {
  Logger.log(
    'debug',
    `[state.resourceNotes.actions] resourceNoteListSuccess(${careGuideId}, ${resourceId}, %j)`,
    data
  );
  return {
    type: TYPES.LIST_SUCCESS,
    careGuideId: careGuideId,
    resourceId: resourceId,
    page: data.page,
    limit: data.limit,
    order: data.order,
    result: data.result,
    total: data.total,
    receivedAt: Date.now(),
  };
}

export function resourceNoteListFailure(careGuideId, resourceId, error) {
  Logger.log(
    'debug',
    `[state.resourceNotes.actions] resourceNoteListFailure(${careGuideId}, ${resourceId}, %j)`,
    error
  );
  return {
    type: TYPES.LIST_FAILURE,
    careGuideId: careGuideId,
    resourceId: resourceId,
    error: error,
  };
}

export function resourceNoteListAdminRequest(
  partnershipId,
  resourceId,
  page,
  limit,
  order,
  filter
) {
  Logger.log(
    'debug',
    `[state.resourceNotes.actions] resourceNoteListAdminRequest(${partnershipId}, ${resourceId}, ${page}, ${limit}, ${order}, %j)`,
    filter
  );
  return {
    type: TYPES.LIST_REQUEST,
    partnershipId: partnershipId,
    resourceId: resourceId,
    page: page,
    limit: limit,
    order: order,
    filter: filter,
  };
}

export function resourceNoteListAdminSuccess(partnershipId, resourceId, data) {
  Logger.log(
    'debug',
    `[state.resourceNotes.actions] resourceNoteListAdminSuccess(${partnershipId}, ${resourceId}, %j)`,
    data
  );
  return {
    type: TYPES.LIST_SUCCESS,
    partnershipId: partnershipId,
    resourceId: resourceId,
    page: data.page,
    limit: data.limit,
    order: data.order,
    result: data.result,
    total: data.total,
    receivedAt: Date.now(),
  };
}

export function resourceNoteListAdminFailure(partnershipId, resourceId, error) {
  Logger.log(
    'debug',
    `[state.resourceNotes.actions] resourceNoteListAdminFailure(${partnershipId}, ${resourceId}, %j)`,
    error
  );
  return {
    type: TYPES.LIST_FAILURE,
    partnershipId: partnershipId,
    resourceId: resourceId,
    error: error,
  };
}

export function resourceNoteReadRequest(careGuideId, resourceId, id) {
  Logger.log(
    'debug',
    `[state.resourceNotes.actions] resourceNoteReadRequest(${careGuideId}, ${resourceId}, ${id})`
  );
  return {
    type: TYPES.READ_REQUEST,
    careGuideId: careGuideId,
    resourceId: resourceId,
    id: id,
  };
}

export function resourceNoteReadSuccess(careGuideId, resourceId, id, data) {
  Logger.log(
    'debug',
    `[state.resourceNotes.actions] resourceNoteReadSuccess(${careGuideId}, ${resourceId}, ${id}, %j)`,
    data
  );
  return {
    type: TYPES.READ_SUCCESS,
    careGuideId: careGuideId,
    resourceId: resourceId,
    id: data.id,
    receivedAt: Date.now(),
  };
}

export function resourceNoteReadFailure(careGuideId, resourceId, id, error) {
  Logger.log(
    'debug',
    `[state.resourceNotes.actions] resourceNoteReadFailure(${careGuideId}, ${resourceId}, ${id}, %j)`,
    error
  );
  return {
    type: TYPES.READ_FAILURE,
    careGuideId: careGuideId,
    resourceId: resourceId,
    id: id,
    error: error,
  };
}

export function resourceNoteCreateRequest(careGuideId, resourceId, data) {
  Logger.log(
    'debug',
    `[state.resourceNotes.actions] resourceNoteCreateRequest(${careGuideId}, ${resourceId}, %j)`,
    data
  );
  return {
    type: TYPES.CREATE_REQUEST,
    careGuideId: careGuideId,
    resourceId: resourceId,
  };
}

export function resourceNoteCreateSuccess(careGuideId, resourceId, data) {
  Logger.log(
    'debug',
    `[state.resourceNotes.actions] resourceNoteCreateSuccess(${careGuideId}, ${resourceId}, %j)`,
    data
  );
  return {
    type: TYPES.CREATE_SUCCESS,
    careGuideId: careGuideId,
    resourceId: resourceId,
    id: data.id,
    receivedAt: Date.now(),
  };
}

export function resourceNoteCreateFailure(careGuideId, resourceId, error) {
  Logger.log(
    'debug',
    `[state.resourceNotes.actions] resourceNoteCreateFailure(${careGuideId}, ${resourceId}, %j)`,
    error
  );
  return {
    type: TYPES.CREATE_FAILURE,
    careGuideId: careGuideId,
    resourceId: resourceId,
    error: error,
  };
}

export function resourceNotePatchRequest(careGuideId, resourceId, id, data) {
  Logger.log(
    'debug',
    `[state.resourceNotes.actions] resourceNotePatchRequest(${careGuideId}, ${resourceId}, ${id}, %j)`,
    data
  );
  return {
    type: TYPES.PATCH_REQUEST,
    careGuideId: careGuideId,
    resourceId: resourceId,
    id: id,
  };
}

export function resourceNotePatchSuccess(careGuideId, resourceId, id, data) {
  Logger.log(
    'debug',
    `[state.resourceNotes.actions] resourceNotePatchSuccess(${careGuideId}, ${resourceId}, ${id}, %j)`,
    data
  );
  return {
    type: TYPES.PATCH_SUCCESS,
    careGuideId: careGuideId,
    resourceId: resourceId,
    id: id,
    receivedAt: Date.now(),
  };
}

export function resourceNotePatchFailure(careGuideId, resourceId, id, error) {
  Logger.log(
    'debug',
    `[state.resourceNotes.actions] resourceNotePatchFailure(${careGuideId}, ${resourceId}, ${id}, %j)`,
    error
  );
  return {
    type: TYPES.PATCH_FAILURE,
    careGuideId: careGuideId,
    resourceId: resourceId,
    id: id,
    error: error,
  };
}

export function resourceNoteFormDestroy(formState = null) {
  Logger.log(
    'debug',
    `[state.resourceNotes.actions] resourceNoteFormDestroy(%j)`,
    formState
  );
  return {
    type: TYPES.FORM_DESTROY,
    form: formState,
  };
}

export function resourceNoteShowList(resourceId = null) {
  Logger.log(
    'debug',
    `[state.resourceNotes.actions] resourceNoteShowList(${resourceId})`
  );
  return {
    type: TYPES.SHOW_LIST,
    resourceId: resourceId,
  };
}

export function resourceNoteHideList() {
  Logger.log('debug', `[state.resourceNotes.actions] resourceNoteHideList()`);
  return {
    type: TYPES.HIDE_LIST,
  };
}

export function resourceNoteShowListAdmin(resourceId = null) {
  Logger.log(
    'debug',
    `[state.resourceNotes.actions] resourceNoteShowListAdmin(${resourceId})`
  );
  return {
    type: TYPES.SHOW_LIST_ADMIN,
    resourceId: resourceId,
  };
}

export function resourceNoteHideListAdmin() {
  Logger.log(
    'debug',
    `[state.resourceNotes.actions] resourceNoteHideListAdmin()`
  );
  return {
    type: TYPES.HIDE_LIST_ADMIN,
  };
}

export function resourceNoteCSVDownloadRequest(
  partnershipId,
  resourceId,
  order,
  filter
) {
  Logger.log(
    'debug',
    `[state.resourceNotes.actions] resourceNoteCSVDownloadRequest(${partnershipId}, ${resourceId})`
  );
  return {
    type: TYPES.CSV_DOWNLOAD_REQUEST,
    order: order,
    filter: filter,
  };
}

export function resourceNoteCSVDownloadSuccess() {
  Logger.log(
    'debug',
    `[state.resourceNotes.actions] resourceNoteCSVDownloadSuccess()`
  );
  return {
    type: TYPES.CSV_DOWNLOAD_SUCCESS,
  };
}

export function resourceNoteCSVDownloadFailure() {
  Logger.log(
    'debug',
    `[state.resourceNotes.actions] resourceNoteCSVDownloadFailure()`
  );
  return {
    type: TYPES.CSV_DOWNLOAD_FAILURE,
  };
}

export function resourceNoteDeleteRequest(careGuideId, id) {
  Logger.log(
    'debug',
    `[state.resources.actions] resourceNoteDeleteRequest(${careGuideId}, ${id})`
  );
  return {
    type: TYPES.DELETE_REQUEST,
    careGuideId: careGuideId,
    id: id,
  };
}

export function resourceNoteDeleteSuccess(careGuideId, id) {
  Logger.log(
    'debug',
    `[state.resources.actions] resourceNoteDeleteSuccess(${careGuideId}, ${id})`
  );
  return {
    type: TYPES.DELETE_SUCCESS,
    careGuideId: careGuideId,
    id: id,
  };
}

export function resourceNoteDeleteFailure(careGuideId, error) {
  Logger.log(
    'debug',
    `[state.resources.actions] resourceNoteDeleteFailure(${careGuideId}, %j)`,
    error
  );
  return {
    type: TYPES.DELETE_FAILURE,
    careGuideId: careGuideId,
    error: error,
  };
}

// API THUNK ACTION CREATORS

export function loadResourceNotes(
  careGuideId,
  resourceId,
  page = 1,
  limit = 10,
  order = null,
  filter = null,
  cb = function () {}
) {
  Logger.log(
    'debug',
    `[state.resourceNotes.actions] loadResourceNotes(${careGuideId}, ${resourceId}, ${page}, ${limit}, ${order}, %j, ###)`,
    filter
  );

  return async function (dispatch) {
    dispatch(
      resourceNoteListRequest(
        careGuideId,
        resourceId,
        page,
        limit,
        order,
        filter
      )
    );

    // call API
    const response = await api.getCareGuideResourceNotes(
      careGuideId,
      resourceId,
      page,
      limit,
      order,
      filter
    );
    let success = false;
    let total = 0;

    // get resource notes list success
    if (200 === response.get('status')) {
      Logger.log(
        'info',
        `Get API resource notes list success. Care Guide ID: ${careGuideId}, Post ID: ${resourceId}, Page: ${page}, Limit: ${limit}, Order: ${order}.`
      );

      const normalizedEntities = normalize(
        response.getIn(['data', 'resource_notes']),
        [schema.resourceNote]
      );
      const data = {
        page: response.getIn(['data', 'page']),
        limit: response.getIn(['data', 'limit']),
        order: order,
        total: response.getIn(['data', 'total']),
        result: normalizedEntities.result,
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(resourceNoteListSuccess(careGuideId, resourceId, data));
      success = true;
      total = response.getIn(['data', 'total']);
    } else if (1 === page && 204 === response.get('status')) {
      Logger.log(
        'info',
        `Get API resource notes list success [empty]. Care Guide ID: ${careGuideId}, Post ID: ${resourceId}, Page: ${page}, Limit: ${limit}, Order: ${order}.`
      );
      const data = {
        page: page,
        limit: limit,
        order: order,
        total: 0,
        result: [],
      };
      dispatch(resourceNoteListSuccess(careGuideId, resourceId, data));
      success = true;

      // get resource notes list failure
    } else {
      Logger.log(
        'info',
        `Get API resource notes list failure. Care Guide ID: ${careGuideId}, Post ID: ${resourceId}, Page: ${page}, Limit: ${limit}, Order: ${order}.`
      );
      dispatch(
        resourceNoteListFailure(
          careGuideId,
          resourceId,
          response.getIn(['data', 'error'])
        )
      );
    }

    // callback function
    cb(success, total);
  };
}

export function loadResourceNotesAdmin(
  partnershipId,
  resourceId,
  page = 1,
  limit = 10,
  order = null,
  filter = null,
  cb = function () {}
) {
  Logger.log(
    'debug',
    `[state.resourceNotes.actions] loadResourceNotesAdmin(${partnershipId}, ${resourceId}, ${page}, ${limit}, ${order}, %j, ###)`,
    filter
  );

  return async function (dispatch) {
    dispatch(
      resourceNoteListRequest(
        partnershipId,
        resourceId,
        page,
        limit,
        order,
        filter
      )
    );

    // call API
    const response = await api.getCareGuideResourceNotesAdmin(
      partnershipId,
      resourceId,
      page,
      limit,
      order,
      filter
    );
    let success = false;
    let total = 0;

    // get resource notes list success
    if (200 === response.get('status')) {
      Logger.log(
        'info',
        `Get API resource notes list success. Partnership ID: ${partnershipId}, Post ID: ${resourceId}, Page: ${page}, Limit: ${limit}, Order: ${order}.`
      );

      const normalizedEntities = normalize(
        response.getIn(['data', 'resource_notes']),
        [schema.resourceNote]
      );
      const data = {
        page: response.getIn(['data', 'page']),
        limit: response.getIn(['data', 'limit']),
        order: order,
        total: response.getIn(['data', 'total']),
        result: normalizedEntities.result,
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(resourceNoteListSuccess(partnershipId, resourceId, data));
      success = true;
      total = response.getIn(['data', 'total']);
    } else if (1 === page && 204 === response.get('status')) {
      Logger.log(
        'info',
        `Get API resource notes list success [empty]. Partnership ID: ${partnershipId}, Post ID: ${resourceId}, Page: ${page}, Limit: ${limit}, Order: ${order}.`
      );
      const data = {
        page: page,
        limit: limit,
        order: order,
        total: 0,
        result: [],
      };
      dispatch(resourceNoteListSuccess(partnershipId, resourceId, data));
      success = true;

      // get resource notes list failure
    } else {
      Logger.log(
        'info',
        `Get API resource notes list failure. Partnership ID: ${partnershipId}, Post ID: ${resourceId}, Page: ${page}, Limit: ${limit}, Order: ${order}.`
      );
      dispatch(
        resourceNoteListFailure(
          partnershipId,
          resourceId,
          response.getIn(['data', 'error'])
        )
      );
    }

    // callback function
    cb(success, total);
  };
}

export function loadResourceNote(
  careGuideId,
  resourceId,
  id,
  cb = function () {}
) {
  Logger.log(
    'debug',
    `[state.resourceNotes.actions] loadResourceNote(${careGuideId}, ${resourceId}, ${id}, ###)`
  );

  return async function (dispatch) {
    dispatch(resourceNoteReadRequest(careGuideId, resourceId, id));

    // call API
    const response = await api.getCareGuideResourceNote(
      careGuideId,
      resourceId,
      id
    );
    let success = false;

    // get resource note success
    if (200 === response.get('status')) {
      Logger.log(
        'info',
        `Get API resource note success. Care Guide ID: ${careGuideId}, Post ID: ${resourceId}, ID: ${id}.`
      );

      const normalizedEntities = normalize(
        [response.getIn(['data', 'resource_note'])],
        [schema.resourceNote]
      );
      const data = {
        id: response.getIn(['data', 'resource_note', 'id']),
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(resourceNoteReadSuccess(careGuideId, resourceId, id, data));
      success = true;

      // get resource note failure
    } else {
      Logger.log(
        'info',
        `Get API resource note failure. Care Guide ID: ${careGuideId}, Post ID: ${resourceId}, ID: ${id}.`
      );
      dispatch(
        resourceNoteReadFailure(
          careGuideId,
          resourceId,
          id,
          response.getIn(['data', 'error'])
        )
      );
    }

    // callback function
    cb(success);
  };
}

export function createResourceNote(
  careGuideId,
  resourceId,
  data,
  cb = function () {}
) {
  Logger.log(
    'debug',
    `[state.resourceNotes.actions] createResourceNote(${careGuideId}, ${resourceId}, %j, ###)`,
    data
  );

  return async function (dispatch) {
    dispatch(resourceNoteCreateRequest(careGuideId, resourceId, data));

    // call API
    const response = await api.postCareGuideResourceNotes(
      careGuideId,
      resourceId,
      data
    );
    let success = false;

    // post resource note success
    if (201 === response.get('status')) {
      Logger.log(
        'info',
        `POST API resource note success. Care Guide ID: ${careGuideId}, Post ID: ${resourceId}.`
      );

      const normalizedEntities = normalize(
        [response.getIn(['data', 'resource_note'])],
        [schema.resourceNote]
      );
      const data = {
        id: response.getIn(['data', 'resource_note', 'id']),
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(resourceNoteCreateSuccess(careGuideId, resourceId, data));
      success = true;

      // post resource note failure
    } else {
      Logger.log(
        'info',
        `POST API resource note failure. Care Guide ID: ${careGuideId}, Post ID: ${resourceId}.`
      );
      dispatch(
        resourceNoteCreateFailure(
          careGuideId,
          resourceId,
          response.getIn(['data', 'error'])
        )
      );
    }

    // callback function
    cb(success);
  };
}

export function patchResourceNote(
  careGuideId,
  resourceId,
  id,
  data,
  cb = function () {}
) {
  Logger.log(
    'debug',
    `[state.resourceNotes.actions] patchResourceNote(${careGuideId}, ${resourceId}, ${id}, %j, ###)`,
    data
  );

  return async function (dispatch) {
    dispatch(resourceNotePatchRequest(careGuideId, resourceId, id, data));

    // call API
    const response = await api.patchCareGuideResourceNote(
      careGuideId,
      resourceId,
      id,
      data
    );
    let success = false;

    // patch resource note success
    if (200 === response.get('status')) {
      Logger.log(
        'info',
        `PATCH API resource note success. Care Guide ID: ${careGuideId}, Post ID: ${resourceId}, ID: ${id}.`
      );

      const normalizedEntities = normalize(
        [response.getIn(['data', 'resource_note'])],
        [schema.resourceNote]
      );
      const data = {
        id: response.getIn(['data', 'resource_note', 'id']),
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(resourceNotePatchSuccess(careGuideId, resourceId, id, data));
      success = true;

      // patch resource note failure
    } else {
      Logger.log(
        'info',
        `PATCH API resource note failure. Care Guide ID: ${careGuideId}, Post ID: ${resourceId}, ID: ${id}.`
      );
      dispatch(
        resourceNotePatchFailure(
          careGuideId,
          resourceId,
          id,
          response.getIn(['data', 'error'])
        )
      );
    }

    // callback function
    cb(success);
  };
}

export function deleteResourceNote(
  careGuideId,
  resourceId,
  id,
  cb = function () {}
) {
  Logger.log('debug', `[state.resources.actions] deleteResource(${id}, ###)`);

  return async function (dispatch) {
    dispatch(resourceNoteDeleteRequest(careGuideId, resourceId, id));

    // call API
    const response = await api.deleteCareGuideResourceNote(
      careGuideId,
      resourceId,
      id
    );
    let success = false;

    // delete resource success
    if (204 === response.get('status')) {
      Logger.log(
        'info',
        `DELETE API resource success. Care Guide ID: ${careGuideId}, Resource ID: ${resourceId}, ID: ${id}.`
      );

      dispatch(removeEntity({ entityType: 'resourceNotes', id: id }));
      dispatch(resourceNoteDeleteSuccess(careGuideId, resourceId, id));
      success = true;

      // get resource failure
    } else {
      Logger.log(
        'info',
        `DELETE API resource failure. Care Guide ID: ${careGuideId}, ID: ${id}.`
      );
      dispatch(
        resourceNoteDeleteFailure(
          careGuideId,
          response.getIn(['data', 'error'])
        )
      );
    }

    // callback function
    cb(success);
  };
}

export function downloadResourceNotesCSV(
  partnershipId = null,
  resourceId = null,
  order = null,
  filter = null,
  cb = function () {}
) {
  Logger.log(
    'debug',
    `[state.resourceNotes.actions] downloadResourceNotesCSV(${order}, ${filter}, ###)`
  );

  return async function (dispatch) {
    dispatch(
      resourceNoteCSVDownloadRequest(partnershipId, resourceId, order, filter)
    );

    // call API
    const response = await api.getResourceNotesCSV(
      partnershipId,
      resourceId,
      order,
      filter
    );

    // get resource notes CSV success
    if (200 === response.get('status')) {
      Logger.log('info', `Get API resource notes CSV success. Order: ${order}`);
      const now = new Date();

      // trigger browser download
      const url = window.URL.createObjectURL(new Blob([response.get('data')]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        `cv-resource-notes-${Format.date(now, 'YYYY-MM-DDTHHmm')}.csv`
      );
      document.body.appendChild(link);
      link.click();

      dispatch(resourceNoteCSVDownloadSuccess());

      // get resourceNotes CSV failure
    } else {
      Logger.log('info', `Get API resourceNotes CSV failure.`);
      dispatch(resourceNoteCSVDownloadFailure());
    }

    // callback function
    cb();
  };
}

Logger.log('silly', `state.resourceNotes.actions loaded.`);
