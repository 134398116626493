import React from "react";
import { Translation } from "react-i18next";
import { Space } from "antd";
import { MinusCircleOutlined } from "@ant-design/icons";

import MembersInput from "../../careGuideHelpers/containers/MembersInputContainer";
import CareGuidesMembersPartnership from "../../careGuideHelpers/components/CareGuidesMembersPartnership";

import PublicStatusInput from "./PublicStatusInput";

const ParticipantInputRow = ({
  isMultipleCareGuide,
  partnershipId,
  participant,
  options,
  index,
  remove,
  careGuideIds,
  disabled = false,
  ...props
}) => {

  return (
    <Translation>
      {(t) => (
        <div key={participant.key} className="participant-input-row">
          <Space style={{ display: "flex" }} align="start">
            {isMultipleCareGuide ? (
              <MembersInput
                name={[participant.name, "profile", "id"]}
                fieldKey={[participant.fieldKey, "profile", "id"]}
                label={t("calendar_event_participant_user")}
                careGuideIds={careGuideIds}
                rules={[
                  {
                    required: true,
                    message: t("feedback_validation_required"),
                  },
                ]}
              />
            ) : (
              <CareGuidesMembersPartnership
                options={options}
                name={[participant.name, "profile", "id"]}
                fieldKey={[participant.fieldKey, "profile", "id"]}
                label={t("calendar_event_participant_user")}
                partnershipId={partnershipId}
                rules={[
                  {
                    required: true,
                    message: t("feedback_validation_required"),
                  },
                ]}
              />
            )}

            <PublicStatusInput
              name={[participant.name, "public_status"]}
              fieldKey={[participant.fieldKey, "public_status"]}
              label={t("calendar_event_participant_public_status")}
              rules={[
                { required: true, message: t("feedback_validation_required") },
              ]}
              disabled={disabled}
            />

            <MinusCircleOutlined
              onClick={() => {
                remove(participant.name);
              }}
            />
          </Space>
        </div>
      )}
    </Translation>
  );
};

export default ParticipantInputRow;
