import { connect } from 'react-redux';
import moment from 'moment';
import { denormalize } from 'normalizr';

import { schema } from '../../../../../state/schema';
import JournalPostsFilter from '../components/JournalPostsFilter';
import { generateJournalPostsPDF } from '../../../../../state/modules/journalPosts/actions';

const mapStateToProps = (state, ownProps) => {
  const careGuide = ownProps.careGuideId
    ? denormalize(
        state.entities.getIn(['careGuides', ownProps.careGuideId]),
        schema.careGuide,
        state.entities.toJS()
      )
    : null;

  return {
    careGuide,
    isGeneratingPDF: state.journalPosts.get('isGeneratingPDF'),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    generatePDF: (careGuideId, page, limit, order, filter, cb) => {
      dispatch(
        generateJournalPostsPDF(
          careGuideId,
          page,
          limit,
          order,
          { tz: moment().utcOffset() / 60, ...filter },
          cb
        )
      );
    },
  };
};

const JournalPostsFilterContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(JournalPostsFilter);

export default JournalPostsFilterContainer;
