import {normalize} from 'normalizr';

import api from '../../api';
import {schema} from '../../schema';
import {addEntities} from '../../actions';
import Logger from '../../../lib/Logger';

export const TYPES = {
  READ_REQUEST: 'ACCOUNTS/READ_REQUEST',
  READ_SUCCESS: 'ACCOUNTS/READ_SUCCESS',
  READ_FAILURE: 'ACCOUNTS/READ_FAILURE',
  UPDATE_PLAN_REQUEST: 'ACCOUNTS/UPDATE_PLAN_REQUEST',
  UPDATE_PLAN_SUCCESS: 'ACCOUNTS/UPDATE_PLAN_SUCCESS',
  UPDATE_PLAN_FAILURE: 'ACCOUNTS/UPDATE_PLAN_FAILURE',
};

export function accountReadRequest(id) {
  Logger.log('debug', `[state.accounts.actions] accountReadRequest(${id})`);
  return {
    type: TYPES.READ_REQUEST,
    id: id
  }
}

export function accountReadSuccess(id, data) {
  Logger.log('debug', `[state.accounts.actions] accountReadSuccess(${id}, %j)`, data);
  return {
    type: TYPES.READ_SUCCESS,
    id: data.id,
    receivedAt: Date.now()
  }
}

export function accountReadFailure(id, error) {
  Logger.log('debug', `[state.accounts.actions] accountReadFailure(${id}, %j)`, error);
  return {
    type: TYPES.READ_FAILURE,
    id: id,
    error: error
  }
}

export function accountPlanUpdateRequest(id, data) {
  Logger.log('debug', `[state.accounts.actions] accountPlanUpdateRequest(${id}, %j)`, data);
  return {
    type: TYPES.UPDATE_PLAN_REQUEST,
  }
}

export function accountPlanUpdateSuccess(data) {
  Logger.log('debug', `[state.accounts.actions] accountPlanUpdateSuccess(%j)`, data);
  return {
    type: TYPES.UPDATE_PLAN_SUCCESS,
    id: data.id,
    receivedAt: Date.now()
  }
}

export function accountPlanUpdateFailure(error) {
  Logger.log('debug', `[state.accounts.actions] accountPlanUpdateFailure(%j)`, error);
  return {
    type: TYPES.UPDATE_PLAN_FAILURE,
    error: error
  }
}


// API THUNK ACTION CREATORS

export function loadAccount(id, cb=function(){}) {
  Logger.log('debug', `[state.accounts.actions] loadAccount(${id}, ###)`);

  return async function(dispatch) {
    dispatch(accountReadRequest(id));

    // call API
    const response = await api.getAccount(id);
    let success = false;

    // get account success
    if (200 === response.get('status')) {

      Logger.log('info', `Get API account success. ID: ${id}.`);

      const normalizedEntities = normalize([response.getIn(['data', 'account'])], [schema.account]);
      const data = {
        id: response.getIn(['data', 'account', 'id']),
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(accountReadSuccess(id, data));
      success = true;
      
    // get account failure
    } else {
      Logger.log('info', `Get API account failure. ID: ${id}.`);
      dispatch(accountReadFailure(id, response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  }
}

export function updateAccountPlan(id, data, cb=function(){}) {
  Logger.log('debug', `[state.accounts.actions] updateAccountPlan(${id}, %j, ###)`, data);

  return async function(dispatch) {
    dispatch(accountPlanUpdateRequest(id, data));

    // call API
    const response = await api.putAccountPlan(id, data);
    let success = false;

    // put account plan success
    if (200 === response.get('status')) {

      Logger.log('info', `PUT API account plan success. User: ${id}.`);

      const normalizedEntities = normalize([response.getIn(['data', 'account'])], [schema.account]);
      const data = {
        id: response.getIn(['data', 'account', 'id']),
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(accountPlanUpdateSuccess(data));
      success = true;
      
    // get account plan failure
    } else {
      Logger.log('info', `PUT API account plan failure. ID: ${id}.`);
      dispatch(accountPlanUpdateFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  }
}

Logger.log('silly', `state.accounts.actions loaded.`);
