import {connect} from 'react-redux';
import {List} from 'immutable';
import {denormalize} from 'normalizr';
import {withRouter} from "react-router";

import {schema} from '../../../../../state/schema';
import {
  sendMessage,
  saveMessageDraft,
  deleteMessage,
  loadMessagesInbox,
  // loadMessagesSent,
  loadMessagesDrafts,
  messageFormDestroy,
  messageHideComposeForm
} from '../../../../../state/modules/messages/actions';
import MessageComposeForm from '../components/MessageComposeForm';
import QueryString from '../../../../../lib/QueryString';

const inputs = List([
  'care_guide',
  'subject',
  'recipients',
  'body',
]);

const mapStateToProps = (state, ownProps) => {

  const data = {}, errors = {};
  for (const val of inputs.values()) {
    if (['care_guide'].includes(val)) {
      errors[val] = state.messages.getIn(['form', 'errors', val + '_id']);
    }
    else {
      errors[val] = state.messages.getIn(['form', 'errors', val]);
    }
    if ('data' in ownProps && ownProps.data[val]) {
      data[val] = ownProps.data[val];
    }
  }

  const pathParams = QueryString.parse(ownProps.location.search);
  // const careGuideCount = state.careGuides.get('total', 0);
  const careGuideId = 'composeThreadId' in pathParams
    ? pathParams['composeThreadId']
    : state.careGuides.get('activeId');
  const recipientIds = 'composeProfileId' in pathParams ? [pathParams['composeProfileId']] : null;

  const messageId = state.messages.get('composeFormMessageId');
  const message = messageId
  ? denormalize(
      state.entities.getIn(['messages', messageId]),
      schema.message,
      state.entities.toJS()
    )
  : null;
  const messageData = message
    ? {
        thread_id: message ? message.thread_id : '',
        care_guide: message ? message.care_guide_id : '',
        subject: message ? message.subject : '',
        recipients: message ? message.recipients.map(x => x.profile.id) : [],
        body: message ? message.body : '',
      }
    : {
        thread_id: state.messages.get('composeFormThreadId'),
        recipients: recipientIds ? recipientIds : state.messages.get('composeFormRecipientIds', []),
        care_guide: careGuideId ? careGuideId : '',
        subject: '',
        body: '',
      };

  return {
    visible: state.messages.get('composeFormIsVisible'),
    threadId: state.messages.get('composeFormThreadId'),
    messageId: messageId,
    messageData: messageData,
    defaultCareGuideId: message ? message.care_guide_id : careGuideId,
    isSubmitting: state.messages.getIn(['form', 'isSubmitting']),
    isSaving: state.messages.getIn(['form', 'isSaving']),
    isDeleting: state.messages.get('isDeleting'),
    success: state.messages.getIn(['form', 'success']),
    errors: errors
  }
}

const mapDispatchToProps = dispatch => {
  return {
    send: (messageId, data, cb) => {
      dispatch(sendMessage(messageId, data, cb));
    },
    save: (messageId, data, cb) => {
      dispatch(saveMessageDraft(messageId, data, cb));
    },
    delete: (messageId, cb) => {
      dispatch(deleteMessage(messageId, cb));
    },
    loadInbox: (page, limit, order, filter, cb) => {
      dispatch(loadMessagesInbox(page, limit, order, filter, cb));
    },
    // loadSent: (page, limit, order, filter, cb) => {
    //   dispatch(loadMessagesSent(page, limit, order, filter, cb));
    // },
    loadDrafts: (page, limit, order, filter, cb) => {
      dispatch(loadMessagesDrafts(page, limit, order, filter, cb));
    },
    formDestroy: (formState) => {
      dispatch(messageFormDestroy(formState));
    },
    hideForm: () => {
      dispatch(messageHideComposeForm());
    },
  }
}

const MessageComposeFormContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(MessageComposeForm);

export default withRouter(MessageComposeFormContainer);
