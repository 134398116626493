import {connect} from 'react-redux';
import {denormalize} from 'normalizr';

import {schema} from '../../../../../state/schema';
import SubscriptionScreen from '../components/SubscriptionScreen';
import {loadSubscription} from '../../../../../state/modules/payments/actions';

const mapStateToProps = (state) => {

  const activeSubscriptionId = state.payments.get('activeSubscriptionId');

  // get subscription from state
  let subscription = null;
  if (activeSubscriptionId) {
    subscription = denormalize(
      state.entities.getIn(['subscriptions', activeSubscriptionId]),
      schema.subscription,
      state.entities.toJS()
    );
  }


  return {
    isLoadingSubscription: state.payments.get('isLoadingSubscription'),
    subscription: subscription,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    loadSubscription: (cb) => {
      dispatch(loadSubscription(cb));
    },
  }
}

const SubscriptionScreenContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(SubscriptionScreen);

export default SubscriptionScreenContainer;
