import api from '../../api';
import Logger from '../../../lib/Logger';

export const LIST_TIMEZONES_REQUEST = 'USER_ACCOUNT_LIST_TIMEZONES_REQUEST';
export const LIST_TIMEZONES_SUCCESS = 'USER_ACCOUNT_LIST_TIMEZONES_SUCCESS';
export const LIST_TIMEZONES_FAILURE = 'USER_ACCOUNT_LIST_TIMEZONES_FAILURE';


export function timezoneListRequest() {
  Logger.log('debug', `[state.timezones.actions] timezoneListRequest()`);
  return {
    type: LIST_TIMEZONES_REQUEST,
  }
}

export function timezoneListSuccess(data) {
  Logger.log('debug', `[state.timezones.actions] timezoneListSuccess(%j)`, data);
  return {
    type: LIST_TIMEZONES_SUCCESS,
    result: data.result,
    receivedAt: Date.now()
  }
}

export function timezoneListFailure(error) {
  Logger.log('debug', `[state.timezones.actions] timezoneListFailure(%j)`, error);
  return {
    type: LIST_TIMEZONES_FAILURE,
    error: error
  }
}


// API THUNK ACTION CREATORS

export function loadTimezones(cb=function(){}) {
  Logger.log('debug', `[state.timezones.actions] loadTimezones()`);

  return async function(dispatch) {
    dispatch(timezoneListRequest());

    // call API
    const response = await api.getTimezones();
    let success = false;

    // get timezones list success
    if (200 === response.get('status')) {

      Logger.log('info', `Get API timezones list success.`);

      const data = {
        result: response.getIn(['data'])
      };

      dispatch(timezoneListSuccess(data));
      success = true;
      
    // get timezones list failure
    } else {
      Logger.log('info', `Get API timezones list failure.`);
      dispatch(timezoneListFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  }
}

Logger.log('silly', `state.timezones.actions loaded.`);
