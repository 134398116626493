import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { Translation } from 'react-i18next';
import { Col, Row, Typography } from 'antd';
import QRCode from 'qrcode.react';

import { pathTo } from '../../../Routes';
import Logger from '../../../../../lib/Logger';
import TwoFactorAuthForm from '../containers/TwoFactorAuthFormContainer';
import DocumentHead from '../../../elements/components/DocumentHead';
import Config from '../../../../../Config';
import imageLogo from '../../../assets/images/logo-300x300.png';
import '../styles/Login.less';

class TwoFactorAuthScreen extends Component {
  render() {
    if (this.props.isAuthenticated) {
      return <Redirect to={pathTo(Config.get('DEFAULT_LOGIN_REDIRECT'))} />;
    }

    const mustEnroll = this.props.twoFactorsecret || this.props.provisioningURI;
    const { Paragraph, Text } = Typography;

    return (
      <Translation>
        {(t) => (
          <div className="screen screen-public screen-2fa">
            <DocumentHead title={t('session_route_login_2fa')} />

            <div className="container-form-2fa">
              <Row type="flex" justify="space-around" align="middle">
                <Col xs={22} sm={18} md={14} lg={12} xl={10}>
                  <img
                    src={imageLogo}
                    height="42"
                    alt={t('app_name')}
                    className="auth-logo"
                  />

                  <Row gutter={16} justify="center">
                    <Col>
                      <h2>{t('session_login_2fa_screen_title')}</h2>
                    </Col>
                  </Row>

                  {mustEnroll ? (
                    <>
                      <Row gutter={16} justify="center">
                        <Col sm={18}>
                          <h3>Instructions:</h3>
                          <ul>
                            <li>
                              Download <Text strong>Google Authenticator</Text>{' '}
                              to your mobile device.
                            </li>
                            <li>
                              Create a new profile by either scanning the QR
                              code or by manually entering the secret key.
                            </li>
                            <li>
                              Submit the generated one-time password in the form
                              to complete authentication.
                            </li>
                          </ul>
                        </Col>

                        <Col sm={6}>
                          {this.props.provisioningURI ? (
                            <QRCode value={this.props.provisioningURI} />
                          ) : null}
                        </Col>
                      </Row>

                      <Row gutter={16} style={{ marginBottom: 24 }}>
                        <Col>
                          {this.props.twoFactorsecret ? (
                            <Paragraph
                              copyable={{ text: this.props.twoFactorsecret }}
                            >
                              Secret:{' '}
                              <Text keyboard>{this.props.twoFactorsecret}</Text>
                            </Paragraph>
                          ) : null}
                        </Col>
                      </Row>
                    </>
                  ) : null}

                  <Row gutter={16}>
                    <Col>
                      <h3>Authenticate</h3>
                      <Paragraph>
                        Enter the one-time password from{' '}
                        <Text strong>Google Authenticator</Text>:
                      </Paragraph>
                      <TwoFactorAuthForm />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          </div>
        )}
      </Translation>
    );
  }

  /* LIFECYCLE EVENTS */

  componentDidMount() {
    Logger.log('silly', `TwoFactorAuthScreen.componentDidMount()`);
    if (typeof window !== 'undefined') {
      window.scrollTo(0, 0);
    }
  }
}

export default TwoFactorAuthScreen;

Logger.log('silly', `TwoFactorAuthScreen loaded.`);
