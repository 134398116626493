import {connect} from 'react-redux';

import MessagesArchivedScreen from '../components/MessagesArchivedScreen';

const mapStateToProps = (state, ownProps) => {
  const page = parseInt(ownProps.match.params.page ? ownProps.match.params.page : 1);
  return {
    isLoading: state.journalPosts.get('isLoading'),
    page: page,
  }
}

const MessagesArchivedScreenContainer = connect(
  mapStateToProps
)(MessagesArchivedScreen);

export default MessagesArchivedScreenContainer;
