import { Button, Checkbox, Row, Space } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import React from 'react';
import { Translation } from 'react-i18next';

const MemberListItemsSelectionBar = ({
  selectedItems = [],
  totalItems = 0,
  itemsDisplayed = 0,
  onClearSelection,
  openEventForm,
  openResourceForm,
}) => {
  const selectionClear = () => {
    if (selectedItems.length) onClearSelection();
  };

  return (
    <Translation>
      {(t) => (
        <Row className="members-list-selection-bar">
          <Space size={'middle'}>
            <Checkbox
              checked={selectedItems.length}
              onChange={selectionClear}
            />
            <p>
              {t('members_list_members_selected', {
                members: selectedItems.length,
              })}
            </p>
            <Space size={'middle'}>
              <Button
                type="primary"
                className="event-button"
                onClick={() => openEventForm(selectedItems)}
              >
                <PlusOutlined /> {t('members_list_add_event')}
              </Button>
              <Button
                type="primary"
                className="resource-button"
                onClick={() => openResourceForm(selectedItems)}
              >
                <PlusOutlined /> {t('members_list_add_resource')}
              </Button>
            </Space>
          </Space>
          <p className="members-list-elements-shown">
            {t('members_list_showing_elements', { itemsDisplayed, totalItems })}
          </p>
        </Row>
      )}
    </Translation>
  );
};

export default MemberListItemsSelectionBar;
