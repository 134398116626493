import { connect } from 'react-redux';
import Config from '../../../../../Config';

import LearningContent from '../components/LearningContent';
import {
  setActiveItem,
  setActiveItemId
} from '../../../../../state/modules/learnings/actions';

const mapStateToProps = (state, ownProps) => {

  const list = ownProps.list;
  const options = ownProps.options;
  let activeItem = state.learnings.get('activeItem');
  const api_url_strapi = Config.get('API_BASE_URL_STRAPI');
  
  return {
    list: list,
    options: options,
    api_url_strapi: api_url_strapi,
    activeItem: activeItem,
    isLoading: state.learnings.get('isLoading'),
    activeItemId: state.learnings.get('activeItemId'),
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setActive: (item) => {
      dispatch(setActiveItem(null));
      dispatch(setActiveItem(item));
    },
    setActiveId: (id) => {
      dispatch(setActiveItemId(id));
    },
  }
}

const LearningContentContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(LearningContent);

export default LearningContentContainer;
