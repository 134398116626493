import React from "react";
import Logger from "../../../../../lib/Logger";
import CareGuideNoteList from "../containers/CareGuideNoteListContainer";
import CareGuideNoteFormModal from "../containers/CareGuideNoteFormModalContainer";
import { withRouter } from "react-router";
import { Translation } from "react-i18next";

const CareGuideNotesScreen = ({ component, page, order, filter, limit, careGuideId, ...props }) => {
    
  return (
    <Translation>{(t) =>
      <>
        <CareGuideNoteList
          limit={limit}
          order={order}
          filter={filter}
          component={component}
          page={page}
          careGuideId={careGuideId}/>

        <CareGuideNoteFormModal
          limit={limit}
          order={order}
          filter={filter}
          page={page}
          careGuideId={careGuideId}
        />
      </>
    }</Translation>
  )

}

export default withRouter(CareGuideNotesScreen);

Logger.log("silly", `CareGuideNotesScreen loaded.`);
