import { connect } from 'react-redux';
import { denormalize } from 'normalizr';
import { List } from 'immutable';

import JournalRepliesList from '../components/JournalRepliesList';
import { schema } from '../../../../../state/schema';
import {
  loadJournalReplies,
  journalReplyShowForm,
  patchJournalReply,
} from '../../../../../state/modules/journalReplies/actions';

const mapStateToProps = (state, ownProps) => {
  const careGuideId = ownProps.careGuideId;
  const journalPostId = ownProps.journalPostId;
  const page = ownProps.page;
  const limit = ownProps.limit;
  const order = ownProps.order;

  let result = List();
  for (let i = 1; i <= page; i++) {
    result = result.merge(
      state.journalReplies.getIn([
        'results',
        careGuideId,
        journalPostId,
        'pages',
        order,
        limit,
        i,
      ])
    );
  }

  const profileId = state.session.get('profileId');

  const careGuide = careGuideId
    ? denormalize(
        state.entities.getIn(['careGuides', careGuideId]),
        schema.careGuide,
        state.entities.toJS()
      )
    : null;

  return {
    careGuide,
    list: result
      ? result
          .reverse()
          .map((x) => {
            return {
              key: x,
              ...denormalize(
                state.entities.getIn(['journalReplies', x]),
                schema.journalReply,
                state.entities.toJS()
              ),
            };
          })
          .toArray()
          .filter((x) => x.key) // for some reason there's an empty result, remove
          .filter((v, i, a) => a.findIndex((t) => t.key === v.key) === i) // dedupe - sometimes merging pagination results have multiples of same
          .map((x) => {
            // flatten some nested objects to make table easier to work with
            return {
              profile_name: x.profile
                ? x.profile.first_name + ' ' + x.profile.last_name
                : '',
              ...x,
            };
          })
      : [],
    total: state.journalReplies.getIn(
      ['results', careGuideId, journalPostId, 'total'],
      0
    ),
    isLoading: state.journalReplies.get('isLoading'),
    profileId: profileId,
    isCareGuideOwner:
      profileId ===
      state.entities.getIn([
        'careGuides',
        careGuideId,
        'owner',
        'profile',
        'id',
      ]),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    load: (careGuideId, journalPostId, page, limit, order, filter, cb) => {
      dispatch(
        loadJournalReplies(
          careGuideId,
          journalPostId,
          page,
          limit,
          order,
          filter,
          cb
        )
      );
    },
    loadAll: (
      careGuideId,
      journalPostId,
      page,
      limit,
      order,
      filter,
      cb = function () {}
    ) => {
      dispatch(
        loadJournalReplies(
          careGuideId,
          journalPostId,
          page,
          limit,
          order,
          filter,
          (success, total) => {
            if (success) {
              // loop over all pages to get all replies
              const pageCount = Math.ceil(total / limit);
              for (var i = pageCount - 1; i >= 1; i--) {
                dispatch(
                  loadJournalReplies(
                    careGuideId,
                    journalPostId,
                    i,
                    limit,
                    order,
                    filter
                  )
                );
              }
              cb();
            }
          }
        )
      );
    },
    patch: (careGuideId, journalPostId, id, data, cb) => {
      dispatch(patchJournalReply(careGuideId, journalPostId, id, data, cb));
    },
    showForm: (postId, replyId) => {
      dispatch(journalReplyShowForm(postId, replyId));
    },
  };
};

const JournalRepliesListContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(JournalRepliesList);

export default JournalRepliesListContainer;
