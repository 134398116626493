import {getI18n} from 'react-i18next';

import Config from '../../../../Config';
import Logger from '../../../../lib/Logger';
import Events from '../../../../lib/EventEmitter';

import MessagesLayout from './containers/MessagesLayoutContainer';
import MessagesInboxScreen from './containers/MessagesInboxScreenContainer';
import MessagesDraftsScreen from './containers/MessagesDraftsScreenContainer';
// import MessagesSentScreen from './containers/MessagesSentScreenContainer';
import MessagesArchivedScreen from './containers/MessagesArchivedScreenContainer';
// import MessagesTrashScreen from './containers/MessagesTrashScreenContainer';

import MessagesThreadScreen from './containers/MessagesThreadScreenContainer';

const register = () => {
  Logger.log('debug', `messages.register()`);

  if (Config.getIn(['MODULE_TOGGLES', 'messages', 'routes'])) {
    
    Events.dispatch('ADD_MAIN_ROUTES',

      // screen name (key): [route type (element), path (prop), exact (prop), component (prop), route name]
      {
        'MessagesLayout': ['PrivateRoute', '/messages', false, MessagesLayout, getI18n().t('messages_route_main')],
      }
    );

    Events.dispatch('ADD_MESSAGE_ROUTES',

      // screen name (key): [route type (element), path (prop), exact (prop), component (prop), route name]
      {
        'MessagesInboxScreen': ['PrivateRoute', '/messages/inbox/:page(\\d+)?', false, MessagesInboxScreen, getI18n().t('messages_route_main')],
        'MessagesDraftsScreen': ['PrivateRoute', '/messages/drafts/:page(\\d+)?', false, MessagesDraftsScreen, getI18n().t('messages_route_main')],
        // 'MessagesSentScreen': ['PrivateRoute', '/messages/sent/:page(\\d+)?', false, MessagesSentScreen, getI18n().t('messages_route_main')],
        'MessagesArchivedScreen': ['PrivateRoute', '/messages/archived/:page(\\d+)?', false, MessagesArchivedScreen, getI18n().t('messages_route_main')],
        // 'MessagesTrashScreen': ['PrivateRoute', '/messages/trash/:page(\\d+)?', false, MessagesTrashScreen, getI18n().t('messages_route_main')],
      }
    );

    Events.dispatch('ADD_MESSAGE_THREAD_ROUTES',

      // screen name (key): [route type (element), path (prop), exact (prop), component (prop), route name]
      {
        'MessagesInboxThreadScreen': ['PrivateRoute', '/messages/inbox/:page(\\d+)/:threadId([\\w\\-]+)?', true, MessagesThreadScreen, getI18n().t('messages_route_main')],
        'MessagesDraftsThreadScreen': ['PrivateRoute', '/messages/drafts/:page(\\d+)/:threadId([\\w\\-]+)?', true, MessagesThreadScreen, getI18n().t('messages_route_main')],
        // 'MessagesSentThreadScreen': ['PrivateRoute', '/messages/sent/:threadId([\\w\\-]+)', true, MessagesThreadScreen, getI18n().t('messages_route_main')],
        'MessagesArchivedThreadScreen': ['PrivateRoute', '/messages/archived/:page(\\d+)/:threadId([\\w\\-]+)?', true, MessagesThreadScreen, getI18n().t('messages_route_main')],
        // 'MessagesTrashThreadScreen': ['PrivateRoute', '/messages/trash/:threadId([\\w\\-]+)', true, MessagesThreadScreen, getI18n().t('messages_route_main')],
      }
    );
  }
}

export default register;

Logger.log('silly', `messages.register() loaded.`);
