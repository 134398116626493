import React from "react";
import YoutubePlayer from "../../../elements/components/YoutubePlayer";
import ReactPlayer from 'react-player';
import VimeoPlayer from "../../../elements/components/VimeoPlayer";
import { Empty } from 'antd';

const ComponentMedia = ({ videoData, activeItem, api_url_strapi, ...props }) => {

    const { url, type } = videoData;

    if (videoData && url && type) {

        if (videoData.type === 1) {
            return <YoutubePlayer videoURL={videoData.url} />;
        }

        if (videoData.type === 2) {
            return <VimeoPlayer vimeoURL={videoData.url} />;
        }

        if (videoData.type === 3) {
            return <ReactPlayer
                className="learning-video"
                url={videoData.url}
                controls />;
        }

        return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;

    }


    if (activeItem.imageUrl && activeItem.videos.length <= 0) {
        return <img className='learning-image' style={{maxHeight: activeItem.imageHeight, maxWidth: activeItem.imageWidth}} src={api_url_strapi + activeItem.imageUrl} alt="Imagen" />;
    }

    return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;


}


export default ComponentMedia;