// import React from 'react';
import {getI18n} from 'react-i18next';

import Config from '../../../../Config';
import Logger from '../../../../lib/Logger';
import Events from '../../../../lib/EventEmitter';

import HomeScreen from './containers/HomeScreenContainer';

const register = () => {
  Logger.log('debug', `home.register()`);

  if (Config.getIn(['MODULE_TOGGLES', 'home', 'routes'])) {
    Events.dispatch('ADD_MAIN_ROUTES', 

      // screen name (key): [route type (element), path (prop), exact (prop), component (prop)]
      {
        'HomeScreen': ['PrivateRoute', "/home/:page(\\d+)?/:page_partnership(\\d+)?", true, HomeScreen, getI18n().t('route_home_selector')],
      }
    );
  }
}

export default register;

Logger.log('silly', `homw.register() loaded.`);
