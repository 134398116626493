import {Map} from 'immutable';

import {
  RELATIONSHIP_LIST_REQUEST,
  RELATIONSHIP_LIST_SUCCESS,
  RELATIONSHIP_LIST_FAILURE,
} from './actions';
import Logger from '../../../lib/Logger';

export default function relationships(
  state=Map({
    isLoading: false,
  }),
  action
) {
  Logger.log('debug', `[state.relationships.reducers] relationships(%j, %j)`, state, action);

  switch(action.type) {

    case RELATIONSHIP_LIST_REQUEST:
      return state.mergeDeep({
        isLoading: true
      });

    case RELATIONSHIP_LIST_SUCCESS:
      return state.mergeDeep({
        isLoading: false,
        lastUpdated: action.receivedAt
      });

    case RELATIONSHIP_LIST_FAILURE:
      return state.mergeDeep({
        isLoading: false
      });

    default:
      return state;
  }
}

Logger.log('silly', `state.relationships.reducers loaded.`);
