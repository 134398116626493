import React, { useState } from 'react';
import { Translation, getI18n } from 'react-i18next';
import { Button, Modal, Row, Space, Table, Image, Empty } from 'antd';
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  EditOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons';

import message from '../../../elements/lib/MessageWrapper';
import UserAvatar from '../../../elements/components/UserAvatar';
import Format from '../../../../../lib/Format';
import Config from '../../../../../Config';
import Logger from '../../../../../lib/Logger';
import { GlobalOutlined } from '@ant-design/icons';
import imageURL from '../../../elements/lib/imageURL';
import {
  MixpanelTracker,
  mixpanelEventsEnum,
} from '../../../../../lib/Mixpanel';

const EventModal = ({ eventId, event, view, careGuide, ...props }) => {
  const [isAccepting, setIsAccepting] = useState(false);
  const [isDeferring, setIsDeferring] = useState(false);
  const [isDeclining, setIsDeclining] = useState(false);
  const withHttp = (url) =>
    !/^https?:\/\//i.test(url) ? `http://${url}` : url;

  const trackerCalendarEvent = (eventMixpanel, params = {}) => {
    if (careGuide?.partnership) {
      MixpanelTracker.mixpanelTrack(eventMixpanel, {
        careGuideName: `${careGuide.first_name} ${careGuide.last_name}`,
        partnershipName: careGuide.partnership.name,
        careGuideId: careGuide.id,
        partnershipId: careGuide.partnership.id,
        eventId: event.id,
        eventName: event.title,
        ...params,
      });
    } else {
      MixpanelTracker.mixpanelTrack(eventMixpanel, {
        careGuideName: `${careGuide.first_name} ${careGuide.last_name}`,
        careGuideId: careGuide.id,
        eventId: event.id,
        eventName: event.title,
        ...params,
      });
    }
  };

  const onClickAcceptHandler = (id) => {
    setIsAccepting(true);
    props.respond(id, { public_status: 2 }, (success) => {
      setIsAccepting(false);
      if (success) {
        trackerCalendarEvent(mixpanelEventsEnum.CALENDAR.RESPOND, {
          status: 'Accepted',
        });
        message.success(
          getI18n().t('calendar_event_invitations_feedback_accept_success')
        );
      } else {
        message.error(
          getI18n().t('calendar_event_invitations_feedback_accept_error')
        );
      }
    });
  };

  const onClickMaybeHandler = (id) => {
    setIsDeferring(true);
    props.respond(id, { public_status: 4 }, (success) => {
      setIsDeferring(false);
      if (success) {
        trackerCalendarEvent(mixpanelEventsEnum.CALENDAR.RESPOND, {
          status: 'Maybe',
        });
        message.success(
          getI18n().t('calendar_event_invitations_feedback_maybe_success')
        );
      } else {
        message.error(
          getI18n().t('calendar_event_invitations_feedback_maybe_error')
        );
      }
    });
  };

  const onClickDeclineHandler = (id) => {
    setIsDeclining(true);
    props.respond(id, { public_status: 3 }, (success) => {
      setIsDeclining(false);
      if (success) {
        trackerCalendarEvent(mixpanelEventsEnum.CALENDAR.RESPOND, {
          status: 'Declined',
        });
        message.success(
          getI18n().t('calendar_event_invitations_feedback_decline_success')
        );
      } else {
        message.error(
          getI18n().t('calendar_event_invitations_feedback_decline_error')
        );
      }
    });
  };

  const datetimeFormat = 'MMM D, YYYY [at] h:mm A';
  const dateFormat = Config.get('DEFAULT_DATE_FORMAT');

  const onClickCancel = () => {
    props.hideDetail();
  };

  const onClickEdit = () => {
    props.hideDetail();
    props.showForm(eventId);
  };

  const eventColumns = [
    {
      title: 'Label',
      dataIndex: 'label',
      key: 'label',
      width: 125,
    },
    {
      title: 'Value',
      dataIndex: 'value',
      key: 'value',
    },
  ];

  const eventDataSource = [];
  if (event) {
    if (event.is_all_day) {
      eventDataSource.push({
        key: 'date',
        label: getI18n().t('calendar_event_detail_date'),
        value: Format.date(event.start_at, dateFormat),
      });
    } else {
      eventDataSource.push({
        key: 'start-date',
        label: getI18n().t('calendar_event_detail_start_at_date'),
        value: Format.date(event.start_at, datetimeFormat),
      });
      eventDataSource.push({
        key: 'end-date',
        label: getI18n().t('calendar_event_detail_end_at_date'),
        value: Format.date(event.end_at, datetimeFormat),
      });
    }
    eventDataSource.push({
      key: 'description',
      label: getI18n().t('calendar_event_detail_description'),
      value: (
        <div
          className="event-description"
          dangerouslySetInnerHTML={{ __html: event.description }}
        />
      ),
    });
    if (event.care_guide_id == null) {
      eventDataSource.push({
        key: 'profile',
        label: getI18n().t('calendar_event_profile_created'),
        value: (
          <>
            <UserAvatar profile={event.profile} size={22} />
            {event.profile.first_name} {event.profile.last_name}
          </>
        ),
      });
    }
  }

  const participantColumns = [
    {
      title: 'Value',
      dataIndex: 'value',
      key: 'value',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: 100,
    },
  ];

  return (
    <Translation>
      {(t) => (
        <>
          <Modal
            title={
              event ? (
                event.partnership &&
                event.partnership.logo &&
                event.partnership.logo.file_name &&
                event.partnership.logo.file_extension &&
                event.partnership.logo.file_directory ? (
                  <>
                    <Row>
                      <Image
                        preview={false}
                        src={imageURL(
                          event.partnership.logo.file_directory,
                          event.partnership.logo.file_name,
                          event.partnership.logo.file_extension,
                          400,
                          400,
                          'ratio'
                        )}
                        height="100px"
                      />
                    </Row>
                    <br />
                    <Row>{event.title}</Row>
                  </>
                ) : (
                  event.title
                )
              ) : null
            }
            centered
            visible={props.visible}
            onCancel={onClickCancel}
            footer={[
              event && event.profile && event.profile.id === props.profileId ? (
                view === false ? (
                  <Button
                    key="edit"
                    icon={<EditOutlined />}
                    onClick={onClickEdit}
                  >
                    {t('action_edit')}
                  </Button>
                ) : null
              ) : null,
              <Button
                key="close"
                icon={<CloseCircleOutlined />}
                onClick={onClickCancel}
              >
                {t('action_close')}
              </Button>,
            ]}
            width={500}
            bodyStyle={{ paddingTop: 0 }}
            style={{
              border: event?.partnership
                ? `2px solid ${event.partnership.color_hex}`
                : null,
            }}
            forceRender={true}
          >
            <div className="calendar-event-detail">
              {event ? (
                <div>
                  <div className="calendar-event-detail-summary">
                    <Table
                      columns={eventColumns}
                      dataSource={eventDataSource}
                      size="small"
                      showHeader={false}
                      pagination={false}
                    />
                  </div>

                  {event.partnership &&
                    event.url_event_partner &&
                    event.url_label && (
                      <Row justify="center" className="event-response-actions">
                        <Space>
                          <div className="website-display">
                            <a
                              href={withHttp(event.url_event_partner)}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <GlobalOutlined />
                              {event.url_label}
                            </a>
                          </div>
                        </Space>
                      </Row>
                    )}

                  {event.participants &&
                  event.participants.length > 0 &&
                  event.care_guide_id ? (
                    <>
                      <h3>{t('calendar_event_detail_participants_title')}</h3>

                      <div className="calendar-event-detail-participants">
                        <Table
                          columns={participantColumns}
                          dataSource={
                            event.participants
                              ? event.participants.map((x) => {
                                  return {
                                    key: x.id,
                                    value: (
                                      <>
                                        <UserAvatar
                                          profile={x.profile}
                                          size={22}
                                        />
                                        {x.profile.first_name}{' '}
                                        {x.profile.last_name}
                                      </>
                                    ),
                                    status: t(
                                      'calendar_event_detail_public_status_' +
                                        x.public_status
                                    ),
                                  };
                                })
                              : []
                          }
                          size="small"
                          showHeader={false}
                          pagination={false}
                        />
                      </div>
                    </>
                  ) : (
                    event?.care_guide_id && (
                      <>
                        <h3>{t('calendar_event_detail_participants_title')}</h3>
                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                      </>
                    )
                  )}

                  {props.participantId ? (
                    <Row justify="center" className="event-response-actions">
                      <Space>
                        <Button
                          key="accept"
                          icon={<CheckCircleOutlined />}
                          loading={isAccepting}
                          disabled={isAccepting || isDeclining || isDeferring}
                          onClick={() =>
                            onClickAcceptHandler(props.participantId)
                          }
                        >
                          {t('calendar_event_invitations_btn_accecpt')}
                        </Button>

                        <Button
                          icon={<QuestionCircleOutlined />}
                          loading={isDeferring}
                          disabled={isAccepting || isDeclining || isDeferring}
                          onClick={() =>
                            onClickMaybeHandler(props.participantId)
                          }
                        >
                          {t('calendar_event_invitations_btn_maybe')}
                        </Button>

                        <Button
                          danger
                          icon={<CloseCircleOutlined />}
                          loading={isDeclining}
                          disabled={isAccepting || isDeclining || isDeferring}
                          onClick={() =>
                            onClickDeclineHandler(props.participantId)
                          }
                        >
                          {t('calendar_event_invitations_btn_decline')}
                        </Button>
                      </Space>
                    </Row>
                  ) : null}
                </div>
              ) : null}
            </div>
          </Modal>
        </>
      )}
    </Translation>
  );
};

export default EventModal;

Logger.log('silly', `EventModal loaded.`);
