import CareGuideNoteListContent from '../components/CareGuideNoteListContent';
import { schema } from '../../../../../state/schema';
import { loadCareGuideNotes } from "../../../../../state/modules/careGuideNotes/actions";
import { denormalize } from 'normalizr';
import { connect } from 'react-redux';

const mapStateToProps = (state, ownProps) => {

  const order = ownProps.order ? ownProps.order : "is_pinned.desc";
  const limit = ownProps.limit ? ownProps.limit : 10;
  const page = ownProps.page ? ownProps.page : 1;
  const careGuideId = ownProps.careGuideId;
  const result = state.careGuideNotes.getIn(['pages', careGuideId, order, limit, page]);
  return {
    list: result 
      ? result.map(x => {
          return {
            key: x,
            ...denormalize(
              state.entities.getIn(['careGuideNotes', x]),
              schema.careGuideNote,
              state.entities.toJS()
            )
          };
        }).toArray()
      : [],
      isLoading: state.careGuideNotes.get('isLoading'),
      total: state.careGuideNotes.get('total') ? state.careGuideNotes.get('total') : 0,
    }

}

const mapDispatchToProps = dispatch => {
  return {
    load: (careGuideId, page, limit, order, filter, cb) => {
      dispatch(loadCareGuideNotes(careGuideId, page, limit, order, filter, cb));
    },
  }
}

const CareGuideNoteListContentContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(CareGuideNoteListContent);

export default CareGuideNoteListContentContainer;
