import { downloadCareGuidesCSV } from '../../../../../state/modules/careGuides/actions';
import { connect } from 'react-redux';
import CareGuideOwnersModalExport from '../components/CareGuideOwnersModalExport';
import storage from '../../../../../lib/Storage';
import { denormalize } from 'normalizr';
import { schema } from '../../../../../state/schema';

const mapStateToProps = (state, ownProps) => {
  let allPageMembersIds = [];

  const order = ownProps.order ? ownProps.order : 'started_at.asc';
  const limit = ownProps.limit ? ownProps.limit : 10;
  const page = ownProps.page ? ownProps.page : 1;
  const result = state.careGuides.getIn(['pages', order, limit, page]);

  const list = result
    ? result
        .map((x) => {
          allPageMembersIds.push(x);
          return {
            key: x,
            ...denormalize(
              state.entities.getIn(['careGuides', x]),
              schema.careGuide,
              state.entities.toJS()
            ),
          };
        })
        .toArray()
    : [];

  return {
    list,
    allPageMembersIds,
    total: state.careGuides.get('total'),
    isDownloading: state.careGuides.get('isCSVDownloading'),
    partnershipId: storage.get('local', 'partnershipId'),
    partnershipName: storage.get('local', 'partnershipData')?.name,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    download: (partnershipId, name, payload, order, filter, cb) => {
      dispatch(
        downloadCareGuidesCSV(partnershipId, name, payload, order, filter, cb)
      );
    },
  };
};

const CareGuideOwnersModalExportContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(CareGuideOwnersModalExport);

export default CareGuideOwnersModalExportContainer;
