import React, {useEffect} from 'react';
import {Translation, getI18n} from 'react-i18next';
import {Link} from 'react-router-dom';
import {Button, Form, Input, Tooltip} from 'antd';
import {CheckCircleOutlined, QuestionCircleOutlined} from '@ant-design/icons';

import {pathTo} from '../../../Routes';
import RoleInput from '../../careGuideHelpers/components/RoleInput';
import TagInput from '../../careGuideHelperTags/containers/TagInputContainer';
import message from '../../../elements/lib/MessageWrapper';
import Config from '../../../../../Config';
import Logger from '../../../../../lib/Logger';


const RegisterStep3Form = ({careGuideId, page, limit, order, data, errors, load, destroyForm, isSubmitting, ...props}) => {

  const [form] = Form.useForm();

  // handle errors reported by API
  useEffect(() => {
    let firstFieldName = '';
    for (const field in errors) {
      form.setFields([{name: field, errors: errors[field]}]);
      if (firstFieldName === '') {
        firstFieldName = field;
      }
    }
    form.scrollToField(firstFieldName);
  }, [form, errors]);


  // submit data handler
  const submitData = async (values) => {
    Logger.log('debug', `RegisterStep3Form.submitData(###)`);

    const payload = {};

    // transform values
    for (var key in values) {

      payload[key] = values[key];

      // set empty string to null
      if (['email'].includes(key) && payload[key] === '') {
        payload[key] = null;
      }
    }

    // create URL templates for invitation
    payload['url_template_register'] = window.location.href.split('/').slice(0, 3).join('/')
      + pathTo('RegisterStep1Screen') + '?invite_code={invite_code}&invite_id={invite_id}';
    payload['url_template_accept'] = window.location.href.split('/').slice(0, 3).join('/')
      + pathTo('CareGuideInvitationAccept', {invitationId: '000'}).replace(/000/, '{invite_id}') + '?invite_code={invite_code}';

    // create
    props.create(careGuideId, payload, (success) => {
      if (success) {
        message.success(getI18n().t('feedback_form_success'));
        clearForm();
        if (page && limit) {
          load(careGuideId, page, limit, order);
        }
      } else {
        message.error(getI18n().t('feedback_form_error'));
      }
    });
  }

  const clearForm = () => {
    props.formDestroy();
    form.setFieldsValue({email: '', role: 2, note: '', tags: []});
    for (const key of Object.keys(data)) {
      form.setFields([{name: key, errors: []}]);
    }
  }

  // form submit handler
  const handleFinish = async (values) => {
    Logger.log('debug', `RegisterStep3Form.handleFinish(###)`);
    if (!props.isSubmitting) {
      await submitData(values);
    }
  }

  // form error handler
  const handleFinishFailed = ({values, errorFields, outOfDate}) => {
    Logger.log('debug', `RegisterStep3Form.handleFinishFailed(###)`);
    message.error(getI18n().t('feedback_form_error'));
    if (errorFields && errorFields.length > 0) {
      form.scrollToField(errorFields[0].name);
    }
  }

  // remove error message when input value changes
  const handleValuesChange = (changedValues, allValues) => {
    for (const key of Object.keys(changedValues)) {
      form.setFields([{name: key, errors: []}]);
    }
  }

  return (
    <Translation>{(t) => 
      <>
        <div className="register-form register-form-step3">
          <Form
            name="register_step2_form"
            layout="vertical"
            form={form}
            initialValues={data}
            onFinish={handleFinish}
            onFinishFailed={handleFinishFailed}
            onValuesChange={handleValuesChange}
            validateTrigger="onSubmit"
            requiredMark={false}
          >

            <div className="form-group">
              <Form.Item
                name="email"
                label={t('care_guide_invitations_form_input_email')}
                rules={[
                  {required: true, message: t('feedback_validation_required')},
                  {type: 'email', message: t('feedback_validation_email')}
                ]}
              >
                <Input autoFocus disabled={isSubmitting} />
              </Form.Item>
            </div>

            <RoleInput
              name="role"
              label={
                <span>
                  {t('care_guide_invitations_role')}&nbsp;
                  <Tooltip title={t('care_guide_invitations_tooltip_role')}>
                    <QuestionCircleOutlined />
                  </Tooltip>
                </span>
              }
              disabled={isSubmitting}
            />

            <TagInput
              name="tags"
              label={t('care_guide_invitations_form_input_tags')}
            />

            <div className="form-actions">
              <Form.Item>

                <div className="form-actions-link">
                  <Link to={pathTo(Config.get('DEFAULT_LOGIN_REDIRECT'))}>
                    {t('register_form3_link_skip')}
                  </Link>
                </div>

                <Button
                  key="submit"
                  type="primary"
                  htmlType="submit"
                  icon={<CheckCircleOutlined />}
                  loading={isSubmitting}
                >
                  {t('register_form3_button_submit')}
                </Button>
              </Form.Item>
            </div>

          </Form>
        </div>
      </>
    }</Translation>
  );
}

export default RegisterStep3Form;

Logger.log('silly', `RegisterStep3Form loaded.`);
