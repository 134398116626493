import React from "react";
import YouTube from "react-youtube";

const YoutubePlayer = ({ videoURL}) => {
  const youtubeRegex = /(?:\/|%3D|v=|vi=)(?<videoID>[0-9A-Za-z_-]{11})(?:[%#?&]|$)/;
  const videoId = videoURL.match(youtubeRegex).groups.videoID;
  
  return (
    <div className='learning-video-container'>
    <YouTube
      iframeClassName="learning-video"
      videoId={videoId}
      id={videoId}                    
    />
    </div>
  );
};

export default YoutubePlayer;