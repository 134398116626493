import React, { useState } from 'react';
import { Col, Row } from 'antd';
import { Translation, getI18n } from 'react-i18next';
import { withRouter } from 'react-router';
import DailyLogsList from './DailyLogsList';
import ConfirmationModal from '../../../elements/components/ConfirmationModal';
import notification from '../../../elements/lib/NotificationWrapper';

const confirmTexts = {
  title: getI18n().t('care_guide_owners_cognito_confirm_modal_title'),
  description: getI18n().t(
    'care_guide_owners_cognito_confirm_modal_description'
  ),
  leaveText: getI18n().t('care_guide_owners_cognito_confirm_modal_leave'),
  saveText: getI18n().t('care_guide_owners_cognito_confirm_modal_save'),
};

const DailyLogsScreen = ({
  cognitoId,
  cognitoKey,
  careGuide,
  profileId,
  list,
  load,
  careGuideId,
  partnershipId,
  component,
  history,
  remove,
  generatePDF,
  isGeneratingPDF,
  page,
  limit,
  filter,
  order,
  total,
  ...props
}) => {
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [dailyLogId, setDailyLogId] = useState(null);
  const filterString = JSON.stringify(filter);

  const onDeleteConfirmModal = () => {
    setConfirmationModalOpen(false);
    remove(partnershipId, careGuideId, dailyLogId, (success) => {
      if (success) {
        load(
          partnershipId,
          careGuideId,
          page,
          limit,
          order,
          JSON.parse(filterString)
        );
        notification.success(
          getI18n().t('care_guide_owners_cognito_daily_logs_delete_success')
        );
      } else {
        notification.success(
          getI18n().t('care_guide_owners_cognito_daily_logs_delete_error')
        );
      }
    });
    setDailyLogId(null);
  };

  const onCancelConfirmModal = () => {
    setConfirmationModalOpen(false);
    setDailyLogId(null);
  };

  return (
    <Translation>
      {(t) => (
        <>
          <Row>
            <Col span={24} flex={1} className="section-daily-log">
              <Row className="info-daily-log">
                <Col flex="auto" className={`info-row`}>
                  <>
                    <iframe
                      src={`https://www.cognitoforms.com/f/${cognitoKey}/${cognitoId}?entry={"Name":"${careGuide.first_name} ${careGuide.last_name}","CareGuideId":"${careGuide.id}","PartnershipId":"${careGuide.partnership.id}","UserProfileId":"${profileId}"}`}
                      allow="payment"
                      height="930"
                      width={'100%'}
                      title="Cognito Forms"
                      style={{ border: 'none' }}
                    ></iframe>
                    <script src="https://www.cognitoforms.com/f/iframe.js"></script>
                  </>
                </Col>
                <DailyLogsList
                  careGuide={careGuide}
                  list={list}
                  load={load}
                  careGuideId={careGuideId}
                  partnershipId={partnershipId}
                  component={component}
                  page={page}
                  limit={limit}
                  filter={filter}
                  order={order}
                  total={total}
                  setConfirmationModalOpen={setConfirmationModalOpen}
                  setDailyLogId={setDailyLogId}
                  generatePDF={generatePDF}
                  isGeneratingPDF={isGeneratingPDF}
                />
              </Row>
            </Col>
          </Row>
          <ConfirmationModal
            texts={confirmTexts}
            openConfirmationModal={confirmationModalOpen}
            onLeaveConfirmation={onDeleteConfirmModal}
            onSaveConfirmation={onCancelConfirmModal}
            onCancel={onCancelConfirmModal}
            setConfirmationModalOpen={setConfirmationModalOpen}
          />
        </>
      )}
    </Translation>
  );
};

export default withRouter(DailyLogsScreen);
