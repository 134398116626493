import { Modal } from 'antd';
import { Button } from 'antd';
import { Translation } from 'react-i18next';
import React, { cloneElement } from 'react';
import { RiErrorWarningLine } from 'react-icons/ri';

const ConfirmationModal = ({
  openConfirmationModal,
  onCancel,
  isLoading,
  onSaveConfirmation,
  onLeaveConfirmation,
  children,
  texts,
  icon,
}) => {
  return (
    <Translation>
      {(t) => (
        <>
          <Modal
            width={590}
            className="confirmation-modal"
            centered
            visible={openConfirmationModal}
            onCancel={onCancel}
            footer={[
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  width: '100%',
                }}
              >
                <Button
                  block
                  size={'large'}
                  key="back"
                  onClick={onSaveConfirmation}
                  disabled={isLoading}
                  style={{ marginRight: '8px', flex: 1 }}
                  type='primary'
                >
                  {texts.saveText}
                </Button>

                <Button
                  block
                  size={'large'}
                  className="confirm-modal-btn-danger"
                  key="submit"
                  loading={isLoading}
                  onClick={onLeaveConfirmation}
                  style={{ marginLeft: '8px', flex: 1 }}
                  danger
                >
                  {texts.leaveText}
                </Button>
              </div>,
            ]}
          >
            {children ?? (
              <section className="modal-description">
                {icon ? (
                  cloneElement(icon, {
                    className: 'modal-description-icon',
                  })
                ) : (
                  <RiErrorWarningLine className="modal-description-icon" />
                )}
                <span className="modal-description-title">{texts.title}</span>
                {texts.description && (
                  <span className="modal-description-content">
                    {texts.description}
                  </span>
                )}
              </section>
            )}
          </Modal>
        </>
      )}
    </Translation>
  );
};

export default ConfirmationModal;
