import {connect} from 'react-redux';

import RegistrationCodesScreen from '../components/RegistrationCodesScreen';
import storage from "../../../../../lib/Storage";

const mapStateToProps = state => {
  return {
    total: state.registrationCodes.get('total'),
    isLoading: state.registrationCodes.get('isLoading'),
    activeId: storage.get('local', 'partnershipId'),
    access_role_admin_in_app: storage.get('local', 'partnershipData')?.access_role_admin_in_app,
  }
}

const RegistrationCodesScreenContainer = connect(
  mapStateToProps
)(RegistrationCodesScreen);

export default RegistrationCodesScreenContainer;
