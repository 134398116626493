import React, {useEffect} from 'react';
import {withRouter} from "react-router";
import {Link} from 'react-router-dom';
import {Translation} from 'react-i18next';
import {List} from 'antd';

import QueryString from '../../../../../lib/QueryString';
import {pathTo} from '../../../Routes';
import Logger from '../../../../../lib/Logger';


function DraftsList({component, page, limit, order, total=0, load, history, ...props}) {

  useEffect(() => {
    load(page, limit, order);
  }, [page, limit, order, load]);

  const pagination = {
    onChange: newPage => {
      const path = pathTo(component, {page: newPage});
      history.push(QueryString.append(path));
    },
    current: page,
    pageSize: limit,
    total: total,
  };

  return (
    <Translation>{(t) => 
      <List
        className="messages-list"
        itemLayout="horizontal"
        dataSource={props.list}
        pagination={page === 1 && total <= limit ? false : pagination}
        renderItem={item => (
          <Link to={'#'} onClick={() => props.showForm(null, item.id)}>
            <List.Item>
              <List.Item.Meta
                title={item.subject || t('messages_no_subject')}
                description={item.recipients ? item.recipients.map(x => x.profile.first_name + ' ' + x.profile.last_name).join(', ') : null}
              />
            </List.Item>
          </Link>
        )}
      />
    }</Translation>
  )  
}

export default withRouter(DraftsList);

Logger.log('silly', `DraftsList loaded.`);
