import {getI18n} from 'react-i18next';

import Config from '../../../../Config';
import Logger from '../../../../lib/Logger';
import Events from '../../../../lib/EventEmitter';

import ResourcesAdminScreen from './containers/ResourcesAdminScreenContainer';
import ResourceAdminAddScreen from './containers/ResourceAdminAddScreenContainer';
import ResourceAdminEditScreen from './containers/ResourceAdminEditScreenContainer';

const register = () => {
  Logger.log('debug', `resources.register()`);

  if (Config.getIn(['MODULE_TOGGLES', 'resourcesAdmin', 'routes'])) {
    Events.dispatch('ADD_ADMIN_ROUTES', 
      {
        'ResourceAdminAddScreen': ['PrivateRoute', '/admin/resources/partnership/:id_partnership([\\w\\-]+)/add', true, ResourceAdminAddScreen, getI18n().t('resources_route_add')],
        'ResourceAdminEditScreen': ['PrivateRoute', '/admin/resources/partnership/:id_partnership([\\w\\-]+)/edit/:id([\\w\\-]+)', true, ResourceAdminEditScreen, getI18n().t('resources_route_edit')],
        'ResourcesAdminScreen': ['PrivateRoute', '/admin/resources/:page(\\d+)?', true, ResourcesAdminScreen, getI18n().t('resources_route_list')],
      }
    );
  }
}

export default register;

Logger.log('silly', `resources.register() loaded.`);
