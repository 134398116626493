import {connect} from 'react-redux';

import {confirmEmailAddress} from '../../../../../state/modules/userAccount/actions';
import EmailConfirmation from '../components/EmailConfirmation';

const mapStateToProps = state => {
  return {
    isSubmitting: state.userAccount.get('isEmailConfirmationSubmitting'),
    isVerified: state.userAccount.getIn(['data', 'is_verified']),
  }
}

const mapDispatchToProps = dispatch => {
  return {
    confirmEmailAddress: (data, cb) => {
      dispatch(confirmEmailAddress(data, cb));
    },
  }
}

const EmailConfirmationContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(EmailConfirmation);

export default EmailConfirmationContainer;
