import {connect} from 'react-redux';
import {List} from 'immutable';

import {
  loadCareGuidePartnerTagAdmin,
  updateCareGuidePartnerTagAdmin,
  createCareGuidePartnerTagAdmin,
  deleteCareGuidePartnerTagAdmin,
  careGuidePartnerTagAdminFormDestroy
} from '../../../../../state/modules/careGuidePartnerTagsAdmin/actions';
import CareGuidePartnerTagAdminForm from '../components/CareGuidePartnerTagAdminForm';
import storage from "../../../../../lib/Storage";

const inputs = List([
  'label',
  'status',
  'color_hex',
  'admin_partner_class'
]);

const mapStateToProps = (state, ownProps) => {

  const data = {}, errors = {};
  for (const val of inputs.values()) {
    data[val] = state.entities.getIn(['careGuidePartnerTagsAdmin', ownProps.id, val], '');
    errors[val] = state.careGuidePartnerTagsAdmin.getIn(['form', 'errors', val]);
  }

  // change select number values to strings to work with ant design select inputs
  Object.keys(data).forEach(x => {
    if (['status'].includes(x)) {
      data[x] = data[x].toString();
    }
  });

  data['color_hex'] = data['color_hex'] !== '' ? data['color_hex']: 'magenta';

  return {
    isLoading: state.careGuidePartnerTagsAdmin.get('isLoading'),
    isSubmitting: state.careGuidePartnerTagsAdmin.getIn(['form', 'isSubmitting']),
    success: state.careGuidePartnerTagsAdmin.getIn(['form', 'success']),
    created_id: state.careGuidePartnerTagsAdmin.getIn(['form', 'created_id']),
    data: data,
    errors: errors,
    createdAt: state.entities.getIn(['careGuidePartnerTagsAdmin', ownProps.id, 'created_at'], null),
    updatedAt: state.entities.getIn(['careGuidePartnerTagsAdmin', ownProps.id, 'updated_at'], null),
    activeId: storage.get('local', 'partnershipId'),

  }
}

const mapDispatchToProps = dispatch => {
  return {
    load: (partnership_id, id, cb) => {
      dispatch(loadCareGuidePartnerTagAdmin(partnership_id, id, cb));
    },
    update: (id, data, cb) => {
      dispatch(updateCareGuidePartnerTagAdmin(id, data, cb));
    },
    create: (data, cb) => {
      dispatch(createCareGuidePartnerTagAdmin(data, cb));
    },
    delete: (partnership_id, id, cb) => {
      dispatch(deleteCareGuidePartnerTagAdmin(partnership_id, id, cb))
    },
    destroyForm: (formState) => {
      dispatch(careGuidePartnerTagAdminFormDestroy(formState));
    },
   
  }
}

const CareGuidePartnerTagAdminFormContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(CareGuidePartnerTagAdminForm);

export default CareGuidePartnerTagAdminFormContainer;