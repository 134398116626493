import React, { useEffect, useState } from 'react';
import { Translation } from 'react-i18next';
import { Form, Select } from 'antd';
import { List } from 'immutable';
import { schema } from '../../../../../state/schema';
import store from '../../../../../state/store';
import { denormalize } from 'normalizr';
import useDebounce from '../../../elements/components/useDebounce';

const { Option } = Select;

const AdminPartnerClassInput = ({
  partnershipId,
  name,
  form,
  label,
  total,
  isLoading,
  disabled,
  load,
  onChange,
  rules = null,
  ...props
}) => {
  const [query, setQuery] = useState('');
  const [filter, setFilter] = useState({});
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [lastPage, setLastPage] = useState(1);
  const [firstLoad, setFirstLoad] = useState(true);
  const [result, setResult] = useState(List([]));
  const [firstDebouncedQuery, setFirstDebouncedQuery] = useState(true);
  const [debouncedTotal, setDebouncedTotal] = useState(0);
  const [useDebouncedTotal, setUseDebouncedTotal] = useState(false);
  const debouncedQuery = useDebounce(query, 1000);

  const limit = 10;
  const order = 'updated_at.desc';
  const state = store.getState();

  const cleanDebounced = () => {
    setFilter({});
    setPage(lastPage);
    setFirstDebouncedQuery(true);
    setUseDebouncedTotal(false);
    setDebouncedTotal(0);
  };

  useEffect(() => {
    if (debouncedQuery.trim() === '') {
      cleanDebounced();
      return;
    } else {
      if (firstDebouncedQuery) {
        setLastPage(page);
      }
      setUseDebouncedTotal(true);
      setFirstDebouncedQuery(false);
      setPage(1);
      setFilter({ name: debouncedQuery });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedQuery]);

  // load sections on mount
  useEffect(() => {
    if (partnershipId) {
      load(partnershipId, page, limit, order, filter, (success, result) => {
        if (success) {
          setResult(List(result));
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [partnershipId, page, limit, filter, load]);

  const onScroll = async (event) => {
    const target = event.target;
    let totalCurrent = data.length;

    if (useDebouncedTotal) {
      totalCurrent = debouncedTotal;
    }

    if (
      !isLoading &&
      target.scrollTop + target.offsetHeight === target.scrollHeight &&
      total > totalCurrent
    ) {
      setPage(page + 1);
    }
  };

  useEffect(() => {
    const options = result
      ? result
          .map((x) => {
            return {
              key: x,
              ...denormalize(
                state.entities.getIn(['adminPartnerClassesAdmin', x]),
                schema.adminPartnerClassAdmin,
                state.entities.toJS()
              ),
            };
          })
          .toArray()
      : [];
    if (useDebouncedTotal) {
      setDebouncedTotal((prevState) => prevState + options.length);
    }
    setData((prevState) => {
      const filteredOptions = options.filter(
        (newOption) => !prevState.some((x) => x.id === newOption.id)
      );
      return prevState.concat(filteredOptions);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [result]);

  useEffect(() => {
    if (firstLoad) {
      const adminPartnerClassId = form.getFieldValue(name);
      if (adminPartnerClassId) {
        const adminPartnerClass = adminPartnerClassId
          ? denormalize(
              state.entities.getIn([
                'adminPartnerClassesAdmin',
                adminPartnerClassId,
              ]),
              schema.adminPartnerClassAdmin,
              state.entities.toJS()
            )
          : null;

        setData((prevState) => {
          let existObject = prevState.find(
            (x) => x.id === adminPartnerClass.id
          );
          
          if (!existObject) {
            prevState.push(adminPartnerClass);
            if (useDebouncedTotal) {
              setDebouncedTotal((prevState) => prevState + 1);
            }
          }

          return prevState;
        });
        setFirstLoad(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firstLoad]);

  return (
    <Translation>
      {(t) => (
        <div className="form-group">
          <Form.Item name={name} label={label} rules={rules}>
            <Select
              showSearch
              onPopupScroll={onScroll}
              optionFilterProp="children"
              disabled={disabled}
              loading={isLoading}
              onChange={onChange}
              maxTagCount={6}
              onSearch={(value) => setQuery(value)}
              onBlur={() => setQuery('')}
            >
              {data.map((x) => (
                <Select.Option key={x.id} value={x.id}>
                  {x.name}
                </Select.Option>
              ))}
              {isLoading && <Option>{t('form_input_loading')}</Option>}
            </Select>
          </Form.Item>
        </div>
      )}
    </Translation>
  );
};

export default AdminPartnerClassInput;
