import React from "react";
import { withRouter } from "react-router";
import { Translation } from "react-i18next";
import { Row, Space, Tag, Button } from "antd";
import { IoClose } from "react-icons/io5";

const CareGuideDocumentsFilterTags = ({
  dateStart,
  dateEnd,
  handleResetDate,
  searchParam,
  handleSearchReset,
}) => {
  return (
    <Translation>
      {(t) => (
        <Row style={{ marginBottom: "20px" }}>
          <Space size={12}>
            {!!dateStart && !!dateEnd && (
              <Tag
                className="action-filter-tag"
                style={{ backgroundColor: "#E5E5E5", color: "#262626" }}
              >
                {t("care_guide_documents_date_tag", {
                  dateStart: dateStart,
                  dateEnd: dateEnd,
                })}
                <Button type="link" onClick={handleResetDate}>
                  <IoClose />
                </Button>
              </Tag>
            )}
            {!!searchParam && (
              <Tag
                className="action-filter-tag"
                style={{ backgroundColor: "#E5E5E5", color: "#262626" }}
              >
                {t("care_guide_documents_search_tag", {
                  searchParam: searchParam,
                })}
                <Button type="link" onClick={handleSearchReset}>
                  <IoClose />
                </Button>
              </Tag>
            )}
          </Space>
        </Row>
      )}
    </Translation>
  );
};

export default withRouter(CareGuideDocumentsFilterTags);
