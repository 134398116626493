import {getI18n} from 'react-i18next';

import Config from '../../../../Config';
import Logger from '../../../../lib/Logger';
import Events from '../../../../lib/EventEmitter';

import CareGuidesAdminScreen from './containers/CareGuidesAdminScreenContainer';
import CareGuideAdminEditScreen from './containers/CareGuideAdminEditScreenContainer';


const register = () => {
  Logger.log('debug', `careGuidesAdmin.register()`);

  if (Config.getIn(['MODULE_TOGGLES', 'careGuidesAdmin', 'routes'])) {
    Events.dispatch('ADD_ADMIN_ROUTES', 
      {
        'CareGuidesAdminScreen': ['PrivateRoute', '/admin/care-guides/:page(\\d+)?', true, CareGuidesAdminScreen, getI18n().t('care_guides_route_list')],
        'CareGuideAdminEditScreen': ['PrivateRoute', '/admin/care-guides/partnership/:id_partnership([\\w\\-]+)/edit/:id([\\w\\-]+)', true, CareGuideAdminEditScreen, getI18n().t('care_guide_route_edit')],
      }
    );
  }
}

export default register;

Logger.log('silly', `careGuidesAdmin.register() loaded.`);
