import { connect } from 'react-redux';

import ResourcesAdminScreen from '../components/ResourcesAdminScreen';
import storage from '../../../../../lib/Storage';
import QueryString from '../../../../../lib/QueryString';

const mapStateToProps = (state, ownProps) => {
  const queryParams = QueryString.parse(ownProps.location.search);
  const view = 'view' in queryParams ? queryParams['view'] : 'table';

  return {
    view: view,
    total: state.resourcesAdmin.get('total'),
    isLoading: state.resourcesAdmin.get('isLoading'),
    partnershipId: storage.get('local', 'partnershipId'),
    partnershipName: storage.get('local', 'partnershipData')?.name,
    access_role_admin_in_app: storage.get('local', 'partnershipData')
      ?.access_role_admin_in_app,
  };
};

const ResourcesAdminScreenContainer =
  connect(mapStateToProps)(ResourcesAdminScreen);

export default ResourcesAdminScreenContainer;
