// import React from 'react';
import {getI18n} from 'react-i18next';

import Config from '../../../../Config';
import Logger from '../../../../lib/Logger';
import Events from '../../../../lib/EventEmitter';

import PartnershipAccountScreen from './containers/PartnershipAccountScreenContainer';

const register = () => {
  Logger.log('debug', `partnershipAccount.register()`);

  if (Config.getIn(['MODULE_TOGGLES', 'partnershipAccount', 'routes'])) {
    Events.dispatch('ADD_ADMIN_ROUTES', 

      // screen name (key): [route type (element), path (prop), exact (prop), component (prop)]
      {
        'PartnershipAccountScreen': ['PrivateRoute', '/admin/partnership-account', true, PartnershipAccountScreen, getI18n().t('partnership_account')],
      }
    );
  }
}

export default register;

Logger.log('silly', `partnershipAccount.register() loaded.`);
