import React, { Fragment, useState, useEffect } from 'react';
import { Translation, getI18n } from 'react-i18next';
import { Button, Card, Col, Form, Input, Row, Space, Table } from 'antd';
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  EditOutlined,
  FilePdfOutlined,
} from '@ant-design/icons';

import CareGuideImageForm from '../containers/CareGuideImageFormContainer';
import CareGuideMembersList from '../../careGuideHelpers/containers/CareGuideMembersListContainer';
import message from '../../../elements/lib/MessageWrapper';
import hasPermission, {
  UPDATE_CARE_GUIDE,
} from '../../../elements/lib/hasPermission';
import Logger from '../../../../../lib/Logger';
import useWindowDimensions from '../../../../../lib/WindowDimensions';
import {
  MixpanelTracker,
  mixpanelEventsEnum,
} from '../../../../../lib/Mixpanel';

const PDFButton = (props) => (
  <Button
    size="small"
    icon={<FilePdfOutlined />}
    loading={props.isGeneratingPDF}
    disabled={props.isGeneratingPDF}
    onClick={props.onClickExportHandler}
  >
    {props.text}
  </Button>
);

const CareGuideSummary = ({
  careGuideId,
  careGuide,
  data,
  errors,
  preventRoutingTransition,
  ...props
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [form] = Form.useForm();

  const { width } = useWindowDimensions();
  const isSmall = width <= 576;

  // focus on first summary input when entering edit mode
  useEffect(() => {
    if (isEditing) {
      const firstInputField = form.getFieldInstance(['summary', 0, 'value']);
      if (firstInputField) {
        firstInputField.focus();
      }
    }
  }, [form, isEditing]);

  // set care guide editing bumper on/off
  useEffect(() => {
    preventRoutingTransition(isEditing);
  }, [isEditing, preventRoutingTransition]);

  // submit data handler
  const submitData = async (values) => {
    Logger.log('debug', `CareGuideSummary.submitData(###)`);

    // construct payload
    const payload = {
      summary: values['summary'],
    };

    props.patch(careGuideId, payload, (success) => {
      if (success) {
        message.success(getI18n().t('feedback_form_success'));
        setIsEditing(false);
        props.load(careGuideId);
      } else {
        message.error(getI18n().t('feedback_form_error'));
      }
    });
  };

  // form submit handler
  const handleFinish = async (values) => {
    Logger.log('debug', `CareGuideSummary.handleFinish(###)`);
    if (!props.isSubmitting) {
      await submitData(values);
    }
  };

  // form error handler
  const handleFinishFailed = ({ values, errorFields, outOfDate }) => {
    Logger.log('debug', `CareGuideSummary.handleFinishFailed(###)`);
    message.error(getI18n().t('feedback_form_error'));
    if (errorFields && errorFields.length > 0) {
      form.scrollToField(errorFields[0].name);
    }
  };

  // remove error message when input value changes
  const handleValuesChange = (changedValues, allValues) => {
    for (const key of Object.keys(changedValues)) {
      form.setFields([{ name: key, errors: [] }]);
    }
  };

  const onClickEditHandler = (e) => {
    setIsEditing(true);
  };

  const onClickSaveHandler = (e) => {
    form.submit();
  };

  const onClickCancelHandler = (e) => {
    setIsEditing(false);
  };

  const onClickExportHandler = (event) => {
    props.generatePDF(careGuideId, (success) => {
      if (success) {
        if (careGuide?.partnership) {
          MixpanelTracker.mixpanelTrack(
            mixpanelEventsEnum.CARE_GUIDE.EXPORT_PDF,
            {
              careGuideName: `${careGuide.first_name} ${careGuide.last_name}`,
              partnershipName: careGuide.partnership.name,
              careGuideId: careGuide.id,
              partnershipId: careGuide.partnership.id,
            }
          );
        } else {
          MixpanelTracker.mixpanelTrack(
            mixpanelEventsEnum.CARE_GUIDE.EXPORT_PDF,
            {
              careGuideName: `${careGuide.first_name} ${careGuide.last_name}`,
              careGuideId: careGuide.id,
            }
          );
        }
      }
    });
    event.stopPropagation();
  };

  const summaryColumns = [
    {
      title: 'Field',
      dataIndex: 'field',
      key: 'field',
      width: isSmall ? 100 : 150,
    },
    {
      title: 'Value',
      dataIndex: 'value',
      key: 'value',
      render: (val) =>
        isEditing ? (
          val
        ) : (
          <div className="care-guide-summary-value">
            {val.split('\n').map((item, key) => {
              return (
                <Fragment key={key}>
                  {item}
                  <br />
                </Fragment>
              );
            })}
          </div>
        ),
    },
  ];

  // add `key` prop for table rendering
  const summaryData =
    careGuide && careGuide.summary
      ? careGuide.summary.map((x, i) => {
          return { key: i, field: x.field, value: x.value };
        })
      : [];

  return (
    <Translation>
      {(t) => (
        <Form
          name="care_guide_summary_form"
          className="care-guide-summary-form"
          form={form}
          initialValues={data}
          onFinish={handleFinish}
          onFinishFailed={handleFinishFailed}
          onValuesChange={handleValuesChange}
          validateTrigger="onSubmit"
          requiredMark={false}
        >
          <Card
            title={careGuide.first_name + ' ' + careGuide.last_name}
            size="small"
            id={'summary'}
            extra={
              isEditing ? (
                <Space direction={isSmall ? 'vertical' : 'horizontal'}>
                  <Button
                    key="cancel"
                    icon={<CloseCircleOutlined />}
                    size="small"
                    onClick={() => onClickCancelHandler()}
                  >
                    {t('action_cancel')}
                  </Button>
                  <Button
                    key="submit"
                    icon={<CheckCircleOutlined />}
                    size="small"
                    type="primary"
                    htmlType="submit"
                    onClick={() => onClickSaveHandler()}
                    loading={props.isSubmitting}
                  >
                    {t('action_save')}
                  </Button>
                </Space>
              ) : (
                <Space direction={isSmall ? 'vertical' : 'horizontal'}>
                  <PDFButton
                    key="export"
                    text={t('care_guides_button_download_pdf')}
                    isGeneratingPDF={props.isGeneratingPDF}
                    onClickExportHandler={onClickExportHandler.bind(this)}
                  />
                  {hasPermission(UPDATE_CARE_GUIDE) ? (
                    <Button
                      key="edit"
                      icon={<EditOutlined />}
                      size="small"
                      type="primary"
                      onClick={(e) => onClickEditHandler(e)}
                    >
                      {t('action_edit')}
                    </Button>
                  ) : null}
                </Space>
              )
            }
          >
            {isEditing ? (
              <Row gutter={8}>
                <Col flex="172px" className="care-guide-image">
                  <CareGuideImageForm
                    careGuideId={careGuideId}
                    isEditing={isEditing}
                  />
                </Col>

                <Col flex="auto">
                  <Form.List name="summary">
                    {(summary, { add, remove, move }) => {
                      return (
                        <Table
                          columns={summaryColumns}
                          dataSource={summary.map((row, i) => {
                            return {
                              key: i,
                              field: form.getFieldValue([
                                'summary',
                                row.name,
                                'field',
                              ]),
                              value: (
                                <Form.Item
                                  {...row}
                                  name={[row.name, 'value']}
                                  fieldKey={[row.fieldKey, 'value']}
                                >
                                  <Input.TextArea autoSize bordered={false} />
                                </Form.Item>
                              ),
                            };
                          })}
                          size="small"
                          showHeader={false}
                          pagination={false}
                        />
                      );
                    }}
                  </Form.List>
                </Col>
              </Row>
            ) : (
              <Row gutter={8}>
                <Col flex="172px" className="care-guide-image">
                  <CareGuideImageForm
                    careGuideId={careGuideId}
                    isEditing={isEditing}
                  />
                </Col>

                <Col style={{ flex: '1 1 auto', flexBasis: 'min-content' }}>
                  <Table
                    columns={summaryColumns}
                    dataSource={summaryData}
                    size="small"
                    showHeader={false}
                    pagination={false}
                  />
                </Col>
              </Row>
            )}

            <div className="care-guide-summary-members">
              <h3>{t('care_guides_members')}</h3>
              <CareGuideMembersList
                careGuideId={careGuideId}
                page={1}
                limit={10}
                order={'joined_at.asc'}
              />
            </div>
          </Card>
        </Form>
      )}
    </Translation>
  );
};

export default CareGuideSummary;
