import {getI18n} from 'react-i18next';

import Config from '../../../../Config';
import Logger from '../../../../lib/Logger';
import Events from '../../../../lib/EventEmitter';

import CareGuideInvitationAccept from './containers/CareGuideInvitationAcceptContainer';

const register = () => {
  Logger.log('debug', `careGuideInvitations.register()`);

  if (Config.getIn(['MODULE_TOGGLES', 'careGuideInvitations', 'routes'])) {
    Events.dispatch('ADD_MAIN_ROUTES', 

      // screen name (key): [route type (element), path (prop), exact (prop), component (prop), route name]
      {
        'CareGuideInvitationAccept': ['PrivateRoute', '/invitation/:invitationId([\\w\\-]+)/accept', true, CareGuideInvitationAccept, getI18n().t('care_guide_invitations_route_accept')],
      }
    );
  }
}

export default register;

Logger.log('silly', `careGuideInvitations.register() loaded.`);
