import { connect } from 'react-redux';
import { List } from 'immutable';
import { denormalize } from 'normalizr';

import { schema } from '../../../../../state/schema';
import JournalEmojisList from '../components/JournalEmojisList';
import {
  loadJournalEmojis,
  createJournalEmoji,
  deleteJournalEmoji,
} from '../../../../../state/modules/journalEmojis/actions';

const mapStateToProps = (state, ownProps) => {
  const careGuideId = ownProps.careGuideId;
  const journalPostId = ownProps.journalPostId;
  const page = ownProps.page;
  const limit = ownProps.limit;
  const order = ownProps.order ? ownProps.order : 'count.desc';
  const careGuide = ownProps.careGuideId
    ? denormalize(
        state.entities.getIn(['careGuides', ownProps.careGuideId]),
        schema.careGuide,
        state.entities.toJS()
      )
    : null;

  let result = List();
  for (let i = 1; i <= page; i++) {
    const paged_results = state.journalEmojis.getIn([
      'results',
      careGuideId,
      journalPostId,
      'pages',
      order,
      limit,
      i,
    ]);
    if (paged_results) {
      result = result.merge(paged_results);
    }
  }

  return {
    careGuide,
    list: result ? result.toArray() : [],
    map:
      result.size > 0
        ? result
            .toArray()
            // eslint-disable-next-line no-sequences
            .reduce((map, obj) => ((map[obj.code] = obj), map), {})
        : {},
    total: state.journalEmojis.getIn(
      ['results', careGuideId, journalPostId, 'total'],
      0
    ),
    isLoading: state.journalEmojis.get('isLoading'),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    load: (careGuideId, journalPostId, page, limit, order, filter, cb) => {
      dispatch(
        loadJournalEmojis(
          careGuideId,
          journalPostId,
          page,
          limit,
          order,
          filter,
          cb
        )
      );
    },
    create: (careGuideId, journalPostId, code, cb) => {
      dispatch(
        createJournalEmoji(careGuideId, journalPostId, { code: code }, cb)
      );
    },
    remove: (careGuideId, journalPostId, id, cb) => {
      dispatch(deleteJournalEmoji(careGuideId, journalPostId, id, cb));
    },
  };
};

const JournalEmojisListContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(JournalEmojisList);

export default JournalEmojisListContainer;
