import { connect } from "react-redux";
import { denormalize } from "normalizr";
import { schema } from "../../../../../state/schema";

import CareGuideOwnersScreen from "../components/CareGuideOwnersScreen";
import storage from "../../../../../lib/Storage";

const mapStateToProps = (state, ownProps) => {
  const page = 1;
  const limit = 10;
  const order = "started_at.asc";

  const result = state.careGuides.getIn(["pages", order, limit, page]);

  const list = result
    ? result
        .map((x) => {
          return {
            key: x,
            ...denormalize(
              state.entities.getIn(["careGuides", x]),
              schema.careGuide,
              state.entities.toJS()
            ),
          };
        })
        .toArray()
    : [];

  return {
    isLoading: state.careGuides.get("isLoading"),
    activeId: state.careGuides.get("activeId"),
    list: list,
    accessTabs: storage.get("local", "partnershipData")?.access_tabs_in_app,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

const CareGuideOwnersScreenContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(CareGuideOwnersScreen);

export default CareGuideOwnersScreenContainer;
