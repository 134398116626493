import {connect} from 'react-redux';
import {denormalize} from 'normalizr';
import {withRouter} from "react-router";

import {schema} from '../../../../../state/schema';
import {
  patchMessage,
  patchMessageRecipient,
  loadMessagesInbox,
  loadMessagesDrafts,
  loadMessagesSent,
  loadMessagesArchived,
  loadMessagesDeleted,
  messageShowComposeForm,
  loadMessagesCounts
} from '../../../../../state/modules/messages/actions';
import MessageDetail from '../components/MessageDetail';
import {getRouteFromPath} from '../../../Routes';

const mapStateToProps = (state, ownProps) => {
  const currentScreen = getRouteFromPath(ownProps.location.pathname).screen;
  return {
    message: denormalize(
      state.entities.getIn(['messages', ownProps.messageId]),
      schema.message,
      state.entities.toJS()
    ),
    isSubmitting: state.messages.getIn(['form', 'isSubmitting']),
    success: state.messages.getIn(['form', 'success']),
    isRecipientFormSubmitting: state.messages.getIn(['recipientForm', 'isSubmitting']),
    recipientFormSuccess: state.messages.getIn(['recipientForm', 'success']),
    profileId: state.session.get('profileId'),
    currentScreen: currentScreen,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  const currentScreen = getRouteFromPath(ownProps.location.pathname).screen;
  return {
    patchMessage: (messageId, data, cb) => {
      dispatch(patchMessage(messageId, data, cb));
    },
    patchRecipient: (messageId, id, data, cb) => {
      dispatch(patchMessageRecipient(messageId, id, data, cb));
    },
    load: (page, limit, order, filter, cb) => {
      switch(currentScreen) {
        case 'MessagesInboxThreadScreen':
          dispatch(loadMessagesInbox(page, limit, order, filter, cb));
          break;
        case 'MessagesDraftsThreadScreen':
          dispatch(loadMessagesDrafts(page, limit, order, filter, cb));
          break;
        case 'MessagesSentThreadScreen':
          dispatch(loadMessagesSent(page, limit, order, filter, cb));
          break;
        case 'MessagesArchivedThreadScreen':
          dispatch(loadMessagesArchived(page, limit, order, filter, cb));
          break;
        case 'MessagesTrashThreadScreen':
          dispatch(loadMessagesDeleted(page, limit, order, filter, cb));
          break;
        default:
      }
    },
    showForm: (threadId, recipientIds) => {
      dispatch(messageShowComposeForm(threadId, null, recipientIds));
    },
    loadCounts: (cb) => {
      dispatch(loadMessagesCounts(cb));
    }
  }
}

const MessageDetailContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(MessageDetail);

export default withRouter(MessageDetailContainer);
